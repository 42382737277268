<!--
	@name app-events
	@description events page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div id="map" class="mb-3" />
		<v-menu v-model="selectedOpen" :close-on-content-click="false" :position-x="x" :position-y="y" absolute max-width="30rem">
			<v-card color="grey lighten-4" min-width="350px" flat :href="selectedEvent.url ? selectedEvent.url : null" :target="selectedEvent.url ? '_blank' : null" :link="!!selectedEvent.url">
				<v-system-bar :color="getEventColor(selectedEvent)" height="10" />

				<v-card-text class="text-h6 font-weight-bold">
					{{ selectedEvent.name }}
				</v-card-text>

				<v-list-item dense>
					<v-list-item-icon class="mr-0">
						<v-icon left>
							mdi-calendar-clock
						</v-icon>
					</v-list-item-icon>
					<v-list-item-subtitle>
						{{ selectedEventTime(selectedEvent) }}
					</v-list-item-subtitle>
				</v-list-item>

				<v-list-item dense>
					<v-list-item-icon class="mr-0">
						<v-icon left>
							mdi-map-marker
						</v-icon>
					</v-list-item-icon>
					<v-list-item-subtitle>{{ selectedEvent.address }}</v-list-item-subtitle>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-subtitle>
						<v-chip small label outlined :color="getEventColor(selectedEvent)" dark>
							{{ getEventTypeName(selectedEvent) }}
						</v-chip>
					</v-list-item-subtitle>
				</v-list-item>

				<v-divider class="mt-5" />

				<v-card-text class="py-6 pt-5">
					<span class="text-body-2 font-weight-bold">{{ selectedEvent.description }}</span>
				</v-card-text>

				<v-card-actions class="pt-0">
					<v-spacer />
					<v-tooltip v-if="selectedEvent.url" top>
						<template #activator="{ on }">
							<v-btn icon color="primary" v-on="on">
								<v-icon>mdi-link</v-icon>
							</v-btn>
						</template>
						<span>Find out more</span>
					</v-tooltip>
					<v-tooltip top>
						<template #activator="{ on }">
							<v-btn icon color="error" v-on="on" @click.stop.prevent="selectedOpen = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</template>
						<span>Close</span>
					</v-tooltip>
				</v-card-actions>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
	import { googleMap } from '@/utils';

	export default {
		name: 'app-events-map',

		components: {},

        props: {
			events: { type: Array, required: true }
		},

        data() {
			return {
                selectedEvent: {},
                selectedOpen: false,
                x: 0,
                y: 0
            };
		},

		computed: {
            mapStyle() {
                return [
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#1eb0e1"
                            },
                            {
                                "weight": "0.30"
                            },
                            {
                                "saturation": "-75"
                            },
                            {
                                "lightness": "5"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#444444"
                            },
                            {
                                "saturation": "-75"
                            },
                            {
                                "lightness": "5"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#17b890"
                            },
                            {
                                "visibility": "on"
                            },
                            {
                                "weight": "6"
                            },
                            {
                                "saturation": "-60"
                            },
                            {
                                "lightness": "30"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#670056"
                            },
                            {
                                "weight": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#17b890"
                            },
                            {
                                "saturation": "-60"
                            },
                            {
                                "lightness": "30"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#1eb0e1"
                            },
                            {
                                "visibility": "simplified"
                            },
                            {
                                "saturation": "-75"
                            },
                            {
                                "lightness": "5"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#17b890"
                            },
                            {
                                "weight": 8
                            },
                            {
                                "saturation": "-28"
                            },
                            {
                                "lightness": "0"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#1eb0e1"
                            },
                            {
                                "weight": 8
                            },
                            {
                                "lightness": "5"
                            },
                            {
                                "gamma": "1"
                            },
                            {
                                "saturation": "-75"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "color": "#1eb0e1"
                            },
                            {
                                "saturation": "-75"
                            },
                            {
                                "lightness": "5"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#1eb0e1"
                            },
                            {
                                "saturation": "-0"
                            },
                            {
                                "lightness": "20"
                            },
                            {
                                "gamma": "2"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "color": "#17b890"
                            },
                            {
                                "saturation": "-28"
                            },
                            {
                                "lightness": "0"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ];
            }
        },

		created() {
            if(window?.google?.maps) this.init();
        },

		methods: {
			init() {
				const gm = new googleMap();
				gm.then(() => {
					let target = document.getElementById('map');
					if (target == null) throw new Error('Error');

					let map = new window.google.maps.Map(target, {
						center: { lat: 53.383331, lng: -1.466667 },
						zoom: 7
					});

					map.setOptions({ styles: this.mapStyle });

					const icon = {
						path: "M33.9218 0.00793457H16.9609C16.9609 8.60942 20.1643 16.4605 25.4414 22.4423C30.7185 16.4632 33.9218 8.60942 33.9218 0.00793457Z",
						fillColor: "#d32f2f",
						fillOpacity: 1,
						strokeWeight: 3,
						strokeColor: "rgba(255, 255, 255, 0.1)",
						rotation: 0,
						scale: 1.25,
						anchor: new window.google.maps.Point(0, 20)
					};

                    this.events.filter((e) => !!e.geolocation).forEach((e) => {
                        window.setTimeout(() => {
                            let geolocation = e.geolocation.split(',');
                            geolocation = geolocation.map(s => s.trim());
                            const position = { lat: Number(geolocation[0]), lng: Number(geolocation[1]) };
                            const marker = new window.google.maps.Marker({
                                position,
                                map,
                                icon,
                                title: target.dataset.title,
                                animation: window.google.maps.Animation.DROP
                            });
                            marker.addListener("click", (evt) => this.showEvent(evt, e));
                        }, 750);
					});
				});
			},

            /**
			 * @name showEvent
			 * @description Show the event
			 * @param {Object} {nativeEvent, event} data for the event
			 */
			showEvent(evt, event) {
                this.x = evt.domEvent.clientX;
                this.y = evt.domEvent.clientY;

                const open = () => {
					this.$nextTick(() => {
                        this.selectedEvent = event;
                        this.selectedOpen = true;
                    });
				};

                if (this.selectedOpen) {
					this.selectedOpen = false;
					setTimeout(open, 10);
				} else open();
			},

            /**
			 * @name getEventColor
			 * @description Return the colour fo the event
			 * @param {Object} event The event as an object
			 * @return {String} Color
			 */
			getEventColor(event) {
				if (event.cancelled) return 'rgb(234, 99, 140)';
				else if (!event.active) return 'grey';
				else if (event.type === 'Air Mortgage Club') return 'rgb(27, 120, 216)';
				else if (event.type === 'Air Later Life Academy') return 'rgb(23, 184, 144)';
				else if (event.type === 'Air Select') return 'rgb(103, 0, 86)';
				else return 'primary';
			},

			/**
			 * @name getEventTypeName
			 * @description Return type name for the event
			 * @param {Object} event The event as an object
			 * @return {String} Type name
			 */
			getEventTypeName(event) {
				if (event.cancelled) return 'Cancelled';
				else if (!event.active) return 'Sold Out';
				else if (event.type === 'Air Mortgage Club') return 'Air Mortgage Club Event';
				else if (event.type === 'Air Later Life Academy') return 'Air Later Life Academy Event';
				else if (event.type === 'Air Select') return 'Air Select Event';
				else return 'Other Company Event';
			},

            /**
			 * @name selectedEventEndTime
			 * @description - If on same day, change this to {start} - {endtime} rather than  {start} - {end}
			 * @return {String} time for selected event
			 */
			selectedEventTime(selectedEvent) {
				const start = this.$moment(String(selectedEvent.start)).format('ddd, Do MMM YYYY h:mma');
				let end = this.$moment(String(selectedEvent.end)).format('ddd, Do MMM YYYY h:mma');

				if (this.$moment(String(selectedEvent.start)).isSame(this.$moment(String(selectedEvent.end)), 'day')) {
					end = this.$moment(String(selectedEvent.end)).format('h:mma');
				}

				return `${start} - ${end}`;
			}
        }
	};
</script>

<style lang="scss" scoped>
	#map {
		width: 100%;
		height: 725px;
	}
</style>
