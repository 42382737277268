<!--
	@name app-comprehensive-conversations
	@description Comprehensive Conversations page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/man-woman-banner.jpg' | urlize" :height="$vuetify.breakpoint.mdAndUp ? '500' : ''">
			<v-container style="position: relative; z-index: 1" fill-height>
				<div>
					<div>
						<img :src="'logo/brand/CC_Logo-WHITE-1024x251.png' | urlize" :height="$vuetify.breakpoint.lgAndUp ? '140' : '100'" />
					</div>
					<div class="text-center">
						<v-btn to="/comprehensive-conversations/report" color="info" class="mt-6">
							View Our Report
						</v-btn>
					</div>
				</div>
			</v-container>
		</v-img>

		<!-- About -->
		<div>
			<v-container>
				<div class="text-body-2 text-center d-flex flex-column align-center py-4" :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }">
					<p class="text-h4 font-weight-regular">
						Are you joining the movement to evolve the later life lending industry?
					</p>
					<div class="text-center">
						<v-btn to="/comprehensive-conversations/manifesto" color="info" class="mt-2">
							<v-icon class="mr-1">
								mdi-bullhorn-outline
							</v-icon>
							Read our Manifesto
						</v-btn>
					</div>

					<div class="video-container mt-8">
						<iframe
							id="vimeo-player-915965799"
							class="video-iframe"
							allowfullscreen=""
							allow="clipboard-write"
							title="vimeo Video Player"
							src="https://player.vimeo.com/video/915965799?color&amp;autopause=0&amp;loop=0&amp;muted=0&amp;title=1&amp;portrait=1&amp;byline=1#t="
							data-ready="true"
						/>
					</div>

					<div class="divider mt-12" />

					<v-row style="width: 100%" class="mt-8">
						<v-col v-for="(video, index) in videos" :key="index" cols="12" lg="4">
							<div class="text-body-1 font-weight-bold" :style="`height: ${$vuetify.breakpoint.lgAndUp ? '80px' : 'auto'}`">
								{{ video.copy }}
							</div>

							<div class="mt-4" style="position: relative">
								<div v-if="$vuetify.breakpoint.lgAndUp">
									<img :src="video.poster | urlize" style="width: 100%" />
									<v-icon size="100" color="black lighten-2" class="play-icon mt-n1" @click="playVideo(video)">
										mdi-play-circle
									</v-icon>
								</div>

								<video v-else controls :src="video.src" :poster="video.poster | urlize" width="100%" height="auto" />
							</div>
						</v-col>
					</v-row>

					<div class="text-body-1 mt-8">
						<p>
							Launching our new Comprehensive Conversations Manifesto to establish a new standard of consumer-focused service excellence in the later-lending market.
						</p>

						<p>
							Our Manifesto underpins an ongoing movement using education and advanced technology to empower advisers, lenders, networks, and professionals to provide
							diverse consumer-focused options.
						</p>

						<p>
							After the FCA Consumer Duty and emerging product innovations for older borrowers, firms and providers are examining how to offer advice and products to
							consumers over 50. Our Comprehensive Conversations movement strives for an agreed benchmark, promoting an affordability-centric, product-agnostic
							approach in the later life lending market.
						</p>

						<p>
							By signing our manifesto, you pledge to follow industry guidelines, present all options to customers, and enhance advice, products, and services when
							possible. Additionally, you may refer customers to alternative services for better outcomes based on their specific needs, preferences, and
							circumstances.
						</p>

						<p>We've also created a Later Life Lexicon as part of the movement to guide you and your firms with clear definitions of all key terms in the market.</p>

						<p>
							As we move forward, your engagement is crucial. We encourage you to read our Manifesto and Lexicon. If our vision resonates with you, please sign the
							Manifesto. We are excited to embark on this journey with you and value your membership immensely.
						</p>
					</div>
				</div>
			</v-container>
		</div>

		<!-- Manifesto -->
		<div class="mt-2" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-row :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '' }" class="fill-height">
				<v-col md="5" sm="12" xs="12" class="pa-0" :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '' }">
					<v-img :src="'generic/Air_Manifesto_Cover.jpg' | urlize" width="100%" height="600px" cover alt="" />
				</v-col>
				<v-col md="7" sm="12" xs="12" style="background-color: #f6f6f6" class="justify-center">
					<div class="fill-height d-flex align-center" :style="{ padding: `24px ${$vuetify.breakpoint.mdAndUp ? '140px' : '70px'}` }">
						<div>
							<div class="text-h3 font-weight-bold primary--text">
								Join the movement
							</div>
							<div class="text-body-1 mt-8">
								<p>
									Air's campaign was pulling into focus the future of the later life market, outlining the ambitions of both lenders and advisers, as well as the
									steps needed to get there. In doing so, advisers will be able to have more comprehensive conversations, resulting in their customers having
									better outcomes.
								</p>
							</div>
							<v-btn to="/comprehensive-conversations/manifesto" color="info" class="mt-6">
								View Manifesto
							</v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>

		<!-- The Later Life Lexicon -->
		<div class="mt-2" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<v-row :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '' }" class="fill-height">
					<v-col md="7" sm="12" xs="12">
						<div class="fill-height d-flex align-center py-4">
							<div>
								<div class="text-h4">
									The Later Life Lexicon
								</div>
								<div class="text-h5 mt-4">
									A new language for later life, taking key messages to market.
								</div>
								<div class="text-body-1 mt-4">
									<p>
										The Later Life Lexicon represents a significant milestone in the evolution of the later life lending landscape. This lexicon serves as a
										vital guide, offering clear and concise definitions for key terms related to later life lending, mortgages, and associated products.
									</p>
									<p>
										More than just a collection of words, it embodies a new language designed to align messages with the later life lending market. This
										initiative is part of a comprehensive conversations campaign, a movement that aspires to be a pivotal force in driving positive change and
										expanding advice propositions.
									</p>
								</div>
								<v-btn :href="'document/Later-Life-Lexicon_V5-1.pdf' | urlize" target="_blank" class="mt-6" color="info">
									<v-icon class="mr-2">
										mdi-download
									</v-icon> Download Lexicon
								</v-btn>
							</div>
						</div>
					</v-col>
					<v-col md="5" sm="12" xs="12" class="pa-0" :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '' }">
						<v-img :src="'generic/IMG_Air_Lexcion_Brochure_A4_MockUp-1.png' | urlize" width="100%" height="100%" contain alt="" />
					</v-col>
				</v-row>
			</v-container>
		</div>

		<!-- Further Resources -->
		<div class="py-16">
			<v-container style="max-width: 1000px">
				<div class="text-h4 text-center mb-4" :class="{ 'mb-10': $vuetify.breakpoint.mdAndUp }">
					<p>Further Resources</p>
				</div>
				<v-row>
					<v-col md="6" sm="12" xs="12">
						<v-card class="d-flex flex-column fill-height mx-auto">
							<v-img :src="'placeholder/academy-hero.jpg' | urlize" max-height="200px" height="200px" />

							<v-card-title class="px-6 pt-6">
								<img :src="'logo/brand/lg_airacademy.svg' | urlize" height="28px" width="auto" />
							</v-card-title>

							<v-card-text class="px-6">
								Personal, business-focused learning platform underpinned by leading technology and knowledgeable experts offering a range of LIBF-accredited
								modules.
							</v-card-text>
							<v-spacer />
							<v-card-actions>
								<v-btn color="info" small class="ml-4 mb-4" to="/air-academy">
									Find out more
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col md="6" sm="12" xs="12">
						<v-card class="d-flex flex-column fill-height mx-auto">
							<v-img :src="'generic/hero_navigator-screen-mockup_update-768x512.jpg' | urlize" max-height="200px" height="200px" />

							<v-card-title class="px-6 pt-6">
								<img :src="'logo/brand/AIR_NAVIGATOR_LOGO_RGB_POS-2.png' | urlize" height="32px" width="auto" />
							</v-card-title>

							<v-card-text class="px-6">
								Supports advisers in their advice process making considerations into affordability and product options prior to their full fact find.
							</v-card-text>
							<v-spacer />
							<v-card-actions>
								<v-btn color="info" small class="ml-4 mb-4" :href="navigatorUrl" target="blank">
									Find out more
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="6" sm="12" xs="12">
						<v-card class="d-flex flex-column fill-height mx-auto">
							<v-img :src="'generic/20220905-S2-Air-773-SE-HI-768x512.jpg' | urlize" max-height="200px" height="200px" />

							<v-card-title class="px-6 pt-3">
								<img :src="'logo/brand/Write_Route_Logo_v2.png' | urlize" height="44px" width="auto" />
							</v-card-title>

							<v-card-text class="px-6">
								Our latest version of WriteRoute, our <strong>Corporate Partner Endorsed WriteRoute</strong>, is endorsed by our corporate partners and aligned to
								network and industry standards as well as best practice checklists. This Corporate Partner Endorsed version includes more mandatory affordability
								and customer vulnerability completions, bringing these crucial focal points to the forefront of the advice process.
							</v-card-text>
							<v-spacer />
							<v-card-actions>
								<v-btn color="info" small class="ml-4 mb-4" :href="writeRouteUrl" target="blank">
									Find out more
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col md="6" sm="12" xs="12">
						<v-card class="d-flex flex-column fill-height mx-auto">
							<v-img :src="'generic/man_on_ipad_600x400.jpg' | urlize" max-height="200px" height="200px" />

							<v-card-title class="px-6 pt-6">
								<img :src="'logo/brand/lg_airsourcing.svg' | urlize" height="28px" width="auto" />
							</v-card-title>

							<v-card-text class="px-6">
								As the leading later life lending platform with the most integrations across the biggest lenders in the market, our members enjoy unrivalled support
								and access to products and the technology to optimise how they write business.
							</v-card-text>
							<v-spacer />
							<v-card-actions>
								<v-btn color="info" small class="ml-4 mb-4" to="/sourcing">
									Find out more
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<common-dialog v-if="selectedVideo" ref="videoDialog" :max-width="960" @closed="selectedVideo = null">
			<template #header>
				{{ selectedVideo?.copy }}
			</template>
			<template #body>
				<div class="video-container">
					<video :controls="true" :src="selectedVideo?.src" :poster="selectedVideo?.poster | urlize" width="100%" height="auto" autoplay />
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-comprehensive-conversations',

		components: {
			CommonDialog
		},

		data() {
			return {
				selectedVideo: null,
				videos: [
					{
						title: 'Paul',
						copy: 'What is the Comprehensive Conversations movement?',
						src: 'https://s3.eu-west-2.amazonaws.com/air.assets/webinar/ComprehensiveConversationsPaulQ1.mp4',
						poster: 'generic/Paul-Thumbnail.png',
						controls: false
					},
					{
						title: 'Matt',
						copy: 'Why is the Comprehensive Conversations movement needed right now?',
						src: 'https://s3.eu-west-2.amazonaws.com/air.assets/webinar/ComprehensiveConversationsMattQ1.mp4',
						poster: 'generic/Matt-Thumbnail.png',
						controls: false
					},
					{
						title: 'Will',
						copy: ' How is the Comprehensive Conversations movement addressing the evolving needs of borrowers in later life?',
						src: 'https://s3.eu-west-2.amazonaws.com/air.assets/webinar/ComprehensiveConversationsWillQ1.mp4',
						poster: 'generic/Will-Thumbnail.png',
						controls: false
					}
				]
			};
		},

		computed: {
			writeRouteUrl() {
				return process.env.VUE_APP_FACTFIND_ORIGIN;
			},
			navigatorUrl() {
				return process.env.VUE_APP_LLLNAVIGATOR_ORIGIN;
			}
		},

		created() {},

		methods: {
			playVideo(video) {
				this.selectedVideo = video;

				this.$nextTick(() => {
					this.$refs.videoDialog.open();
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.video-iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.video-container {
		width: 100%;
		height: 100%;
		aspect-ratio: 1.77777;
	}

	.divider {
		background: var(--v-info-base);
		width: 150px;
		height: 5px;
		border-radius: 10px;
	}

	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
</style>
