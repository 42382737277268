<!--
	@name app-download-document
	@description Show documents on the downloads page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-alert v-if="error" type="error" class="mb-0">
			{{ error }}
		</v-alert>
		<v-skeleton-loader
			v-else-if="!Object.keys(consumerDutyResources).length || !Object.keys(providers).length"
			type="list-item-avatar-two-line"
		/>
		<template v-else>
			<v-card v-for="(documents, provider) in consumerDutyResources" :key="provider" class="item mx-auto">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h6 mb-1">
							{{ providers[provider].name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-sheet
					height="194"
					color="grey lighten-4 pointer"
					class="d-flex align-center justify-center"
					@click="openResources(providers[provider], documents)"
				>
					<v-img
						contain
						class="logo"
						:src="providers[provider].organisationDetail.logo?.filter((l) => l.primary)[0].name | urlize('organisation')"
						height="194"
					/>
				</v-sheet>
			</v-card>
		</template>

		<common-dialog ref="dialog" :component="$options.name" :max-width="1000">
			<template #header>
				{{ provider }} Resources
			</template>

			<template #body>
				<common-base-download-items :items="resources" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UrlTools } from '@/utils';
import CommonDialog from '@/component/common/dialog';
import CommonBaseDownloadItems from '@/component/common/base/download-items';
import { keyBy } from 'lodash';

export default {
	name: 'app-download-document',

	components: { CommonDialog, CommonBaseDownloadItems },
	data() {
		return {
			error: false,
			provider: null,
			providers: [],
			resources: []
		};
	},

	computed: {
		...mapState('app', ['config']),
		...mapState('CmsDownload', ['consumerDutyResources'])
	},

	mounted: function() {
		this.loadConsumerDutyResourceList().then(() => {
			this.loadProviderList({ where: { id: { value: Object.keys(this.consumerDutyResources), type: 'uuid' } }, order: { property: 'organisation.name' } })
				.then((resp) => this.providers = keyBy(resp.data, (provider) => provider.id))
				.catch(() => this.error = 'Unable to load Consumer Duty resources. Please try again later');
		});
	},

	methods: {
		...mapActions('AccountOrganisation', ['loadProviderList']),
		...mapActions('CmsDownload', ['loadConsumerDutyResourceList']),

		/**
		 * @name providerLogo
		 * @description Returns the logo url of the given provider
		 * @param {Object} provider The provider object
		 * @return {String} a url to the provider logo
		 */
		providerLogo(id) {
			return UrlTools.providerImageUrlFromId(id, this.config);
		},

		openResources(provider, documents) {
			this.provider = provider.name;
			this.resources = documents;
			this.$refs.dialog.open();
		}
	}
};
</script>

<style lang="scss" scoped>
.item {
	margin: 10px !important;
	display: inline-block;
	flex: 1 1 300px;
	width: calc(33.333% - 20px);
}

.logo {
	max-width: 80%;
	max-height: 80%;
}

.pointer {
	cursor: pointer;
}
</style>