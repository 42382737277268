<!--
	@name app-client-dashboard-workflow-fact-find-activity
	@description Client Fact Find Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="loading" type="list-item-three-line" />

		<v-sheet v-else-if="!items?.length">
			<v-row>
				<v-col cols="12">
					<video v-if="introResource" class="d-block ml-10 mb-6" :src="introResource.video" :poster="introResource.poster" controls width="50%" style="float: right" />
					<p class="text-body-1 font-weight-bold mb-6">
						This integrated fact find flow is an efficient way to provide an audit trail that demonstrates the advice provided is tailored to the customer.
					</p>
					<ul class="text-body-1 pl-8 mb-6">
						<li class="mb-2">
							Navigate directly to "WriteRoute" where you will be able to complete the fact find with your customers.
						</li>
						<li class="mb-2">
							Information gathered in your fact find will automatically be pulled through to help generate your Suitability Report.
						</li>
						<li class="mb-0">
							Will automatically track the updated progress of your fact find, right from creation to completion, allowing it to be kept up to date for each case.
						</li>
					</ul>
					<p class="text-body-1 mb-8">
						Click on the intro video now to gain a deeper insight into how you can navigate AirFlow's fact find tile and maximise its benefits and features.
					</p>
					<v-btn color="primary" @click="createNewFactFind()">
						Get started
					</v-btn>
					<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
				</v-col>
			</v-row>
		</v-sheet>

		<div v-else>
			<v-timeline align-top dense>
				<v-timeline-item v-for="(item, index) in items" :key="index" :color="item.completed ? 'grey' : 'grey'" small :class="{ 'mt-4': !!index }">
					<v-row class="ma-n3 pt-1">
						<v-col>
							<p class="text-h6 mt-n2 mb-1">
								<span class="font-weight-bold">
									{{ item.reference }}
								</span>
							</p>
							<div class="mb-4 d-flex align-center">
								<span class="flex-grow-0 mr-2"> Progress: {{ item.progress }}% </span>
								<v-tooltip :disabled="!!item.progress" bottom max-width="300">
									<template #activator="{ on }">
										<v-progress-linear
											:value="item.progress"
											:color="determineProgressColour(item.progress)"
											height="8"
											style="max-width: 300px"
											:indeterminate="item.progress ? false : true"
											v-on="on"
										/>
									</template>
									<span>Awating progress</span>
								</v-tooltip>
								<p class="d-none text-body-2 plain mb-0">
									v{{ item.versionLatest || item.versionInitial }}
								</p>
							</div>

							<v-timeline align-top dense class="pt-1">
								<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon color="success">
											mdi-check-bold
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Created: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block"> {{ item.created | moment('MMM Do YYYY, h:mma') }} | v{{ item.versionInitial }} </span>
										</v-col>
									</v-row>
								</v-timeline-item>
								<v-timeline-item v-if="item.versionLatest && item.versionLatest != item.versionInitial" color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon color="success">
											mdi-check-bold
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Updated: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block"> v{{ item.versionLatest || item.versionInitial }} </span>
										</v-col>
									</v-row>
								</v-timeline-item>
								<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon v-if="item.completed" color="success">
											mdi-check-bold
										</v-icon>
										<v-icon v-else color="error">
											mdi-close-thick
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Completed: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												<template v-if="item.completed">
													{{ item.completed | moment('MMM Do YYYY, h:mma') }}
												</template>
												<template v-else> -- </template>
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
							</v-timeline>

							<v-sheet class="mt-4 mx-n1">
								<v-btn color="primary" class="mx-1" @click="openFactFind(item)">
									Open factfind
								</v-btn>

								<v-tooltip bottom>
									<template #activator="{ on }">
										<div class="d-inline-block" v-on="on">
											<v-btn
												color="info"
												class="mx-1"
												icon
												:disabled="isSRDisabled(item)"
												:loading="fileLoading[item.id]"
												@click="fetchDocument(item, 'suitabilityReport')"
											>
												<v-icon> mdi-file-download </v-icon>
											</v-btn>
										</div>
									</template>
									<span>Download Suitability Report</span>
								</v-tooltip>

								<v-tooltip bottom>
									<template #activator="{ on }">
										<div class="d-inline-block" v-on="on">
											<v-btn
												color="info"
												class="mx-1"
												icon
												:disabled="isPDFDisabled(item)"
												:loading="fileLoading[item.id]"
												@click="fetchDocument(item, 'pdf')"
											>
												<v-icon> mdi-file-download </v-icon>
											</v-btn>
										</div>
									</template>
									<span>Download PDF</span>
								</v-tooltip>
							</v-sheet>
						</v-col>
					</v-row>
				</v-timeline-item>
			</v-timeline>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';

	export default {
		name: 'app-client-dashboard-workflow-fact-find-activity',

		components: {},

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, default: null },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				items: null,
				loading: true,
				fileLoading: {}
			};
		},

		created() {
			this.loadFactFinds();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('FactFind', ['fetchFactFindList']),
			...mapActions('File', ['getFile']),

			/**
			 * @name onMessage
			 * @description Websocket handler
			 * @param message Websocket message
			 */
			onMessage(message) {
				if (message?.type === 'factFindCreated' && message.item) {
					this.items = [message.item];
					this.$emit('workflow-updated');
				} else if (message?.type === 'factFind' && message.documents?.length) {
					const index = this.items.findIndex((i) => i.id === message.id);
					if (index > -1) {
						this.$set(this.items, index, { ...this.items[index], completed: message.completed, documents: [...this.items[index].documents, ...message.documents] });
					}
				}
			},

			/**
			 * @name loadFactFinds
			 * @description Fetch all fact finds
			 */
			async loadFactFinds() {
				const entityIds = [this.client.id];
				if (this.association) entityIds.push(this.association.id);

				try {
					this.items = await this.fetchFactFindList({ entityIds }).then(({ data }) => data.data);
					//TODO - remove when data cleanup up
					this.items = this.items.filter((i) => i.clients?.find((c) => c?.id === this.client.id));
					if (this.items.length > 1) this.items = this.items.slice(0, 1);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch fact finds items');
				}

				this.loading = false;
				if (!this.items?.length) this.$emit('toggle-intro-resource');
			},

			/**
			 * @name createNewFactFind
			 * @description Create beta fact find request
			 */
			async createNewFactFind() {
				let url = `${process.env.VUE_APP_FACTFIND_ORIGIN}/#/fact-find-create?client1=${this.client.id}`;
				if (this.association) url += `&client2=${this.association.id}`;
				if (this.asset) url += `&property${1}=${this.asset.id}`;
				new PopupWindow().open(url, '_blank');
			},

			/**
			 * @name openFactFind
			 * @description Redirect to write route
			 * @param item Fact find item
			 */
			openFactFind(item) {
				new PopupWindow().open(`${process.env.VUE_APP_FACTFIND_ORIGIN}/#/dashboard?factfindId=${item.id}`, '_blank');
			},

			/**
			 * @name fetchDocument
			 * @description Fetch affordability tool document
			 * @param item Fact find item
			 */
			async fetchDocument(item, documentType) {
				const { id, documents } = item;
				this.$set(this.fileLoading, id, true);

				const document = documents?.find((d) => d.type === documentType);

				this.getFile({ s3Key: document.s3Key, filename: document.filename || `${id}.${documentType === 'pdf' ? 'pdf' : 'docx'}` })
					.then((response) => {
						if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, id, false));
			},

			determineProgressColour(progress) {
				if (progress === null) return 'grey';
				if (progress <= 15) return 'red accent-3';
				if (progress <= 40) return 'warning';
				if (progress <= 55) return 'warning';
				if (progress <= 70) return 'amber accent-2';
				if (progress <= 90) return 'light-green accent-4';
				if (progress == 100) return 'green accent-2';
				return 'green accent-4';
			},

			/**
			 * @name openChat
			 */
			openChat: function() {
				EventBus.$emit('open-chat');
			},

			isSRDisabled(item) {
				return !item.documents?.find((d) => d.type === 'suitabilityReport');
			},

			isPDFDisabled(item) {
				return !item.documents?.find((d) => d.type === 'pdf');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clickable {
		cursor: pointer;
	}

	.fact-find-list {
		margin-top: 24px;
		&:first-child {
			margin-top: 0px;
		}
	}

	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}
</style>
