import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Product
 * @exports Service/Cms/ProductUpdate
 * @description API ProductUpdate Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class ProductUpdate {
	/**
	 * @static @name post
	 * @description List all product updates
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post(`cms/product-updates/list`, payload, {
			cache,
			cachePath: `table-cms-product-updates-${domain}`
		});
	}
}
