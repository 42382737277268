<!--
	@name app-events
	@description events page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet v-show="displayCountdown" class="text-center pa-2 rounded d-none d-sm-block" color="rgba(27, 120, 216, 0.6)" style="position: absolute; top: 16px; right: 16px; z-index: 2;">
			<div class="wrap">
				<div class="countdown countdown-static">
					<div class="bloc-time days" :data-init-value="days">
						<span class="count-title">Days</span>

						<div class="figure days days-1">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>

						<div class="figure days days-2">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>
					</div>

					<div class="bloc-time hours" :data-init-value="hours">
						<span class="count-title">Hours</span>

						<div class="figure hours hours-1">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>

						<div class="figure hours hours-2">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>
					</div>

					<div class="bloc-time min" :data-init-value="minutes">
						<span class="count-title">Minutes</span>

						<div class="figure min min-1">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>

						<div class="figure min min-2">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>
					</div>

					<div class="bloc-time sec" :data-init-value="seconds">
						<span class="count-title">Seconds</span>

						<div class="figure sec sec-1">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>

						<div class="figure sec sec-2">
							<span class="top">0</span>
							<span class="top-back">
								<span>0</span>
							</span>
							<span class="bottom">0</span>
							<span class="bottom-back">
								<span>0</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</v-sheet>
	</div>
</template>
Ï
<script>
	import { gsap, Quart, Back } from 'gsap';

	export default {
		name: 'app-events-event-countdown',

		components: {},

		props: {
			event: { type: Object, required: true }
		},

        data() {
			return {
				obj: {},
				displayCountdown: false,
				days: null,
				hours: null,
				minutes: null,
				seconds: null,
				total_seconds: null,
				staticInit: false,
				dialogInit: false,
				countdown_interval: null
			};
		},

		computed: {
			/**
			 * @name selectedEventEndTime
			 * @description - If on same day, change this to {start} - {endtime} rather than  {start} - {end}
			 * @return {String} time for selected event
			 */
			 eventDateTime() {
				const start = this.$moment(String(this.event.start)).format('ddd, Do MMM YYYY h:mma');
				let end = this.$moment(String(this.event.end)).format('ddd, Do MMM YYYY h:mma');

				if (this.$moment(String(this.event.start)).isSame(this.$moment(String(this.event.end)), 'day')) {
					end = this.$moment(String(this.event.end)).format('h:mma');
				}

				return `${start} - ${end}`;
			}
        },

		created() {
			this.$nextTick(() => {
				this.init();
			});
        },

		methods: {
			init() {
				var t1 = new Date(this.event.start);
				var t2 = new Date();
				var dif = t1.getTime() - t2.getTime();
				if(dif < 0) return;

				this.total_seconds = Math.ceil(Math.abs(dif / 1000));
				var delta = this.total_seconds;

				this.days = Math.floor(delta / 86400);
				delta -= this.days * 86400;
				// calculate (and subtract) whole hours
				this.hours = Math.floor(delta / 3600);
				delta -= this.hours * 3600;

				// calculate (and subtract) whole minutes
				this.minutes = Math.floor(delta / 60);
				delta -= this.minutes * 60;

				// what's left is seconds
				this.seconds = Math.floor(delta % 60); // in theory the modulus is not required

				// Init countdown values
				this.values = {
					days: this.days,
					hours: this.hours,
					minutes: this.minutes,
					seconds: this.seconds
				};

				let selector = '.countdown-static';
				this.objel = document.querySelector(selector);

				// DOM
				this.obj = {
					days: this.objel.querySelectorAll('.bloc-time.days .figure'),
					hours: this.objel.querySelectorAll('.bloc-time.hours .figure'),
					minutes: this.objel.querySelectorAll('.bloc-time.min .figure'),
					seconds: this.objel.querySelectorAll('.bloc-time.sec .figure')
				};

				// Animate countdown to the end
				if (this.days < 100) {
					this.displayCountdown = true;
					this.count(this.obj);
				}
			},

			count(obj) {
				var that = this,
					$day_1 = obj.days[0],
					$day_2 = obj.days[1],
					$hour_1 = obj.hours[0],
					$hour_2 = obj.hours[1],
					$min_1 = obj.minutes[0],
					$min_2 = obj.minutes[1],
					$sec_1 = obj.seconds[0],
					$sec_2 = obj.seconds[1];

				if (this.countdown_interval) clearInterval(this.countdown_interval);

				this.countdown_interval = setInterval(() => {
					if (that.total_seconds > 0) {
						--that.values.seconds;

						if (that.values.minutes >= 0 && that.values.seconds < 0) {
							that.values.seconds = 59;
							--that.values.minutes;
						}

						if (that.values.hours >= 0 && that.values.minutes < 0) {
							that.values.minutes = 59;
							--that.values.hours;
						}

						if (that.values.days >= 0 && that.values.hours < 0) {
							that.values.hours = 23;
							--that.values.days;
						}

						// Days
						that.checkHour(that.values.days, $day_1, $day_2);
						// Hours
						that.checkHour(that.values.hours, $hour_1, $hour_2);
						// Minutes
						that.checkHour(that.values.minutes, $min_1, $min_2);
						// Seconds
						that.checkHour(that.values.seconds, $sec_1, $sec_2);

						--that.total_seconds;
					} else {
						this.displayCountdown = false;
						clearInterval(that.countdown_interval);
					}
				}, 1000);
			},

			animateFigure($el, value) {
				var $top = $el.querySelectorAll('.top'),
					$bottom = $el.querySelectorAll('.bottom'),
					$back_top = $el.querySelectorAll('.top-back'),
					$back_bottom = $el.querySelectorAll('.bottom-back');

				// Before we begin, change the back value
				$back_top[0].querySelectorAll('span')[0].innerHTML = value;

				// Also change the back bottom value
				$back_bottom[0].querySelectorAll('span')[0].innerHTML = value;

				// Then animate
				gsap.to($top[0], {
					rotationX: '-180deg',
					transformPerspective: 300,
					ease: Quart.easeOut,
					duration: 0.8,
					onComplete() {
						$top[0].innerHTML = value;
						$bottom[0].innerHTML = value;
						gsap.set($top[0], { rotationX: 0 });
					}
				});

				gsap.to($back_top[0], {
					rotationX: 0,
					transformPerspective: 300,
					ease: Back.easeOut,
					scaleX: 0.98,
					duration: 0.8,
					clearProps: 'all'
				});
			},

			checkHour(value, $el_1, $el_2) {
				var val_1 = value.toString().charAt(0),
					val_2 = value.toString().charAt(1),
					fig_1_value = $el_1.querySelectorAll('.top'),
					fig_2_value = $el_2.querySelectorAll('.top');

				if (value >= 10) {
					// Animate only if the figure has changed
					if (fig_1_value[0].innerHTML !== val_1) this.animateFigure($el_1, val_1);
					if (fig_2_value[0].innerHTML !== val_2) this.animateFigure($el_2, val_2);
				} else {
					// If we are under 10, replace first figure with 0
					if (fig_1_value[0].innerHTML !== '0') this.animateFigure($el_1, 0);
					if (fig_2_value[0].innerHTML !== val_1) this.animateFigure($el_2, val_1);
				}
			}
        }
	};
</script>

<style lang="scss" scoped>
    @function tint($color, $percentage) {
	    @return mix(white, $color, $percentage);
    }

	// Variables
	$lato: 'Roboto';
	$cardCol1: tint(rgb(27, 120, 216), 10%);
	$cardCol2: tint(rgb(27, 120, 216), 20%);

	// Countdown
	.countdown {
		width: 300px;
		margin: 0 auto;
		display: flex;

		.bloc-time {
			text-align: center;
			flex-grow: 1;
			flex-shrink: 1;
			padding: 4px;
			flex-basis: 20%;

			&:last-child {
				margin-right: 0;
			}
		}

		.count-title {
			display: block;
			margin-bottom: 8px;
			font: normal 0.75em $lato;
			//color: #55acb8;
			color: #eee;
			text-transform: uppercase;
			font-weight: 700;
		}

		.figure {
			position: relative;
			float: left;
			height: 2rem;
			width: 40%;
			margin: 0 5%;
			background-color: $cardCol1;
			border-radius: 4px;

			&:last-child {
				margin-right: 0;
			}

			> span {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				font: normal 1.5rem $lato;
				line-height: 2rem;
				font-weight: 300;
				color: #eee;
			}

			&.days {
				> span {
					//color: #ccc;
                    //font-weight: 700;
				}
			}

			.top,
			.bottom-back {
				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			.top {
				z-index: 3;
				background-color: $cardCol2;
				transform-origin: 50% 100%;
				-webkit-transform-origin: 50% 100%;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				transform: perspective(200px);
			}

			.bottom {
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 50%;
					background-color: rgba(0, 0, 0, 0.02);
				}
			}

			.bottom-back {
				z-index: 2;
				top: 0;
				height: 50%;
				overflow: hidden;
				background-color: $cardCol2;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;

				span {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			.top,
			.top-back {
				height: 50%;
				overflow: hidden;
				backface-visibility: hidden;
			}

			.top-back {
				z-index: 4;
				bottom: 0;
				background-color: #fff;
				background-color: $cardCol1;
				-webkit-transform-origin: 50% 0;
				transform-origin: 50% 0;
				transform: perspective(200px) rotateX(180deg);
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;

				span {
					position: absolute;
					top: -100%;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
		}
	}

	.wrap {
		background-position: 50% 50%;
		background-size: cover;
	}
</style>
