import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Domain
 * @exports Service/Account/Organisation/Domain
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Domain {
	/**
	 * @static @name list
	 * @description Get all organisation domains with details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`account/organisation/domain/list`, payload);
	}

	/**
	 * @static @name get
	 * @description Get Organisation domains for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`account/organisation/domain/${id}`);
	}

	/**
	 * @static @name update
	 * @description Update all Organisation domains for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static update(id, payload) {
		return $http.put(`account/organisation/domain/${id}`, payload);
	}

	/**
	 * @static @name modify
	 * @description Update some Organisation domains for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static modify(id, payload) {
		return $http.patch(`account/organisation/domain/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete Organisation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/organisation/domain/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new Organisation resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static add(payload) {
		return $http.post(`account/organisation/domain`, payload);
	}
}
