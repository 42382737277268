<!--
	@name common-form-client
	@description common client component
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="form && form.length">
			<div v-for="(client, index) in clients" :key="client.id">
				<common-structure-section class="flex-grow-1 mb-6 elevation-2">
					<template #header>
						Applicant {{ clients.length > 1 ? index + 1 : '' }}
						<v-spacer />
						<v-btn v-if="!readOnly && index > 0" class="mr-n4" icon @click="requestRemoveClient(index)">
							<v-icon color="error">
								mdi-close-box
							</v-icon>
						</v-btn>
					</template>

					<template #body>
						<div v-if="config[index].clientLoading" class="flex-grow-1 mx-n4">
							<v-skeleton-loader type="list-item-three-line" />
						</div>

						<div v-else-if="isEmpty(client)">
							<v-btn-toggle
								v-model="config[index].clientToggle"
								:mandatory="config[index].mandatory"
								dense
								class="d-flex"
								color="primary"
								@change="clientToggleChanged(index)"
							>
								<v-tooltip :disabled="index !== 1 || (!association && !clients[0].hasFactFind)" bottom max-width="300">
									<template #activator="{ on }">
										<div class="v-btn-toggle v-btn-toggle--dense" v-on="on">
											<v-btn
												:disabled="index === 1 && (association || clients[0].hasFactFind)"
												style="border-top-right-radius: 0; border-bottom-right-radius: 0"
											>
												Source a new client
											</v-btn>
										</div>
									</template>
									<span> You cannot create a new client as client 1 is already associated with one or more clients. </span>
								</v-tooltip>

								<v-btn> Choose an existing client </v-btn>
							</v-btn-toggle>

							<v-alert v-if="showError" dense text border="left" type="error" class="mt-4 mb-0 text-subtitle-2">
								You need to select an applicant to proceed
							</v-alert>

							<v-autocomplete
								v-show="config[index].clientToggle === 1"
								v-model="config[index].selectedClient"
								class="mt-6"
								:items="config[index].clientList"
								:loading="config[index].isLoading"
								:hide-no-data="false"
								no-data-text="Loading clients..."
								:hide-details="true"
								:hide-selected="false"
								item-text="text"
								item-value="value"
								label="Client List"
								placeholder="Start typing to Search"
								autocomplete="off"
								@change="fetchClient(index)"
								@click="fetchClientList(index)"
							/>
						</div>

						<div v-else>
							<p class="text-body-1 ma-0">
								<span class="detail font-weight-bold">{{ getFullName(client) }}</span>
								<span v-if="!client.assumedDateOfBirth && client.dateOfBirth">
									<span>, born on </span>
									<span class="font-italic">{{ client.dateOfBirth }}</span>
								</span>
								<span v-else-if="client.assumedDateOfBirth && client.age">
									<span>, </span>
									<span class="detail font-italic">{{ client.age }} years old</span>
								</span>
							</p>

							<v-alert
								dense
								text
								border="left"
								:type="isValid[index] ? (client.assumedDateOfBirth && ageDisabled ? 'warning' : 'success') : 'error'"
								class="mt-2 mb-0"
							>
								<v-row align="center" class="px-3">
									<v-col v-if="isValid[index]" class="grow pa-0 text-subtitle-2">
										<span v-if="client.assumedDateOfBirth && ageDisabled"> You are advised to check the date of birth for this Applicant </span>
										<span v-else> You have entered the required details for this Applicant </span>
									</v-col>

									<v-col v-else class="grow pa-0 text-subtitle-2">
										We require further information relating to this Applicant.
									</v-col>

									<v-col class="shrink pa-0">
										<v-btn small dark @click="openDynamicFormDialog(form, client, 'Update Applicant', 'Applicant', 'setClient', 'update', index)">
											Update Details
										</v-btn>
									</v-col>

									<v-col v-if="!readOnly && !editOnly" class="shrink pa-0 ml-2">
										<v-btn small dark color="error" @click="requestRemoveClient(index)">
											Remove
										</v-btn>
									</v-col>
								</v-row>
							</v-alert>

							<div v-show="!readOnly && showAddButton(index)" class="mt-4">
								<v-tooltip :disabled="!clients[0].hasFactFind || association" bottom max-width="300">
									<template #activator="{ on }">
										<div class="d-inline-block" v-on="on">
											<v-btn small :disabled="clients[0].hasFactFind && !association" :loading="config[1].isLoading" @click="addClient">
												+ Add another applicant
											</v-btn>
										</div>
									</template>
									<span> This client is associated with a Fact Find. You can only add an additional applicant from WriteRoute. </span>
								</v-tooltip>
							</div>
						</div>
					</template>
				</common-structure-section>
			</div>

			<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="setClient" @closed="dialogClosed">
				<template #header>
					<slot name="header" />
				</template>
			</dynamic-form-dialog>
			<common-dialog-confirm ref="confirm" />
		</div>

		<v-card v-else class="flex-grow-1 mb-6">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { isEmpty } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';

	export default {
		name: 'common-form-client',

		components: {
			CommonStructureSection,
			CommonDialogConfirm,
			DynamicFormDialog
		},

		props: {
			form: { type: Array, required: true, default: () => [] },
			clients: { type: Array, required: true },
			showError: { type: Boolean, default: false },
			editOnly: { type: Boolean, default: false },
			readOnly: { type: Boolean, default: false },
			ageDisabled: { type: Boolean, default: false }
		},

		data() {
			return {
				config: [
					{
						clientToggle: null,
						mandatory: false,
						selectedClient: null,
						isLoading: false,
						clientLoading: false,
						search: null,
						clientList: []
					},
					{
						clientToggle: null,
						mandatory: false,
						selectedClient: null,
						isLoading: false,
						clientLoading: false,
						search: null,
						clientList: []
					}
				],
				isValid: [],
				association: false
			};
		},

		methods: {
			...mapActions('AppClient', ['loadClientList', 'loadClient']),
			...mapActions('AppClientConnected', ['loadConnectedClientList']),

			/**
			 * @name fetchClientList
			 * @description Fetch user's clients from server
			 * @param {Number} index Index of the applicant
			 */
			async fetchClientList(index, addClientEvent = false, autoSelectAssociation = true) {
				let payload;

				if ((this.config[index].isLoading || this.config[index].clientList.length > 0) && !addClientEvent) return;

				this.config[index].isLoading = true;

				let clientList = [];
				if (index === 1 && this.clients[0].id) {
					const { data } = await this.loadConnectedClientList({
						clientId: this.clients[0].id,
						payload: { order: { property: 'updated', direction: 'desc' }, factFindFilter: true }
					});
					if (data.data.length) {
						this.association = true;
						if (data.data.length === 1 && autoSelectAssociation) {
							this.fetchClient(1, data.data[0].id);
							return;
						} else clientList = data.data;
					} else {
						this.association = false;
						if (this.clients[0].hasFactFind) {
							this.config[index].isLoading = false;
							return;
						}
						if (addClientEvent) this.setClient({ data: {}, dataIndex: 1 });
						payload = { where: { id: { c: '!=', v: this.clients[0].id } }, singleLife: true };
					}
				}
				if (index === 1 && !this.clients[0].id) payload = { singleLife: true };
				if (index === 0 || !clientList.length) clientList = await this.loadClientList(payload);
				this.config[index].isLoading = false;
				this.config[index].clientList = clientList.map((i) => ({ value: i.id, text: `${i.nameGiven} ${i.nameFamily}` }));
			},

			/**
			 * @name fetchClient
			 * @description Autocomplete selected client change handler
			 * @param {Number} index Index of the applicant
			 */
			async fetchClient(index, clientId, autoSelectAssociation = true) {
				if (index === 0 && this.config[1].selectedClient) this.removeClient(1);

				clientId = clientId || this.config[index].selectedClient;
				this.config[index].clientLoading = true;

				try {
					const { data } = await this.loadClient(clientId);
					const landline = data?.communications?.find((c) => c.type === 'phone' && c.context === 'landline');
					const mobile = data?.communications?.find((c) => c.type === 'phone' && c.context === 'mobile');
					const email = data?.communications?.find((c) => c.type === 'email');
					const estimatedRetirementAge = data?.data?.estimatedRetirementAge;

					const mapped = {
						id: data.id,
						created: data.created,
						updated: data.updated,
						nameTitleId: data.nameTitleId,
						nameTitleName: data.nameTitleName,
						nameGiven: data.nameGiven,
						nameMiddle: data.nameMiddle,
						nameFamily: data.nameFamily,
						dateOfBirth: data.dateOfBirth ? this.$moment(data.dateOfBirth).format('DD/MM/YYYY') : null,
						assumedDateOfBirth: data.assumedDateOfBirth,
						genderId: data.genderId,
						landlineId: landline?.id,
						landline: landline?.identity,
						mobileId: mobile?.id,
						mobile: mobile?.identity,
						emailId: email?.id,
						email: email?.identity,
						estimatedRetirementAge,
						age: data.assumedDateOfBirth ? this.$moment().diff(data.dateOfBirth, 'years') : null,
						hasFactFind: index === 0 && !!data.factFindId
					};

					this.setClient({ data: mapped, dataIndex: index });

					if (index === 0) this.fetchClientList(1, false, autoSelectAssociation);

					// wait for validation to prevent flicker
					await new Promise((resolve) => setTimeout(resolve, 20));
					this.config[index].clientLoading = false;

					return data;
				} catch (error) {
					this.config[index].clientLoading = false;
					ElementTools.fireNotification(this.$el, 'error', 'Failed to load client details');
				}
			},

			/**
			 * @name clientToggleChanged
			 * @description Button toggle change handler
			 * @param {Number} index Index of the applicant
			 */
			clientToggleChanged(index) {
				this.config[index].mandatory = true;
				this.config[index].selectedClient = null;

				if (!isEmpty(this.clients[index]))
					this.setClient({
						data: {},
						dataIndex: index
					});

				if (index === 0 && !isEmpty(this.clients[1])) this.removeClient(1);

				if (this.config[index].clientToggle === 0) {
					this.openDynamicFormDialog(this.form, {}, 'Add Applicant', 'Applicant Details', 'setClient', 'add', index);
				}
			},

			/**
			 * @name addClient
			 * @description Add additional applicant. Creates an empty object for the additional applicant in applicants array
			 */
			addClient() {
				if (this.association && this.config[1].clientList.length === 1) this.fetchClientList(1, true);
				else this.setClient({ data: {}, dataIndex: 1 });
			},

			/**
			 * @name setClient
			 * @param {Object} client
			 */
			setClient(client) {
				let { data, dataIndex, config } = client;

				if (config?.type === 'add' && dataIndex === 0) {
					this.association = false;
				}

				this.$emit('set-client', { dataIndex, data, type: config?.type });

				if (config && config.type === 'add') ElementTools.fireNotification(this.$el, 'success', 'Applicant has been successfully added');
				else if (config && config.type === 'update') ElementTools.fireNotification(this.$el, 'success', 'Applicant has been successfully updated');
			},

			/**
			 * @name requestRemoveClient
			 * @description Request the removal of a selected applicant.
			 * @param {Number} index Index of the applicant
			 */
			requestRemoveClient(index) {
				this.$refs.confirm
					.open('Remove Applicant', 'Are you sure you wish to remove this Applicant?')
					.then(() => {
						this.removeClient(index);
					})
					.catch(() => {});
			},

			/**
			 * @name removeClient
			 * @description Remove selected applicant.
			 * @param {Number} index Index of the applicant
			 */
			removeClient(index) {
				if (index === 0) {
					this.setClient({
						data: {},
						dataIndex: 0
					});

					this.resetConfig(0);
				}

				this.$emit('remove-client', 1);
				this.resetConfig(1);
				this.clients.splice(1, 1);
			},

			/**
			 * @name showAddButton
			 * @description Condition check for showing or hiding add applicant button
			 * @param {Number} index Index of the applicant
			 */
			showAddButton(index) {
				return !this.editOnly && this.clients.length !== 2 && index === 0;
			},

			/**
			 * @name resetConfig
			 * @description Resets the config to initial state for the applicant
			 * @param index Index of the applicant
			 */
			resetConfig(index) {
				let config = this.config[index];

				config.clientToggle = null;
				config.mandatory = false;
				config.selectedClient = null;
				config.isLoading = false;
				config.search = null;
				config.clientList = [];
			},

			/**
			 * @name dialogClosed
			 * @description Dynamic form dialog close without submitting event handler
			 * @param {Object} dialogConfig Config that passed into dialog in open method. This is for getting the index
			 */
			dialogClosed(dialogConfig) {
				if (this.config[dialogConfig.dataIndex].clientToggle === 1) return;

				this.config[dialogConfig.dataIndex].mandatory = false;
				this.config[dialogConfig.dataIndex].clientToggle = null;
			},

			/**
			 * @name isEmpty
			 * @description Wrapper method for lodash isEmpty for using the method inside template
			 * @param {*} val Value will be checked
			 */
			isEmpty: (val) => isEmpty(val),

			/**
			 * @name validateForm
			 * @description Validates each applicant details form and sets result to store
			 */
			async validateForm() {
				let isValid = [];

				for (let index = 0; index < this.clients.length; index++) {
					let result = await this.$refs.dynamicFormDialog.validateForm(this.form, this.clients[index]);
					isValid.push(result);
				}
				this.isValid = isValid;
				this.$emit('set-validation', { applicant: !isValid.includes(false) });
			},

			/**
			 * @name getFullName
			 * @description Builds a full name with title for an item
			 * @param item Applicant data
			 * @return {String} Full name
			 */
			getFullName(client) {
				return `${this.getDisplayValuebyId(client, 'nameTitleId')} ${client.nameGiven} ${client.nameFamily}`;
			},

			/**
			 * @name getDisplayValuebyId
			 * @description Get display value of the data to displaying meaningful data to user instead of id
			 * @param {String} formName Form contains the field to be displayed
			 * @param {String} fieldName Name of field to be displayed
			 * @param {*} value Value of the field to be displayed
			 */
			getDisplayValuebyId(client, fieldName) {
				const field = this.form.find((field) => field.name === fieldName);

				return field ? field.options.find((option) => option.value == client[fieldName]).text : '';
			},

			/**
			 * @name openDynamicFormDialog
			 * @description Event for open dynamic form dialog
			 * @param {Array} formSchema Schema for creating form layout
			 * @param {Object} formData Data for populating form
			 * @param {String} title Dialog title
			 * @param {String} action Action to be dispached after form submission
			 * @param {Number} dataIndex Index of the data to be updated if data type is an array (Ex: Applicants)
			 */
			openDynamicFormDialog(formSchema, formData, submitButtonText, title, action, type, dataIndex) {
				this.$refs.dynamicFormDialog.open({
					formSchema,
					formData,
					submitButtonText,
					dataIndex,
					title,
					action,
					type
				});
			}
		}
	};
</script>
