/**
 * @namespace Utils
 * @class GoogleAnalytics
 * @exports Utils/GoogleAnalytics
 * @description Util for working with Google Analytics
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class GoogleAnalytics {
    static sendEvent(event, obj) {
        // Google Analytics event
        obj.event = event;
        window.dataLayer.push(obj);
    }
}
