<!--
	@name app-dashboard-onboarding
	@description Onboarding page
	@date 2020/10/22
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-row>
			<v-col class="py-0 pt-6 mb-10" cols="12" md="8" offset-md="2">
				<div>
					<p class="text-h4 font-weight-bold mb-4">
						Welcome to Air Group
					</p>

					<p class="body-1 font-weight-regular mb-8">
						<span class="font-weight-bold">Air Group is your trusted later life lending platform.</span>
						We have everything you need to maximise your potential in this growing market.
					</p>

					<v-alert v-show="false" type="info" color="success" class="pl-6 white mb-10" border="left" prominent colored-border>
						<v-row align="center">
							<v-col class="text-body-1 grow pa-0 px-6">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								quis nostrud exercitation ullamco laboris nisi ut.
							</v-col>
							<v-col v-if="canSkip" class="shrink py-1 px-6">
								<v-btn outlined color="grey" @click="skipOnboarding">
									Skip Tutorials
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>

					<p class="text-h5 font-weight-bold pt-3 mb-3">
						Tell us a little bit about yourself
					</p>
					<p class="text-body-1 mb-6">
						We want to ensure your Air Group membership gives you everything you need. The following questions will help us to get to know you a little better and mean
						we can tailor your membership to exactly what you need.
					</p>

					<v-card class="pt-2 px-10">
						<v-window v-model="activeQuestionIndex" :continuous="false" vertical>
							<v-window-item v-for="(question, index) in questions" :key="index" class="py-6">
								<v-card-text class="pa-0 text-left">
									<p class="text-h6 mb-5">
										<span class="text-body-1 font-weight-regular purple--text">Question {{ activeQuestionIndex + 1 }} of {{ questions.length }}: </span>
										<span class="font-weight-medium">{{ question.question }}</span>
									</p>

									<div v-if="question.type === 'item-group'">
										<v-item-group v-model="questionAnswers[activeQuestionIndex]" class="py-2" :multiple="question.multiple">
											<div class="d-flex mx-n2">
												<div v-for="(answer, index) in answerOptions" :key="index" class="flex-grow-1 flex-shrink-0 px-2" style="flex-basis: 0">
													<v-item v-slot="{ active, toggle }" :value="answer.title">
														<v-card
															:color="active ? 'black accent-3' : answer.color"
															class="d-flex flex align-center pa-3"
															:class="active ? 'elevation-0' : 'elevation-8'"
															dark
															min-height="120"
															style="height: 100%"
															@click="toggle"
														>
															<v-scroll-y-transition>
																<div class="title font-weight-regular flex-grow-1 text-center">
																	{{ answer.title }}
																</div>
															</v-scroll-y-transition>
														</v-card>
													</v-item>
												</div>
											</div>
										</v-item-group>

										<v-expand-transition>
											<div v-if="showAdditionalQuestion(question)">
												<v-text-field
													v-model="additionalQuestionAnswers[activeQuestionIndex]"
													class="pt-6"
													:label="question.additionalQuestion.question"
													hide-details
													outlined
												/>
											</div>
										</v-expand-transition>
									</div>

									<div v-if="question.type === 'slider'">
										<div v-for="(config, i) in question.config" :key="i">
											<v-slider
												v-model="sliderAnswers[i]"
												:step="config.step"
												:min="config.min"
												:max="config.max"
												ticks="always"
												tick-size="5"
												thumb-label="always"
												:thumb-size="28"
												hide-details
												class="mt-12"
												track-color="grey lighten-2"
												track-fill-color="success"
											>
												<template #prepend>
													<p class="text-body-1 ma-0" style="white-space: nowrap; width: 10rem">
														{{ config.label }}
													</p>
												</template>
												<template #thumb-label="{ value }">
													<span class="text-body-2">{{ getThumbValue(value, index, i) }}</span>
												</template>
											</v-slider>
										</div>
									</div>
								</v-card-text>
							</v-window-item>
						</v-window>

						<div class="d-flex pt-3 pb-6">
							<v-btn v-if="canSkip" color="grey" outlined @click="skipQuestions">
								Skip Questions
							</v-btn>

							<v-spacer />

							<v-btn v-show="activeQuestionIndex !== 0" color="primary" outlined class="mr-3" min-width="10rem" @click="previousQuestion">
								Previous
							</v-btn>

							<v-btn color="primary" min-width="13rem" :loading="loading" @click="nextQuestion">
								<span v-if="activeQuestionIndex !== questions.length - 1">Next</span>
								<span v-else>Finish</span>
							</v-btn>
						</div>
					</v-card>

					<v-fade-transition hide-on-leave>
						<div v-show="showNextStepQuestion" id="next-step-question" class="mt-5 pt-5">
							<p class="text-h5 font-weight-bold pt-3 mb-3">
								Let's get started!
							</p>

							<p class="text-body-1 mb-6">
								Thank you for telling us a little more about yourself. You can now take a look around and start maximising your later life lending potential with
								Air group.
							</p>

							<v-card class="px-10 pt-8 pb-6">
								<v-card-text class="pa-0">
									<p class="text-h6 font-weight-bold black--text mb-3">
										What would you like to do first?
									</p>

									<v-item-group v-model="nextStepQuestionAnswer">
										<v-container class="pa-0">
											<v-row>
												<v-col v-for="answer in nextStepQuestionAnswers" :key="answer.value" cols="12" md="6">
													<v-item v-slot="{ active, toggle }" :value="answer.value">
														<v-card
															:color="!active ? answer.class : '#111111'"
															:elevation="active ? 0 : 5"
															class="d-flex align-center pa-6"
															dark
															height="180"
															@click="toggle"
														>
															<v-scroll-y-transition>
																<div class="flex-grow-1 text-center">
																	<p class="text-h6 mb-2">
																		{{ answer.text }}
																	</p>
																	<p class="text-subtitle-2 font-weight-light ma-0">
																		{{ answer.copy }}
																	</p>
																</div>
															</v-scroll-y-transition>
														</v-card>
													</v-item>
												</v-col>
											</v-row>
										</v-container>
									</v-item-group>

									<div class="text-center mt-6">
										<v-btn color="grey" outlined class="mb-0 mx-3" large min-width="20rem" @click="skipOnboarding">
											GO TO DASHBOARD
										</v-btn>
										<v-fade-transition hide-on-leave>
											<v-btn v-if="nextStepQuestionAnswer" color="primary" class="mb-0 mx-3 pulse--primary" large min-width="15rem" @click="submit">
												get started!
											</v-btn>
										</v-fade-transition>
									</div>
								</v-card-text>
							</v-card>
						</div>
					</v-fade-transition>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

	export default {
		name: 'app-dashboard-onboarding',

		data() {
			return {
				activeQuestionIndex: 0,
				questions: [
					{
						question: 'What brings you to Air Group?',
						type: 'item-group',
						answers: [
							{
								title: 'Sourcing equity release',
								color: 'green'
							},
							{
								title: 'Air Club',
								color: 'purple lighten-2'
							},
							{
								title: 'Air Academy',
								color: 'pink darken-2'
							},
							{
								title: 'Sourcing RIOs',
								color: 'blue darken-3'
							},
							{
								title: 'Other',
								color: 'warning  '
							}
						],
						airAcademyAnswers: [
							{
								title: 'Air Academy',
								color: 'pink darken-2'
							},
							{
								title: 'Sourcing equity release',
								color: 'green'
							},
							{
								title: 'Air Club',
								color: 'purple lighten-2'
							},
							{
								title: 'Sourcing RIOs',
								color: 'blue darken-32'
							},
							{
								title: 'Other',
								color: 'warning  '
							}
						],
						additionalQuestion: {
							question: 'Other; Please provide details',
							condition: 'Other'
						},
						multiple: true
					},
					{
						question: 'How long have you been in the later life lending market?',
						type: 'item-group',
						answers: [
							{
								title: 'Less than a year',
								color: 'blue'
							},
							{
								title: '1-5 years',
								color: 'cyan'
							},
							{
								title: '6 - 10 years',
								color: 'teal'
							},
							{
								title: '10+ years',
								color: 'success'
							}
						]
					},
					{
						question: 'How many later life lending cases do you typically write each year?',
						type: 'slider',
						config: [
							{ label: 'Equity release', min: 0, max: 10, step: 1, valueSuffix: { sliderValue: 10, suffix: '+' } },
							{ label: 'Others (inc RIOs)', min: 0, max: 10, step: 1, valueSuffix: { sliderValue: 10, suffix: '+' } }
						]
					}
				],
				questionAnswers: [],
				sliderAnswers: [],
				nextStepQuestion: {
					question: 'What do you want to do next',
					answers: [
						{
							id: 1,
							text: 'Explore Air Academy',
							copy: 'Air Academy is the leading training programme for advisers in the market. Work through 7 modules at your own pace to achieve Professional Status and boost your advice offering to clients.',
							value: 'academy',
							class: 'pink darken-2'
						},
						{
							id: 2,
							text: 'Source a product',
							copy: 'Get started straight away with Air Sourcing, the leading product sourcing platform in the market.',
							value: 'source',
							class: 'green'
						},
						{
							id: 3,
							text: 'Create a client record',
							copy: 'The client dashboard stores a full audit history for your Air Sourcing activity and provides quick access to product sourcing, fact find, notes, document storage and a range of ancillary products & services.',
							value: 'clients',
							class: 'blue darken-3'
						},
						{
							id: 4,
							text: 'Set up my firm account',
							copy: 'A firm account gives you greater control. You can manage all accounts associated with your firm to ensure one version of the truth. You can add, edit or even remove an account within your firm',
							value: 'firm',
							class: 'warning'
						},
						{
							id: 5,
							text: 'Explore Air Club',
							copy: 'Air Club gives you access to exclusive products and deals, enhanced procuration fees, support materials and access to our dedicated, knowledgeable support team',
							value: 'club',
							class: 'purple lighten-2'
						}
					]
				},
				nextStepQuestionAnswer: null,
				showNextStepQuestion: false,
				additionalQuestionAnswers: [],
				loading: false,
				dataInputComplete: false
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapGetters('Account', ['membership', 'accountOwnershipStatus']),

			hasPendingAcademyMembership() {
				return this.membership('academy').request && !this.membership('academy').approve;
			},

			/**
			 * @name canSkip
			 * @description Can the user skip the onboarding process
			 */
			canSkip() {
				// User cannot skip if this is a firm invite / only status owner can skip
				return this.accountOwnershipStatus === 1;
			},

			nextStepQuestionAnswers() {
				return this.nextStepQuestion.answers.filter((item) => this.nextStepQuestionAnswersToBeShown.includes(item.id));
			},

			answerOptions() {
				return this.hasPendingAcademyMembership && this.questions[this.activeQuestionIndex].airAcademyAnswers
					? this.questions[this.activeQuestionIndex].airAcademyAnswers
					: this.questions[this.activeQuestionIndex].answers;
			},

			nextStepQuestionAnswersToBeShown() {
				if (this.hasPendingAcademyMembership) return [1, 2, 3, 5];
				return [2, 3, 4, 5];
			}
		},

		created() {
			// TODO: Put questionAnswers in slider model or merge sliderAnswers with questionAnswers on submit
			// this.questions.map((question, index) => {
			// 	if (question.type === 'slider') {
			// 		this.questionAnswers[index] = {};
			// 	}
			// });
		},

		methods: {
			...mapActions('AccountUser', ['modifyUser']),
			...mapMutations('Account', ['setUser']),

			/**
			 * @name skipQuestions
			 * @description Set user onboarding status
			 */
			async skipQuestions() {
				this.showNextStepQuestion = true;
				this.$nextTick(() => this.scrollTo('#next-step-question'));
			},

			/**
			 * @name nextQuestion
			 * @description Goes to next question
			 */
			async nextQuestion() {
				if (this.activeQuestionIndex !== this.questions.length - 1) {
					this.activeQuestionIndex++;
					return null;
				}

				// Construct our RegistrationReasons, placing the other text appended to Other if present
				let RegistrationReason = this.questionAnswers.length && this.questionAnswers[0] ? this.questionAnswers[0] : [];
				if (this.additionalQuestionAnswers[0]) RegistrationReason[RegistrationReason.length - 1] += ' : ' + this.additionalQuestionAnswers[0];

				this.loading = true;
				this.modifyUser({
					id: this.user.id,
					payload: {
						data: {
							onboarding: {
								status: true,
								registrationReasons: RegistrationReason,
								timeInIndustry: this.questionAnswers.length > 1 ? this.questionAnswers[1] : null,
								equityReleaseCasesPerYear: this.sliderAnswers.length && this.sliderAnswers[0] ? this.sliderAnswers[0] : 0,
								numberOfOtherCasesPerYear: this.sliderAnswers.length > 1 ? this.sliderAnswers[1] : 0
							}
						}
					}
				}).then(() => {
					this.loading = false;
					this.dataInputComplete = true;
					this.showNextStepQuestion = true;
					this.$nextTick(() => this.scrollTo('#next-step-question'));
				});
			},

			/**
			 * @name previousQuestion
			 * @description Goes to previous question
			 */
			previousQuestion() {
				this.activeQuestionIndex--;
			},

			/**
			 * @name scrollTo
			 * @description scrolls page to the specified element
			 * @param {String} elementId
			 */
			scrollTo(elementId) {
				this.$vuetify.goTo(elementId, {
					duration: 500,
					offset: 0,
					easing: 'easeOutCubic'
				});
			},

			/**
			 * @name submit
			 * @description submits form and redirects user to the selected section
			 */
			submit() {
				this.setUser({ onboarded: true });
				this.$router.push(`/${this.nextStepQuestionAnswer}`);
			},

			/**
			 * @name skipOnboarding
			 * @description Set user onboarding status
			 */
			async skipOnboarding() {
				this.setUser({ onboarded: true });
				window.scrollTo(0, 0);
			},

			/**
			 * @name getThumbValue
			 * @description slider thumb value
			 * @param {Number} sliderValue
			 * @param {Number} questionIndex
			 * @param {Number} sliderIndex
			 */
			getThumbValue(sliderValue, questionIndex, sliderIndex) {
				let config = this.questions[questionIndex].config[sliderIndex];

				if (config.valueSuffix && config.valueSuffix.sliderValue === sliderValue) return `${sliderValue}${config.valueSuffix.suffix}`;
				return sliderValue;
			},

			/**
			 * @name showAdditionalQuestion
			 * @description show an additional question if applicable
			 * @param {Object} question
			 */
			showAdditionalQuestion(question) {
				return (
					question.additionalQuestion &&
					(question.additionalQuestion.condition == this.questionAnswers[this.activeQuestionIndex] ||
						(this.questionAnswers[this.activeQuestionIndex] && this.questionAnswers[this.activeQuestionIndex].includes(question.additionalQuestion.condition)))
				);
			}
		}
	};
</script>
