<!--
	@name app-air-academy-cost
	@description Partner tab view for the air academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="pa-6">
		<p class="text-h5 font-weight-bold mb-0">
			Cost
		</p>
		<p class="text-body-1 mt-2 mb-0">
			Air Academy has been training advisers since 2014 and provides a personal and business-focused learning platform designed to aid advisers' continuous professional development.
			Underpinned by leading technology and knowledgeable experts, it provides advisers with the highest quality advice, support and content to enable their business to go further and provide their clients with holistic advice.
		</p>
		<p class="text-body-1 font-weight-bold mt-6 mb-0">
			There are three ways to engage with the Academy:
		</p>
		<ul class="text-body-1 mt-3 mb-0">
			<li class="mb-2">
				<strong>Air Academy Essentials:</strong> Free to Air members, Air Academy Essentials gives you access to systems tools and training, as well as some CPD materials and the ability to book onto our live events. There is also taster content for the LIBF-accredited programmes and a basic CPD log.
			</li>
			<li class="mb-2">
				<strong>Air Academy Premium:</strong> This is a monthly subscription which grants access to Air's LIBF-accredited Adviser Accreditation for Later Life Lending programme. Completion of this grants your 'Professional Status' and this opens Ambassador offers and procuration fee enhancements. You also have access to premium CPD content and our premium CPD log functionality. <a @click="openRing('ringOne')">Click here</a> for more information on the LIBF-accredited programme.
			</li>
			<li class="mb-2">
				<strong>Later Life Lending Adviser Induction Programme:</strong> This is a one-off payment and enrols you onto our LIBF-accredited Later Life Lending Adviser Induction programme. This walks through the customer journey from an adviser's perspective and is a blended learning programme focusing on the practicalities involved in achieving good customer outcomes. <a @click="openRing('ringTwo')">Click here</a> for more information on the LIBF-accredited programme.
			</li>
		</ul>
		<p class="text-body-1 font-weight-bold mt-6 mb-0">
			See below for what's included in each option and the associated cost:
		</p>

		<div class="v-data-table table--styled elevation-3 mt-3">
			<table width="100%">
				<thead>
					<tr>
						<th class="text-left">
							Options
						</th>
						<th>
							Adviser Accreditation for Later Life Lending
						</th>
						<th>
							Later Life Lending Adviser Induction
						</th>
						<th>
							Systems & Technology Training
						</th>
						<th>
							Insights hub: on demand CPD
						</th>
						<th>
							Air Academy Events
						</th>
						<th>
							CPD Log
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-left">
							Air Academy Essentials
						</td>
						<td class="text-center">
							<v-icon color="grey" size="24">
								mdi-close
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="grey" size="24">
								mdi-close
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							Limited
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							Limited
						</td>
					</tr>
					<tr>
						<td class="text-left">
							Air Academy Premium
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="grey" size="24">
								mdi-close
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
					</tr>
					<tr>
						<td class="text-left">
							Later Life Lending Adviser Induction
						</td>
						<td class="text-center">
							<v-icon color="grey" size="24">
								mdi-close
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							<v-icon color="grey" size="24">
								mdi-close
							</v-icon>
						</td>
						<td class="text-center">
							Limited
						</td>
						<td class="text-center">
							<v-icon color="success" size="32">
								mdi-check-bold
							</v-icon>
						</td>
						<td class="text-center">
							Limited
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="v-data-table table--styled elevation-3 mt-6">
			<table width="100%">
				<thead>
					<tr>
						<th class="text-left">
							Option
						</th>
						<th class="text-left">
							Advance
						</th>
						<th class="text-left">
							Platinum
						</th>
						<th class="text-left">
							Elite
						</th>
						<th class="text-left">
							Select
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-left">
							Air Academy Essentials
						</td>
						<td>£0</td>
						<td>£0</td>
						<td>£0</td>
						<td>£0</td>
					</tr>
					<tr>
						<td class="text-left">
							Air Academy Premium (non-Professional)
						</td>
						<td>£50 p/m</td>
						<td>£50 p/m</td>
						<td>Contractual</td>
						<td>Contractual</td>
					</tr><tr>
						<td class="text-left">
							Air Academy Premium (Professional)
						</td>
						<td>£30 p/m</td>
						<td>£30 p/m</td>
						<td>Contractual</td>
						<td>Contractual</td>
					</tr><tr>
						<td class="text-left">
							Later Life Lending Adviser Induction
						</td>
						<td>£850</td>
						<td>£850</td>
						<td>£850</td>
						<td>£700</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';

	export default {
		name: 'app-air-academy-cost',

		components: {  },

		computed: {
		},

		created() {
		},

		methods: {
			openRing(ring) {
				EventBus.$emit('open-academy-ring', ring);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.table--styled {
		th, td {
			padding: 8px 16px;
		}
	}
</style>