<!--
	@name app-events
	@description events page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-container class="py-0 px-4" style="max-width: 800px">
			<app-events-event-details v-if="event" :event="event" :breadcrumbs="breadcrumbs" />
		</v-container>
	</div>
</template>
Ï
<script>
	import { mapActions, mapState } from 'vuex';
	import AppEventsEventDetails from '@/component/app/events/event-details';

	export default {
		name: 'app-events-event',

		components: {
			AppEventsEventDetails
		},

        data() {
			return {
				event: null,
				breadcrumbs: [{
					text: `Back to events`,
					disabled: false,
					exact: true,
					to: `/events`
				}]
			};
		},

		computed: {
			...mapState('CmsEvent', ['eventList'])
        },

		created() {
            this.loadEventsList().then(() => {
				this.$nextTick(() => {
					this.event = this.eventList.find((e) => e.id === this.$route.params.id);
					this.breadcrumbs.push({
						text: this.event.name,
						disabled: true
					});
				});
            });
        },

		methods: {
            ...mapActions('CmsEvent', ['loadEventsList'])
		}
	};
</script>
