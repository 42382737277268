<!--
	@name app-firm-webhooks
	@description Firm Webhookls
	@date 2024/07/16
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet class="pa-5">
			<v-skeleton-loader v-if="loading" type="list-item-three-line" />

			<validation-observer v-else v-slot="{ invalid, validate }">
				<v-chip-group v-model="selectedWebhooks" color="success" column multiple>
					<v-chip v-for="(webhook, index) in webhookList" :key="index" :value="webhook.id">
						{{ webhook.name }}
					</v-chip>
				</v-chip-group>
				<form class="mt-5" @submit.prevent="validate().then(submit)">
					<validation-provider
						v-for="webhook in webhookList"
						:key="webhook.id"
						v-slot="{ errors }"
						:vid="webhook.id"
						:name="`${webhook.name} URL`"
						:rules="{ required: selectedWebhooks.includes(webhook.id) }"
					>
						<v-text-field
							v-show="selectedWebhooks.includes(webhook.id)"
							v-model="formData[webhook.id]"
							:disabled="!selectedWebhooks.includes(webhook.id)"
							dense
							:label="`${webhook.name} URL`"
							outlined
							:placeholder="`${webhook.name} URL`"
							:error-messages="errors"
						/>
					</validation-provider>
					<v-btn color="primary" class="mx-auto d-block" :disabled="invalid" :loading="submitting" type="submit">
						Submit
					</v-btn>
				</form>
			</validation-observer>
		</v-sheet>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-firm-webhooks',

		components: { ValidationProvider, ValidationObserver },

		props: {},

		data() {
			return {
				loading: false,
				loadingApiCredentials: false,
				submitting: false,
				formData: {},
				webhookList: [],
				selectedWebhooks: []
			};
		},

		computed: {
			...mapState('Account', ['organisation'])
		},

		created() {
			this.setFormData();
		},

		methods: {
			...mapActions('Option', ['getWebhookList']),
			...mapActions('AccountOrganisationWebhook', ['loadOrganisationWebhooks', 'addOrganisationWebhooks']),

			async setFormData() {
				this.loading = true;
				const requests = [this.getWebhookList(), this.loadOrganisationWebhooks(this.organisation.id)];
				Promise.all(requests).then((responses) => {
					this.webhookList = responses[0].data;
					this.webhookList.forEach((webhook) => this.$set(this.formData, webhook.id, null));
					this.selectedWebhooks = responses[1].data.map((i) => i.webhookId);
					responses[1].data.forEach((i) => this.$set(this.formData, i.webhookId, i.url));
				}).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', `An error occured`)
				}).finally(() => {
					this.loading = false;
				});
			},

			/**
			 * @name submit
			 * @description Triggered on submit to create or update organisation webhooks
			 * @param {Object} payload
			 */
			async submit() {
				this.submitting = true;

				try {
					let payload = [];
					for (const [id, url] of Object.entries(this.formData)) {
						if (this.selectedWebhooks.includes(id)) payload.push({ webhookId: id, url })
					}
					await this.addOrganisationWebhooks({ id: this.organisation.id, payload });
					ElementTools.fireNotification(this.$el, 'success', `Your firm Webhooks have been successfully updated`)
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to updated firm webhooks');
				}

				this.submitting = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
