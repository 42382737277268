<!--
	@name common-base-address-search
	@description Address search
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-autocomplete
			ref="addressSearch"
			v-model="selectedAddress"
			:label="label"
			:items="mappedItems"
			:search-input.sync="search"
			:hide-details="hideDetails"
			:error-messages="errorMessages"
			:hide-selected="false"
			hide-no-data
			:outlined="outlined"
			:dense="dense"
			:loading="loading"
			item-value="Id"
			item-text="fullText"
			autocomplete="new-password"
			no-filter
			return-object
			@input="onAddressSelected"
		>
			<template #item="data">
				<v-list-item-content>
					<v-list-item-title>
						{{ data.item.Text }}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{ data.item.Description }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
	import { ApiAddress } from '@/service';
	import { debounce } from 'lodash';

	export default {
		name: 'common-base-address-search',

		props: {
			model: {
				type: Object,
				default: null
			},

			label: {
				type: String,
				default: 'Address Search'
			},

			disabled: {
				type: Boolean,
				default: false
			},

			outlined: {
				type: Boolean,
				default: true
			},

			dense: {
				type: Boolean,
				default: true
			},

			hideDetails: {
				type: Boolean,
				default: true
			},

			errorMessages: {
				type: Array,
				default: () => []
			},

			value: {
				type: String,
				default: ''
			},

			countryOptions: {
				type: Array,
				required: true
			}
		},

		data() {
			return {
				loading: false,
				selectedAddress: null,
				items: [],
				search: null,
				preventSearch: false
			};
		},

		computed: {
			/**
			 * @name mappedItems
			 * @description Mapping items to put additional computed properties
			 * @returns {Array} Items
			 */
			mappedItems() {
				return this.items.map((item) => {
					item.fullText = `${item.Text} - ${item.Description}`;
					return item;
				});
			}
		},

		watch: {
			/**
			 * @name search
			 * @description Watcher for search input
			 * @param {String} searchText Search value
			 */
			search(searchText) {
				if (this.preventSearch) {
					this.preventSearch = false;
					return;
				}

				if (searchText && searchText.length > 0) {
					this.searchAddress(searchText);
				} else {
					this.selectedAddress = null;
					this.items = [];
				}
			},

			/**
			 * @name value
			 * @description Watcher for value
			 */
			value(value) {
				if (!value) this.selectedAddress = null;
			}
		},

		methods: {
			onAddressSelected() {
				let address = this.selectedAddress;
				if (!address) return;

				this.preventSearch = true;
				if (address.Type === 'Address') this.retrieveAddressDetails(address.Id);
				else this.searchAddressByContainer(address.Id);
			},
			/**
			 * @name searchAddress
			 * @description Send request to API
			 * @param {String} searchText Selected address
			 */
			searchAddress: debounce(async function(searchText) {
				if (this.search !== searchText) return;

				this.loading = true;
				let response = await ApiAddress.find(searchText);
				this.loading = false;

				if (response.data && this.search === searchText) {
					this.items = response.data.Items;
				}
			}, 500),

			async searchAddressByContainer(addressId) {
				let response = await ApiAddress.find('', addressId);

				if (response.data) {
					this.items = response.data.Items;
					this.$refs.addressSearch.isMenuActive = true;
				}
			},

			async retrieveAddressDetails(addressId) {
				let response = await ApiAddress.retrieve(addressId);

				if (!response.data || !response.data.Items || response.data.Items[0].Error) return;

				let addressDetails = response.data.Items[0];

				if (this.model) {
					this.$set(this.model, 'property_search', this.selectedAddress.fullText);
					this.$set(this.model, 'address1', addressDetails.Line1);
					this.$set(this.model, 'address2', `${addressDetails.Line2} ${addressDetails.Line3} ${addressDetails.Line4}`.trim());
					this.$set(this.model, 'townCity', addressDetails.City);
					this.$set(this.model, 'county', addressDetails.Province);
					this.$set(this.model, 'postcode', addressDetails.PostalCode);
					this.$set(this.model, 'countryId', this.countryOptions.find((country) => country.nameUnique === addressDetails.Field1.toLowerCase().replaceAll(' ', '_'))?.value);
				}

				this.$emit('address-retrieved', addressDetails);
			}
		}
	};
</script>
