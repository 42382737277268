import { $http } from '@/utils';

/**
 * @namespace Service
 * @exports Service/System /Version
 * @description Version Service
 * @date 2024/09/25
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name version
	 * @description Perform a version check by hitting version log
	 * @returns {Promise} Response
	 */
	getVersion: () => {
		return $http.get(`${process.env.VUE_APP_VERSION_URL}/version.json`, { params: { ts: new Date().getTime() }, cache: false });
	}
};
