<!--
	@name app-source-result-mortgage-item-equivalent
	@description Lifetime mortgage product item annual equivalent rate
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div>
			<div v-if="item.annualEquivalentRate">
				<span class="text-body-2 font-weight-medium">
					{{ item.annualEquivalentRate | numFormat('0.00') }}%
				</span>
				<span v-if="item.annualEquivalentRate !== item.monthlyRate" v-show="item.monthlyRate" class="text-caption nowrap">
					{{ item.monthlyRate }}% MER
				</span>
				<v-tooltip v-if="interestRateTooltip" bottom>
					<template #activator="{ on }">
						<span class="text-caption text-decoration-underline" v-on="on">
							{{ item.interestRateTypeName }}
						</span>
					</template>
					<span>
						{{ interestRateTooltip }}
					</span>
				</v-tooltip>
				<span v-else class="text-caption">
					{{ item.interestRateTypeName }}
				</span>
			</div>
			<div v-else-if="!item.restriction" class="nowrap">
				--
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-equivalent',

		props: {
			item: { type: Object, required: true }
		},

		data() {
			return {};
		},

		computed: {
			/**
			 * @name interestRateTooltip
			 * @description TODO
			 * @return {Mixed} TODO
			 */
			interestRateTooltip() {
				if (!this.item.interestRateTypeIsInitial) return false;

				return this.item.interestRateInitialFixedTerm && this.item.fallbackInterestRate
					? `Initial term of ${this.item.interestRateInitialFixedTerm} years, at which point the rate will revert to ${this.item.fallbackInterestRate}%`
					: 'Initial rate term/fallback rate not provided. TTP calculated on initial rate over full term';
			}
		},

		methods: {}
	};
</script>
