<!--
	@name app-source-section-health-details
	@description Health details section
	@date 2020/11/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex mb-6">
		<common-structure-section v-if="form.length" class="flex-grow-1 elevation-2">
			<template #header>
				Health
			</template>

			<template #body>
				<div>
					<p class="font-weight-bold">
						Although not required to source, to retrieve Health Enhanced Quotes, you'll need to complete the following question set
					</p>

					<v-alert v-if="!loading" dense text border="left" :type="isValid ? 'success' : alertTypeNotValid" class="mt-4 mb-0">
						<v-row align="center" class="px-3">
							<v-col v-if="isValid" class="grow pa-0 text-subtitle-2">
								You have entered the required details for the Health Questionnaire
							</v-col>
							<v-col v-else class="grow pa-0 text-subtitle-2">
								We require further information in order to submit the Health Questionnaire
							</v-col>

							<v-col class="shrink pa-0 ml-2">
								<v-btn small dark @click="openDynamicFormDialog(form, health, 'Update Health Details', 'Health Details', 'setHealth')">
									<span> Update Details </span>
								</v-btn>
							</v-col>

							<v-col class="shrink pa-0 ml-2">
								<v-btn v-show="showRemoveButton" :loading="removeLoading" small light color="error" @click="confirmRemoveHealthData">
									<span> Remove </span>
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>

					<v-skeleton-loader v-else class="mx-n4" type="list-item-three-line" />
				</div>
			</template>
		</common-structure-section>

		<v-card v-else class="flex-grow-1">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="dynamicFormSubmit" />
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { camelCase } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';

	export default {
		name: 'app-source-section-health-details',

		components: {
			CommonStructureSection,
			CommonDialogConfirm,
			DynamicFormDialog
		},

		props: {
			submitFailed: { type: Boolean, required: true },
			form: { type: Array, required: true, default: () => [] },
			applicants: { type: Array, required: true },
			health: { type: Object, required: true }
		},

		data() {
			return {
				isValid: false,
				loading: false,
				removeLoading: false
			};
		},

		computed: {
			alertTypeNotValid() {
				return this.isHealthFormEmpty ? 'warning' : 'error';
			},

			isHealthFormEmpty() {
				return !Object.values(this.health).some((val) => (val !== null && !Array.isArray(val)) || (Array.isArray(val) && val.length));
			},

			showRemoveButton() {
				return Object.keys(this.health).length > 0;
			}
		},

		methods: {
			...mapActions('AppClient', ['removeClientHealth']),
			...mapActions('AppClientHealth', ['loadClientHealthList']),

			/**
			 * @name validateForm
			 * @description Validates item details form and sets result to store
			 */
			async validateForm() {
				this.isValid = await this.$refs.dynamicFormDialog.validateForm(this.form, this.health);
				this.$emit('set-validation', { health: this.isHealthFormEmpty || this.isValid });
			},

			/**
			 * @name fetchHealth
			 * @description fetch client's health data
			 * @param {Number} index Index of the applicant
			 */
			async fetchHealth(index, clientId) {
				this.loading = true;
				const clientHealth = await this.loadClientHealthList(clientId);
				this.loading = false;

				this.setHealthData(clientHealth.data, index);
			},

			setHealthData(clientHealth, index) {
				if (!clientHealth?.length) {
					this.validateForm();
					return;
				}

				let health = {};
				health[`${index}.healthConditions`] = [];

				clientHealth.map((item) => {
					if (item.type === 'condition' || item.type === 'event' || item.type === 'treatment') {
						health[`${index}.healthConditions`].push(item.id);
						let dataKey = Object.keys(item.data || []);
						if (!dataKey || !dataKey.length) return;
						dataKey.map((key) => {
							let healthKey = camelCase(item.nameUnique) + key.charAt(0).toUpperCase() + key.slice(1);
							health[`${index}.${healthKey}`] = item.data[key];
						});
					} else if (item.type === 'height' || item.type == 'weight') {
						Object.keys(item.data).map((key) => {
							let healthKey = item.nameUnique + key.charAt(0).toUpperCase() + key.slice(1);
							health[`${index}.${healthKey}`] = item.data[key];
						});
					} else {
						Object.keys(item.data).map((key) => {
							health[`${index}.${key}`] = item.data[key];
						});
					}
				});

				Object.keys(this.health).map((key) => {
					if (!key.includes(index + '.')) health[key] = this.health[key];
				});

				this.setHealth({ data: health });
			},

			/**
			 * @name setHealth
			 * @param {Object} data
			 */
			setHealth({ data }) {
				this.$emit('set-health', data);
			},

			/**
			 * @name confirmRemoveHealthData
			 * @description delete client's health data
			 */
			confirmRemoveHealthData() {
				this.$refs.confirm
					.open(
						'Remove Health Details',
						`Are you sure you wish to remove health details for the selected applicant${
							this.applicants.length > 1 ? 's' : ''
						}? Please note: this is a permanent action and will immediately remove all health data for the selected client${this.applicants.length > 1 ? 's' : ''}.`
					)
					.then(() => this.removeHealthData())
					.catch(() => {});
			},

			/**
			 * @name removeClientHealth
			 * @description delete client's health data
			 */
			removeHealthData() {
				let requests = [];
				this.applicants.forEach((applicant) => {
					if (applicant.id) requests.push(this.removeClientHealth(applicant.id));
				});

				if (requests.length) {
					this.removeLoading = true;
					Promise.all(requests)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', 'Health details have been successfully removed.');
							this.$emit('set-health', {});
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'success', 'Failed to remove health details.'))
						.finally(() => (this.removeLoading = false));
				} else {
					ElementTools.fireNotification(this.$el, 'success', 'Health details have been successfully removed.');
					this.$emit('set-health', {});
				}
			},

			/**
			 * @name openDynamicFormDialog
			 * @description Event for open dynamic form dialog
			 * @param {Array} formSchema Schema for creating form layout
			 * @param {Object} formData Data for populating form
			 * @param {String} title Dialog title
			 * @param {String} action Action to be dispached after form submission
			 */
			openDynamicFormDialog(formSchema, formData, submitButtonText, title, action) {
				this.$refs.dynamicFormDialog.open({
					formSchema,
					formData,
					submitButtonText,
					title,
					action
				});
			},

			/**
			 * @name dynamicFormSubmit
			 * @param {Object} data
			 */
			dynamicFormSubmit(data) {
				this.setHealth(data);
				ElementTools.fireNotification(this.$el, 'success', 'Health details have been successfully updated');
			}
		}
	};
</script>
