<!--
	@name app-client-service-care-loans
	@description Loans Service
	@date 2020/07/16
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<app-client-service-template ref="serviceTemplate" :has-referral="false">
			<template slot="header">
				<!-- :url="Object.keys(documents).length ? documents.theRightLoan : ''" -->
				<app-client-service-header :header="'Loans'" :subheader="'The Right Loan'" :link="'Download Brochure'" :url="brochureUrl" :logo="'therightloan.png'" />
			</template>
			<template slot="copy">
				<p class="text-body-1 font-weight-bold">
					Having worked in the secured loan and bridging finance sector for years, we have helped thousands of clients find the right loan for their specific needs.
				</p>
				<p class="text-body-2">
					We analyse each customer's individual situation to ensure we are providing quality advice and recommendations that is tailored to their personal circumstances,
					so that you know your customers are in good hands. Plus we take care of all of the advice and compliance requirements.
				</p>
				<p class="text-body-2">
					Do you have clients who have found that equity release is not the right choice for them?
				</p>
				<p class="text-body-2">
					We can help by providing a short term loan to bridge the gap, helping with scenarios such as:
				</p>
				<ul>
					<li>
						Those who are buying and selling a property via a lifetime mortgage, but have found the purchase has fallen through as a result of a chain break, or an
						issue with the lender;
					</li>
					<li>Applicants who are looking to carry out home improvements or have started home improvements projects such as extensions;</li>
					<li>Those who are looking to take out a lifetime mortgage, but have learned the property they're looking at is not suitable at the point of application.</li>
				</ul>
				<p class="text-body-2 mt-4">
					Not only do we always aim to offer our introducers, and their customers, an excellent service each time, we're also continuously maintaining and building
					relationships with lenders and our referral partners, allowing us to provide whole of market advice in regards to second charge loans and bridging finance, and
					further fine tune the service we provide.
				</p>
				<p class="text-body-2">
					Refer your clients to The Right Loan today and enjoy access to the below product criteria via the specialist lenders on our panel:
				</p>
				<ul>
					<li>No maximum age;</li>
					<li>Rates as low as 0.49%;</li>
					<li>LTVs up to 65% (gross);</li>
					<li>Loans from £15,000;</li>
					<li>Age restricted properties accepted;</li>
					<li>No monthly payments or affordability calculations to be carried out;</li>
					<li>All credit profiles considered.</li>
				</ul>

				<p class="text-body-1 mt-10 mb-0 font-weight-bold">
					Here are further reasons you should work with us:
				</p>

				<v-row>
					<v-col v-for="(card, index) in cards" :key="index" cols="4">
						<v-card class="text-center px-6 py-3" style="height: 100%">
							<v-icon :color="card.color" class="mt-4" size="60">
								{{ card.icon }}
							</v-icon>
							<v-card-text class="text--primary">
								{{ card.text }}
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<p class="text-body-1 text-center mt-10 mb-0 font-weight-bold">
					But don't just take our word for it, here's what one of our customers have had to say about our service:
				</p>

				<blockquote class="text-h6 text-center grey--text font-weight-light mt-6" style="position: relative">
					<v-icon large style="position: absolute; top: -0.5rem; left: -0.5rem">
						mdi-format-quote-open
					</v-icon>
					The service at the Right Loan has been second to none, the process from referral to completion was as extremely efficient thus by completing in just under 4
					weeks. I would recommend them over and over again.
					<v-icon large style="position: absolute; bottom: -0.5rem; right: -0.5rem">
						mdi-format-quote-close
					</v-icon>
				</blockquote>

				<p class="text-h6 text-center font-weight-bold mb-2 mt-10">
					A bright, fresh approach to secured loans and bridging finance
				</p>

				<p class="text-body-1 text-center mb-2">
					<a :href="brochureUrl">Find out more about The Right Loan here.</a>
				</p>

				<p class="text-body-1 text-center">
					Call our team on 01564 791 118 or visit our website <a href="https://therightloan.org/" target="_blank">therightloan.org</a> to learn more.
				</p>

				<div class="d-flex flex-wrap mt-12">
					<v-card v-for="(product, index) in products" :key="index" class="flex-grow-1 elevation-3 pa-6" :class="index === 0 ? '' : 'ml-5'" outlined tile>
						<v-card-text class="text-center pa-0">
							<v-card-title class="text-h5 font-weight-bold d-block pb-5">
								{{ product.title }}
							</v-card-title>
						</v-card-text>
						<v-card-actions class="mb-2 justify-center">
							<v-btn v-for="(button, buttonIndex) in product.buttons" :key="buttonIndex" :color="button.color" :href="button.href" target="_blank">
								<v-icon v-if="button.icon" class="mr-1" small>
									{{ button.icon }}
								</v-icon>
								{{ button.title }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</div>
			</template>
		</app-client-service-template>
	</div>
</template>

<script>
	import AppClientServiceTemplate from '@/component/app/client/service/template';
	import AppClientServiceHeader from '@/component/app/client/service/header';

	export default {
		name: 'app-client-service-lpa',

		components: { AppClientServiceTemplate, AppClientServiceHeader },

		data() {
			return {
				products: [
					{
						title: 'Second Charge',
						buttons: [
							{
								title: 'Find out more',
								color: 'primary',
								icon: 'mdi-file-pdf-box',
								href: 'https://airsourcing-staging.co.uk/download/The%20Right%20Loan%20Working%20in%20Conjunction%20With%20The%20Air%20Group.pdf'
							},
							{ title: 'Instruction Form', color: 'info', href: 'https://therightloan.org/air-sourcing-secured-loan' }
						]
					},
					{
						title: 'Bridging Finance',
						buttons: [
							{
								title: 'Find out more',
								color: 'primary',
								icon: 'mdi-file-pdf-box',
								href: 'https://airsourcing-staging.co.uk/download/The%20Right%20Loan%20Working%20in%20Conjunction%20With%20The%20Air%20Group.pdf'
							},
							{ title: 'Instruction Form', color: 'info', href: 'https://therightloan.org/air-sourcing-bridging-finance' }
						]
					}
				],
				cards: [
					{
						icon: 'mdi-clock',
						color: 'primary',
						text: 'We offer quick completion timescales of 2-3 weeks'
					},
					{
						icon: 'mdi-briefcase',
						color: 'secondary',
						text: 'We take care of all the work, advice and compliance'
					},
					{
						icon: 'mdi-bell-ring',
						color: 'amber',
						text: 'Access an experienced helpdesk and receive regular case updates'
					}
				]
			};
		},

		computed: {
			brochureUrl() {
				return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/document/The+Right+Loan+Working+in+Conjunction+With+The+Air+Group.pdf`;
			}
		},

		methods: {
			open() {
				this.$refs.serviceTemplate.open();
			}
		}
	};
</script>
