<!--
	@name app-register-verify-email-address
	@description Email verification page
	@date 2020/12/02
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="site" to="/">
					<v-img class="mx-auto" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
				</router-link>
			</v-col>
		</v-row>

		<v-row class="mx-auto" style="max-width: 900px">
			<v-col cols="12" class="text-center">
				<template v-if="verified">
					<v-icon class="text-h2" color="success">
						mdi-checkbox-marked-circle-outline
					</v-icon>
					<v-card-title class="text-h6 font-weight-bold d-block">
						Thank you
					</v-card-title>
					<v-card-text class="text-body-1 px-10">
						You account has been verified. Please wait whilst we redirect you to the log in page...
					</v-card-text>
				</template>

				<template v-else-if="pending">
					<div v-if="verificationLinkSent">
						<v-icon class="text-h2" color="success">
							mdi-check-bold
						</v-icon>
						<v-card-title class="text-h6 font-weight-bold d-block">
							We have sent an email with a confirmation link to your email address.
						</v-card-title>
						<v-card-text class="text-body-1 px-10">
							Please allow 5-10 minutes for this message to arrive. Once received, please click on the <span class="font-weight-bold">Verify my email</span> link to
							verify your account and continue the registration process.
						</v-card-text>
					</div>
					<div v-else>
						<v-icon class="text-h2" color="error">
							mdi-exclamation
						</v-icon>
						<v-card-title class="text-h6 font-weight-bold d-block">
							Verify your email address
						</v-card-title>
						<v-card-text class="text-body-1 pb-0">
							An email has been sent to your registered email address for verification. If you have not received the message you can resend the verification email by
							clicking on the button below.
						</v-card-text>
						<v-card-actions>
							<v-btn color="primary" large :loading="loading" class="mt-5 mx-auto d-flex" @click="resendVerificationEmail">
								Resend Verification Email
							</v-btn>
						</v-card-actions>
					</div>
				</template>

				<template v-else-if="error">
					<div>
						<v-icon class="text-h2" color="error">
							mdi-exclamation
						</v-icon>
						<v-card-title class="text-h6 font-weight-bold d-block">
							An unexpected error occurred
						</v-card-title>
						<v-card-text class="text-body-1 px-10">
							An unexpected error occurred whilst attempting to verify your account. If you have previously used this verification link, your account will already be active, and you may <router-link to='/advisers'>proceed to log in</router-link>.
						</v-card-text>
						<v-card-text class="text-body-1 pt-0 px-10">
							For additional support, please contact our support team on {{ site.contact.number }}.
						</v-card-text>
					</div>
				</template>

				<template v-else>
					<v-skeleton-loader type="list-item-three-line" />
				</template>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-register-verify-email-address',

		data() {
			return {
				loading: false,
				verificationLinkSent: false,
				verified: false,
				error: false,
				pending: false,
				registrationData: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			token() {
				return this.$route.params.token;
			},

			currentRouteName() {
				return this.$route.name;
			}
		},

		created() {
			this.submitVerifyRegistration({ token: this.$route.params.token })
				.then(() => {
					this.verified = true;
					setTimeout(() => this.$router.push('/advisers'), 2500); // Redirect to login
				})
				.catch((error) => {
					if (error.status === 410) {
						this.pending = true;
						this.registrationData = error.data;
					} else {
						this.error = true;
					}
				});
		},

		methods: {
			...mapActions('AccountRegistrationVerify', ['submitVerifyRegistration']),
			...mapActions('AccountRegistrationRegenerate', ['submitRegenerateRegistration']),

			resendVerificationEmail() {
				this.loading = true;
				this.submitRegenerateRegistration({ token: this.token })
					.then(() => (this.verificationLinkSent = true))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An unexpected error occurred. If this problem continues, please contact our support team.'))
					.finally(() => (this.loading = false));
			}
		}
	};
</script>
