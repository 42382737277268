<!--
	@name app-common-privacy
	@description Privacy Notice
	@date 2020/06/03
	@license no license
	@copywrite {{ site.company.name }}
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Privacy Notice
		</template>
		<template v-if="site" #body>
			<div>
				<div>Please read in conjunction with our Terms and Conditions which provides more detailed information about our obligations relating to Personal Data</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />PART 1: Privacy Notice</strong>
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Who are we?</strong>
				</div>
				<div>
					<br />{{ site.softwareName }} is a trading style of {{ site.company.name }}, part of Key Group, including more2life and Standard Life Home Finance.
					{{ site.company.name }} is a limited company registered in England No 09991416 with its Head Office at {{ site.company.name }}, {{ site.contact.address }}
				</div>
				<div>
					In this privacy notice references to “we”, “us” and “our” are to {{ site.company.name }}. References to “our website” or “the website” are to
					{{ website_name }}.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />About this Privacy Notice</strong>
				</div>
				<div>This privacy notice explains how we collect, use and store any personal information we collect about you when you use the {{ site.softwareName }} website/s.</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Areas Covered:</strong>
				</div>
				<div>• Information we collect about you</div>
				<div>• How we use this information</div>
				<div>• Protecting your information</div>
				<div>• Marketing</div>
				<div>• Accessing and amending your information</div>
				<div>• Links to other websites</div>
				<div>• Social Media</div>
				<div>• Changes to our Privacy Notice</div>
				<div>• How to contact us</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Information we collect and how</strong>
				</div>
				<div>The information we collect may include;</div>
				<div>
					<p>
						Any personal details you knowingly provide us with through forms and our email, such as name, trading address, telephone number, FCA number, and email
						address.
					</p>
				</div>
				<ul>
					<li style="color: #000000">
						Your preferences and use of email updates, recorded by emails we send you (if you select to receive email updates on products and offers).
					</li>
					<li style="color: #000000">
						Your IP address and browser type. Your IP address is a string of numbers unique to your computer that is recorded by our web server when you request any
						page or component on the website. This information is used to monitor your usage of the website.
					</li>
				</ul>
				<div>
					We may supplement the information we collect from you with information from 3rd parties to assist with providing services to you - for example this may include
					additional contact information or geodemographic information.<br /><br />
				</div>
				<div>
					You will also be asked to create a Username and Password in order to log into your account and access the services of the website. These log in credentials will
					only be known to you and we will not hold a copy of your log in information. Please ensure that you keep your password secure and do not reveal it to anyone.<br /><br />
				</div>
				<div>
					If you believe your password has been compromised then please take action to change it immediately. In order to provide you with a service of requesting product
					illustrations on your behalf, the website can also store your log in information for product provider websites. This information is only stored in encrypted
					format on the website and can only be accessed by employees of {{ site.company.name }} where you ask us to request a product illustration on your behalf.<br /><br />
				</div>
				<div>
					In the “My Account” section of {{ site.softwareName }} you will be able to add, amend or delete any log in information for both {{ site.softwareName }} and
					Product Provider websites.<br /><br />
				</div>
				<div>
					We also collect information when you voluntarily complete customer surveys, provide feedback and participate in competitions. This information is held in a
					database stored on our secured server. Website usage information is also collected using cookies. More information can be found on Cookies later in this
					document. With your permission your information is shared within our group of Companies where additional services are required by you.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />How will we use the information about you?</strong>
				</div>
				<div>
					We collect information about you to provide you with the services of researching financial products, requesting product illustrations, assisting you in your
					product research, submitting applications and providing case tracking.
				</div>
				<div>
					<br />When you provide consent, we will also email you about all the products and services we think may be of interest to you from a wide range of product
					providers This may also involve using your details and information provided to make enquiries on your behalf with third parties such as product providers in
					order to assist you in helping you research financial products.
				</div>
				<div>We use your information collected from the website to personalise your repeat visits to our website.</div>
				<div>
					<br />We may pass on your personal information to Air group of companies so that they may offer you their products and services. Answers in Retirement Limited
					will not share your information for marketing purposes with companies outside the group unless we have your permission to do so.
				</div>
				<div>
					We may use your data to email you about selected products or services offered by us or the service providers and selected partners we work with. You may
					unsubscribe from email marketing communications at any time and all our communications with you will contain a clear link and instructions to unsubscribe.
				</div>
				<div>
					<br />Sensitive data such as bank details are stored securely and will only be shared within the group for accounting purposes. We will never write to you to
					obtain this sensitive information we will always contact you in person. Should we no longer require this information it will be permanently deleted from our
					systems.
				</div>
				<div>
					<br />We may also share your personal data with other members of the Key Group in order to run our business in an efficient manner including for corporate
					governance or audit purposes.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />How we use your data</strong><br />To provide you with the services you have requested<br />To manage our relationship with you<br />To provide
					you with the information about our products and services via post<br />To provide you with information about our products and services via email, phone and
					SMS<br />We use cookies to monitor website usage to improve our online services for you<br />To run our business in an efficient and proper way. This includes
					managing our financial position, business capability, planning communications, corporate governance and audit.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Lawful basis</strong><br />This is necessary for the performance of the contract to provide the services you have requested.<br />This is in our
					legitimate interest of keeping you informed of our available products and services and keeping our records up to date.<br />This is in our legitimate interests
					to keep you informed of our services. You may opt-out at any time.<br />This is in our legitimate interests to keep you informed of our services. You may
					opt-out at any time.<br />This is in our legitimate interests to improve our services. You may reject non-essential cookies at any visit to the website.<br />This
					is necessary to comply with the legal and regulatory requirements that apply to us. This may also be in our legitimate interest to apply governance duties
					efficiently.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Protecting your information</strong>
				</div>
				<div>
					We are committed to protecting the confidentiality and security of your information.&nbsp; As such any information collected via our website is encrypted and
					delivered to us through a secure environment and protected using robust security methods. {{ site.company.name }} maintains a comprehensive information security
					management system which ensures industry standard technical and administrative controls are in place for the protection of customer data.
				</div>
				<div>
					<br />Please note that there is no guarantee that any email sent will be received, or that the contents of any such email will remain private or unaltered
					during Internet transmission. If you have any such concerns, you may prefer to telephone or write to us instead. We accept no liability for any damages you or
					others may suffer as a result of the alteration or loss of confidentiality of any such information.
				</div>
				<div>
					<br />We reserve the right to monitor the use and content of emails which are sent from and received by us for the purposes of ensuring compliance with our own
					email policy, and identifying and taking action against unlawful or improper use of our systems, including, but not limited to, spoofing, the transmission of
					computer viruses and a denial of service attack.
				</div>

				<div>&nbsp;</div>
				<div>
					<strong><br />Marketing</strong>
				</div>
				<div>
					We would like to send you information about products and services of ours and other companies within the Key Group which may be of interest to you. This will
					ensure that you are kept up to date with all important product provider changes and other useful or relevant information.
				</div>
				<div>&nbsp;</div>
				<div>
					You have the right at any time to stop us from contacting you for marketing purposes (opt-out) . A link and instructions on how to unsubscribe from all
					marketing from our group will be provided in each marketing related message we send to you.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Your Rights</strong>
				</div>
				<div>You have the right to:</div>
				<div>• request a copy of any information that we currently hold about you and ask us to correct or delete this.</div>
				<div>• restrict or object to processing of your information.</div>
				<div><br />Where you have provided consent for us to process your data, you have the right to withdraw the consent for the processing of that data.</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Social Media Platforms including Live Chat</strong>
				</div>
				<div>
					Communication, engagement and actions taken through external social media platforms that this website participates in are custom to the terms and conditions as
					well as the privacy policies held with each social media platform respectively. This website may use social sharing buttons which help share web content
					directly from web pages to the social media platform in question.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Shortened Links in Social Media</strong>
				</div>
				<div>
					We, through our social media platform accounts may share web links with you to relevant web pages. By default some social media platforms used a shortened URL
					(Uniform Resource Locator).
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />Changes to our privacy notice</strong>
				</div>
				<div>
					We keep our privacy notice under regular review and we will place any updates on this page. Where we update our privacy notice we will publish an announcement
					as such on the website. This privacy notice was last updated on 17 September 2024.
				</div>
				<div>&nbsp;</div>
				<div>
					<strong><br />How to contact us</strong>
				</div>
				<div>
					Please contact us if you have any questions or complaints about our privacy notice or information we hold about you by email to
					Privacy@airlaterlife.co.uk or write to us at the following address; {{ site.company.name }}, {{ site.contact.address }}. You can also contact us by telephone on
					{{ site.contact.number }}.<br /><br /><br />
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-privacy',

		components: { CommonDialog },

		data: () => ({
			website_name: `www.${window.location.hostname}`
		}),

		computed: { ...mapState('CmsSite', ['site']) },

		watch: {},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	table {
		border: 1px solid #888;
		width: 100%;
		td {
			border: 1px solid #888;
			padding: 8px;
		}
	}
</style>
