import { $http } from '@/utils';

/**
 * @namespace Floods
 * @class Floods
 * @exports Service/Floods
 * @description API Postcode Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Floods {
	/**
	 * @static @name post
	 * @description to generate a flood report
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('proxy/flood-report', payload);
	}

	/**
	 * @static @name post
	 * @description to generate a flood report pdf
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static pdf(payload) {
		return $http.post('proxy/flood-report/pdf', payload);
	}
}
