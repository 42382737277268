<template>
	<div style="position: relative; z-index: 1000">
		<v-app-bar color="white" :height="showDrawer ? 100 : 140" fixed elevate-on-scroll>
			<!-- <template #extension>
					<v-container fluid class="d-none yellow accent-3 ma-0 pa-0 h-100">
						<v-row no-gutters class="align-center h-100">
							<v-col class="text-center d-flex align-center justify-center">
								<span class="text-body-2 font-weight-regular">
									Are you ready for the
									<span class="font-weight-bold"> National Later Life Lending Adviser Conference 2024? </span>
								</span>
								<div class="countdown">
									<div class="countdown--number">
										9
									</div>
									<div class="countdown--number">
										9
									</div>
									<div class="countdown--unit">
										d
									</div>
									<div class="countdown--number">
										1
									</div>
									<div class="countdown--number">
										7
									</div>
									<div class="countdown--unit">
										h
									</div>
									<div class="countdown--number">
										0
									</div>
									<div class="countdown--number">
										2
									</div>
									<div class="countdown--unit">
										m
									</div>
									<div class="countdown--number">
										3
									</div>
									<div class="countdown--number">
										1
									</div>
									<div class="countdown--unit">
										s
									</div>
								</div>
								<v-btn small color="black" dark elevation="0">
									book now
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</template> -->
			<v-container :fluid="$vuetify.breakpoint.mdAndDown">
				<v-row no-gutters class="py-2 mx-auto">
					<v-col cols="3" sm="3">
						<router-link to="/">
							<img :src="'logo/brand/logo.png' | urlize" :width="showDrawer ? 100 : 160" class="mr-5" />
						</router-link>
					</v-col>

					<v-col cols="9" sm="9">
						<div v-if="showDrawer" class="d-flex justify-end align-center fill-height">
							<v-app-bar-nav-icon class="mr-2" @click.stop="drawer = !drawer" />
						</div>

						<div v-else class="d-flex flex-column justify-center fill-height custom-navigation">
							<div class="d-flex justify-end text-body-2 mb-4">
								<div v-for="item in mainMenu" :key="item.name" class="flex-grow-0 align-self-center mx-2" :class="item.class">
									<a
										text
										:href="isExternalLink(item) ? item.url : null"
										:target="item.target == null ? null : item.target"
										@click="!isExternalLink(item) ? $router.push(item.url) : null"
									>
										{{ item.name }}
									</a>
								</div>
							</div>
							<div class="d-flex justify-end text-body-1 font-weight-medium" style="">
								<div v-for="item in subMenu" :key="item.name" class="flex-grow-0 align-self-center mx-2" :class="item.class">
									<a
										text
										:href="isExternalLink(item) ? item.url : null"
										:target="item.target == null ? null : item.target"
										@click="!isExternalLink(item) ? $router.push(item.url) : null"
									>
										{{ item.name }}
									</a>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" right fixed temporary>
			<v-list nav dense>
				<v-list-item-group v-model="group" active-class="primary--text text--accent-4">
					<v-list-item
						v-for="item in mainMenu"
						:key="item.name"
						:href="isExternalLink(item) ? item.url : ''"
						:to="!isExternalLink(item) ? item.url : ''"
						:target="item.target == null ? '_self' : item.target"
					>
						<v-list-item-title>{{ item.name }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
				<v-divider class="my-4" />
				<v-list-item-group v-model="group" active-class="primary--text text--accent-4">
					<v-list-item
						v-for="item in subMenu"
						:key="item.name"
						:href="isExternalLink(item) ? item.url : ''"
						:to="!isExternalLink(item) ? item.url : ''"
						:target="item.target == null ? '_self' : item.target"
					>
						<v-list-item-title>{{ item.name }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	export default {
		name: 'app-layout-default-header',

		data() {
			return {
				drawer: false,
				group: null,
				mainMenu: [
					{ name: 'Product Updates', url: '/product-updates' },
					{ name: 'Conference', url: '/conference' },
					{ name: 'Awards', url: '/awards', class: 'link--special' },
					{ name: 'Insights Hub', url: '/insights-hub' },
					{ name: 'News', url: '/news' },
					{ name: 'Events', url: '/events' },
					{ name: 'Register', url: '/register' },
					{ name: 'Log in', url: '/advisers', class: 'link--highlight px-3 rounded info' }
				],
				subMenu: [
					{ name: 'Sourcing', url: '/sourcing' },
					{ name: 'Club', url: '/air-club' },
					{ name: 'Academy', url: '/air-academy' },
					{ name: 'Marketing', url: '/marketing' },
					{ name: 'Ambassadors', url: '/ambassadors' },
					{ name: 'Comprehensive Conversations', url: '/comprehensive-conversations' }
				]
			};
		},

		computed: {
			showDrawer() {
				return this.$vuetify.breakpoint.smAndDown;
			}
		},

		watch: {
			group() {
				this.drawer = false;
			}
		},

		beforeMount() {
			if (window.localStorage.getItem('air.authorization')) {
				const index = this.mainMenu.findIndex((i) => i.url === '/advisers');
				this.$set(this.mainMenu, index, { ...this.mainMenu[index], name: 'My Account', url: '/account' });
			}
		},

		methods: {
			isExternalLink(link) {
				return link.url.includes('http://') || link.url.includes('https://');
			}
		}
	};
</script>

<style scoped lang="scss">
	.link--highlight {
		a {
			color: #fff !important;
			text-transform: uppercase;
			&:hover {
				color: var(--v-secondary-base) !important;
			}
		}
	}

	.link--special {
		background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/cms/texthighlight.svg');
		background-position: 50% 50%;
		padding: 8px 16px;
		margin: 0px !important;
		background-repeat: no-repeat;
		background-size: cover;
		font-weight: 500;
	}

	.custom-navigation {
		a {
			color: #000000;
			&:hover {
				color: var(--v-secondary-base);
			}
		}
	}

	::v-deep .v-toolbar__extension {
		padding: 0;
	}

	.countdown {
		display: inline-block;
		padding: 0 32px;

		&--number,
		&--unit {
			display: inline-block;
		}

		&--number {
			padding: 1px 8px;
			font-size: 17px;
			border: 1px solid rgba(0, 0, 0, 0.5);
			margin: 0 2px;
			border-radius: 3px;
			background: rgba(255, 255, 255, 0.5);
			&:first-child {
				margin-left: 0;
			}
		}

		&--unit {
			margin: 0 8px 0 2px;
			line-height: 17px;
			text-transform: uppercase;
			&:last-child {
				margin-right: 0;
			}
		}
	}
</style>
