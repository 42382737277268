/**
 * @class @name PaymentsHelper
 * @description Payment common methods
 */
export default class PaymentsHelper {
	/**
	 * @name covertDecimalYearsToYearsMonths
	 * @param {Number} decimal
	 * @param {String} format
	 * @return {String|Object} "X years X months" or { years: X, months: X }
	 */
	static covertDecimalYearsToYearsMonths(decimal, format = 'object') {
		const years =  Math.floor(decimal);
		const months = (12*(decimal - Math.floor(decimal))).toFixed(0);
		if (format === 'object') return { years, months };
        if (format === 'shortString') return `${years}yr${years != 1 ? 's' : ''} ${months}mth${months != 1 ? 's' : ''}`;
        return `${years} year${years != 1 ? 's' : ''} ${months} month${months != 1 ? 's' : ''}`;
	}
}
