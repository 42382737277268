<!--
	@name app-source-result-dialog-product-issue
	@description Source result dialog showing showing product issue
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Report An Issue
		</template>
		<template #body>
			<div v-if="success" class="text-center">
				<v-icon class="text-h2" color="success">
					mdi-check-bold
				</v-icon>
				<v-card-text class="text-subtitle-1 d-block pb-5">
					{{ message }}
				</v-card-text>
			</div>
			<div v-else>
				<p v-if="message" class="text-subtitle-1" :class="{ 'error--text': messageError }">
					{{ message }}
				</p>
				<p v-else class="text-subtitle-1">
					Please provide as much information as possible to ensure we have a complete understanding of the issue you are reporting so that we may investigate at the
					earliest opportunity.
				</p>
				<validation-observer v-slot="{ invalid, validate }">
					<form @submit.prevent="validate().then(submit)">
						<validation-provider v-slot="{ errors }" name="Feedback" rules="required">
							<v-textarea v-model="data.issue" :error-messages="errors" label="Please describe the issue with this Product" outlined />
						</validation-provider>
						<v-btn color="primary" class="mr-4" type="submit" :loading="submitting" :disabled="invalid">
							Submit Issue
						</v-btn>
					</form>
				</validation-observer>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-product-issue',

		components: { ValidationObserver, ValidationProvider, CommonDialog },

		data() {
			return {
				product: null,
				data: {},
				submitting: false,
				message: null,
				messageError: false,
				success: false
			};
		},

		methods: {
			...mapActions('AppProduct', ['submitProductIssue']),

			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open(product) {
				this.product = product;
				this.data = {};
				this.messageError = false;
				this.success = false;
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description Submit product issue/s
			 */
			async submit() {
				this.submitting = true;

				this.submitProductIssue({ product: this.product, ...this.data })
					.then(() => {
						this.success = true;
						this.message = 'Your request has been submitted';
					})
					.catch(() => (this.messageError = true))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>
