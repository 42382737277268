import { $http } from '@/utils';

/**
 * @namespace Service
 * @class AirMail
 * @exports Service/Cms/AirMail
 * @description API AirMail Service
 * @date 2024/06/20
 * @license no license
 * @copyright Answers In Retirement Limited
 */
export default class AirMail {

	/**
	 * Fetches a list of air mail items from the CMS.
	 *
	 * @param {Object} payload - The payload to be sent with the request.
	 * @param {string} domain - The domain for which the air mail items are being fetched.
	 * @param {boolean} [cache=true] - Indicates whether the response should be cached.
	 * @returns {Promise} A promise that resolves to the response from the server.
	 */
	static list(payload, domain, cache = true) {
		return $http.post('cms/air-mail/list', payload, {
			cache,
			cachePath: `table-cms-air-mail-${domain}`
		});
	}
}
