<!--
	@name app-dashboard-product-changes
	@description Product changes component
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="white" :class="[{ 'h-100': large }, { 'mb-6': !large }]">
		<common-structure-section>
			<template #header>
				Product Updates
			</template>

			<template #body>
				<v-card v-if="loading" class="flex-grow-1">
					<v-skeleton-loader type="list-item-three-line" />
				</v-card>
				<div v-else>
					<p class="mb-0 text-body-2 font-weight-medium">
						<span>There {{ numChangesWithinPeriod === 1 ? `is ${numChangesWithinPeriod} known product update` : `are ${numChangesWithinPeriod} known product updates` }} within the
							next {{ comparisonPeriod }} days.</span>
						<span v-if="nextChange"> The next known product update will take effect on {{ nextChange.effective | moment('MMM DD, YYYY') }}.</span>
						<a v-if="!nextChange" color="secondary" @click="displayRatesTable">
							Click here to open the Product Updates table.
						</a>
					</p>

					<v-list v-if="nextChange" dense color="grey lighten-3 mt-4 mb-0 pa-0 elevation-2">
						<v-list-item link class="py-1" @click="displayRatesTable">
							<v-list-item-icon class="px-2 align-self-center">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-icon large :color="getChangeIcon(nextChange).color" v-on="on">
											{{ getChangeIcon(nextChange).icon }}
										</v-icon>
									</template>
									<span>{{ getChangeIcon(nextChange).hover }}</span>
								</v-tooltip>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title class="body-2 mb-1" v-html="$sanitize(nextChange.organisationName)" />
								<v-list-item-subtitle class="font-weight-regular mb-1" v-html="$sanitize(nextChange.product)" />
								<v-list-item-subtitle class="font-weight-regular">
									{{ subtitleDisplay }}
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn color="secondary" small @click="displayRatesTable">
									view all
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</div>
			</template>
		</common-structure-section>

		<common-dialog ref="ratesTable" :max-width="1280">
			<template #header>
				Product Updates
			</template>
			<template #body>
				<rate-change-table :product-changes="latestProductChanges" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import { debounce, filter, orderBy } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialog from '@/component/common/dialog/index';
	import RateChangeTable from '@/component/app/common/rate-change-table';

	export default {
		name: 'app-dashboard-product-list',

		components: {
			CommonDialog,
			CommonStructureSection,
			RateChangeTable
		},


		props: {
			large: { type: Boolean, default: false }
		},

		data() {
			return {
				comparisonPeriod: 14,
				loading: true
			};
		},

		computed: {
			...mapState('CmsProductUpdate', ['latestProductChanges']),

			changesWithinPeriod() {
				return filter(this.latestProductChanges, (o) => {
					return this.$moment(String(o.effective)).isBetween(this.$moment(), this.$moment().add(this.comparisonPeriod, 'days'), 'day', '[]');
				});
			},

			numChangesWithinPeriod() {
				return this.changesWithinPeriod.length;
			},

			futureItems() {
				let futureItems = filter(this.latestProductChanges, (o) => this.$moment(String(o.effective)).isSameOrAfter(this.$moment(), 'days'));
				return orderBy(futureItems, 'effective', ['asc']).slice(0, 1);
			},

			nextChange() {
				if (!this.futureItems.length) return false;
				return this.futureItems[0];
			},

			subtitleDisplay() {
				if (!this.nextChange) return false;
				if (this.nextChange.category.toLowerCase() == 'product launch') return `Product launch: ${this.$moment(this.nextChange.effective).format('MMM DD, YYYY')}`;
				return `Last KFI: ${this.$moment(this.nextChange.lastKfi).format('MMM DD, YYYY')}`;
			}
		},

		created() {
			this.loadLatestProductChanges().then(() => (this.loading = false));
		},

		methods: {
			...mapActions('CmsProductUpdate', ['loadLatestProductChanges']),

			/**
			 * @debounce @name displayRatesTable
			 */
			displayRatesTable: debounce(async function() {
				this.$refs.ratesTable.open();
			}, 200),

			getChangeIcon(item) {
				if (item.category.toLowerCase() == 'rate change') {
					if (item.type === 'Increase') return { icon: 'mdi-arrow-up-thin-circle-outline', color: 'error', hover: 'Rates will increase' };
					if (item.type === 'Decrease') return { icon: 'mdi-arrow-down-thin-circle-outline', color: 'success', hover: 'Rates will decrease' };
					return { icon: 'mdi-progress-question', color: 'warning', hover: 'Rates will increase, decrease or stay the same' };
				} else if (item.category.toLowerCase() == 'ltv change') {
					if (item.type === 'Increase') return { icon: 'mdi-home-plus', color: 'success', hover: 'LTVs will increase' };
					if (item.type === 'Decrease') return { icon: 'mdi-home-minus', color: 'error', hover: 'LTVs will decrease' };
					return { icon: 'mdi-progress-question', color: 'warning', hover: 'LTVs will increase, decrease or stay the same' };
				} else if (item.category.toLowerCase() == 'product withdrawal') {
					return { icon: 'mdi-package-variant-remove', color: 'error', hover: 'Product is being withdrawn from the market' };
				} else if (item.category.toLowerCase() == 'product launch') {
					return { icon: 'mdi-package-variant-plus', color: 'success', hover: 'Product is being launched' };
				} else if (item.category.toLowerCase() == 'cashback withdrawal') {
					return { icon: 'mdi-cash-remove', color: 'error', hover: 'Cashback is being withdrawn from the market' };
				}
				return { icon: 'mdi-progress-question', color: 'grey', hover: 'Awaiting details' };
			}
		}
	};
</script>
