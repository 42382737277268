<!--
	@name app-dialog-air-tier
	@description Show a dialog for Air Select
	@date 2020/06/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="800">
		<template #header>
			Air {{ isSelect ? 'Select' : 'Club' }}
		</template>
		<template #body>
			<v-container class="pa-0">
				<app-air-select-panel-status v-if="isSelect" reload class="mb-5" />
				<div v-if="tier.id">
					<p class="text-body-1" v-html="$sanitize(contentClub.tierDialog)" />
					<p v-if="!isFactFind" class="text-body-1 mb-0 font-weight-medium">
						<a @click="goToMembership">Visit your membership page</a> to explore all the benefits associated with your Air Club account.
					</p>
				</div>
				<div v-else>
					<p class="text-body-1 mb-0 font-weight-medium">
						<a @click="goToMembership">Join Air Club</a> to benefit from all the great tools, support and software available to your tier. Explore your personalised
						membership page to find out more.
					</p>
				</div>
			</v-container>
		</template>
	</common-dialog>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { EventBus } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import AppAirSelectPanelStatus from '@/component/app/air-select/panel-status';

	export default {
		name: 'app-dialog-air-tier',

		components: { CommonDialog, AppAirSelectPanelStatus },

		data() {
			return {
				timeout: null
			};
		},

		computed: {
			...mapGetters('Account', ['membership', 'tier']),
			...mapGetters('CmsContent', ['contentClub']),

			isSelect() {
				return this.membership('select').approve;
			},

			isFactFind() {
				return !!this.$route.query?.factfind;
			}
		},

		created() {
			EventBus.$on('toggle-air-select-bypass', this.toggleAirSelectBypassListener);
		},

		beforeDestroy() {
			EventBus.$off('toggle-air-select-bypass', this.toggleAirSelectBypassListener);
		},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			},

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name toggleAirSelectBypassListener
			 * @description Air select bypass changed listener
			 */
			toggleAirSelectBypassListener() {
				clearTimeout(this.timeout);

				this.timeout = setTimeout(() => {
					this.$refs?.dialog?.close();
				}, 500);
			},

			/**
			 * @name goToMembership
			 * @description Navigate to membership page
			 */
			goToMembership() {
				this.close();
				this.$router.push('/club');
			}
		}
	};
</script>
