<!--
	@name app-conference-avatar
	@description Conference avatar
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3 d-inline-flex align-center" :style="`flex-basis: ${basis}%;`">
		<div>
			<v-avatar :size="imageSize" style="border: 1px solid rgba(0,0,0,0.2);">
				<img
					alt="Avatar"
					:src="image"
				/>
			</v-avatar>
		</div>
		<div class="pl-3">
			<span class="font-weight-bold" :class="[{ 'white--text':dark }, nameSize]">
				{{ name }}
			</span>
			<span class="d-block font-weight-regular" :class="[{ 'white--text':dark }, positionSize]">
				{{ position }}
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-conference-avatar',

		components: {},

        props: {
			name: { type: String, required: true },
			position: { type: String, required: true },
			image: { type: String, required: true },
			size: { type: String, default: 'medium' },
			basis: { type: Number, default: 20 },
			dark: { type: Boolean, default: false }
		},

		data() {
			return {};
		},

		computed: {
			nameSize() {
				if (this.size === 'large') return 'text-h6';
				return 'text-body-1';
			},

			positionSize() {
				if (this.size === 'large') return 'text-body-2';
				return 'text-caption plain';
			},

			imageSize() {
				if (this.size === 'large') return 100;
				return 75;
			}
		},

		created() {},

		methods: {}
	};
</script>

<style lang="scss" scoped>
</style>
