<!--
	@name common-dialog-progress
	@description A common dialog box for confirmations
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="active" :max-width="maxWidth" :max-height="maxHeight" :component="$options.name" persistent>
		<v-card>
			<v-app-bar dark :color="color">
				<h2 class="header">
					<slot name="header" />
				</h2>
			</v-app-bar>
			<v-divider />
			<v-card-text>
				<slot name="body" />
				<v-progress-linear v-model="percent" height="25" buffer-value="0" :stream="stream" :indeterminate="indeterminate" :color="progressColor">
					<strong v-if="percent && text">{{ Math.ceil(percent) }}%</strong>
				</v-progress-linear>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: 'common-dialog-progress',

		props: {
			indeterminate: { type: Boolean, default: false },
			stream: { type: Boolean, default: false },
			text: { type: Boolean, default: true },
			percent: { type: Number, default: 0 },
			color: { type: String, default: 'blue-grey' },
			progressColor: { type: String, default: 'green' },
			maxWidth: { type: Number, default: 900 },
			maxHeight: { type: Number, default: 500 }
		},

		data: () => ({
			active: false
		}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.active = true;
			},

			/**
			 * @name close
			 * @description Close dialog and set properties
			 */
			close() {
				this.active = false;
			}
		}
	};
</script>

<style scoped>
	.header {
		font-size: 1.1rem;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
	}
</style>
