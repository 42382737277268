<template>
	<div :component="$options.name" class="pt-2 pb-12 px-6">
		<h1>
			<strong>Cookie Notice</strong>
		</h1>
		<div>This cookie notices references to "we", "us" and "our" are to {{ site?.company.name }}. References to “our website” or “the website” are to {{ website_name }}.</div>
		<p>
			We pride ourselves in providing an excellent service to our customers. In order for us to provide and continually improve this service we use cookies for a number of
			reasons. Whilst our cookies do not contain any personal information and simply help us to improve the online experience for you we&rsquo;d like to explain more about
			how and why we use them.
		</p>
		<p>Some Cookies make websites work better</p>
		<p>
			Cookies are small text files placed on your computer by websites or sometimes by emails. They provide useful information to companies; this information contributes to
			the ongoing development of the website in line with customer demands. They also make sure you can use our handy calculators on our website, use our social links and
			remember any preferences you may wish to set. For us, cookies help us to analyse how our customers interact with our sites so we can make improvements and in general
			make the website work better for everyone. We use three types of cookies on our websites
		</p>
		<ul>
			<li>Session cookies that are deleted after each visit.</li>
			<li>Persistent cookies that remain in place across multiple visits to our sites.</li>
			<li>Third party cookies that are used by our approved business partners.</li>
		</ul>
		<p>Some of these cookies are essential and some are non-essential.</p>
		<p><strong>Some cookies are essential </strong></p>
		<p>
			Certain cookies are necessary in order for you to use our websites. These are used &lsquo;in-session&rsquo; each time you visit and then expire when you leave the site.
			They&rsquo;re not stored on your computer and they don&rsquo;t contain any personal data. However, you can delete them via your browser if you wish to, but this will
			restrict the functions that you&rsquo;re able to carry out on our sites. For more information about how to disable cookies in your browser please visit the
			<a href="https://www.aboutcookies.org" target="_blank">About cookies</a> website.
		</p>
		<p>Here&rsquo;s a list of essential cookies and how we use them:</p>
		<p>
			<strong>Session cookies</strong> &ndash; These enable us to secure the site and you to carry out some essential functions on our sites, such as maintaining log in
			details for the session or a transaction. They also help by minimising the need to transfer information across the internet. They are not stored on your computer, and
			they expire when you terminate your browser session. &nbsp;
		</p>
		<p><strong>Some cookies are non-essential</strong></p>
		<p>
			These cookies are usually supplied by our business partners and help us to filter out irrelevant messages or pop-ups and to make sure that we only send you information
			that’s interesting and relevant to you.
		</p>
		<p>Here&rsquo;s a list of our non-essential cookies and what they do:</p>
		<ul>
			<li>
				Analytical cookies &ndash; These enable us to recognise repeat visitors to the site. By matching an anonymous, randomly generated identifier, we&rsquo;re able to
				record specific browsing information such as how you arrive at the site, the pages you view, options you select, and the path you take through the site. By
				monitoring this information we&rsquo;re able to make improvements to our sites.
			</li>
		</ul>
		<p><strong>You can accept or reject non-essential cookies </strong></p>
		<p>
			You can reject any non-essential cookies from our cookie banner and any website through your browser settings. For more information about how to disable cookies in your
			browser please visit the
			<a href="https://www.aboutcookies.org" target="_blank">About cookies</a> website.
		</p>
		<p>If you share the use of a computer, accepting or rejecting the use of cookies will affect all users of that computer.</p>
		<p><strong>Find out more </strong></p>
		<p>
			You&rsquo;ll find more information about cookies at <a href="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>, which gives details on how to delete cookies from your
			computer. For information on how to do this on your mobile phone browser, please see your handset manual.
		</p>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	export default {
		name: 'app-cookie-policy',

		data: () => ({
			website_name: `www.${window.location.hostname}`
		}),

		computed: { ...mapState('CmsSite', ['site']) },

		created() {
			this.loadSite();
		},

		methods: {
			...mapActions('CmsSite', ['loadSite'])
		}

	};
</script>

<style></style>
