import { CmsData } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Data {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			dataList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name data
			 * @description used to get filtered data from store
			 * @param {Object} state
			 */
			data: (state) => (nameUnique) => {
				return state.dataList.find((i) => i.nameUnique === nameUnique) || {};
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setData
			 * @description used to set data on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setData: (state, data) => {
				let dataList = [...state.dataList];
				let idx = dataList.reduce((p, c, i) => (c.nameUnique === data.nameUnique ? i : p), -1);
				if (idx >= 0) dataList[idx] = data;
				else dataList.push(data);
				state.dataList = dataList;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name getData
			 * @param {function} commit
			 */
			async loadData({ commit }, name) {
				let response = await CmsData.get(name);
				if (!response.error) commit('setData', response.data);
			}
		};
	}
}
