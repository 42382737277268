<!--
	@name app-client-dashboard-workflow-vulnerability-assessment
	@description Client Workflow Vulnerability Assessment Item
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<app-client-dashboard-workflow-template
			:workflow-item="workflowItem"
			@close-workflow-item="$emit('close-workflow-item')"
			@update-status="$emit('update-status', $event)"
		>
			<template slot="activity">
				<app-client-dashboard-workflow-vulnerability-assessment-activity
					:client="client"
					:association="association"
					:asset="asset"
					:intro-resource="introResource"
					@toggle-intro-resource="toggleIntroResource"
					@workflow-updated="$emit('workflow-updated')"
				/>
			</template>

			<template slot="resources">
				<app-client-dashboard-workflow-template-resources :section="section" :resources="resources" :remove-intro-video="removeIntroVideo" />
			</template>
		</app-client-dashboard-workflow-template>
	</div>
</template>

<script>
	import AppClientDashboardWorkflowTemplate from '@/component/app/client/dashboard/workflow/template';
	import AppClientDashboardWorkflowVulnerabilityAssessmentActivity from '@/component/app/client/dashboard/workflow/vulnerability-assessment/activity';
	import AppClientDashboardWorkflowTemplateResources from '@/component/app/client/dashboard/workflow/template/resources';
	import courseVideoMixin from '@/mixins/course-video';

	export default {
		name: 'app-client-dashboard-workflow-vulnerability-assessment',

		components: {
			AppClientDashboardWorkflowTemplate,
			AppClientDashboardWorkflowVulnerabilityAssessmentActivity,
			AppClientDashboardWorkflowTemplateResources
		},

		mixins: [courseVideoMixin],

		props: {
			workflowItem: { type: Object, required: true },
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, default: null },
			resources: { type: Array, required: true }
		},

		data() {
			return {
				section: 'vulnerability_assessment'
			};
		}
	};
</script>

<style scoped lang="scss"></style>
