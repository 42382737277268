<!--
	@name app-dashboard-tab-view-news
	@description News tab view for the dashboard page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-news :items="newsList" class="pa-5">
			<template #detail>
				Important product updates, news and selected articles from the market. Click on any article below to read more…
			</template>
		</common-structure-news>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonStructureNews from '@/component/common/structure/news';

	export default {
		name: 'app-dashboard-tab-view-news',

		components: { CommonStructureNews },

		computed: {
			...mapState('CmsNews', ['newsList'])
		}
	};
</script>
