<!--
	@name app-layout-fact-find
	@description Fact find mode layout
	@date 2020/06/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<app-layout-default-authed-header :show-contact="false" :show-account="false" :show-logout="false" factfind />

		<!-- content -->
		<v-main>
			<app class="pa-5" />
		</v-main>
	</v-app>
</template>

<script>
	import App from '@/component/app';
	import AppLayoutDefaultAuthedHeader from '../default-authed/header.vue';

	export default {
		name: 'app-layout-fact-find',

		components: { App, AppLayoutDefaultAuthedHeader },

		created() {
			this.$connectWebSocket();
		},
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		//background: #fff !important;
	}
</style>
