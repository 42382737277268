<!--
	@name app-source-result
	@description Show equity results page
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="data-table-wrapper mortgage-table">
		<v-sheet class="pa-6">
			<common-base-menu-hint
				ref="orderedColumnsOnBoardingHint"
				:nudge-bottom="40"
				width="30rem"
				color="amber accent-2"
				:border="{ width: '6px', style: 'solid', color: 'orange.accent2' }"
			>
				<template #target>
					<v-row no-gutters class="pb-3 data-table-header">
						<v-col cols="12" sm="3" class="search-box">
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								:disabled="loadingProducts"
								:class="{ 'pulse--accent--small': searchBarHintStatus }"
								placeholder="Search: Provider, Product Name, AER ..."
								dense
								outlined
								filled
								hide-details
								@focus="$emit('search-bar-focus')"
								@keydown="searchBarChangeHandler"
							/>
						</v-col>
						<v-col cols="12" sm="6">
							<v-pagination
								v-if="pages"
								v-model="currentPage"
								:disabled="!pages || !productListFiltered(viewOptions).length || loadingProducts"
								:length="pages"
								:total-visible="9"
								@input="changePage"
								@update:page="$emit('page-change')"
							/>
						</v-col>
						<v-col cols="12" sm="3">
							<v-select
								v-model="viewOptions"
								:disabled="loadingProducts"
								outlined
								multiple
								dense
								filled
								class="smart-filters"
								:items="viewOptionItems"
								item-text="text"
								item-value="value"
								label="View Options"
								@change="viewOptionChangeHandler"
							>
								<template #selection="{ item, index }">
									<span v-if="index === 0">
										{{ item.text }}
									</span>
									<span v-if="index === 1" class="d-inline-block grey--text text-caption pl-2"> (+{{ viewOptions.length - 1 }} others) </span>
								</template>
							</v-select>
						</v-col>
					</v-row>
				</template>
				<template #standard>
					<h2 class="font-weight-black black--text text-body-1 mb-2">
						"ORDER!"
					</h2>
					<p class="mb-0 text-body-2 black--text font-weight-medium">
						Click on a column heading to sort results by that column. Click a second time to reverse the order and a third time to reset.
						<span class="font-weight-bold">You can order by MULTIPLE columns!</span>
					</p>
				</template>
			</common-base-menu-hint>

			<p v-if="showHint" class="text-body-1 mt-4 mb-1 font-weight-light purple--text text--accent-2 text-center">
				<span class="font-weight-bold purple--text text--accent-4">HINT:</span> Click on a column heading to sort results by that column. Click a second time to reverse the
				order and a third time to reset. <span class="font-weight-bold purple--text text--accent-4">You can order by MULTIPLE columns!</span>
			</p>

			<v-data-table
				:loading="submitting || loading"
				loading-text="Fetching Products..."
				no-data-text="No matching products found"
				no-results-text="No matching products found"
				item-key="id"
				:items="submitting || loading ? [] : productListFiltered(viewOptions)"
				:items-per-page="itemsPerPageModel"
				:headers="headers"
				:footer-props="footerProps"
				class="hide-footer"
				:class="{ 'disable-sort': submitting }"
				:page.sync="currentPage"
				:sort-by.sync="sortConfig.sortBy"
				:sort-desc.sync="sortConfig.sortDesc"
				:custom-sort="customSort"
				:disable-sort="loadingProducts"
				multi-sort
				@update:sort-desc="handleSort"
			>
				<template #header.id>
					<v-tooltip bottom max-width="400">
						<template #activator="{ on }">
							<span class="text-decoration-underline black--text" v-on="on"> Actions </span>
							<span class="d-block font-weight-regular"> KFIs, criteria ... </span>
						</template>
						<span style="white-space: normal">
							Select one or more products to request KFIs, add manual restrictions or compare borrowing forecasts. Click MORE to view enhanced product critera.
						</span>
					</v-tooltip>
				</template>
				<template #item="{ item, headers }">
					<tr v-if="item.restriction" class="restricted heading">
						<v-tooltip bottom max-width="500">
							<template #activator="{ on }">
								<th :colspan="headers.length" v-on="item.restriction.reason.length > 100 ? on : null">
									<p class="pa-0 ma-0 text-body-2-2 d-inline font-weight-light">
										<v-icon color="white" class="mr-2">
											mdi-alert
										</v-icon>
										<span class="font-weight-medium">{{ !item.restriction.type === 'hard' ? 'RESTRICTED' : 'OUTSIDE CRITERIA' }}:</span> {{ item.restriction.reason | truncate(100) }}
									</p>
									<v-btn v-if="item.restriction && item.restriction.code === 999999" class="float-right" color="success" x-small @click="$emit('remove-restriction', item)">
										Remove Restriction
									</v-btn>
								</th>
							</template>
							<span>{{ item.restriction.reason }}</span>
						</v-tooltip>
					</tr>
					<tr v-if="item.criteria && item.criteriaActive" class="criteria heading">
						<th :colspan="headers.length">
							<v-tooltip right>
								<template #activator="{ on }">
									<v-icon class="mr-2" size="24" v-on="on">
										mdi-compass
									</v-icon>
								</template>
								<span>Knowledge Bank Search Results</span>
							</v-tooltip>
							<common-base-criteria-chips v-if="item.criteria.length" :criteria-items="item.criteria" :truncate-character-count="20" />
							<p v-else class="mb-0 d-inline-block font-weight-regular" style="line-height: 24px;">
								No matching criteria found for this product
							</p>
						</th>
					</tr>
					<tr :class="{ restricted: item.restriction }">
						<td class="shrunk remove-2">
							<common-base-image-tooltip
								:src="logoName(item.provider.logo) | urlize('organisation')"
								:tooltip="item.provider.name"
								:height="40"
								:width="75"
								:max-width="90"
								:max-height="90"
							/>
						</td>
						<td class="sized max">
							<app-equity-result-mortgage-item-name :item="item" />
						</td>
						<td class="sized text-center">
							<app-equity-result-mortgage-item-equivalent :item="item" />
						</td>
						<td class="sized nowrap text-center">
							<app-equity-result-mortgage-item-advance :item="item" />
						</td>
						<td class="sized text-center">
							<app-equity-result-mortgage-item-drawdown :item="item" />
						</td>
						<td class="sized nowrap text-center remove-3">
							<app-equity-result-mortgage-item-facility :item="item" />
						</td>
						<td class="sized nowrap text-center">
							<app-equity-result-mortgage-item-payment :item="item" v-on="$listeners" />
						</td>
						<td class="sized nowrap text-center">
							<app-equity-result-mortgage-item-total :item="item" v-on="$listeners" />
						</td>
						<td class="shrunk actions">
							<app-equity-result-mortgage-item-action :item="item" :is-quick-quote="isQuickQuote" :selected-product-list="selectedProductList" v-on="$listeners" />
						</td>
					</tr>
				</template>
			</v-data-table>

			<v-sheet class="caption text-center py-3">
				<div class="d-flex align-center justify-center flex-grow-0 flex-shrink-0 nowrap" style="position: relative;">
					<v-pagination
						v-if="pages"
						v-model="currentPage"
						:disabled="!pages || !productListFiltered(viewOptions).length || loadingProducts"
						:length="pages"
						:total-visible="9"
						@input="changePage"
					/>
					<div class="d-flex align-center flex-grow-0 flex-shrink-0" style="position: absolute; width: 260px; right: 0;">
						Rows per page:
						<v-select
							v-model="itemsPerPageModel"
							class="ml-4 my-2 pt-0"
							:disabled="loadingProducts"
							:items="rowsPerPageOptions"
							hide-details
							@change="itemsPerPageChangeHandler"
						/>
						<span class="d-block ml-8"> {{ indexOfFirstResultInTable }}-{{ indexOfLastResultInTable }} of {{ totalProductCount }} </span>
					</div>
				</div>
			</v-sheet>
		</v-sheet>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { debounce } from 'lodash';
	import CommonBaseCriteriaChips from '@/component/common/base/criteria-chips';
	import CommonBaseImageTooltip from '@/component/common/base/image-tooltip';
	import CommonBaseMenuHint from '@/component/common/base/menu-hint';
	import AppEquityResultMortgageItemAction from '@/component/app/source-result/mortgage/item-action';
	import AppEquityResultMortgageItemAdvance from '@/component/app/source-result/mortgage/item-advance';
	import AppEquityResultMortgageItemFacility from '@/component/app/source-result/mortgage/item-facility';
	import AppEquityResultMortgageItemPayment from '@/component/app/source-result/mortgage/item-payment';
	import AppEquityResultMortgageItemDrawdown from '@/component/app/source-result/mortgage/item-drawdown';
	import AppEquityResultMortgageItemEquivalent from '@/component/app/source-result/mortgage/item-equivalent';
	import AppEquityResultMortgageItemName from '@/component/app/source-result/mortgage/item-name';
	import AppEquityResultMortgageItemTotal from '@/component/app/source-result/mortgage/item-total';

	export default {
		name: 'app-source-result-mortgage',

		components: {
			CommonBaseCriteriaChips,
			CommonBaseImageTooltip,
			CommonBaseMenuHint,
			AppEquityResultMortgageItemAction,
			AppEquityResultMortgageItemAdvance,
			AppEquityResultMortgageItemFacility,
			AppEquityResultMortgageItemPayment,
			AppEquityResultMortgageItemDrawdown,
			AppEquityResultMortgageItemEquivalent,
			AppEquityResultMortgageItemName,
			AppEquityResultMortgageItemTotal
		},

		props: {
			loadingProducts: { type: Boolean, required: true, default: false },
			isQuickQuote: { type: Boolean, required: true, default: false },
			sortConfig: { type: Object, required: true, default: () => ({ sortBy: [], sortDesc: [] }) },
			productList: { type: Array, required: true },
			selectedProductList: { type: Array, required: true },
			totalProductCount: { type: Number, required: true, default: 0 },
			itemsPerPage: { type: Number, required: true, default: 0 },
			showHint: { type: Boolean, required: true, default: false },
			searchBarHintStatus: { type: Boolean, required: true, default: false }
		},

		data() {
			return {
				submitting: false,
				headers: [
					{ text: 'Provider', align: 'left', value: 'provider.name', class: 'remove-2' },
					{ text: 'Product Name', align: 'left', value: 'name' },
					{ text: 'AER', align: 'center', value: 'annualEquivalentRate' },
					{ text: 'Advance', align: 'center', value: 'advance' },
					{ text: 'Drawdown', align: 'center', value: 'drawdownFacility.amount' },
					{ text: 'Total Facility', align: 'center', value: 'maximumAvailable', class: 'remove-3' },
					{ text: 'Payments', align: 'center', value: 'payments.totalPayments' },
					{ text: 'Total Cost', align: 'center', value: 'totalToPay' },
					{ text: 'Actions', align: 'center', value: 'id', filterable: false, sortable: false }
				],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				search: null,
				currentPage: 1,
				pageCount: 0,
				viewOptionItems: [
					{ text: 'Hide Outside Criteria', value: 'hideRestricted' },
					{ text: 'Isolate Exclusives', value: 'isolateExclusives' }
				],
				viewOptions: [],
				itemsPerPageModel: 0,
				rowsPerPageOptions: [5, 10, 15, 20, 25]
			};
		},

		computed: {
			...mapGetters('Account', ['departmentName']),

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			},

			loading() {
				return this.loadingProducts && !this.productList.length;
			},

			pages() {
				return Math.ceil(this.totalProductCount / this.itemsPerPageModel);
			},

			indexOfFirstResultInTable() {
				return (this.currentPage - 1) * this.itemsPerPageModel + 1;
			},

			indexOfLastResultInTable() {
				const lastIndex = this.currentPage * this.itemsPerPageModel;
				if (lastIndex > this.totalProductCount) return this.totalProductCount;
				return lastIndex;
			}
		},

		mounted() {
			this.itemsPerPageModel = this.itemsPerPage;
		},

		methods: {
			/**
			 * @name productListFiltered
			 * @description Ordered array of products
			 * * @param {Array} viewOptions
			 */
			productListFiltered(viewOptions) {
				let productList = this.productList;

				if (viewOptions.includes('isolateExclusives')) productList = productList.filter((p) => p.exclusive);
				if (viewOptions.includes('hideRestricted')) productList = productList.filter((p) => !p.restriction);

				return productList;
			},

			setPage(page) {
				this.currentPage = page;
			},

			setSearch(search) {
				this.search = search;
			},

			changePage() {
				this.$emit('search-criteria-updated', { offset: (this.currentPage - 1) * this.itemsPerPageModel, search: this.search, createNewIteration: false });
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			},

			/**
			 * @name handleSort
			 * @description Adds iteration with sort by data
			 */
			handleSort() {
				if (this.isArchive) this.$emit('reset-sort-config');
				else if (!this.loadingProducts) this.$emit('search-criteria-updated', { sortConfig: this.sortConfig });
			},

			/**
			 * @name customSort
			 * @description Triggered when sorting & product list object mutation
			 * @param {Object} items The product object
			 * @return {Array} Sorted Product List
			 */
			customSort(items) {
				return items;
			},

			/**
			 * @debounce @name searchBarChangeHandler
			 * @description Intercept a keydown on the search bar, decide if this is the first instance nad act accordingly
			 */
			searchBarChangeHandler: debounce(function() {
				// Google Analytics event
				window.dataLayer.push({
					event: 'searchSubmitted',
					'gtm.element.dataset.gaId': 'search-form',
					'gtm.element.dataset.gaValue': this.search
				});

				this.currentPage = 1;
				this.changePage();
			}, 800),

			/**
			 * @name viewOptionChangeHandler
			 * @description View option change handler
			 */
			viewOptionChangeHandler() {
				this.currentPage = 1;
				this.$emit('search-criteria-updated', { viewOptions: this.viewOptions, createNewIteration: false });
			},

			itemsPerPageChangeHandler() {
				if (this.currentPage > this.pages) this.currentPage = this.pages;
				this.$emit('search-criteria-updated', { offset: (this.currentPage - 1) * this.itemsPerPageModel, limit: this.itemsPerPageModel });
			}
		}
	};
</script>

<style lang="scss">
	@import '~vuetify/src/styles/styles.sass';

	::v-deep .v-select__selection--comma {
		font-size: 0.75rem !important;
	}

	.mortgage-table {
		.v-data-table-header__icon {
			top: -2px;
			transform: rotate(270deg);
			opacity: 0.3;
		}

		.disable-sort {
			.v-data-table-header th {
				pointer-events: none;
			}
		}

		.pagination {
			padding: 24px 0;
		}
		.pagination-column {
			ul {
				width: auto;
			}
		}
		.data-table-header {
			align-items: center;
			.smart-filters {
				.v-text-field__details {
					display: none;
				}
			}
		}
		.hide-footer .v-data-footer {
			display: none;
		}

		.theme--light.v-data-table {
			thead tr:last-child th {
				border-bottom: none !important;
			}

			th,
			td {
				border-bottom: 0.25rem solid #ffffff !important;

				@media (max-width: 1335px) {
					&.remove-1 {
						display: none;
					}
				}

				@media (max-width: 1235px) {
					&.remove-2 {
						display: none;
					}
				}

				@media (max-width: 1135px) {
					&.remove-3 {
						display: none;
					}
				}

				&.sized {
					max-width: 10rem;
					padding-left: 0.75rem;
					padding-right: 0.75rem;

					&.max {
						width: auto;
						max-width: none;
					}
				}

				&.shrunk {
					width: 1px;
					white-space: nowrap;
				}
				&.nowrap {
					white-space: nowrap;
				}
				&.actions {
					background: rgba(27, 120, 216, 0.05);
				}
			}

			td {
				vertical-align: top;
			}

			tbody {
				tr {
					background-color: rgba(200, 200, 200, 0.3) !important;

					&:nth-child(odd) {
						background-color: rgba(27, 120, 216, 0.1) !important;
					}

					&:hover {
						background: rgba(23, 184, 144, 0.25) !important;
					}

					&.restricted {
						background: tint($error, 80%) !important;

						&.heading {
							th {
								background: $error !important;
								border-bottom: none !important;
								height: auto !important;
								padding-top: 0.5rem;
								padding-bottom: 0.5rem;
								color: white;
							}
						}
					}

					&.criteria {
						&.heading {
							background: #ffffff;
							th {
								background: map-get($blue, lighten-4) !important;
								border-bottom: none !important;
								height: auto !important;
								padding-top: 0.5rem;
								padding-bottom: 0.5rem;
								.v-icon {
									color: #2374ab;
								}
							}
						}
					}

					&.pending {
						background: map-get($orange, lighten-4) !important;
					}
				}
			}
		}
	}
</style>
