<!--
	@name app-source-result-dialog-back-to-sourcing
	@description Equity dialog prompting user to consider options before going back to sourcing page
	@date 2020/08/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog
		ref="dialog"
		:component="$options.name"
		:max-width="1000"
		:body-class="'pa-6'"
		:ga-id="'back-interceptor'"
		:ga-test-id="gaTestId"
	>
		<template #header>
			Before you go...
		</template>

		<template #body>
			<v-row v-if="version == 1">
				<v-col cols="12" md="4" class="py-0">
					<v-card
						class="ga--trigger ga--back-button py-5 pa-2 text-center d-flex flex-column justify-space-between"
						data-ga-id="back-interceptor"
						data-ga-value="applicant"
						:data-ga-test-id="gaTestId"
						color="grey lighten-2"
						height="100%"
						:to="`${sourcingUrl}?back-interceptor=true`"
					>
						<v-card-title class="title font-weight-regular justify-center pa-0 pb-10" style="word-break: break-word">
							Update Applicant details?
						</v-card-title>

						<v-card-subtitle class="font-weight-bold text-center pa-0 grey--text">
							Click Here
						</v-card-subtitle>
					</v-card>
				</v-col>

				<v-col cols="12" md="4" class="py-0">
					<v-card
						class="ga--trigger ga--back-button py-5 pa-2 text-center d-flex flex-column justify-space-between"
						data-ga-id="back-interceptor"
						data-ga-value="property"
						:data-ga-test-id="gaTestId"
						color="grey lighten-2"
						height="100%"
						:to="sourcingUrl"
					>
						<v-card-title class="title font-weight-regular justify-center pa-0 pb-10" style="word-break: break-word">
							Update Property details?
						</v-card-title>

						<v-card-subtitle class="font-weight-bold text-center pa-0 grey--text">
							Click Here
						</v-card-subtitle>
					</v-card>
				</v-col>

				<v-col cols="12" md="4" class="py-0">
					<v-card
						class="ga--trigger ga--filters-opened py-5 pa-2 text-center d-flex flex-column justify-space-between"
						data-ga-id="back-interceptor"
						:data-ga-test-id="gaTestId"
						color="pink accent-2"
						height="100%"
						@click.stop="openFilters"
					>
						<v-card-title class="title font-weight-medium justify-center pa-0 pb-10 white--text" style="word-break: break-word">
							Need something else?
						</v-card-title>

						<v-card-subtitle class="font-weight-bold text-center pa-0 pink--text text--lighten-5">
							Try our Filters!
						</v-card-subtitle>
					</v-card>
				</v-col>
			</v-row>

			<template v-if="version == 2">
				<v-row>
					<v-col cols="12" class="py-0">
						<v-card
							class="ga--trigger ga--filters-opened text-left d-flex flex-column justify-space-between"
							data-ga-id="back-interceptor"
							:data-ga-test-id="gaTestId"
							color="grey lighten-2"
							height="100%"
							hover
							@click.stop="openFilters"
						>
							<v-system-bar height="16" color="rgba(23, 184, 144, 1)" />
							<div class="pa-6">
								<v-card-title class="title font-weight-medium justify-center pa-0 pb-10 grey--text text--darken-4" style="word-break: break-word">
									<span class="font-weight-bold mx-1">Change advance?</span>
									<span class="font-weight-regular mx-1">Amend drawdown?</span>
									<span class="font-weight-bold mx-1">Add / remove product type?</span>
									<span class="font-weight-regular mx-1">Update affordability?</span>
								</v-card-title>

								<v-card-subtitle class="font-weight-regular text-body-1 text-center pa-0 black--text">
									<span class="primary--text font-weight-bold">Click here</span> to save time and build a powerful audit trail by using the filters.
								</v-card-subtitle>
							</div>
						</v-card>
					</v-col>
				</v-row>

				<p class="text-body-2 grey--text text--darken-4 mt-4 mb-5">
					Using the on-page filters can save valuable time on even the most straightforward sourcing journey. Moreover, <strong>each filter update will automatically build an audit history</strong>, providing unrivalled access to powerful insights.
					<a
						class="ga--trigger ga--on-air-article-opened font-weight-medium"
						data-ga-id="back-interceptor"
						:data-ga-value="onAirArticle.title"
						:data-ga-test-id="gaTestId"
						@click="openArticle(onAirArticle.id)"
					>Click here</a> to watch a short demonstration of the audit functionality.
				</p>
				<p class="text-body-2 mb-2">
					Alternatively, click either of the options below to navigate back to the sourcing details page.
				</p>
				<v-row>
					<v-col cols="12" md="6" class="py-0">
						<v-card
							class="ga--trigger ga--back-button text-center d-flex flex-column justify-space-between"
							data-ga-id="back-interceptor"
							data-ga-value="applicant"
							:data-ga-test-id="gaTestId"
							color="grey lighten-2"
							height="100%"
							hover
							:to="`${sourcingUrl}?back-interceptor=true`"
						>
							<v-system-bar height="8" color="grey lighten-1" />
							<v-card-title class="title font-weight-regular justify-center py-5 pa-2" style="word-break: break-word">
								Update Applicant details?
							</v-card-title>
						</v-card>
					</v-col>

					<v-col cols="12" md="6" class="py-0">
						<v-card
							class="ga--trigger ga--back-button text-center d-flex flex-column justify-space-between"
							data-ga-id="back-interceptor"
							data-ga-value="property"
							:data-ga-test-id="gaTestId"
							color="grey lighten-2"
							height="100%"
							hover
							:to="sourcingUrl"
						>
							<v-system-bar height="8" color="grey lighten-1" />
							<v-card-title class="title font-weight-regular justify-center py-5 pa-2" style="word-break: break-word">
								Update Property details?
							</v-card-title>
						</v-card>
					</v-col>
				</v-row>
			</template>

			<v-divider class="mt-8 mb-6" />
			<v-checkbox v-model="isHidden" class="ma-0 pa-0" label="Do not show this in future" hide-details color="primary" @change="isHiddenChanged" />
		</template>
	</common-dialog>
</template>

<script>
	import Vue from 'vue';
	import { $localStorage } from '@/utils/storage';
	import { EventBus } from '@/utils';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-back-to-sourcing',

		components: { CommonDialog },

		props: {
			isArchive: { type: Boolean, required: false, default: false }
		},

		data() {
			return {
				isHidden: false,
				version: 1,
				onAirArticle: {
					id: 'f26aae27-09ca-4a4e-bc4f-5dac24513f6a',
					title: 'Audit Functionality'
				}
			};
		},

		computed: {
			sourcingUrl() {
				return `/source/${this.$route.params.processId}`;
			},

			name() {
				if (this.version === 2) return 'backToSourcingDetailsPrompt29032024';
				return 'backToSourcingDetailsPrompt25082020';
			},

			gaTestId() {
				return `back-interceptor-v${this.version}`;
			}
		},

		created() {
			if (Vue.GrowthBook.isOn("back-interceptor")) {
				const feature = Vue.GrowthBook.evalFeature("back-interceptor");
				this.version = feature?.experimentResult?.key || this.version;
				// Google Analytics event
				window.dataLayer.push({
					event: 'backInterceptorAbTest1VariantSet',
					backInterceptorAbTest1Variant: this.version
				});
			}
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open() {
				// If we're in archive, no need to intercept so send them on their way
				if (this.isArchive) this.$router.push(this.sourcingUrl);
				else if ($localStorage.isDialogHidden(this.name)) {
					// Google Analytics event
					window.dataLayer.push({
						event: 'backInterceptorSkipped',
						'gtm.element.dataset.gaTestId': this.gaTestId
					});

					this.$router.push(this.sourcingUrl);
				}
				else this.$refs.dialog.open();
			},

			/**
			 * @name openFilters
			 * @description open filters panel
			 */
			openFilters() {
				this.$refs.dialog.close();
				this.$emit('open-filters');
			},

			/**
			 * @name isHiddenChanged
			 * @description Checkbox changed event handler
			 */
			isHiddenChanged() {
				// Google Analytics event
				window.dataLayer.push({
					event: 'doNotShowStatusUpdated',
					'gtm.element.dataset.gaId': 'back-interceptor',
					'gtm.element.dataset.gaValue': this.isHidden,
					'gtm.element.dataset.gaTestId': this.gaTestId
				});

				if (this.isHidden) $localStorage.hideDialog(this.name);
				else $localStorage.resetDialog(this.name);
			},

			/**
			 * @name openArticle
			 * @description Open on air article
			 */
			openArticle(id) {
				this.$nextTick(() => EventBus.$emit('open-on-air-article', id));
			}
		}
	};
</script>
