<!--
	@name app-maintenance
	@description Maintenance page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-container class="pa-0 text-center">
			<div class="d-flex justify-center">
				<img :src="site.logo.path | urlize('brand')" style="width: auto; max-height: 5rem" class="mt-15 mb-6" />
			</div>
			<template v-if="site.maintenance">
				<p class="text-h5 font-weight-bold mb-5">
					We are updating our systems
				</p>
				<p class="text-h6 font-weight-light">
					Our systems are currently updating. Normal service will resume shortly.
				</p>
			</template>
			<template v-else>
				<p class="text-h5 font-weight-bold mb-5">
					Sytem updates are complete
				</p>
				<p class="text-h6 font-weight-light">
					Click <router-link to="/advisers">
						here
					</router-link> to log in
				</p>
			</template>
		</v-container>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-maintenance',

		components: {},

		computed: {
			...mapState('CmsSite', ['site'])
		}
	};
</script>
