<script>
	import Vue from 'vue';

	export default {
		props: {
			htmlContent: { type: String, required: true },
			parseDelay: { type: Number, default: 0 }
		},

		data() {
			return {
				innerHTML: ''
			};
		},

		mounted() {
			this.innerHTML = this.htmlContent;

			setTimeout(() => {
				this.parseAnchors();
				this.parseDynamicValues();
			}, this.parseDelay);
		},

		methods: {
			parseAnchors() {
				const anchors = this.$el.getElementsByTagName('a');
				Array.from(anchors).forEach((anchor) => {
					const url = anchor.getAttribute('href');

					// skip if external link
					if (!url || url.startsWith('http')) return;

					const propsData = { to: url };
					const parent = this;
					const RouterLink = Vue.component('RouterLink');
					const routerLink = new RouterLink({ propsData, parent });

					routerLink.$mount(anchor);

					// update innerHTML after $mount, otherwise it will be undefined
					routerLink.$el.innerHTML = anchor.innerHTML;
				});
			},

			parseDynamicValues() {
				const dynamicContent = this.innerHTML.match(/\{([^}]*)\}/g)?.map((content) =>
					content
						.replace(/[\{\}]/g, '')
						.split('.')
						.reduce((o, i) => o?.[i] || '', this.$store.state)
				);

				let innerHTML = this.innerHTML.replace(/\{([^}]*)\}/g, () => dynamicContent.shift());
				this.innerHTML = this.$sanitize(innerHTML);
			}
		},

		render(createElement) {
			const options = {
				domProps: { innerHTML: this.innerHTML }
			};
			return createElement('div', options);
		}
	};
</script>
