import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Media
 * @exports Service/Cms/Media
 * @description API Media Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Media {
	/**
	 * @static @name list
	 * @description Get all medias as a list that can be manipulated through post body properties
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post(`cms/media/list`, payload, {
			cache,
			cachePath: `table-cms-media-${domain}`
		});
	}
}
