<template>
	<v-dialog :value="active" eager scrollable :component="$options.name" persistent max-width="800" max-height="600">
		<v-card>
			<v-app-bar dark :color="color">
				<v-toolbar-title>
					{{ config.title }}
				</v-toolbar-title>

				<v-spacer />

				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-app-bar>

			<v-card-text class="dynamic-form-wrapper">
				<p class="text-body-2 mb-0">
					<strong>Complete the form below to add a new CPD item.</strong>
					If adding an <a href="/events" target="_blank">Air Event</a>, start typing the name and the system will automatically scan for matches.
					Select the relevant event to auto-populate the remaining data fields.
					<span class="font-weight-medium">
						To refer to a list of past events, <a href="/events" target="_blank">click here</a>.
					</span>
				</p>

				<v-divider inset class="mt-7 mb-6" />

				<v-row>
					<v-col cols="12">
						<div id="tester" :class="{ 'manual--autocomplete': !menuDisplay }" style="position: relative;">
							<v-autocomplete
								v-model="model"
								:items="mappedSearchResultNames"
								:loading="isLoading"
								:search-input.sync="search"
								color="success"
								item-text="display"
								item-value="id"
								hide-details
								hide-no-data
								label="Name: e.g. Air Later Life Conference"
								return-object
								outlined
								dense
								clearable
								disable-lookup
								attach="#tester"
								@keydown.tab="onKeydown"
								@input="onEventSelected"
								@blur="onBlur"
								@focus="onFocus"
							/>
						</div>
					</v-col>
				</v-row>

				<dynamic-form
					ref="dynamicForm"
					:submit-button-text="config.submitButtonText"
					:submit-button-loading="submitButtonLoading"
					:form-schema="config.formSchema"
					:form-data="data"
					@dynamic-form-submit="submit"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { isEmpty, debounce } from 'lodash';
	import moment from 'moment';
	import { mapActions, mapGetters } from 'vuex';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'common-dialog-dynamic-form-dialog',

		components: {
			DynamicForm
		},

		props: {
			submitButtonLoading: { type: Boolean, default: false },
			color: { type: String, default: 'blue-grey v-app-bar-generic-gradient' }
		},

		data() {
			return {
				active: false,
				data: {},
				config: {
					formSchema: [],
					formData: {},
					submitButtonText: 'Submit'
				},
				model: null,
				searchResults: [],
				isLoading: false,
				search: null,
				menuDisplay: true
			};
		},

		computed: {
			...mapGetters('CmsEvent', ['passedEvents']),

			mappedSearchResults() {
				if (!this.search || !this.search.length) return [];
				return this.searchResults;
			},

			mappedSearchResultNames() {
				if (!this.mappedSearchResults.length) return [];
				return this.mappedSearchResults.map((x) => x.display);
			}
		},

		watch: {
			search(val) {
				// Don't bother if empty
				if (!val || !val.length) return;

				// Items have already been loaded
				if (this.searchResults.length > 0) return;

				// Items have already been requested
				if (this.isLoading) return;

				//this.getEvents(val);
			}
		},

		created() {
			this.getEvents();
		},

		methods: {
			...mapActions('CmsEvent', ['loadEventsList']),

			/**
			 * @name open
			 * @description Show dialog
			 * @param {Object} config
			 * @param {Array} config.formSchema Schema for creating form layout
			 * @param {Object} config.formData Data for populating form
			 * @param {String} config.title Dialog title
			 * @param {String} config.action Action to be dispached after form submission
			 */
			open(config) {
				this.active = true;
				this.config = config;
				this.model = null;
				this.search = config.formData?.name || null;
				this.setFormValues(config.formData);
				this.onBlur();
				// TODO Ilgaz check why next tick is required
				this.$nextTick().then(() => this.$refs.dynamicForm.resetValidation());
			},

			/**
			 * @name setFormValues
			 * @description Iterates the schema and creates the form object with default values and then overrides with actual values
			 */
			setFormValues(formData) {
				this.data = {};

				this.config.formSchema.map((field) => {
					if (typeof field.valueDefaultLazy !== 'undefined') {
						this.$set(this.data, field.name, field.valueDefaultLazy);
					}

					if (typeof field.ageDefaultLazy !== 'undefined') {
						const dateOfBirth = moment().subtract(field.ageDefaultLazy, 'years').format('DD/MM/yyyy');
						this.$set(this.data, field.name, dateOfBirth);
					}
				});

				if (!isEmpty(formData)) {
					Object.keys(formData).map((key) => {
						if (formData[key] !== null || typeof this.data[key] === 'undefined') {
							this.$set(this.data, key, formData[key]);
						} else {
							this.$set(formData, key, this.data[key]);
						}
					});
				}
			},

			/**
			 * @name validateForm
			 * @description Validate dynamic form manually
			 * @param {Array} formSchema Schema for creating form layout
			 * @param {Object} formData Data for populating form
			 * @returns {Boolean} Validation result
			 */
			validateForm(formSchema, formData) {
				let vm = this;

				vm.config.formSchema = formSchema;
				this.data = formData;
				return vm.$nextTick().then(() => vm.$refs.dynamicForm?.validate().then((isValid) => isValid));
			},

			/**
			 * @name submit
			 * @description Submit handler for dynamic form
			 * @param {Object} formData Data to be submitted
			 */
			submit(formData) {
				this.active = false;

				formData.name = this.model;

				this.$emit('dynamic-form-submit', {
					data: formData,
					dataIndex: this.config.dataIndex,
					config: this.config
				});
			},

			closeDialog() {
				this.active = false;
				this.$emit('closed', this.config);
			},

			/**
			 * @name getEvents
			 * @description Send request to API
			 */
			getEvents: debounce(async function() {
				this.isLoading = true;
				await this.loadEventsList();
				this.searchResults = this.passedEvents.filter(e => e?.cpdPoints !== null).map((e) => { return { ...e, display: `${e.name} - ${this.$moment(String(e.start)).format('ddd, Do MMM YYYY h:mma')}` } } );
				this.isLoading = false;
			}, 500),

			/**
			 * @name onEventSelected
			 * @description TODO
			*/
			onEventSelected() {
				let event = this.mappedSearchResults.find((x) => x.display === this.model);

				const newFormValues = {
					name: event?.name
				};
				if (event?.id) {
					newFormValues.issued = moment(event.start).format('DD/MM/YYYY');
					newFormValues.points = event.cpdPoints;
					newFormValues.type = event.cpdType;
				}
				this.setFormValues(newFormValues);
			},

			/**
			 * @name reset
			 * @description TODO
			*/
			reset() {
				this.model = null;
				this.search = null;
				this.setFormValues();
				this.removeManuallyAddedItems();
			},

			/**
			 * @name onBlur
			 * @description Override autocomplete default functionality to ensure text with no mathcing result isn't cleared on blue
			*/
			onBlur() {
				this.removeManuallyAddedItems();

				// If item isn't in list, add to list as manual entry and then hardcode to model
				if (!this.searchResults.find((e) => e.display == this.search)) {
					this.menuDisplay = false;
					this.searchResults.push({
						manuallyAdded: true,
						display: this.search
					});
				}

				this.model = this.search;
			},

			/**
			 * @name onFocus
			 * @description Override autocomplete default functionality to prevent flash of menu UI when no mathcing result
			*/
			onFocus() {
				this.menuDisplay = true;
				this.removeManuallyAddedItems();
			},

			/**
			 * @name onKeydown
			 * @description Override autocomplete default functionality to ensure text with no mathcing result isn't cleared on tab key press
			*/
			onKeydown(e) {
				if (e.code.toLowerCase() === 'tab') {
					// If item isn't in list, add to list as manual entry and then hardcode to model
					if (!this.searchResults.find((e) => e.display == this.search)) {
						this.menuDisplay = false;
						this.searchResults.push({
							manuallyAdded: true,
							display: this.search
						});
						this.model = this.search;
					}
				}
			},

			/**
			 * @name removeManuallyAddedItems
			 * @description Remove all manually added autocomplete entries
			*/
			removeManuallyAddedItems() {
				this.searchResults = this.searchResults.filter((e) => !e.manuallyAdded );
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-app-bar {
		background-size: cover;
	}

	.header {
		font-size: 1.1rem;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
	}

	.footer {
		background-color: #f4f4f4;
	}

	.manual--autocomplete {
		::v-deep .v-autocomplete__content {
			display: none !important;
		}
	}
</style>
