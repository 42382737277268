import { render, staticRenderFns } from "./item-action.vue?vue&type=template&id=4cfda384&scoped=true&"
import script from "./item-action.vue?vue&type=script&lang=js&"
export * from "./item-action.vue?vue&type=script&lang=js&"
import style0 from "./item-action.vue?vue&type=style&index=0&id=4cfda384&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cfda384",
  null
  
)

export default component.exports