/**
 * @namespace Utils
 * @class ElementTools
 * @exports Utils/ElementTools
 * @description Util for housing all element based tools with consolidating element functions
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class ElementTools {
	/**
	 * @static @name fireEvent
	 * @description Fire an event on an element scope, allows bubbling of the event to occur up to root
	 */
	static fireEvent(scope, name, detail, bubble) {
		scope.dispatchEvent(new CustomEvent(name, { detail, bubbles: bubble || false, composed: bubble || false }));
	}

	/**
	 * @static @name fireNotification
	 * @description Fire a notification event up the tree, bubbling until its caught by the root element and farmed out to notifcation component
	 */
	static fireNotification(scope, type, detail, options) {
		// resolve single object, or color and object/message
		detail = typeof type !== 'string' ? type : { type, ...(typeof detail === 'object' && !detail.length ? detail : { text: detail, ...options }) };
		scope.dispatchEvent(new CustomEvent('notification', { detail, bubbles: true, composed: true }));
	}

	/**
	 * @static @name hideNotification
	 * @description Hide a notification event up the tree, bubbling until its caught by the root element and farmed out to notifcation component
	 */
	static hideNotification(scope) {
		scope.dispatchEvent(new CustomEvent('notification', { detail: { hide: true }, bubbles: true, composed: true }));
	}
}
