import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Client
 * @exports Service/App/Client
 * @description API Client Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Client {
	/**
	 * @static @name list
	 * @description Get all clients
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('client/list', payload);
	}

	/**
	 * @static @name get
	 * @description Get client details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`client/${id}`);
	}

	/**
	 * @static @name delete
	 * @description Hard delete client details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`client/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new client resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`client`, payload);
	}

	/**
	 * @static @name patch
	 * @description Patch existing client
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch({ id, payload }) {
		return $http.patch(`client/${id}`, payload);
	}
}
