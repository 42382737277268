<!--
	@name app-layout-default
	@description Front page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-layout v-if="loadingApp" align-content-center justify-center fill-height wrap>
		<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
	</v-layout>
	<v-app v-else>
		<!-- header -->
		<select-header />

		<!-- content -->
		<v-main>
			<app />
		</v-main>

		<!-- footer -->
		<select-footer />
	</v-app>
</template>

<script>
	import App from '@/component/app';
	import SelectHeader from './header';
	import SelectFooter from './footer';

	export default {
		name: 'app-layout-default',

		components: {
			App,
			SelectHeader,
			SelectFooter
		}
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		background: #fafafa !important;
	}
</style>
