<!--
	@name app-account-settings-details
	@description Account settings - Account details tab content
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-5">
		<p class="text-body-1 mb-6">
			Here you can amend your personal details. If you wish to make a change, amend the necessary information and click on Update Details at the bottom.
		</p>

		<v-card v-if="!form" class="flex-grow-1">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>

		<div v-else>
			<common-structure-section class="flex-grow-1 elevation-2">
				<template #header>
					<slot>Your Details</slot>
				</template>

				<template #body>
					<dynamic-form
						ref="dynamicForm"
						submit-button-text="Update Details"
						:form-schema="form('account-details').value"
						:form-data="formData"
						:submit-button-loading="submitting"
						@dynamic-form-submit="submit"
					/>
				</template>
			</common-structure-section>
		</div>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-account-settings-tab-details',

		components: {
			CommonStructureSection,
			DynamicForm
		},

		data() {
			return {
				submitting: false,
				formData: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.setFormData();
			this.loadForm('account-details');
		},

		methods: {
			...mapActions('Account', ['loadUserDetails', 'modifyUser', 'modifyUserAddress', 'modifyUserDetail']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsContent', ['loadContent']),

			setFormData() {
				this.formData = {
					name: this.user.name,
					address1: this.user.userAddress?.addressLine1,
					address2: this.user.userAddress?.addressLine2,
					town: this.user.userAddress?.townCity,
					postcode: this.user.userAddress?.postalCode,
					mobile: this.user.userDetail?.mobile
				};
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submit(data) {
				this.submitting = true;

				Promise.all([
					this.modifyUser({
						name: data.name
					}),
					this.modifyUserAddress({
						addressLine1: data.address1,
						addressLine2: data.address2,
						townCity: data.town,
						postalCode: data.postcode
					}),
					this.modifyUserDetail({
						mobile: data.mobile
					})
				])
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Updated details successfully');
						this.loadContent('dashboard');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update details'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>
