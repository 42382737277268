<!--
	@name app-source-result-mortgage-item-total
	@description Lifetime mortgage product item total to pay
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="item.restriction && item.restriction.type === 'hard'">
			--
		</div>
		<div v-else>
			<span class="text-body-2 font-weight-medium">
				£{{ item.totalToPay | numFormat('0,0') }}
			</span>
			<v-tooltip bottom content-class="text-center" max-width="300">
				<template #activator="{ on }">
					<span class="text-caption text-tooltip" v-on="on">
						£{{ item.upfrontFee | numFormat }} / £{{ item.completionFee.amount | numFormat }}
					</span>
				</template>
				<span>
					<span class="d-block mt-1 text-decoration-underline">
						Fees
					</span>
					£{{ item.upfrontFee | numFormat }} up front
					<br />
					£{{ item.completionFee.amount | numFormat }} on completion
					<span v-if="completionFeeWarning" class="text-caption plain d-block mt-1" style="line-height: 1rem"> n.b. {{ completionFeeWarning }} </span>
				</span>
			</v-tooltip>
			<v-tooltip bottom content-class="text-center" max-width="280">
				<template #activator="{ on }">
					<span class="text-tooltip" v-on="on" @click="openProductCostDialog(item)">
						<span
							class="ga--trigger ga--product-cost-opened text-caption"
							data-ga-id="total-column"
						>
							View details
						</span>
					</span>
				</template>
				<span>
					<span class="d-block mb-1 text-decoration-underline">
						Total Cost
					</span>
					<div>
						£{{ item.totalToPay | numFormat('0,0') }}
					</div>
					<span class="d-block mt-1 font-weight-bold">
						Click for full breakdown
					</span>
				</span>
			</v-tooltip>
			<v-tooltip v-if="item.totalToPayExplanation != null" bottom content-class="text-center" max-width="300">
				<template #activator="{ on }">
					<div class="mt-1">
						<v-chip color="purple lighten-1" text-color="white" label x-small active v-on="on">
							See more
						</v-chip>
					</div>
				</template>
				{{ item.totalToPayExplanation }}
			</v-tooltip>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-total',

		props: {
			item: { type: Object, required: true }
		},

		computed: {
			/**
			 * @name completionFeeWarning
			 * @description TODO
			 * @return {String|Boolean} TODO
			 */
			completionFeeWarning() {
				if (!this.item.completionFee || !this.item.completionFee.message) return false;
				return this.item.completionFee.message;
			}
		},

		methods: {
			/**
			 * @name openProductCostDialog
			 * @description TODO
			 * @param {TODO} item TODO
			 */
			 openProductCostDialog(item) {
				this.$emit('open-product-cost-dialog', item);
			}
		}
	};
</script>
