import { RewardCredit, RewardDebit, RewardTransaction } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Reward {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadRewardTransactionList
			 * @param {function} commit
			 */
			async loadRewardTransactionList(store, payload) {
				const { data } = await RewardTransaction.list(payload);
				return data.data;
			},

			/**
			 * @async @name loadRewardList
			 * @param {function} commit
			 */
			async loadRewardCreditList(store, payload) {
				const { data } = await RewardCredit.list(payload);
				return data;
			},

			/**
			 * @async @name loadRewardDebitList
			 * @param {function} commit
			 */
			async loadRewardDebitList(store, payload) {
				const { data } = await RewardDebit.list(payload);
				return data.data;
			},

			/**
			 * @async @name rewardRedeem
			 * @param {Object} store
			 * @param {Object} data
			 */
			async rewardRedeem(store, payload) {
				return await RewardTransaction.post(payload);
			},

			/**
			 * @async @name voucherRedeem
			 * @param {Object} store
			 * @param {Object} data
			 */
			async voucherRedeem(store, { transactionId, data }) {
				return await RewardTransaction.patch(transactionId, { ...data, actioned: true });
			}
		};
	}
}
