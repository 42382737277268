<!--
	@name app-password-recovery
	@description Password recovery page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row v-if="site" no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="site" to="/">
					<v-img class="mx-auto" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
				</router-link>

				<p class="text-h5 font-weight-bold mt-8 mb-3">
					Password Recovery
				</p>
				<p class="text-body-2">
					Enter your CURRENT USERNAME in order to proceed through the password recovery process. If you have forgotten your current username, please contact us on 08002
					945097 to reset your log in details. Alternatively, email {{ site.contact.email }} and request a call back.
				</p>
			</v-col>
		</v-row>

		<v-card class="mx-auto" max-width="500">
			<v-sheet class="body pa-5">
				<div v-if="success" class="text-center">
					<v-icon x-large color="success" class="mb-2">
						mdi-check
					</v-icon>
					<p class="text-body-1 font-weight-bold mb-2">
						Your request has been successfully submitted
					</p>
					<p v-if="successMessage" class="text-body-2 mb-5">
						{{ successMessage }}
					</p>
					<v-btn color="primary" block large class="mt-3 mx-auto d-flex" :to="loginUrl">
						Proceed to log in
					</v-btn>
				</div>
				<div v-else>
					<dynamic-form
						ref="loginForm"
						:form-schema="form('password_recovery').value"
						:form-data="formData"
						:submit-button-loading="loading"
						submit-button-text="Continue"
						:submit-button-block="true"
						:submit-button-large="true"
						@dynamic-form-submit="submit"
					/>
					<v-btn color="blue-grey lighten-1" text class="mt-3 mx-auto text-none d-flex mb-0" :to="loginUrl">
						Return to login
					</v-btn>
				</div>
			</v-sheet>
		</v-card>
	</div>
</template>

<script>
	import DynamicForm from '@/component/common/base/dynamic-form';
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';

	export default {
		name: 'app-password-recovery',

		components: { DynamicForm },

		data() {
			return {
				formData: {},
				loading: false,
				success: false,
				successMessage: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),

			currentRouteName() {
				return this.$route.name;
			},

			isAdviser() {
				return this.currentRouteName === 'passwordRecovery';
			},

			isProvider() {
				return this.currentRouteName === 'providerPasswordRecovery';
			},

			loginUrl() {
				if (this.isProvider) return '/providers';
				return '/advisers';
			}
		},

		created() {
			this.loadForm('passwordRecovery');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Account', ['resetPasswordRequest']),

			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			async submit() {
				this.loading = true;

				this.resetPasswordRequest({
					identity: this.formData.username,
					identityType: 'username'
				})
					.then(() => {
						this.success = true;
						this.successMessage = 'An email will be sent to your registered email address shortly containing a password reset link.';
					})
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error.data?.message || 'An error occured. Please try again later.'))
					.finally(() => (this.loading = false));
			}
		}
	};
</script>
