<!--
	@name app-insights-hub-content-detail
	@description Insights Hub Content Detail
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div>
			<v-container>
				<div class="pb-8">
					<div id="background" />

					<v-container style="max-width: 950px; position: relative; z-index: 4">
						<v-sheet class="pa-14 mt-12">
							<!-- Breadcrumbs -->
							<v-breadcrumbs :items="breadcrumbs" class="pl-0">
								<template #divider>
									<v-icon>mdi-chevron-right</v-icon>
								</template>
							</v-breadcrumbs>

							<div v-if="loadingInsightItem">
								<v-skeleton-loader type="heading" />
								<v-skeleton-loader type="text" class="mt-4" />
								<v-skeleton-loader type="image" class="mt-4" />
								<v-skeleton-loader class="mt-4 mx-n4" type="list-item-three-line@3" />
							</div>

							<div v-else>
								<div class="text-h4 font-weight-light">
									{{ insight?.name }}
								</div>

								<div class="text-body-2 font-weight-bold info--text mt-4">
									Insights Hub
								</div>

								<div class="text-body-1 mt-4">
									Posted on {{ insight?.created | moment('DD MMMM YYYY') }}
								</div>

								<!-- image here -->
								<div class="mt-8">
									<img :src="insight?.image || 'placeholder/gradient.png' | urlize" width="100%" alt="" />
								</div>

								<!-- html content here -->
								<div class="mt-8" v-html="insight?.story" />

								<v-divider class="mt-8" />

								<div class="d-flex pt-6">
									<router-link to="/insights-hub">
										<v-icon color="secondary">
											mdi-arrow-left
										</v-icon>
									</router-link>
									<v-spacer />
									<div class="d-flex">
										<div class="text-body-2 font-weight-black mr-2">
											Share:
										</div>
										<ul class="d-flex pl-0">
											<li class="d-block mr-2">
												<a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank">
													<v-icon size="24" color="secondary">mdi-facebook</v-icon>
												</a>
											</li>
											<li class="d-block mr-2">
												<a :href="`https://twitter.com/intent/tweet?url=${url}`" target="_blank">
													<v-icon size="24" color="secondary">mdi-twitter</v-icon>
												</a>
											</li>
											<li class="d-block mr-2">
												<a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}`" target="_blank">
													<v-icon size="24" color="secondary">mdi-linkedin</v-icon>
												</a>
											</li>
											<li class="d-block mr-2">
												<a :href="`https://wa.me/?text=${url}`" target="_blank">
													<v-icon size="24" color="secondary">mdi-whatsapp</v-icon>
												</a>
											</li>
											<li class="d-block mr-2">
												<a @click="copyLink">
													<v-icon size="24" color="secondary">mdi-link-variant</v-icon>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</v-sheet>

						<!-- Related articles -->
						<div>
							<div class="text-h5 text-center">
								Related articles
							</div>

							<div v-if="loadingInsightList">
								<v-row>
									<v-col v-for="i in 3" :key="i" md="4" sm="12" xs="12" class="mt-4">
										<v-skeleton-loader type="card" />
										<v-skeleton-loader type="list-item-three-line" />
									</v-col>
								</v-row>
							</div>
							<v-row v-else class="mt-4">
								<v-col v-for="(item, index) in relatedArticles" :key="index" md="4" sm="12" xs="12" class="mt-4">
									<v-card class="d-flex flex-column fill-height mx-auto">
										<v-img :src="item.image || 'placeholder/gradient.png' | urlize" min-height="200px" max-height="200px" />

										<div class="pa-4">
											<div class="text-body-2">
												Insights Hub
											</div>

											<div class="mt-4 text-h5 secondary--text line-clamp" :title="item.name">
												{{ item.name }}
											</div>

											<div class="text-body-2 mt-4 line-clamp" v-html="item.headline" />
										</div>

										<v-spacer />
										<div>
											<v-btn :to="`/insights-hub/${item.id}`" color="info" small class="ml-4 mb-4">
												Read more
											</v-btn>
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</v-container>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-insights-hub-content-detail',

		components: {},

		data() {
			return {
				breadcrumbs: [{ text: 'Insights Hub', exact: true, to: '/insights-hub' }, {}],
				insight: null,
				relatedArticles: [],
				loadingInsightItem: true,
				loadingInsightList: true
			};
		},

		computed: {
			...mapState('CmsInsight', ['insightList']),

			url() {
				return encodeURIComponent(window.location.href);
			}
		},

		watch: {
			'$route.params.id'() {
				this.init();
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsInsight', ['loadInsightItem', 'loadInsightList']),

			init() {
				this.loadingInsightItem = true;
				this.loadingInsightList = true;

				this.loadInsightItem(this.$route.params.id).then(({ data }) => {
					this.insight = data;
					this.loadingInsightItem = false;
				});

				this.loadInsightList().then(() => {
					const items = this.insightList.filter((item) => item.id !== this.$route.params.id).slice(0, 3);
					this.relatedArticles = items;
					this.loadingInsightList = false;
				});
			},

			copyLink() {
				navigator.clipboard
					.writeText(window.location.href)
					.then(() => ElementTools.fireNotification(this.$el, 'success', `Link copied to clipboard`))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `Failed to copy link to clipboard`));
			}
		}
	};
</script>

<style lang="scss" scoped>
	#background {
		background-image: linear-gradient(to right, #470048, #1b78d8);
		width: 100%;
		height: 600px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}

	.line-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
</style>
