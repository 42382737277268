<!--
	@name app-client-dashboard-workflow-application
	@description Client Workflow Application Item
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<app-client-dashboard-workflow-template
			:workflow-item="workflowItem"
			@close-workflow-item="$emit('close-workflow-item')"
			@update-status="$emit('update-status', $event)"
		>
			<template slot="activity">
				<app-client-dashboard-workflow-application-activity
					:client="client"
					:asset="asset"
					:association="association"
					:intro-resource="introResource"
					@toggle-intro-resource="toggleIntroResource"
					@get-started="getStarted"
				/>
			</template>

			<template v-if="site && (!('usesAirFlow' in site) || site.usesAirFlow)" slot="resources">
				<app-client-dashboard-workflow-template-resources :section="section" :resources="resources" :remove-intro-video="removeIntroVideo" />
			</template>
		</app-client-dashboard-workflow-template>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import AppClientDashboardWorkflowTemplate from '@/component/app/client/dashboard/workflow/template';
	import AppClientDashboardWorkflowApplicationActivity from '@/component/app/client/dashboard/workflow/application/activity';
	import AppClientDashboardWorkflowTemplateResources from '@/component/app/client/dashboard/workflow/template/resources';
	import courseVideoMixin from '@/mixins/course-video';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-client-dashboard-workflow-application',

		components: {
			AppClientDashboardWorkflowTemplate,
			AppClientDashboardWorkflowApplicationActivity,
			AppClientDashboardWorkflowTemplateResources
		},

		mixins: [courseVideoMixin],

		props: {
			workflowItem: { type: Object, required: true },
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			resources: { type: Array, required: true }
		},

		data() {
			return {
				section: 'application'
			};
		},

		computed: {
			...mapState('CmsSite', ['site'])
		},

		methods: {
			/**
			 * @name getStarted
			 */
			 getStarted() {
				ElementTools.fireNotification(
					this.$el,
					'warning',
					{ text: `Before you can begin an Application, you must first complete the Key Facts Illustration stage`, timeout: 8000 }
				);
				this.$router.push({ query: Object.assign({}, this.$route.query, { workflowSection: this.workflowItem.prev.id }) });
			}
		}
	};
</script>

<style scoped lang="scss"></style>
