<!--
	@name app-account-settings
	@description Account Page
	@date 2020/04/24
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						My Account
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<p class="text-h5 mb-2 font-weight-regular">
						View and edit the details we store about you from <span class="font-weight-bold">the 'Details' tab</span>
					</p>
					<p class="text-body-1 mb-0">
						The 'Security' tab is used to update your critical account information and protect your account with 2FA. Use the 'Credentials' tab to connect your account
						with our many integrated partners, as well as to store your lender portal login details. The 'Preferences' tab gives you complete control over how we'll
						contact you about our services and special offers.
					</p>
				</div>

				<!-- Tabs -->
				<common-base-tab-view :tabs="tabs" :loading="!loaded" router-view dark />
			</v-col>

			<v-col class="py-0" cols="6" md="4">
				<common-link-shortcuts v-if="!isAdmin" :items="shortcutAccount.list" />

				<common-promotion-advisor-advert />
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import CommonLinkShortcuts from '@/component/common/link/shortcuts';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';

	export default {
		name: 'app-account-settings',

		components: {
			CommonBaseTabView,
			CommonLinkShortcuts,
			CommonPromotionAdvisorAdvert
		},

		data() {
			return {
				loaded: false,
				tabs: [
					{ name: 'settings.details', title: 'Details', alerts: 0, path: '/settings/details' },
					{ name: 'settings.security', title: 'Security', alerts: 0, path: '/settings/security' },
					{ name: 'settings.credentials', title: 'Credentials', alerts: 0, path: '/settings/credentials' },
					{ name: 'settings.preferences', title: 'Preferences', alerts: 0, path: '/settings/preferences' }
				]
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['isAdmin']),
			...mapGetters('CmsShortcut', ['shortcutAccount']),
			...mapGetters('CmsData', ['data']),

			hasBanner() {
				return this.site.adverts?.login;
			}
		},

		watch: {
			'$route.name': {
				handler(name) {
					if (name === 'settings') this.$router.push('/settings/details');
				},
				deep: true,
				immediate: true
			}
		},

		created() {
			this.loadUserDetails(this.user.id).then(() => (this.loaded = true));
			this.loadShortcut('account');
			if (this.isAdmin) this.tabs = this.tabs.filter((tab) => tab.name !== 'settings.credentials');
		},

		methods: {
			...mapActions('Account', ['loadUserDetails']),
			...mapActions('CmsShortcut', ['loadShortcut'])
		}
	};
</script>

<style lang="scss" scoped>
	.v-slide-group {
		::v-deep .v-slide-group__content {
			justify-content: center;
		}
	}
</style>
