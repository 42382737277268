<!--
	@name app-reward-charity
	@description Rewards page charities
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name">
		<template #header>
			Our Chosen Charities
		</template>
		<template #body>
			<div v-if="!charities" class="flex-grow-1">
				<v-skeleton-loader type="list-item-three-line" />
			</div>
			<div v-else>
				<p class="mb-4 text-body-1">
					For any point donations made to our chosen charities, Air will also make a donation of 10% of your total.
				</p>
				<v-tooltip v-for="(item, index) in charities" :key="index" bottom>
					<template #activator="{ on }">
						<v-card v-show="index === visibleIndex" class="pa-0" :color="item.data.color" flat elevation="3" :dark="item.data.dark" :href="item.data.url" target="_blank" v-on="on">
							<v-card-title class="text-body-1 font-weight-black pa-4 justify-center" v-text="item.name" />
							<v-avatar class="pa-3 grey lighten-5 mx-auto" :size="item.data.avatar.size" width="100%" tile>
								<v-img :src="item.data.avatar.path | urlize" :height="item.data.avatar.imageHeight" contain />
							</v-avatar>
							<v-card-text class="text-body-2 pa-4 text-center" v-text="item.description" />
						</v-card>
					</template>
					<span>Click here to find out more about {{ item.name }}</span>
				</v-tooltip>
			</div>
		</template>
	</common-structure-section>
</template>

<script>
	import { mapActions } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-reward-charity',

		components: { CommonStructureSection },

		data() {
			return {
				charities: null,
				visibleIndex: 0
			};
		},

		created() {
			this.loadRewardDebitList({ where: { entity_type: 'charity' }}).then(data => this.charities = data);
		},

		mounted() {
			setInterval(() => (this.visibleIndex =  this.charities && this.visibleIndex === this.charities.length - 1 ? 0 : this.visibleIndex + 1), 10000);
		},

		methods: {
			...mapActions('AppReward', ['loadRewardDebitList'])
		}
	};
</script>
