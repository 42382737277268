import Calculator from '@/component/app/calculator';
import BorrowingForecastTool from '@/component/app/calculator/borrowing-forecast-tool';
import FloodRiskReport from '@/component/app/calculator/flood-risk-report';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'tools',
		path: '/tools',
		component: Calculator,
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		children: [
			{
				name: 'tools.borrowingForecastTool',
				path: 'borrowing-forecast-tool',
				meta: { layout: 'default-authed' },
				component: BorrowingForecastTool
			},
			{
				name: 'tools.floodRiskReport',
				path: 'flood-risk-report',
				component: FloodRiskReport,
				meta: { layout: 'default-authed' }
			}
		]
	}
];
