import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Product
 * @exports Service/LifetimeMortgage/[id]/Notes
 * @description API Product Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Product {
	/**
	 * @static @name get
	 * @description Get product notes
	 * @param {String} iterationId
	 * @param {String} productId
	 * @returns {Object} Promise a http response
	 */
	static get(id, iterationId) {
		return $http.get(`product/lifetime-mortgage/${id}/notes/${iterationId}`);
	}
}
