<template>
	<v-sheet color="white" class="header">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="3">
				<v-img max-width="8rem" class="mr-5" :src="'logo/brand/select.svg' | urlize" />
			</v-col>
			<v-col cols="12" sm="9">
				<div class="d-flex justify-end" style="height: 100%">
					<div v-for="(navigationItem, index) in navigationItems" :key="index" class="flex-grow-0 align-self-center">
						<v-btn class="px-3 text-none text-h6" text>
							{{ navigationItem.text }}
						</v-btn>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
	export default {
		name: 'app-layout-default-header',

		data() {
			return {
				navigationItems: [
					{
						type: 'phone',
						text: '01334 555 666'
					},
					{
						type: 'email',
						text: 'support@airselect.co.uk'
					}
				]
			};
		}
	};
</script>
