<!--
	@name app-air-academy-libf
	@description Air Academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section>
			<template #header>
				<slot>
					LIBF
				</slot>
			</template>

			<template #body>
				<p class="text-body-2 mb-0">
					Air Academy is a Trusted Partner of the London Institute of Banking and Finance. Find out more below from Paul Glynn (CEO, Air) and John Somerville (Director of Financial Services, LIBF).
				</p>
				<app-air-academy-training-webinar-item class="mt-4" :item="testimonial" @play-video="playVideo" />
				<app-dialog-video-player ref="videoDialog" />
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';
	import AppDialogVideoPlayer from '@/component/app/dialog/video-player';

	export default {
		name: 'app-air-academy-libf',

		components: {
			CommonStructureSection,
            AppAirAcademyTrainingWebinarItem,
            AppDialogVideoPlayer
		},

		data() {
			return {};
		},

		computed: {
            testimonial() {
				return {
					name: 'LIBF Trusted Partner',
					image: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyTrustedPartner.png`,
					mimetype: 'video/mp4',
					hideStatus: true,
					fixedHeight: true,
					emit: 'play-video'
				};
			}
		},

		created() {},

		methods: {
            playVideo() {
				this.$refs.videoDialog.open(`${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyTrustedPartner.mp4`);
			}
        }
	};
</script>

<style lang="scss" scoped>
</style>