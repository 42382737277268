import { StateBenefit } from '@/service';

/**
 * @class @name StateBenefit
 * @description Exposes menu store module
 */
export default class StateBenefitStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @name loadStateBenefitList
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async loadStateBenefitList(context, payload) {
				return StateBenefit.list(payload).then(({ data }) => data);
			},

			/**
			 * @name submitStateBenefit
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async submitStateBenefit(context, payload) {
				return StateBenefit.post(payload);
			}
		};
	}
}
