<!--
	@name app-dashboard-product-changes
	@description Product changes component
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" style="height:100%" class="white">
		<common-structure-section :header-class="'background-rotation-manual--3'" :dark-header="true">
			<template #header>
				OnAir
			</template>

			<template #body>
				<v-card v-if="!changelogItems?.length" class="flex-grow-1">
					<v-skeleton-loader type="list-item-three-line" />
				</v-card>
				<div v-else>
					<p class="text-body-2 mb-4">
						A central resource to keep track of system improvements, new features and future releases - complete with video demonstrations. OnAir is now broadcasting 24/7...
						<a class="font-weight-bold font-decoration-underline" @click="$router.push(`/on-air`)">
							come take a look!
						</a>
					</p>
					<v-card hover to="/on-air" color="grey lighten-3">
						<v-chip color="accent" label small class="font-weight-medium black--text" style="position: absolute; top: -12px; right: -8px">
							Latest
						</v-chip>
						<v-row no-gutters class="py-3 px-4">
							<v-col class="grow py-0">
								<p class="text-body-2 mb-0">
									<span class="text-body-1 font-weight-bold">
										{{ log.title | truncate(80) }}
									</span>
								</p>
							</v-col>
							<v-col class="shrink py-0 align-self-center">
								<p class="text-caption font-weight-light grey--text text--darken-4 mb-0 nowrap">
									{{ timestamp }}
								</p>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-dashboard-product-list',

		components: {
			CommonStructureSection
		},

		data() {
			return {
				includeComingSoon: true
			};
		},

		computed: {
			...mapState('Account', ['changelogItems']),

			log() {
				if (!this.changelogItems) return null;
				if (this.includeComingSoon) return this.changelogItems[0];
				return this.changelogItems?.filter((item) => !this.$moment(item.posted).isAfter(this.$moment()))[0];
			},

			timestamp() {
				if (!this.log) return null;
				if (this.$moment(this.log.posted).isAfter(this.$moment())) return 'Coming soon';
				return `Posted: ${this.$moment(String(this.log.posted )).format('MMM Do YYYY')}`;
			}
		},

		created() {
		},

		methods: {
		}
	};
</script>
