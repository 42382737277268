<!--
	@name app-layout-authed-blank
	@description Authed blank page layout
	@date 2024/09/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-layout v-if="!authed" align-content-center justify-center fill-height wrap>
		<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
	</v-layout>
	<v-app v-else>
		<!-- content -->
		<v-main>
			<app />
		</v-main>
	</v-app>
</template>

<script>
	import App from '@/component/app';
	import { mapGetters, mapState } from 'vuex';

	export default {
		name: 'app-layout-authed-blank',

		components: { App },

		computed: {
			...mapGetters('Account', ['authed']),
			...mapState('Account', ['user'])
		},

		created() {
			if (this.user.type === 'advisor') this.$connectWebSocket();
		},

		destroyed() {
			this.$closeWebSocket();
		}
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		background: #fff !important;
	}
</style>
