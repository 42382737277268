<!--
	@name app-common-terms-default
	@description Terms
	@date 2020/11/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<div v-if="site">
			<p><strong>Please read in conjunction with Our Privacy Notice available <a @click="openPrivacy">[here]</a></strong></p>
			<p><strong>Definitions</strong><br />In these Terms and Conditions (“Agreement”) the following terms shall have the meanings set out below:</p>
			<p>
				<strong>{{ site.softwareName }}</strong>&nbsp;or&nbsp;<strong>Websites</strong>&nbsp;means the websites under the domain name {{ website_name }} or any other domain we may use that point to the
				{{ site.softwareName }} software. {{ site.softwareName }} is a trading style of {{ site.company.name }};
			</p>
			<p><strong>Company, Us, Our, Ourselves or We</strong> means Answers in Retirement Limited;</p>
			<p>
				<strong>Authorised User or User</strong> means financial intermediaries authorised and regulated by the Financial Conduct Authority who have registered and been
				approved to access the Services of the {{ site.softwareName }} websites or an employee or agent of the Customer who is authorised to access the Services;
			</p>
			<p><strong>Customer Personal Data</strong>&nbsp;means Personal Data relating to the end customer provided by their intermediary on Our website/s;</p>
			<p>
				<strong>Intermediary Personal Data</strong>&nbsp;means Personal Data relating to Authorised Users or Users provided to the Company in the course of the provision of
				the Services and in relation to which the Customer, a member of the Customer&rsquo;s Group, or an Authorised User of {{ site.softwareName }} acts as Controller.
				&lsquo;Intermediary Personal Data&rsquo; will include Personal Data which is provided in order to register Authorised Users for {{ site.softwareName }}, and
				Personal Data which is input into {{ site.softwareName }} in the course of using and receiving the Services;
			</p>
			<p><strong>Processor</strong>&nbsp;means an organisation that processes personal data on behalf of another organisation;</p>
			<p><strong>Authorised Processor</strong> means a the Company and any Sub-processor engaged by the Company in accordance with clause 6;</p>
			<p><strong>Subprocessor</strong> means any person or company appointed to process Data on another&rsquo;s behalf;</p>
			<p>
				<strong>Services or Resources</strong> means the services made available via {{ site.softwareName }}, including (without limitation) the ability to obtain online
				quotes and comparisons relating to financial products, and to submit and track new business;
			</p>
			<p>
				<strong>Data Privacy Legislation</strong> means (i) the Data Protection Act 2018; (ii) the United Kingdom General Data Protection Regulations (UK GDPR) as
				established by the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2019 and (iii) any other directly applicable law
				or regulation relating to privacy and the protection of personal data (including with regards to electronic communications).
			</p>
			<p>
				<strong>UK GDPR</strong> means the United Kingdom General Data Protection Regulations (UK GDPR) as established by the Data Protection, Privacy and Electronic
				Communications (Amendments etc.) (EU Exit) Regulations 2019.
			</p>
			<p><strong>Third Party Product Provider/s</strong> means a third party who makes available certain financial products and/or services via {{ site.softwareName }};</p>
			<p><strong>You or Your</strong> means any person who accesses and uses the Websites;</p>
			<p>
				The terms <strong>“Controller”, “Processor”, “Data Subject”, “Personal Data”, “Personal Data Breach”, “Supervisory Authority”</strong> and
				<strong>“processing”</strong> shall have the meaning given to those terms in the Data Privacy Legislation, and <strong>“process”</strong> and
				<strong>“processed”</strong> shall be construed accordingly.
			</p>
			<p>
				<strong>2. Acceptance of Terms</strong><br />By accessing the {{ site.softwareName }} Websites or using any of the resources provided by these Websites You agree to
				be bound by these Terms and Conditions and acknowledge the terms of Our Privacy Notice. If We make a change to this Agreement or Our Privacy Notice We will inform
				Authorised Users by publishing an announcement on {{ site.softwareName }}. The amended Agreement or Privacy Notice will be effective from the date they are posted
				on {{ site.softwareName }}.
			</p>
			<p>
				<strong>3. License</strong><br />
				<strong>3.1.</strong> You are permitted to print and download extracts from the Website for Your own use on the following basis:
			</p>
			<p>
				no material or related graphics on the Websites are modified by You in any way;<br />no graphics on the Websites are used by You separately from the corresponding
				text; and<br />the Company's copyright and trade mark notices and this permission notice appear in all copies that You make of extracts from the Website.
			</p>
			<p>
				<strong>3.2.</strong> Unless otherwise stated, the copyright and other intellectual property rights in all material on the Website (including without limitation
				graphical images) are owned by the Company or its licensors. For the purposes of this legal notice, any use of extracts from the Website other than in accordance
				with clause 3.1 for any purpose is prohibited. If You breach any of the terms in this legal notice, your permission to use the websites will be terminated and you
				must immediately destroy any downloaded or printed extracts from the website.
			</p>
			<p>
				<strong>3.3.</strong> Subject to clause 3.1, no part of the Website may be reproduced or stored in any other website or included in any public or private electronic
				retrieval system or service without the Company's prior written permission.
			</p>
			<p><strong>3.4.</strong> Any rights not expressly granted in these terms are reserved.</p>
			<p>
				<strong>4. Registration</strong><br /><strong>4.1.</strong> When You register with {{ site.softwareName }} You are responsible for safeguarding your username and
				password. Please ensure that you keep your password secure and do not reveal it to anyone. If you believe your password has been compromised then please take action
				to change it immediately. We reserve the right to cancel your registration with {{ site.softwareName }} where we have evidence to believe that you are not an
				intermediary authorised and regulated by the Financial Conduct Authority to conduct regulated business. We will notify you in advance and allow you to provide
				evidence to the contrary.
			</p>
			<p>
				<strong>4.2.</strong> Each registration is for one Authorised User only. The Company does not permit you to share Your user name and password with any other person
				nor with multiple users on a network.
			</p>
			<p>
				<strong>5. Intermediary Group</strong><br />If the Personal Data being processed is data in relation to which a member of Our Group acts as Controller, then all
				rights afforded to the Intermediary and all obligations applicable to the Intermediary in this document shall also be deemed to apply to the relevant Intermediary
				Group member. Notwithstanding the foregoing, any claims under these Terms and Conditions may only be made by the Intermediary.
			</p>
			<p>
				<strong>6. Processing of Customer Personal Data</strong><br /><strong>6.1.</strong> The parties agree that for the purposes of the Data Privacy Legislation, We
				shall, in the course of the provision of the Services, be acting as a Controller in respect of the Intermediary Personal Data, and Processor in relation to Customer
				Personal Data provided by the Intermediary.
			</p>
			<p>
				<strong>6.2.</strong> Each party agrees to comply with the Data Privacy Legislation applicable to it in its role as Controller or Processor (as the case may be) to
				the extent that it relates to the provision and/or receipt of the Services provided under this Agreement.
			</p>
			<p>
				<strong>6.3.</strong> We shall not process Customer Personal Data other than on the Intermediary's documented instructions (provided that such instructions are
				within the scope of the Services provided by {{ site.softwareName }}), unless We are required to process the Customer Personal Data by any law to which We are
				subject (in such a case We shall inform the Customer of that legal requirement before processing unless that law prohibits Us from so notifying the Customer). For
				the avoidance of doubt, an instruction given by you or any Authorised User shall be deemed to have been given on the Customer's behalf.
			</p>
			<p>
				<strong>6.4.</strong> Details regarding the scope, nature and purpose of the processing, the type of Personal Data Processed by us, the duration of the processing
				and the categories of Data Subject (the “Processing Information”) are set out in Annex 1.
			</p>
			<p>
				<strong>6.5.</strong> We shall ensure that appropriate technical, organisational and security measures are taken against unauthorised or unlawful processing of
				Personal Data and against accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, such Personal Data.
			</p>
			<p>
				<strong>6.6.</strong> We shall take reasonable steps to ensure the reliability of any employee, agent or contractor of the Company or any Authorised Processor who
				may have access to Personal Data and shall ensure that such personnel are aware of the confidential nature of the Personal Data and are subject to enforceable
				duties of confidence in respect of Personal Data.
			</p>
			<p>
				<strong>6.7.</strong> We shall notify the Intermediary promptly upon receipt of any subject access request or other request received from a Customer in accordance
				with the Data Privacy Legislation, and at the Intermediary's cost, assist the Intermediary utilising appropriate technical and organisational measures, in so far as
				this is possible, in order that the Intermediary may respond to any such request in a timely manner and in accordance with the Customer's obligations under the Data
				Privacy Legislation.
			</p>
			<p>
				<strong>6.8.</strong> We notify the Intermediary without undue delay upon becoming aware of any Personal Data Breach affecting the Intermediary's Personal Data or
				the Customer's Personal Data.
			</p>
			<p>
				<strong>6.9.</strong> We shall, at the Intermediary's cost, provide reasonable assistance to the Intermediary in relation to any data protection impact assessments
				and prior consultations with Supervisory Authorities or other competent data privacy authorities, relating to the Customer's Personal Data.
			</p>
			<p><strong>6.10.</strong> We shall maintain all necessary records and information to demonstrate its compliance with the provisions set out in this clause 6.</p>
			<p>
				<strong>6.11.</strong> The Intermediary shall ensure that We are provided with no less than 10 business days’ notice of any request for an audit or inspection and
				the Intermediary shall ensure that it complies (and ensure that any third party carrying out an audit on its behalf complies) with any reasonable restrictions and
				requirements for access (including but not limited to security processes, confidentiality and vetting procedures) imposed by the Company, an Authorised Processor,
				or any other operator of the relevant premises and shall ensure that the conduct of the visits is within normal business hours and shall not unreasonably disturb
				the business operations of the Company or an Authorised Processor.
			</p>
			<p>
				<strong>6.12.</strong> The Intermediary is only entitled to one audit or inspection of an Authorised Processor in relation to processing of Customer Personal Data
				in any twelve month period, unless
			</p>
			<li>
				the Intermediary is required or requested to carry out an audit by a Supervisory Authority or any similar regulatory authority responsible for the enforcement of
				Data Privacy Legislation,
			</li>
			<br />
			<p>provided that the Intermediary has identified its concerns or the relevant requirement or request in its notice to Us of the additional audit or inspection.</p>
			<p><strong>6.13.</strong> To the extent:</p>
			<li>
				a physical/ on-site audit or inspection carried out by the Intermediary (or its authorised representatives) pursuant to this clause 6 exceeds one business day, We
				shall be entitled to charge the Intermediary (at its standard rates from time to time) for any effort We reasonably expends in assisting such audit for every day
				over and above the initial day;
			</li>
			<li>
				We are required to respond to any written request for information made by the Intermediary (or its authorised representatives) relating to Our compliance with the
				provisions of this Agreement, We shall be entitled to charge the Intermediary (at its standard rates from time to time) for any effort We reasonably expend over and
				above half a business day.
			</li>
			<br />
			<p>
				<strong>6.14.</strong> We shall, upon receipt of a written request from the Customer or the Intermediary, delete or return all Customer Personal Data at the end of
				the provision of the Services to which the Customer Personal Data relates, provided that We may retain copies of the Customer Personal Data in accordance with any
				legal and regulatory requirements, and any guidance that has been issued in relation to deletion or retention by a Supervisory Authority. In relation to
				Intermediary Personal Data for which we are Data Controller, we shall treat this in accordance with our Privacy Notice available on our website.
			</p>
			<p><strong>6.15.</strong> The Intermediary authorises the Company to appoint the following Subprocessors in relation to the Customer Personal Data:</p>
			<li>members of the Answers In Retirement Group; and</li>
			<li>Third party data centre and hosting services providers.</li>
			<br />
			<p>
				<strong>6.16.</strong> In relation to the engagement of a Subprocessor other than one authorised pursuant to clause 6.15, We shall give the Intermediary prior
				written notice of the appointment of such Subprocessor, which may include publishing an announcement on {{ site.softwareName }} regarding the proposed appointment
				of such Subprocessor. If, within 30 days of receipt of that notice, the Intermediary notifies us in writing of any objections (on reasonable grounds) to the
				proposed appointment then we shall not appoint (nor disclose any Customer Personal Data to) the proposed Subprocessor except with the prior written consent of the
				Intermediary.
			</p>
			<p>
				<strong>6.17.</strong> We shall ensure that any transfers of Customer Personal Data to locations outside of the United Kingdom are undertaken in accordance with the
				obligations or standards imposed by Data Privacy Legislation.
			</p>
			<p>
				<strong>6.18.</strong> We shall hold the Customer's personal data for a minimum of 24 months (2years) after this the information may be removed from the Air Later
				Life Platform. Intermediary Personal Data will be held in accordance with our Privacy Notice. It is the responsibility of the Authorised Users to retain any
				Customer personal data for a longer period.
			</p>
			<p>
				<strong>7. Intermediary Acknowledgment</strong><br /><strong>7.1.</strong> Without prejudice to the generality of clause 6.2, the Intermediary warrants that in
				relation to all Customer Personal Data which is processed by the Company or an Authorised Processor:
			</p>
			<li>
				the Customer will ensure that all required fair processing notices are provided to the relevant Data Subjects which are sufficient in scope to enable Us or any of
				the Authorised Processors to (i) transfer Customer Personal Data to Third Party Product Providers in an electronic communication sent via {{ site.softwareName }},
				and (ii) otherwise to provide the Services and to carry out their obligations under the agreement in existence between the Customer and Ourselves, in accordance
				with the Data Privacy Legislation; and
			</li>
			<li>the Customer Personal Data transferred to Us can be lawfully processed by the Company (or any of the Authorised Processors).</li>
			<p><br /><strong>7.2.</strong> The Intermediary:</p>
			<li>
				acknowledges that the services provided via {{ site.softwareName }} require Us to transfer certain Intermediary Personal Data and/or Customer Personal Data to Third
				Party Product Providers on behalf of the Customer (but for the avoidance of doubt, the Third Party Product Provider shall not be deemed to be a Subprocessor of the
				Company in relation to the Customer Personal Data);
			</li>
			<li>
				Authorises Us to transfer Intermediary and / or Customer Personal Data to a Third Party Product Provider. Where such Third Party Product Provider is based outside
				of the United Kingdom, the Intermediary warrants that it has obtained all necessary consents of the Data Subject to the proposed transfer in sufficient terms to
				enable Us to transfer the Intermediary Personal Data and/or Customer Personal Data to the Third Party Product Provider;
			</li>
			<li>
				agrees that in relation to all Customer Personal Data which is transferred to Third Party Product Providers in an electronic message via
				{{ site.softwareName }}, the Customer will ensure that the fair processing notice provided to a Data Subject is sufficient in scope to enable the Third Party
				Product Provider to process the Customer Personal Data in order to provide an electronic response to such message.
			</li>
			<br />
			<p>
				<strong>8. Cancellation Rights</strong><br /><strong>8.1.</strong> You may cancel your account and have all Personal against your account removed by requesting
				termination of your account in writing. You can do so by sending an email to: {{ site.contact.email }}.
			</p>
			<p>
				<strong>8.2.</strong> We reserve the right to cancel your access to the Service where we have grounds to believe that you are not an intermediary authorised and
				regulated by the Financial Conduct Authority or we have evidence that you are in breach of the terms of this Agreement. We will notify you in advance in this event
				and allow you to provide evidence to the contrary, however we have absolute discretion to determine whether or not to permit your continued access to the Service.
			</p>
			<p>
				<strong>9. Disclaimer</strong><br /><strong>9.1.</strong> While the Company endeavours to ensure that the information on the Website is correct, the Company does
				not warrant the accuracy and completeness of the information on the Website. All product information including interest rates, fees and product criteria are
				provided directly by Third Party Product Providers. The responsibility to keep all product information updated sits with the Third Party Product Providers who list
				and maintain their own product data on the Websites and the Company makes no commitment to update such information on their behalf.
			</p>
			<p>
				<strong>9.2.</strong> The information on the Website is provided "as is", without any conditions, warranties or other terms of any kind. Accordingly, to the maximum
				extent permitted by law, the Company provides You with the Website on the basis that the Company excludes all representations, warranties, conditions and other
				terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which,
				but for this legal notice, might have effect in relation to the Website.
			</p>
			<p>
				<strong>10. Liability</strong><br /><strong>10.1.</strong> The Company, any other party (whether or not involved in creating, producing, maintaining or delivering
				the Service), and any of the Company's officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any
				amount or kind of loss or damage that may result to You or a third party (including without limitation, any direct, indirect, punitive or consequential loss or
				damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption,
				and whether in tort (including without limitation negligence), contract or otherwise) in connection with the Website in any way or in connection with the use,
				inability to use or the results of use of the Website, any websites linked to the Website or the material on such websites, including but not limited to loss or
				damage due to viruses that may infect Your computer equipment, software, data or other property on account of Your access to, use of, or browsing the Website or
				Your downloading of any material from the Website or any websites linked to the Website.
			</p>
			<p><strong>10.2.</strong> Nothing in this legal notice shall exclude or limit the Company's liability for:</p>
			<li>death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977; or</li>
			<li>fraud; or</li>
			<li>misrepresentation as to a fundamental matter; or</li>
			<li>any liability which cannot be excluded or limited under applicable law.</li>
			<p>
				<br /><strong>10.3.</strong> If your use of material on the Website results in the need for servicing, repair or correction of equipment, software or data, You
				assume all costs thereof.
			</p>
			<p>
				<strong>11. Service Availability</strong><br />There may be occasions whereby due to events outside our control, access to the {{ site.softwareName }} websites is
				temporary unavailable. In this event We will do all that is possible to restore access as soon as possible and We do not accept responsibility for any loss during
				this eventuality and will not be liable for any actions by You that result in an infringement claim.
			</p>
			<p>
				<strong>12. Links to and from other websites</strong><br /><strong>12.1.</strong> Links to third party websites on the Website are provided solely for your
				convenience. If you use these links, you leave the Website. The Company is not able to keep under constant review all of these third party websites, does not
				control and is not responsible for these websites or their content or availability. The Company therefore does not endorse or make any representations about them,
				or any material found there, or any results that may be obtained from using them. If you decide to access any of the third party websites linked to the Website, You
				do so entirely at your own risk.
			</p>
			<p>
				<strong>12.2.</strong> If You would like to create a link to the Website, You may only do so on the basis that you link to, but do not replicate, the home page of
				the Website, and subject to the following conditions:
			</p>
			<li>You do not remove, distort or otherwise alter the size or appearance of the Company logo;</li>
			<li>You do not create a frame or any other browser or border environment around the Website;</li>
			<li>You do not in any way imply that the Company is endorsing any products or services other than its own;</li>
			<li>You do not misrepresent Your relationship with the Company nor present any other false information about the Company;</li>
			<li>You do not otherwise use any Company trademarks displayed on the Website without express written permission from the Company;</li>
			<li>You do not link from a website that is not owned by You; and</li>
			<li>
				Your website does not contain content that is distasteful, offensive or controversial, infringes any intellectual property rights or other rights of any other
				person or otherwise does not comply with all applicable laws and regulations.
			</li>
			<p>
				<br /><strong>12.3.</strong> The Company expressly reserves the right to revoke the right granted in clause 12.2 for breach of these terms and to take any action it
				deems appropriate.
			</p>
			<p>
				<strong>12.4.</strong> You shall fully indemnify the Company for any loss or damage suffered by the Company or any of its group companies for breach of clause 12.2.
			</p>
			<p>
				<strong>13. Copyright</strong><br />The {{ site.softwareName }} websites content is the copyright of Answers in Retirement Limited. All rights not expressly granted
				by this Agreement are reserved.
			</p>
			<p>
				<strong>14. General</strong><br /><strong>14.1.</strong> These terms and conditions are governed by and interpreted in accordance with English law. Disputes arising
				out of or in connection with these terms and conditions shall be subject to the non-exclusive jurisdiction of England and Wales.
			</p>
			<p>
				<strong>14.2.</strong> If any provision of this Agreement is held to be unlawful, invalid or unenforceable, that provision shall be deemed deleted from this
				Agreement and the validity and enforceability of the remaining provisions of this Agreement shall not be affected.
			</p>
			<p>
				<strong>14.3.</strong> This Agreement, together with Our Privacy Notice, constitute the entire agreement between the Customer and the Company relating to the
				Customer's access to and use of {{ site.softwareName }} and supersede any prior agreements (including any previous terms of use of {{ site.softwareName }}).
			</p>
			<p>
				<strong>14.4.</strong> No failure or delay by us in exercising any right under this Agreement will operate as a waiver of that right nor will any single or partial
				exercise by Us of any right preclude any further exercise of any right.
			</p>
			<p>
				<strong>15. Complaints</strong><br />If you wish to make a complaint you can do so in writing by either email ({{ site.contact.email }}) or by post (Answers in
				Retirement Limited, Southgate House, Southgate Street, Gloucester, Gloucestershire, United Kingdom, GL1 1UD). We will acknowledge Your complaint within seven (7)
				business days and inform You of Our intended course of action including timescales.
			</p>
			<p>
				<strong>16. {{ site.softwareName }} Contact Details</strong><br />
			</p>
			<p><strong>Trading Address:</strong> {{ site.contact.address }}</p>
			<p><strong>Telephone:</strong> {{ site.contact.number }}</p>
			<p><strong>Email:</strong> {{ site.contact.email }}</p>
			<p><strong>Company registration number:</strong> {{ site.company.number }}</p>
			<br />
			<p><strong>Annex 1 - Processing Information</strong></p>
			<p><strong>(a) Scope and purpose of processing:</strong></p>
			<p>We may process Customer Personal Data</p>
			<li>during the provision of the Services;</li>
			<br />
			<li>in the course of providing support services to the Customer relating to {{ site.softwareName }}; – in accordance with these Terms.</li>
			<br />
			<p><strong>(b) Nature of processing:</strong></p>
			<p>The nature of the processing activity may include:-</p>
			<li>
				receiving, uploading, downloading, extracting, copying, duplicating, transmitting, organising, referencing, indexing, classifying, compressing, compiling, updating,
				transferring, transforming, analysing, modelling, changing, maintaining, protecting or securing, preserving, storing, backing-up or archiving, restoring, retrieving
				and accessing Customer Personal Data in order to perform any of the services set out in paragraph (a) above (or any other services which We may be instructed to
				provide in accordance with clause 6.3);
			</li>
			<br />
			<li>destroying, erasing and/or anonymising Customer Personal Data;</li>
			<br />
			<li>
				processing Customer Personal Data in order to comply with the our obligations under the Data Privacy Legislation, or to assist the Customer in complying with its
				obligations, including responding to any requests made by Data Subjects;
			</li>
			<br />
			<li>
				transferring to or receiving Customer Personal Data from Third Party Product Providers including Third Party Product Providers - this may include transferring the
				Customer Personal Data to a Third Party Product Provider located outside of the United Kingdom depending on the location of that Third Party Product Provider and its
				processing activity.
			</li>
			<br />
			<p><strong>(c) Duration of Processing</strong></p>
			<p>We will process Customer Personal Data for the duration of the provision of the Services and as set out in these terms.</p>
			<p><strong>(d) Types of Personal Data</strong></p>
			<p>We may process Customer Personal Data which falls into the following categories:</p>
			<li>
				Personal details which may include (without limitation): names, addresses, date of birth, sex, National Insurance number, passport number, tax identification
				numbers, telephone and mobile numbers and email addresses
			</li>
			<li>family details</li>
			<li>lifestyle and social circumstances</li>
			<li>goods and services</li>
			<li>employment,education details</li>
			<li>income and financial details which may include (without limitation):</li>
			<li>bank account details, investment details, insurance details, documentation of the above and notes of meetings.</li>
			<li>physical or mental health details which may include (without limitation):</li>
			<li>smoker status and health records</li>
			<li>nationality</li>
			<li>photographs</li>
			<li>IP addresses</li>
			<li>any other information which is uploaded into the Services pursuant to this Agreement.</li>
			<br />
			<p>
				<strong>Categories of data subject</strong><br />Authorised Users who make available their personal information in order to become registered as a user of Air Later
				Life.
			</p>
			<p>Customer or potential end Customers  (including, where relevant, their relatives, dependents, guardians and associates) and Authorised Users / Intermediaries.</p>
		</div>

		<v-container v-else class="mx-auto" max-width="500">
			<v-skeleton-loader type="list-item-three-line@3" />
		</v-container>
		<app-common-privacy ref="privacy" />
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import AppCommonPrivacy from '@/component/app/common/privacy';

	export default {
		name: 'app-common-terms-default',

		components: { AppCommonPrivacy },

		data: () => ({
			website_name: `www.${window.location.hostname}`
		}),

		computed: { ...mapState('CmsSite', ['site']) },

		methods: {
			openPrivacy() {
				this.$refs.privacy.open();
			}
		}
	};
</script>
