<!--
	@name app-calculator
	@description Calculators & Tools page
	@date 2020/04/28
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Tools &amp; Calculators
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12">
				<div class="border-effect border-effect--lg ml-6 mb-8">
					<p class="text-h5 mb-2 font-weight-regular">
						We have provided some handy <span class="font-weight-bold">tools and calculators</span> below.
					</p>
					<p class="text-body-1 mb-0">
						Click on the relevant tab to open the desired calculator.
					</p>
				</div>

				<v-row>
					<v-col v-for="option in options" :key="option.name" cols="4">
						<v-card :loading="loading" class="flex-shrink-1 flex-grow-1" height="100%">
							<template slot="progress">
								<v-progress-linear color="deep-purple" height="10" indeterminate />
							</template>
							<div class="image" :style="backgroundImage(option)" />
							<v-card-title>
								{{ option.title }}
							</v-card-title>
							<v-card-text v-html="$sanitize(option.copy)" />
							<v-divider class="mx-4" />
							<v-card-actions>
								<v-btn v-if="option.url" color="primary" text :href="option.url" target="_blank">
									OPEN
								</v-btn>
								<v-btn v-else color="primary" text @click="openTool(option)">
									OPEN
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<borrowing-forecast-tool ref="borrowingForecast" @closed="toolClosed" />
		<flood-risk-report ref="floodRiskReport" @closed="toolClosed" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { orderBy } from 'lodash';
	import BorrowingForecastTool from '@/component/app/calculator/borrowing-forecast-tool';
	import FloodRiskReport from '@/component/app/calculator/flood-risk-report';

	export default {
		name: 'app-calculator',

		components: {
			BorrowingForecastTool,
			FloodRiskReport
		},

		data() {
			return {
				loading: false,
				selection: 1,
				lllNavigatorActive: true,
				borrowingForecastActive: false,
				floodRiskReportActive: false,
				options: [
					{
						title: 'Later Life Lending Navigator',
						copy: 'Determine the type(s) of product(s) for which your client may be eligible, including lifetime mortgages, retirement interest-only (RIO) and standard residential mortgages.',
						image: 'logo/tool/navigator.jpg',
						ref: 'lllNavigator',
						url: 'https://lllnavigator.co.uk'
					},
					{
						title: 'Borrowing Forecast',
						copy: 'Forecast one or two products over time to establish cost including interest, fees, payments and any future drawdowns. Or, compare an existing mortgage to a new mortgage product over time.',
						image: 'logo/tool/forecast.jpg',
						ref: 'borrowingForecast',
						path: '/tools/borrowing-forecast-tool'
					},
					{
						title: 'Flood Risk Report',
						copy: 'Access the Environment Agency flood and river level API to view real-time flood data and download a PDF Report from just a customer postcode, including geographical flood area maps.',
						image: 'logo/tool/flood.jpg',
						ref: 'floodRiskReport',
						path: '/tools/flood-risk-report'
					}
				]
			};
		},

		computed: {
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsData', ['data']),
			...mapGetters('CmsDownload', ['downloads']),

			orderedDownloads() {
				return orderBy(this.downloads('Calculators'), 'created', 'desc').slice(0, 4);
			},

			displayProviderTools() {
				return !this.membership('select').id || this.membership('select').bypass;
			},

			activeTool() {
				return this.$route.name === 'tools' ? false : this.$route;
			}
		},

		created() {
			this.loadDownloadsList();
			this.loadData('provider-tool');
		},

		mounted() {
			if (this.activeTool) {
				let tool = this.options.find((o) => o.path == this.activeTool.path);
				if (tool) this.openTool(tool);
			}
		},

		methods: {
			...mapActions('CmsDownload', ['loadDownloadsList']),
			...mapActions('CmsData', ['loadData']),

			openTool(tool) {
				if (this.$route.path !== tool.path) this.$router.push(tool.path);
				this.$refs[tool.ref].open();
			},

			toolClosed() {
				this.$router.push('/tools');
			},

			backgroundImage(option) {
				return `background-image: url('${this.$options.filters.urlize(option.image)}')`;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.image {
		height: 120px;
		width: 100%;
		background-size: cover;
		background-position: 50% 50%;
	}
</style>
