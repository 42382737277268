import { ProductHrpRequest, ProductIssue, ProductLifetimeMortgageCriteria, ProductLifetimeMortgageNotes } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Product {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchProductCriteria
			 * @param {function} commit
			 */
			fetchProductCriteria(store, productId) {
				return ProductLifetimeMortgageCriteria.get(productId);
			},

			/**
			 * @async @name fetchProductNotes
			 * @param {function} commit
			 */
			fetchProductNotes(store, { productId, iterationId }) {
				return ProductLifetimeMortgageNotes.get(productId, iterationId);
			},

			/**
			 * @name submitProductIssue
			 * @param {Object} context
			 * @param {Object} payload
			 */
			submitProductIssue(context, payload) {
				return ProductIssue.post(payload);
			},

			/**
			 * @async @name submitHrpRequest
			 * @param {function} commit
			 */
			async submitHrpRequest(store, payload) {
				const { data } = await ProductHrpRequest.post(payload);
				return { title: data.title, copy: data.copy };
			}
		};
	}
}
