<!--
	@name app-client-dashboard-workflow-overview
	@description Client Workflow Overview
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="itemsLoading" class="pa-0 ma-0" type="list-item-avatar-two-line" />
		<v-sheet v-else-if="!asset">
			<h2 class="text-h6 font-weight-bold mb-3">
				{{ title }}
			</h2>
			<div>
				<video
					v-if="introMedia"
					class="d-block ml-10 mb-6"
					:src="introMedia.video | urlize"
					:poster="introMedia.image || 'generic/gradient.png' | urlize"
					controls
					width="50%"
					style="float: right"
				/>
				<p class="text-body-1 font-weight-bold mb-4">
					AirFlow provides you with an automated central workflow, integrating all the different parts of the system and tools into one seamless journey for you and your
					customers.
				</p>
				<ul class="text-body-1 mb-4">
					<li class="mb-2">
						Each step guides you to the relevant part of the system, specific tool, or resource
					</li>
					<li class="mb-2">
						As you progress through the different stages, data will flow back into AirFlow. For instance, the LLL navigator PDFs and WriteRoute suitability report will
						be automatically stored in the relevant stage as they are generated.
					</li>
					<li class="mb-2">
						This allows you to efficiently monitor and track each customer's unique advice journey, with quick and easy access to the necessary documentation.
					</li>
				</ul>
				<p class="text-body-1 mb-7">
					Each stage is also supported with integrated resources and "How to training videos from the Academy". The Academy is an innovative learning platform that gives
					you access to LIBF-accredited training programmes and continuous professional development opportunities.
				</p>
				<v-btn color="primary" class="pulse--accent--small" large @click="$emit('add-property')">
					Get started
				</v-btn>
				<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
			</div>
		</v-sheet>
		<template v-else-if="items && items.length">
			<v-row no-gutters>
				<v-col class="grow pr-10">
					<h2 class="text-h6 font-weight-bold mb-2">
						{{ title }}
					</h2>
					<p class="text-body-2 mb-0">
						{{ title }} allows efficient monitoring to keep track of each customers unique advice journey, along with providing quick and easy access to relevant
						resources and documentation. Please select a status to keep on track of the overall case progression.
					</p>
				</v-col>
				<v-col class="shrink text-right">
					<v-sheet width="240">
						<v-select v-model="selectedItem" :items="statuses" label="Status" outlined hide-details dense @change="updateClientStatus" />
						<a class="text-caption plain d-inline-block grey--text text-decoration-underline mt-2" @click="openStatusInfo"> what's all this? </a>
					</v-sheet>
				</v-col>
			</v-row>
			<template v-if="showRecommendations">
				<p class="text-body-2 font-weight-bold mt-3 mb-1">
					Recommendations:
				</p>
				<span class="d-block font-weight-medium">
					<v-btn small color="primary" @click="openAirflowVideo"> learn more about {{ title }} </v-btn>
					<v-btn small class="ml-2" color="primary" @click="createSourcingSession"> start a sourcing session </v-btn>
				</span>
			</template>
			<v-divider class="my-6" />
			<v-row class="ma-n3">
				<v-col v-for="(item, index) in items" :key="index" cols="4">
					<v-card height="100%" hover link @click="handleRoute(item)">
						<v-system-bar height="16" :color="getStatusColor(item.data?.statuses?.[0]?.status)" />
						<v-card-title class="text-body-1 font-weight-bold pa-5 pb-2">
							{{ item.name }}
						</v-card-title>
						<v-card-text class="pa-5 pt-0">
							{{ getCopy(item.nameUnique) | truncate(125) }}
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</template>

		<app-client-dashboard-workflow-section
			ref="section"
			:items="items"
			:client="client"
			:association="association"
			:asset="asset"
			@update-status="updateStatus"
			@workflow-updated="init"
		/>

		<common-dialog ref="airflowVideo">
			<template #body>
				<video class="d-block" :src="introMedia.video | urlize" :poster="introMedia.image || 'generic/gradient.png' | urlize" controls width="100%" />
			</template>
		</common-dialog>

		<common-dialog ref="statusInfo">
			<template #body>
				<p class="text-body-1 font-weight-bold mb-3">
					Case Status
				</p>
				<p class="text-body-1 mb-0">
					The status you select here represents the overall status of the entire case. {{ /* This designation will be used to update the
					<a @click="$router.push('/cases')">case manager</a> page in the future, helping to keep track of the case's progress and current standing. */ }}
				</p>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';
	import AppClientDashboardWorkflowSection from '@/component/app/client/dashboard/workflow/section';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-client-dashboard-workflow-overview',

		components: {
			AppClientDashboardWorkflowSection,
			CommonDialog
		},

		props: {
			title: { type: String, default: null },
			client: { type: Object, default: null },
			association: { type: Object, default: null },
			asset: { type: Object, default: null }
		},

		data() {
			return {
				selectedItem: null, // Initially selected item
				items: [],
				itemsLoading: true,
				statuses: ['Lead', 'Appointment', 'Recommendation', 'Application', 'Complete', 'After Care', 'Declined'],
				airFlowVideoDisplay: false
			};
		},

		computed: {
			...mapGetters('AppWorkflow', ['getStatusColor']),
			...mapState('Account', ['changelogItems']),

			section() {
				return this.$route.query.workflowSection;
			},

			introMedia() {
				return this.changelogItems.find((item) => item.title === 'Introducing AirFlow') || null;
			},

			showRecommendations() {
				return true;
				//return !!this.items.map((x) => x.data?.statuses?.[0]?.status).filter((x) => !!x)?.length;
			}
		},

		watch: {
			section: {
				handler(newVal, prevVal) {
					if (newVal === prevVal || !newVal) return; // Do nothing if already on the destination route or if the destination route is empty
					if (this.items) this.findWorkFlowItem(this.section);
				}
			},

			items: {
				handler() {
					if (this.section) this.findWorkFlowItem(this.section);
				}
			}
		},

		created() {
			this.getCourseList();

			EventBus.$on('credentials-updated', this.getCourseList);
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			EventBus.$off('credentials-updated', this.getCourseList);
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('AppWorkflow', ['findWorkflow', 'createWorkflow', 'addStatus']),
			...mapActions('AppClient', { addClientStatus: 'addStatus' }),
			...mapActions('AppWebinar', ['getCourseList']),

			onMessage(message) {
				if (!message?.type || !['affordability', 'factFind', 'kfi', 'conveyancing', 'powerOfAttorney', 'will'].includes(message.type)) return;
				this.init();
			},

			init() {
				if (!this.asset) {
					this.itemsLoading = false;
					return;
				}

				this.itemsLoading = true;
				this.findWorkflow({ entityIds: [this.asset.id] }).then(({ data }) => {
					if (data?.template?.items?.length) {
						this.workflow = { id: data.id, name: data.template.name };
						this.items = data.template.items;
						//TODO - move to API
						this.items.forEach((x, i) => {
							let prev = i - 1;
							if (this.items[prev]) {
								this.items[i].prev = {
									id: this.items[prev].id,
									name: this.items[prev].name
								};
							}
							let next = i + 1;
							if (this.items[next]) {
								this.items[i].next = {
									id: this.items[next].id,
									name: this.items[next].name
								};
							}
						});
					} else {
						//this.title = 'AirFlow';
						this.items = [];
					}
					this.itemsLoading = false;
				});
				this.selectedItem = this.client?.data?.status;
			},

			getCopy(type) {
				if (type === 'affordability')
					return `The fully automated "Affordability," tile has been designed to equip you with the tools and knowledge necessary to have more comprehensive conversations with your customers and evidence that you have truly considered all their Later Life Lending options.`;
				else if (type === 'fact_find')
					return `Using Air's fact find and creation of a Suitability Report will ensure you adopt a customer centric approach, as well as playing a significant role in the advice journey by demonstrating how the advice is tailored to and meets the customer's individual circumstances, complies with regulations, and promotes transparency and understanding between you and your customers.`;
				else if (type === 'vulnerability_assessment')
					return `Creating a vulnerability report during or prior to your appointment provides the ability to identify any customer vulnerabilities in line with the FCA four characteristics associated with the main drivers of vulnerability. It will also supply helpful suggestions on how you can support your customers, ensuring you fulfil the Consumer Duty requirement that those with characteristics of vulnerability are receiving outcomes that are as good as outcomes received by other customers.`;
				else if (type === 'sourcing')
					return `Diligent product research is achieved by ensuring products offer features that address your customers needs over the short, medium and long-term, before comparing product costs. Our range of research filters within allows you to match product features and benefits with the customers personal priorities and preferences. This then creates a shortlist of the best-suited products with which to compare, using our overall Total Cost, for comparison.`;
				else if (type === 'kfi')
					return `Once you have completed a thorough fact find, vulnerability assessment, affordability assessment and conducted your sourcing research, you are in a position to recommend the most suitable solution and product and obtain a Key Facts Illustration.`;
				else if (type === 'application')
					return `Easy and instant access to the latest status of each of your customers online applications allows you to swiftly address any issues or delays, ensuring efficiency and speed in the application process.`;
				else if (type === 'conveyancing')
					return `Conveyancing ensures that the transfer of property is legally valid and that all relevant laws and regulations are followed. When choosing a solicitor to act on their behalf, the customer has the option of either choosing their own, or they can choose to use a solicitor that you suggest. A large proportion of customers will look to you, as their adviser, for some guidance on which solicitor to use.`;
				else if (type === 'will')
					return `Your customer having an up-to-date Will is an important part of later life planning. This involves organising their assets, ensuring they're managed as they wish once they've passed away.`;
				else if (type === 'lpa') return `Find out more about Lasting Powers of Attorney here and why it's important you discuss LPAs with your clients.`;
				else if (type === 'state_benefits')
					return `If your customer is looking to raise capital or increase income, it is vital to establish whether they are eligible to claim State Benefits. AirFlow's State Benefits tile allows you to assess their entitlement to any benefits they are not currently claiming and to check whether releasing funds will impact any benefits they are already in receipt of, providing them with the full picture before they make any decision to proceed.`;
				else if (type === 'power_of_attorney')
					return `Having Powers of Attorney in place is an important factor for your customers to ensure they appoint someone that they trust to make decisions on their behalf if they become unable to make them, due to illness, injury, or incapacity. This ensures that their affairs are managed according to their wishes even if they're unable to express them.`;
				else
					return `Nulla sollicitudin rutrum tellus. Fusce porttitor sem ac iaculis placerat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam egestas mi vitae consectetur aliquam. Ut vitae nulla non augue porttitor elementum. Morbi sed est non urna aliquet ullamcorper.`;
			},

			handleRoute(item) {
				this.$router.push({ query: Object.assign({}, this.$route.query, { workflowSection: item.id }) });
			},

			findWorkFlowItem(id) {
				// Close dialog if id is empty
				if (!id) this.$refs.section.close();
				let item = this.items.find((item) => item.id === id);
				if (item) this.openSection(item);
				else console.log('ERROR'); // TODO handle this
			},

			openSection(item) {
				item.copy = this.getCopy(item.nameUnique);
				this.$refs.section.open(item);
			},

			updateStatus(data) {
				const { item, status } = data;

				this.addStatus({ id: this.workflow.id, payload: { workflowTemplateItemId: item.id, status } })
					.then(() => {
						const index = this.items.findIndex((i) => i.id === item.id);
						this.$set(this.items, index, {
							...this.items[index],
							data: { ...(this.items[index].data || {}), statuses: [{ status, timestamp: new Date().toISOString() }] }
						});
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `Failed to mark item as ${status}`));
			},

			/**
			 * @name updateClientStatus
			 * @description Update client status
			 */
			updateClientStatus(status) {
				this.addClientStatus({ id: this.client.id, payload: { data: { status } } });
				if (status === 'Declined') this.$emit('add-note');
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name createSourcingSession
			 * @description Create New Sourcing Session
			 */
			createSourcingSession() {
				this.$router.push(
					`/source?clientId=${this.client?.id}${this.asset?.id ? `&propertyId=${this.asset.id}` : ''}${
						this.association?.id ? `&associationId=${this.association.id}` : ''
					}`
				);
			},

			/**
			 * @name openAirflowVideo
			 * @description open airflow video dialog
			 */
			openAirflowVideo() {
				this.$refs.airflowVideo.open();
			},

			/**
			 * @name openStatusInfo
			 * @description open status info dialog
			 */
			openStatusInfo() {
				this.$refs.statusInfo.open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clickable {
		cursor: pointer;
	}
</style>
