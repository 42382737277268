<!--
	@name app-dialog-account-takeover
	@description Show a dialog for account takeover
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Account Takeover
		</template>
		<template #body>
			<div v-if="impersonator.id">
				<p class="text-body-1 mb-0">
					<span class="font-weight-bold">You are currently accessing the account of {{ user.name }}, {{ organisation.name }}.</span>
					<span class="accent black--text pa-1 rounded-sm" style="cursor: pointer" @click="endAccountTakeover()">Click Here</span> to revert to your normal account.
				</p>
			</div>
			<div v-else>
				<p class="text-body-1 mb-4 font-weight-medium">
					Type the name of the user for which you would like to perform an account takeover and click 'Search'. Find the relevant account from the list of returned
					results and click the 'Choose' button to complete the process.
				</p>
				<v-autocomplete
					v-model="model"
					:items="searchResults"
					:loading="isLoading"
					:search-input.sync="search"
					color="success"
					item-text="name"
					item-value="id"
					hide-details
					:hide-no-data="true"
					hide-selected
					label="Search: e.g. Patrick Bateman"
					placeholder="Start typing to Search"
					prepend-icon="mdi-account-search"
					return-object
					dense
					filled
				>
					<template #append-outer>
						<v-slide-x-reverse-transition mode="out-in">
							<v-icon v-if="search" color="red lighten-2" @click="reset()">
								mdi-eraser
							</v-icon>
						</v-slide-x-reverse-transition>
					</template>
				</v-autocomplete>
				<v-expand-transition>
					<v-list v-if="model" class="blue-grey lighten-5 pa-0 mt-5" dense tile>
						<v-list-item>
							<v-list-item-content class="py-3">
								<v-list-item-title class="text-body-2 mb-2" v-text="model.name" />
								<v-list-item-subtitle v-text="model.organisationName" />
								<v-list-item-subtitle v-text="model.email" />
								<div class="d-block">
									<v-chip v-if="model.locked" class="mr-2 mt-2" color="warning accent-3" label x-small active>
										Locked
									</v-chip>
								</div>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn color="primary" small :disabled="model.locked" @click="submitAccountTakeover(model.id)">
									Select
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-expand-transition>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { debounce } from 'lodash';
	import CommonDialog from '@/component/common/dialog';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-dialog-account-takeover',

		components: { CommonDialog },

		data: () => ({
			data: { name: null, number: null, enquiry: null },
			message: null,
			messageError: false,
			success: false,
			descriptionLimit: 60,
			searchResults: [],
			isLoading: false,
			model: null,
			search: null
		}),

		computed: {
			...mapState('Account', ['impersonator', 'user', 'organisation'])
		},

		watch: {
			search(val) {
				// Don't bother if empty
				if (!val || !val.length) return;

				// Items have already been loaded
				if (this.searchResults.length > 0) return;

				// Items have already been requested
				if (this.isLoading) return;

				this.searchAdvisers(val);
			}
		},

		created() {
			const urlParams = new URLSearchParams(window.location.search);
			const impersonate = urlParams.get('impersonate');
			if (impersonate) {
				urlParams.delete('impersonate');
				window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
				this.submitAccountTakeover(impersonate);
			}
		},

		methods: {
			...mapActions('Account', ['impersonate', 'reinstate']),
			...mapActions('Option', ['getAdvisorList']),
			/**
			 * @name searchAdvisers
			 * @description Send request to API
			 */
			searchAdvisers: debounce(async function() {
				this.isLoading = true;
				let response = await this.getAdvisorList();
				this.isLoading = false;
				if (!response.error) this.searchResults = response.data;
				else this.searchResults = [];
			}, 500),

			/**
			 * @name submitAccountTakeover
			 * @description Send request to API
			 * @param {String} id to takeover
			 */
			submitAccountTakeover: debounce(async function(id) {
				this.isLoading = true;
				if (this.impersonator.id) await this.reinstate();
				let response = await this.impersonate({ impersonate: { id, refreshToken: window.localStorage.getItem('air.refreshToken') } });

				if (!response.error) location.reload();
				else ElementTools.fireNotification(this.$el, 'error', 'An unexpected error has occurred');
			}, 150),

			/**
			 * @name endAccountTakeover
			 * @description Send request to API
			 */
			endAccountTakeover: debounce(function() {
				// switch back to impersonator
				this.reinstate();
				location.reload();
			}, 150),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.data = { name: null, number: null, enquiry: null };
				this.isLoading = false;
				this.message = null;
				this.messageError = false;
				this.success = false;
				this.$refs.dialog.open();
			},

			reset: function() {
				this.model = null;
				this.search = null;
			}
		}
	};
</script>
