<!--
	@name app-client-dashboard-workflow-will
	@description Client Workflow Will Item
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<app-client-dashboard-workflow-template :workflow-item="workflowItem" @close-workflow-item="$emit('close-workflow-item')" @update-status="$emit('update-status', $event)">
			<template slot="activity">
				<!-- Loading... -->
				<v-skeleton-loader v-if="loading" type="list-item-three-line" />
				<div v-else>
					<app-client-dashboard-workflow-will-get-started
						v-if="showGetStarted"
						:intro-resource="introResource"
						@toggle-intro-resource="toggleIntroResource"
						@hideGetStartedDisplay="hideGetStartedDisplay"
					/>
					<app-client-dashboard-workflow-will-activity
						v-else-if="!newReferralOverride && referrals.length"
						:referrals="referrals"
						@toggleNewReferralOverride="toggleNewReferralOverride"
					/>
					<app-client-dashboard-workflow-will-solicitors
						v-else
						:client="client"
						:association="association"
						:asset="asset"
						:referrals="referrals"
						@toggleNewReferralOverride="toggleNewReferralOverride"
						@submittedNewReferral="submittedNewReferral"
						@workflow-updated="$emit('workflow-updated')"
					/>
				</div>
			</template>

			<template slot="resources">
				<app-client-dashboard-workflow-template-resources :section="section" :resources="resources" :remove-intro-video="removeIntroVideo" />
			</template>
		</app-client-dashboard-workflow-template>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import AppClientDashboardWorkflowTemplate from '@/component/app/client/dashboard/workflow/template';
	import AppClientDashboardWorkflowWillGetStarted from '@/component/app/client/dashboard/workflow/will/get-started';
	import AppClientDashboardWorkflowWillActivity from '@/component/app/client/dashboard/workflow/will/activity';
	import AppClientDashboardWorkflowWillSolicitors from '@/component/app/client/dashboard/workflow/will/solicitors';
	import AppClientDashboardWorkflowTemplateResources from '@/component/app/client/dashboard/workflow/template/resources';
	import courseVideoMixin from '@/mixins/course-video';

	export default {
		name: 'app-client-dashboard-workflow-will',

		components: {
			AppClientDashboardWorkflowTemplate,
			AppClientDashboardWorkflowWillGetStarted,
			AppClientDashboardWorkflowWillActivity,
			AppClientDashboardWorkflowWillSolicitors,
			AppClientDashboardWorkflowTemplateResources
		},

		mixins: [courseVideoMixin],

		props: {
			workflowItem: { type: Object, required: true },
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			resources: { type: Array, required: true }
		},

		data() {
			return {
				loading: true,
				referrals: [],
				hideGetStarted: false,
				newReferralOverride: false,
				section: 'will'
			};
		},

		computed: {
			showGetStarted() {
				return !this.referrals.length && !this.hideGetStarted;
			}
		},

		created() {
			this.loadWill();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('AppWill', ['loadWillList']),

			onMessage(message) {
				if (message?.type !== 'will' || !message.action?.data?.status) return;
				const index = this.referrals.findIndex((i) => i.id === message.id);
				this.$set(this.referrals, index, { ...this.referrals[index], status: message.action.data.status });
			},

			loadWill() {
				return this.loadWillList({ entityIds: [this.asset.id], order: { property: 'created', direction: 'desc' } })
					.then(({ data }) => (this.referrals = data))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => (this.loading = false));
			},

			hideGetStartedDisplay() {
				this.hideGetStarted = true;
			},

			toggleNewReferralOverride() {
				this.newReferralOverride = !this.newReferralOverride;
			},

			submittedNewReferral() {
				this.newReferralOverride = false;
				this.loadWill();
				this.$emit('workflow-updated');
				this.$nextTick(() => {
					let element = document.getElementById('template');
					element?.scrollIntoView({ behavior: 'smooth' });
				});
			}
		}
	};
</script>
