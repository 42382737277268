<!--
	@name app-kfi-request
	@description Show KFI Request Page
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						KFI Request
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip v-if="resultsUrl" bottom>
						<template #activator="{ on }">
							<v-btn text large color="white" :to="resultsUrl" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Product Results</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row v-if="displaySummary">
			<!-- Summary -->
			<v-col class="py-0" cols="12" sm="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<div class="d-flex">
						<div class="flex-grow-1 align-self-center">
							<p class="text-h5 mb-2 font-weight-regular">
								All KFI requests <strong>for all Products</strong> have been requested.
							</p>
							<p class="text-body-1 mb-0">
								Any requests received before 3pm will be actioned the same day. With best endeavours, we will look to process any
								requests received after 3pm, however this cannot be guaranteed.
							</p>
						</div>
					</div>
				</div>

				<v-alert
					class="text-body-1 mb-6 pl-6 background-rotation-manual--2"
					type="warning"
					color="accent"
					colored-border
					border="left"
					prominent
					dark
				>
					Please note: any requests received after 5pm will be processed the next working day. To prevent any delays please ensure your credentials are updated in
					<a class="white--text text-decoration-underline" @click="$router.push('/settings/credentials')">your account</a> page.
				</v-alert>

				<v-sheet class="pa-6 rounded">
					<p class="text-h6 font-weight-bold mb-2">
						Whats' next?
					</p>
					<p class="text-body-1 mb-0">
						You'll be notified by email as each KFI document is received, <strong>at which point you can download and review directly from your {{ site.softwareName || '' }} account.</strong>
						To download your KFI document, or proceed to application, please visit the <a @click="$router.push(`/client/${clientId}?workflowSection=${kfiStageId}`)">Key Facts Illustration</a> section of the <a @click="$router.push(`/client/${clientId}`)">client dashboard</a>.
						<template v-if="site && (!('usesAirFlow' in site) || site.usesAirFlow)">
							You can use the resources below to learn more about either the KFI process, or the entire AirFlow lifecycle.
						</template>
					</p>

					<template v-if="site && (!('usesAirFlow' in site) || site.usesAirFlow)">
						<v-divider inset class="mt-7 mb-5" />
						<v-row>
							<v-col cols="12" md="6" class="pr-6">
								<video
									v-if="kfiVideo"
									class="d-block"
									:src="kfiVideo.video | urlize"
									:poster="kfiVideo.image || 'generic/gradient.png' | urlize"
									controls
									width="100%"
								/>
							</v-col>
							<v-col cols="12" md="6" class="pl-6">
								<video
									v-if="introMedia"
									class="d-block"
									:src="introMedia.video | urlize"
									:poster="introMedia.image || 'generic/gradient.png' | urlize"
									controls
									width="100%"
								/>
							</v-col>
						</v-row>
					</template>

					<common-structure-section v-if="1== 2 && submittedProducts.length" class="mt-6">
						<template #header>
							Products // {{ submittedProducts.length }}
						</template>
						<template #body>
							<v-alert type="error" dense outlined text class="body-2 font-weight-medium mb-5 d-none">
								One or more of your KFI requests requires attention
							</v-alert>
							<v-list class="pa-0">
								<v-list-item
									v-for="(product, index) in submittedProducts"
									:key="product.id"
									class="px-0"
									:class="[index === 0 ? 'grey lighten-3 ' : 'blue-grey lighten-5 ', { 'mt-1': index !== 0 }]"
								>
									<template v-if="product.data.provider">
										<v-list-item-icon class="align-self-center ma-0 px-4 py-3">
											<common-base-image-tooltip
												:src="logoName(product.data.provider.logo) | urlize('organisation')"
												:tooltip="product.data.provider.name"
												:max-width="90"
												:max-height="60"
												center
											/>
										</v-list-item-icon>

										<v-list-item-content>
											<v-tooltip left>
												<template #activator="{ on }">
													<v-list-item-title class="ma-0 text-body-2 font-weight-medium" s v-on="on" v-text="product.data.name" />
												</template>
												<span>{{ product.data.name }}</span>
											</v-tooltip>
											<v-list-item-subtitle class="mt-1 caption d-none">
												<p v-if="index === 0" class="mb-0 error--text font-weight-medium">
													Rate change today: As this KFI has been requested outside of SLA, we suggest that you go direct to the lender in order to secure the
													current rate
												</p>
												<p v-else-if="index === 1" class="mb-0">
													To be processed immediately through our integrated API.
													<span class="warning--text font-weight-medium">If you do not recieve the KFI document within 30 minutes, please contact our support team</span>
												</p>
												<p v-else class="mb-0">
													To be processed next working day
												</p>
											</v-list-item-subtitle>
										</v-list-item-content>

										<v-list-item-icon v-if="index === 0" class="align-self-center px-4 d-none">
											<v-icon color="error">
												mdi-alert
											</v-icon>
										</v-list-item-icon>
									</template>
								</v-list-item>
							</v-list>
						</template>
					</common-structure-section>
				</v-sheet>
			</v-col>
			<v-col class="py-0" cols="6" md="4">
				<v-sheet v-if="hasBanner" class="pa-5">
					<common-promotion-advisor-advert />
				</v-sheet>
			</v-col>
		</v-row>

		<v-row v-else>
			<!-- Submission -->
			<v-col class="py-0" cols="12" md="8">
				<common-structure-section v-if="onlyManualProducts" class="mb-6">
					<template #body>
						<div>
							<v-alert prominent text outlined border="left" type="error" class="text-body-1 mb-6 font-weight-bold">
								Unfortunately, your chosen Products are not available for 'Quick KFI Requests'. However, select any of the Products listed below and you will be
								diverted to the relevant Provider website to begin your KFI request.
							</v-alert>
							<product-list :products="manualProducts" :link-button="true" @update-product="updateProduct" @remove-product="removeProduct" />
						</div>
					</template>
				</common-structure-section>

				<template v-else>
					<div v-if="site" class="border-effect border-effect--lg ml-6 mb-10">
						<p class="text-h5 mb-2 font-weight-regular">
							Just one quick step remaining in order to <span class="font-weight-bold">submit your KFI request{{ automatedProducts.length > 1 ? 's' : '' }}</span>
						</p>
						<p class="text-body-1 mb-0">
							Simply enter any missing information below and press "Proceed". You'll be notified by email as each KFI document is received, at which point you can
							download and review directly from your {{ site.softwareName }} account.
						</p>
					</div>

					<v-alert
						v-if="showPanelChange"
						class="pl-4 mb-6 white"
						:class="panelChangeMessage.disclosed ? 'grey lighten-1' : 'warning'"
						:color="panelChangeMessage.disclosed ? 'success' : 'error'"
						dark
						dense
						prominent
						colored-border
						border="left"
						:type="panelChangeMessage.disclosed ? 'success' : 'warning'"
						@click="panelChangeMessage.disclosed = !panelChangeMessage.disclosed"
					>
						<v-row>
							<v-col class="grow text-body-1 py-1">
								{{ panelChangeMessage.text }}
							</v-col>
							<v-col class="shrink py-1">
								<v-checkbox
									:value="panelChangeMessage.disclosed"
									inset
									class="ma-0"
									hide-details
									@click.self="panelChangeMessage.disclosed = !panelChangeMessage.disclosed"
								/>
							</v-col>
						</v-row>
					</v-alert>

					<common-structure-section class="mb-6">
						<template #body>
							<div v-if="!products || !form('kfi').value">
								<v-row class="no-gutters">
									<v-col v-for="index in 6" :key="index" cols="12" sm="12" class="pa-0 ma-0">
										<v-card class="flex-grow-1" :class="[index !== 6 ? 'mb-5' : 'mb-0']">
											<v-skeleton-loader type="list-item-three-line" />
										</v-card>
									</v-col>
								</v-row>
							</div>

							<div v-else-if="outstandingProducts.length">
								<!-- Company Details -->
								<common-form-company
									v-if="showCompany"
									ref="companyDetails"
									:form="formFromGroup('kfi', 'company')"
									:item="company"
									@set-validation="setValidation"
									@dynamic-form-submit="setCompany"
								/>

								<!-- Applicant Details-->
								<common-form-client
									ref="applicantDetails"
									:form="applicantForm"
									:clients="clientList"
									edit-only
									read-only
									@set-client="setApplicant"
									@set-validation="setValidation"
								/>

								<!-- Property Details -->
								<common-form-property
									ref="propertyDetails"
									:property-form="propertyForm"
									:another-property-form="formFromGroup('kfi', 'another_property')"
									:property="property"
									:client="clientList[0]"
									:another-property="anotherProperty"
									:show-another-property="showAnotherProperty"
									edit-only
									@set-validation="setValidation"
									@set-property="setProperty"
									@set-another-property="setAnotherProperty"
								/>

								<!-- Lender Account Credentials -->
								<common-form-lender-credentials v-if="showLenderCredentials" ref="lenderCredentials" :lenders="lenders" />

								<!-- Borrowing Requested -->
								<common-form-section
									v-if="borrowingRequestedForm.length"
									ref="borrowingRequested"
									title="Borrowing Requested"
									:form="borrowingRequestedForm"
									:item="borrowingRequested"
									@set-validation="setValidation"
								/>

								<!-- Advice Fee -->
								<common-form-section ref="adviceFee" title="Advice Fee" :form="adviceFeeForm" :item="adviceFee" @set-validation="setValidation">
									<template #header>
										<div class="d-flex flex-grow-1 justify-space-between align-center">
											<span>Advice Fee</span>
											<v-switch v-model="setAdviceFeeAsDefault" :disabled="!adviceFeeForm.length" label="Set as default" color="primary" hide-details />
										</div>
									</template>
								</common-form-section>

								<!-- Notes -->
								<common-form-section ref="notes" title="Notes" :form="formFromGroup('kfi', 'notes')" :item="notes" />

								<!-- Form Footer -->
								<div class="text-center">
									<v-alert v-show="!isValid" transition="slide-y-transition" dense prominent outlined text border="left" type="error" class="mb-6 text-body-1 text-left">
										You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message.
									</v-alert>
									<v-btn large color="primary" :loading="submitting" @click="submitKfiRequest">
										Proceed to Request KFI{{ automatedProducts.length > 1 ? 's' : '' }}
									</v-btn>
								</div>
							</div>
						</template>
					</common-structure-section>
				</template>
			</v-col>

			<!-- Products -->
			<v-col class="py-0" cols="6" md="4">
				<div v-if="!products">
					<common-structure-section class="mb-0">
						<template #header>
							Outstanding
						</template>
						<template #body>
							<v-row class="no-gutters">
								<v-col v-for="index in 2" :key="index" cols="12" sm="12" class="pa-0 ma-0">
									<v-card class="flex-grow-1" :class="[index !== 2 ? 'mb-5' : 'mb-0']">
										<v-skeleton-loader type="list-item-avatar" />
									</v-card>
								</v-col>
							</v-row>
						</template>
					</common-structure-section>
				</div>
				<div v-else-if="outstandingProducts.length">
					<common-structure-section v-if="automatedProducts.length" class="mb-6">
						<template #header>
							Outstanding // {{ automatedProducts.length }}
						</template>
						<template #body>
							<product-list :products="automatedProducts" @remove-product="removeProduct" />
						</template>
					</common-structure-section>

					<common-structure-section v-if="displayManualOnlyProductsSidebar" class="mb-6">
						<template #header>
							Manual Only // {{ manualProducts.length }}
						</template>
						<template #body>
							<v-alert type="warning" icon="mdi-exclamation" outlined text border="left" class="text-body-2">
								KFI requests for Products listed below <span class="font-weight-bold">cannot be made via Air Sourcing</span>. Please click the Product to request a
								KFI from the Providers website
							</v-alert>
							<product-list :products="manualProducts" :link-button="true" @update-product="updateProduct" @remove-product="removeProduct" />
						</template>
					</common-structure-section>

					<common-structure-section v-if="!displaySummary && submittedProducts.length" class="mb-6">
						<template #header>
							Submitted // {{ submittedProducts.length }}
						</template>
						<template #body>
							<product-list :products="submittedProducts" :delete-button="false" />
						</template>
					</common-structure-section>

					<div v-if="hasBanner && !manualProducts.length">
						<common-promotion-advisor-advert />
					</div>
				</div>
				<v-sheet v-else-if="hasBanner" class="pa-5">
					<common-promotion-advisor-advert />
				</v-sheet>
			</v-col>
		</v-row>
		<common-dialog ref="invalidProductsDialog" :show-close="false" :color="'error'">
			<template #header>
				Data mismatch
			</template>

			<template #body>
				<v-card elevation="0">
					<p class="text-body-2 mb-0">
						The following outstanding KFI requests cannot proceed as the information used to source the products is out of date.
						<strong>These products will be removed from the request when you proceed to the form.</strong>
					</p>

					<v-list class="pt-4 pb-0">
						<v-list-item v-for="product in invalidProducts" :key="product.id" class="amber lighten-5 my-1">
							<v-list-item-content>
								<v-list-item-title class="mb-0 font-weight-medium text-body-2">
									{{ product.data.provider.name }} - <span class="caption">{{ product.data.name }}</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-actions class="pa-0 pt-5">
						<v-spacer />
						<v-btn color="primary" style="margin:0 auto" @click="removeInvalidProducts">
							Agree and proceed
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import { $localStorage } from '@/utils/storage';
	import { orderBy, isEmpty, isNil, some } from 'lodash';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormCompany from '@/component/common/form/company';
	import CommonFormSection from '@/component/common/form/section';
	import CommonFormLenderCredentials from '@/component/common/form/lender-credentials';
	import CommonFormProperty from '@/component/common/form/property';
	import CommonBaseImageTooltip from '@/component/common/base/image-tooltip';
	import ProductList from './product-list';
	import { MatterHelper } from '@/store/helpers/MatterHelper';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-kfi-request',

		components: {
			CommonPromotionAdvisorAdvert,
			CommonStructureSection,
			CommonFormClient,
			CommonFormCompany,
			CommonFormSection,
			CommonFormLenderCredentials,
			CommonFormProperty,
			CommonBaseImageTooltip,
			ProductList,
			CommonDialog
		},

		data() {
			return {
				submitting: false,
				setAdviceFeeAsDefault: false,
				selectPanel: [],
				panelChangeMessage: {
					text: 'You are requesting KFIs for Products that sit outside of the Air Select panel. Please tick to confirm your understanding and proceed to request these KFIs',
					disclosed: false
				},
				matter: {},
				products: null,
				company: {},
				clientList: [{}],
				property: {},
				anotherProperty: {},
				lenderCredentials: {},
				borrowingRequested: {},
				adviceFee: {},
				notes: {},
				validations: {},
				firstTimeBuyer: null,
				kfiStageId: '10cea1e8-8edb-4615-a3e3-8d66ad57f401',
				clientId: null,
				resources: [],
				invalidProducts: []
			};
		},

		computed: {
			...mapState('Account', ['organisation', 'department', 'changelogItems']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['formFromGroup', 'form']),

			hasBanner() {
				return this.site.adverts?.advisor;
			},

			/**
			 * @name matterId
			 */
			matterId() {
				return this.$route.params.matterId;
			},

			/**
			 * @name processTypeId
			 */
			processTypeId() {
				return this.$route.query.processTypeId;
			},

			/**
			 * @name outstandingProducts
			 * @description used to get kfi submissions to be submitted
			 */
			outstandingProducts() {
				return (this.products || []).filter((product) => (!product.action || !product.action.filter((action) => action.type === 'SubmitKeyFactsIllustration').length) && product.valid);
			},

			/**
			 * @name manualProducts
			 * @description used to get kfi submissions to be submitted manually
			 */
			manualProducts() {
				return (this.outstandingProducts || []).filter((product) => product.data.keyFactsIllustration?.online && product.data.keyFactsIllustration?.url);
			},

			/**
			 * @name automatedProducts
			 * @description used to get kfi submissions to be submitted automatically
			 */
			automatedProducts() {
				return (this.outstandingProducts || []).filter((product) => !product.data.keyFactsIllustration?.online || !product.data.keyFactsIllustration?.url);
			},

			/**
			 * @name submittedProducts
			 * @description submitted products
			 */
			submittedProducts() {
				return (this.products || []).filter((product) => product.action && product.action.filter((action) => action.type === 'SubmitKeyFactsIllustration').length);
			},

			/**
			 * @name showLenderCredentials
			 * @description Lender Credentials section display conditionshowPanelChangeisvalid
			 * @return {Boolean} Value for display condition
			 */
			showLenderCredentials() {
				return this.lenders?.length;
			},

			/**
			 * @name displaySummary
			 * @description Lender Credentials section display condition
			 * @return {Boolean} Value for display condition
			 */
			displaySummary() {
				return this.products && !this.outstandingProducts.length;
			},

			/**
			 * @name showPanelChange
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showPanelChange() {
				if (!this.products) return false;
				if (!this.products.length) return false;
				if (!this.membership('select').id) return false;
				if (!this.selectPanel.length) return false;
				const hasNonAirSelectProviders = this.outstandingProducts.some((product) => !this.selectPanel.includes(product.data.provider.id));
				return hasNonAirSelectProviders;
			},

			/**
			 * @name displayManualOnlyProductsSidebar
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			displayManualOnlyProductsSidebar() {
				return this.products && this.products.length && this.manualProducts.length && !this.onlyManualProducts;
			},

			/**
			 * @name onlyManualProducts
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			onlyManualProducts() {
				return this.manualProducts.length && !this.automatedProducts.length;
			},

			/**
			 * @name showCompany
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showCompany() {
				return this.department.name && this.department.name.includes('owner') && !this.organisation.data?.membership?.fca;
			},

			/**
			 * @name showAnotherProperty
			 * @description TODO
			 * @return {Boolean} Value for display condition
			 */
			showAnotherProperty() {
				return this.property.mortgageTypeName === 'Purchase';
			},

			/**
			 * @name applicantForm
			 * @description TODO
			 * @return {Array} Applicant form
			 */
			 applicantForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'applicant');
				return form.length ? this.showFormFields(form) : [];
			},

			/**
			 * @name propertyForm
			 * @description TODO
			 * @return {Array} Borrowing reqesuted form
			 */
			propertyForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'property');
				return form.length ? this.showFormFields(form) : [];
			},

			/**
			 * @name borrowingRequestedForm
			 * @description TODO
			 * @return {Array} Borrowing reqesuted form
			 */
			borrowingRequestedForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'borrowing_requested');
				return form.length ? this.showFormFields(form) : [];
			},

			/**
			 * @name adviceFeeForm
			 * @description TODO
			 * @return {Array} Advice fee form
			 */
			adviceFeeForm() {
				// TODO use dynamic form
				const form = this.formFromGroup('kfi', 'advice_fee');
				return form.length ? this.showFormFields(form) : [];
			},

			/**
			 * @name resultsUrl
			 * @description construct results url
			 * @return {String} results url
			 */
			resultsUrl() {
				if (!this.matter?.process) return;

				let iterations = this.matter?.process?.map((process) => process.action?.iteration);
				let iteration = iterations?.sort((a, b) => new Date(b.created) - new Date(a.created))[0];

				return `/results/${iteration?.processId}?iteration=${iteration?.id}`;
			},

			/**
			 * @name isValid
			 * @description all forms are valid
			 * @return {Boolean} is valid
			 */
			isValid() {
				if (this.showPanelChange && !this.panelChangeMessage.disclosed) return false;
				return !Object.keys(this.validations).filter((item) => this.validations[item] === false)[0];
			},

			/**
			 * @name lenders
			 * @description lender list constructed from outstanding products
			 * @return {Array} lenders
			 */
			lenders() {
				// If interest reward, switch ID 50ce465a-863c-4779-9def-87d6ea114eca for 66f485cf-bc37-45a1-aacc-4f9e3d2ff1e3
				let providers = this.automatedProducts.map((product) => {
					if (product.data.provider.nameUnique === 'standard_life_home_finance' && product.data?.name?.toLowerCase().includes('interest reward')) return 'standard_life_home_finance___interest_reward';
					if (product.data.provider.nameUnique === 'more2life' && product.data?.name?.toLowerCase().includes('payment term')) return 'more2life__payment_term';
					return product.data.provider.nameUnique;
				});
				return [...new Set(providers)];
			},

			/**
			 * @name kfiVideo
			 * @description TODO
			 */
			kfiVideo() {
				if (!this.resources.length) return null;
				return this.resources.find((x) => some(x.workflowTemplateItem, {'id': this.kfiStageId }));
			},

			/**
			 * @name introMedia
			 * @description TODO
			 */
			introMedia() {
				if (!this.changelogItems.length) return null;
				return this.changelogItems.find((item) => item.title === 'Introducing AirFlow') || null;
			}
		},

		created() {
			this.init();
			this.loadResources();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm', 'loadForm']),
			...mapActions('CmsSite', ['getMembershipSite']),
			...mapActions('AppClientAsset', ['loadClientAsset']),
			...mapActions('AppClient', ['loadClient', 'getUserConnect']),
			...mapActions('AppMatter', ['fetchMatter']),
			...mapActions('LifetimeMortgageKfi', ['submitKfi', 'deleteProduct']),
			...mapActions('CmsCourse', ['loadCourseList']),

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			},

			init() {
				let { matterId, processTypeId } = this;
				let requests = [this.fetchMatter({ id: matterId, processTypeId }), this.loadForm('kfi')];

				Promise.all(requests).then((response) => {
					this.setMatterData(response[0].data);

					// Get our master client ID
					const entities = response[0].data?.matterEntity;
					const clients = entities.filter((e) => e.entity === 'client');
					this.clientId = clients[0].entityId;
					// 2 clients, find the master
					if (clients?.length > 1) this.getUserConnect({ clientIds: [clients[0].entityId, clients[1].entityId] }).then(({ data }) => this.clientId = data.mainClient );

					if (this.showCompany)
						this.setCompany({
							organisationName: this.organisation?.name,
							organisationFcaNumber: this.organisation?.data?.membership?.fca
						});
				});

				this.loadForm('interface');
				this.setSelectPanel();
			},

			setSelectPanel() {
				this.getMembershipSite('select').then(({ data }) => {
					let panel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
					if (panel) this.selectPanel = data?.matter?.lifetimeMortgage?.panel?.sourcing;
				});
			},

			async setMatterData(matter) {
				this.matter = matter;
				this.setProducts(matter).then(() => {
					if (this.automatedProducts.length) {
						this.setMatterEntities(matter.matterEntity);
						this.setProcessData(matter.process);
					}
				});
			},

			async setProducts(matter) {
				const matterHelper = new MatterHelper();
				this.products = await matterHelper.groupKfiProducts(matter);
				this.checkInvalidProducts();
			},

			checkInvalidProducts() {
				this.invalidProducts = (this.products || []).filter((product) => (!product.action || !product.action.filter((action) => action.type === 'SubmitKeyFactsIllustration').length) && !product.valid);
				if (this.invalidProducts.length) {
					this.$refs.invalidProductsDialog.open();
				}
			},

			removeInvalidProducts() {
				let deletions = [];
				for(const invalidProduct of this.invalidProducts) {
					const payload = {
						processId: invalidProduct.processId,
						iterationId: invalidProduct.id,
						data: {
							action: {
								key: 'DeleteKeyFactsIllustrationProduct'
							}
						}
					};

					deletions.push(this.deleteProduct(payload));
				}

				Promise.all(deletions)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', `Products have been successfully removed from outstanding list`);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove products'));
				this.$refs.invalidProductsDialog.close();
			},

			async setMatterEntities(matterEntity) {
				const clientEntity = matterEntity.filter((item) => item.entity === 'client');
				const assetEntity = matterEntity.find((item) => item.entity === 'asset');

				this.$nextTick(() => {
					clientEntity.map(async(item, index) => this.$refs.applicantDetails?.fetchClient(index, item.entityId, false));
					this.$refs.propertyDetails?.fetchAsset(clientEntity[0].entityId, assetEntity.entityId);
				});
			},

			setProcessData(process) {
				let { data } = orderBy(process, 'created', ['desc']).find((item) => !isEmpty(item.data)) || {};

				if (data) {
					this.borrowingRequested = data.borrowingRequested || {};
					this.adviceFee = data.adviceFee || {};
					this.notes = data.notes || {};
					this.firstTimeBuyer = data.firstTimeBuyer;
					this.anotherProperty = data.alternativeProperty;
				}

				if ($localStorage.get('adviceFeeDefaults') && isEmpty(this.adviceFee)) {
					this.adviceFee = $localStorage.get('adviceFeeDefaults');
					this.setAdviceFeeAsDefault = true;
				}

				this.$nextTick(() => {
					this.$refs.adviceFee.validateForm(true);
					if (this.borrowingRequestedForm.length) this.$refs.borrowingRequested.validateForm(true);
				});
			},

			setApplicant({ dataIndex, data }) {
				this.$set(this.clientList, dataIndex, data);
				this.$nextTick(() => this.$refs.applicantDetails?.validateForm());
			},

			setProperty(property) {
				let firstTimeBuyer = !isNil(property.firstTimeBuyer)
					? property.firstTimeBuyer
					: !isNil(this.property?.firstTimeBuyer)
					? this.property.firstTimeBuyer
					: this.firstTimeBuyer;
				this.property = { ...property, firstTimeBuyer };
				this.$nextTick(() => this.$refs.propertyDetails?.validateForm());
			},

			setAnotherProperty(property) {
				this.anotherProperty = property;
				this.$nextTick(() => this.$refs.propertyDetails?.validateForm());
			},

			setCompany(company) {
				this.company = company;
				this.$nextTick(() => this.$refs.companyDetails.validateForm());
			},

			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			/**
			 * @name submitKfiRequest
			 * @description Submit KFI requsts
			 */
			async submitKfiRequest() {
				if (!this.validateForms() || !this.validateClients()) return;

				this.submitting = true;
				const payload = this.createPayload();

				this.submitKfi(payload)
					.then((response) => {
						if (this.setAdviceFeeAsDefault) $localStorage.set('adviceFeeDefaults', this.adviceFee);
						this.products = response.data;
						ElementTools.fireNotification(this.$el, 'success', 'KFI request has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst submitting the KFI request'))
					.finally(() => (this.submitting = false));
			},

			updateProduct(product) {
				const index = this.products.findIndex((item) => item.id === product.id);
				this.$set(this.products, index, product);
			},

			removeProduct(product) {
				this.products = this.products.filter((item) => item.id !== product.id);
			},

			/**
			 * @name validateForms
			 * @description Validate all forms in the page
			 * @return {Boolean} Validation result
			 */
			validateForms() {
				this.$refs.adviceFee.validateForm();
				if (this.$refs.companyDetails) this.$refs.companyDetails.validateForm();
				if (this.$refs.borrowingRequested) this.$refs.borrowingRequested.validateForm();

				return this.isValid;
			},

			validateClients() {
				if (this.clientList.length !== this.matter.matterEntity.filter((item) => item.entity === 'client').length) {
					ElementTools.fireNotification(this.$el, 'error', 'Client data is missing or invalid.');
					return false;
				}

				return true;
			},

			showFormFields(form) {
				return form
					.map((field) => {
						if (field && typeof field === 'object' && field.conditions && field.conditions.include) {
							const result = field.conditions.include.every((condition) => this.getConditionResult(condition));
							return result ? field : null;
						} else return field;
					})
					.filter((f) => f);
			},

			getConditionResult(condition) {
				let result = false;
				let dataSet = [];
				if (condition.entity === 'asset') dataSet = [this.property];
				if (condition.entity === 'product') dataSet = this.outstandingProducts.map((product) => product.data);

				dataSet.map((item) => {
					let data = null;
					if (condition.name.includes('.')) data = condition.name.split('.').reduce((o, i) => o[i] || o.value[i], item);
					else data = item[condition.name];

					if (
						(condition.type === 'equals' && data == condition.value) ||
						(condition.type === 'not_equals' && data != condition.value) ||
						(condition.type === 'includes' && data && data.includes(condition.value)) ||
						(condition.type === 'in_array' && condition.value.includes(data)) ||
						(condition.type === 'not_in_array' && !condition.value.includes(data)) ||
						(condition.type === 'is_empty' && isEmpty(data)) ||
						(condition.type === 'not_empty' && !isEmpty(data))
					) {
						result = true;
						return;
					}
				});

				return result;
			},

			createPayload() {
				// map property
				const property = this.property;

				// groundRent
				// serviceCharge
				// numberOfFloors
				// floor
				// servedByLift
				// aboveRetailPremises
				// whollyPrivatelyOwned
				// unexpiredLeaseTerm
				// standard
				// constructionDetails
				// ageRestrictedDetails
				// listed
				// listing
				// firstTimeBuyer

				const mappedProperty = {
					id: property.id,
					type: property.type,
					data: {
						construction: {
							details: property.constructionDetails,
							numberOfFloors: property.numberOfFloors,
							standard: property.standard
						},
						cost: {
							groundRent: property.groundRent,
							serviceCharge: property.serviceCharge
						},
						detail: {
							listed: property.listed,
							listedBuildingGradeId: property.listedBuildingGradeId,
							servedByLift: property.servedByLift,
							ageRestricted: {
								details: property.ageRestrictedDetails
							},
							unexpiredLeaseTerm: Number(property.unexpiredLeaseTerm),
							whollyPrivatelyOwned: property.whollyPrivatelyOwned,
							managementCompany: this.borrowingRequested?.managementCompany,
							blockInsurance: this.borrowingRequested?.blockInsurance
						},
						location: {
							floor: property.floor,
							aboveRetailPremises: property.aboveRetailPremises
						}
					}
				};

				// map client
				const mappedClientList = this.clientList.map((item) => {
					const { dateOfBirth, estimatedRetirementAge, ...client } = item;
					const [day, month, year] = dateOfBirth.split('/');
					const dob = this.$moment.utc(`${month}/${day}/${year}`).toISOString();
					if (estimatedRetirementAge) client.data = { estimatedRetirementAge };
					return { ...client, dateOfBirth: dob };
				});

				const mappedOrganisation = this.showCompany
					? {
							name: this.company?.organisationName,
							data: {
								membership: {
									fca: this.company?.organisationFcaNumber
								}
							}
					  }
					: undefined;

				return {
					processId: this.outstandingProducts[this.outstandingProducts.length - 1].processId,
					client: mappedClientList,
					asset: mappedProperty,
					organisation: mappedOrganisation,
					data: {
						process: {
							borrowingRequested: { ...this.borrowingRequested, managementCompany: undefined, blockInsurance: undefined },
							adviceFee: this.adviceFee,
							notes: this.notes,
							firstTimeBuyer: property.firstTimeBuyer,
							alternativeProperty: this.anotherProperty
						},
						action: {
							key: 'SubmitKeyFactsIllustration'
						}
					}
				};
			},

			loadResources() {
				this.loadCourseList().then((items) => this.resources = items.data.data.filter((i) => i.useAsIntro));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-alert {
		font-size: 14px;
		line-height: 18px;
	}

	::v-deep .v-card.v-sheet {
		margin-bottom: 32px;
	}
</style>
