import { MatterType } from '@/service';
import { orderBy } from 'lodash';
import { pluralize } from 'inflection';

/**
 * @class @name MatterType
 * @description Exposes menu store module
 */
export default class MatterTypeStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			matterTypeList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name mappedProviderList
			 * @description get provider list
			 * @param {Object} state
			 */
			mappedProviderList: () => (providerList) =>
				orderBy(providerList, (p) => p.name.toLowerCase()).map((p) => ({
					id: p.id,
					nameUnique: p.nameUnique,
					title: p.name,
					subTitle: p.organisationAddress.address1
						? `${p.organisationAddress.address1} ${p.organisationAddress.address2}, ${p.organisationAddress.address3 ? p.organisationAddress.address3 + ',' : ''} ${
								p.organisationAddress.townCity
						  }, ${p.organisationAddress.postalCode}`
						: null,
					address: `${p.organisationAddress.address1} ${p.organisationAddress.address2}, ${p.organisationAddress.address3 ? p.organisationAddress.address3 + ',' : ''} ${
						p.organisationAddress.townCity
					}, ${p.organisationAddress.postalCode}`,
					latitude: p.organisationAddress.geolocation?.x,
					longitude: p.organisationAddress.geolocation?.y,
					image: `logo/organisation/${p.organisationDetail.logo.filter((l) => l.primary)[0].name}`,
					phone: p.organisationCommunication.filter((c) => c.type === 'phone')[0]?.identifier,
					email: p.organisationCommunication.filter((c) => c.type === 'email')[0]?.identifier,
					website: p.organisationDetail.website,
					detail: p.organisationProvider?.data?.bio,
					clubProvider: p.organisationProvider?.equityRelease
				})),

			/**
			 * @name mappedMatterTypeList
			 * @description get product list
			 * @param {Object} state
			 */
			mappedMatterTypeList: (state, getters) => {
				return state.matterTypeList
					.filter((p) => p.organisation.length > 0)
					.map((p) => ({
						...p,
						title: pluralize(p.data?.displayName || p.name),
						image: `logo/matter_type/${p.nameUnique}.jpg`,
						items: getters.mappedProviderList(p.organisation)
					}));
			},

			/**
			 * @name providerList
			 * @description get all provider list
			 * @param {Object} state
			 */
			providerList: (state, getters) => {
				let providers = [];

				state.matterTypeList
					.filter((p) => p.organisation.length > 0)
					.map((p) => {
						p.organisation.map((o) => {
							if (!providers.find((item) => item.nameUnique === o.nameUnique)) providers.push(o);
						});
					});

				return getters.mappedProviderList(providers);
			},

			/**
			 * @name providerById
			 * @description get providers filtered by id
			 * @param {Object} state
			 */
			providerById: (state, getters) => (ids) => getters.providerList.filter((p) => (Array.isArray(ids) ? ids : [ids]).includes(p.id)),

			/**
			 * @name providerListByMatterType
			 * @description get providers filtered by nameUnique
			 * @param {Object} state
			 */
			providerListByMatterType: (state, getters) => (matterType) => {
				return getters.mappedMatterTypeList.find((m) => m.nameUnique === matterType)?.items;
			},

			/**
			 * @name clientMatterTypes
			 * @description get matter types for clients dashboard
			 * @param {Object} state
			 */
			clientMatterTypes: (state) => (nameUnique) => {
				let matterTypes = ['loans', 'home_reversion_plan', 'care_plans'];
				if (nameUnique) matterTypes = Array.isArray(nameUnique) ? nameUnique : [nameUnique];
				return state.matterTypeList
					.filter((m) => matterTypes.includes(m.nameUnique))
					.map((p) => ({
						id: p.id,
						name: p.name,
						nameUnique: p.nameUnique,
						data: p.data
					}));
			},

			/**
			 * @name estatePlanningMatterTypes
			 * @description get matter types for estate planning
			 * @param {Object} state
			 */
			estatePlanningMatterTypes: (state) => (nameUnique) => {
				let matterTypes = ['care_plans', 'power_of_attorney', 'will'];
				if (nameUnique) matterTypes = Array.isArray(nameUnique) ? nameUnique : [nameUnique];
				return state.matterTypeList
					.filter((m) => matterTypes.includes(m.nameUnique))
					.map((p) => ({
						id: p.id,
						name: p.name,
						nameUnique: p.nameUnique,
						data: p.data
					}));
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			setMatterTypeList(state, data) {
				state.matterTypeList = data;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadMatterTypeList
			 * @param {function} commit
			 */
			async loadMatterTypeList({ commit }, payload) {
				const { data } = await MatterType.list(payload);
				commit('setMatterTypeList', data.data);
			}
		};
	}
}
