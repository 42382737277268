<!--
	@name criteria-chips
	@description Display a collection of chips to demonstrate criteria crtieria search results
	@date 2020/02/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="ma-n1 d-inline-block criteria-chips" :component="$options.name">
		<template v-for="item in orderedCriteriaItems">
			<v-tooltip :key="item.Criteria_Category_ID" bottom max-width="600" :disabled="!tooltipText(item)">
				<template #activator="{ on }">
					<v-chip class="ma-1 font-weight-regular" :color="chipColor(item.Criteria_Summary)" small outlined label v-on="on" @click="openFullText(item)">
						{{ item.Criteria_Title | truncate(truncateCharacterCount, '') }}
					</v-chip>
				</template>
				<span>{{ tooltipText(item) }}</span>
			</v-tooltip>
		</template>

		<common-dialog ref="fullTextDialog" :max-width="800" :component="$options.name" :color="criteriaItem_Color">
			<template #header>
				{{ criteriaItem_Status }}
			</template>
			<template #body>
				<h3 class="text-body-2 font-weight-medium mb-0">
					{{ criteriaItem_Lender }}
				</h3>
				<h2 class="text-h6 text-body-1 font-weight-bold mb-3">
					{{ criteriaItem_Title }}
				</h2>
				<p class="nlbr text-body-2 mb-0">
					{{ criteriaItem_FullText }}
				</p>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	const STATUS_CODES = {
		1: { color: '#009d00', text: 'white--text', status: 'Yes' },
		2: { color: '#f0cc00', text: 'black--text', status: 'Yes (Conditions)' },
		2.5: { color: '#a227ab', text: 'white--text', status: 'Refer' },
		3: { color: '#f77100', text: 'black--text', status: 'Yes (Exceptions)' },
		4: { color: '#b81800', text: 'white--text', status: 'No' },
		5: { color: '#8c8d8c', text: 'white--text', status: 'Info Only' }
	};

	import { orderBy } from 'lodash';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'common-base-criteria-chips',

		components: { CommonDialog },

		props: {
			criteriaItems: { type: Array, required: true },
			truncateCharacterCount: { type: Number, required: false, default: 200 },
			ordered: { type: Boolean, required: false, default: true }
		},

		data() {
			return {
				criteriaItem_FullText: null,
				criteriaItem_Lender: null,
				criteriaItem_Title: null,
				criteriaItem_Status: null,
				criteriaItem_Color: null
			};
		},

		computed: {
			/**
			 * @name orderedCriteriaItems
			 * @description Orders the chips for listing
			 * @return {Array} Criteria chips
			 */
			orderedCriteriaItems() {
				if (!this.ordered) return this.criteriaItems;
				return orderBy(this.criteriaItems, ['Criteria_Summary'], ['asc']);
			}
		},

		methods: {
			/**
			 * @name chipClass
			 * @description Chip class based on criteria summary
			 * @param {String|Number} summary Criteria summary
			 * @return {String} Class name
			 */
			chipClass(summary) {
				summary = Number(summary);
				return STATUS_CODES[summary].text;
			},

			/**
			 * @name chipColor
			 * @description Chip color based on criteria summary
			 * @param {String|Number} summary Criteria summary
			 * @return {String} Color code
			 */
			chipColor(summary) {
				summary = Number(summary);
				return STATUS_CODES[summary].color;
			},

			tooltipText(item) {
				if (!item.Criteria_Full_Text) return false;
				if (item.Criteria_Full_Text.length > 300) return 'Click for full text';
				return item.Criteria_Full_Text;
			},

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			openFullText(item) {
				if (!item.Criteria_Full_Text) return false;
				//if(item.Criteria_Full_Text.length <= 300) return false;
				this.criteriaItem_Lender = item.Lender_Name;
				this.criteriaItem_Title = item.Criteria_Title;
				this.criteriaItem_FullText = item.Criteria_Full_Text;
				this.criteriaItem_Status = STATUS_CODES[item.Criteria_Summary].status;
				this.criteriaItem_Color = STATUS_CODES[item.Criteria_Summary].color;
				this.$refs.fullTextDialog.open();
			}
		}
	};
</script>

<style scoped lang="scss">
	.criteria-chips {
		.v-chip.v-chip--outlined.v-chip.v-chip {
			border-width: 2px;
			border-left-width: 6px;
			color: black !important;
			background-color: rgba(255, 255, 255, 0.8) !important;
		}
	}

	.nlbr {
		white-space: pre-line;
	}
</style>
