import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Docebo
 * @exports Service/Partner/Docebo
 * @description API Partner Docebo Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Docebo {
	/**
	 * @static @name compactList
	 * @description Get compact list of courses for user
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static compactList() {
		return $http.post(`partner/docebo/compact-list`);
	}

	/**
	 * @static @name catalogContent
	 * @description Get content for a given catalog
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static catalogContent(catalogId) {
		return $http.post(`partner/docebo/catalog-content/${catalogId}`);
	}

	/**
	 * @static @name updateUser
	 * @description Updarte a user record by ID
	 * @param {Number} userId
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static updateUser(userId, payload) {
		return $http.put(`partner/docebo/update-user/${userId}`, payload);
	}
}
