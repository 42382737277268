import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Form
 * @exports Service/Cms/Form
 * @description API Form Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Config {
	/**
	 * @static @name get
	 * @description Get config using type and name
	 * @param {String} type
	 * @param {String} name
	 * @returns {Object} Promise a http response
	 */
	static get(type, name, params) {
		return $http.get(`cms/config/using/${type}/${name}`, { params });
	}
}
