import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Case
 * @exports Service/Case
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Case {
		/**
	 * @static @name list
	 * @description Get a list of case resources
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
		static list(payload) {
			return $http.post('case/list', payload);
		}
}
