import { $http } from '@/utils';

/**
 * @namespace Service
 * @class FirmJoinRequest
 * @exports Service/Account/FirmJoinRequest
 * @description API FirmJoinRequest Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class FirmJoinRequest {
	/**
	 * @static @name list
	 * @description Get all firm join requests with details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.get('account/registration/firm-join-request/list', payload);
	}

	/**
	 * @static @name acceptFirmJoinRequest
	 * @description Accept a firm join request
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static acceptFirmJoinRequest(payload) {
		return $http.post('account/registration/firm-join-request/accept-request', payload);
	}

	/**
	 * @static @name get
	 * @description Get user firm join request details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`account/registration/firm-join-request/${id}`);
	}

	/**
	 * @static @name modify
	 * @description Update some firm join request details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static modify(id, payload) {
		return $http.patch(`account/registration/firm-join-request/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete firm join request details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/registration/firm-join-request/${id}`);
	}
}
