<!--
	@name common-base-progress-circle
	@description Show a progress circle
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-progress-circular :color="colour" :rotate="rotate" :size="size" :value="displayValue" :width="width">
			<span class="text-body-1 font-weight-medium">
				{{ bufferValue | numFormat }}
			</span>
		</v-progress-circular>
	</div>
</template>

<script>
	export default {
		name: 'common-base-progress-circle',

		props: {
			colour: { type: String, default: 'light-blue' },
			rotate: { type: Number, default: -90 },
			size: { type: Number, default: 125 },
			value: { type: Number, default: undefined },
			width: { type: Number, default: 12 }
		},

		data() {
			return { bufferValue: 0, displayValue: 0, interval: null };
		},

		watch: {
			value: {
				handler() {
					if (this.value > 0) this.startBuffer();
				}
			}
		},

		mounted() {
			if (this.value > 0) this.startBuffer();
		},

		beforeDestroy() {
			clearInterval(this.interval);
		},

		methods: {
			/**
			 * @name startBuffer
			 * @description TODO
			 */
			startBuffer() {
				this.interval = setInterval(() => {
					if (this.displayValue === 100) {
						this.bufferValue = this.value;
						clearInterval(this.interval);
						return;
					}

					this.displayValue = 100;
					this.bufferValue = Math.round((this.value / 100) * this.displayValue);
				}, 1000);
			}
		}
	};
</script>
