import { $http } from '@/utils';

/**
 * @namespace Service
 * @class File
 * @exports Service/File
 * @description API File Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class File {
	/**
	 * @static @name get
	 * @description Get presigned url for api file
	 * @returns {Object} Promise a http response
	 */
	static get(s3Key, filename) {
		return $http.get(`file?resource=${s3Key}&filename=${encodeURIComponent(filename)}`);
	}

	/**
	 * @static @name getAsset
	 * @description Get presigned url for asset file
	 * @returns {Object} Promise a http response
	 */
	static getAsset(s3Key, filename) {
		return $http.get(`file?resource=${s3Key}&filename=${encodeURIComponent(filename)}&bucket=air.assets`);
	}
}
