import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Reset
 * @exports Service/Account/Reset
 * @description API Reset Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Reset {
	/**
	 * @static @name post
	 * @description Perform an account password reset request
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('account/reset', payload);
	}

	/**
	 * @static @name patch
	 * @description Perform a reset of the users password
	 * @param {Object} payload
	 * @param {String} token
	 * @returns {Object} Promise a http response
	 */
	static patch(payload, token) {
		return $http.patch(`account/reset/${token}`, payload);
	}

}
