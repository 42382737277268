<!--
	@name app-air-club-select
	@description Select tab view for the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-6">
		<h2 class="text-h5 font-weight-bold mb-0">
			Are you ready to grow your business?
		</h2>
		<p class="text-body-1 mt-2 mb-5">
			As an Air Select member you can now access a range of exclusive benefits, previously only accessible by the large specialists.
		</p>

		<div v-for="(benefit, i) in benefits" :key="i" class="d-flex justify-center align-content-center align-center mb-2" :class="[{ '': i % 2 === 1 }, benefit.cardClass]">
			<div class="flex-grow-0 pa-6">
				<v-img contain max-height="6rem" max-width="10rem" class="brand-logo" :src="benefit.image | urlize" />
			</div>
			<div class="flex-grow-1 pa-6 text-body-1">
				<div v-html="$sanitize(benefit.text)" />
				<v-btn v-if="benefit.button" class="ma-0 mt-3" min-width="15rem" small color="primary" dark :href="benefit.button.target" :target="benefit.button.method">
					{{ benefit.button.text }}
				</v-btn>
			</div>
		</div>

		<v-alert color="info" icon="mdi-chat" prominent text outlined border="left" class="text-body-1 mt-6 mb-0 pl-6">
			And don't forget we're here when you need us. <a @click="openChat">Start a live chat</a> for instant answers to any questions you have.
		</v-alert>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';

	export default {
		name: 'app-air-club-select',

		data() {
			return {
				benefits: [
					{
						text: '<span class="font-weight-bold">Marketing Hub: </span>Developed using 20 years\' experience and a wealth of customer insight this comprehensive range of marketing materials will take your business to the next level by generating cost effective leads',
						cardClass: 'blue-grey lighten-5 black--text text--darken-4',
						button: {
							text: 'Go to Marketing Hub',
							target: 'https://airselecthub.bdprints.co.uk/',
							method: '_blank'
						},
						image: 'icon/activity/update.png'
					},
					{
						text: '<span class="font-weight-bold">Collateral Hub: </span>With full access to our suite of approved sales process literature and correspondence your cases will be completing smoothly and quickly',
						cardClass: 'grey lighten-5 black--text text--darken-4',
						button: {
							text: 'Go to Collateral Hub',
							target: 'https://airselecthub.bdprints.co.uk/',
							method: '_blank'
						},
						image: 'icon/activity/list.png'
					},
					{
						text: "Integrating Knowledge Bank's intelligent criteria with our market-leading sourcing software gives you the very latest comprehensive lending criteria in real time. This industry-first in super-tech ensures the best results for your clients every time.",
						cardClass: 'blue-grey lighten-5 black--text text--darken-4',
						button: {
							text: 'Source a Product',
							target: '/source',
							method: null
						},
						image: 'logo/organisation/knowledge_bank.svg'
					},
					{
						text: 'Are you taking advantage of all that the Air Later Life Academy can offer? Designed specifically to give you the knowledge you need to offer holistic advice, including fact finding, report writing, benefits and pensions. All with structured CPD and the option to progress to Air Later Life Professional status.',
						cardClass: 'grey lighten-5 black--text text--darken-4',
						button: {
							text: 'Go to Air Academy',
							target: '/academy',
							method: null
						},
						image: 'logo/brand/air_academy_logo.svg'
					}
				]
			};
		},

		methods: {
			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style scoped lang="scss">
	.v-sheet.outlined {
		flex-basis: 30%;
	}
</style>