import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Configuration
 * @exports Service/Cms/Site
 * @description API Configuration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Site {
	/**
	 * @static @name usingDomain
	 * @description Get Site
	 * @returns {Object} Promise a http response
	 */
	static get() {
		return $http.get(`cms/site`);
	}

	/**
	 * @name getMembership
	 * @description Get membership site config
	 * @returns {Object} Promise a http response
	 */
	static getMembership(type) {
		return $http.get(`cms/site/membership/${type}`);
	}
}
