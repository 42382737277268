import { CmsInsight } from '@/service';
import { orderBy } from 'lodash';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Insight {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			insightList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setInsightList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setInsightList: (state, data) => (state.insightList = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadInsightList
			 * @param {function} commit
			 */
			async loadInsightList({ state, commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				if (state.insightList.length > 0) return;
				let { data } = await CmsInsight.list(domain);
				const insightList = orderBy(data.data, ['created'], ['desc']);
				commit('setInsightList', insightList);
			},

			/**
			 * @async @name loadInsightItem
			 * @param {function} commit
			 * @param {string} id
			 */
			async loadInsightItem(context, id) {
				return CmsInsight.get(id);
			}
		};
	}
}
