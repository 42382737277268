import { $http } from '@/utils';

/**
 * @namespace Service
 * @class User
 * @exports Service/Account/Organisation/User
 * @description API Organisation User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class User {
	/**
	 * @static @name list
	 * @description Get all organisations with details
	 * @returns {Object} Promise a http response
	 */
	static list(oid) {
		return $http.get(`account/organisation/${oid}/user/list`);
	}
}
