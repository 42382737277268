import { render, staticRenderFns } from "./shortcuts.vue?vue&type=template&id=4a0b4fc8&"
import script from "./shortcuts.vue?vue&type=script&lang=js&"
export * from "./shortcuts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports