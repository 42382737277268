import { NotificationUser } from '@/service';

/**
 * @class @name Notification
 * @description Exposes menu store module
 */
export default class Notification {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			notificationList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setNotificationList
			 * @description used to set app config
			 * 			 * @param {Object} state
			 * @param {Object} data
			 */
			setNotificationList(state, data) {
				state.notificationList = data;
			},

			/**
			 * @name updateNotification
			 * @description used to set app config
			 *  @param {Object} state
			 * @param {Object} data
			 */
			updateNotification(state, notification) {
				state.notificationList = [...state.notificationList.map((item) => (item.id === notification.id ? { ...item, ...notification } : item))];
			},

			/**
			 * @name deleteNotification
			 * @description used to set app config
			 * @param {Object} state
			 * @param {Object} data
			 * @param {String} id
			 */
			deleteNotification(state, id) {
				state.notificationList = state.notificationList.filter((item) => item.id !== id);
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @name loadNotificationList
			 * @param {Object} context
			 * @param {Object} payload
			 */
			loadNotificationList({ commit }, payload) {
				return NotificationUser.list(payload).then(({ data }) => commit('setNotificationList', data.data));
			},

			/**
			 * @name markAsRead
			 * @param {Object} context
			 * @param {Object} notification
			 * @description Mark a notification as read
			 */
			markAsRead({ commit }, notification) {
				return NotificationUser.patch(notification.id, { read: true }).then(() => commit('updateNotification', { ...notification, read: true }));
			},

			/**
			 * @name deleteNotification
			 * @param {Object} context
			 * @param {String} id
			 */
			deleteNotification({ commit }, id) {
				return NotificationUser.delete(id).then(() => commit('deleteNotification', id));
			}
		};
	}
}
