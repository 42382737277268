<!--
	@name app-client-service-header
	@description Header UI
	@date 2020/07/16
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-row no-gutters class="justify-center">
			<v-col cols="12" md="8" class="d-flex">
				<div class="align-self-center">
					<p class="headline mb-0 font-weight-bold">
						{{ header }}
					</p>
					<p class="title mb-1 font-weight-bold">
						{{ subheader }}
					</p>
					<a class="text-body-2 font-weight-bold" target="_blank" :href="url">{{ link }}</a>
				</div>
				<v-spacer />
				<v-img max-width="300" max-height="100" contain class="brand-logo" :src="logo | urlize('organisation')" />
			</v-col>
		</v-row>
		<v-divider class="my-6" />
	</div>
</template>

<script>
	export default {
		name: 'app-client-service-header',

		components: {},

		props: {
			header: { type: String, required: true },
			subheader: { type: String, required: true },
			link: { type: String, required: true },
			url: { type: String, required: true },
			logo: { type: String, required: true }
		},

		data() {
			return {};
		}
	};
</script>
