import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Iteration
 * @exports Service/Iteration
 * @description API Iteration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Iteration {
	/**
	 * @static @name add
	 * @description Add a new Iteration resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static add(payload) {
		return $http.post('iteration', payload);
	}

	/**
	 * @static @name get
	 * @description Add a new Iteration resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`iteration/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new Iteration resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('iteration/list', payload);
	}
}
