<!--
	@name app-insights-hub-content-list
	@description Insights Hub Content List
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Insights Hub
					</div>
				</div>
			</v-container>
		</v-img>

		<div>
			<v-container>
				<!-- Breadcrumbs -->
				<v-breadcrumbs :items="breadcrumbs">
					<template #divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>
				</v-breadcrumbs>

				<!-- Content list -->
				<div class="pb-8">
					<v-row>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Altz-1024x574.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div
										class="mt-4 text-h5 secondary--text text-truncate"
										style="-webkit-line-clamp: 3"
										title="Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in Summer KFI Campaign"
									>
										Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in Summer KFI
										Campaign
									</div>

									<div class="text-body-2 mt-4">
										21 September 2023 – Air, the leading later life lending platform, has announced that it will mark World Alzheimer's Day...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Shortlist-header.png" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Later Life Lending Awards shortlist announced!
									</div>

									<div class="text-body-2 mt-4">
										Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering
										dedication to...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/07/WLT-1024x551.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Air launches a White Label Calculator to support customer conversations
									</div>

									<div class="text-body-2 mt-4">
										27 July 2023: With Consumer Duty front of mind, Air has launched a new retained equity and repayments calculator (click...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Altz-1024x574.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in Summer KFI
										Campaign
									</div>

									<div class="text-body-2 mt-4">
										21 September 2023 – Air, the leading later life lending platform, has announced that it will mark World Alzheimer's Day...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Shortlist-header.png" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Later Life Lending Awards shortlist announced!
									</div>

									<div class="text-body-2 mt-4">
										Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering
										dedication to...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/07/WLT-1024x551.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Air launches a White Label Calculator to support customer conversations
									</div>

									<div class="text-body-2 mt-4">
										27 July 2023: With Consumer Duty front of mind, Air has launched a new retained equity and repayments calculator (click...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Altz-1024x574.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in Summer KFI
										Campaign
									</div>

									<div class="text-body-2 mt-4">
										21 September 2023 – Air, the leading later life lending platform, has announced that it will mark World Alzheimer's Day...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Shortlist-header.png" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Later Life Lending Awards shortlist announced!
									</div>

									<div class="text-body-2 mt-4">
										Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering
										dedication to...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img src="https://airlaterlife.co.uk/app/uploads/2023/07/WLT-1024x551.jpg" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text">
										Air launches a White Label Calculator to support customer conversations
									</div>

									<div class="text-body-2 mt-4">
										27 July 2023: With Consumer Duty front of mind, Air has launched a new retained equity and repayments calculator (click...
									</div>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</div>
				<v-pagination v-model="page" :length="10" :total-visible="4" class="mb-12" />
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-insights-hub-content-list',

		components: {},

		data() {
			return {
				page: 1,
				breadcrumbs: [{ text: 'Insights Hub', exact: true, to: '/air-later-life/insights-hub' }, { text: 'Air News' }]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>
