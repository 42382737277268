import { $http } from '@/utils';

/**
 * @namespace Service
 * @class List
 * @exports Service/Client/List
 * @description API Client Connected Service
 * @date 2022/09/20
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class List {
	/**
	 * @static @name list
	 * @description Get connect client list for a specific client (RBAC)
	 * @param {Object} clientId
	 * @returns {Object} Promise a http response
	 */
	static list(clientId, payload) {
		return $http.post(`client/${clientId}/connected/list`, payload);
	}
}
