<!--
	@name app-air-academy-webinar-item
	@description Card for the webinar
	@date 2020/05/05
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" :style="item.fixedHeight && !!item.fixedHeight ? '' : 'height: 100%'">
		<v-card :height="item.fixedHeight && !!item.fixedHeight ? 'auto' : '100%'" class="d-flex flex-column">
			<v-img :src="item.image" :contain="'imageContain' in item && item.imageContain" height="194">
				<v-row class="no-gutters iconContainer text-center" align="center">
					<v-col>
						<v-tooltip v-if="item.disable" bottom>
							<template #activator="{ on }">
								<v-icon size="100" color="error" class="icon mt-n1" v-on="on">
									mdi-close-circle
								</v-icon>
							</template>
							<span>{{ item.disable }}</span>
						</v-tooltip>
						<v-btn v-else icon x-large height="100" width="100" :href="item.emit ? '' : lmsUrl" target="_blank" @click="handleClick(item)">
							<v-icon size="100" color="blue lighten-1" class="icon mt-n1 clickable">
								mdi-play-circle
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-img>
			<v-card-text class="text-subtitle-2 font-weight-bold">
				<span class="line-clamp" style="-webkit-line-clamp: 1">
					{{ item.name }}
				</span>
				<p v-if="!item.hideStatus" class="mb-0 font-weight-regular" :class="[{ 'success--text': status === 2 }, { 'warning--text': status === 1 }, 'error--text']">
					{{ statusText }}
				</p>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	export default {
		name: 'app-air-academy-webinar-item',

		props: {
			item: { type: Object, required: true }
		},

		data() {
			return {};
		},

		computed: {
			pending() {
				return this.item.enrollment_info?.status_label == 'in progress';
			},

			complete() {
				return this.item.enrollment_info?.status_label == 'completed';
			},

			status() {
				if (this.complete) return 2;
				if (this.pending) return 1;
				return 0;
			},

			statusText() {
				if (this.complete) return 'Complete';
				if (this.pending) return 'In progress';
				return 'Incomplete';
			},

			lmsUrl() {
				if (this.item.url) return this.item.url;
				return `https://academy.airlaterlife.co.uk/learn/course/${this.item.id_course}/${this.item.slug_name}`;
			}
		},

		methods: {
			handleClick(item) {
				if(item.emit) this.$emit(item.emit, true);
			}
		}
	};
</script>

<style scoped lang="scss">
	.iconContainer {
		height: 194px;
		.icon {
			opacity: 0.5;
		}
		.clickable {
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
</style>
