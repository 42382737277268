<!--
	@name app-dashboard-shortcuts
	@description Shortcuts
	@date 2020/05/13
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section class="mb-6">
			<template #header>
				Shortcuts
			</template>
			<template #body>
				<v-skeleton-loader v-if="!items.length" type="list-item-three-line" />
				<div v-else class="ma-n2">
					<v-btn
						v-for="(shortcut, index) in items"
						:key="shortcut.nameUnique"
						dark
						small
						class="ma-2"
						:class="colourClass(index)"
						:data-shortcut="shortcut.name"
						label
						@click="$router.push(shortcut.url)"
					>
						{{ shortcut.name }}
					</v-btn>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	const COLOURS = ['primary', 'secondary'];

	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-shortcuts',

		components: {
			CommonStructureSection
		},

		props: {
			items: { type: Array, required: true, default: () => [] }
		},

		methods: {
			/**
			 * @name colourClass
			 * @description TODO
			 * @param {Number} key TODO
			 * @return {String} Classes for element
			 */
			 colourClass(key) {
				return COLOURS[key % COLOURS.length];
			}

			// async handleButtonClick(shortcut) {
			// 	if (shortcut.url) window.location = shortcut.url;
			// 	else if (shortcut.emit) {
			// 		this.$emit(shortcut.emit);
			// 		return null;
			// 	} else if (shortcut.service) {
			// 		if (shortcut.service.type === 'source') {
			// 			// Client Session
			// 			let response = await SourceService[shortcut.service.function](shortcut.service.param);
			// 			if (response.success && response.url) window.location = response.url;
			// 		}
			// 	}
			// }
		}
	};
</script>
