import store from '@/store';
import Club from '@/component/app/air-club';
import Join from '@/component/app/air-club/join';
import Deal from '@/component/app/air-club/deal';
import Select from '@/component/app/air-club/select';
import Product from '@/component/app/air-club/product';
import Provider from '@/component/app/air-club/provider';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for Air Club page
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'airClub',
		path: '/club',
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		component: Club,
		children: [
			{
				name: 'airClub.join',
				path: 'join',
				meta: { layout: 'default-authed' },
				component: Join
			},
			{
				name: 'airClub.deal',
				path: 'deals',
				meta: { layout: 'default-authed' },
				component: Deal
			},
			{
				name: 'airClub.products',
				path: 'products',
				meta: { layout: 'default-authed' },
				component: Product
			},
			{
				name: 'airClub.providers',
				path: 'providers',
				meta: { layout: 'default-authed' },
				component: Provider
			},
			{
				name: 'airClub.select',
				path: 'select',
				meta: { layout: 'default-authed' },
				component: Select
			}
		]
	}
];
