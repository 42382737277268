import Academy from '@/component/app/air-academy';
import Dashboard from '@/component/app/air-academy/dashboard';
import Ambassador from '@/component/app/air-academy/ambassador';
import Cost from '@/component/app/air-academy/cost';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'airAcademy',
		path: '/academy',
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		component: Academy,
		children: [
			{
				name: 'airAcademy.dashboard',
				path: 'dashboard',
				meta: { layout: 'default-authed' },
				component: Dashboard
			},
			{
				name: 'airAcademy.ambassadors',
				path: 'ambassadors',
				meta: { layout: 'default-authed' },
				component: Ambassador
			},
			{
				name: 'airAcademy.cost',
				path: 'cost',
				meta: { layout: 'default-authed' },
				component: Cost
			}
		]
	}
];
