<!--
	@name app-product updates
	@description Product updates page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Product Updates
					</div>
				</div>
			</v-container>
		</v-img>
		<div>
			<v-container class="py-0 px-4">
				<div class="mt-10 mb-10">
					<p class="text-body-1 mb-1">
						Designed with you in mind, this page will show you all the upcoming last KFI and application submission dates.
					</p>
					<p v-if="site.softwareName" class="text-body-1 mb-0">
						<router-link to="/advisers">
							Log in
						</router-link> to your {{ site.softwareName }} account now to start a sourcing session
					</p>
				</div>
			</v-container>

			<v-container class="pa-0 pb-10">
				<div class="mx-auto">
					<rate-change-table :product-changes="latestProductChanges" />
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import RateChangeTable from '@/component/app/common/rate-change-table';

	export default {
		name: 'app-product-changes',

		components: { RateChangeTable },

		computed: {
			...mapState('CmsProductUpdate', ['latestProductChanges']),
			...mapState('CmsSite', ['site'])
		},

		created() {
			this.loadLatestProductChanges();
		},

		methods: {
			...mapActions('CmsProductUpdate', ['loadLatestProductChanges'])
		}
	};
</script>
