import { LifetimeMortgageSourcing } from '@/service';

/**
 * @class @name Sourcing
 * @description Exposes menu store module
 */
export default class Sourcing {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchSourcingList
			 * @param {function} commit
			 * @param {Object} payload
			 */
			fetchSourcingList(context, payload) {
				return LifetimeMortgageSourcing.list(payload);
			},

			/**
			 * @async @name fetchResults
			 * @param {Object} context
			 * @param {Object} payload
			 */
			fetchResults(context, payload) {
				return LifetimeMortgageSourcing.results(payload);
			},

			/**
			 * @async @name submitIteration
			 * @param {Object} context
			 * @param {Object} payload
			 */
			submitIteration(context, payload) {
				return LifetimeMortgageSourcing.iteration(payload);
			},

			/**
			 * @async @name submitSourcing
			 * @param {Object} context
			 * @param {Object} payload
			 */
			submitSourcing(context, payload) {
				return LifetimeMortgageSourcing.post(payload);
			},

			/**
			 * @async @name submitQuickSourcing
			 * @param {Object} context
			 * @param {Object} payload
			 */
			submitQuickSourcing(context, payload) {
				return LifetimeMortgageSourcing.quick(payload);
			},

			/**
			 * @async @name fetchInsights
			 * @param {Object} context
			 * @param {Object} payload
			 */
			fetchInsights(context, payload) {
				return LifetimeMortgageSourcing.fetchInsights(payload);
			},

			/**
			 * @async @name saveToPdf
			 * @param {Object} context
			 * @param {Object} payload
			 */
			saveToPdf(context, payload) {
				return LifetimeMortgageSourcing.saveToPdf(payload);
			}
		};
	}
}
