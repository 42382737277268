import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Client
 * @exports Service/Client/UserConnect
 * @description API Client UserConnect Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserConnect {
	/**
	 * @static @name post
	 * @description Get all clients with associated clients
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('client/user-connect', payload);
	}
}
