<!--
	@name app-source-result-multi-select
	@description Equity dialog showing showing request KFI
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-fade-transition>
			<v-footer v-if="selectedProductList.length" fixed inset tile class="amber accent-2">
				<v-row class="d-flex pa-2" justify="center" no-gutters>
					<div class="message-text">
						<v-tooltip top :disabled="!selectedProductsMessageTooltip || isFactFind">
							<template #activator="{ on }">
								<span class="products-selected" v-on="on">
									{{ selectedProductsMessage }}
								</span>
							</template>
							<span>{{ selectedProductsMessageTooltip }}</span>
						</v-tooltip>
					</div>
					<div class="d-flex align-center">
						<v-btn class="ml-2" outlined color="error" @click="$emit('clear-selected-product-list')">
							Clear
						</v-btn>
					</div>

					<v-divider vertical class="mx-5" />

					<div v-if="!isFactFind" class="d-flex align-center">
						<v-select
							v-model="selectedAction"
							:disabled="loadingProducts"
							:items="actionItems"
							item-text="name"
							item-value="key"
							solo
							dense
							label="Choose action"
							class="action-chooser"
						/>
						<v-btn
							class="ml-2 primary"
							:loading="loadingProducts"
							:class="hasSelected() && !disabled() ? 'pulse--primary' : ''"
							:disabled="disabled()"
							@click="applySelected"
						>
							Submit
						</v-btn>
					</div>

					<div v-else>
						<v-btn class="primary pulse--primary" @click="$emit('submit-fact-find-products')">
							Submit
						</v-btn>
					</div>
				</v-row>
			</v-footer>
		</v-fade-transition>

		<common-dialog ref="checkout">
			<template #header>
				Are you sure you wish to request these KFIs?
			</template>

			<template #body>
				<v-card v-if="showExpiredTokenWarning && !skipExpiredTokenWarning" elevation="0">
					<v-alert dense outlined text type="warning">
						<span class="font-weight-bold"> Expired credentials </span> prevented {{ expiredCredentialProviders.length }} Provider{{
							expiredCredentialProviders.length > 1 ? 's' : ''
						}}
						from returning products.
					</v-alert>
					<p class="text-body-2 mb-0">
						{{
							expiredCredentialProviders
								.map((p) => p.name)
								.join(', ')
								.replace(/, ([^,]*)$/, ' and $1')
						}}
						failed to return products due to expired credentials on your account.
						<span class="font-weight-bold error--text">This is a permanent error that, if not addressed, will affect all sourcing sessions and research documents.</span>
						To quickly resolve this issue, click UPDATE CREDENTIALS.
					</p>

					<v-card-actions class="pa-0 pt-5">
						<v-spacer />
						<v-btn color="primary mr-1" @click="openProductLoader">
							Update Credentials
						</v-btn>
						<v-btn outlined color="blue-grey" @click="skipExpiredTokenWarning = true">
							proceed to request KFIs
						</v-btn>
					</v-card-actions>
				</v-card>
				<v-card v-else elevation="0">
					<p class="text-body-2 mb-0">
						When you are ready to proceed, click <span class="font-weight-bold">Proceed to Request KFIs</span>. On the next page, you'll be asked to confirm the details
						you've already entered and provide any outstanding information before your KFI requests can be submitted. Should you wish to remove a product from the list,
						click the <v-icon>mdi-trash-can</v-icon> icon.
					</p>
					<v-list two-line class="pt-4 pb-0">
						<v-list-item v-for="product in selectedProductsKfi" :key="product.id" class="amber lighten-5 my-1">
							<v-list-item-content>
								<v-list-item-title class="mb-1 font-weight-medium text-body-2">
									{{ product.provider.name }} - <span class="caption">{{ product.name }}</span>
								</v-list-item-title>
								<v-list-item-subtitle class="caption">
									<span class="white warning--text text--darken-1 font-weight-regular">Advance £{{ product.advance | numFormat('0,0') }}</span>
									<span class="white amber--text text--darken-4 font-weight-regular d-inline-block ml-3">Drawdown £{{ product.drawdownFacility.amount | numFormat('0,0') }}</span>
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<v-btn
									class="ga--trigger ga--remove-from-cart"
									data-ga-id="remove-product-button"
									icon
									@click="removeSelectedProduct(product)"
								>
									<v-icon color="error">
										mdi-trash-can
									</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>

					<v-card-actions class="pa-0 pt-5">
						<v-spacer />
						<v-btn
							class="ga--trigger ga--begin-checkout"
							data-ga-id="cart"
							color="primary mr-1"
							@click="confirmRequest"
						>
							Proceed to Request KFIs
						</v-btn>
						<v-btn
							class="ga--trigger ga--close-cart"
							data-ga-id="cart"
							outlined
							color="blue-grey"
							@click="$refs.checkout.close()"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</common-dialog>

		<common-dialog-confirm ref="confirm" />

		<common-dialog-progress ref="progress" :text="false" :progress-color="'green'" stream indeterminate :percent="progressDialog.progress">
			<template #header>
				Processing Request
			</template>
			<template #body>
				<p class="text-body-1 mb-3">
					{{ progressDialog.text }}
				</p>
			</template>
		</common-dialog-progress>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import CommonDialogProgress from '@/component/common/dialog/progress';

	export default {
		name: 'app-source-result-multi-select',

		components: { CommonDialog, CommonDialogConfirm, CommonDialogProgress },

		props: {
			quickQuote: { type: Boolean, required: true },
			productList: { type: Array, required: true },
			providerList: { type: Array, required: true },
			selectedProductList: { type: Array, required: true },
			loadingProducts: { type: Boolean, required: true, default: false }
		},

		data() {
			return {
				submitting: false,
				progressDialog: { active: false },
				selectedAction: '',
				buttonDisabled: false,
				skipExpiredTokenWarning: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			iterationId() {
				return this.$route.query.iteration;
			},

			processId() {
				return this.$route.params.processId;
			},

			kfiRequestAccess() {
				return !(this.quickQuote || this.matterPanel?.lifetimeMortgage?.keyFactsIllustration === false);
			},

			/**
			 * @name selectedProductsMessage
			 * @description Create a message based on selected action
			 * @return {String} Message
			 */
			selectedProductsMessage() {
				if (this.isFactFind) return `${this.selectedProductList.length} Product${this.selectedProductList.length === 1 ? '' : 's'} Selected`;
				if (this.selectedAction === 'request-kfi') return `${this.selectedProductsKfi.length} Product${this.selectedProductsKfi.length === 1 ? '' : 's'} Selected`;
				if (this.selectedAction === 'restrict-products')
					return `${this.selectedProductsRestricted.length} Product${this.selectedProductsRestricted.length === 1 ? '' : 's'} Selected`;
				if (this.selectedAction === 'create-forecast')
					return `${this.selectedProductsForecast.length} Product${this.selectedProductsForecast.length === 1 ? '' : 's'} Selected`;
				return `${this.selectedProductList.length} Product${this.selectedProductList.length > 1 ? 's' : ''} Selected`;
			},

			/**
			 * @name selectedProductsMessageTooltip
			 * @description Create tooltip text based on selected products
			 * @return {String} Tooltip text
			 */
			selectedProductsMessageTooltip() {
				let semantics = this.selectedProductList.length === 1 ? 'has' : 'have';
				if (this.selectedAction === 'request-kfi' && this.selectedProductsKfi.length < this.selectedProductList.length)
					return `${this.selectedProductList.length} Product${this.selectedProductList.length > 1 ? 's' : ''} ${semantics} been selected however ${
						this.selectedProductsKfi.length === 0 ? '' : 'only'
					} ${this.selectedProductsKfi.length === 0 ? 'none' : this.selectedProductsKfi.length} ${
						this.selectedProductsKfi.length === 1 ? 'is' : 'are'
					} applicable for KFI requests`;
				if (this.selectedAction === 'restrict-products' && this.selectedProductsRestricted.length < this.selectedProductList.length)
					return `${this.selectedProductList.length} Product${this.selectedProductList.length > 1 ? 's' : ''} ${semantics} been selected however ${
						this.selectedProductsRestricted.length === 0 ? '' : 'only'
					} ${this.selectedProductsRestricted.length === 0 ? 'none' : this.selectedProductsRestricted.length} ${
						this.selectedProductsRestricted.length === 1 ? 'is' : 'are'
					} applicable for Restriction`;
				if (this.selectedAction === 'create-forecast' && this.selectedProductsForecast.length < this.selectedProductList.length)
					return `${this.selectedProductList.length} Product${this.selectedProductList.length > 1 ? 's' : ''} ${semantics} been selected however ${
						this.selectedProductsForecast.length === 0 ? '' : 'only'
					} ${this.selectedProductsForecast.length === 0 ? 'none' : this.selectedProductsForecast.length} ${
						this.selectedProductsForecast.length === 1 ? 'is' : 'are'
					} applicable for Forecast`;
				return false;
			},

			/**
			 * @name selectedProductsKfi
			 * @description Get selected products for kfi request
			 * @return {Array} Selected products
			 */
			selectedProductsKfi() {
				return this.selectedProductList.filter((product) => product?.availableActions?.kfiRequest);
			},

			/**
			 * @name selectedProductsRestricted
			 * @description Get selected products to restrict
			 * @return {Array} Selected products
			 */
			selectedProductsRestricted() {
				return this.selectedProductList.filter(() => {
					// TODO: waiting for backend // Check if product is applicable for restriction
					// return !(product && product.availableActions && product.availableActions.Restricted === false)
					return true;
				});
			},

			/**
			 * @name selectedProductsForecast
			 * @description Get selected products for forecast
			 * @return {Array} Selected products
			 */
			selectedProductsForecast() {
				return this.selectedProductList.filter((product) => product?.availableActions?.createForecast);
			},

			/**
			 * @name createForecastAvailable
			 * @description Disable create forecast if any mandatory payment product is chosen
			 * @return {Array} Selected products
			 */
			createForecastAvailable() {
				const mandatoryPayments = this.selectedProductList.filter((product) => product?.mandatoryPayments);
				return !mandatoryPayments.length;
			},

			/**
			 * @name actionItems
			 * @description Get all action items and if they are disabled or note
			 * @return {Array} Array of available action items
			 */
			actionItems() {
				let items = [];
				if (this.selectedProductsKfi.length) items.push({ key: 'request-kfi', name: 'Request KFI', disabled: !this.kfiRequestAccess });
				if (this.selectedProductsForecast.length)
					items.push({ key: 'create-forecast', name: 'Create Forecast', disabled: !this.createForecastAvailable || this.selectedProductsForecast.length > 2 });
				items.push({ key: 'restrict-products', name: 'Restrict Products', disabled: this.quickQuote });

				return items;
			},

			/**
			 * @name isFactFind
			 * @description Check if result page is opened from a fact find
			 * @return {Boolean} Is fact find session
			 */
			isFactFind() {
				return this.$route.query && !!this.$route.query.factfind;
			},

			/**
			 * @name expiredCredentialProviders
			 * @description Have credentials expired for any providers?
			 * @return {Object}
			 */
			expiredCredentialProviders() {
				return this.providerList.filter((prov) => prov.refreshToken);
			},

			/**
			 * @name showExpiredTokenWarning
			 * @description Should we show the expired token warning display
			 * @return {Boolean}
			 */
			showExpiredTokenWarning() {
				return this.expiredCredentialProviders.length > 0;
			}
		},

		watch: {
			selectedProductsKfi: {
				handler() {
					if (!this.selectedAction && this.kfiRequestAccess && this.selectedProductsKfi.length) this.selectedAction = 'request-kfi';
					else if (!this.selectedAction && this.selectedProductsForecast.length) this.selectedAction = 'create-forecast';
					else if (
						(!this.selectedAction && this.selectedProductsRestricted.length) ||
						(this.selectedAction && !this.selectedProductsKfi.length && !this.selectedProductsForecast.length && this.selectedProductsRestricted.length)
					)
						this.selectedAction = 'restrict-products';
				}
			}
		},

		methods: {
			...mapActions('LifetimeMortgageKfi', ['requestKfi']),

			/**
			 * @name hasSelected
			 * @description Do we have any specific selected items
			 * @return {Boolean} True if button is disabled
			 */
			hasSelected() {
				switch (this.selectedAction) {
					case 'request-kfi':
						return this.selectedProductsKfi.length > 0;
					case 'restrict-products':
						return this.selectedProductsRestricted.length > 0;
					case 'create-forecast':
						return this.selectedProductsForecast.length <= 2;
				}
			},

			/**
			 * @name disabled
			 * @description TODO
			 * @return {Boolean} True if button is disabled
			 */
			disabled() {
				if (this.buttonDisabled) return true;
				return !this.hasSelected();
			},

			/**
			 * @name applySelected
			 * @description What to do with the selected items
			 */
			applySelected() {
				switch (this.selectedAction) {
					case 'request-kfi':
						this.openCheckoutDialog();
						break;
					case 'restrict-products':
						this.$emit('open-restrict-product-dialog', this.selectedProductsRestricted);
						break;
					case 'create-forecast':
						this.$emit(
							'open-borrowing-forecast-dialog',
							this.selectedProductsForecast[0],
							this.selectedProductsForecast[1] ? this.selectedProductsForecast[1] : false,
							false,
							'footer'
						);
						break;
				}
			},

			/**
			 * @name openCheckoutDialog
			 * @description Open request kfi dialog
			 */
			openCheckoutDialog() {
				// Google Analytics event
				window.dataLayer.push({
					event: 'viewCart',
					'gtm.element.dataset.gaId': 'footer'
				});
				this.skipExpiredTokenWarning = false;
				this.$refs.checkout.open();
			},

			/**
			 * @name openProductLoader
			 * @description Open product loader dialog
			 */
			openProductLoader() {
				this.skipExpiredTokenWarning = true;
				this.$emit('open-product-loader-dialog');
			},

			/**
			 * @name confirmRequest
			 * @description Request kfi for select products on confirm
			 */
			async confirmRequest() {
				this.$refs.checkout.close();
				this.submitting = true;
				this.$refs.progress.open();
				this.progressDialog.text = 'Please be patient whilst we prepare the KFI request journey...';

				//Extract only the properties that we require
				const mappedSelectedProductList = this.selectedProductsKfi.map((a) => {
					return (({ id, iterationId }) => ({
						id,
						iterationId
					}))(a);
				});

				const payload = {
					iterationId: this.iterationId,
					processId: this.processId,
					data: {
						products: mappedSelectedProductList,
						action: { key: 'CreateKeyFactsIllustration' }
					}
				};

				this.requestKfi(payload)
					.then(({ data }) => {
						this.$refs.progress.close();
						this.$router.push(`/request/${data.matterId}?processTypeId=${data.processTypeId}`);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name removeSelectedProduct
			 * @description Remove product from select list
			 * @param {Boolean} value
			 * @param {Object} product
			 */
			removeSelectedProduct(product) {
				this.$refs.confirm
					.open('Remove Product', `Are you sure you wish to remove this product from the list?`)
					.then(() => {
						const event = false;
						this.$emit('update-selected-product-list', { product, event });
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .action-chooser {
		max-width: 160px;

		.v-text-field__details {
			display: none;
		}
		.v-input__control .v-input__slot {
			margin-bottom: 0px;
		}
	}

	.products-selected {
		display: inline-block;
		font-size: 0.95rem;
		color: red;
	}
	.message-text {
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
</style>
