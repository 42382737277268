/**
 * @namespace Utils
 * @class UrlTools
 * @exports Utils/Unit
 * @description Util for converting scales
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Unit {
	/**
	 * @static @name filepathToComponentName
	 * @description Converts kg to stone/pounds and vice versa
	 * @param {Array} value [First Value, Second Value]
	 * @param {Object} unit - {from, to}
	 * @return {Object} - {unit: converted measurement} || { stones: 7, lbs: 89 } || { kg: 19 }
	 */
	static convertWeight(value, unit) {
		if (value.length > 2) throw new Error(`Value length exceeds two ${value}`);
		const convertedValue = {};
		switch (`${unit.from}->${unit.to}`) {
			case 'kg->stone': {
				const result = String(parseFloat(parseInt(value) / 6.35).toFixed(2)).split('.');
				// const result = String(parseFloat(parseInt(value.kg) / 6.35).toFixed(2)).split('.');
				convertedValue.stones = parseInt(result[0]);
				convertedValue.lbs = Math.round(parseInt(result[1] * 14) / 100);
				break;
			}
			case 'stone->kg': {
				convertedValue.kg = Math.round(parseInt(value.join('.')) * 6.35);
				// convertedValue.cm = Math.round(parseInt(`${value.stones}.${value.lbs}`) * 30.48);
				break;
			}
			default: {
				throw new Error(`Unknown unit convert weight ${unit.from} to ${unit.to}`);
			}
		}
		return convertedValue;
	}

	/**
	 * @static @name filepathToComponentName
	 * @description Converts cm to feet and vice versa
	 * @param {Array} value [First Value, Second Value]
	 * @param {Object} unit - {from, to}
	 * @return {Object} - {unit: Converted Measurement} || { feet: 7, inches: 89 } || { cm: 19 }
	 */
	static convertHeight(value, unit) {
		if (value.length > 2) throw new Error(`Value length exceeds two ${value}`);
		const convertedValue = {};
		switch (`${unit.from}->${unit.to}`) {
			case 'cm->feet': {
				const result = String(parseFloat(parseInt(value) / 30.48).toFixed(2)).split('.');
				// const result = String(parseFloat(parseInt(value.cm) / 30.48).toFixed(2)).split('.');
				convertedValue.feet = parseInt(result[0]);
				convertedValue.inches = Math.round(parseInt(result[1]));
				break;
			}
			case 'feet->cm': {
				convertedValue.cm = Math.round(parseInt(value.join('.')) * 30.48);
				// convertedValue.cm = Math.round(parseInt(`${value.feet}.${value.inches}`) * 30.48);
				break;
			}
			default: {
				throw new Error(`Unknown unit convert height ${unit.from} to ${unit.to}`);
			}
		}
		return convertedValue;
	}
}
