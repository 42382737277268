<!--
	@name app-reward
	@description Rewards page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Air Rewards
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom max-width="300">
						<template #activator="{ on }">
							<v-btn large text color="white" v-on="on" @click="openSmallPrintDialog">
								<v-icon large>
									mdi-book-information-variant
								</v-icon>
							</v-btn>
						</template>
						<span>The small print - discover the finer details of Air Rewards</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<p class="text-h5 mb-2 font-weight-regular">
						Earn more with <span class="font-weight-bold">Air Rewards</span>
					</p>
					<p class="text-body-1 mb-0">
						Our simplified Air Rewards programme gives your better value for your hard work. You can claim your points at any time, and your total will
						reset every 6 months. You can claim your points as a personal reward or donate your rewards to charity. For any points donated to our chosen
						charities, Air will make an additional 10% donation.
					</p>
				</div>

				<!-- Tabs -->
				<common-base-tab-view :tabs="tabs" router-view @redeem="openRedeemDialog" />
			</v-col>

			<v-col class="py-0" cols="12" md="4">
				<app-reward-report />
				<app-reward-charity class="mt-6" />
			</v-col>
		</v-row>

		<app-reward-dialog-smallprint ref="smallprint" />
		<app-reward-dialog-redeem ref="redeemPoints" @init="init" />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import AppRewardDialogSmallprint from '@/component/app/reward/dialog/smallprint';
	import AppRewardDialogRedeem from '@/component/app/reward/dialog/redeem';
	import AppRewardCharity from '@/component/app/reward/charity';
	import AppRewardReport from '@/component/app/reward/report';

	export default {
		name: 'app-reward',

		components: {
			CommonBaseTabView,
			AppRewardDialogSmallprint,
			AppRewardDialogRedeem,
			AppRewardCharity,
			AppRewardReport
		},

		provide() {
			return {
				$rewardsBalance: () => this.user.rewardsBalance,
				$rewardTransactionList: () => this.rewardTransactionList,
				$rewardDebitList: () => this.rewardDebitList,
				$rewardCreditList: () => this.rewardCreditList
			};
		},

		data() {
			return {
				rewardTransactionList: null,
				rewardDebitList: null,
				rewardCreditList: null,
				tabs: [
					{ title: 'Summary', alerts: 0, name: 'airRewards.summary', path: '/rewards' },
					{ title: 'History', alerts: 0, name: 'airRewards.history', path: '/rewards/history' },
					{ title: 'How to earn Air Rewards', alerts: 0, name: 'airRewards.earn', path: '/rewards/earn' }
				]
			};
		},

		computed: {
			...mapState('Account', ['user'])
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AppReward', ['loadRewardTransactionList', 'loadRewardDebitList', 'loadRewardCreditList']),

			/**
			 * @name init
			 * @description TODO
			 */
			init() {
				this.setRewardTransactionList();
				this.setRewardDebitList();
				this.setRewardCreditList();
			},

			/**
			 * @name setReward
			 * @description TODO
			 */
			async setRewardTransactionList() {
				this.rewardTransactionList = null;
				this.rewardTransactionList = await this.loadRewardTransactionList({ order: { property: 'created', direction: 'desc' }});
			},

			/**
			 * @name setRewardDebitList
			 * @description TODO
			 */
			async setRewardDebitList() {
				this.rewardDebitList = null;
				this.rewardDebitList = await this.loadRewardDebitList();
			},

			/**
			 * @name setRewardCreditList
			 * @description TODO
			 */
			async setRewardCreditList() {
				this.rewardCreditList = null;
				this.rewardCreditList = await this.loadRewardCreditList({ where: { visible: { condition: 'IS NOT', value: null } }, order: { property: 'order' } });
			},

			/**
			 * @name openSmallPrintDialog
			 * @description TODO
			 */
			openSmallPrintDialog() {
				this.$refs.smallprint.open();
			},

			/**
			 * @name openRedeemDialog
			 * @description TODO
			 */
			openRedeemDialog(type, id) {
				this.$refs.redeemPoints.open({ type, id });
			}
		}
	};
</script>

<style lang="scss" scoped>
	.image-content {
		font-size: 0;
	}

	.brand-logo {
		max-width: 130px;
	}
</style>
