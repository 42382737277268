import { $http } from '@/utils';
import CryptoJS from 'crypto-js';

/**
 * @namespace Service
 * @class Authenticate
 * @exports Service/Account/Authenticate
 * @description API Authenticate Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Authenticate {
	/**
	 * @static @name post
	 * @description Performs a login action to get a JWT auth token
	 * @param {Object} payload - The object contains the payload such as { email: ..., password: ...}
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('account/authenticate', {
			identity: payload.username,
			identityType: !payload.impersonate ? 'username' : undefined,
			password: payload.password,
			code: payload.code,
			organisationID: payload.organisationID,
			impersonate: payload.impersonate,
			scope: 'advisor',
			token: CryptoJS.MD5(process.env.VUE_APP_AUTH_TOKEN).toString()
		});
	}

	/**
	 * @static @name get
	 * @description Get the logged in user via JWT auth token
	 * @returns {Object} Promise a http response
	 */
	static get() {
		return $http.get('account/authenticate');
	}
}
