import Enquiries from '@/component/app/enquiry';
import EnquiryDetails from '@/component/app/enquiry/enquiry-details';
import EnquiryList from '@/component/app/enquiry/enquiry-list';
import CreateNewEnquiry from '@/component/app/enquiry/new-enquiry';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		path: '/enquiries',
		component: Enquiries,
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		children: [
			{
				name: 'enquiries.list',
				path: '',
				meta: { layout: 'default-authed' },
				component: EnquiryList
			},
			{
				name: 'enquiries.createNewEnquiry',
				path: 'create-new-enquiry',
				meta: { layout: 'default-authed' },
				component: CreateNewEnquiry
			}
		]
	},
	{ path: '/enquiry/:id', name: 'enquiryDetails', meta: { layout: 'default-authed' }, component: EnquiryDetails }
];
