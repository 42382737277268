<!--
	@name app-client-dashboard-property
	@description Client properties
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section flex-height>
			<template #header>
				{{ (items && items.length) || '' }} {{ items && items.length === 1 ? 'Property' : 'Properties' }}
			</template>

			<template #body>
				<v-carousel
					v-if="items && items.length"
					v-model="carouselModel"
					:show-arrows="false"
					:cycle="cycle"
					interval="8000"
					height="100%"
					@change="carouselChangeHandler"
					@mouseover.native="cycle = false"
					@mouseleave.native="cycle = true"
				>
					<v-carousel-item v-for="(item, index) in items" :key="index" style="position: relative">
						<div :id="`map-${index}`" style="position: absolute" />
						<v-card
							class="mx-auto pa-3 pb-15 text-center d-flex flex-column"
							light
							outlined
							style="height: 100%; background-color: rgba(255, 255, 255, 0.6)"
						>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="title mb-0" style="text-shadow: 2px 2px white;">
										{{ propertyIdent(item.data.location) }}
									</v-list-item-title>
									<v-list-item-action class="justify-center mx-0 mb-0">
										<v-btn small color="secondary" :disabled="assetId === item.id" @click="loadProperty(item)">
											click to Open Property
										</v-btn>
									</v-list-item-action>
								</v-list-item-content>
							</v-list-item>
						</v-card>
					</v-carousel-item>
				</v-carousel>

				<div v-else-if="items && items.length === 0" class="text-left">
					<p class="text-body-1 mb-4">
						Let's add a Property for this Client.
					</p>
					<v-btn small color="secondary" @click="$emit('add-property')">
						Add Property
					</v-btn>
				</div>

				<v-skeleton-loader v-else type="image" />
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { googleMap } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-client-dashboard-property',

		components: { CommonStructureSection },

		props: {
			clientId: { type: String, required: true },
			assetId: { type: String, required: false, default: null },
			items: { type: Array, default: null }
		},

		data() {
			return {
				carouselModel: 0,
				cycle: true
			};
		},

		mounted() {
			if (this.items && this.items.length) this.drawMap(0);
		},

		methods: {
			loadProperty(property) {
				this.$emit('load-property', property);
			},

			propertyIdent(location) {
				const { address1, address2, townCity, postcode } = location;
				return [address1, address2, townCity, postcode].filter(Boolean).join(', ');
			},

			/**
			 * @name carouselChangeHandler
			 * @description Handler for carousel item changed event
			 */
			carouselChangeHandler() {
				if (!document.querySelector(`#map-${this.carouselModel} div`)) setTimeout(() => this.drawMap(this.carouselModel), 100);
			},

			/**
			 * @name propertyDashboardPath
			 * @description Path for property dashboard
			 */
			propertyDashboardPath(item) {
				return `/client/${this.clientId}/property/${item.id}`;
			},

			/**
			 * @name drawMap
			 * @description Draw the map to the element
			 */
			drawMap(index) {
				const gm = new googleMap();
				gm.then(() => {
					let styledMapType = new window.google.maps.StyledMapType(
						[
							{ elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
							{ elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
							{ elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
							{
								featureType: 'administrative',
								elementType: 'geometry.stroke',
								stylers: [{ color: '#c9b2a6' }]
							},
							{
								featureType: 'administrative.land_parcel',
								elementType: 'geometry.stroke',
								stylers: [{ color: '#dcd2be' }]
							},
							{
								featureType: 'administrative.land_parcel',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#ae9e90' }]
							},
							{
								featureType: 'landscape.natural',
								elementType: 'geometry',
								stylers: [{ color: '#dfd2ae' }]
							},
							{
								featureType: 'poi',
								elementType: 'geometry',
								stylers: [{ color: '#dfd2ae' }]
							},
							{
								featureType: 'poi',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#93817c' }]
							},
							{
								featureType: 'poi.park',
								elementType: 'geometry.fill',
								stylers: [{ color: '#a5b076' }]
							},
							{
								featureType: 'poi.park',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#447530' }]
							},
							{
								featureType: 'road',
								elementType: 'geometry',
								stylers: [{ color: '#f5f1e6' }]
							},
							{
								featureType: 'road.arterial',
								elementType: 'geometry',
								stylers: [{ color: '#fdfcf8' }]
							},
							{
								featureType: 'road.highway',
								elementType: 'geometry',
								stylers: [{ color: '#f8c967' }]
							},
							{
								featureType: 'road.highway',
								elementType: 'geometry.stroke',
								stylers: [{ color: '#e9bc62' }]
							},
							{
								featureType: 'road.highway.controlled_access',
								elementType: 'geometry',
								stylers: [{ color: '#e98d58' }]
							},
							{
								featureType: 'road.highway.controlled_access',
								elementType: 'geometry.stroke',
								stylers: [{ color: '#db8555' }]
							},
							{
								featureType: 'road.local',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#806b63' }]
							},
							{
								featureType: 'transit.line',
								elementType: 'geometry',
								stylers: [{ color: '#dfd2ae' }]
							},
							{
								featureType: 'transit.line',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#8f7d77' }]
							},
							{
								featureType: 'transit.line',
								elementType: 'labels.text.stroke',
								stylers: [{ color: '#ebe3cd' }]
							},
							{
								featureType: 'transit.station',
								elementType: 'geometry',
								stylers: [{ color: '#dfd2ae' }]
							},
							{
								featureType: 'water',
								elementType: 'geometry.fill',
								stylers: [{ color: '#b9d3c2' }]
							},
							{
								featureType: 'water',
								elementType: 'labels.text.fill',
								stylers: [{ color: '#92998d' }]
							}
						],
						{ name: 'Styled Map' }
					);

					let target = document.getElementById(`map-${index}`);
					let latLng = { lat: 51.8623462, lng: -2.2493464 };
					if (this.items[index].data.location.latLng && Object.values(this.items[index].data.location.latLng).every((value) => value)) {
						latLng = this.items[index].data.location.latLng;
					}
					let map = new window.google.maps.Map(target, {
						center: latLng,
						zoom: 15,
						disableDefaultUI: true
					});

					map.mapTypes.set('greyscale_map', styledMapType);
					map.setMapTypeId('greyscale_map');
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	div[id^='map'] {
		width: 100%;
		height: 100%;
	}
</style>

<style scoped></style>
