<!--
	@name common-base-image-tooltip
	@description Show a small image with tooltip
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<v-tooltip bottom :component="$options.name">
		<template #activator="{ on }">
			<div :class="[clss, 'image-container']" :style="{ width: `${width}px` }">
				<v-fade-transition>
					<v-skeleton-loader v-show="!loaded" :class="[{ center }, 'image-skeleton']" type="image" :width="width" :height="height" />
				</v-fade-transition>

				<v-fade-transition>
					<img :src="src" :width="width" :style="{ 'max-height': `${maxHeight}px`, 'max-width': `${maxWidth}px` }" v-on="on" @load="onLoad" />
				</v-fade-transition>
			</div>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
	export default {
		name: 'common-base-image-tooltip',

		props: {
			clss: { type: String, required: false, default: '' },
			src: { type: String, required: true },
			tooltip: { type: String, required: false, default: null },
			height: { type: [ Number, String ], default: 40 },
			width: { type: [ Number, String ], default: 60 },
			maxWidth: { type: Number, required: true },
			maxHeight: { type: Number, required: true },
			center: { type: Boolean, default: false }
		},

		data() {
			return {
				loaded: false
			};
		},

		methods: {
			/**
			 * @name onLoad
			 * @description Sets loaded true when loaded
			 */
			onLoad() {
				this.loaded = true;
			}
		}
	};
</script>

<style scoped>
	.image-container {
		line-height: 0;
	}
	.image-skeleton {
		position: absolute;
	}
	.center {
		top: 50%;
		transform: translateY(-50%);
	}
</style>
