import { SystemVersion } from '@/service';
import { StringTools } from '@/utils';

export default class Version {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			versionCurrent: process.env.VUE_APP_VERSION ?? '0.0.0',
			versionCheck: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			versionUpgrade: (state) => StringTools.versionToNumber(state.versionCheck) > StringTools.versionToNumber(state.versionCurrent)
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setVersion
			 * @description Set version number
			 * @param {Object} state
			 * @param {Object} data
			 */
			setVersionCheck(state, data) {
				state.versionCheck = data.version.ui.number;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @name getVersion
			 * @description Get version from version log
			 * @param {function} commit
			 */
			async getVersionCheck({ commit }) {
				return SystemVersion.getVersion().then((request) => commit('setVersionCheck', request?.data));
			}
		};
	}
}
