<!--
	@name app-source-result-dialog-product-cost
	@description Source result dialog showing showing product cost
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Product cost
		</template>
		<template #body>
			<v-row v-if="product && product.hasOwnProperty('payments')">
				<v-col :cols="displayHints ? 8 : 12" class="py-0">
					<v-row v-if="hasContractedPayments">
						<v-col class="py-0">
							<p class="text-body-2 font-weight-bold mb-1">
								Contracted Payments
							</p>
							<ul class="text-body-2 mb-0">
								<li v-for="(term, index) in product.payments.contract.terms" :key="index">
									From month {{ term.start }} to month {{ term.end }} a contracted payment of £{{ term.amount | numFormat('0,00.[00]') }} is required
								</li>
							</ul>
						</v-col>
					</v-row>
					<v-row v-if="displayVoluntaryPayments">
						<v-col class="py-0">
							<p class="text-body-2 font-weight-bold mb-1">
								Voluntary Payments
							</p>
							<ul class="text-body-2 mb-0">
								<template v-if="product.payments.voluntary.terms">
									<li v-for="(term, index) in product.payments.voluntary.terms" :key="index">
										From month {{ term.start }} to month {{ term.end }} a voluntary payment of £{{ term.amount | numFormat('0,00.[00]') }} has been assumed
									</li>
								</template>
								<li v-if="hasContractedPayments && !product.voluntaryPaymentsAllowedAlongsideContractedPayments">
									Voluntary payments are not permitted whilst contracted payments are being made
								</li>
								<li v-if="displayMaximumAnnuaVoluntaryPaymentMessage">
									Annual voluntary payments are limited to {{ product.payments.voluntary.maxAnnualPaymentPercentage }}% of the initial advance
								</li>
								<li v-if="displayMinimumVoluntaryPaymentMessage">
									Voluntary payments must exceed the minimum amount of £{{ product.payments.voluntary.voluntaryPaymentMinimumAmount }}
								</li>
							</ul>
						</v-col>
					</v-row>
					<v-row v-if="displayPaymentTerm">
						<v-col class="py-0">
							<p class="text-body-2 font-weight-bold mb-1">
								Payment Term
							</p>
							<ul class="text-body-2 mb-0">
								<li v-if="product.monthlyRepaymentsTermAfterWhichRateIsSecured">
									Discounted rate is locked for full term after {{ product.monthlyRepaymentsTermAfterWhichRateIsSecured }} years
								</li>
								<li v-if="product.monthlyRepaymentsTermAfterWhichRateIsSecured">
									If monthly payments cease before {{ product.monthlyRepaymentsTermAfterWhichRateIsSecured }} years, the rate will increase to
									{{ product.monthlyRepaymentsFallbackInterestRate }}%
								</li>
								<li v-else-if="product.monthlyRepaymentsFallbackInterestRate">
									If monthly payments cease at any time, the rate will increase to {{ product.monthlyRepaymentsFallbackInterestRate }}% MER
								</li>
							</ul>
						</v-col>
					</v-row>
					<v-row v-if="earlyRepayment || product.hasOwnProperty('outstandingBalance')">
						<v-col class="py-0">
							<p class="text-body-2 font-weight-bold mb-1">
								Outstanding Balance
							</p>
							<ul class="text-body-2 mb-0">
								<li v-if="earlyRepayment">
									Based on the above, the loan would be repaid in {{ termDisplay }}.
								</li>
								<li v-else>
									Based on the above, the outstanding balance will be £{{ product.outstandingBalance | numFormat('0,0.[00]') }} at the end of the term.
								</li>
							</ul>
						</v-col>
					</v-row>
					<v-row class="mt-4">
						<v-col class="py-0">
							<p class="mb-0 text-caption plain">
								<em>Please refer to the product criteria to review if Early Repayment Charges may be added. If you are unsure, check the “Maximise affordability”
									filter is off to only display contracted payment required for that product</em>
							</p>
						</v-col>
					</v-row>
				</v-col>
				<v-col v-if="displayHints" cols="4" class="py-0">
					<div class="pl-5 h-100" style="border-left: 1px solid rgba(0, 0, 0, 0.12)">
						<v-row v-if="reposessionRisk">
							<v-col class="shrink py-0">
								<v-icon color="indigo lighten-1" style="position: relative; left: 1px">
									mdi-home-alert
								</v-icon>
							</v-col>
							<v-col class="grow pl-0 py-0 text-caption plain">
								This product carries a risk of repossession if contracted payments cease
							</v-col>
						</v-row>
						<v-row v-if="earlyRepayment">
							<v-col class="shrink py-0">
								<v-icon color="success">
									mdi-home-clock
								</v-icon>
							</v-col>
							<v-col class="grow pl-0 py-0 text-caption plain">
								The payment term for this product subceeds the comparison term
							</v-col>
						</v-row>
						<v-row v-if="discountedRateNotSecured && !earlyRepayment">
							<v-col class="shrink py-0">
								<v-icon color="error">
									mdi-lock-off
								</v-icon>
							</v-col>
							<v-col class="grow pl-0 py-0 text-caption plain">
								Your payment term does not meet the requirements to lock in the discounted rate
							</v-col>
						</v-row>
						<v-row v-if="rateIncrease">
							<v-col class="shrink py-0">
								<v-icon color="error">
									mdi-arrow-up-bold-circle
								</v-icon>
							</v-col>
							<v-col class="grow pl-0 py-0 text-caption plain">
								A rate increase will come into effect when monthly payments cease
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
			<div v-if="product?.availableActions?.createForecast">
				<v-divider class="mt-6 mb-4" />
				<p class="text-body-2 font-weight-bold mb-3">
					Additional options
				</p>
				<div>
					<v-btn color="primary" small @click="openBorrowingForecast(product)">
						Borrowing Forecast
					</v-btn>
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import PaymentsHelper from '@/utils/PaymentsHelper';

	export default {
		name: 'app-source-result-dialog-product-cost',

		components: { CommonDialog },

		data() {
			return {
				product: null
			};
		},

		computed: {
			/**
			 * @name hasContractedPayments
			 * @description TODO
			 */
			hasContractedPayments() {
				return this.product?.payments?.contract?.terms?.length;
			},

			/**
			 * @name hasVoluntaryPayments
			 * @description TODO
			 */
			hasVoluntaryPayments() {
				return this.product?.payments?.voluntary?.terms?.length;
			},

			/**
			 * @name displayVoluntaryPayments
			 * @description TODO
			 */
			displayVoluntaryPayments() {
				if (this.hasContractedPayments && !this.product?.voluntaryPaymentsAllowedAlongsideContractedPayments) return true;
				if (this.displayMinimumVoluntaryPaymentMessage) return true;
				if (this.hasVoluntaryPayments) return true;
				return false;
			},

			/**
			 * @name displayMaximumAnnuaVoluntaryPaymentMessage
			 * @description TODO
			 */
			displayMaximumAnnuaVoluntaryPaymentMessage() {
				if (this.hasVoluntaryPayments && this.product?.payments?.voluntary?.maxAnnualPaymentPercentage) return true;
				return false;
			},

			/**
			 * @name displayMinimumVoluntaryPaymentMessage
			 * @description TODO
			 */
			displayMinimumVoluntaryPaymentMessage() {
				if (!this.product?.payments?.voluntary?.voluntaryPaymentMinimumAmount) return false;
				// Display if no voluntary payments, as this may be because voluntaryPaymentMinimumAmount wasn't met
				if (!this.hasVoluntaryPayments) return true;
				// If payments are being made, only return if any voluntary payment is less than voluntaryPaymentMinimumAmount
				if (this.hasVoluntaryPayments) {
					let minimumAmount = this.product.payments.voluntary.terms.reduce((prev, current) => (prev && prev.amount < current.amount ? prev.amount : current.amount), {
						amount: 0
					});
					return minimumAmount < this.product?.payments?.voluntary?.voluntaryPaymentMinimumAmount;
				}
				return false;
			},

			/**
			 * @name displayHints
			 * @description TODO
			 */
			displayHints() {
				return this.reposessionRisk || this.earlyRepayment || this.discountedRateNotSecured || this.rateIncrease;
			},

			/**
			 * @name reposessionRisk
			 * @description TODO
			 */
			reposessionRisk() {
				const loanPaymentOptions = [
					'90649bf3-09be-45c4-aaf9-6266dbb4bfc7', //Full Interest Serviced Mortgage
					'4a38fc40-e285-4019-a486-03b623a65066', //Capital and Interest Mortgage
					'5f018d6a-4f09-45de-be42-3c140ad8317a' //Mandatory Payments
				];
				return loanPaymentOptions.includes(this.product?.loanPaymentTypeId);
			},

			/**
			 * @name earlyRepayment
			 * @description TODO
			 */
			earlyRepayment() {
				return this.product?.payments?.earlyRepayment;
			},

			/**
			 * @name discountedRateNotSecured
			 * @description TODO
			 */
			discountedRateNotSecured() {
				if (!this.product?.payments?.term) return false;
				if (!this.product?.monthlyRepaymentsDiscount) return false;
				if (!this.product?.monthlyRepaymentsTermAfterWhichRateIsSecured) return false;
				return this.product?.payments?.term < this.product?.monthlyRepaymentsTermAfterWhichRateIsSecured;
			},

			/**
			 * @name rateIncrease
			 * @description TODO
			 */
			rateIncrease() {
				if (this.discountedRateNotSecured) return false;
				return this.product?.payments?.contract?.rateIncrease;
			},

			/**
			 * @name displayPaymentTerm
			 * @description TODO
			 */
			displayPaymentTerm() {
				if (this.product.monthlyRepaymentsTermAfterWhichRateIsSecured) return true;
				if (this.product.monthlyRepaymentsFallbackInterestRate) return true;
				return false;
			},

			/**
			 * @name termDisplay
			 * @description TODO
			 */
			termDisplay() {
				if (!this.product?.payments?.term) return null;
				let term = PaymentsHelper.covertDecimalYearsToYearsMonths(this.product?.payments?.term);
				if (!term) return '';
				let string = `${term.years} year${term.years != 1 ? 's' : ''}`;
				if (Number(term.months)) string += ` ${term.months} month${term.months != 1 ? 's' : ''}`;
				return string;
			}
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open(product) {
				this.product = product;
				this.$refs.dialog.open();
			},

			/**
			 * @name openBorrowingForecast
			 * @description TODO
			 * @param {TODO} product TODO
			 */
			openBorrowingForecast(product) {
				this.$emit('open-borrowing-forecast-dialog', product, false, false, 'product-cost-dialog');
			}
		}
	};
</script>
