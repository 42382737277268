import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Organisation
 * @exports Service/Account/Organisation
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Organisation {
	/**
	 * @static @name get
	 * @description Get Organisation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`account/organisation/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new Organisation resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/organisation`, payload);
	}

	/**
	 * @static @name list
	 * @description Get all organisations with details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('account/organisation/list', payload);
	}

	/**
	 * @static @name modify
	 * @description Update some Organisation details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`account/organisation/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete Organisation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/organisation/${id}`);
	}

	/**
	 * @static @name modifyFcaNumberRequest
	 * @description Modify the FCA number request for an organisation
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static modifyFcaNumberRequest(payload) {
		return $http.post(`account/organisation/modify-fca-number-request`, payload);
	}

	/**
	 * @static @name fcaCheck
	 * @description Find if FCA number is available
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static fcaCheck(payload) {
		return $http.post(`account/organisation/fca-check`, payload);
	}
}
