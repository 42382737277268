<!--
	@name app-kfi-request-product-list
	@description KFI Submission Products
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="products.length">
			<v-list-item v-for="(product, index) in products" :key="product.id" class="blue-grey lighten-5" :class="{ 'mt-1': index !== 0 }">
				<v-list-item-icon class="align-self-center mt-2 mb-2">
					<common-base-image-tooltip
						:src="logoName(product.data.provider.logo) | urlize('organisation')"
						:tooltip="product.data.provider.name"
						:max-width="90"
						:max-height="60"
						center
					/>
				</v-list-item-icon>

				<v-list-item-content>
					<v-tooltip left>
						<template #activator="{ on }">
							<v-list-item-title class="ma-0 text-body-2" s v-on="on" v-text="product.data.name" />
						</template>
						<span>{{ product.data.name }}</span>
					</v-tooltip>
					<v-list-item-subtitle v-if="product.data.submitted" class="mt-1" v-text="getSubmittedDisplayValue(product.data.submitted)" />
				</v-list-item-content>

				<v-list-item-icon class="align-self-center">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn v-if="linkButton" v-show="!isLoading[product.id]" icon v-on="on" @click="openPage(product)">
								<v-icon color="primary">
									mdi-link-box-variant
								</v-icon>
							</v-btn>
						</template>
						<span>Request KFI</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn v-if="deleteButton && products.length" icon :loading="isLoading[product.id]" v-on="on" @click="deleteProductHandler(product)">
								<v-icon color="error">
									mdi-delete
								</v-icon>
							</v-btn>
						</template>
						<span>Remove Product</span>
					</v-tooltip>
				</v-list-item-icon>
			</v-list-item>
		</div>
		<div v-else>
			<v-card v-for="key in 3" :key="key" class="mb-2">
				<v-skeleton-loader type="list-item-two-line" />
			</v-card>
		</div>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import CommonBaseImageTooltip from '@/component/common/base/image-tooltip';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-kfi-request-product-list',

		components: {
			CommonBaseImageTooltip,
			CommonDialogConfirm
		},

		props: {
			deleteButton: { type: Boolean, required: false, default: true },
			linkButton: { type: Boolean, required: false, default: false },
			products: { type: Array, required: true }
		},

		data() {
			return {
				isLoading: {}
			};
		},

		methods: {
			...mapActions('LifetimeMortgageKfi', ['submitKfi', 'deleteProduct']),

			/**
			 * @name deleteProduct
			 * @description Remove product from KFI submissions after confirmation
			 * @param {Object} productObj The product object
			 */
			deleteProductHandler(product) {
				let msg = 'Are you sure you wish to remove this product from your submission?';
				this.$refs.confirm.open('Remove Product', msg).then(async() => {
					this.$set(this.isLoading, product.id, true);

					const payload = {
						processId: product.processId,
						iterationId: product.id,
						data: {
							action: {
								key: 'DeleteKeyFactsIllustrationProduct'
							}
						}
					};

					this.deleteProduct(payload)
						.then(() => {
							this.$emit('remove-product', product);
							ElementTools.fireNotification(this.$el, 'success', `Product "${product.data.name} by ${product.data.provider.name}" has been successfully removed`);
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove product'))
						.finally(() => this.$set(this.isLoading, product.id, false));
				});
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			},

			/**
			 * @name getSubmittedDisplayValue
			 * @description Format submitted date for display
			 * @param date
			 * @returns {string}
			 */
			getSubmittedDisplayValue(date) {
				return 'Submitted: ' + this.$moment(String(date)).format('MMM. D, YYYY [at] h:mm A z');
			},

			openPage(product) {
				let msg = `Please confirm that you wish to request a KFI from ${product.data.provider.name} for the ${product.data.name} product? WARNING - This will divert you to provider website where you will be asked to enter all client and property information again to request your KFI.`;
				this.$refs.confirm
					.open('Request KFI', msg, { width: 800 })
					.then(() => {
						this.$set(this.isLoading, product.id, true);

						const payload = {
							processId: product.processId,
							iterationId: product.id,
							data: {
								action: {
									key: 'SubmitKeyFactsIllustration'
								}
							}
						};

						this.submitKfi(payload)
							.then((response) => {
								const product = response.data;
								this.$emit('update-product', product);
								new PopupWindow().open(product.data.keyFactsIllustration.url, '_blank');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
							.finally(() => this.$set(this.isLoading, product.id, false));
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.provider-image-container {
		height: 40px;
	}
</style>
