import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Completion
 * @exports Service/App/Completion
 * @description API Completion Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Completion {
	/**
	 * @static @name list
	 * @description Get all completions
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	 static list(payload) {
		return $http.post('completion/list', payload);
	}

	/**
	 * @static @name report
	 * @description Get all completions for report
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static report(payload) {
		return $http.post('completion/report/list', payload);
	}
}
