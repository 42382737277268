/**
 * @namespace Utils
 * @class UrlTools
 * @exports Utils/UrlTools
 * @description Util for manipulating URL strings
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UrlTools {
	/**
	 * @static @name filepathToComponentName
	 * @description TODO
	 * @return {String}
	 */
	static providerImageUrlFromId(providerId, config) {
		return !config.urls || !config.urls.logos || !config.urls.logos.provider ? '' : config.urls.logos.provider.replace('${id}', providerId);
	}
}
