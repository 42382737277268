<!--
	@name app-layout-default
	@description Front page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<!-- header -->
		<app-layout-air-later-life-header />

		<!-- content -->
		<v-main :style="{ 'padding-top': $vuetify.breakpoint.smAndDown ? '100px' : '140px' }">
			<app />
		</v-main>

		<!-- footer -->
		<app-layout-air-later-life-footer />
	</v-app>
</template>

<script>
	import App from '@/component/app';
	import AppLayoutAirLaterLifeHeader from './header';
	import AppLayoutAirLaterLifeFooter from './footer';

	export default {
		name: 'app-layout-default',

		components: {
			App,
			AppLayoutAirLaterLifeHeader,
			AppLayoutAirLaterLifeFooter
		}
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		background: #ffffff !important;

		::v-deep .v-application--wrap {
			position: relative; z-index: 10;
		}
	}
</style>
