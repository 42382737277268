<!--
	@name app-source-result-dialog-query-builder
	@description Query builder dialog
	@date 2020/08/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Restrict Products
		</template>
		<template #header-action>
			<v-tooltip bottom>
				<template #activator="{ on }">
					<v-btn class="d-none" icon color="white" v-on="on" @click="displayInfo = !displayInfo">
						<v-icon>mdi-frequently-asked-questions</v-icon>
					</v-btn>
				</template>
				<span>Help</span>
			</v-tooltip>
		</template>
		<template #body>
			<v-sheet v-show="displayInfo">
				<v-btn icon class="float-left mr-3 mb-2" @click="displayInfo = false">
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
				<p class="text-body-2 mb-3">
					This powerful feature allows you to <span class="font-weight-bold">restrict products in bulk</span> based on a set of conditions.
				</p>
			</v-sheet>
			<v-sheet v-show="!displayInfo">
				<p class="text-body-2 mb-0">
					This powerful feature allows you to <span class="font-weight-bold">restrict products in bulk</span> based on a set of conditions,
				</p>
				<div class="mt-4">
					<validation-observer ref="observer">
						<v-form @submit.prevent="submit">
							<div v-for="(filter, index) in filters" :key="index" class="d-flex align-center py-2">
								<div class="flex-grow-0 mr-2">
									<div class="d-flex align-center">
										<v-btn-toggle v-model="filter.expression" :disabled="!index" mandatory small @change="expressionChange">
											<v-tooltip bottom max-width="250">
												<template #activator="{ on }">
													<v-btn :disabled="!index" small text value="and" v-on="on">
														and
													</v-btn>
												</template>
												<span> Use AND to narrow your search to a single product range </span>
											</v-tooltip>
											<v-tooltip bottom max-width="250">
												<template #activator="{ on }">
													<v-btn :disabled="!index" small text value="or" v-on="on">
														or
													</v-btn>
												</template>
												<span> Use OR to broaden your search to multiple product ranges </span>
											</v-tooltip>
										</v-btn-toggle>
									</div>
								</div>
								<div class="flex-grow-1 mx-2" style="flex-basis: 25%">
									<v-select value="name" disabled dense solo hide-details :items="columns" label="Property" />
								</div>
								<div class="flex-grow-1 mx-2" style="flex-basis: 25%">
									<validation-provider v-slot="{ errors }" name="Condition" rules="required">
										<v-select
											v-model="filters[index].condition"
											dense
											solo
											hide-details
											:error-messages="errors"
											:items="conditions"
											return-object
											label="Condition"
										/>
									</validation-provider>
								</div>
								<div class="flex-grow-1 mx-2">
									<validation-provider v-slot="{ errors }" name="Condition" rules="required">
										<v-text-field v-model="filters[index].value" :error-messages="errors" autocomplete="new-value" label="Value" dense solo hide-details />
									</validation-provider>
								</div>
								<div class="flex-grow-0 ml-2">
									<div class="d-flex align-center">
										<v-tooltip v-if="!index" left>
											<template #activator="{ on }">
												<v-btn :disabled="filters.length > 1 || !activeFilters.length" color="error" icon v-on="on" @click="clearFilters">
													<v-icon>mdi-eraser</v-icon>
												</v-btn>
											</template>
											<span>Clear</span>
										</v-tooltip>
										<v-tooltip v-else left>
											<template #activator="{ on }">
												<v-btn color="error" icon v-on="on" @click="removeQuery(index)">
													<v-icon>mdi-delete-outline</v-icon>
												</v-btn>
											</template>
											<span>Remove condition</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<p v-if="activeFilters.length" class="text-body-2 mt-5 mb-0">
								<span class="text-uppercase blue--text">RESTRICT</span> all products <span class="text-uppercase blue--text">WHERE</span>
								<span v-for="(filter, index) in activeFilters" :key="index" class="">
									<span class="text-uppercase blue--text">{{ filter.expression }}</span>
									<span class="font-weight-bold"> {{ filter.property.text }} {{ filter.condition?.text }} "{{ filter.value }}" </span>
								</span>
							</p>
							<div v-else class="mt-5">
								<p class="text-body-2 font-italic d-inline-block mb-0">
									Your search query will appear here once you've added your first condition
								</p>
							</div>
							<div class="d-flex justify-center mt-5">
								<v-btn class="mr-4" dark color="success" @click="addQuery(false)">
									add condition
								</v-btn>
								<v-btn color="primary" type="submit">
									confirm
								</v-btn>
							</div>
						</v-form>
					</validation-observer>
				</div>
			</v-sheet>
		</template>
	</common-dialog>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/component/common/dialog';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-source-result-dialog-query-builder',

		components: { CommonDialog, ValidationObserver, ValidationProvider },

		props: {
			queryModel: { type: Array, default: () => [] }
		},

		data() {
			return {
				displayInfo: false,
				filters: []
			};
		},
		computed: {
			columns() {
				return [{ text: 'Product Name', value: 'name' }];
			},

			conditions() {
				return [
					{ text: 'contains', value: 'includes' },
					{ text: 'does not contain', value: 'notIncludes' },
					{ divider: true },
					{ header: 'Coming soon' },
					{ text: 'is equal to', value: 'equal', disabled: true },
					{ text: 'is not equal to', value: 'notEqual', disabled: true }
				];
			},

			activeFilters() {
				return this.filters.filter((filter) => filter.condition !== null && filter.value !== null);
			}
		},

		methods: {
			open(queryModel) {
				this.$refs.dialog.open();
				this.filters = cloneDeep(queryModel);
				if (this.filters.length === 0) this.addQuery(true);
			},

			addQuery(isFirstItem = false) {
				// get second condition so we can clone the expression for the new
				let condition = this.filters[1];
				let filterData = {
					property: { text: 'Product Name', value: 'name' },
					expression: condition?.expression || 'and',
					condition: null,
					value: null
				};

				if (isFirstItem) delete filterData.expression;

				this.filters.push(filterData);
			},

			clearFilters() {
				this.filters = [];
				this.addQuery(true);
			},

			removeQuery(filterIndex) {
				this.filters = this.filters.filter((filter, index) => index !== filterIndex);
			},

			setQuery(filters) {
				let transformed = filters.map((filter) => ({
					property: filter.property,
					expression: filter.expression,
					condition: filter.condition,
					value: filter.value
				}));

				this.filters = cloneDeep(transformed);

				return transformed;
			},

			async submit() {
				const isValid = await this.$refs.observer.validate();
				if (!isValid && (this.filters[0].condition || this.filters[0].value)) return;
				this.$emit('submit', cloneDeep(this.activeFilters));
				this.$refs.dialog.close();
			},

			expressionChange(val) {
				this.filters = this.filters.map((filter) => {
					if (filter.expression) filter.expression = val;
					return filter;
				});
			}
		}
	};
</script>
