<!--
	@name app-summer-summer202401-banner
	@description
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="d-none d-md-block" :component="$options.name">
		<div
			style="
				position: relative;
				overflow: hidden;
				z-index: 1;
				background-color: rgb(180, 51, 13);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: cover;
			"
		>
			<div
				style="
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.9;
					background-color: rgb(180, 51, 13);
					background-position: left 20px, left bottom 20px, 50% 50%;
					background-size: auto, auto, contain;
					background-repeat: repeat-x, repeat-x, repeat;
					background-blend-mode: hard-light, hard-light, lighten;
				"
				:style="
					`background-image: url('${VUE_APP_S3_AIR_ASSETS_URL}/cms/AIR+White+Line.jpg'), url('${VUE_APP_S3_AIR_ASSETS_URL}/cms/AIR+White+Line.jpg'), url('${VUE_APP_S3_AIR_ASSETS_URL}/cms/Red-Track-Texture.jpg');`
				"
			/>
			<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
				<img style="width: 100%; position: relative; z-index: 2;" :src="'cms/AIR-Track_FOOTER72dpi.png' | urlize" />
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-summer-summer202401-banner',

		components: {},

		props: {
			copy: { type: String, required: false, default: null }
		},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL
			};
		},

		computed: {},

		created() {}
	};
</script>

<style lang="scss" scoped></style>
