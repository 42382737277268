import { $http } from '@/utils';

/**
 * @namespace Service
 * @class UserDetail
 * @exports Service/Account/UserDetail
 * @description API User Detail Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserDetail {
	/**
	 * @static @name patch
	 * @description Update some User details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`account/user/detail/${id}`, payload);
	}
}
