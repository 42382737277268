<!--
	@name app-client-dialog-information
	@description Client information
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Client Information
		</template>

		<template #body>
			<h2 class="text-h6 mb-2">
				Client Reference
			</h2>
			<p class="body-2 mb-0">
				If you need the Air client(s) reference numbers, please click on the COPY button. Once copied you will be able to paste the reference numbers as needed.
			</p>
			<div v-if="client" class="mt-6">
				<p class="mb-1 font-weight-bold">
					{{ clientFullName }}
				</p>
				<v-row class="ma-n3">
					<v-col class="grow">
						<v-text-field
							:value="client.id"
							:append-icon="clientCopied ? 'mdi-eye' : false"
							readonly
							outlined
							hide-details
							dense
							type="text"
						>
							<template #append>
								<v-fade-transition leave-absolute>
									<v-icon v-if="clientCopied" color="success">
										mdi-check
									</v-icon>
								</v-fade-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col class="shrink align-self-center pl-0">
						<v-btn @click="copyToClipboard('client', client.id)">
							<v-icon left>
								mdi-content-copy
							</v-icon>
							Copy
						</v-btn>
					</v-col>
				</v-row>
			</div>
			<div v-if="association" class="mt-4">
				<p class="mb-1 font-weight-bold">
					{{ associationFullName }}
				</p>
				<v-row class="ma-n3">
					<v-col class="grow">
						<v-text-field
							:value="association.id"
							readonly
							outlined
							hide-details
							dense
							type="text"
						>
							<template #append>
								<v-fade-transition leave-absolute>
									<v-icon v-if="associationCopied" color="success">
										mdi-check
									</v-icon>
								</v-fade-transition>
							</template>
						</v-text-field>
					</v-col>
					<v-col class="shrink align-self-center pl-0">
						<v-btn @click="copyToClipboard('association', association.id)">
							<v-icon left>
								mdi-content-copy
							</v-icon>
							Copy
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapGetters } from 'vuex';
	import CommonDialog from '@/component/common/dialog';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-client-dialog-information',

		components: { CommonDialog },

		props: {
			client: { type: Object, required: false, default: null },
			association: { type: Object, required: false, default: null }
		},

        data() {
			return {
				clientCopied: false,
                associationCopied: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),

            clientFullName() {
                return `${this.client.nameTitleName} ${this.client.nameGiven} ${this.client.nameMiddle || ''} ${this.client.nameFamily}`;
            },

            associationFullName() {
                return `${this.association.nameTitleName} ${this.association.nameGiven} ${this.association.nameMiddle || ''} ${this.association.nameFamily}`;
            }
		},

		created() {},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
                this.clientCopied = false;
                this.associationCopied = false;
                this.$refs.dialog.open();
			},

            /**
			 * @name copyToClipboard
			 * @description Copy text to clipboard for paste
			 */
			copyToClipboard(type, text) {
				navigator.clipboard.writeText(text).then(() => {
					//EventBus.$emit('displayMsg', 'Copying to clipboard was successful!', 'green');
                    ElementTools.fireNotification(this.$el, 'success', `Copying client reference to clipboard was successful`);
                    if(type === 'association') this.associationCopied = true;
                    this.clientCopied = true;
					console.log('Async: Copying to clipboard was successful!');
				}, function(err) {
					console.error('Async: Could not copy text: ', err);
				});
			}
		}
	};
</script>

<style scoped></style>
