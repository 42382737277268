import { $http } from '@/utils';

/**
 * @namespace Service
 * @class SecurityQuestion
 * @exports Service/Account/SecurityQuestion
 * @description API SecurityQuestion Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class SecurityQuestion {
	/**
	 * @static @name list
	 * @description Get all security questions
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list() {
		return $http.get('account/security-question');
	}
}
