<!--
	@name app-common-api-health-items
	@description Load products via API
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-list class="pa-0">
			<app-common-api-health-item
				v-for="(provider, index) in providersToDisplay"
				:key="index"
				:provider="provider"
				:layout="layout"
			/>
		</v-list>
	</div>
</template>

<script>
	import AppCommonApiHealthItem from '@/component/app/common/api-health/item';

    export default {
		name: 'app-source-result-product-loader-items',

		components: {
            AppCommonApiHealthItem
        },

		props: {
			providers: { type: Array, required: true },
			layout: { type: String, default: 'default' }
		},

		data() {
			return {};
		},

		computed: {
            providersToDisplay() {
				return this.providers.filter((p) => p.health !== false);
			}
        },

		methods: {}
	};
</script>
