<!--
	@name common-dialog-Knowledge-bank-criteria
	@description Knowledge bank criteria dialog
	@date 2022/05/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable :component="$options.name">
		<v-card tile>
			<v-toolbar flat dark class="pl-3 pr-3 generic-gradient" max-height="64">
				<v-toolbar-title class="font-weight-medium">
					Completions Report
				</v-toolbar-title>
				<v-spacer />
				<v-toolbar-items>
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-container style="max-width: 1000px" class="mx-auto">
					<template v-if="!month">
						<p class="text-body-1">
							Welcome to your completion history. Navigate your journey by clicking 'View' next to a specific month to access a detailed breakdown of your
							completions. For your convenience, you can also download a monthly summary for your personal records.
						</p>
						<v-data-table
							class="table--styled elevation-3"
							:headers="headers"
							:items="months"
							:options.sync="options"
							:server-items-length="options.total"
							:loading="loading"
							:footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
						>
							<template #progress>
								<v-progress-linear indeterminate />
							</template>
							<template #item="{ item }">
								<tr v-if="months.length > 0">
									<td>
										{{ item.display }}
									</td>
									<td class="shrunk actions">
										<v-btn color="primary" small @click="selectMonth(item)">
											View
										</v-btn>
									</td>
								</tr>
							</template>
						</v-data-table>
					</template>
					<v-row v-else no-gutters>
						<v-col cols="12">
							<v-row>
								<v-col class="grow align-self-end pt-0">
									<a class="d-block mb-2" @click="month = null"> &laquo;&laquo; back </a>
									<h2 class="text-h6 mb-0">
										{{ month.display }}
									</h2>
								</v-col>
								<v-col class="shrink align-self-end pt-0">
									<v-btn icon @click="generatePdf">
										<v-icon> mdi-printer </v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-simple-table dense class="elevation-3 table--styled" :loading="loading">
								<template #default>
									<thead>
										<tr>
											<th class="text-left">
												Client
											</th>
											<th class="text-left">
												Ref
											</th>
											<th class="text-left">
												Provider
											</th>
											<th class="text-left">
												Route
											</th>
											<th class="text-left">
												Loan size
											</th>
											<th class="text-right">
												Date
											</th>
										</tr>
									</thead>
									<template v-if="loading">
										<thead>
											<tr>
												<th colspan="6">
													<v-progress-linear indeterminate />
												</th>
											</tr>
										</thead>
										<tbody>
											<tr class="v-data-table__empty-wrapper">
												<td colspan="6">
													Loading items...
												</td>
											</tr>
										</tbody>
									</template>
									<tbody>
										<tr v-for="completion in completions" :key="completion.id">
											<td>
												<span v-if="!completion.data.client" class="grey--text text--lighten-1 font-italic"> Not known </span>
												<template v-else>
													{{ completion.data.client }}
												</template>
											</td>
											<td>
												<span v-if="!completion.data.funder" class="grey--text text--lighten-1 font-italic"> Not known </span>
												<template v-else>
													{{ completion.data.funder }}
												</template>
											</td>
											<td>{{ completion.data.provider }}</td>
											<td>
												<span v-if="!completion.data.route" class="grey--text text--lighten-1 font-italic"> Not known </span>
												<template v-else>
													{{ completion.data.route }}
												</template>
											</td>
											<td>
												<span v-if="!completion.data.amount" class="grey--text text--lighten-1 font-italic"> Not known </span>
												<template v-else>
													£{{ completion.data.amount | numFormat('0,00') }}
												</template>
											</td>
											<td class="text-right">
												{{ completion.completed | moment('MMM Do YYYY') }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import pdfMake from 'pdfmake/build/pdfmake';
	import pdfFonts from 'pdfmake/build/vfs_fonts';

	export default {
		name: 'common-completions-report',

		components: {},

		data() {
			return {
				active: false,
				month: false,
				loading: false,
				options: {
					itemsPerPage: 5,
					total: 0
				},
				headers: [
					{
						text: 'Date',
						align: 'start',
						sortable: false,
						value: 'date',
						cellClass: 'shrink'
					},
					{ text: '', value: 'actions', sortable: false, class: 'shrunk actions' }
				],
				completions: [],
				months: []
			};
		},

		watch: {
			options: {
				handler() {
					this.loading = true;
					this.months = [];
					this.loadCompletionsMonthsList({ limit: this.options.itemsPerPage, offset: (this.options.page - 1) * this.options.itemsPerPage }).then((months) => {
						this.months = months.data.map((item) => ({ display: this.$moment(item).format('MMMM YYYY'), date: item }));
						this.options.total = months.total;
						this.loading = false;
					});
				},
				deep: true
			}
		},

		methods: {
			...mapActions('AppCompletion', ['loadCompletionsList', 'loadCompletionsMonthsList']),

			open() {
				this.active = true;
			},

			close() {
				this.active = false;
			},

			selectMonth(month) {
				this.completions = [];
				this.month = month;
				this.loading = true;
				this.loadCompletionsList({
					limit: 1000,
					where: {
						completed: {
							t: 'daterange',
							c: 'between',
							v: [this.$moment(month.date).startOf('month').format('YYYY-MM-DD'), this.$moment(month.date).endOf('month').format('YYYY-MM-DD')]
						}
					},
					order: { property: 'completed', direction: 'DESC' }
				}).then((completions) => {
					this.completions = completions.data;
					this.loading = false;
				});
			},

			generatePdf() {
				pdfMake.vfs = pdfFonts.pdfMake.vfs;

				const docDefinition = {
					pageOrientation: 'landscape',

					info: {
						title: `Completion Report: ${this.month.display}`,
						author: 'Air Sourcing'
					},
					content: [
						{
							alignment: 'left',
							text: `Completions Report: ${this.month.display}`,
							style: 'header',
							fontSize: 18,
							bold: true,
							margin: [0, 10, 0, 20]
						},
						{
							layout: {
								fillColor: '#E6E6E6'
							},
							table: {
								widths: ['100%'],
								heights: [20],
								body: [
									[
										{
											text: `Date: ${this.$moment().format('DD/MM/YYYY HH:mm')}`,
											fontSize: 10,
											bold: true,
											margin: [4, 4, 0, 0],
											lineHeight: 1.2
										}
									]
								]
							}
						},
						{
							style: 'tableExample',
							margin: [0, 20, 0, 0],
							layout: {
								fillColor: function(rowIndex) {
									return rowIndex === 0 ? '#E6E6E6' : null;
								}
							},
							table: {
								widths: ['18%', '26%', '16%', '16%', '12%', '12%'],
								heights: [25, ...this.completions.map(() => 20)],
								headerRows: 1,
								dontBreakRows: true,
								body: [
									[
										{ text: 'Client', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Ref', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Provider', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Route', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Loan Size', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Date', bold: true, fontSize: 10, lineHeight: 1.2, margin: [4, 3, 0, 0], alignment: 'right' }
									],
									...this.completions.map((completion) => [
										{
											text: completion.data.client || '--',
											bold: true,
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0]
										},
										{
											text: completion.data.funder || '--',
											bold: true,
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0]
										},
										{
											text: completion.data.provider,
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0]
										},
										{
											text: completion.data.route || '--',
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0]
										},
										{
											text: `£${this.$options.filters.numFormat(completion.data.amount, '0,00')}`,
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0]
										},
										{
											text: this.$moment(completion.completed).format('MMM Do YYYY'),
											fontSize: 10,
											lineHeight: 1.2,
											margin: [4, 3, 0, 0],
											alignment: 'right'
										}
									])
								]
							}
						}
					]
				};
				pdfMake.createPdf(docDefinition).download(`Completion Report - ${this.month.display}.pdf`);
			}
		}
	};
</script>

<style lang="scss" scoped>
	table {
		th,
		td {
			&.shrunk {
				width: 1px;
				white-space: nowrap;
			}

			&.nowrap {
				white-space: nowrap;
			}
		}
	}

	::v-deep table th,
	td {
		&.shrunk {
			width: 1px;
			white-space: nowrap;
		}

		&.nowrap {
			white-space: nowrap;
		}
	}
</style>
