import { AccountUser } from '@/service';

/**
 * @class @name User
 * @description Exposes menu store module
 */
export default class User {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name modifyUser
			 * @description used to modify user details
			 * @param {function} commit
			 * @param {Object} data
			 */
			async modifyUser(store, data) {
				let { id, payload } = data;
				return AccountUser.patch(id, payload);
			},

			/**
			 * @async @name loadUserList
			 * @description load users details
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async loadUserList(store, payload) {
				return AccountUser.list(payload);
			},

			/**
			 * @async @name academyAdvisorList
			 * @description load academy user list
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async academyAdvisorList(store, payload) {
				return AccountUser.academyAdvisorList(payload);
			}
		};
	}
}
