import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Finova
 * @exports Service/Partner/Finova
 * @description API Partner Finova Service
 * @date 2022/10/07
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Finova {
	/**
	 * @static @name magicLink
	 * @description Get case magic link
	 * @returns {Object} Promise a http response
	 */
	static magicLink(provider, caseId) {
		return $http.post(`partner/finova/${provider}/case/${caseId}/magic-link`);
	}
}
