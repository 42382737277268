<!--
	@name app-air-select-panel-status
	@description Air Select panel status
	@date 2020/06/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section body-class="flex-grow-1 pt-0">
			<template #body>
				<v-fade-transition>
					<v-alert :color="active ? 'success' : 'error'" class="mb-0" text outlined>
						<v-row no-gutters>
							<v-col class="grow">
								<p class="text-body-2 font-weight-regular mb-0" v-html="$sanitize(message)" />
							</v-col>
							<v-col class="shrink d-flex align-center">
								<v-divider class="mx-5" vertical />
								<v-tooltip bottom>
									<template #activator="{ on }">
										<div v-on="on">
											<v-switch
												:input-value="active"
												:loading="loading"
												:disabled="loading"
												class="ma-0 pa-0"
												hide-details
												color="success"
												@change="toggleBypass"
											/>
										</div>
									</template>
									<span>{{ active ? 'Click to deactivate Air Select' : 'Click to activate Air Select' }}</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-alert>
				</v-fade-transition>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-air-select-panel-status',

		components: { CommonStructureSection },

		data() {
			return {
				loading: false
			};
		},

		computed: {
			...mapGetters('Account', ['membership']),

			active() {
				return this.membership('select').id && !this.membership('select').bypass;
			},

			panelStatus() {
				if (this.loading) return 'Pending';
				if (this.active === undefined) return false;
				if (this.active) return 'Active';
				return 'Inactive';
			},

			message() {
				if (this.active)
					return '<span class="font-weight-bold">Your panel status is set to ACTIVE</span>, meaning you are currently operating as an Air Select adviser. Click the switch to revert to Whole of Market and update your panel status to INACTIVE.';
				return '<span class="font-weight-bold">Your panel status is set to INACTIVE</span>, meaning you are currently operating as a Whole of Market adviser. Click the switch to revert to Air Select and update your panel status to ACTIVE.';
			}
		},

		methods: {
			...mapActions('Account', ['toggleAirSelectBypass']),

			async toggleBypass() {
				this.loading = true;
				await this.toggleAirSelectBypass();
				this.loading = false;
			}
		}
	};
</script>

<style lang="scss" scoped></style>
