<!--
	@name common-dialog-Knowledge-bank-criteria
	@description Knowledge bank criteria dialog
	@date 2022/05/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			<span>Criteria Search</span>
		</template>

		<template #body>
			<div v-if="submitting" class="text-center">
				<h2 class="text-h6 mb-5">
					Contacting Knowledge Bank...
				</h2>
				<v-progress-circular :size="70" :width="7" color="#2374ab" indeterminate />
			</div>

			<div v-else>
				<v-row align="center">
					<v-col cols="12" sm="4" class="py-2">
						<v-img width="266" height="89" class="mx-auto" :src="'knowledge_bank.svg' | urlize('organisation')" />
					</v-col>
				</v-row>

				<div v-if="results">
					<v-row>
						<v-col cols="12" sm="12">
							<p class="text-body-1 pb-3 mb-0">
								<span class="font-weight-bold">Criteria Search Results:</span> See below for a concentrated list of criteria results by Lender. Additionally,
								results have been added individually to each applicable Product in the results list.
							</p>
						</v-col>
					</v-row>

					<v-row no-gutters align="center" :class="[{ 'mb-6': requestStatusSuccess, 'mt-3': !requestStatusSuccess }]">
						<v-col cols="12" sm="8">
							<p v-if="!requestStatusSuccess" class="mb-0 text-body-2 font-weight-bold error--text">
								{{ !requestStatusSuccess }}
							</p>
							<v-sheet v-else>
								<common-base-criteria-chips :criteria-items="key" :ordered="false" />
								<span class="d-block mt-2 text-caption plain grey--text text--darken-1">
									Key: The colour code used by Knowledge Bank to indicate the status of each result.
								</span>
							</v-sheet>
						</v-col>
						<v-col cols="12" sm="4" class="text-right align-self-start">
							<v-btn color="light-blue darken-4" class="ma-0 white--text" @click="backToCriteria">
								Perform New Search
							</v-btn>
						</v-col>
					</v-row>

					<div v-if="requestStatusSuccess">
						<v-card class="elevation-0">
							<v-simple-table>
								<template #default>
									<tbody>
										<tr v-for="(item, key) in results" :key="key">
											<td class="shrunk vertical-align-top">
												<v-img
													:src="logoName(item.provider.logo) | urlize('organisation')"
													aspect-ratio="1"
													contain
													width="75"
													max-width="75"
													max-height="75"
												/>
											</td>
											<td class="vertical-align-middle">
												<div v-for="(plan, planId, index) in item.criteria" :key="planId" :class="[planId === 0 || index === 0 ? '' : 'mt-4']">
													<p class="text-body-2 font-weight-bold mb-1">
														{{ plan.title }}
													</p>
													<span class="text-body-2">
														<common-base-criteria-chips :criteria-items="plan.criteria" />
													</span>
												</div>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card>
					</div>
				</div>

				<div v-else>
					<v-row>
						<v-col cols="12" sm="12">
							<p class="text-body-1 pb-3 mb-0">
								<span class="font-weight-bold">Build your Criteria Search:</span> Firstly select a criteria category, then select criteria items to be added to your
								search. Once you have selected all the required criteria, press the Submit Criteria Search button.
							</p>
						</v-col>
					</v-row>

					<v-row no-gutters align="center">
						<v-col cols="12" sm="12">
							<v-text-field
								v-model="search"
								prepend-inner-icon="mdi-table-search"
								full-width
								hide-details
								solo
								outlined
								dense
								clearable
								label="Search Keywords: e.g. Flood, Subsidence, CCJ"
								class="mb-0"
							/>
							<p v-show="searchItems" class="mt-1 pl-2 mb-n2 text-caption grey--text">
								{{ searchItems }}
							</p>
						</v-col>
					</v-row>

					<v-row v-show="filteredCriteria.length" no-gutters align="center" class="mt-5">
						<v-col cols="12" sm="4">
							<v-chip
								outlined
								label
								class="text-body-2 ma-0 px-5 py-4 font-weight-regular"
								:class="{ 'blue darken-4 blue--text text--darken-4': selectedItemsCount, 'grey--text': !selectedItemsCount }"
							>
								Selected Items:<span class="d-inline-block ml-1">
									{{ selectedItemsCount }}
								</span>
							</v-chip>
						</v-col>
						<v-col cols="12" sm="2" />
						<v-col cols="12" sm="6" class="text-right">
							<div class="mx-n1">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn v-show="selectedItemsCount" color="grey lighten-3" class="my-0 mx-1 error--text" @click="clearSlectedCriteria" v-on="on">
											Clear
										</v-btn>
									</template>
									<span>Clear selected items</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn v-show="selectedItemsCount" color="light-blue darken-4" class="my-0 mx-1 white--text" :disabled="selectedItemsCount < minimumItems" @click="submit" v-on="on">
											{{ submitButtonText }}
										</v-btn>
									</template>
									<span>Submit</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn v-show="closeButtonText && !selectedItemsCount" color="grey lighten-3" class="my-0 mx-1 error--text" @click="close" v-on="on">
											{{ closeButtonText }}
										</v-btn>
									</template>
									<span>Close without applying changes</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn v-show="closeActionButtonText && defaultCriteria.length && !selectedItemsCount" color="error" class="my-0 mx-1" @click="closeAction" v-on="on">
											{{ closeActionButtonText }}
										</v-btn>
									</template>
									<span>Previously selected criteria will be removed</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>

					<v-expansion-panels class="mt-5">
						<v-expansion-panel v-for="(criterias, key) in filteredGroupedCriteria" :key="'criteriaGroup' + key">
							<v-expansion-panel-header disable-icon-rotate>
								{{ criterias.name }}
								<template v-if="isGroupActive(key)" #actions>
									<v-icon color="#2374ab">
										mdi-check-network
									</v-icon>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-simple-table v-for="(criteria, key) in criterias.criteriaList" :key="key" class="mt-3">
									<thead class="blue-grey lighten-4">
										<tr>
											<th class="text-left" colspan="2">
												{{ criteria.Criteria_Category_Title }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="shrunk vertical-align-top pr-0">
												<v-checkbox v-model="data[criteria.Criteria_Category_ID]" class="mt-0 pt-0" hide-details color="primary" />
											</td>
											<td class="text-left" colspan="2">
												{{ criteria.Criteria_Question }}
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import Vue from 'vue';
	import { mapActions, mapState } from 'vuex';
	import criteriaList from './criteria.json';
	import CommonDialog from '@/component/common/dialog';
	import CommonBaseCriteriaChips from '@/component/common/base/criteria-chips';
	import { ElementTools, GoogleAnalytics } from '@/utils';
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'common-knowledge-bank-criteria',

		components: { CommonDialog, CommonBaseCriteriaChips },

		props: {
			defaultCriteria: { type: Array, default: () => [] },
			submitButtonText: { type: String, default: 'Submit Criteria Search' },
			submitIntercept: { type: Boolean, default: false },
			closeButtonText: { type: String, default: 'Close' },
			closeActionButtonText: { type: String, default: null },
			minimumItems: { type: Number, default: 1 }
		},

		data() {
			return {
				key: [
					{
						Criteria_Category_ID: 1,
						Criteria_Summary: 1,
						Criteria_Title: 'Yes'
					},
					{
						Criteria_Category_ID: 2,
						Criteria_Summary: 2,
						Criteria_Title: 'Yes (Conditions)'
					},
					{
						Criteria_Category_ID: 3,
						Criteria_Summary: 3,
						Criteria_Title: 'Yes (Exceptions)'
					},
					{
						Criteria_Category_ID: 4,
						Criteria_Summary: 4,
						Criteria_Title: 'No'
					},
					{
						Criteria_Category_ID: 2.5,
						Criteria_Summary: 2.5,
						Criteria_Title: 'Refer'
					},
					{
						Criteria_Category_ID: 5,
						Criteria_Summary: 5,
						Criteria_Title: 'Info Only'
					}
				],
				search: '',
				criteriaList: criteriaList,
				data: {},
				submitting: false,
				results: null,
				requestStatusSuccess: null,
				requestErrorStatusMessage: null,
				referenceKey: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			/**
			 * @name searchItems
			 * @description TODO
			 * @return {String}
			 */
			searchItems: function() {
				if (!this.search) return '';
				if (!this.filteredCriteria.length) return '0 Results';
				return `${this.filteredCriteria.length} Result${this.filteredCriteria.length !== 1 ? 's' : ''}`;
			},

			/**
			 * @name groupedCriteria
			 * @description TODO
			 * @return {Object}
			 */
			groupedCriteria: function() {
				return this.filteredCriteria.reduce((acc, cur) => {
					if (acc[cur.Group_Name]) acc[cur.Group_Name].criteriaList.push(cur);
					else acc[cur.Group_Name] = { name: cur.Group_Name, criteriaList: [cur] };

					return acc;
				}, {});
			},

			/**
			 * @name filteredCriteria
			 * @description TODO
			 * @return {Array}
			 */
			filteredCriteria: function() {
				if (!this.criteriaList) return [];
				if (!this.search) return this.criteriaList;
				return this.criteriaList.filter(
					(item) => item.Group_Name.toLowerCase().includes(this.search.toLowerCase()) || item.Criteria_Question.toLowerCase().includes(this.search.toLowerCase())
				);
			},

			/**
			 * @name filteredGroupedCriteria
			 * @description TODO
			 * @return {Array}
			 */
			filteredGroupedCriteria: function() {
				let criteraiList = this.criteriaList;
				if (!this.criteriaList) return [];
				if (this.search)
					criteraiList = this.criteriaList.filter(
						(item) => item.Group_Name.toLowerCase().includes(this.search.toLowerCase()) || item.Criteria_Question.toLowerCase().includes(this.search.toLowerCase())
					);

				return criteraiList.reduce((acc, cur) => {
					if (acc[cur.Group_Name]) acc[cur.Group_Name].criteriaList.push(cur);
					else acc[cur.Group_Name] = { name: cur.Group_Name, groupId: cur.Group_ID, criteriaList: [cur] };

					return acc;
				}, {});
			},

			/**
			 * @name selectedItemsCount
			 * @description TODO
			 * @return {Array}
			 */
			selectedItemsCount: function() {
				return this.activeCriteria.length;
			},

			/**
			 * @name activeCriteria
			 * @description TODO
			 * @return {Array}
			 */
			activeCriteria() {
				let active = Object.keys(this.data).filter((x) => this.data[x] === true);
				return active;
			}
		},

		watch: {
			defaultCriteria() {
				this.defaultCriteria.forEach((x) => Vue.set(this.data, x, true));
			}
		},

		methods: {
			...mapActions('PartnerKnowledgeBank', ['searchCriteria']),

			/**
			 * @name isGroupActive
			 * @description TODO
			 * @param {Number} group The group
			 * @return {Boolean} Whether the passed group has a selected criteria
			 */
			isGroupActive(group) {
				const criteria = this.criteriaList.find((c) => this.data[c.Criteria_Category_ID] && c.Group_Name === group);
				if (criteria) return true;

				return false;
			},

			/**
			 * @name open
			 * @description Open the dialog
			 */
			open() {
				this.data = {};
				this.defaultCriteria.forEach((x) => Vue.set(this.data, x, true));
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description Close the dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name clearSlectedCriteria
			 * @description TODO
			 */
			clearSlectedCriteria() {
				this.data = {};
			},

			/**
			 * @name closeAction
			 * @description TODO
			 * @return {String}
			 */
			closeAction() {
				this.$emit('close-action-confirmed', {
					selectedCriteria: this.activeCriteria,
					criteria: { key: null }
				});
			},

			/**
			 * @name submit
			 * @description TODO
			 * @return {String}
			 */
			submit() {
				if (!this.submitIntercept) this.submitKnowledgeBankCriteria();
				this.$emit('search-criteria-submitted', { selectedCriteria: this.activeCriteria });
			},

			/**
			 * @name submitKnowledgeBankCriteria
			 * @description TODO
			 * @return {String}
			 */
			submitKnowledgeBankCriteria() {
				this.submitting = true;
				this.referenceKey = uuidv4();
				const payload = { criteria: this.activeCriteria, cacheOptions: { key: this.referenceKey } };

				this.searchCriteria(payload)
					.then((criteria) => {
						this.results = criteria;
						this.requestStatusSuccess = true;
						this.$emit('search-criteria-updated', {
							selectedCriteria: this.activeCriteria,
							criteria: { key: this.referenceKey }
						});
						GoogleAnalytics.sendEvent('knowledgeBankCriteriaSubmitted', {
							'gtm.element.dataset.gaId': 'toolbar'
							,'gtm.element.dataset.gaValue': `${this.activeCriteria.toString()}?` //add character to ensure value is cast as string in GA4
						});
					})
					.catch((err) => {
						let msg = 'An error occured whilst trying to communicate with the Knowledge Bank API. Please try again later';
						if (err.data.message) msg = err.data.message;
						ElementTools.fireNotification(this.$el, 'error', msg);
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name backToCriteria
			 * @description TODO
			 */
			backToCriteria() {
				this.results = null;
				this.requestStatusSuccess = null;
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			}
		}
	};
</script>

<style lang="scss" scoped>
	tr {
		border-bottom: none !important;
		td {
			border-bottom: 5px solid #ffffff !important;
		}
		&:last-child td {
			border-bottom: none !important;
		}
	}

	th.shrunk,
	td.shrunk {
		width: 1px;
		white-space: nowrap;
	}
	.vertical-align-top {
		vertical-align: top;
	}
</style>
