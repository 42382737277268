import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Insight
 * @exports Service/Cms/Insight
 * @description API Insight Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Insight {
	/**
	 * @static @name list
	 * @description Get all news as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static list(domain, cache = true) {
		return $http.post(
			'cms/insight/list',
			{},
			{
				cache,
				cachePath: `table-cms-insight-${domain}`
			}
		);
	}

	/**
	 * @static @name get
	 * @description Get a single news item by id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`cms/insight/${id}`);
	}
}
