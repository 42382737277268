<!--
	@name app-firm-detail
	@description Update Firm Details
	@date 2020/05/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet class="pa-5">
			<div v-if="formData">
				<dynamic-form
					ref="dynamicForm"
					:form-schema="form('firm_details').value"
					:form-data="formData"
					submit-button-text="Save Changes"
					:submit-button-loading="submitting"
					@dynamic-form-submit="submit"
				/>
				<div class="mt-6">
					<p class="text-body-1 font-weight-bold mb-0">
						FCA Number
					</p>
					<p class="text-body-2 mb-2">
						Use this section if you should ever need to update your FCA number. Your request will be sent to our support team for processing.
					</p>
					<v-alert v-if="pendingFcaNumber" type="info" dense text out class="text-body-2 mb-2">
						Your request to change to FCA number {{ pendingFcaNumber }} has been sent to our support team
					</v-alert>
					<v-skeleton-loader v-if="!form('firm_fca_number').value" type="list-item-three-line" />
					<dynamic-form
						v-else
						ref="dynamicForm"
						:form-schema="form('firm_fca_number').value"
						:form-data="fcaNumberFormData"
						submit-button-text="Update FCA Number"
						:submit-button-loading="loadingFcaNumber"
						:submit-button-disabled="!!pendingFcaNumber"
						@dynamic-form-submit="submitFcaNumber"
					/>
				</div>
			</div>

			<v-skeleton-loader v-else type="list-item-three-line" />
		</v-sheet>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-firm-detail',

		components: { DynamicForm },

		data() {
			return {
				formData: null,
				submitting: false,
				fcaNumberFormData: {},
				loadingFcaNumber: false,
				pendingFcaNumber: false,
				organisationCommunication: []
			};
		},

		computed: {
			...mapState('Account', ['organisation', 'organisationAddress']),
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('firmDetails');
			this.loadForm('firmFcaNumber');
			this.loadOrganisationDetails(this.organisation.id).then((response) => {
				this.organisationCommunication = response.organisationCommunication;

				this.formData = {
					id: this.organisation.id,
					name: this.organisation.name,
					addressLine1: this.organisationAddress.addressLine1,
					addressLine2: this.organisationAddress.addressLine2,
					townCity: this.organisationAddress.townCity,
					postalCode: this.organisationAddress.postalCode,
					landline: this.organisationCommunication.find((d) => d.type === 'phone')?.identifier
				};

				this.fcaNumberFormData = { fca: this.organisation.data?.membership?.fca };
			});
		},

		methods: {
			...mapActions('Account', ['modifyOrganisation', 'modifyOrganisationAddress']),
			...mapActions('AccountOrganisation', ['modifyOrganisationFcaNumberRequest', 'modifyOrganisationCommunication', 'loadOrganisationDetails']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name submit
			 * @description submit user request to update firm details
			 */
			async submit(data) {
				this.submitting = true;

				Promise.all([
					this.modifyOrganisation({ id: data.id, name: data.name }),
					this.modifyOrganisationAddress({
						organisationId: this.organisation.id,
						id: this.organisationAddress.id,
						addressLine1: data.addressLine1,
						addressLine2: data.addressLine2,
						townCity: data.townCity,
						postalCode: data.postalCode
					}),
					this.modifyOrganisationCommunication({
						organisationId: this.organisation.id,
						...this.organisationCommunication.find((d) => d.type === 'phone'),
						identifier: data.landline
					})
				])
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Your firm details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update firm details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submitFcaNumber
			 * @description submit fca number change request
			 */
			async submitFcaNumber() {
				this.loadingFcaNumber = true;

				const payload = {
					organisationId: this.organisation.id,
					fcaNumber: this.fcaNumberFormData.fca
				};
				this.modifyOrganisationFcaNumberRequest(payload)
					.then(({ data }) => {
						this.pendingFcaNumber = this.fcaNumberFormData.fca;
						// Change has not been actioned, so relfect that in FCA input box
						this.fcaNumberFormData.fca = this.organisation.data?.membership?.fca;
						ElementTools.fireNotification(this.$el, 'success', data?.message || 'Your request has been sent to our support team');
					})
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error?.data?.message || 'Failed to submit FCA number change request'))
					.finally(() => (this.loadingFcaNumber = false));
			}
		}
	};
</script>
