<!--
	@name app-layout-account-footer
	@description Footer for account page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-footer class="ma-0 pa-0 pt-15" inset style="background: none !important">
		<v-row v-if="site" no-gutters class="white pa-6 mx-auto justify-center">
			<v-col cols="8" class="mb-0 text-center">
				<p class="text-caption plain mb-3">
					Copyright © {{ year }} {{ site.company.name }}. {{ site.softwareName }} is a Trading Style of {{ site.company.name }} {{ site.company.name }} is a Limited
					Company registered in England and Wales number: {{ site.contact.number }}. {{ site.contact.address }}. Opening hours: {{ site.contact.openingHours }}.
				</p>
				<p class="text-caption plain mb-3">
					Telephone: {{ site.contact.number }}
					<br />
					Email: <a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
				</p>
				<p class="text-caption plain mb-0">
					<a @click="openTerms">Terms and Conditions</a> | <a @click="openPrivacy">Privacy Notice</a> |
					<a @click="openSlaveryStatement">Modern Slavery Statement</a>
				</p>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
	import { mapState } from 'vuex';
	import { EventBus } from '@/utils';

	export default {
		name: 'app-layout-account-footer',

		components: {},

		computed: {
			...mapState('CmsSite', ['site']),

			year() {
				let d = new Date();
				return d.getFullYear();
			}
		},

		methods: {
			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			openSlaveryStatement() {
				EventBus.$emit('open-slavery-statement');
			}
		}
	};
</script>
