<!--
	@name app-case-manager-cases
	@description Cases for case manager
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section thick-header :header-class="headerClass">
			<template v-if="showHeader" #header>
				<v-pagination v-model="options.page" class="justify-start" :length="pageCount" :total-visible="8" color="info" @input="paginationUpdated" />
				<v-spacer />
				<validation-observer ref="observer">
					<validation-provider v-slot="{ errors }" name="Search" rules="search">
						<v-text-field
							v-model="searchTerm"
							placeholder="Search: e.g. NOC List"
							append-icon="mdi-magnify"
							dense
							solo
							class="mt-6"
							:error-messages="errors"
							style="width: 20rem; max-width: 20rem; margin-top: 3px"
						/>
					</validation-provider>
				</validation-observer>
			</template>
			<template #body>
				<v-data-table
					class="data-table"
					:disable-pagination="!showHeader"
					:hide-default-footer="!showHeader"
					:footer-props="footerProps"
					:headers="filteredHeaders"
					:items="cases"
					:no-data-text="'No results found'"
					:loading="loading"
					:options.sync="options"
					:items-per-page="itemsPerPage"
					:search="searchTerm"
					:server-items-length="total"
					style="margin-top: -3px"
					multi-sort
					@update:options="optionsUpdated($event)"
				>
					<template #item="{ item }">
						<tr>
							<td class="shrunk px-5 py-4 pr-2">
								<v-avatar size="36px" :color="colourClass(cases.indexOf(item))">
									<v-icon dark>
										mdi-account-circle
									</v-icon>
								</v-avatar>
							</td>
							<td v-if="showColumn('Client')">
								<p class="text-body-1 font-weight-bold mb-0">
									{{ item.clientName }}
								</p>
								<p v-if="item.address" class="text-subtitle-2 ma-0">
									{{ item.address }}
								</p>
							</td>
							<td class="">
								<p class="text-subtitle-2 font-weight-medium ma-0">
									{{ item.id }}
								</p>
							</td>
							<td class="">
								<p class="text-subtitle-2 font-weight-medium ma-0">
									{{ item.matterTypeName }}
								</p>
							</td>
							<td class="shrunk">
								<v-chip :class="getStatusColour(item)" label small active dark>
									<p class="text-subtitle-2 font-weight-light ma-0">
										{{ item.status }}
									</p>
								</v-chip>
							</td>
							<td class="shrunk">
								<p v-if="item.updated" class="text-subtitle-2 font-weight-medium ma-0">
									{{ item.updated | moment('from', 'now') }}
								</p>
								<p v-else class="text-subtitle-2 font-weight-medium ma-0">
									No Updates
								</p>
							</td>
							<td class="shrunk text-right">
								<div class="d-flex flex-no-wrap justify-end">
									<v-menu offset-y open-on-hover transition="slide-y-transition" left>
										<template #activator="{ on }">
											<v-btn small color="secondary" class="mb-4" :loading="fileLoading[item.iterationId]" v-on="on">
												<v-icon>mdi-dots-horizontal</v-icon>
											</v-btn>
										</template>
										<v-list dense class="py-0">
											<template v-for="(action, index) in getConfig(item).functions">
												<v-tooltip :key="index" bottom :disabled="!action.tooltip">
													<template #activator="{ on }">
														<div v-on="on">
															<v-list-item v-show="!action.hide" :disabled="action.disabled" @click="handleAction(action, item)">
																<v-list-item-icon :disabled="true">
																	<v-icon :color="action.icon_color">
																		{{ action.icon }}
																	</v-icon>
																</v-list-item-icon>
																<v-list-item-title>{{ action.title }}</v-list-item-title>
															</v-list-item>
														</div>
													</template>
													<span>{{ action.tooltip }}</span>
												</v-tooltip>
											</template>
										</v-list>
									</v-menu>
								</div>
							</td>
						</tr>
					</template>
				</v-data-table>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	const COLOURS = ['green', 'blue darken-4', 'yellow darken-2', 'purple darken-2', 'green darken-3'];

	import CommonStructureSection from '@/component/common/structure/section';
	import { mapActions } from 'vuex';
	import { debounce } from 'lodash';
	import documentMixin from '@/mixins/document';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-case-manager-cases',

		components: {
			CommonStructureSection,
			ValidationObserver,
			ValidationProvider
		},

		mixins: [documentMixin],

		props: {
			showHeader: { type: Boolean, default: true },
			hideHeaders: { type: Array, default: () => [] },
			headerClass: { type: String, default: 'blue-grey lighten-4' }
		},

		data() {
			return {
				cases: [],
				itemsPerPage: 10,
				pageCount: 0,
				total: 0,
				searchTerm: '',
				options: {
					page: 1,
					itemsPerPage: 10,
					sortBy: [],
					sortDesc: []
				},
				headers: [
					{ text: '', align: 'left', value: '', sortable: false },
					{ text: 'Client', align: 'left', value: 'client.name_given', class: 'text-no-wrap', sortable: false },
					{ text: 'Case ID', align: 'left', value: 'id', class: 'text-no-wrap', sortable: false },
					{ text: 'Type', align: 'left', value: 'matter_type.name_unique', class: 'text-no-wrap' },
					{ text: 'Status', align: 'left', value: 'status', class: 'text-no-wrap', sortable: false },
					{ text: 'Updated', align: 'left', value: 'action.updated', class: 'text-no-wrap' },
					{ text: '', align: 'left', value: '', sortable: false }
				],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				loading: false,

				fileLoading: {}
			};
		},

		computed: {
			filteredHeaders() {
				return this.headers.filter((o) => !this.hideHeaders.includes(o.text));
			}
		},

		watch: {
			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;

				if (!this.searchTerm.lengh) {
					this.options.page = 1;
				}
				this.debouncedLoadCases();
			}
		},

		methods: {
			...mapActions('Case', ['fetchCaseList']),

			showColumn(header) {
				return this.filteredHeaders.filter((o) => o.text === header).length;
			},
			/**
			 * @name fetchCaseList
			 * @description Load cases
			 */
			async fetchCases() {
				if (this.loading) return;

				this.cases = [];
				this.loading = true;
				const { page, itemsPerPage, sortDesc, sortBy } = this.options;
				const payload = {
					limit: itemsPerPage,
					offset: (page - 1) * itemsPerPage,
					order: { property: 'action.updated', direction: 'DESC' }
				};

				if (sortBy.length) {
					payload.order = [];
					sortBy.forEach((property, index) => payload.order.push({ property, direction: sortDesc[index] ? 'DESC' : 'ASC' }));
				}

				if (this.searchTerm) {
					payload.where = [{ 'client.name_given': { c: 'ILIKE', v: `%${this.searchTerm}%` } }, '||', { 'client.name_family': { c: 'ILIKE', v: `%${this.searchTerm}%` } }];
				}

				const res = await this.fetchCaseList(payload);
				this.cases = res.data.data;
				this.pageCount = Math.ceil(res.data.total / itemsPerPage);
				this.total = res.data?.total;
				this.loading = false;
			},
			/**
			 * @name optionsUpdated
			 * @description event handler for options updated
			 * @param {Object} options
			 */
			optionsUpdated(options) {
				this.options = options || this.options;
				this.fetchCases();
			},
			/**
			 * @name paginationUpdated
			 * @description event handler for pagination updated
			 */
			paginationUpdated() {
				this.optionsUpdated();
			},
			/**
			 * @name debouncedLoadCases
			 * @description Load clients with debounce
			 */
			debouncedLoadCases: debounce(function() {
				this.fetchCases();
			}, 500),

			getStatusColour(item) {
				if (item.type === 'lifetime_mortgage') {
					switch (item.status) {
						case 'KFI Request Pending Submission':
							return 'warning';
						case 'KFI Request Submitted':
							return 'amber black--text';
						case 'KFI Request Failed':
							return 'red accent-2';
						case 'KFI Received':
							return 'light-green';
						case 'Product Application Submitted':
							return 'green';
					}
				} else {
					switch (item.status) {
						case 'Submitted':
							return 'warning black--text';
						case 'Declined':
							return 'red';
						case 'Accepted':
							return 'amber';
						case 'In Progress':
							return 'light-green';
						case 'Suspended':
							return 'warning';
						case 'Completed':
							return 'green';
						case 'Failed':
							return 'red accent-2';
					}
				}
			},

			/**
			 * @name colourClass
			 * @description TODO
			 * @param {Number} key TODO
			 * @return {String} Classes for element
			 */
			colourClass(key) {
				return COLOURS[key % COLOURS.length];
			},

			getConfig(item) {
				const functionCase = [
					{
						name: 'will',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}?workflowSection=2a066d3a-fcab-49d1-ad3c-9cfe56c7291d`,
								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					},
					{
						name: 'conveyancing',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}?workflowSection=8ab933f5-7796-46dc-b354-a62fb721b6ca`,
								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					},
					{
						name: 'power_of_attorney',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}?workflowSection=49a51c5d-d4f1-45c2-8c73-d4e1abdcb4bf`,

								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					},
					{
						name: 'care_plans',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}`,
								//path: `/client/${item.clientId}?service=carePlans`, TODO - add back when dialog close issue resolved
								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					},
					{
						name: 'lifetime_mortgage',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}?workflowSection=10cea1e8-8edb-4615-a3e3-8d66ad57f401`,
								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					},
					{
						name: 'home_reversion_plan',
						functions: [
							{
								title: 'Access Details',
								path: `/client/${item.clientId}`,
								//path: `/client/${item.clientId}?service=homeReversionPlan`, TODO - add back when dialog close issue resolved
								icon: 'mdi-eye',
								icon_color: 'blue'
							}
						]
					}
				];

				return functionCase.find((it) => {
					return it.name === item.type;
				});
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 * @param item item that needs updating
			 */
			handleAction(action, item) {
				if (action.path) this.$router.push(action.path);
				if (action.function) this[action.function](item, action.functionParams);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.pagination-column ul {
		width: auto;
	}

	.shrunk {
		width: 1px;
		white-space: nowrap;
	}
</style>
