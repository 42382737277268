import { EventBus } from '@/utils';
/**
 * @namespace Utils
 * @method $http
 * @exports Utils/popupWindow
 * @description Popup window helper methods
 * @date 2020/05/06
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

const DEFAULT_CONFIG = {
	width: 600,
	height: 550
};

const _addEventListener = ({ resolve, reject, key }, interval) => {
	let handler = (e) => {
		if (e.data && Object.prototype.hasOwnProperty.call(e.data, key)) {
			// if (origin !== e.origin) {
			// 	console.debug('origin mismatch...');
			// 	return;
			// }

			if (typeof resolve === 'function') resolve(e);
		} else if (e.data && Object.prototype.hasOwnProperty.call(e.data, 'error') && typeof reject === 'function') reject(e.data);
		else console.debug(`${key} is missing...`);

		clearInterval(interval);
		window.removeEventListener('message', handler);
	};

	window.addEventListener('message', handler);

	return handler;
};

class PopupWindow {
	interval = null;

	openAuthPopup = ({ provider, authUrl, authPopupOptions }, resolve, reject) => {
		const key = provider;
		const width = authPopupOptions.width || DEFAULT_CONFIG.width;
		const height = authPopupOptions.height || DEFAULT_CONFIG.height;
		const top = screen.height / 2 - height / 2;
		const left = screen.width / 2 - width / 2;
		const onCloseErrorMessage = 'Authentication failed';

		this.openPopup({ url: authUrl, name, width, height, top, left }, { resolve, reject, key, onCloseErrorMessage });
	};

	openPopup = ({ name, url, width, height, top, left }, listenerConfig) => {
		let popupConfig = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;
		let popupWindow = window.open(url, name, popupConfig);
		let handler = null;

		this.interval = setInterval(() => {
			if ((!popupWindow || popupWindow.closed) && typeof listenerConfig?.reject === 'function' && listenerConfig?.onCloseErrorMessage) {
				clearInterval(this.interval);
				listenerConfig?.reject({ error: listenerConfig?.onCloseErrorMessage });
				if (handler) window.removeEventListener('message', handler);
			}
		}, 1000);

		if (listenerConfig) handler = _addEventListener(listenerConfig, this.interval);
	};

	open = (url, target = '_self') => {
		let popupWindow = window.open(url, target);

		if (!popupWindow || popupWindow.closed || typeof popupWindow.closed == 'undefined') {
			EventBus.$emit('popup-blocked', { url, target });
		}

		return popupWindow;
	};
}

export default PopupWindow;
