import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Application
 * @exports Service/Application
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Application {
	/**
	 * @static @name list
	 * @description Get all Applications
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('lifetime-mortgage/application/list', payload);
	}

	/**
	 * @static @name post
	 * @description Add a new application resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('lifetime-mortgage/application', payload);
	}
}
