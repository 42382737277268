<!--
	@name app-common-error
	@description Error
	@date 2020/12/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row v-if="site" no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="3">
				<img :src="site.logo.path | urlize('brand')" style="max-height: 4rem" />
			</v-col>
		</v-row>

		<v-img max-height="288" :src="`404.jpg` | urlize('generic')" />

		<v-row v-if="site" no-gutters class="py-10 px-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12">
				<template v-if="is404">
					<h1 class="text-h5 font-weight-medium">
						Well, this is embarrassing!
					</h1>
					<p class="text-body-1">
						It looks like the page you are trying to access no longer exists. If this problem persists, please let us know at {{ site.contact.number }} or
						<a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>.
					</p>
				</template>

				<template v-if="is500">
					<p class="title font-weight-medium">
						An Internal Error Has Occurred!
					</p>
					<p class="body-1">
						Sorry for the inconvenience, we're working on it.
					</p>
				</template>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-error',

		computed: {
			...mapState('CmsSite', ['site']),

			currentRouteName() {
				return this.$route.name;
			},

			is404() {
				return this.currentRouteName === 'error404';
			},

			is500() {
				return this.currentRouteName === 'error500';
			},

			image() {
				return this.currentRouteName.replace('error', '');
			}
		}
	};
</script>
