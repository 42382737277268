<!--
	@name app
	@description Main app, thats bootstrapped to html
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<div v-if="(!initialised || !routerLoaded || !documentLoaded) && !isExternalRoute" key="loading">
				<v-dialog :value="true" persistent width="300">
					<v-card>
						<v-card-text>
							Loading site...
							<v-progress-linear indeterminate color="primary" class="mt-2 mb-0" />
						</v-card-text>
					</v-card>
				</v-dialog>
			</div>
			<div v-else key="layout" class="fill-height">
				<component :is="layout" />
			</div>
		</transition>
		<app-notification ref="notification" />
		<common-dialog-version-update ref="versionUpdate" />
	</v-app>
</template>

<script>
	import Vue from 'vue';
	import { mapGetters, mapState } from 'vuex';
	import LayoutDefault from '@/component/layout/default';
	import LayoutDefaultMinimal from '@/component/layout/default-minimal';
	import LayoutDefaultAuthed from '@/component/layout/default-authed';
	import LayoutBlank from '@/component/layout/blank';
	import LayoutFactFind from '@/component/layout/fact-find';
	import LayoutSelect from '@/component/layout/select';
	import LayoutAirLaterLife from '@/component/layout/air-later-life';
	import LayoutAuthedBlank from '@/component/layout/authed-blank';
	import AppNotification from '@/component/common/base/notification';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogVersionUpdate from '@/component/common/dialog/version-update';
	import { EventBus } from '@/utils';

	export default {
		name: 'app',

		components: {
			LayoutDefault,
			LayoutDefaultMinimal,
			LayoutDefaultAuthed,
			LayoutBlank,
			LayoutFactFind,
			LayoutSelect,
			LayoutAirLaterLife,
			LayoutAuthedBlank,
			AppNotification,
			CommonDialog,
			CommonDialogVersionUpdate
		},

		data() {
			return {
				routerLoaded: false,
				documentLoaded: false,
				isExternalRoute: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['user']),
			...mapGetters('App', ['initialised']),

			layout() {
				return this.$route.meta && this.$route.meta.layout ? `layout-${this.$route.meta.layout}` : 'layout-blank';
			}
		},

		watch: {
			$route: {
				handler() {
					// Growthbook
					let attributes = {
						// Only update the `url` attribute, keep the rest the same
						...Vue.GrowthBook.getAttributes(),
						url: window.location.href,
						query: window.location.search
					};
					if (this.user.id) attributes.id = this.user.id;
					Vue.GrowthBook.setAttributes(attributes);

					this.routerLoaded = !!this.$route.name;
				},
				deep: true
			}
		},

		mounted() {
			this.isExternalRoute = window._isExternalRoute;

			document.onreadystatechange = () => {
				if (document.readyState == 'complete') {
					this.documentLoaded = true;
				}
			};

			document.addEventListener('notification', (ev) => {
				if (ev.detail.hide) this.$refs.notification.hide();
				else this.$refs.notification.show(ev.detail);
			});

			EventBus.$on('app-version-update', () => this.$refs.versionUpdate.open());
		}
	};
</script>

<style lang="scss">
	@import '@/assets/scss/global.scss';
</style>

<style lang="scss" scoped>
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.25s ease;
	}

	.fade-leave-active {
		opacity: 0;
	}

	.v-application--is-ltr ::v-deep .v-data-footer__select {
		margin-left: 0;
	}
</style>
