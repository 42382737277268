<!--
	@name app-source-result-mortgage-item-payment
	@description Lifetime mortgage product item advance
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="item.restriction && item.restriction.type === 'hard'">
			--
		</div>
		<div v-else>
			<span class="text-body-2 font-weight-medium d-block">
				£{{ item.payments.totalPayments | numFormat('0,0') }}
			</span>
			<div v-if="displayIcons" class="pb-1">
				<v-tooltip v-if="reposessionRisk" bottom content-class="text-center" max-width="300">
					<template #activator="{ on }">
						<v-icon size="20" color="indigo lighten-1" class="mx-1" style="position: relative; top: 1px;" v-on="on">
							mdi-home-alert
						</v-icon>
					</template>
					<span>
						Risk of reposession
					</span>
				</v-tooltip>
				<v-tooltip v-if="earlyRepayment" bottom content-class="text-center" max-width="300">
					<template #activator="{ on }">
						<v-icon size="18" color="success" class="mx-1" v-on="on">
							mdi-home-clock
						</v-icon>
					</template>
					<span>
						Loan repaid in {{ termDisplay(item.payments.term) }}
					</span>
				</v-tooltip>
				<v-tooltip v-if="discountedRateNotSecured && !earlyRepayment" bottom content-class="text-center" max-width="300">
					<template #activator="{ on }">
						<v-icon size="18" color="red lighten-1" class="mx-1" v-on="on">
							mdi-lock-off
						</v-icon>
					</template>
					<span>
						Your payment term does not meet the requirements to lock in the discounted rate
					</span>
				</v-tooltip>
				<v-tooltip v-if="rateIncrease" bottom content-class="text-center" max-width="300">
					<template #activator="{ on }">
						<v-icon size="18" color="red lighten-1" class="mx-1" v-on="on">
							mdi-arrow-up-bold-circle
						</v-icon>
					</template>
					<span>
						A rate increase will come into effect when monthly payments cease
					</span>
				</v-tooltip>
			</div>
			<v-tooltip bottom content-class="text-center" max-width="280">
				<template #activator="{ on }">
					<span class="text-tooltip" v-on="on" @click="openProductCostDialog(item)">
						<span
							class="ga--trigger ga--product-cost-opened text-caption"
							data-ga-id="payments-column"
						>
							View details
						</span>
					</span>
				</template>
				<span>
					<span class="d-block mb-1 text-decoration-underline">
						Monthly Payments
					</span>
					<div v-show="item.payments?.totalPayments">
						£{{ item.payments.totalPayments | numFormat('0,0') }} total
					</div>
					<div v-show="contractedPayments">
						£{{ contractedPayments | numFormat('0,0') }} contracted
					</div>
					<div v-show="voluntaryPayments">
						£{{ voluntaryPayments | numFormat('0,0') }} voluntary
					</div>
					<div v-show="item.payments?.term">
						Payment term: {{ termDisplay(item.payments.term) }}
					</div>
					<span class="d-block mt-1 font-weight-bold">
						Click for full breakdown
					</span>
				</span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>
	import PaymentsHelper from '@/utils/PaymentsHelper';

	export default {
		name: 'app-source-result-mortgage-item-payment',

		components: {},

		props: {
			item: { type: Object, required: true }
		},

		computed: {
			/**
			 * @name contractedPayments
			 * @description TODO
			 */
			 contractedPayments() {
				let term = this.item?.payments?.contract?.terms?.find((t) => t.start == 1);
				if (!term) return false;
				return term.amount;
			},

			/**
			 * @name voluntaryPayments
			 * @description TODO
			 */
			 voluntaryPayments() {
				let term = this.item?.payments?.voluntary?.terms?.find((t) => t.start == 1);
				if (!term) return false;
				return term.amount;
			},

			/**
			 * @name reposessionRisk
			 * @description TODO
			 */
			reposessionRisk() {
				const loanPaymentOptions = [
					'90649bf3-09be-45c4-aaf9-6266dbb4bfc7', //Full Interest Serviced Mortgage
					'4a38fc40-e285-4019-a486-03b623a65066', //Capital and Interest Mortgage
					'5f018d6a-4f09-45de-be42-3c140ad8317a' //Mandatory Payments
				];
				return loanPaymentOptions.includes(this.item?.loanPaymentTypeId);
			},

			/**
			 * @name earlyRepayment
			 * @description TODO
			 */
			earlyRepayment() {
				return this.item.payments?.earlyRepayment;
			},

			/**
			 * @name discountedRateNotSecured
			 * @description TODO
			 */
			discountedRateNotSecured() {
				if(!this.item.payments.term) return false;
				if(!this.item.monthlyRepaymentsDiscount) return false;
				if(!this.item.monthlyRepaymentsTermAfterWhichRateIsSecured) return false;
				return this.item.payments.term < this.item.monthlyRepaymentsTermAfterWhichRateIsSecured;
			},

			/**
			 * @name rateIncrease
			 * @description TODO
			 */
			rateIncrease() {
				if(this.discountedRateNotSecured) return false;
				return this.item?.payments?.contract?.rateIncrease;
			},

			/**
			 * @name displayIcons
			 * @description TODO
			 */
			displayIcons() {
				return this.reposessionRisk || this.earlyRepayment || this.discountedRateNotSecured || this.rateIncrease;
			}
		},

		methods: {
			/**
			 * @name openProductCostDialog
			 * @description TODO
			 * @param {TODO} item TODO
			 */
			openProductCostDialog(item) {
				this.$emit('open-product-cost-dialog', item);
			},

			termDisplay(term) {
				return PaymentsHelper.covertDecimalYearsToYearsMonths(term, 'shortString');
			}
		}
	};
</script>
