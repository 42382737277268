<!--
	@name common-base-downloads-section
	@description Show recent downloads on the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name" :header-class="headerClass">
		<template #header>
			{{ title }}
		</template>
		<template #body>
			<slot name="copy" />
			<v-skeleton-loader v-if="!recentDownloads.length" type="list-item-three-line@2" class="pa-0 ma-0" />
			<common-base-download-items v-else :theme="theme" :items="recentDownloads" :type="type" :display-all-tags="displayAllTags" />
		</template>
	</common-structure-section>
</template>

<script>
	import { orderBy } from 'lodash';
	import { mapGetters } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonBaseDownloadItems from '@/component/common/base/download-items';

	export default {
		name: 'common-base-downloads-section',

		components: { CommonStructureSection, CommonBaseDownloadItems },

		props: {
			items: { type: Array, default: () => [] },
			copy: { type: String, default: null },
			headerClass: { type: String, default: 'blue-grey lighten-4' },
			numberOfItems: { type: Number, required: false, default: 4 },
			promoted: { type: Boolean, default: false },
			tags: { type: String, default: null },
			theme: { type: Array, default: () => ['blue-grey lighten-5', 'grey lighten-4'] },
			title: { type: String, default: 'Resources' },
			displayAllTags: { type: Boolean, default: false },
			type: { type: String, default: 'download' }
		},

		computed: {
			...mapGetters('Account', ['membership']),

			recentDownloads() {
				let arr = orderBy(this.items, 'created', 'desc');
				return arr.slice(0, this.numberOfItems);
			}
		}
	};
</script>
