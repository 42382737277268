// plugins/i18n.js
export default {
	install: (app, options) => {
		window._iub = window._iub || {};
		window._iub.csConfiguration = options;
		const iubenda = document.createElement('script');
		iubenda.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
		document.head.appendChild(iubenda);
	}
};
