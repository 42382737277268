/**
 * @class @name Content
 * @description Exposes menu store module
 */

import router from '@/router';

export default class App {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			initialised: false
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			initialised: (state) => state.initialised
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			setInitialised(state) {
				state.initialised = true;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			async init({ dispatch, commit }) {
				const requests = [dispatch('CmsSite/loadSite', null, { root: true })];

				if (router.history.pending?.meta?.public) requests.push(dispatch('CmsMenu/loadFrontMenu', null, { root: true }));
				else requests.push(dispatch('CmsMenu/loadAuthedMenu', null, { root: true }));

				await Promise.all(requests);
				await dispatch('CmsContent/loadContent', 'club', { root: true });

				commit('setInitialised');
			}
		};
	}
}
