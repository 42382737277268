<!--
	@name app-awards
	@description Awards page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img
			:src="'logo/brand/later-life-lending-awards-bg.png' | urlize"
			style="border-bottom: 20px solid #1eb0e1"
			:height="$vuetify.breakpoint.mdAndUp ? '400' : ''"
		>
			<div id="overlay" />
			<v-container style="position: relative" fill-height>
				<v-row class="px-10">
					<v-col class="align-content-center justify-start">
						<div class="text-h2 font-weight-medium white--text">
							Later Life Lending <br />
							Awards 2024
						</div>
					</v-col>
					<v-col class="align-content-center" style="justify-items: end;">
						<v-img style="width:12rem" :src="'logo/brand/later-life-lending-awards-icon.png' | urlize" />
					</v-col>
				</v-row>
			</v-container>
		</v-img>

		<!-- About Us -->
		<div>
			<v-container>
				<div class="text-center d-flex flex-column align-center py-8" :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }" style="max-width: 1200px; margin: 0 auto;">
					<div class="text-h4 font-weight-bold mb-6">
						About the awards
					</div>
					<p class="text-h6">
						As the market leading distribution channel and platform for later life lending professionals, Air is thrilled to announce the third Later Life Lending Awards, taking place at the iconic Allianz Stadium – Twickenham on the 16th of January 2025.
					</p>
					<p class="text-body-1">
						This prestigious event will honour and celebrate the most exceptional advisers, lenders, networks, and stakeholders from across the industry.
						Bringing together a diverse array of later life propositions, the awards highlight excellence across the sector, reflecting the evolving landscape and the industry's forward-thinking definition of later life lending.
					</p>
					<p class="text-body-1 mb-6">
						Click below to access our awards software for nominations, voting, submissions and updates.
					</p>
					<v-btn href="https://laterlifelending.awardsplatform.com/" target="_blank" color="info">
						Access Awards Platform
					</v-btn>
				</div>
			</v-container>
		</div>

		<v-divider />

		<!-- Benefits -->
		<div :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<div class="text-h4 text-center">
					<p>2024 Categories</p>
				</div>

				<v-row no-gutters class="justify-center mt-4" :class="{ 'mt-6': $vuetify.breakpoint.mdAndUp }">
					<v-col v-for="(item, i) in awards" :key="i" cols="12" md="4" class="d-flex flex-column">
						<v-card
							class="ma-6 text-center flex d-flex flex-column"
							elevation="2"
							color="grey lighten-2"
							hover
							@click="openDialog(i)"
						>
							<v-system-bar :class="`background-rotation-manual--${i%5+1}`" height="16" />
							<div class="pa-3">
								<v-card-text class="flex px-2 mt-4 py-0">
									<div class="text-h6 justify-center">
										{{ item.category }}
									</div>
								</v-card-text>

								<v-card-actions class="justify-center">
									<v-btn text class="text-capitalize grey--text text--darken-3">
										See Description
									</v-btn>
								</v-card-actions>
							</div>

							<v-dialog v-model="dialog[i]" max-width="500px" transition="dialog-transition">
								<v-card class="pa-2">
									<v-card-text>
										<v-btn icon style="position: absolute; right: 18px; top: 18px" @click="closeDialog(i)">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<div class="text-h6 justify-center secondary--text" style="line-height: 1">
											{{ item.category }}
										</div>
										<div class="text-body-2 mt-4">
											{{ item.description }}
										</div>
									</v-card-text>
								</v-card>
							</v-dialog>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<v-divider class="mt-6" />

		<div class="mt-10 mb-8 text-h4 text-center">
			Awards Timeline
		</div>
		<v-timeline class="pb-10">
			<v-timeline-item v-for="(date, index) in dates" :key="date.id" fill-dot small class="py-3" :class="{'text-right':index%2!==0}">
				<p class="text-h5 mb-1">
					{{ date.title }}
				</p>
				<p class="text-body-1 font-weight-light mb-0">
					{{ date.date }}
				</p>
			</v-timeline-item>
		</v-timeline>

		<!-- 2023 -->
		<div style="border-bottom: 20px solid #1eb0e1">
			<div class="generic-gradient">
				<v-container class="fill-height text-center d-flex flex-column align-center py-16">
					<v-col class="d-flex flex-column align-center">
						<div class="text-h4 font-weight-bold white--text text-center">
							The 2023 Winners
						</div>
						<p class="mt-6 text-body-1 white--text">
							Congratulations from Air to our 2023 winners. It's an honour to recognise you and your firms for your contribution to the later life lending market.
						</p>
						<v-btn to="/awards-2023" class="mt-4" color="info">
							View winners
						</v-btn>
						<img class="mt-6" :src="'events/AirAwards2023-192-ALL-WINNERS-1-scaled-e1701948365886.jpg' | urlize" style="max-width: 861px" />
					</v-col>
				</v-container>
			</div>
		</div>

		<!-- 2022 -->
		<div>
			<div class="generic-gradient">
				<v-container class="fill-height text-center d-flex flex-column align-center py-16">
					<v-col class="d-flex flex-column align-center">
						<div class="text-h4 font-weight-bold white--text text-center">
							The 2022 Winners
						</div>
						<p class="mt-6 text-body-1 white--text">
							A huge congratulations to our winners from the 2022 Air Awards night, and thanks for all the continued work you've done this past year.
						</p>
						<v-btn to="/awards-2022" class="mt-4" color="info">
							View winners
						</v-btn>
						<img class="mt-6" :src="'events/2022-Awards-winners.png' | urlize" style="max-width: 861px" />
					</v-col>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-awards',

		components: {},

		data() {
			return {
				dialog: [],
                dates: [
                    {
                        id: 1,
                        title: 'Nominations Open',
                        date: '18th October - 5th November'
                    },
                    {
                        id: 2,
                        title: 'Longlist Announced',
                        date: '6th November'
                    },
                    {
                        id: 3,
                        title: 'Voting Period',
                        date: '6th November – 18th November'
                    },
                    {
                        id: 4,
                        title: 'Shortlist Announced',
                        date: '19th November'
                    },
                    {
                        id: 5,
                        title: 'Shortlist Submissions',
                        date: '19th November - 6th December'
                    },
                    {
                        id: 6,
                        title: 'Judging Period',
                        date: '9th December - 12th December'
                    },
                    {
                        id: 7,
                        title: 'Finalists Informed',
                        date: '20th December'
                    },
                    {
                        id: 8,
                        title: 'Awards Evening',
                        date: '16th January'
                    }
                ],
				awards: [
                    {
						category: 'Best adviser support specialist at a lender',
						winner: 'Pauline Dovey',
						presentedBy: 'Mike Millar (more2life)',
						image: 'events/Best-Adviser-Support-Individual-Pauline-Dovey-2048x1365.jpg',
						description:
							'This category recognises individuals working within a lender for providing exceptional personalised assistance to financial advisers, enabling them to offer top-tier services to their clients, enhancing efficiency, compliance, and overall client satisfaction.'
					},
                    {
						category: 'Outstanding legal services in later life lending',
						winner: 'Equilaw',
						presentedBy: 'Lisa Windley (Responsible Lending)',
						image: 'events/Best-Legal-Firm-Equilaw-2048x1365.jpg',
						description:
							'Independent legal advice is essential in the later life lending sector. This award honours the conveyancers who deliver exceptional, personalised legal guidance, tailored to meet the unique needs of each client. It recognises the firms that have set themselves apart by providing high-quality, client-focused support in an increasingly complex landscape.'
					},
					{
						category: 'Outstanding support firm for later life lending',
						winner: 'Pure Retirement',
						presentedBy: 'Mike Taylor (Air)',
						image: 'events/Best-Adviser-Support-Firm-Pure-Retirement-2048x1365.jpg',
						description:
							'In a rapidly evolving and complex market, offering advisers the right support to stay ahead has been crucial. From the latest product innovations and advancements in technology to navigating regulatory changes, firms have had to continually adapt and enhance their support. This award recognizes the company that has excelled in providing advisers with the tools and resources needed to thrive in the later life lending sector. Who has truly set the standard?'
					},
					{
						category: 'Best Large Advisory Firm (+5)',
						winner: 'Viva Retirement Solutions',
						presentedBy: 'Kelly Steel (Gilroy Steel)',
						image: 'events/Best-Large-Advisory-Firm-5-Viva-Retirement-Solutions-2048x1365.jpg',
						description:
							'This category is designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions, going above and beyond to improve every touchpoint.'
					},
					{
						category: 'Best Small Advisory Firm (1-4)',
						winner: 'Lifetime Equity Release',
						presentedBy: 'Nick Startford (Poynton Law)',
						image: 'events/Best-Small-Advisory-Firm-1-5-Lifetime-Equity-Release-2048x1365.jpg',
						description:
							'This category recognises the effort of smaller equity release advisory firms in their own right, who make a big impact across the later life lending industry.'
					},
					{
						category: 'Best Later Life Innovation',
						winner: 'LiveMore',
						presentedBy: 'Kieran Mullen (Canada Life)',
						image: 'events/Best-Later-Life-Innovation-LiveMore-Capital-2048x1365.jpg',
						description:
							'This category recognises firms striving to support advisers by quickly evolving and adapting to the changing market condition. Whether it’s a product, a new feature, an enhanced service or an online tool, which firm has delivered the greatest innovation to the market this year?'
					},
					{
						category: 'Best Later Life Lending Provider',
						winner: 'Legal & General',
						presentedBy: 'Leon Diamond (LiveMore)',
						image: 'events/Best-Later-Life-Provider-Legal-General--2048x1365.jpg',
						description:
							'This award recognises providers who have showcased and championed later life lending excellence. These have been at the forefront of supporting advisers with their product and service offering.'
					},
					{
						category: 'Network of the year',
						winner: 'HL Partnership',
						presentedBy: 'Kay Westgarth (Standard Life Home Finance)',
						image: 'events/Best-Network-for-Later-Life-Lending-HL-Partnership-2048x1365.jpg',
						description:
							'This award goes to the Network that has made the biggest impact in later life lending this year, through supporting their members with education, resources and tools to enable them to offer their clients more options to meet their needs.'
					},
					{
						category: 'BDM of the Year',
						winner: 'Fran Green',
						presentedBy: 'Nick Birdseye (Legal & General)',
						image: 'events/BDM-of-the-Year-Fran-Green-2048x1365.jpg',
						description:
							'This category recognises a Business Development Manager who has demonstrated their skill, market expertise and knowledge to support advisers to navigate the changes impacting the industry and done this with passion and a positive attitude.'
					},
					{
						category: 'Later Life Adviser of the Year',
						winner: 'Stuart Borthwick',
						presentedBy: 'Scott Burman (Pure Retirement)',
						image: 'events/Later-Life-Adviser-of-the-Year-Stuart-Borthwick-2048x1365.jpg',
						description:
							'This award recognises the individual who is the leading light when it comes to advice delivery, customer service and continuing professional development – a true local hero of their community. It is one of our most coveted award categories.'
					},
					{
						category: 'New adviser of the Year',
						winner: 'Haydn Garnett',
						presentedBy: 'Jade Barry (Equilaw)',
						image: 'events/Newcomer-of-the-year-Haydn-Garnett-2048x1365.jpg',
						description:
							'This award celebrates the outstanding achievements of advisers in their first year within the later life lending industry. These individuals have not only navigated significant challenges but have also demonstrated exceptional resilience, professionalism, and a commitment to excellence. Who has truly made their mark as a rising star in the industry?'
					},
					{
						category: 'Underwriter of the Year',
						winner: 'Steve Miller',
						presentedBy: 'Emma Graham (Hodge)',
						image: 'events/Underwriter-of-the-year-Steve-Miller-2048x1365.jpg',
						description:
							'This award will recognise the best of the best when it comes to lending criteria expertise and support, these underwriters are the unsung experts of our industry and key gatekeepers to lending success.'
					},
                    {
						category: 'Best customer support specialist at an advisory firm',
						winner: 'David Forsdyke',
						presentedBy: 'Peter Barton (Barton Law)',
						image: 'events/Market-Champion-of-the-Year-David-Forsdyke-2048x1365.jpg',
						description:
							'This category recognises individuals within an advisory firm who demonstrate exceptional dedication to providing personalised, efficient, and professional support to clients. Their efforts enhance client experience, streamline operations, and contribute to the overall success of the firm.'
					}
				],
				categories: [
					{
						name: 'Best Later Life Provider',
						description:
							'This award will be open to all providers of later life products that meet the new industry definition of ‘later life lending’ (lifetime mortgages, later life mortgage and Retirement Interest Only mortgages). This category will also be open to Home Reversion providers.'
					},
					{
						name: 'Best Legal Firm',
						description:
							'Independent legal advice is critically important in our industry and this award is designed to recognise the very best later life lending conveyancers, who either through their specialist conveyancing offering or providing of specialist legal advice to clients stands head and shoulders above others.'
					},
					{
						name: 'Best Large Advisory Firm (+5)',
						description:
							'Our Best Advisory Firm award categories are designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions. This category is for firms that have more than five qualified later life advisers working within them.'
					},
					{
						name: 'Best Small Advisor Firm (1-5)',
						description:
							'Our Best Advisory Firm award categories are designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions. This category is for firms that have between 1 and 5 qualified later life advisers working within them.'
					},
					{
						name: 'Later Life Advisor of the Year',
						description:
							'This award recognises the individual in our industry who is the leading light when it comes to advice delivery, customer service and continuing professional development – a true local hero of their community. It is one of our most coveted award categories and competition is fierce!'
					},
					{
						name: 'Newcomer of the Year',
						description:
							'The future of our market depends on more advisers and support staff and qualified, experienced in later life lending joining this industry. This award is specifically to recognise the achievements of those in this first year of their later life profession.'
					},
					{
						name: 'Market Champion of the Year',
						description:
							'The success of our industry depends on many things, including great products, features and of course expert advice. It also hinges on individuals and firms who work hard to promote the great customer outcomes that later life lending can bring and this award is designed to recognise the very best of our industry’s flag wavers.'
					},
					{
						name: 'Underwriter of the Year',
						description:
							'Underwriters are the unsung experts of our industry and key gatekeepers to lending success. This award will recognise the best of the best when it comes to lending criteria expertise and support.'
					},
					{
						name: 'BDM of the Year',
						description:
							'A great BDM is worth their weight in gold, especially during tough market conditions, and our award for the BDM of the Year will demonstrate just how much their skill, market expertise and knowledge is appreciated by advisers.'
					},
					{
						name: 'Best Network or Club',
						description:
							'Networks are fast becoming the engine room of growth in the later life lending market as more and more advisers from other sectors of financial services engage with the products and propositions on offer. Our award goes to the Network that has made the biggest impact in later life lending this year.'
					},
					{
						name: 'Best Later Life Lending Innovation',
						description:
							'Whether it’s a product, a feature, a service, an online tool or other new piece of later life lending kit, which firm – or individual – has delivered the greatest innovation to the market this year?'
					},
					{
						name: 'Best Adviser Support - Individual',
						description:
							'In a complex and fast-moving market, providing advisers with support to help them keep their finger on the pulse of the latest products, technology and regulatory requirements is crucial. Which member of your team does this best?'
					},
					{
						name: 'Best Adviser Support - Firm',
						description:
							'In a complex and fast-moving market, providing advisers with support to help them keep their finger on the pulse of the latest products, technology and regulatory requirements is crucial. Who does this best? A lender? Service provider? This award will give us the answer!'
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {
			openDialog(i) {
				this.$set(this.dialog, i, true);
			},
			closeDialog(i) {
				this.$set(this.dialog, i, false);
			}
		}
	};
</script>

<style lang="scss" scoped>
	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}
</style>
