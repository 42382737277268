<!--
	@name app-source-quick-source
	@description Sourcing Page
	@date 2020/02/27
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-form-section ref="quickSource" title="Quick Source" :item="quickSource" :form="form('quick-source').value" @set-validation="setValidation" />
		<div class="text-center">
			<v-btn v-show="form('quick-source').value" large color="primary" :loading="isSubmitting" @click="submitQuickSource">
				Proceed To Products
			</v-btn>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonFormSection from '@/component/common/form/section';

	export default {
		name: 'app-source-quick-source',

		components: {
			CommonFormSection
		},

		props: {
			process: { type: Object, default: null }
		},

		data() {
			return {
				isSubmitting: false,
				forms: null,
				quickSource: {},
				validations: {
					quickSource: false
				}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['form']),

			isValid() {
				return !Object.keys(this.validations).find((key) => !this.validations[key]);
			}
		},

		created() {
			this.loadForm('quick-source').then(() => {
				if (this.process) this.quickSource = this.process.data;
				else this.setDefaultValues(this.form('quick-source').value);
			});
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('LifetimeMortgageSourcing', ['submitQuickSourcing']),

			/**
			 * @name submitQuickSource
			 * @description Submit quick quote form to the server
			 */
			async submitQuickSource() {
				if (!this.isValid) {
					// even though we already have the validation result, this still needs to be triggered to show the validation errors on the form
					this.$refs.quickSource.validateForm();
					return;
				}

				if ((!this.membership('club') || !this.membership('club').request) && this.site.usesMortgageClub) {
					this.$emit('club-registration-required', 'quick-source');
					return;
				}

				const payload = {
					process: this.quickSource,
					matterType: 'lifetime_mortgage',
					processType: 'quick_source'
				};

				try {
					this.isSubmitting = true;
					const { data } = await this.submitQuickSourcing(payload);
					this.$router.push(`/results/${data.processId}${data.iterationId ? '?iteration=' + data.iterationId : ''}`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'An error occured');
				}

				this.isSubmitting = false;
			},

			/**
			 * @name setValidation
			 * @description set applicant
			 * @param {Object} validation
			 */
			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			/**
			 * @name setDefaultValues
			 * @param {Array} form
			 */
			setDefaultValues(form) {
				form.map((field) => {
					if (this.quickSource && typeof field.valueDefault !== 'undefined' && this.quickSource[field.name] !== null && typeof this.quickSource === 'object') {
						this.quickSource[field.name] = field.valueDefault;
					}
				});
			}
		}
	};
</script>
