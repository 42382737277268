<!--
	@name app-client-dashboard-note
	@description Client Notes
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section class="mt-0">
			<template #header>
				<span>Notes</span>

				<v-spacer />

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn color="grey darken-3" :disabled="!client" icon v-on="on" @click="openAddNote">
							<v-icon>mdi-pencil-plus</v-icon>
						</v-btn>
					</template>
					<span>Add New Note</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn color="grey darken-3" icon :disabled="!items || !items.length" v-on="on" @click="viewAllClientNotes">
							<v-icon>mdi-notebook</v-icon>
						</v-btn>
					</template>
					<span>View All Notes</span>
				</v-tooltip>
			</template>
			<template #body>
				<v-skeleton-loader v-if="!notes" type="article" class="px-0" />

				<v-timeline v-else-if="notes.length > 0" dense class="small pa-0">
					<v-timeline-item v-for="(item, index) in notes" :key="index" small fill-dot class="py-2 remove-dot">
						<template #icon>
							<img :src="'icon/note.png' | urlize" width="35px" style="position: relative; top: -6px" />
						</template>

						<v-card class="elevation-0 pa-1 note-background" color="#FFF3B0">
							<v-card-text class="pb-0 line-clamp">
								{{ item.description }}
							</v-card-text>

							<v-card-actions v-if="item.created" class="pa-0 pt-3">
								<v-card-text class="caption pt-0">
									Added: {{ item.created | moment('from', 'now') }}
								</v-card-text>
							</v-card-actions>
							<v-card-actions v-if="item.add" class="pa-0 pt-3">
								<v-card-text class="caption pt-0">
									<a class="black--text text-decoration-underline" @click="openAddNote">
										Click here to add a client note
									</a>
								</v-card-text>
							</v-card-actions>
						</v-card>
					</v-timeline-item>
				</v-timeline>

				<div v-else-if="notes.length === 0" class="text-body-1">
					<p class="text-body-1 mb-4">
						Notes can be added against this Client for future reference.
					</p>
					<v-btn small color="secondary" dark @click="openAddNote">
						Add a Client Note
					</v-btn>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-client-dashboard-note',

		components: { CommonStructureSection },

		props: {
			items: { type: Array, default: null },
			client: { type: Object, default: null }
		},

		computed: {
			notes() {
				if (this.items?.length) return this.items;
				return [{
					description: 'Notes can be added against this Client for future reference.',
					add: true
				}];
			}
		},

		methods: {
			openAddNote() {
				this.$emit('add-note');
			},

			viewAllClientNotes() {
				this.$emit('view-all-client-notes');
			}
		}
	};
</script>

<style scoped lang="scss">
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.v-timeline {
		&.v-timeline--dense:not(.v-timeline--reverse):before {
			left: calc(30px - 1px);
		}

		&.v-timeline--dense ::v-deep .v-timeline-item__body {
			max-width: calc(100% - 60px);
		}

		::v-deep .v-timeline-item__divider {
			min-width: 60px;
			margin-right: 8px;
		}
	}

	.note-background {
		background: linear-gradient(150deg, #fff3b0 0%, #f6e27f 60%);
	}

	.line-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
</style>
