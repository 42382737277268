<!--
	@name app-firm-dialog-restriction
	@description trading style restriction dialog component
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<common-dialog ref="restrictionDialog" :max-width="800">
			<template #header>
				Add New Restriction
			</template>

			<template #fixed>
				<v-text-field v-model="newTerm" label="Restriction Keyword" hide-details="auto" />
				<div class="d-flex justify-center my-4">
					<v-btn color="success" :loading="loading" @click="addTerm">
						Add
					</v-btn>
				</div>
			</template>

			<template #body>
				<div>
					<v-chip v-for="term in terms" :key="term" :value="term" close @click:close="removeTerm(term)">
						{{ term }}
					</v-chip>
				</div>
			</template>

			<template #footer>
				<div class="d-flex justify-center my-4">
					<v-btn color="success" :loading="loading" @click="submit">
						Submit
					</v-btn>
				</div>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-firm-dialog-restriction',

		components: {
			CommonDialog,
			CommonDialogConfirm
		},

		data() {
			return {
				newTerm: '',
				providerId: null,
				tradingStyle: null,
				terms: [],
				loading: false
			};
		},

		methods: {
			...mapActions('AccountOrganisationTradingStyle', ['updateSourcingPanel']),

			/**
			 * @name addTerm
			 * @description add restriction term to provider
			 */
			addTerm() {
				if(this.newTerm) {
					this.terms.push(this.newTerm);
					this.newTerm = '';
				}
			},

			/**
			 * @name removedTerm
			 * @description add restriction term to provider
			 */
			 removeTerm(string) {
				const index = this.terms.indexOf(string);
				if (index !== -1) {
					this.terms.splice(index, 1);
				}
			},

			/**
			 * @name open
			 * @description open providers dialog
			 */
			 open(tradingStyle, id) {
				this.tradingStyle = tradingStyle;
				this.providerId = id;
				this.terms = [];
				if(this.tradingStyle.panelData?.reject?.[id]) {
					this.terms = this.tradingStyle.panelData.reject[id];
				}
				this.loading = false;
				this.$refs.restrictionDialog.open();
			},

			/**
			 * @name submit
			 * @description submit new providers
			 */
			submit() {
				this.$refs.confirm
					.open('Add restrictions', `Are you sure you want to add these restrictions?`)
					.then(() => {
						this.loading = true;

						let terms = this.tradingStyle.panelData.reject || {};
						terms[this.providerId] = this.terms;

						this.updateSourcingPanel({
									id: this.tradingStyle.id,
									payload: {
										sourcing: this.tradingStyle.panelData.panel.sourcing || [],
										reject: terms
									}
								})
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Providers have been added successfully'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst adding Providers to this enquiry'))
							.finally(() => {
								this.$refs.restrictionDialog.close();
								this.$emit('submitted');
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
