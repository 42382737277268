import { CmsNews } from '@/service';
import { orderBy } from 'lodash';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class News {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			newsList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name membership
			 * @description get non member only list
			 * @param {Object} state
			 * @return {Object} TODO
			 */
			nonMemberNewsList: (state) => state.newsList.filter((n) => n.memberOnly === false),
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setNewsList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setNewsList: (state, data) => (state.newsList = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadNewsList
			 * @param {function} commit
			 */
			async loadNewsList({ commit, state, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				if (state.newsList.length > 0) return;
				let { data } = await CmsNews.list(domain);
				const news = orderBy(data.data, ['created'], ['desc']);
				commit('setNewsList', news);
			},

			/**
			 * @async @name loadNewsItem
			 * @param {function} commit
			 * @param {string} id
			 */
			async loadNewsItem(context, id) {
				return CmsNews.get(id);
			}
		};
	}
}
