import { GrowthBook } from "@growthbook/growthbook";

export default {
	async install(Vue) {
		Vue.GrowthBook = new GrowthBook({
			apiHost: "https://cdn.growthbook.io",
			clientKey: "sdk-FjzosEQVUCHkiXxS",
			enableDevMode: process.env.NODE_ENV !== "production",
			subscribeToChanges: true,
			attributes: {
				"id": 1,
				"url": window.location.href,
				"path": window.location.pathname,
				"host": window.location.hostname,
				"query": window.location.search
			},
			trackingCallback: (experiment, result) => {
				// Google Analytics event
				window.dataLayer.push({
					event: 'experimentViewed',
					experimentId: experiment.key,
					variationId: result.key,
					featureId: result.featureId
				});
			}
		});

		await Vue.GrowthBook.loadFeatures({
			// If the network request takes longer than this (in milliseconds), continue
			// Default: `0` (no timeout)
			timeout: 2000
		});
	}
};