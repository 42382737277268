import { Client, ClientHealth, ClientUserConnect, ClientAggregated } from '@/service';

/**
 * @class @name Client
 * @description Exposes menu store module
 */
export default class ClientStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			clientList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name client
			 * @description used to get filtered clients from store
			 * @param {Object} state
			 */
			client: (state) => (id) => {
				return state.clientList.find((i) => i.id === id) || {};
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setClients
			 * @param {Object} state
			 * @param {Object} data
			 */
			setClientList(state, data) {
				state.clientList = data;
			},

			deleteClient(state, clientId) {
				state.clientList = state.clientList.filter((client) => client.id !== clientId);
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadClientList
			 * @param {Object} store
			 * @param {Object} options
			 */
			async loadClientList(context, payload) {
				const response = await Client.list({
					order: {
						property: 'updated',
						direction: 'desc'
					},
					...payload
				});

				let clientList = response.data.data.map((i) => ({ ...i, nameFull: `${i.nameTitle} ${i.nameGiven}${i.nameMiddle ? i.nameMiddle : ' '}${i.nameFamily}` }));
				return clientList;
			},

			/**
			 * @async @name loadAggregatedClientList
			 * @param {function} commit
			 * @param {Number} payload
			 */
			loadAggregatedClientList(context, payload) {
				return ClientAggregated.list(payload);
			},

			/**
			 * @async @name loadClient
			 * @param {function} commit
			 * @param {Number} id
			 */
			loadClient(context, id) {
				return Client.get(id);
			},

			/**
			 * @async @name addClient
			 * @param {function} commit
			 * @param {Object} client
			 */
			addClient(context, client) {
				return Client.post(client);
			},

			/**
			 * @async @name deleteClient
			 * @param {function} commit
			 * @param {Number} id
			 */
			deleteClient(context, clientId) {
				return Client.delete(clientId);
			},

			/**
			 * @async @name addStatus
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async addStatus(context, payload) {
				return await Client.patch(payload);
			},

			/**
			 * @async @name userConnect
			 * @param {function} commit
			 * @param {Number} id
			 */
			getUserConnect(context, payload) {
				return ClientUserConnect.post(payload);
			},

			/**
			 * @async @name removeClientHealth
			 * @param {function} commit
			 * @param {Number} id
			 */
			removeClientHealth(context, clientId) {
				return ClientHealth.delete(clientId);
			}
		};
	}
}
