<!--
	@name app-source-result-dialog-filter-confirm-close
	@description Confirm close filters
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" color="warning" :component="$options.name">
		<template #header>
			Are you sure?
		</template>

		<template #body>
			<div>
				<p class="text-subtitle-1 mb-0">
					You will lose any unsaved changes if you close the filter panel without submitting your changes.
				</p>
			</div>
		</template>

		<template #footer>
			<div class="pa-4">
				<v-btn color="primary" class="mr-4" @click="apply">
					Apply Changes
				</v-btn>
				<v-btn color="error" class="mr-4" @click="close">
					CLose without applying
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-filter-confirm-close',

		components: { CommonDialog },

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open() {
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description close the dialog
			 */
			close() {
				this.$refs.dialog.close();
				this.$emit('close');
			},

			/**
			 * @name apply
			 * @description apply changes
			 */
			apply() {
				this.$refs.dialog.close();
				this.$emit('apply');
			}
		}
	};
</script>
