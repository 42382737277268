import { $http } from '@/utils';

/**
 * @namespace Service
 * @class HomeReversionPlan
 * @exports Service/HomeReversionPlan
 * @description API HomeReversionPlan Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class HomeReversionPlan {
	/**
	 * @static @name list
	 * @description Get a list of referrals
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('home-reversion-plan/list', payload);
	}

	/**
	 * @static @name post
	 * @description Add a new HomeReversionPlan resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('home-reversion-plan', payload);
	}
}
