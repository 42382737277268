import Reward from '@/component/app/reward';
import Summary from '@/component/app/reward/summary';
import History from '@/component/app/reward/history';
import Earn from '@/component/app/reward/earn';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'airRewards',
		path: '/rewards',
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		component: Reward,
		children: [
			{
				name: 'airRewards.summary',
				path: '',
				meta: { layout: 'default-authed' },
				component: Summary
			},
			{
				name: 'airRewards.history',
				path: 'history',
				meta: { layout: 'default-authed' },
				component: History
			},
			{
				name: 'airRewards.earn',
				path: 'earn',
				meta: { layout: 'default-authed' },
				component: Earn
			}
		]
	}
];
