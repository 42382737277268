<!--
	@name app-download
	@description Show downloads page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Resources
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<p class="text-h5 mb-2 font-weight-regular">
						Discover more with <span class="font-weight-bold">Air Resources</span>
					</p>
					<p class="text-body-1 mb-0">
						<span class="font-weight-bold"> Here you will find a range of useful resources split into 2 sections: Documents and Consumer Duty. </span>
						Both sections host a variety of documents that you can view or download to support with: Product sourcing and specification, Proc Fees, Consumer Duty
						and much more.
					</p>
				</div>

				<v-tabs background-color="blue-grey lighten-4" color="black" class="elevation-2 mt-6" light fixed-tabs>
					<v-tabs-slider color="accent" />

					<v-tab>Documents</v-tab>
					<v-tab>Consumer Duty</v-tab>

					<v-tab-item class="pa-6">
						<p class="text-body-1">
							Here you will find useful Documents including Downloadable Calculators, Information Guides, Fact-find documents and other downloads - please read
							carefully before downloading.
						</p>
						<app-download-document />
					</v-tab-item>
					<v-tab-item class="pa-6">
						<p class="text-body-1">
							In this section you will find links to Consumer Duty related documents designed to help firm principals meet the requirements of the new regulations,
							including the new product information sheets from lenders. For further reading some lenders have provided links which will redirect through to
							individual document stores.
						</p>
						<app-download-consumer-duty />
					</v-tab-item>
				</v-tabs>
			</v-col>

			<v-col class="py-0" cols="12" md="4">
				<v-card class="d-flex flex-column mb-6 flex-fill flex-grow-1 flex-shrink-0" flat tile>
					<v-card-title class="white--text text-body-1 font-weight-medium justify-left py-3 background-rotation-manual--2">
						Insights Hub
					</v-card-title>
					<v-card-text class="pa-5 pb-0 ma-0">
						<p class="text-body-1 mb-0">
							Through our Insights hub you can explore our practical resources from the latest trends in the industry and products, to tips on talking equity release
							with your clients - so you can create opportunities for your business and keep up to date with an ever evolving market.
						</p>
					</v-card-text>
					<v-card-actions class="pa-5">
						<v-btn href="https://airlaterlife.co.uk/insights-hub/" target="_blank" color="blue-grey lighten-4" class="px-4">
							visit the insights hub
						</v-btn>
					</v-card-actions>
				</v-card>

				<common-base-downloads-section
					v-if="downloads('Promoted').length"
					class="mt-6"
					:items="downloads('Promoted')"
					:tags="'Promoted'"
					:title="'Promoted Downloads'"
					:header-class="'background-rotation-manual--5 white--text'"
				/>

				<common-promotion-advisor-advert class="mt-6" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonBaseDownloadsSection from '@/component/common/base/downloads-section';
	import AppDownloadDocument from '@/component/app/download/document';
	import AppDownloadConsumerDuty from '@/component/app/download/consumer-duty';

	export default {
		name: 'app-download',

		components: { CommonPromotionAdvisorAdvert, CommonBaseDownloadsSection, AppDownloadDocument, AppDownloadConsumerDuty },

		computed: {
			...mapState('CmsDownload', { downloads: 'downloadList' }),
			...mapGetters('CmsDownload', ['downloads'])
		},

		created() {
			this.loadDownloadsList();
		},

		methods: {
			...mapActions('CmsDownload', ['loadDownloadsList'])
		}
	};
</script>
