<!--
	@name app-client-dashboard-workflow-conveyancing
	@description Client Workflow Conveyancing Item
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<app-client-dashboard-workflow-template :workflow-item="workflowItem" @close-workflow-item="$emit('close-workflow-item')" @update-status="$emit('update-status', $event)">
			<template slot="activity">
				<!-- Loading... -->
				<v-skeleton-loader v-if="loading" type="list-item-three-line" />
				<div v-if="!loading">
					<app-client-dashboard-workflow-conveyancing-get-started
						v-if="showGetStarted"
						:intro-resource="introResource"
						@toggle-intro-resource="toggleIntroResource"
						@hideGetStartedDisplay="hideGetStartedDisplay"
					/>
					<app-client-dashboard-workflow-conveyancing-activity
						v-else-if="!newReferralOverride && referrals.length"
						:referrals="referrals"
						@toggleNewReferralOverride="toggleNewReferralOverride"
					/>
					<app-client-dashboard-workflow-conveyancing-solicitors
						v-else
						:client="client"
						:association="association"
						:asset="asset"
						:referrals="referrals"
						@toggleNewReferralOverride="toggleNewReferralOverride"
						@submittedNewReferral="submittedNewReferral"
					/>
				</div>
			</template>

			<template slot="resources">
				<app-client-dashboard-workflow-template-resources :section="section" :resources="resources" :remove-intro-video="removeIntroVideo" />
			</template>
		</app-client-dashboard-workflow-template>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import AppClientDashboardWorkflowTemplate from '@/component/app/client/dashboard/workflow/template';
	import AppClientDashboardWorkflowConveyancingGetStarted from '@/component/app/client/dashboard/workflow/conveyancing/get-started';
	import AppClientDashboardWorkflowConveyancingActivity from '@/component/app/client/dashboard/workflow/conveyancing/activity';
	import AppClientDashboardWorkflowConveyancingSolicitors from '@/component/app/client/dashboard/workflow/conveyancing/solicitors';
	import AppClientDashboardWorkflowTemplateResources from '@/component/app/client/dashboard/workflow/template/resources';
	import courseVideoMixin from '@/mixins/course-video';

	export default {
		name: 'app-client-dashboard-workflow-conveyancing',

		components: {
			AppClientDashboardWorkflowTemplate,
			AppClientDashboardWorkflowConveyancingGetStarted,
			AppClientDashboardWorkflowConveyancingActivity,
			AppClientDashboardWorkflowConveyancingSolicitors,
			AppClientDashboardWorkflowTemplateResources
		},

		mixins: [courseVideoMixin],

		props: {
			workflowItem: { type: Object, required: true },
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			resources: { type: Array, required: true }
		},

		data() {
			return {
				loading: true,
				referrals: [],
				hideGetStarted: false,
				newReferralOverride: false,
				section: 'conveyancing'
			};
		},

		computed: {
			showGetStarted() {
				return !this.referrals.length && !this.hideGetStarted;
			}
		},

		created() {
			this.loadConveyancing();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('AppConveyancing', ['loadConveyancingList']),

			onMessage(message) {
				if (message?.type !== 'conveyancing' || !message.action?.data?.status) return;
				const index = this.referrals.findIndex((i) => i.id === message.id);
				this.$set(this.referrals, index, { ...this.referrals[index], status: message.action.data.status });
			},

			loadConveyancing() {
				this.loadConveyancingList({
					entityIds: [this.asset.id],
					order: { property: 'created', direction: 'desc' }
				})
					.then(({ data }) => {
						this.referrals = data;
					})
					.finally(() => {
						this.loading = false;
					});
			},

			hideGetStartedDisplay() {
				this.hideGetStarted = true;
			},

			toggleNewReferralOverride() {
				this.newReferralOverride = !this.newReferralOverride;
			},

			submittedNewReferral() {
				this.newReferralOverride = false;
				this.loadConveyancing();
				this.$emit('workflow-updated');
				this.$nextTick(() => {
					let element = document.getElementById('template');
					element?.scrollIntoView({ behavior: 'smooth' });
				});
			}
		}
	};
</script>
