<!--
	@name app-firm-dialog-add-domain
	@description Show a dialog to add a domain
	@date 2020/08/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="500">
		<template #header>
			Add New Domain
		</template>

		<template #body>
			<dynamic-form
				ref="dynamicForm"
				:submit-button-text="'Create Domain'"
				:form-schema="form"
				:form-data="formData"
				:submit-button-loading="submitting"
				@dynamic-form-submit="addDomain"
			/>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-firm-dialog-add-domain',

		components: { CommonDialog, DynamicForm },

		props: {
			form: { type: Array, required: true, default: () => [] }
		},

		data() {
			return {
				submitting: false,
				formData: {}
			};
		},

		computed: {
			...mapState('Account', ['organisation'])
		},

		methods: {
			...mapActions('AccountOrganisationDomain', ['addOrganisationDomain']),

			/**
			 * @name addDomain
			 * @description Sends request to add a domain
			 */
			async addDomain() {
				this.submitting = true;

				this.addOrganisationDomain({ domainAddress: this.formData.domainAddress, organisationId: this.organisation.id })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Domain has been successfully added');
						this.$refs.dialog.close();
					})
					.catch((e) => {
						let message = 'Failed to add domain';
						if (e.data.message === 'Cannot duplicate a unique value, value already exists') message = 'Trading style has already been added for the firm';
						ElementTools.fireNotification(this.$el, 'error', message);
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.formData = {};
				this.$refs.dialog.open();
			}
		}
	};
</script>
