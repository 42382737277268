<!--
	@name app-source
	@description Sourcing Page
	@date 2020/02/27
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Source a Product
					</div>
				</v-col>
				<v-col class="grow text-right">
					<common-dialog-glossary class="d-inline" :items="glossaryItems" :button-text-hidden="true" :icon-large="true" icon-color="white" title="Glossary" />
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-banner v-if="isTutorial" color="error" class="mb-6" app sticky>
			<p class="subtitle-2 mb-0">
				You are seeing this page in tutorial mode. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
				aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
				voluptate velit esse cillum dolore.
			</p>

			<template #actions>
				<v-btn text color="red accent-4" @click="exitTutorial">
					Exit Tutorial
				</v-btn>
			</template>
		</v-banner>

		<div v-if="loading" />
		<v-alert v-else-if="isExistingSession && !process" type="error" transition="fade">
			Either the URL is incorrect, or the session has been deleted. If the session was deleted in error, please contact our support team. To begin a brand new session,
			<a class="white--text text-decoration-underline font-weight-bold" @click="$router.push('/source')">click here</a>.
		</v-alert>
		<v-fade-transition v-else>
			<v-row>
				<v-col class="py-0" cols="12" lg="8">
					<div class="border-effect border-effect--lg ml-6 mb-10">
						<p class="text-h5 mb-2 font-weight-regular">
							Complete this form to display <span class="font-weight-bold">all suitable products.</span>
						</p>
						<p class="text-body-1 mb-0">
							When your results are displayed, you can also use a range of “Research Filters” to further narrow down your products to a shortlist.
						</p>
					</div>

					<v-row v-if="hasHeroBanner && heroBannerImage" no-gutters class="mx-auto my-6" style="max-width: 1000px;">
						<v-col cols="12" class="text-center">
							<a :href="heroBannerLink" target="_blank">
								<v-img :src="heroBannerImage" class="mx-auto" max-height="120" max-width="1000" contain />
							</a>
						</v-col>
					</v-row>

					<v-alert
						v-if="site && panelChangeMessage"
						class="pl-4 mb-6 white"
						:class="panelChangeMessageDisclosed ? 'grey lighten-1' : 'warning'"
						:color="panelChangeMessageDisclosed ? 'success' : 'error'"
						dark
						dense
						prominent
						colored-border
						border="left"
						:type="panelChangeMessageDisclosed ? 'success' : 'warning'"
						@click="panelChangeMessageDisclosed = !panelChangeMessageDisclosed"
					>
						<v-row>
							<v-col class="grow text-body-1 py-1">
								{{ panelChangeMessage }}
							</v-col>
							<v-col class="shrink py-1">
								<v-checkbox
									:value="panelChangeMessageDisclosed"
									inset
									class="ma-0"
									hide-details
									@click.self="panelChangeMessageDisclosed = !panelChangeMessageDisclosed"
								/>
							</v-col>
						</v-row>
					</v-alert>

					<div class="mt-0 mb-6 d-flex justify-center sourcing-session-mode">
						<v-btn-toggle v-model="activeTab" color="primary" mandatory dense>
							<v-btn
								v-show="hasWriteRouteAccess"
								large
								class="font-weight-bold"
								width="250"
								:disabled="isExistingSession || isTutorial"
								:href="factFindUrl"
								target="_blank"
							>
								Complete a Fact Find
							</v-btn>

							<v-btn large class="font-weight-bold" width="250" :disabled="(isExistingSession && !isQuickQuote) || isTutorial">
								Source a Product
							</v-btn>

							<v-btn large class="font-weight-bold" width="250" :disabled="(isExistingSession && !isQuickQuote) || isTutorial">
								Quick Source
							</v-btn>
						</v-btn-toggle>
					</div>

					<common-structure-section class="mb-6">
						<template #body>
							<!-- Fact Find -->
							<div v-if="activeTab === FACT_FIND" v-show="hasWriteRouteAccess">
								<p class="body-1 ma-0">
									Our new WriteRoute is opening in another browser window. In the event that your browser has blocked this process, you can open up WriteRoute
									directly by <a :href="factFindUrl" target="_blank">clicking here</a>.
								</p>
							</div>

							<!-- Sourcing Session -->
							<div v-if="activeTab === FULL_SOURCE">
								<app-source-full-source
									ref="source"
									:process="process"
									:valid="!panelChangeMessage ? true : panelChangeMessageDisclosed"
									@club-registration-required="openClubRegistrationDialog"
								/>
							</div>

							<!-- Quick Source -->
							<div v-if="activeTab === QUICK_SOURCE">
								<app-source-quick-source ref="quickSource" :process="process" @club-registration-required="openClubRegistrationDialog" />
							</div>
						</template>
					</common-structure-section>
				</v-col>

				<v-col class="py-0" cols="12" lg="4">
					<common-structure-section v-if="hasWriteRouteAccess">
						<template #header>
							Streamline and simplify your advice process
						</template>

						<template #body>
							<v-sheet class="px-4 py-3 mb-4 background-rotation-manual--1 rounded">
								<p class="text-body-1 text-center white--text mb-0">
									Our new WriteRoute is fully interactive end-to-end and will improve your advice process.
									Try WriteRoute and <strong>benefit from an integrated fact find, product sourcing, customisable questions, offline usage and a streamlined experience.</strong>
								</p>
							</v-sheet>
							<p class="text-body-1 text-center px-3 mb-0">
								Take your clients from fact find straight through to KFI and even <strong>generate a comprehensive suitability report with no need to re-enter any information.</strong>
							</p>
						</template>
					</common-structure-section>

					<common-promotion-advisor-advert class="mt-6" />
				</v-col>
			</v-row>
		</v-fade-transition>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import AppSourceFullSource from '@/component/app/source/full-source';
	import AppSourceQuickSource from '@/component/app/source/quick-source';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import CommonDialogGlossary from '@/component/common/dialog/glossary';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-source',

		components: {
			AppSourceFullSource,
			AppSourceQuickSource,
			CommonDialogConfirm,
			CommonDialogGlossary,
			CommonPromotionAdvisorAdvert,
			CommonStructureSection
		},

		data() {
			return {
				FACT_FIND: 0,
				FULL_SOURCE: 1,
				QUICK_SOURCE: 2,
				loading: true,
				activeTab: null,
				process: null,
				panelChangeMessageDisclosed: false,
				glossaryItems: [
					{
						key: 'erc',
						type: 'icon',
						icon: 'mdi-check-decagram',
						iconColour: 'green',
						title: 'Display Equity Release Council Products Only',
						description:
							'Selecting "Yes" will exclude any non council approved products, such as older borrower term mortgages from your results. Select "No" if you want to compare all products.'
					},
					{
						key: 'advance',
						type: 'icon',
						icon: 'mdi-cash-check',
						iconColour: 'orange',
						title: 'Advance',
						description:
							'The "Advance" is the initial sum paid to client as a lump sum on completion. You can either enter a specific amount OR tick the "Show Maximum Available" box underneath to display the maximum lump sum available from all products.'
					},
					{
						key: 'drawdown',
						type: 'icon',
						icon: 'mdi-cash-lock-open',
						iconColour: 'purple',
						title: 'Drawdown Facility Required',
						description:
							'Selecting "No" will NOT exclude products that include an optional drawdown facility. Selecting "Yes" will mean that only products offering drawdown facilities will be displayed.'
					}
				],
				requireClubRegistration: false,
				apiHealthQuestions: {},
				heroBanner: null
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership']),

			hasHeroBanner() {
				return this.site.adverts?.advisor;
			},

			heroBannerLink() {
				return this.heroBanner ? this.heroBanner?.link : '';
			},

			heroBannerImage() {
				return this.heroBanner ? this.$options.filters.urlize(this.heroBanner?.image) : '';
			},

			hasWriteRouteAccess() {
				return this.site.writeRouteAccess;
			},

			factFindUrl() {
				return `${process.env.VUE_APP_FACTFIND_ORIGIN}`;
			},

			airSelect() {
				return this.membership('select').id && !this.membership('select').bypass;
			},

			processId() {
				return this.$route.params.processId;
			},

			isExistingSession() {
				return !!this.processId;
			},

			isQuickQuote() {
				return this.process && !this.process?.matterEntity;
			},

			isQuickQuoteUpgrade() {
				return Object.keys(this.$route.query).includes('upgrade');
			},

			isTutorial() {
				return !!this.$route.query.tutorial;
			},

			panelChangeMessage() {
				let prevPanel = null;
				let newPanel = null;

				if (!this.membership('select').id || !this.isExistingSession || this.isQuickQuoteUpgrade) return false;

				if (this.isSelectProcess && this.membership('select').bypass) {
					// This process is marked as Air Select, but Air Select status is inactive
					newPanel = 'Whole of Market';
					prevPanel = 'Air Select';
				} else if (!this.isSelectProcess && this.membership('select').id && !this.membership('select').bypass) {
					// This is an existing session, for which the process is not marked as Air Select, but the adviser IS an Air Select Member and status is Active
					prevPanel = 'Whole of Market';
					newPanel = 'Air Select';
				} else return false;

				let applicants = (this.process.matterEntity || []).filter((item) => item.entity === 'client').length;

				return `This session was previously sourced as ${prevPanel}. Please click the checkbox to confirm that the applicant${applicants.length > 1 ? '(s)' : ''} ${
					applicants.length > 1 ? 'have' : 'has'
				} been advised that you are now sourcing from the ${newPanel} panel.`;
			},

			isSelectProcess() {
				if (this.process?.data?.attributes?.select === true) return true; // legacy
				if (this.process?.data?.attributes?.panel?.name === 'select') return true;
				return false;
			},

			isBackInterceptor() {
				return this.$route.query && !!this.$route.query['back-interceptor'];
			}
		},

		created() {
			this.activeTab = this.FULL_SOURCE;

			this.$nextTick(() => {
				if (this.isExistingSession) this.getProcessData();
				else this.loading = false;
			});

			this.loadAdvisorBanner().then(({ data }) => {
				if (data?.value.sourcing_hero) this.heroBanner = data.value.sourcing_hero;
			});
		},

		mounted() {
			setTimeout(() => {
				if (this.isBackInterceptor) {
					// Google Analytics event
					window.dataLayer.push({
						event: 'openBackInterceptorSurvey'
					});
				}
			}, 400);
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AppSourceProcess', ['getProcess']),
			...mapActions('Account', ['requestMembership']),
			...mapActions('CmsBanner', ['loadAdvisorBanner']),

			/**
			 * @name getProcessData
			 * @description Shows an error dialog
			 */
			async getProcessData() {
				this.loading = true;
				this.getProcess(this.processId)
					.then(({ data }) => {
						this.process = data;
						this.activeTab = (data.matterEntity && data.matterEntity.length) || this.isQuickQuoteUpgrade ? this.FULL_SOURCE : this.QUICK_SOURCE;
					})
					.finally(() => (this.loading = false));

				// TODO: Set isAirSelect from process data
				// this.isAirSelect = this.process.isAirSelect
			},

			/**
			 * @name openClubRegistrationDialog
			 * @description Submit quick quote form to the server
			 */
			openClubRegistrationDialog(submitMethod) {
				this.$refs.confirm
					.open('Join the award winning Air Club!', null, {
						app_bar_color: '#b26fac',
						buttons: { yes: 'SIGN ME UP!', no: 'No thanks' },
						paragraphs: [
							`You must be a member of Air Club to proceed.`,
							`Air Club is completely FREE to join. Established over 12 years and recognised as the UK's foremost later life lending club.`
						],
						width: 600
					})
					.then(async() => {
						try {
							await this.requestMembership({ type: 'club', identityId: this.user.id, identityType: 'user' });
							ElementTools.fireNotification(this.$el, 'success', 'Your application has been successfully submitted for approval');

							if (submitMethod === 'source') this.$refs.source.sourceFormSubmitHandler();
							else this.$refs.quickSource.submitQuickSource();
						} catch (e) {
							ElementTools.fireNotification(this.$el, 'error', e);
						}
					})
					.catch(() => {});
			},

			/**
			 * @name showErrorDialog
			 * @description Shows an error dialog
			 */
			showErrorDialog(error) {
				this.$refs.confirm
					.open('Error', error.message, {
						system_bar_color: 'error darken-2',
						app_bar_color: 'error',
						buttons: { yes: error.buttonText, no: false }
					})
					.finally(() => (window.location = error.redirectUrl));
			},

			exitTutorial() {
				this.$refs.confirm
					.open('Exit Tutorial?', 'We recommend you to complete this tutorial for getting the best experience.', {
						system_bar_color: 'info darken-2',
						app_bar_color: 'info darken-1',
						buttons: { yes: 'Continue to tutorial', no: 'Get me out!' }
					})
					.catch(() => {
						window.location = '/account';
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-alert {
		font-size: 14px;
		line-height: 18px;
	}
</style>
