<!--
	@name app-air-club-product
	@description Product tab view for the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-6">
		<common-structure-directory :items="products" :page-size="6">
			<template #title>
				Products
			</template>

			<template #detail>
				It is vital if operating in the later life market that you consider these ancillary products below to meet the needs of your clients. Click on any provider logo to
				find out more about their products and services. For retirement lending we operate a whole of market policy and to you should use the Source link on the left to
				source a lifetime or retirement mortgage.
			</template>
		</common-structure-directory>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { shuffle } from 'lodash';
	import CommonStructureDirectory from '@/component/common/structure/directory';

	export default {
		name: 'app-air-club-product',

		components: { CommonStructureDirectory },

		computed: {
			...mapGetters('CmsData', ['data']),
			...mapGetters('AppMatterType', ['mappedMatterTypeList']),

			products() {
				return shuffle(this.mappedMatterTypeList);
			}
		},

		created() {
			this.loadMatterTypeList({ with: 'organisation', where: [[{ "data->>'is_product'": true, "data->>'hidden'": false }]], order: { property: 'position' } });
			this.loadData('product');
		},

		methods: {
			...mapActions('CmsData', ['loadData']),
			...mapActions('AppMatterType', ['loadMatterTypeList'])
		}
	};
</script>

<style scoped lang="scss"></style>
