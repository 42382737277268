<!--
	@name app-calculator-borrowing-forecast-tool
	@description Account settings - Account security tab content
	@date 2020/05/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="active" scrollable max-width="1000" :retain-focus="false">
		<v-card tile>
			<v-toolbar class="generic-gradient" dark>
				<v-toolbar-title> Advanced features </v-toolbar-title>
				<v-spacer />
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-container fluid class="pa-0">
					<h2 class="text-h6 mb-2">
						Blended Rate Calculator
					</h2>
					<p class="body-2 mb-0">
						Add loan amounts and any associated AER to calculate a blended loan / rate. <a @click="addNewItem">Click to add a new row</a>.
					</p>
					<p class="body-2 font-weight-bold mt-1 mb-0">
						RESULT: loan amount of £{{ blendedLoanAmount | numFormat('0,00') }} at a blended rate of {{ blendedLoanRate | numFormat('0,00.00') }}%
					</p>
					<a v-if="actionText" :class="{ disabled: !actionActive }" class="body-2 mt-1 mb-0 d-block" @click="actionClick">
						{{ actionText }}
					</a>
					<v-list class="grey lighten-3 px-0 mt-3">
						<v-list-item v-for="(loan, index) in loans" :key="index">
							<v-list-item-content class="pa-0">
								<v-text-field v-model="loan.loan" solo dense label="Loan amount" prepend-inner-icon="mdi-currency-gbp" hide-details class="mr-2" />
								<v-text-field v-model="loan.rate" solo dense label="AER" append-icon="mdi-percent-outline" hide-details class="ml-2" />
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-btn icon color="error" :disabled="loans.length === 1" @click="removeLoan(index)">
									<v-icon> mdi-trash-can </v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>

					<div class="mt-8">
						<h2 class="text-h6 d-inline-block mb-2">
							MER to AER calculator
						</h2>
						<p class="body-2 mb-0">
							Enter a monthly interest rate to calculate the annual equivalent rate.
						</p>
						<p class="body-2 font-weight-bold mt-1 mb-0">
							RESULT: annual equivalent rate of {{ aer | numFormat('0,00.00') }}%
						</p>
						<v-list class="grey lighten-3 px-0 mt-3">
							<v-list-item>
								<v-list-item-content class="pa-0">
									<v-text-field v-model="mer" type="number" solo dense label="MER" placeholder="0" append-icon="mdi-percent-outline" hide-details />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</div>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import numeral from 'numeral';

	export default {
		name: 'app-calculator-blended-rate-calculator',

		components: {},

		filters: {
			conditionalDecimal(value) {
				let format = parseFloat(value) < 1000 ? '0,0.00' : '0,0';
				return numeral(value).format(format);
			}
		},

		props: {
			actionText: { type: String, default: null, required: false }
		},

		data() {
			return {
				active: false,
				loans: [{ loan: 0, rate: 0 }],
				mer: 0
			};
		},

		computed: {
			actionActive() {
				return this.formatNumber(this.blendedLoanRate) > 0 && this.formatNumber(this.blendedLoanAmount) > 0;
			},

			blendedLoanAmount() {
				return this.loans.reduce((previousValue, currentValue) => previousValue + this.formatNumber(currentValue.loan), 0);
			},

			blendedLoanRate() {
				return (
					(this.loans.reduce((previousValue, currentValue) => previousValue + (this.formatNumber(currentValue.loan) * this.formatNumber(currentValue.rate)) / 100, 0) /
						this.blendedLoanAmount) *
					100
				);
			},

			aer() {
				return (Math.pow(this.mer / 12 / 100 + 1, 12) - 1) * 100;
			}
		},

		methods: {
			/**
			 * @name open
			 * @description Opens the advanced settings dialog
			 */
			open() {
				this.active = true;
			},

			/**
			 * @name close
			 * @description Closes the advanced settings dialog
			 */
			close() {
				this.active = false;
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			},

			/**
			 * @name actionClick
			 * @description Action has been clicked
			 */
			actionClick() {
				if (!this.actionActive) return false;
				this.$emit('action', this.blendedLoanAmount, this.blendedLoanRate);
			},

			/**
			 * @name addNewItem
			 * @description Add a new loan
			 */
			addNewItem() {
				this.loans.push({ loan: 0, rate: 0 });
			},

			/**
			 * @name removeLoan
			 * @description Remove loan from list
			 */
			removeLoan(index) {
				this.loans.splice(index, 1);
			},

			/**
			 * @name formatNumber
			 * @description convert variable to an integer
			 */
			formatNumber(val) {
				if (val == null) return 0;
				if (isNaN(val)) return 0;
				return Number(val);
			}
		}
	};
</script>

<style lang="scss" scoped>
	a.disabled {
		color: #bbbbbb;
		font-weight: normal !important;
		text-decoration: line-through;
		cursor: not-allowed;
	}
</style>
