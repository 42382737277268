<!--
	@name app-summer-campaigns-trda-04092024
	@description trda-04092024 campaign section
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div class="d-flex align-center flex-column mb-12">
			<span>
				<p class="text-h5 font-weight-bold mb-3">
					Are you looking for enhanced support and exclusive tools to grow your advising business?
				</p>
				<p class="text-body-1 mb-6">
					The Right DA Club, in partnership with Air Academy, offers unparalleled resources designed specifically for DA advisers like you. By joining us, you'll gain access to a comprehensive range of benefits.
				</p>
				<p class="text-h6 font-weight-bold mb-2">
					Why Join Air?
				</p>
				<ul class="text-body-1 mb-3">
					<li>
						Access to become a <span class="font-weight-bold">Platinum* member</span> of Air with Academy status.
					</li>
					<li>
						Access to <span class="font-weight-bold">Academy's Premium service at a discounted rate</span> - all modules accredited by the LIBF.
					</li>
					<li>
						Access to <span class="font-weight-bold">Air Select** Panel and enhanced commissions*</span>.
					</li>
					<li>
						Access to Air helpdesk team for support with placing business.
					</li>
					<li>
						<span class="font-weight-bold">TRDA Club will have a dedicated Account Manager</span>, with its members being allocated dedicated telephone support, and face to face where applicable.
					</li>
				</ul>
				<p class="text-caption plain mb-6">
					*All proc fees subject to lender governance.
					<br />
					**Air's 'Select' panel, consisting of More2Life, Standard Life Home Finance, Legal and General, and Aviva. Advisers retain the ability to source from whole of market in instances when they are unable to find a solution that fits the client needs via the panel.
				</p>
				<v-divider inset class="mb-5" />
				<v-row>
					<v-col v-for="(video, index) in videos" :key="index" cols="12" lg="4">
						<div class="text-body-2 font-weight-medium text-center px-lg-5 d-none">
							{{ video.copy }}
						</div>

						<div class="mt-0" style="position: relative">
							<div v-if="$vuetify.breakpoint.lgAndUp" class="rounded">
								<img :src="video.poster" class="rounded d-block" style="width: 100%" />
								<v-btn icon class="ga--trigger ga--campaign-click ga--video-play play-icon mt-n1" @click="playVideo(video)">
									<v-icon size="100" color="black lighten-2">
										mdi-play-circle
									</v-icon>
								</v-btn>
							</div>

							<video v-else controls :src="video.src" :poster="video.poster | urlize" width="100%" height="auto" />
						</div>
					</v-col>
				</v-row>
				<v-divider inset class="my-5" />
				<p class="text-h6 font-weight-bold mb-2">
					Not a Member Yet?
				</p>
				<p class="text-body-1 mb-0">
					<a class="ga--trigger ga--campaign-click ga--register font-weight-bold" @click="$router.push('/register')">Register with Air Club today</a> to access your exclusive benefits and take advantage of our comprehensive support services.
				</p>
			</span>
		</div>

		<!-- Dialogs -->
		<common-dialog v-if="selectedVideo" ref="videoDialog" :max-width="960" @closed="selectedVideo = null">
			<template #header>
				{{ selectedVideo?.copy }}
			</template>
			<template #body>
				<div class="video-container">
					<video :controls="true" :src="selectedVideo?.src" :poster="selectedVideo?.poster | urlize" width="100%" height="auto" autoplay />
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-summer',

		components: {
			CommonDialog
		},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL,
				selectedVideo: null,
				videos: [
					{
						title: 'Celebrating a decade',
						copy: 'Celebrating a decade of professional development with the Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/65568bfd3ba980849ff38ad31a8c1fa1.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/76cd26f4f6e9da657e7bde2eb964c709.png`,
						controls: false
					},
					{
						title: 'Affordability module',
						copy: 'Bringing affordability to the forefront of your advice process with our 10th Academy module',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/90e581e6d22471df00df811ecce6f16e.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/fe1387496eb4a6440bb261344762905a.png`,
						controls: false
					},
					{
						title: 'LIBF Trusted Partner',
						copy: ' LIBF Trusted Partner: Establishing excellence with Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/Maverix+-+Trusted+Partner.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/69c8eca99dfe81a6627eb94a6dc0ad64.png`,
						controls: false
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {
			playVideo(video) {
				this.selectedVideo = video;

				this.$nextTick(() => {
					this.$refs.videoDialog.open();
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.video-iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.video-container {
		width: 100%;
		height: 100%;
		aspect-ratio: 1.77777;
	}

	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
</style>
