<!--
	@name app-password-change
	@description Password change page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="site" to="/">
					<v-img class="mx-auto" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
				</router-link>

				<p class="text-h5 font-weight-bold mt-8 mb-3">
					Password Recovery
				</p>
				<p class="text-body-2">
					Enter your CURRENT USERNAME and enter a NEW PASSWORD below. You can then log in with your new password immediately. If you have also forgotten your current
					username, please contact us on {{ site.contact.number }} to reset your log in details. Alternatively, email {{ site.contact.email }} and request a call back.
				</p>
			</v-col>
		</v-row>

		<v-card class="mx-auto" max-width="500">
			<v-sheet class="body pa-5">
				<div v-if="success" class="text-center">
					<v-icon x-large color="success" class="mb-2">
						mdi-check
					</v-icon>
					<p class="text-body-1 font-weight-bold mb-4">
						Your password has been successfully reset
					</p>
					<v-btn color="primary" block large class="mt-3 mx-auto d-flex" to="/advisers">
						Proceed to log in
					</v-btn>
				</div>
				<div v-else>
					<dynamic-form
						ref="loginForm"
						:form-schema="formSchema"
						:form-data="formData"
						:submit-button-loading="loading"
						submit-button-text="Continue"
						:submit-button-block="true"
						:submit-button-large="true"
						@dynamic-form-submit="submit"
					/>
					<v-btn color="blue-grey lighten-1" text class="mt-3 mx-auto text-none d-flex mb-0" to="/advisers">
						Return to login
					</v-btn>
				</div>
			</v-sheet>
		</v-card>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-password-change',

		components: { DynamicForm },

		data() {
			return {
				formData: {},
				formSchema: [
					{
						type: 'text',
						name: 'username',
						display: 'Username',
						rules: 'required',
						dense: false,
						sm: 12
					},
					{
						type: 'passwordStrength',
						name: 'password',
						ref: 'new_password',
						display: 'New Password',
						rules: 'required|verify_password',
						dense: false,
						sm: 12
					},
					{
						type: 'password',
						name: 'confirmPassword',
						display: 'Confirm New Password',
						rules: 'required|confirmed:new_password',
						dense: false,
						sm: 12
					}
				],
				loading: false,
				success: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site'])
		},

		methods: {
			...mapActions('Account', ['resetPassword']),

			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			async submit() {
				this.loading = true;

				this.resetPassword({
					payload: {
						identity: this.formData.username,
						identityType: 'username',
						password: this.formData.password
					},
					token: this.$route.params.token
				})
					.then(() => {
						this.success = true;
					})
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error.data.message || 'An error occured. Please try again later.'))
					.finally(() => (this.loading = false));
			}
		}
	};
</script>
