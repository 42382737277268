import { AccountOrganisationDomain } from '@/service';

/**
 * @class @name Domain
 * @description Exposes menu store module
 */
export default class Domain {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			organisationDomainList: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name domainById
			 * @description used to get domain by ID from store
			 * @param {Object} state
			 */
			domainById: (state) => (id) => state.organisationDomainList.find((t) => t.id === id)
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setOrganisationDomainList
			 * @param {Object} state
			 * @param {Array} data
			 */
			setOrganisationDomainList(state, data) {
				state.organisationDomainList = data.data;
			},

			/**
			 * @name setOrganisationDomain
			 * @param {Object} state
			 * @param {Object} data
			 */
			setOrganisationDomain(state, data) {
				if (!state.organisationDomainList) state.organisationDomainList = [data];
				else {
					const arr = [...state.organisationDomainList];
					const idx = arr.reduce((p, c, i) => (c.id === data.id ? i : p), -1);
					if (idx >= 0) arr[idx] = { ...arr[idx], ...data };
					else arr.push(data);
					state.organisationDomainList = arr;
				}
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadOrganisationDomainList
			 * @param {function} commit
			 */
			async loadOrganisationDomainList({ commit }) {
				return AccountOrganisationDomain.list()
					.then(({ data }) => commit('setOrganisationDomainList', data))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisationDomain
			 * @param {function} commit
			 */
			modifyOrganisationDomain({ state, commit }, { id, payload }) {
				return AccountOrganisationDomain.modify(id, payload)
					.then(() => commit('setOrganisationDomain', { ...state.organisationDomainList.find((u) => u.id === id), ...payload }))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name addOrganisationDomain
			 * @param {function} commit
			 */
			async addOrganisationDomain({ commit }, payload) {
				return AccountOrganisationDomain.add(payload)
					.then(({ data }) => commit('setOrganisationDomain', data))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name deleteOrganisationDomain
			 * @param {function} commit
			 */
			async deleteOrganisationDomain({ commit, state }, id) {
				return AccountOrganisationDomain.delete(id)
					.then(() => commit('setOrganisationDomainList', [...state.organisationDomainList.filter((u) => u.id !== id)]))
					.catch((error) => {
						throw error;
					});
			}
		};
	}
}
