<!--
	@name app-client-dashboard-workflow-fact-find
	@description Client Workflow Fact Find Item
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div v-if="canAccessNewFactFind" :component="$options.name">
		<app-client-dashboard-workflow-template
			:workflow-item="workflowItem"
			@close-workflow-item="$emit('close-workflow-item')"
			@update-status="$emit('update-status', $event)"
		>
			<template slot="activity">
				<app-client-dashboard-workflow-fact-find-activity
					:client="client"
					:association="association"
					:asset="asset"
					:intro-resource="introResource"
					@toggle-intro-resource="toggleIntroResource"
					@workflow-updated="$emit('workflow-updated')"
				/>
			</template>

			<template slot="resources">
				<app-client-dashboard-workflow-template-resources :section="section" :resources="resources" :remove-intro-video="removeIntroVideo" />
			</template>
		</app-client-dashboard-workflow-template>
	</div>
	<v-alert v-else type="error" transition="fade">
		An unexpected error occurred. Please contact our support team.
	</v-alert>
</template>

<script>
	import { mapState } from 'vuex';
	import AppClientDashboardWorkflowTemplate from '@/component/app/client/dashboard/workflow/template';
	import AppClientDashboardWorkflowFactFindActivity from '@/component/app/client/dashboard/workflow/fact-find/activity';
	import AppClientDashboardWorkflowTemplateResources from '@/component/app/client/dashboard/workflow/template/resources';
	import courseVideoMixin from '@/mixins/course-video';

	export default {
		name: 'app-client-dashboard-workflow-fact-find',

		components: {
			AppClientDashboardWorkflowTemplate,
			AppClientDashboardWorkflowFactFindActivity,
			AppClientDashboardWorkflowTemplateResources
		},

		mixins: [courseVideoMixin],

		props: {
			workflowItem: { type: Object, required: true },
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, default: null },
			resources: { type: Array, required: true }
		},

		data() {
			return {
				section: 'fact_find'
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			canAccessNewFactFind() {
				if (!this.site) return false;
				return this.site.writeRouteAccess;
			}
		}
	};
</script>

<style scoped lang="scss"></style>
