<!--
	@name app-client-dashboard-workflow-section
	@description Client Workflow Overview
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog v-if="dialog" v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition" scrollable>
			<v-card v-if="item" tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								{{ item.name }}
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-spacer />
						<v-col cols="12" md="2" class="grow text-right">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon dark class="align-self-center" @click="close" v-on="on">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Back to Client Dashboard</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-card-text class="pa-0">
					<v-sheet>
						<v-container id="workflow" class="pa-6">
							<component
								:is="activeWorkFlowItemComponent"
								:workflow-item="activeWorkFlowItem"
								:client="client"
								:association="association"
								:asset="asset"
								:resources="resources"
								@close-workflow-item="close"
								@update-status="$emit('update-status', $event)"
								@workflow-updated="$emit('workflow-updated')"
							/>
						</v-container>
					</v-sheet>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { kebabCase } from 'lodash';
	import AppClientDashboardWorkflowAffordability from '@/component/app/client/dashboard/workflow/affordability';
	import AppClientDashboardWorkflowFactFind from '@/component/app/client/dashboard/workflow/fact-find';
	import AppClientDashboardWorkflowVulnerabilityAssessment from '@/component/app/client/dashboard/workflow/vulnerability-assessment';
	import AppClientDashboardWorkflowSourcing from '@/component/app/client/dashboard/workflow/sourcing';
	import AppClientDashboardWorkflowKeyFactsIllustration from '@/component/app/client/dashboard/workflow/key-facts-illustration';
	import AppClientDashboardWorkflowApplication from '@/component/app/client/dashboard/workflow/application';
	import AppClientDashboardWorkflowConveyancing from '@/component/app/client/dashboard/workflow/conveyancing';
	import AppClientDashboardWorkflowWill from '@/component/app/client/dashboard/workflow/will';
	import AppClientDashboardWorkflowPowerOfAttorney from '@/component/app/client/dashboard/workflow/lpa';
	import AppClientDashboardWorkflowStateBenefits from '@/component/app/client/dashboard/workflow/state-benefits';

	export default {
		name: 'app-client-dashboard-workflow-section',

		components: {
			AppClientDashboardWorkflowAffordability,
			AppClientDashboardWorkflowFactFind,
			AppClientDashboardWorkflowVulnerabilityAssessment,
			AppClientDashboardWorkflowSourcing,
			AppClientDashboardWorkflowKeyFactsIllustration,
			AppClientDashboardWorkflowApplication,
			AppClientDashboardWorkflowConveyancing,
			AppClientDashboardWorkflowWill,
			AppClientDashboardWorkflowPowerOfAttorney,
			AppClientDashboardWorkflowStateBenefits
		},

		props: {
			items: { type: Array, default: null },
			client: { type: Object, default: null },
			association: { type: Object, default: null },
			asset: { type: Object, default: null }
		},

		data() {
			return {
				dialog: false,
				item: null,
				resources: []
			};
		},

		computed: {
			...mapGetters('AppWorkflow', ['getStatusColor']),

			activeWorkFlowItem() {
                if (!this.item) return null;
				const item = this.items.find((item) => item.id === this.item.id);
				return { id: item.id, name: item.name, copy: item.copy, statuses: item.data?.statuses, prev: item.prev, next: item.next };
			},

			activeWorkFlowItemComponent() {
				if (!this.activeWorkFlowItem) return null;
				return `app-client-dashboard-workflow-${kebabCase(this.activeWorkFlowItem.name)}`;
			}
		},

		watch: {
			activeWorkFlowItemComponent(val) {
				if (!val) return;
				this.$nextTick(() => document.getElementById('workflow')?.scrollIntoView({ behavior: 'instant' }));
			}
		},

		created() {
			this.loadCourseList().then((items) => {
				this.resources = items.data.data;
			});
		},

		methods: {
			...mapActions('CmsCourse', ['loadCourseList']),

			open(item) {
				// Prevent double scrollbar https://github.com/vuetifyjs/vuetify/issues/5356
				document.documentElement.classList.add('overflow-y-hidden');
                this.item = item;
				this.dialog = true;
			},

			close() {
				const query = { ...this.$route.query };
				delete query.workflowSection;
				this.$router.replace({ query });
				this.dialog = false;
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 */
			 handleAction(action) {
				this[action]();
			},

			/**
			 * @name createEnquirySession
			 * @description Create New Enquiry Session
			 */
			createEnquirySession() {
				this.$router.push(
					`/enquiries/create-new-enquiry?clientId=${this.client?.id}${this.asset?.id ? `&propertyId=${this.asset.id}` : ''}${
						this.association?.id ? `&associationId=${this.association.id}` : ''
					}`
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clickable {
		cursor: pointer;
	}
</style>