<!--
	@name app-client-service-home-reversion-plan
	@description Home Reversion Plan
	@date 2020/07/17
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<div>
		<!-- LOADING -->
		<v-dialog v-model="loading" persistent width="300">
			<v-card color="white">
				<v-card-text>
					Loading
					<v-progress-linear indeterminate color="black" class="mt-2 mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- CASE TRACKING -->
		<!-- hasReferral set to false to not display in built referral -->
		<app-client-service-template id="serviceTemplate" ref="serviceTemplate" :has-referral="false">
			<template slot="header">
				<app-client-service-header
					:header="'Home Reversion Plan'"
					:subheader="'Crown Equity Release'"
					:link="'crownequityrelease.com/'"
					:url="'https://crownequityrelease.com/'"
					:logo="'9cf60580-6c92-45c8-bbcc-fee9815c5e96.png'"
				/>
			</template>
			<!-- HOME REVERSION PLAN REFERRAL -->
			<template slot="copy">
				<v-row class="justify-center">
					<v-col cols="12" md="12">
						<p class="text-body-1 font-weight-bold">
							Crown Equity Release is an independent specialist provider of capital, it has been assisting clients on how to get the most out of their retirement - with peace of mind, for over 30 years.
						</p>
						<p class="text-body-1 mb-6">
							Crown Equity Release is a member of the Equity Release Council which puts in place a number of safeguards Crown Equity Release provides you with the opportunity to unlock equity in your home.
						</p>

						<div class="mt-6">
							<app-client-service-home-reversion-plan-referral v-if="client" :client="client" :association="association" :asset-id="workflowAsset?.id" :submitting="submitting" @submit="submit" />
						</div>
					</v-col>
				</v-row>
			</template>
		</app-client-service-template>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import AppClientServiceHomeReversionPlanReferral from '@/component/app/client/service/home-reversion-plan/referral';
	import AppClientServiceTemplate from '@/component/app/client/service/template';
	import AppClientServiceHeader from '@/component/app/client/service/header';

	export default {
		name: 'app-client-service-home-reversion-plan',

		components: { AppClientServiceTemplate, AppClientServiceHomeReversionPlanReferral, AppClientServiceHeader },

		props: {
			client: { type: Object, required: false, default: null },
			association: { type: Object, required: false, default: null },
			assetList: { type: Array, required: false, default: null },
			workflowAsset: { type: Object, required: false, default: null },
			items: { type: Array, required: false, default: null }
		},

		data() {
			return {
				active: false,
				loading: false,
				submitting: false
			};
		},

		computed: {
			referrals() {
				if (!this.items || !this.assetList) return [];
				return this.items;
			}
		},

		// created() {
		// 	this.loadHomeReversionPlans();
		// },

		methods: {
			...mapActions('AppHomeReversionPlan', ['submitHomeReversionPlan', 'loadHomeReversionPlanList']),

			// loadHomeReversionPlans() {
			// 	const entityIds = [this.client.id];
			// 	if (this.association) entityIds.push(this.association.id);
			// 	return this.loadHomeReversionPlanList({ entityIds }).then(({ data }) => (this.items = data));
			// },

			open() {
				this.loading = true;
				this.$nextTick().then(() => {
					this.$refs.serviceTemplate.open({ formSchema: [], formData: [], referrals: this.referrals });
					this.loading = false;
				});
			},

			/**
			 * @name submit
			 * @description Fetch client
			 */
			submit(payload) {
				this.submitting = true;

				this.submitHomeReversionPlan(payload)
					.then(() => {
						this.submitting = false;
						this.$refs.serviceTemplate.submitSuccess();
						this.$refs.serviceTemplate.refresh();
						this.$emit('submit');
					})
					.catch(() => this.$refs.serviceTemplate.submitError());
			},

			openServiceTemplate() {
				this.$refs.serviceTemplate.open({ formSchema: [], initialFormData: [], referrals: this.referrals });
			},

			close() {
				this.active = false;
				this.$router.replace({ query: null });
			}
		}
	};
</script>
