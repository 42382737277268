<!--
	@name app-air-club-my-club
	@description My club portion of hte air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div v-if="display" :component="$options.name">
		<common-structure-section>
			<template #header>
				<span v-if="!membership('club')" />
				<span v-else-if="membership('club').approve">Maximise your membership</span>
				<span v-else>Why {{ site.submissionRoutes.default }}</span>
				<v-chip color="#470048" label dark small style="position: absolute; top: -8px; right: -8px">
					Promoted
				</v-chip>
			</template>
			<template #body>
				<v-skeleton-loader v-if="!membership('club')" type="list-item-three-line" />
				<p class="text-h5 font-weight-bold mb-2">
					{{ contentClub.carouselTitle }}
				</p>
				<div class="text-body-2 last--child-mb-0 mb-1" v-html="$sanitize(contentClub.carouselCopy)" />
				<a :href="brochureUrl" target="_blank" class="text-body-2 font-weight-bold d-block mb-4">
					Click here to explore the full Air Club proposition
				</a>
				<v-carousel v-model="model" height="220" cycle hide-delimiters class="rounded">
					<v-carousel-item v-for="(item, i) in contentClub.carouselItems" :key="i" class="background-rotation">
						<div class="fill-height">
							<v-row class="fill-height" align="center" justify="center">
								<div class="text-h4 font-weight-regular white--text text-center" style="width: 70%">
									{{ item }}
								</div>
							</v-row>
						</div>
					</v-carousel-item>
				</v-carousel>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { mapGetters, mapState } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-air-club-my-club',

		components: { CommonStructureSection },

		data: () => ({
			showStatusInfo: true,
			model: 0
		}),
		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership', 'tier']),
			...mapGetters('CmsSite', ['restrictedRoutes']),
			...mapGetters('CmsContent', ['contentClub']),

			display() {
				if(!this.contentClub.carouselTitle || !this.contentClub.carouselCopy || !this.contentClub.carouselItems) return false;
				return true;
			},

			brochureUrl() {
				return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/document/Air+Club+Brochure.pdf`;
			}
		}
	};
</script>

<style scoped lang="scss">
	.last--child-mb-0 {
		::v-deep p:last-child {
			margin-bottom: 0px;
		}
	}

	.status {
		&__section {
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			.v-alert {
				margin-bottom: 0;
			}

			p {
				display: none;
			}
		}
	}

	.status_expanded {
		.status {
			&__section {
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}

				.v-alert {
					margin-bottom: 0;
				}

				p {
					display: block;
					margin-top: 8px;
				}
			}
		}
	}
</style>
