<!--
	@name common-base-tag
	@description Show a set of tags from array
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<div class="d-flex align-center" :component="$options.name">
		<span class="nowrap">
			<v-checkbox
				:input-value="allSelected"
				:indeterminate="someSelected"
				:label="checkboxLabel"
				color="primary"
				class="ma-0 pa-0"
				@change="toggleAll"
			/>
		</span>
		<v-divider vertical inset class="ml-4 mr-4" />
		<v-chip-group v-model="activeTags" :active-class="activeClass" multiple column>
			<v-chip v-for="tag in tags" :key="tag.name" :value="tag.name" label outlined class="elevation-2 font-weight-medium">
				{{ tag.name }}
			</v-chip>
		</v-chip-group>
	</div>
</template>

<script>
	export default {
		name: 'common-base-tag',

		props: {
			tags: { type: Array, required: true },
			activeClass: { type: String, required: false, default: 'primary' }
		},

		data() {
			return {
				activeTags: []
			};
		},

		computed: {
			/**
			 * @name allSelected
			 * @description Checks if all tags are selected
			 * @return {Boolean}
			 */
			allSelected() {
				return this.tags.length === this.activeTags.length;
			},

			/**
			 * @name someSelected
			 * @description Checks if some of the tags are selected
			 * @return {Boolean}
			 */
			someSelected() {
				return this.activeTags.length !== 0 && !this.allSelected;
			},

			/**
			 * @name noneSelected
			 * @description Checks if none of the tags are selected
			 * @return {Boolean}
			 */
			noneSelected() {
				return !this.activeTags.length || this.activeTags.length === 0;
			},

			/**
			 * @name checkboxLabel
			 * @description Returns a checkbox label according to tags state
			 * @return {String}
			 */
			checkboxLabel() {
				return this.allSelected ? 'Clear All' : 'Show All';
			}
		},

		watch: {
			activeTags: {
				handler() {
					this.$emit('update-tags', this.activeTags);

					if (this.allSelected || this.noneSelected) {
						if (this.$route.query.tag) this.$router.replace({ query: null });
					} else if (this.activeTags.join(',') !== this.$route.query.tag) this.$router.push({ query: { tag: this.activeTags.join(',') } });
				}
			}
		},

		mounted() {
			let tags = this.$route.query.tag;

			if (tags && tags.length > 0) this.activeTags = this.$route.query.tag.split(',');
			else this.toggleAll();
		},

		methods: {
			/**
			 * @name toggleAll
			 * @description TODO
			 */
			toggleAll() {
				let vm = this;

				if (!this.allSelected) {
					vm.activeTags = [];
					this.tags.map((tag) => this.activeTags.push(tag.name));
				} else vm.activeTags = [];
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-input__slot {
		margin: 0 !important;
	}
	::v-deep .v-messages {
		display: none;
	}
	.v-divider--vertical.v-divider--inset {
		margin-bottom: 8px;
	}
</style>
