import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Communication
 * @exports Service/Account/OrganisationCommunication
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class OrganisationCommunication {
	/**
	 * @static @name modify
	 * @description Update some Organisation communication for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`account/organisation/communication/${id}`, payload);
	}
}
