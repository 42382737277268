/**
 * @namespace Mixins
 * @exports Mixins/removeIntroResource
 * @description Course video common methods
 * @date 2024/05/03
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default {
	data() {
		return {
			removeIntroVideo: false
		};
	},

	computed: {
		introResource() {
			const foundResource = this.resources.find((resource) => resource.useAsIntro === true && resource.workflowTemplateItem.find((wti) => wti?.nameUnique === this.section));
			return foundResource
				? { video: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/${foundResource.video}`, poster: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/${foundResource.image}` }
				: null;
		}
	},

	methods: {
		toggleIntroResource() {
			this.removeIntroVideo = !this.removeIntroVideo;
		}
	}
};
