/**
 * @namespace Utils
 * @method $http
 * @exports Utils/postMessage
 * @description Post message helper methods
 * @date 2021/02/15
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

import { EventBus } from '@/utils';

let hash;
let host;

/**
 * @name _initListener
 * @description Initialize message
 */
const _initListener = () => {
	window.addEventListener(
		'message',
		function(ev) {
			// parse response
			let response;
			try {
				response = JSON.parse(ev.data);
			} catch (e) {
				return;
			}

			// first handshake
			if (response.action === 'handshake' && !hash && !host) {
				// do something here
				hash = response.data;
				host = ev.origin;

				// return message to complete handshake
				_handshake();
			}

			// process actions
			if (hash !== response.hash) return;

			EventBus.$emit(`message:${response.action.toLowerCase()}`, response.data);
		},
		false
	);
};

const _handshake = () => {
	window.opener.postMessage(JSON.stringify({ hash, action: 'handshake', data: { message: 'Handshake Success' } }), host);
};

class PostMessage {
	origin = undefined;

	constructor(config) {
		if (!config || !config.origin) throw 'Origin is required';

		this.origin = config.origin;
		_initListener();
		this.send('loaded');
	}

	send(action, data) {
		if (window.opener) window.opener.postMessage(JSON.stringify({ hash, action, data }), this.origin);
	}
}

export default PostMessage;
