import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Api
 * @exports Service/Account/Organisation/Api
 * @description API Organisation API user Service
 * @date 2024/08/02
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Api {
	/**
	 * @static @name get
	 * @description Fetch organisation API user
	 * @param {String} oid
	 * @returns {Object} Promise a http response
	 */
	static get(oid) {
		return $http.get(`account/organisation/${oid}/user/api`);
	}
}
