import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Configuration
 * @exports Service/Cms/Menu
 * @description API Configuration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Menu {
	/**
	 * @static @name get
	 * @description Get Menu
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(name) {
		return $http.get(`cms/menu/${name}`);
	}
}
