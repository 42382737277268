<!--
	@name app-dialog-contact
	@description Show a dialog for news item
	@date 2020/03/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template v-if="item" #header>
			{{ item.provider ? item.provider : 'News' }}
		</template>
		<template v-if="item" #body>
			<p class="text-subtitle-1 font-weight-bold">
				{{ item.headline }}
			</p>
			<p class="text-body-1 text-pre-line" v-html="autoLink($sanitize(item.story))" />
			<p class="text-subtitle-2 font-italic mb-0">
				Posted: {{ $moment(String(item.updated)).format('ddd, D MMM YYYY') }}
			</p>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import Autolinker from 'autolinker';

	export default {
		name: 'app-dialog-news',

		components: { CommonDialog },

		data: () => ({
			item: null
		}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open(item) {
				this.item = item;
				this.$refs.dialog.open();
			},

			/**
			 * @name autoLink
			 * @description Purify html
			 */
			autoLink(text) {
				return Autolinker.link(text, { stripPrefix: false });
			}
		}
	};
</script>
