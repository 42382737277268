import { CmsCpdTrainingItem } from '@/service';

/**
 * @class @name menu
 * @description Exposes menu store module
 */
export default class Menu {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			cpdTrainingItems: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setItems
			 * @param {Object} state
			 * @param {Object} data
			 */
			setItems: (state, data) => (state.cpdTrainingItems = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadCpdTrainingItems
			 * @description used to fetch cpd training items
			 * @param {function} commit
			 */
			async loadCpdTrainingItems({ commit }, payload) {
				const { data } = await CmsCpdTrainingItem.list(payload);
				commit('setItems', data.data);
			},

			/**
			 * @async @name addNewCpdTrainingItem
			 * @param {Object} payload
			 */
			async addNewCpdTrainingItem(context, payload) {
				return CmsCpdTrainingItem.post(payload);
			},

			/**
			 * @async @name modifyCpdTrainingItem
			 * @param {String} id
			 * @param {Object} payload
			 */
			async modifyCpdTrainingItem(context, payload) {
				return CmsCpdTrainingItem.patch(payload.id, payload);
			},

			/**
			 * @async @name deleteCpdTrainingItem
			 * @param {function} commit
			 */
			// eslint-disable-next-line no-unused-vars
			async deleteCpdTrainingItem({ commit }, id) {
				return CmsCpdTrainingItem.delete(id);
			}
		};
	}
}
