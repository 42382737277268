<!--
	@name app-layout-default
	@description Front page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<!-- header -->
		<app-layout-default-header @open-navigation="openNavigation" />
		<app-layout-default-sidebar ref="sidebar" />

		<!-- content -->
		<v-main>
			<app />
		</v-main>

		<!-- footer -->
		<app-layout-default-footer />
	</v-app>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import App from '@/component/app';
	import AppLayoutDefaultHeader from './header';
	import AppLayoutDefaultSidebar from './sidebar';
	import AppLayoutDefaultFooter from './footer';

	export default {
		name: 'app-layout-default',

		components: {
			App,
			AppLayoutDefaultHeader,
			AppLayoutDefaultSidebar,
			AppLayoutDefaultFooter
		},

		computed: {
			...mapState('CmsMenu', ['frontMenu'])
		},

		created() {
			if (!this.frontMenu) this.loadFrontMenu();
		},

		methods: {
			...mapActions('CmsMenu', ['loadFrontMenu']),

			openNavigation() {
				this.$refs.sidebar.openNavigation();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		background: #fafafa !important;
	}
</style>
