import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Debit
 * @exports Service/App/Reward/Debit
 * @description API Reward Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Debit {
	/**
	 * @static @name debitList
	 * @description Add a new reward resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`reward/debit/list`, payload);
	}
}
