<!--
	@name common-dialog-video
	@description Display an on air article by ID
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			{{ displayItem.title }}
		</template>

		<template #body>
			<h2 class="mb-2 text-h5 font-weight-bold">
				{{ displayItem.title }}
			</h2>
			<div class="text-body-1 font-weight-medium" v-html="$sanitize(displayItem.abstract)" />
			<video v-if="displayItem.video" class="d-block mt-5" :src="displayItem.video | urlize" controls width="100%" />
			<v-img
				v-else-if="displayItem.image"
				:src="displayItem.image | urlize"
				class="align-end mt-5"
				gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
				height="200px"
				cover
			/>
			<div v-if="displayItem.description" class="text-body-1 mt-5" v-html="$sanitize(displayItem.description)" />
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import { EventBus } from '@/utils';

	export default {
		name: 'common-dialog-video',

        components: {
			CommonDialog
		},

		data() {
			return {
				loading: false,
                displayItem: {
                    title: null,
                    abstract: null,
                    video: null,
                    description: null
                }
            };
        },

        computed: {},

		methods: {
            /**
			 * @name init
			 * @description init component
			 */
			init(payload) {
                this.displayItem = payload;
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description Close dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

            /**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>
