import { $http } from '@/utils';

/**
 * @namespace Service
 * @class UserOrganisationTradingStyle
 * @exports Service/Account/UserOrganisationTradingStyle
 * @description API User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserOrganisationTradingStyle {
	/**
	 * @static @name add
	 * @description Add a new User resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/user/organisation-trading-style`, payload);
	}


	/**
	 * @static @name list
	 * @description Get all organisation trading styles with details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list() {
		return $http.get(`account/user/organisation-trading-style`);
	}

	/**
	 * @static @name activateUserOrganisationTradingStyle
	 * @description Makes a user's trading style active
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static activateUserOrganisationTradingStyle(payload) {
		return $http.post(`account/user/organisation-trading-style/activate-trading-style`, payload);
	}

	/**
	 * @static @name add or modify
	 * @description Add a new User resource or modify and existing one
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static update(payload) {
		return $http.put(`account/user/organisation-trading-style/update`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete User details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/user/organisation-trading-style/${id}`);
	}
}
