<!--
	@name app-comprehensive-conversations-report
	@description Comprehensive Conversations Report
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div data-shorthand="https://stories.rostrum.agency/comprehensive-conversations-main-report-2024" />
	</div>
</template>

<script>
	export default {
		name: 'app-comprehensive-conversations-report',

		components: {},

		data() {
			return {};
		},

		computed: {},

		created() {},

		mounted() {
			document.querySelectorAll('[data-shorthand]').forEach(function(embed) {
				var url = embed.getAttribute('data-shorthand');
				if (url.indexOf('http') === -1) url = 'https://' + url;
				var script = document.createElement('script');
				script.src = url.replace(/\/?$/, '/embed.js');
				embed.parentElement.insertBefore(script, embed);
			});
		},

		methods: {}
	};
</script>

<style lang="scss">
	@import '~vuetify/src/styles/settings/_variables';

	.Story-Header {
		margin-top: 140px !important;

		@media #{map-get($display-breakpoints, 'sm-and-down')} {
			margin-top: 100px !important;
		}
	}
</style>
