<!--
	@name common-dialog-product-criteria
	@description Product criteria dialog
	@date 2020/05/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" @closed="closed">
		<template #header>
			<span>Product Criteria</span>
		</template>

		<template #header-action>
			<v-btn icon :disabled="!criteria" @click="print">
				<v-icon>mdi-printer</v-icon>
			</v-btn>
		</template>

		<template #body>
			<div v-if="error">
				<v-card-text class="text-center">
					<v-icon class="text-h1" color="error">
						mdi-exclamation
					</v-icon>

					<v-card-title class="text-h6 d-block pb-5">
						An unexpected error occurred
					</v-card-title>

					<v-card-title v-if="site" class="text-subtitle-1 d-block pt-0">
						If this problem continues, please contact our support team on {{ site.contact.number }}.
					</v-card-title>
				</v-card-text>
			</div>

			<div v-else-if="product" id="product-criteria">
				<v-card-title class="text-h6 pa-0 product-criteria-title">
					{{ (product.provider || {}).name }}
				</v-card-title>

				<v-card-title class="text-subtitle-1 pa-0 product-criteria-subtitle">
					{{ product.name }}
				</v-card-title>

				<div v-if="criteria">
					<v-simple-table v-for="(table, key) in criteria" :key="key" class="mt-3 product-criteria-table">
						<thead class="blue-grey lighten-4 product-criteria-table-head">
							<tr>
								<th class="text-left product-criteria-table-title" colspan="2">
									{{ table.title }}
								</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(row, key) in table.rows" :key="key" class="product-criteria-table-row">
								<td class="font-weight-bold product-criteria-table-row-title" style="width: 200px">
									{{ row.title }}
								</td>
								<td class="product-criteria-table-row-value">
									{{ row.value }}
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</div>

				<div v-else-if="notes">
					<v-expansion-panels class="mt-6">
						<v-expansion-panel v-for="(note, index) in formattedNotes" :key="index">
							<v-expansion-panel-header disable-icon-rotate class="font-weight-medium">
								{{ note.heading }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<span v-if="!note.noteText.length"> No further detail supplied. </span>
								<span v-else>
									<p v-for="(text, index) in note.noteText" :key="index" class="mt-3 mb-0 text-body-2" v-html="autoLink($sanitize(text))" />
								</span>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>

				<div v-else>
					<v-card-text>
						<v-card-text class="text-center">
							<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
						</v-card-text>
					</v-card-text>
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { PopupWindow } from '@/utils';
	import { orderBy } from 'lodash';
	import html2pdf from 'html2pdf.js';
	import CommonDialog from '@/component/common/dialog';
	import DOMPurify from 'dompurify';
	import Autolinker from 'autolinker';

	export default {
		name: 'common-dialog-product-criteria',

		components: { CommonDialog },

		data() {
			return {
				product: null,
				notes: null,
				criteria: null,
				error: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			/**
			 * @name formattedNotes
			 * @description order note by sequenceNumber and remove any empty notes
			 */
			formattedNotes() {
				if (this.notes === null) return null;
				return orderBy(this.notes, 'sequenceNumber', ['asc']).map((note) => {
					const notes = note.noteText.filter((note) => note.length > 0) || [];
					return { ...note, noteText: notes };
				});
			}
		},

		methods: {
			...mapActions('AppProduct', ['fetchProductCriteria', 'fetchProductNotes']),

			/**
			 * @name autoLink
			 * @description Purify html
			 */
			autoLink(text) {
				return Autolinker.link(text, { stripPrefix: false });
			},

			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open(iterationId, product) {
				this.product = product;
				this.$refs.dialog.open();

				if (product.productNotes) {
					this.fetchProductNotes({ iterationId, productId: product.id })
						.then((response) => (this.notes = response.data.productNotes))
						.catch(() => (this.error = true));
				} else {
					this.fetchProductCriteria(product.config?.airProductId || product.id)
						.then((response) => (this.criteria = response.data.criteria))
						.catch(() => (this.error = true));
				}
			},

			/**
			 * @name closed
			 * @description Dialog closed callback
			 */
			closed() {
				this.error = false;
				this.criteria = null;
				this.notes = null;
			},

			/**
			 * @name print
			 * @description TODO
			 */
			print() {
				const element = document.querySelector('#product-criteria').cloneNode(true);
				element.classList.add('product-criteria-print');

				const opt = { pagebreak: { mode: 'avoid-all' }, margin: [2, 0], image: { quality: 1 } };
				html2pdf()
					.set(opt)
					.from(element)
					.toPdf()
					.get('pdf')
					.then((pdf) => new PopupWindow().open(pdf.output('bloburl'), '_blank'));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.product-criteria-print {
		margin: 2.5rem;

		.product-criteria-title {
			padding-bottom: 0 !important;
			font-weight: bold;
		}

		.product-criteria-subtitle {
			padding: 0 0 0rem 1rem !important;
			margin-bottom: 1em;
		}

		.product-criteria-table {
			margin: 0 1rem 1.5rem 1rem;

			.product-criteria-table-head {
				background-color: #1c1d36;

				.product-criteria-table-title {
					color: #ffffff;
					font-size: 1.2rem !important;
					font-weight: bold !important;
				}
			}

			.product-criteria-table-row {
				&:nth-child(odd) {
					background-color: #ebebed;
				}

				&:nth-child(even) {
					background-color: #ffffff;
				}

				.product-criteria-table-row-title {
					color: #1c1d36;
					font-weight: bold !important;
					font-size: 0.9rem !important;
					background-color: #d8eed7;
					width: 20% !important;
					border-bottom: none !important;
				}

				.product-criteria-table-row-value {
					font-weight: normal !important;
					font-size: 0.9rem !important;
					border-bottom: none !important;
				}
			}
		}
	}

	.product-criteria-table-row-value {
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}
</style>
