/**
 * @class @name ActionHelper
 * @description Action common methods
 */
export default class ActionHelper {
	/**
	 * @name getLatestStatusFromActions
	 * @param {Array} action
	 * @return {String} The latest status
	 */
	static getLatestStatusFromActions(actions) {
		const statusActions = actions.filter((action) => action.type === 'status');
		if (statusActions.length)
			return statusActions.reduce((a, b) => (a.type === 'status' && b.type === 'status' && Date.parse(a.created) > Date.parse(b.created) ? a : b)).data.status;
		return undefined;
	}
}
