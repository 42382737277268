import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Criterion
 * @exports Service/Partner/Criterion
 * @description API Partner Criterion Service
 * @date 2022/10/07
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Criterion {
	/**
	 * @static @name applications
	 * @description Create application request
	 * @returns {Object} Promise a http response
	 */
	static applications(provider, payload) {
		return $http.post(`partner/criterion/${provider}/applications`, payload);
	}
}
