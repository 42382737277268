<!--
	@name app-calculator-borrowing-forecast-tool
	@description Account settings - Account security tab content
	@date 2020/05/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog v-model="borrowingForecastActiveModel" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Borrowing Forecast
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon dark class="mr-2" v-on="on" @click="openManual">
										<v-icon> mdi-frequently-asked-questions </v-icon>
									</v-btn>
								</template>
								<span>Frequently asked questions</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon dark class="mr-2" v-on="on" @click="openAdvancedSettings">
										<v-icon> mdi-cog </v-icon>
									</v-btn>
								</template>
								<span>Advanced Features</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon dark class="mr-2" v-on="on" @click="printResults">
										<v-icon>mdi-printer</v-icon>
									</v-btn>
								</template>
								<span>Print PDF</span>
							</v-tooltip>
							<v-btn icon dark class="align-self-center" @click="close('borrowingForecast')">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 pt-10 ma-0 mx-auto" style="max-width: 1400px">
					<common-structure-section v-if="forms" no-top-radius :body-class="'flex-grow-1'">
						<template #body>
							<v-sheet v-if="[0, 1].includes(options.calculatorType)">
								<v-snackbar v-model="paymentsPrompt" color="info" timeout="-1" top>
									HINT: To quickly add consistent payments across all years, scroll up and update the "with monthly payments of" value
									<template #action="{ attrs }">
										<v-btn icon v-bind="attrs" @click="paymentsPrompt = false">
											<v-icon>mdi-close-circle</v-icon>
										</v-btn>
									</template>
								</v-snackbar>

								<a class="d-block title mb-6 text-decoration-underline" @click="resetCalculatorType">
									&laquo; Currently in <span class="font-weight-medium text-uppercase">{{ calculatorTypeOptions[options.calculatorType].title }}</span> mode.
									Click to change
								</a>

								<!-- Borrowing Forecast -->
								<v-sheet v-show="options.calculatorType === 0">
									<v-item-group v-model="selectedOption" class="d-flex mt-n3 mb-8" mandatory>
										<v-row>
											<v-col v-for="(option, index) in compareOptions" :key="index" cols="12" md="4">
												<v-item v-slot="{ active, toggle }">
													<v-card
														class="text-h6 pa-6 text-center"
														height="100%"
														:class="active ? 'card--active' : 'grey lighten-2'"
														@click="toggle"
													>
														<span class="body-2 d-block">
															{{ option.subtitle }}
														</span>
														<v-list-item :two-line="option.title.length <= 20" :three-line="option.title.length > 30">
															<v-list-item-subtitle class="text-h6 line-height-inherit" :class="{ 'black--text': active }">
																{{ option.title }}
															</v-list-item-subtitle>
														</v-list-item>
													</v-card>
												</v-item>
											</v-col>
										</v-row>
									</v-item-group>

									<!-- Step 1 - Product 1 -->
									<div v-show="selectedOption === 0" class="text-center mb-10">
										<div id="forecastBlurb1">
											<dynamic-natural-language-form
												:form-schema="forms.borrowingForecastTool"
												:form-data="productData"
												text-class="text-h5 font-weight-medium"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
											<div v-if="productData.rateChanges?.length" class="text-body-1 px-3 mt-4 grey--text text--darken-3">
												*Interest rate changes to
												<span class="font-weight-bold">
													{{ productData.rateChanges[0].rate }}%
												</span>
												at beginning of month
												<span class="font-weight-bold">
													{{ productData.rateChanges[0].start }}
												</span>
											</div>
										</div>
									</div>

									<!-- Step 2 - Product 2 -->
									<div v-show="selectedOption === 1" class="text-center mb-10">
										<v-container fluid class="d-flex justify-center">
											<v-switch v-model="options.comparisonActive" hide-details inset class="flex-center ma-0 comparisonActivator">
												<template #label>
													Click to activate product comparison
												</template>
											</v-switch>
										</v-container>
										<div id="forecastBlurb2">
											<dynamic-natural-language-form
												v-show="comparisonIsActive"
												:form-schema="forms.borrowingForecastToolCompare"
												:form-data="comparisonProductData"
												text-class="text-h5 font-weight-medium mt-6"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
											<div v-if="comparisonProductData.rateChanges?.length" class="text-body-1 px-3 mt-4 grey--text text--darken-3">
												*Interest rate changes to
												<span class="font-weight-bold">
													{{ comparisonProductData.rateChanges[0].rate }}%
												</span>
												at beginning of year
												<span class="font-weight-bold">
													{{ comparisonProductData.rateChanges[0].start }}
												</span>
											</div>
										</div>
									</div>

									<!-- Step 3 - Property -->
									<div v-show="selectedOption === 2" class="text-center mb-10">
										<v-container fluid class="d-flex justify-center">
											<v-switch v-model="options.propertyActive" hide-details inset class="flex-center ma-0 comparisonActivator">
												<template #label>
													Click to activate property value forecast
												</template>
											</v-switch>
										</v-container>
										<div id="forecastBlurb3">
											<dynamic-natural-language-form
												v-show="options.propertyActive"
												:form-schema="forms.borrowingForecastToolProperty"
												:form-data="propertyData"
												text-class="text-h5 font-weight-medium mt-6"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
										</div>
									</div>
								</v-sheet>

								<!-- Rebroke -->
								<v-sheet v-show="options.calculatorType === 1">
									<v-item-group v-model="selectedOption" class="d-flex mt-n3 mb-8" mandatory>
										<v-row>
											<v-col v-for="(option, index) in rebrokeOptions" :key="index" cols="12" md="4">
												<v-item v-slot="{ active, toggle }">
													<v-card
														class="text-h6 pa-6 text-center"
														height="100%"
														:class="active ? 'card--active' : 'grey lighten-2'"
														@click="toggle"
													>
														<span class="body-2 d-block">
															{{ option.subtitle }}
														</span>
														<v-list-item :two-line="option.title.length <= 20" :three-line="option.title.length > 30">
															<v-list-item-subtitle class="text-h6 line-height-inherit" :class="{ 'black--text': active }">
																{{ option.title }}
															</v-list-item-subtitle>
														</v-list-item>
													</v-card>
												</v-item>
											</v-col>
										</v-row>
									</v-item-group>

									<!-- Step 1 - Product 1 -->
									<div v-show="selectedOption === 0" class="text-center mb-10">
										<div id="forecastBlurb4">
											<dynamic-natural-language-form
												:form-schema="forms.borrowingForecastToolExistingProduct"
												:form-data="comparisonProductData"
												text-class="text-h5 font-weight-medium"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
										</div>
									</div>

									<!-- Step 2 - Product 2 -->
									<div v-show="selectedOption === 1" class="text-center mb-10">
										<div id="forecastBlurb5">
											<dynamic-natural-language-form
												:form-schema="forms.borrowingForecastToolNewProduct"
												:form-data="productData"
												text-class="text-h5 font-weight-medium mt-6"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
										</div>
									</div>

									<!-- Step 3 - Property -->
									<div v-show="selectedOption === 2" class="text-center mb-10">
										<v-container fluid class="d-flex justify-center">
											<v-switch v-model="options.propertyActive" hide-details inset class="flex-center ma-0 comparisonActivator">
												<template #label>
													Click to activate property value forecast
												</template>
											</v-switch>
										</v-container>
										<div id="forecastBlurb6">
											<dynamic-natural-language-form
												v-show="options.propertyActive"
												:form-schema="forms.borrowingForecastToolProperty"
												:form-data="propertyData"
												text-class="text-h5 font-weight-medium mt-6"
												text-style="line-height:2.5rem;"
												:submit-button="false"
											/>
										</div>
									</div>
								</v-sheet>

								<div id="screenshot_target">
									<p class="font-weight-regular mb-5 pr-2 text-right">
										<span class="blue darken-4 white--text d-inline-block px-2 rounded">
											{{ options.calculatorType === 0 ? 'Product One' : 'New Product' }}
										</span>
										<span v-if="comparisonIsActive" class="white--text d-inline-block px-2 ml-2 rounded" style="background-color: rgb(23, 184, 144);">
											{{ options.calculatorType === 0 ? 'Product Two' : 'Existing Product' }}
										</span>
										<span v-if="options.propertyActive" class="info white--text d-inline-block px-2 ml-2 rounded"> Property Value </span>
									</p>

									<div class="graph pa-5 pt-6 rounded">
										<line-chart
											ref="lineChart"
											:chart-data="chartData"
											:options="chartOptions"
											:width="'100%'"
											:height="'350'"
											@line-chart-mounted="lineChartMounted"
										/>
									</div>

									<v-alert v-if="displayTermWarning" type="warning" text outlined dense class="mt-5">
										Please note: the term shown here is less than 15 years which is usually shown as a default minimum term for illustration purposes.
									</v-alert>

									<p class="caption mt-3 mb-0">
										Use the options below to switch the graph display from total cost to loan balance
									</p>
									<v-btn-toggle v-model="toggleGraphDataSource" mandatory active-class="primary--text" dense class="mt-1">
										<v-btn small>
											Total cost
										</v-btn>
										<v-btn small>
											Loan balance
										</v-btn>
									</v-btn-toggle>
								</div>

								<p class="mt-10 text-body-2 font-weight-bold">
									Click either the drawdowns or payments column for any year and enter an amount to adjust the forecast. Both the calculator and graph will
									update automatically.
									<span class="info--text">To add consistent payments across all years, scroll up and update the "with monthly payments of" value.</span>
									Hover over the "Drawdown" or "Isolate" column headings for instructions on how to make best use of these features.
								</p>

								<v-sheet v-if="advancedFeaturesActive" class="mb-5">
									<v-alert type="info" border="left" colored-border elevation="4" class="mt-5 mb-5 body-2 info--text font-weight-bold">
										One or more of your chosen options has opened up advanced features below
									</v-alert>
									<ul class="body-2 ml-8 info--text" style="line-height: 1.5rem">
										<li>
											<strong>Voluntary payments:</strong>
											To set a consistent payment over the entire term, enter the payment value in the "with monthly payments of" option above. You
											can alter the payment in any individual year by clicking the <v-icon>mdi-lock-outline</v-icon> icon and entering your desired
											amount. With the <v-icon>mdi-lock-open</v-icon> icon visible, any ad-hoc amounts entered will take precedence.
											<span class="font-weight-medium">Resetting the lock icon will immediately revert the payment to the annual amount.</span>
										</li>
									</ul>
								</v-sheet>

								<v-simple-table v-if="dataSet.length" class="mt-3 table--styled elevation-3" :class="{ 'footer-active': selectedRows.length }">
									<template #default>
										<thead>
											<tr>
												<th :colspan="drawdownsActive ? '3' : '2'" class="text-center" />
												<th :colspan="displayProduct1Payments ? '4' : '3'" class="loan text-center">
													{{ options.calculatorType === 0 ? 'Product One' : 'New Product' }}
													<span class="d-block caption"> initial balance £{{ dataSet[0].loanBalanceFirst | conditionalDecimal }} </span>
												</th>
												<th v-show="comparisonIsActive" />
												<th v-show="comparisonIsActive" :colspan="displayProduct2Payments ? '4' : '3'" class="compare text-center">
													{{ options.calculatorType === 0 ? 'Product Two' : 'Existing Product' }}
													<span class="d-block caption"> initial balance £{{ dataSet[0].comparisonBalanceFirst | conditionalDecimal }} </span>
												</th>
												<th v-show="options.propertyActive" colspan="2" class="property text-center">
													Property
													<span class="d-block caption"> starting valuation £{{ dataSet[0].priceFirst | conditionalDecimal }} </span>
												</th>
											</tr>
											<tr>
												<th>Year</th>
												<th class="text-center isolate">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on">Isolate </span>
														</template>
														<span>Isolate certain years in order to shorten the table and see with immediate effect how your edits are affecting the
															graph</span>
													</v-tooltip>
												</th>
												<th v-show="drawdownsActive">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on"> Drawdowns </span>
														</template>
														<span>Drawdows are purely illustrative as they are based on the initial AER</span>
													</v-tooltip>
												</th>
												<th class="loan text-center">
													Interest
												</th>
												<th v-show="displayProduct1Payments" class="loan" :class="{ 'text-center': !product1PaymentsEditable }">
													Payments
												</th>
												<th class="loan text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on"> Balance </span>
														</template>
														<span>Loan balance at year end, accounting for roll-up interest, payments and drawdowns</span>
													</v-tooltip>
												</th>
												<th class="loan text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on"> Cost </span>
														</template>
														<span>Total cost at year end, accounting for roll-up interest and payments and drawdowns</span>
													</v-tooltip>
												</th>
												<th v-show="comparisonIsActive" />
												<th v-show="comparisonIsActive" class="compare text-center">
													Interest
												</th>
												<th
													v-show="comparisonIsActive && displayProduct2Payments"
													class="compare"
													:class="{ 'text-center': !product2PaymentsEditable }"
												>
													Payments
												</th>
												<th v-show="comparisonIsActive" class="compare text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on"> Balance </span>
														</template>
														<span>Loan balance at year end, accounting for roll-up interest, payments and drawdowns</span>
													</v-tooltip>
												</th>
												<th v-show="comparisonIsActive" class="compare text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="font-weight-black text-decoration-underline" v-on="on"> Cost </span>
														</template>
														<span>Total cost at year end, accounting for roll-up interest and payments and drawdowns</span>
													</v-tooltip>
												</th>
												<th v-show="options.propertyActive" class="property text-center">
													Valuation
												</th>
												<th v-show="options.propertyActive" class="equity text-center">
													Equity
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in dataSet" v-show="!isolated || selectedRows.includes(index)" :key="index">
												<td>{{ index + 1 }}</td>

												<td class="isolate">
													<div class="d-flex justify-center" style="min-width: 48px">
														<v-switch
															class="ma-0 pa-0 mr-n3 pr-0"
															color="primary"
															:value="selectedRows.includes(index)"
															dense
															hide-details
															inset
															@change="selectRow($event, index)"
														/>
													</div>
												</td>

												<td v-show="drawdownsActive" class="input">
													<validation-provider v-slot="{ errors }" rules="numeric|decimal:2">
														<v-text-field
															v-model="drawdowns[index]"
															type="text"
															prefix="£"
															:error-messages="errors"
															hide-details
															solo
															dense
															autocomplete="off"
															:disabled="index === 0"
															@blur="tableFieldBlur"
															@input="tableFieldUpdated(drawdowns[index], errors)"
														/>
													</validation-provider>
												</td>

												<td class="loan text-center">
													<span v-if="!item.productOneWithinTerm"> -- </span>
													<span v-else>£{{ item.interest | conditionalDecimal }}</span>
												</td>
												<td v-show="displayProduct1Payments" class="input loan" :class="{ 'text-center': !product1PaymentsEditable }">
													<span v-if="!item.productOneWithinTerm"> -- </span>
													<span v-else-if="!product1PaymentsEditable"> £{{ payments[index] | conditionalDecimal }} </span>
													<template v-else>
														<v-tooltip
															left
															color="deep-orange"
															max-width="300"
															:disabled="monthlyPaymentsZero || paymentsOverrides.includes(index)"
															transition="slide-x-transition"
														>
															<template #activator="{ on }">
																<validation-provider v-slot="{ errors }" rules="decimal:2" name="payment">
																	<v-text-field
																		v-model="payments[index]"
																		type="text"
																		prefix="£"
																		:append-icon="
																			monthlyPaymentsZero ? null : paymentsOverrides.includes(index) ? 'mdi-lock-open' : 'mdi-lock-outline'
																		"
																		:error-messages="errors"
																		:hide-details="errors.length == 0"
																		solo
																		dense
																		:readonly="monthlyPaymentsZero || paymentsOverrides.includes(index) ? false : true"
																		autocomplete="off"
																		@blur="tableFieldBlur"
																		@input="tableFieldUpdated(payments[index], errors, index, 'product')"
																		@click:append="togglePaymentInput(productData.monthlyPayments, payments, paymentsOverrides, index)"
																		@paste="pasted()"
																		v-on="on"
																	/>
																</validation-provider>
															</template>
															<span>Locked</span>
														</v-tooltip>
													</template>
												</td>
												<td class="loan text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="text-decoration-underline" v-on="on"> £{{ item.loanBalanceFinal | numFormat('0,0') }} </span>
														</template>
														<span>Previously £{{ item.loanBalanceFirst | numFormat('0,0') }} as of 1st Jan</span>
													</v-tooltip>
												</td>
												<td
													class="loan text-center text--darken-4 font-weight-medium"
													:class="[
														{ 'red--text': comparisonIsActive && item.loanCost > item.comparisonCost },
														{ 'green--text': comparisonIsActive && item.loanCost < item.comparisonCost }
													]"
												>
													<v-chip
														label
														outlined
														small
														class="font-weight-medium"
														:class="[
															{ 'red': comparisonIsActive && item.loanCost > item.comparisonCost },
															{ 'green darken-2': comparisonIsActive && item.loanCost < item.comparisonCost }
														]"
													>
														£{{ item.loanCost | conditionalDecimal }}
													</v-chip>
												</td>

												<td v-show="comparisonIsActive" class="text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<v-icon
																:class="[
																	{ 'red--text': comparisonIsActive && item.loanCost > item.comparisonCost },
																	{ 'green--text': comparisonIsActive && item.loanCost < item.comparisonCost }
																]"
																v-on="on"
															>
																{{
																	item.loanCost > item.comparisonCost
																		? 'mdi-arrow-up-bold-circle'
																		: item.comparisonCost > item.loanCost
																			? 'mdi-arrow-down-bold-circle'
																			: 'mdi-equal-box'
																}}
															</v-icon>
														</template>
														<span>£{{ Math.abs(item.loanCost - item.comparisonCost) | conditionalDecimal }}
															{{ item.loanCost > item.comparisonCost ? 'loss at' : 'saving by' }} year {{ index + 1 }}</span>
													</v-tooltip>
												</td>

												<td v-show="comparisonIsActive" class="compare text-center">
													<span v-if="!item.productTwoWithinTerm"> -- </span>
													<span v-else>£{{ item.comparisonInterest | conditionalDecimal }}</span>
												</td>
												<td
													v-show="comparisonIsActive && displayProduct2Payments"
													class="input compare"
													:class="{ 'text-center': !product2PaymentsEditable }"
												>
													<span v-if="!item.productTwoWithinTerm"> -- </span>
													<span v-else-if="!product2PaymentsEditable"> £{{ comparisonPayments[index] | conditionalDecimal }} </span>
													<template v-else>
														<v-tooltip
															left
															color="deep-orange"
															max-width="300"
															:disabled="comparisonMonthlyPaymentsZero || comparisonPaymentsOverrides.includes(index)"
															transition="slide-x-transition"
														>
															<template #activator="{ on }">
																<validation-provider v-slot="{ errors }" rules="decimal:2" name="payment">
																	<v-text-field
																		v-model="comparisonPayments[index]"
																		type="text"
																		prefix="£"
																		:append-icon="
																			comparisonMonthlyPaymentsZero
																				? null
																				: comparisonPaymentsOverrides.includes(index)
																					? 'mdi-lock-open'
																					: 'mdi-lock-outline'
																		"
																		:error-messages="errors"
																		:hide-details="errors.length == 0"
																		solo
																		dense
																		:readonly="comparisonMonthlyPaymentsZero || comparisonPaymentsOverrides.includes(index) ? false : true"
																		autocomplete="off"
																		@blur="tableFieldBlur"
																		@input="tableFieldUpdated(comparisonPayments[index], errors, index, 'comparison')"
																		@click:append="
																			togglePaymentInput(
																				comparisonProductData.monthlyPayments,
																				comparisonPayments,
																				comparisonPaymentsOverrides,
																				index
																			)
																		"
																		v-on="on"
																	/>
																</validation-provider>
															</template>
															<span>Locked</span>
														</v-tooltip>
													</template>
												</td>
												<td v-show="comparisonIsActive" class="compare text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="text-decoration-underline" v-on="on"> £{{ item.comparisonBalanceFinal | numFormat('0,0') }} </span>
														</template>
														<span>Previously £{{ item.comparisonBalanceFirst | numFormat('0,0') }} as of 1st Jan</span>
													</v-tooltip>
												</td>
												<td
													v-show="comparisonIsActive"
													class="compare text-center text--darken-4 font-weight-medium"
													:class="[
														{ 'red--text': comparisonIsActive && item.loanCost < item.comparisonCost },
														{ 'green--text': comparisonIsActive && item.loanCost > item.comparisonCost }
													]"
												>
													<v-chip
														label
														outlined
														small
														class="font-weight-medium"
														:class="[
															{ 'red': comparisonIsActive && item.loanCost < item.comparisonCost },
															{ 'green darken-2': comparisonIsActive && item.loanCost > item.comparisonCost }
														]"
													>
														£{{ item.comparisonCost | conditionalDecimal }}
													</v-chip>
												</td>

												<td v-show="options.propertyActive" class="property text-center">
													<v-tooltip bottom max-width="300">
														<template #activator="{ on }">
															<span class="text-decoration-underline" v-on="on">
																£{{ item.priceFinal | conditionalDecimal }}
															</span>
														</template>
														<span>Previously £{{ item.priceFirst | numFormat('0,0') }} as of 1st Jan. Increase in value of £{{ item.increase | conditionalDecimal }}.</span>
													</v-tooltip>
												</td>
												<td v-show="options.propertyActive" class="equity text-center">
													£{{ item.equity | conditionalDecimal }}
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>

								<p class="caption mt-4 mb-0">
									Please refer to the product criteria to review if Early Repayment Charges may be added.
								</p>
								<p class="caption mt-0 mb-0">
									Please note that the figures above are for illustrative purposes only.
								</p>
							</v-sheet>

							<v-sheet v-else class="text-center">
								<p class="text-left text-body-1 mb-6">
									We encourage you to click the <v-icon color="pink darken-2">
										mdi-frequently-asked-questions
									</v-icon> icon in the toolbar and read our
									frequently asked questions for a full explanation of the many features included within the borrowing forecast. Clicking the
									<v-icon color="pink darken-2">
										mdi-cog
									</v-icon> icon will open up our advanced features, including a blended rate calculator and
									<span class="teal--text text--lighten-1 font-weight-bold">new</span> MER to AER converter.
								</p>
								<v-item-group v-model="options.calculatorType" class="d-flex mt-n3 mb-8">
									<v-row>
										<v-col v-for="(option, index) in calculatorTypeOptions" :key="index" cols="12" md="6">
											<v-item v-slot="{ toggle }">
												<v-card class="text-h6 pa-6 text-center grey lighten-2" style="height: 100%" @click="toggle">
													<v-chip v-if="option.new" dark label small class="teal lighten-1 v-chip-absolute elevation-1">
														NEW
													</v-chip>
													{{ option.title }}
													<v-chip v-if="option.updated" dark label small class="deep-orange accent-3 v-chip-absolute elevation-1">
														UPDATED
													</v-chip>
													<span class="body-1 d-block mt-2" v-html="$sanitize(option.copy)" />
												</v-card>
											</v-item>
										</v-col>
									</v-row>
								</v-item-group>
							</v-sheet>
						</template>
					</common-structure-section>

					<v-card v-else class="flex-grow-1">
						<v-skeleton-loader type="list-item-three-line" />
					</v-card>

					<v-dialog v-model="manualActive" scrollable max-width="1000" :retain-focus="false">
						<v-card tile>
							<v-toolbar class="generic-gradient" dark>
								<v-row no-gutters>
									<v-col cols="12" md="7" class="d-flex pa-0">
										<v-toolbar-title class="align-self-center">
											<span>Frequently Asked Questions</span>
										</v-toolbar-title>
									</v-col>

									<v-col cols="12" md="4" class="d-flex pa-0" style="max-width: 1000px">
										<v-text-field
											v-model="searchTerm"
											placeholder="Search: e.g. How to print?"
											class="search-field align-self-center"
											append-icon="mdi-magnify"
											hide-details
											dense
											solo
											light
											clear-icon="mdi-close-circle"
											clearable
											@click:clear="searchTerm = ''"
										/>
									</v-col>

									<v-spacer />
									<v-col cols="12" md="1" class="d-flex pa-0 justify-end">
										<v-btn id="faq-close" icon text @click="closeManual">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-toolbar>
							<v-card-text class="py-6">
								<v-container class="pa-0" style="max-width: 1000px">
									<v-sheet v-for="(faq, index) in faqResults" :key="faq.question" :class="{ 'mt-0': index === 0 }" class="my-7 mb-0">
										<h3 class="title mb-2">
											{{ faq.question }}
										</h3>
										<p class="body-1 mb-0" v-html="$sanitize(faq.answer)" />
									</v-sheet>
								</v-container>
							</v-card-text>
						</v-card>
					</v-dialog>

					<blended-rate-calculator ref="blendedRateCalculator" :action-text="blendedRateActionText" @action="blendedRateAction" />

					<v-dialog v-model="onboardingActive" scrollable max-width="1000" :retain-focus="false">
						<v-card tile>
							<v-toolbar flat color="generic-gradient" dark class="px-2">
								<v-toolbar-title class="font-weight-medium">
									What the forecast is this?!
								</v-toolbar-title>
								<v-spacer />
								<v-btn icon dark @click="closeOnboarding">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-toolbar>
							<v-card-text class="pa-6">
								<h2 class="title mb-5">
									Welcome to the all-new borrowing forecast!
								</h2>
								<p class="body-1 mb-4">
									Created to help you in your research and recommendations, the Forecast and Rebroke tools allow you to demonstrate the cost of a lifetime
									mortgage over time, both visually and in figures.
								</p>
								<p class="body-1 mb-4">
									Taking account of fees, interest, payments and drawdowns, you can quickly create graphs and detailed cost tables of one product, or two products
									compared to each other, to accurately establish the right solution for your customer.
								</p>
								<p class="body-1 ma-0">
									<strong>We highly recommend that you have a quick read of the user guide.</strong> The guide will cover general usage, as well as highlighting
									some of the more advanced fearures. You can access the guide at any time by clicking the
									<v-icon color="purple">
										mdi-frequently-asked-questions
									</v-icon>
									icon in the top right of the toolbar.
								</p>
								<v-row class="mb-n3 mt-5">
									<v-col cols="12" md="3">
										<v-btn color="primary" block @click="closeOnboardingOpenManual">
											Read the guide
										</v-btn>
									</v-col>
									<v-col cols="12" md="3">
										<v-btn color="secondary" outlined block @click="closeOnboarding">
											Get Started
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-dialog>

					<v-dialog v-model="furtherAdvanceDialogActive" scrollable max-width="1000" :retain-focus="false">
						<v-card tile>
							<v-toolbar flat color="generic-gradient" dark class="px-2">
								<v-toolbar-title class="font-weight-medium">
									A further advance for further advances...
								</v-toolbar-title>
								<v-spacer />
								<v-btn icon dark @click="closeFurtherAdvanceDialog">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-toolbar>
							<v-card-text class="pa-6">
								<h2 class="title mb-5">
									We've added new functionality for further advances!
								</h2>
								<p class="body-1 mb-4">
									The rebroke calculator has new functionality to add a further advance to an existing product. Simply click the defaulted option of
									<span class="green--text text--darken-2 font-weight-bold inline-field">without a further advance</span> and select
									<span class="green--text text--darken-2 font-weight-bold inline-field">with a further advance</span> to reveal additional options for loan
									amount and AER.
								</p>
								<p class="body-1 mb-4">
									In addition, our new blended rate calculator (accessed at any time via the
									<v-icon class="pink--text text--darken-2">
										mdi-cog
									</v-icon>
									icon in the toolbar) can be used to calculate a combined current balance and AER for customers that have existing products with one or more
									drawdowns.
								</p>
								<v-row class="mb-n3 mt-5">
									<v-col cols="12" md="3">
										<v-btn color="secondary" outlined block @click="closeFurtherAdvanceDialog">
											Got it
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-dialog>

					<v-overlay :value="printing">
						<v-card color="white" width="300">
							<v-card-text class="black--text">
								Preparing PDF...
								<v-progress-linear indeterminate color="black" class="mt-2 mb-0" />
							</v-card-text>
						</v-card>
					</v-overlay>

					<v-fade-transition>
						<v-footer v-if="selectedRows.length" fixed tile class="amber accent-2">
							<v-row class="d-flex pa-2" justify="center" no-gutters>
								<div class="d-flex align-center">
									<v-btn v-show="!isolated" class="ml-2" outlined color="red" @click="isolated = true">
										Isolate {{ selectedRows.length }} Rows
									</v-btn>
									<v-btn v-show="isolated" class="ml-2" outlined color="green" @click="clearIsolatedRows()">
										Clear Isolated Rows
									</v-btn>
								</div>
							</v-row>
						</v-footer>
					</v-fade-transition>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	const NAME = 'forecastOnBoarding0150322';
	const FURTHERADVANCES_NAME = 'forecastFurtherAdvance131022';
	const PAYMENTS_PROMPT_STORAGE_NAME = 'forecastPaymentsPromp0150322';

	import { mapGetters, mapState } from 'vuex';
	import { debounce, findIndex } from 'lodash';
	import { ValidationProvider } from 'vee-validate';
	import colors from 'vuetify/lib/util/colors';
	import numeral from 'numeral';
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicNaturalLanguageForm from '@/component/common/base/dynamic-natural-language-form';
	import BlendedRateCalculator from './blended-rate-calculator.vue';
	import LineChart from '@/component/common/chart/line-chart';
	import html2canvas from 'html2canvas';
	import pdfMake from 'pdfmake/build/pdfmake.min';
	import pdfFonts from 'pdfmake/build/vfs_fonts';
	import { $localStorage, $sessionStorage } from '@/utils/storage';
	pdfMake.vfs = pdfFonts.pdfMake.vfs;
	import { EventBus, ElementTools } from '@/utils';

	export default {
		name: 'app-calculator-borrowing-forecast-tool',

		components: {
			CommonStructureSection,
			DynamicNaturalLanguageForm,
			LineChart,
			BlendedRateCalculator,
			ValidationProvider
		},

		filters: {
			conditionalDecimal(value) {
				let format = parseFloat(value) < 1000 ? '0,0.00' : '0,0';
				return numeral(value).format(format);
			}
		},

		props: {
			borrowingForecastActive: { type: Boolean, required: false, default: false }
		},

		data() {
			return {
				source: 'self',
				borrowingForecastActiveModel: null,
				isLineChartMounted: false,
				toggleGraphDataSource: 0,
				manualActive: false,
				onboardingActive: false,
				furtherAdvanceDialogActive: false,
				options: {
					source: null,
					calculatorType: null,
					comparisonActive: false,
					propertyActive: false
				},
				calculatorTypeOptions: [
					{
						title: 'Forecast',
						copy: 'Create a borrowing forecast for one new mortgage product, or two new mortgage products compared, to establish which is least expensive.'
					},
					{
						title: 'Rebroke',
						copy: 'Create a remortgage borrowing forecast allowing for early repayment charges, set up fees and captial raising to establish if a saving will be made over time.'
					}
				],
				selectedOption: 0,
				selectedRows: [],
				isolated: false,
				printing: false,
				dataSet: [],
				drawdownPaymentData: [],
				drawdowns: [],
				payments: [],
				monthlyPayments: [],
				paymentsOverrides: [],
				comparisonPayments: [],
				comparisonMonthlyPayments: [],
				comparisonPaymentsOverrides: [],
				productData: {},
				comparisonProductData: {},
				propertyData: {},

				chartData: {
					datasets: []
				},

				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
						align: 'end',
						labels: {
							usePointStyle: true,
							boxWidth: 6
						}
					},
					tooltips: {
						callbacks: {
							label: (tooltipItems) => `£${numeral(tooltipItems.yLabel).format('0,0')}`,
							title: (tooltipItems) => `Year ${tooltipItems[0].xLabel}`
						}
					},
					scales: {
						xAxes: [
							{
								type: 'linear',
								ticks: { stepSize: 1, fontSize: 14, fontColor: '#000000', fontFamily: 'Roboto', padding: 10 },
								gridLines: { color: '#CCCCCC', zeroLineWidth: 0, tickMarkLength: 0 }
							}
						],
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									callback: (value) => `£${numeral(value).format('0,0')}`,
									fontSize: 14,
									fontColor: '#000000',
									fontFamily: 'Roboto',
									padding: 10
								},
								gridLines: { color: '#CCCCCC', zeroLineWidth: 0, tickMarkLength: 0 }
							}
						]
					}
				},

				faqs: [
					{
						question: 'How do I change the figures in the tool?',
						answer: 'Any of the <span class="primary--text font-weight-bold inline-field">underlined</span> words are editable. You need to click on them and enter the correct values in the box which pops up.'
					},
					{
						question: 'How do I add a second product for comparison?',
						answer: 'Click on the option titled “Product Two” and then click the “activate product comparison” toggle. This will allow you to enter the same details as Product 1'
					},
					{
						question: 'How can I compare the escalating loan amount to the property value?',
						answer: 'Click on the option titled “Property” and then click the “activate property value forecast” toggle. This will allow you to enter the inflation rate and property value.'
					},
					{
						question: 'Can I print this forecast out for my records?',
						answer: 'Yes! In the top right corner of the screen you will see a <i class="v-icon mdi mdi-printer theme--dark pink--text text--darken-2"></i> icon. Clicking this icon will download a PDF from which you can print as normal.'
					},
					{
						question: 'I only want to see the forecast for the next 20 years, how can I do this?',
						answer: 'You can enter a new term in the "term" field above the graph and you can isolate specific rows of the table so you can see key years at a glance. The final column of the table is an “isolate” column. You will need to turn on the toggle button for the relevant years and then click the “isolate rows” button at the bottom of the screen.'
					},
					{
						question: 'How can I see at a glance how much is being saved each year by choosing one product over another?',
						answer: 'In the table below the graph you will see an arrow which will either be <span class="green--text">green</span> or <span class="red--text">red</span>. If you hover over this arrow, it will tell you how much your client will have saved by each year in the table.'
					},
					{
						question: 'My client wants to make ad-hoc payments towards their loan, how can I demonstrate this?',
						answer: 'In the product section, you can change “roll-up interest” to “voluntary payments”, which will then reveal an additional option of adding consistent monthly payments. Alternatively, you can enter payments individually for each year by clicking on the <i class="v-icon mdi mdi-lock-outline theme--dark grey--text text--darken-2"></i> icon to unlock the field and enter a specific amount. <span class="font-weight-bold">You can also combine monthly payments with ad-hoc payments.</span>'
					},
					{
						question: 'What do the green and red colours mean?',
						answer: 'A <span class="green--text">green</span> number in the cost column indicates that your client will make a saving. A <span class="red--text">red</span> number indicates that your client would make a loss. <span class="font-weight-bold">The left hand most product (Product 1 or New Product) is always the subject of the comparison.</span>'
					},
					{
						question: 'My client wants to make withdrawals from a drawdown facility, how can I demonstrate this?',
						answer: 'In the table beneath the graph there is “drawdowns” column. In here you can enter the amount your client wishes to take and the year they wish to take it, and the impact of this will be reflected in both the graph and the figures in the table.'
					},
					{
						question: 'How do I add a Further Advance?',
						answer: 'In the Rebroke Calculator, in the Existing Product section, you can select to add a further advance by clicking on <span class="primary--text font-weight-bold inline-field">without a further advance</span> and selecting <span class="primary--text font-weight-bold inline-field">with a further advance</span>. This will then display an additional balance and interest rate for you to compare against. '
					},
					{
						question: 'How do I use the Blended Rate Calculator?',
						answer: 'You can navigate to the Blended rate calculator via the tab on the Tools landing page or by clicking on the "Advanced Features" <i class="v-icon mdi mdi-cog theme--dark pink--text text--darken-2"></i> icon at the top of the page. Here you can add a balance and interest rate by clicking on “Click to add a new row”. The results are returned in the following format, <span class="font-weight-bold">RESULT: loan amount of £100,000 at a blended rate of 1.23%</span>.'
					}
				],
				searchTerm: '',
				pasteArray: [],
				paymentsPrompt: false,
				forms: {
					borrowingForecastTool: {
						text: 'Create forecast based on initial borrowing of {initialBorrowing} at an{aerText}{merText}of {interestRate}. The loan payment type is {paymentType}{monthlyPaymentsText}{monthlyPayments}{paymentTermText1}{paymentTermType}{paymentTermText2}{paymentTermText3}{paymentTerm}{paymentTermText4}. Assume the following fees to be added to loan, a product fee of {productFee}, an adviser fee of {adviserFee}, solicitors fee of {solicitorsFee} and other fees of {otherFees}. Assume a term of {term} year(s).',
						fields: [
							{
								type: 'number',
								name: 'initialBorrowing',
								display: 'Initial Borrowing',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'plain-text',
								name: 'aerText',
								display: ' AER (annual) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'not_in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'merText',
								display: ' MER (monthly) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'interestRate',
								display: 'Interest Rate Percentage',
								rules: 'required|decimal:2|between:0,100',
								suffix: '%',
								percentage: true
							},
							{
								type: 'select',
								name: 'paymentType',
								display: 'Loan payment type',
								rules: 'required',
								options: [
									{
										value: 4,
										text: 'voluntary payments'
									},
									{
										value: 5,
										text: 'interest serviced'
									},
									{
										value: 8,
										text: 'mandatory payments'
									},
									{
										value: 2,
										text: 'interest only'
									},
									{
										value: 3,
										text: 'capital & interest'
									}
								]
							},
							{
								type: 'plain-text',
								name: 'monthlyPaymentsText',
								display: ' with monthly payments of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'monthlyPayments',
								display: 'Monthly payment',
								prefix: '£',
								rules: 'required|decimal:2',
								currency: true,
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText1',
								display: ' for ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'select',
								name: 'paymentTermType',
								display: 'Payment Term type',
								rules: 'required',
								options: [
									{
										value: 'entire',
										text: 'entire'
									},
									{
										value: 'fixed',
										text: 'fixed'
									}
								],
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText2',
								display: ' term',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText3',
								display: ' of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'text',
								name: 'paymentTerm',
								display: 'Payment Term',
								rules: 'required|numeric|between:1,480',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText4',
								display: ' months(s)',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'number',
								name: 'productFee',
								display: 'Product fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'text',
								name: 'adviserFee',
								display: 'Adviser fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'text',
								name: 'solicitorsFee',
								display: 'Solicitors fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'text',
								name: 'otherFees',
								display: 'Other fees',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'text',
								name: 'term',
								display: 'Term',
								rules: 'required|numeric|between:1,40'
							}
						]
					},
					borrowingForecastToolCompare: {
						text: 'Compare against an initial borrowing of {initialBorrowing} at an {aerText}{merText}of {interestRate}. The loan payment type is {paymentType}{monthlyPaymentsText}{monthlyPayments}{paymentTermText1}{paymentTermType}{paymentTermText2}{paymentTermText3}{paymentTerm}{paymentTermText4}. Assume the following fees to be added to loan, a product fee of {productFee}, an adviser fee of {adviserFee}, solicitors fee of {solicitorsFee} and other fees of {otherFees}. Assume a term of {term} year(s).',
						fields: [
							{
								type: 'number',
								name: 'initialBorrowing',
								display: 'Current Balance',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'plain-text',
								name: 'aerText',
								display: ' AER (annual) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'not_in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'merText',
								display: ' MER (monthly) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'interestRate',
								display: 'Interest Rate Percentage',
								rules: 'required|decimal:2|between:0,100',
								suffix: '%',
								percentage: true
							},
							{
								type: 'select',
								name: 'paymentType',
								display: 'Loan payment type',
								rules: 'required',
								options: [
									{
										value: 4,
										text: 'voluntary payments'
									},
									{
										value: 5,
										text: 'interest serviced'
									},
									{
										value: 8,
										text: 'mandatory payments'
									},
									{
										value: 2,
										text: 'interest only'
									},
									{
										value: 3,
										text: 'capital & interest'
									}
								]
							},
							{
								type: 'plain-text',
								name: 'monthlyPaymentsText',
								display: ' with monthly payments of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'monthlyPayments',
								display: 'Monthly payment',
								prefix: '£',
								rules: 'required|decimal:2',
								currency: true,
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText1',
								display: ' for ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'select',
								name: 'paymentTermType',
								display: 'Payment Term type',
								rules: 'required',
								options: [
									{
										value: 'entire',
										text: 'entire'
									},
									{
										value: 'fixed',
										text: 'fixed'
									}
								],
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText2',
								display: ' term',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText3',
								display: ' of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'text',
								name: 'paymentTerm',
								display: 'Payment Term',
								rules: 'required|numeric|between:1,480',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText4',
								display: ' months(s)',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'number',
								name: 'productFee',
								display: 'Product fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'adviserFee',
								display: 'Adviser fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'solicitorsFee',
								display: 'Solicitors fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'otherFees',
								display: 'Other fees',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'term',
								display: 'Term',
								rules: 'required|numeric|between:1,40'
							}
						]
					},
					borrowingForecastToolExistingProduct: {
						text: 'Current balance of {initialBorrowing} at an {aerText}{merText}of {interestRate}, {furtherAdvanceOption}{furtherAdvanceText1}{furtherAdvanceAmount}{furtherAdvanceText2}{furtherAdvanceRate}. The loan payment type is {paymentType}{monthlyPaymentsText}{monthlyPayments}{paymentTermText1}{paymentTermType}{paymentTermText2}{paymentTermText3}{paymentTerm}{paymentTermText4}. The remaining product term is {term} year(s).',
						fields: [
							{
								type: 'number',
								name: 'initialBorrowing',
								display: 'Current balance',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'plain-text',
								name: 'aerText',
								display: ' AER (annual) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'not_in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'merText',
								display: ' MER (monthly) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'interestRate',
								display: 'Interest rate percentage',
								rules: 'required|decimal:2|between:0,100',
								suffix: '%',
								percentage: true
							},
							{
								type: 'select',
								name: 'furtherAdvanceOption',
								display: 'Further advance?',
								rules: 'required',
								options: [
									{
										value: 1,
										text: 'without a further advance'
									},
									{
										value: 2,
										text: 'with a further advance'
									}
								]
							},
							{
								type: 'plain-text',
								name: 'furtherAdvanceText1',
								display: ' of ',
								conditions: {
									show: [
										{
											name: 'furtherAdvanceOption',
											type: 'equals',
											value: 2
										}
									]
								}
							},
							{
								type: 'number',
								name: 'furtherAdvanceAmount',
								display: 'Further advance amount',
								prefix: '£',
								rules: 'required|numeric',
								currency: true,
								conditions: {
									show: [
										{
											name: 'furtherAdvanceOption',
											type: 'equals',
											value: 2
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'furtherAdvanceText2',
								display: ' at an AER interest rate of ',
								conditions: {
									show: [
										{
											name: 'furtherAdvanceOption',
											type: 'equals',
											value: 2
										}
									]
								}
							},
							{
								type: 'number',
								name: 'furtherAdvanceRate',
								display: 'Further advance interest rate',
								rules: 'required|decimal:2|between:0,100',
								suffix: '%',
								percentage: true,
								conditions: {
									show: [
										{
											name: 'furtherAdvanceOption',
											type: 'equals',
											value: 2
										}
									]
								}
							},
							{
								type: 'select',
								name: 'paymentType',
								display: 'Loan payment type',
								rules: 'required',
								options: [
									{
										value: 4,
										text: 'voluntary payments'
									},
									{
										value: 5,
										text: 'interest serviced'
									},
									{
										value: 8,
										text: 'mandatory payments'
									},
									{
										value: 2,
										text: 'interest only'
									},
									{
										value: 3,
										text: 'capital & interest'
									}
								]
							},
							{
								type: 'plain-text',
								name: 'monthlyPaymentsText',
								display: ' with monthly payments of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'monthlyPayments',
								display: 'Existing monthly payment',
								prefix: '£',
								rules: 'required|numeric',
								currency: true,
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText1',
								display: ' for ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'select',
								name: 'paymentTermType',
								display: 'Payment Term type',
								rules: 'required',
								options: [
									{
										value: 'entire',
										text: 'entire'
									},
									{
										value: 'fixed',
										text: 'fixed'
									}
								],
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText2',
								display: ' term',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText3',
								display: ' of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'text',
								name: 'paymentTerm',
								display: 'Payment Term',
								rules: 'required|numeric|between:1,480',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText4',
								display: ' months(s)',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'text',
								name: 'term',
								display: 'Remaining product term',
								rules: 'required|numeric|between:1,40'
							}
						]
					},
					borrowingForecastToolNewProduct: {
						text: 'Initial borrowing must clear current balance of {initialBorrowing}, plus capital raising of {capitalRaising}. Assume the following fees to be added to loan, a product fee of {productFee}, an early repayment charge of {erc}, an adviser fee of {adviserFee}, solicitors fee of {solicitorsFee} and other fees of {otherFees}. The loan payment type is {paymentType}{monthlyPaymentsText}{monthlyPayments}{paymentTermText1}{paymentTermType}{paymentTermText2}{paymentTermText3}{paymentTerm}{paymentTermText4} and {aerText}{merText}of {interestRate}. Assume a term of {term} year(s).',
						fields: [
							{
								type: 'number',
								name: 'initialBorrowing',
								display: 'Current Balance',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'capitalRaising',
								display: 'Capital Raising',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'productFee',
								display: 'Product fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'erc',
								display: 'Early repayment charge',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'adviserFee',
								display: 'Adviser fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'solicitorsFee',
								display: 'Solicitors fee',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'number',
								name: 'otherFees',
								display: 'Other fees',
								prefix: '£',
								rules: 'required|numeric',
								currency: true
							},
							{
								type: 'select',
								name: 'paymentType',
								display: 'Loan payment type',
								rules: 'required',
								options: [
									{
										value: 4,
										text: 'voluntary payments'
									},
									{
										value: 5,
										text: 'interest serviced'
									},
									{
										value: 8,
										text: 'mandatory payments'
									},
									{
										value: 2,
										text: 'interest only'
									},
									{
										value: 3,
										text: 'capital & interest'
									}
								]
							},
							{
								type: 'plain-text',
								name: 'monthlyPaymentsText',
								display: ' with monthly payments of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'monthlyPayments',
								display: 'Existing monthly payment',
								prefix: '£',
								rules: 'required|decimal:2',
								currency: true,
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText1',
								display: ' for ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'select',
								name: 'paymentTermType',
								display: 'Payment Term type',
								rules: 'required',
								options: [
									{
										value: 'entire',
										text: 'entire'
									},
									{
										value: 'fixed',
										text: 'fixed'
									}
								],
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText2',
								display: ' term',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText3',
								display: ' of ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'text',
								name: 'paymentTerm',
								display: 'Payment Term',
								rules: 'required|numeric|between:1,480',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'paymentTermText4',
								display: ' months(s)',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										},
										{
											name: 'paymentTermType',
											type: 'equals',
											value: 'fixed'
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'aerText',
								display: ' AER (annual) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'not_in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'plain-text',
								name: 'merText',
								display: ' MER (monthly) interest rate ',
								conditions: {
									show: [
										{
											name: 'paymentType',
											type: 'in_array',
											value: [4, 5, 8]
										}
									]
								}
							},
							{
								type: 'number',
								name: 'interestRate',
								display: 'Interest Rate Percentage',
								rules: 'required|decimal:2|between:0,100',
								suffix: '%',
								percentage: true
							},
							{
								type: 'number',
								name: 'term',
								display: 'Term',
								rules: 'required|numeric|between:1,40'
							}
						]
					},
					borrowingForecastToolProperty: {
						text: 'Assume house price inflation to be {inflation} percent on a property currently valued at {propertyValue}.',
						fields: [
							{ type: 'number', name: 'inflation', display: 'Inflation Percentage', rules: 'required|decimal:2|between:0,100' },
							{ type: 'number', name: 'propertyValue', display: 'Property Value', prefix: '\u00a3', rules: 'required|numeric', currency: true }
						]
					}
				},

				baseProductData: {
					name: 'Product One',
					initialBorrowing: 50000,
					capitalRaising: 20000,
					interestRate: 6.69,
					rateChanges: [],
					paymentType: 4,
					monthlyPayments: 0,
					paymentTermType: 'entire',
					paymentTerm: 120,
					productFee: null,
					adviserFee: null,
					solicitorsFee: null,
					otherFees: null,
					erc: 0,
					term: 40
				},
				defaultProductData: {},

				baseComparisonProductData: {
					name: 'Product Two',
					initialBorrowing: 50000,
					interestRate: 6.75,
					rateChanges: [],
					furtherAdvanceOption: 1,
					furtherAdvanceAmount: 0,
					furtherAdvanceRate: 0,
					paymentType: 4,
					monthlyPayments: 0,
					paymentTermType: 'entire',
					paymentTerm: 120,
					productFee: null,
					adviserFee: null,
					solicitorsFee: null,
					otherFees: null,
					term: 40
				},
				defaultComparisonProductData: {},

				defaultPropertyData: {
					name: 'Property',
					inflation: 3,
					propertyValue: 350000
				}
			};
		},

		computed: {
			...mapGetters('Account', ['membership']),
			...mapState('Account', ['completion', 'user']),
			...mapState('CmsSite', ['site']),

			compareOptions() {
				return [
					{ title: this.productData.name, subtitle: 'Product One' },
					{ title: this.comparisonProductData.name, subtitle: 'Product Two (optional)' },
					{ title: this.propertyData.name, subtitle: 'Property (optional)' }
				];
			},

			rebrokeOptions() {
				return [
					{ title: 'Existing Product', subtitle: 'Existing Product' },
					{ title: this.productData.name === 'Product One' ? 'New Product' : this.productData.name, subtitle: 'New Product' },
					{ title: this.propertyData.name, subtitle: 'Property (optional)' }
				];
			},

			blendedRateActionText() {
				// If calculator type is not rebroke, walk away...
				if (this.options.calculatorType !== 1) return null;
				return 'Click here to use these values as the existing product current balance and AER';
			},

			displayTermWarning() {
				// Ignore capital & interest (this.productData.paymentType 3)
				// Ignore existing product term
				// Only assess product 2 in forecast if comparison is active
				if (this.options.calculatorType === 0 && this.comparisonIsActive && this.comparisonProductData.paymentType !== 3 && this.productTwoTerm < 15) return true;
				if (this.productData.paymentType !== 3 && this.productOneTerm < 15) return true;
				return false;
			},

			comparisonIsActive() {
				// If calculator type is rebroke, comparison is active by default.
				if (this.options.calculatorType === 1) return 1;
				return this.options.comparisonActive;
			},

			drawdownsActive() {
				let capitalAndInterestActive = this.productData.paymentType === 3 || this.comparisonProductData.paymentType === 3;
				return !capitalAndInterestActive;
			},

			displayProduct1Payments() {
				return this.productData.paymentType !== 1;
			},

			product1PaymentsEditable() {
				return [4, 5, 8].includes(this.productData.paymentType);
			},

			monthlyPaymentsZero() {
				return this.productData.monthlyPayments == 0;
			},

			displayProduct2Payments() {
				return this.comparisonProductData.paymentType !== 1;
			},

			product2PaymentsEditable() {
				return [4, 5, 8].includes(this.comparisonProductData.paymentType);
			},

			comparisonMonthlyPaymentsZero() {
				return this.comparisonProductData.monthlyPayments == 0;
			},

			advancedFeaturesActive() {
				if (this.product1PaymentsEditable && !this.monthlyPaymentsZero) return true;
				if (this.comparisonIsActive && this.product2PaymentsEditable && !this.comparisonMonthlyPaymentsZero) return true;
				return false;
			},

			productOneTerm() {
				return this.formatNumber(this.productData.term);
			},

			productTwoTerm() {
				return this.formatNumber(this.comparisonProductData.term);
			},

			termEnd() {
				return this.productOneTerm > this.productTwoTerm ? this.productOneTerm : this.productTwoTerm;
			},

			faqResults() {
				if (!this.searchTerm) return this.faqs;
				let filtered = this.faqs.filter(
					(faq) => faq.question.toLowerCase().includes(this.searchTerm.toLowerCase()) || faq.answer.toLowerCase().includes(this.searchTerm.toLowerCase())
				);
				return filtered;
			}
		},

		watch: {
			/**
			 * @name toggleGraphDataSource
			 * @description re-calculate & draw table on source data change
			 */
			toggleGraphDataSource: {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name productData.monthlyPayments
			 * @description reset payment overrides if monthly payments is updated to or from 0
			 */
			'productData.monthlyPayments': {
				handler(val, oldVal) {
					if (val === 0 || oldVal === 0) this.paymentsOverrides = [];
				},
				deep: true
			},

			/**
			 * @name comparisonProductData.monthlyPayments
			 * @description reset comparison payment overrides if comparison monthly payments is updated to or from 0
			 */
			'comparisonProductData.monthlyPayments': {
				handler(val, oldVal) {
					if (val === 0 || oldVal === 0) this.comparisonPaymentsOverrides = [];
				},
				deep: true
			},

			/**
			 * @name productData
			 * @description re-calculate & draw table on product data update
			 */
			productData: {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name comparisonProductData
			 * @description re-calculate & draw table on comparison product data update
			 */
			comparisonProductData: {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name propertyData
			 * @description re-calculate & draw table on property data update
			 */
			propertyData: {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name calculatorType
			 * @description re-calculate & draw table
			 */
			 'options.calculatorType': {
				handler() {
					if (this.options.calculatorType == null) return;
					else if (this.options.calculatorType == 1) this.openFurtherAdvancesDialog();

					// Google Analytics event
					window.dataLayer.push({
						event: 'borrowingForecastOpened',
						'gtm.element.dataset.gaId': this.source,
						'gtm.element.dataset.gaValue': this.calculatorTypeOptions[this.options.calculatorType].title
					});

					this.createData();
				},
				deep: true
			},

			/**
			 * @name 'options.comparisonActive'
			 * @description re-calculate & draw table on product comparision update
			 */
			'options.comparisonActive': {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name options.propertyActive
			 * @description re-calculate & draw table on property activation
			 */
			'options.propertyActive': {
				handler() {
					this.createData();
				},
				deep: true
			},

			/**
			 * @name comparisonProductData.initialBorrowing
			 * @description keeps initial borrowing synced for compared products on rebroke mode
			 */
			'comparisonProductData.initialBorrowing': {
				handler() {
					if (this.options.calculatorType === 1) this.productData.initialBorrowing = this.comparisonProductData.initialBorrowing;
				},
				deep: true
			},

			/**
			 * @name productData.initialBorrowing
			 * @description keeps initial borrowing synced for compared products on rebroke mode
			 */
			'productData.initialBorrowing': {
				handler() {
					if (this.options.calculatorType === 1) this.comparisonProductData.initialBorrowing = this.productData.initialBorrowing;
				},
				deep: true
			},

			/**
			 * @name comparisonProductData.furtherAdvanceAmount
			 * @description furtherAdvanceAmount synced (one-way) for capitalRaising
			 */
			'comparisonProductData.furtherAdvanceAmount': {
				handler(newVal, oldVal) {
					if (this.options.calculatorType === 1 && (oldVal == 0 || this.productData.capitalRaising === oldVal))
						this.productData.capitalRaising = this.comparisonProductData.furtherAdvanceAmount;
				},
				deep: true
			},

			/**
			 * @name comparisonProductData.initialBorrowing
			 * @description term synced (one-way) for compared products
			 */
			'comparisonProductData.term': {
				handler(newVal, oldVal) {
					let newTerm = this.comparisonProductData.term < 15 ? 15 : this.comparisonProductData.term;
					if (this.options.calculatorType === 1 && this.productData.term == oldVal) this.productData.term = newTerm;
					this.termChanged();
				},
				deep: true
			},

			/**
			 * @name productData.initialBorrowing
			 * @description term synced (one-way) for compared products
			 */
			'productData.term': {
				handler(newVal, oldVal) {
					if (this.options.calculatorType === 0 && this.comparisonProductData.term === oldVal) this.comparisonProductData.term = this.productData.term;
					this.termChanged();
				},
				deep: true
			},

			/**
			 * @name productData.paymentType
			 * @description reset payments on payment type change
			 */
			'productData.paymentType': {
				handler() {
					if ([4, 5, 8].includes(this.productData.paymentType)) {
						if (this.productData.monthlyEquivalentRate) this.productData.interestRate = this.productData.monthlyEquivalentRate;
						this.payments = [];
						this.createData();
					} else if (this.productData.annualEquivalentRate) this.productData.interestRate = this.productData.annualEquivalentRate;
				},
				deep: true
			},

			/**
			 * @name comparisonProductData.paymentType
			 * @description reset payments on payment type change
			 */
			'comparisonProductData.paymentType': {
				handler() {
					if ([4, 5, 8].includes(this.comparisonProductData.paymentType)) {
						if (this.comparisonProductData.monthlyEquivalentRate) this.comparisonProductData.interestRate = this.comparisonProductData.monthlyEquivalentRate;
						this.payments = [];
						this.createData();
					} else if (this.comparisonProductData.annualEquivalentRate) this.comparisonProductData.interestRate = this.comparisonProductData.annualEquivalentRate;
				},
				deep: true
			}
		},

		created() {
			this.borrowingForecastActiveModel = this.borrowingForecastActive;
		},

		mounted() {
			if (!$localStorage.isDialogHidden(NAME)) {
				this.onboardingActive = true;
				$localStorage.hideDialog(NAME);
			}
			this.setFormData();
		},

		methods: {
			open(options, productData = {}, comparisonProductData = {}, propertyData = false, source = null) {
				// Used for Google Analytics event
				if (source) this.source = source;
				document.documentElement.classList.add('overflow-y-hidden');
				this.borrowingForecastActiveModel = true;
				//reset source here to ensure it doesn't carry over
				this.options.source = null;
				if (options) this.options = Object.assign({}, this.options, options);

				//deal with paymentsOverrides
				let paymentsOverrides = false;
				if (Object.prototype.hasOwnProperty.call(productData, 'paymentsOverrides')) {
					paymentsOverrides = productData.paymentsOverrides;
					delete productData.paymentsOverrides;
				}
				let comparisonPaymentsOverrides = false;
				if (Object.prototype.hasOwnProperty.call(comparisonProductData, 'paymentsOverrides')) {
					comparisonPaymentsOverrides = comparisonProductData.paymentsOverrides;
					delete comparisonProductData.paymentsOverrides;
				}

				this.defaultProductData = productData;
				this.defaultComparisonProductData = comparisonProductData;
				if (propertyData) this.defaultPropertyData = Object.assign(this.defaultPropertyData, propertyData);

				this.setFormData();

				//must deal with overrides here to avoid reactive watcher resetting them to 0
				if (paymentsOverrides || comparisonPaymentsOverrides) {
					this.$nextTick().then(() => {
						//must reset arrays here, otherwise odd decimal behaviour creeps in
						this.payments = [];
						this.paymentsOverrides = [];
						this.comparisonPayments = [];
						this.comparisonPaymentsOverrides = [];

						if(paymentsOverrides) paymentsOverrides.forEach((t) => {
							//for each override term calculate the annual payment
							for(var i=t.start; i<=t.end; i++){
								let yr = Number(Math.floor((i-1)/12));
								!this.payments[yr] ? this.payments[yr] = Number(t.amount.toFixed(2)) : this.payments[yr] += Number(t.amount.toFixed(2));
								this.monthlyPayments[i] = Number(t.amount);
							}
						});
						if(comparisonPaymentsOverrides) comparisonPaymentsOverrides.forEach((t) => {
							//for each override term calculate the annual payment
							for(var i=t.start; i<=t.end; i++){
								let yr = Number(Math.floor((i-1)/12));
								!this.comparisonPayments[yr] ? this.comparisonPayments[yr] = Number(t.amount.toFixed(2)) : this.comparisonPayments[yr] += Number(t.amount.toFixed(2));
								this.comparisonMonthlyPayments[i] = Number(t.amount);
							}
						});

						//ensure this.paymentsOverrides is turned on if the annual amount does not match monthlyPayments
						this.payments.forEach((yr, index) => {
							if(this.defaultProductData?.monthlyPayments && Number(yr).toFixed(2) !== Number(this.defaultProductData.monthlyPayments * 12).toFixed(2)) {
								this.paymentsOverrides.push(index);
							}
						});
						this.comparisonPayments.forEach((yr, index) => {
							if(this.defaultComparisonProductData?.monthlyPayments && Number(yr).toFixed(2) !== Number(this.defaultComparisonProductData.monthlyPayments * 12).toFixed(2)) {
								this.comparisonPaymentsOverrides.push(index);
							}
						});
					});
				}
			},

			lineChartMounted() {
				this.isLineChartMounted = true;
				this.$nextTick().then(() => this.createData());
			},

			close() {
				this.resetCalculatorType();
				this.borrowingForecastActiveModel = false;
				this.$emit('closed');
			},

			blendedRateAction(blendedLoanAmount, blendedLoanRate) {
				this.comparisonProductData.initialBorrowing = this.formatNumber(blendedLoanAmount);
				this.comparisonProductData.interestRate = this.formatNumber(blendedLoanRate).toFixed(2);
				ElementTools.fireNotification(this.$el, 'success', 'Existing product current balance and AER updated');
			},

			/**
			 * @name closeOnboardingOpenManual
			 * @description Closes the onboarding popup and opens the manual
			 */
			closeOnboardingOpenManual() {
				this.onboardingActive = false;
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
				this.manualActive = true;
			},

			/**
			 * @name closeOnboarding
			 * @description Closes the onboarding dialog
			 */
			closeOnboarding() {
				this.onboardingActive = false;
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			},

			/**
			 * @name openFurtherAdvancesDialog
			 * @description Opens the further advances dialog
			 */
			openFurtherAdvancesDialog() {
				if (!$localStorage.isDialogHidden(FURTHERADVANCES_NAME)) {
					this.furtherAdvanceDialogActive = true;
					$localStorage.hideDialog(FURTHERADVANCES_NAME);
				}
			},

			/**
			 * @name closeFurtherAdvanceDialog
			 * @description Closes the further advances dialog
			 */
			closeFurtherAdvanceDialog() {
				this.furtherAdvanceDialogActive = false;
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			},

			/**
			 * @name openAdvancedSettings
			 * @description Opens the advanced settings dialog
			 */
			openAdvancedSettings() {
				this.$refs.blendedRateCalculator.open();
			},

			/**
			 * @name closeAdvancedSettings
			 * @description Closes the advanced settings dialog
			 */
			closeAdvancedSettings() {
				this.$refs.blendedRateCalculator.close();
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			},

			/**
			 * @name openManual
			 * @description Opens the manual dialog
			 */
			openManual() {
				this.manualActive = true;
			},

			/**
			 * @name closeManual
			 * @description Closes the manual dialog
			 */
			closeManual() {
				this.manualActive = false;
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			},

			/**
			 * @name termChanged
			 * @description Event handler for term value change
			 */
			termChanged() {
				this.createData();
			},

			/**
			 * @name setFormData
			 * @description sets the form data with default values
			 */
			setFormData() {
				this.productData = Object.assign({}, this.productData, this.baseProductData, this.defaultProductData);
				this.comparisonProductData = Object.assign({}, this.comparisonProductData, this.baseComparisonProductData, this.defaultComparisonProductData);
				this.propertyData = this.defaultPropertyData;
			},

			/**
			 * @name resetCalculatorType
			 * @description resets local data with default values
			 */
			resetCalculatorType() {
				this.selectedOption = 0;
				this.options = {
					calculatorType: null,
					comparisonActive: false,
					propertyActive: false
				};
				this.drawdowns = [];
				this.payments = [];
				this.monthlyPayments = [];
				this.paymentsOverrides = [];
				this.comparisonPayments = [];
				this.comparisonMonthlyPayments = [];
				this.comparisonPaymentsOverrides = [];
				this.isolated = false;
				this.selectedRows = [];
				this.setFormData();
			},

			/**
			 * @name formatNumber
			 * @description convert variable to an integer
			 */
			formatNumber(val) {
				if (val == null) return 0;
				return Number(val);
			},

			/**
			 * @name calcCapitalAndInterestTotalToPay
			 * @description interest calculation
			 */
			calcCapitalAndInterestTotalToPay(loanAmount, term, rate, FV = 0.0, Type = 0) {
				if (term === 0 || rate === 0) return 0.0;

				let frequency_of_payments = 12;

				rate = rate / 100 / frequency_of_payments;

				let num_of_payments = term * frequency_of_payments;
				let xp = Math.pow(1 + rate, num_of_payments);

				let calc1 = (loanAmount * rate * xp) / (xp - 1) + (rate / (xp - 1)) * FV;
				let calc2 = Type === 0 ? 1 : 1 / (rate + 1);
				let cap_and_interest = calc1 * calc2;

				return cap_and_interest * num_of_payments;
			},

			/**
			 * @name sum
			 * @description calculates sum of a collection of numbers
			 */
			sum(array) {
				return array.reduce((acc, value) => acc + this.formatNumber(value), 0);
			},

			/**
			 * @name createData
			 * @description Creates the table data from the inputs of form
			 */
			createData() {
				//Avoid wasted effort
				if (!this.borrowingForecastActiveModel) return;
				if (!this.isLineChartMounted) return;

				this.dataSet = [];
				let productDataset = [];
				let comparisonDataset = [];
				let propertyValueDataset = [];
				let initialBorrowing = 0;
				let comparisonBorrowing = 0;
				let comparisonRate = this.formatNumber(this.comparisonProductData.interestRate);

				if (this.options.calculatorType === 1) {
					initialBorrowing = this.sum([
						this.comparisonProductData.initialBorrowing,
						this.productData.capitalRaising,
						this.productData.productFee,
						this.productData.erc,
						this.productData.adviserFee,
						this.productData.solicitorsFee,
						this.productData.otherFees
					]);
					comparisonBorrowing = this.formatNumber(this.comparisonProductData.initialBorrowing);
					if (this.comparisonProductData.furtherAdvanceOption === 2) {
						comparisonRate = this.calculateBlendedRate(
							comparisonBorrowing,
							this.formatNumber(this.comparisonProductData.interestRate),
							this.formatNumber(this.comparisonProductData.furtherAdvanceAmount),
							this.formatNumber(this.comparisonProductData.furtherAdvanceRate)
						);
						comparisonBorrowing += this.formatNumber(this.comparisonProductData.furtherAdvanceAmount);
					}
				} else {
					initialBorrowing = this.sum([
						this.productData.initialBorrowing,
						this.productData.productFee,
						this.productData.adviserFee,
						this.productData.solicitorsFee,
						this.productData.otherFees
					]);
					comparisonBorrowing = this.sum([
						this.comparisonProductData.initialBorrowing,
						this.comparisonProductData.productFee,
						this.comparisonProductData.adviserFee,
						this.comparisonProductData.solicitorsFee,
						this.comparisonProductData.otherFees
					]);
				}

				// TODO: Ben to comment this line
				let initialCapitalAndInterestCost = this.calcCapitalAndInterestTotalToPay(
					initialBorrowing,
					this.productOneTerm,
					parseFloat(this.productData.interestRate)
				);
				let comparisonCapitalAndInterestCost = this.calcCapitalAndInterestTotalToPay(
					comparisonBorrowing,
					this.productTwoTerm,
					parseFloat(comparisonRate)
				);

				for (let index = 0; index < this.formatNumber(this.termEnd); index++) {
					// Does the product have a shorter temr than termEnd?
					let productOneWithinTerm = false;
					let productTwoWithinTerm = false;

					// Inputs
					if (this.drawdowns[index] == null) this.drawdowns[index] = 0;
					let drawdown = parseFloat(this.drawdowns[index]) || 0;
					if (this.productData.paymentType === 3 || this.comparisonProductData.paymentType === 3) drawdown = 0;

					// Product 1 loan amount
					let loanBalanceFirst = index === 0 ? parseFloat(initialBorrowing) : this.dataSet[index - 1].loanBalanceFinal;
					let interest = 0;
					let payment = 0;
					let _drawdown = drawdown;

					if (index + 1 <= this.productOneTerm) {
						productOneWithinTerm = true;
						loanBalanceFirst += _drawdown;

						// Determine whether to use original rate or changed rate
						let rate = this.productData.interestRate;
						rate = this.productData.rateChanges?.length && this.productData.rateChanges.find((r) => Math.ceil(r.start / 12) <= index+1)?.rate || rate;

						let obj = this.createInterestPaymentsObject(
							loanBalanceFirst,
							rate,
							this.productData.paymentType,
							this.productOneTerm,
							this.productData.monthlyPayments,
							this.monthlyPayments,
							this.paymentsOverrides.includes(index) ? this.payments[index] : false,
							initialCapitalAndInterestCost,
							index + 1, // Year
							this.productData.paymentTermType,
							this.productData.paymentTerm

						);
						interest = obj.interest;
						payment = this.payments[index] = obj.payment;
					} else _drawdown = 0;

					let loanBalanceFinal = Number((loanBalanceFirst + interest - payment).toFixed(5));
					if (loanBalanceFinal < 0) loanBalanceFinal = 0;
					let loanCost = (index === 0 ? parseFloat(loanBalanceFirst) : this.dataSet[index - 1].loanCost) + interest + _drawdown;

					// Product 2 loan amount
					let comparisonBalanceFirst = index === 0 ? parseFloat(comparisonBorrowing) : this.dataSet[index - 1].comparisonBalanceFinal;
					let comparisonInterest = 0;
					let comparisonPayment = 0;
					let comparisonDrawdown = drawdown;

					if (index + 1 <= this.productTwoTerm) {
						productTwoWithinTerm = true;
						comparisonBalanceFirst += comparisonDrawdown;

						// Determine whether to use original rate or changed rate
						comparisonRate = this.comparisonProductData.rateChanges?.length && this.comparisonProductData.rateChanges.find((r) => r.start <= index+1)?.rate || comparisonRate;

						let obj = this.createInterestPaymentsObject(
							comparisonBalanceFirst,
							comparisonRate,
							this.comparisonProductData.paymentType,
							this.productTwoTerm,
							this.comparisonProductData.monthlyPayments,
							this.comparisonMonthlyPayments,
							this.comparisonPaymentsOverrides.includes(index) ? this.comparisonPayments[index] : false,
							comparisonCapitalAndInterestCost,
							index + 1, // Year
							this.comparisonProductData.paymentTermType,
							this.comparisonProductData.paymentTerm
						);
						comparisonInterest = obj.interest;
						comparisonPayment = this.comparisonPayments[index] = obj.payment;
					} else comparisonDrawdown = 0;

					let comparisonBalanceFinal = Number((comparisonBalanceFirst + comparisonInterest - comparisonPayment).toFixed(5));
					if (comparisonBalanceFinal < 0) comparisonBalanceFinal = 0;
					let comparisonCost = (index === 0 ? parseFloat(comparisonBalanceFirst) : this.dataSet[index - 1].comparisonCost) + comparisonInterest + comparisonDrawdown;

					// Property value
					let priceFirst = index === 0 ? parseFloat(this.propertyData.propertyValue) : this.dataSet[index - 1].priceFinal;
					let increase = (priceFirst * parseFloat(this.propertyData.inflation)) / 100;
					let priceFinal = priceFirst + increase;

					// Equity
					let equity = priceFinal - loanBalanceFinal;

					this.dataSet.push({
						drawdown,
						payment,
						loanBalanceFirst,
						interest,
						loanBalanceFinal,
						loanCost,
						comparisonPayment,
						comparisonBalanceFirst,
						comparisonInterest,
						comparisonBalanceFinal,
						comparisonCost,
						priceFirst,
						increase,
						priceFinal,
						equity,
						productOneWithinTerm,
						productTwoWithinTerm
					});

					productDataset.push({
						y: this.toggleGraphDataSource === 1 ? loanBalanceFinal : loanCost,
						x: index + 1
					});

					comparisonDataset.push({
						y: this.toggleGraphDataSource === 1 ? comparisonBalanceFinal : comparisonCost,
						x: index + 1
					});

					propertyValueDataset.push({
						y: priceFinal,
						x: index + 1
					});
				}

				this.chartData.datasets = [];
				this.chartData.datasets.push(this.createDataset('Product', colors.blue.darken4, productDataset));
				if (this.comparisonIsActive) this.chartData.datasets.push(this.createDataset('Comparison', 'rgb(23, 184, 144)', comparisonDataset));
				if (this.options.propertyActive) this.chartData.datasets.push(this.createDataset('Property Value', colors.purple.darken2, propertyValueDataset));
			},

			/**
			 * @name calculateBlendedRate
			 * @description calculate interest and payments
			 * @param {Number} loan1
			 * @param {Number} rate1
			 * @param {Number} loan2
			 * @param {Number} rate2
			 * @return {Number}
			 */
			calculateBlendedRate(loan1, rate1, loan2, rate2) {
				return 100 * (((loan1 * rate1) / 100 + (loan2 * rate2) / 100) / (loan1 + loan2));
			},

			/**
			 * @name createInterestPaymentsObject
			 * @description calculate interest and payments
			 * @param {Number} balance
			 * @param {Number} rate
			 * @param {Number} paymentType
			 * @param {Number} term
			 * @param {Number|Null} monthlyPayments
			 * @param {Number|Boolean} payment
			 * @param {Number|Null} capitalAndInterestCost
			 * @return {Object}
			 */
			createInterestPaymentsObject(balance, rate, paymentType, term, monthlyPayment = null, monthlyPayments = [], payment = false, capitalAndInterestCost = null, year, paymentTermType, paymentTerm) {
				let i = 0;
				let interest = 0;
				if ([4, 5, 8].includes(paymentType)) {
					for (i = 0; i < 12; i++) {
						if(balance > 0) {
							let _interest = (balance * parseFloat(rate)) / 12 / 100;
							interest += _interest;
							balance = balance + _interest;

							if(monthlyPayment != 0 && payment === false ) { // monthly payments are set and payment input is unset
								if (paymentTermType === 'entire' || (paymentTermType === 'fixed' && Number(((year-1)*12)+i) < paymentTerm)) { // Payment Term
									balance = balance - monthlyPayment;
								}
							} else {
								// Use payment input by default
								let month = ((year-1)*12)+i+1;
								monthlyPayment = monthlyPayments[month] || parseFloat(payment / 12) || 0;
								balance = balance - monthlyPayment;
							}
						}
					}
				} else if ([3].includes(paymentType)) {
					for (i = 0; i < 12; i++) {
						let _interest = (balance * parseFloat(rate)) / 12 / 100;
						interest += _interest;
						balance = balance + _interest - parseFloat(capitalAndInterestCost / term / 12);
					}
				} else interest = (balance * parseFloat(rate)) / 100;

				// Payments
				if (paymentType === 1) payment = 0;
				if (paymentType === 2) payment = interest; // Interest only
				if (paymentType === 3) payment = parseFloat(capitalAndInterestCost / term); // Capital & interest
				if ([4, 5, 8].includes(paymentType)) {
					if(monthlyPayment != 0 && payment === false ) { // monthly payments are set and payment input is unset
						payment = 0;
						for (var j = 0; j < 12; j++) {
							if (paymentTermType === 'entire' || (paymentTermType === 'fixed' && Number(((year-1)*12)+j) < paymentTerm)) { // Payment Term
								payment += parseFloat(monthlyPayment);
							}
						}
					} else payment = parseFloat(payment) || 0; // Use payment input by default
				}

				return { interest: interest, payment: payment.toFixed(2) };
			},

			/**
			 * @name createDataset
			 * @description Creates a chart dataset
			 * @param {String} label
			 * @param {String} color
			 * @param {Object} data
			 * @return {Object} Chart dataset
			 */
			createDataset(label, color, data) {
				return {
					label,
					data,
					borderColor: color,
					pointBackgroundColor: color,
					backgroundColor: 'rgba(0,0,0,0)',
					pointRadius: [3],
					radius: [3],
					borderWidth: 2
				};
			},

			/**
			 * @name togglePaymentInput
			 * @description locks / unlocks payment textbox and collect payment overrides
			 */
			togglePaymentInput(payment, payments, overrides, index) {
				if (!payment) return null;
				if (overrides.includes(index)) {
					overrides.splice(overrides.indexOf(index), 1);
					payments[index] = payment;
					this.createData();
				} else overrides.push(index);
			},

			/**
			 * @name tableFieldUpdated
			 * @description validate form and draw chart afterwards
			 * @param {Array} errors Validation errors
			 */
			tableFieldUpdated(val, errors, index, type) {
				if (!val.trim().length) return;
				if (errors && errors.length) return;
				if (typeof index !== 'undefined' && index !== null && type) {
					if (type === 'product' && !this.paymentsOverrides.includes(index)) this.paymentsOverrides.push(index);
					if (type === 'comparison' && !this.comparisonPaymentsOverrides.includes(index)) this.comparisonPaymentsOverrides.push(index);
				}
				this.createDataDebounced();
			},

			/**
			 * @name createDataDebounced
			 * @description call createData with 1 second delay
			 */
			createDataDebounced: debounce(function() {
				this.createData();
			}, 1000),

			/**
			 * @name tableFieldBlur
			 * @description field blur event handler
			 */
			tableFieldBlur() {
				this.createData();
			},

			/**
			 * @name pasted
			 * @description paste event handler for payment textbox
			 */
			pasted() {
				if (this.pasteArray.length === 3) return;
				let val = null;
				let paste = (event.clipboardData || window.clipboardData).getData('text');
				if (this.pasteArray.length) val = [...this.pasteArray].pop();
				if (val === null || val == paste) this.pasteArray.push(paste);
				else this.pasteArray = [paste];

				if (this.pasteArray.length === 3) {
					if (!$sessionStorage.isDialogHidden(PAYMENTS_PROMPT_STORAGE_NAME)) {
						// Google Analytics event
						window.dataLayer.push({
							event: 'borrowingForecastPaymentsPromptDisplayed'
						});

						this.paymentsPrompt = true;
						$sessionStorage.hideDialog(PAYMENTS_PROMPT_STORAGE_NAME);
					}
				}
			},

			/**
			 * @name selectRow
			 * @description Row select handler for isolatation of rows
			 * @param {Boolean} value Value of the switch
			 * @param {Number} index Row index
			 */
			selectRow(value, index) {
				if (!value) {
					let arrayIndex = findIndex(this.selectedRows, (rowIndex) => rowIndex === index);
					this.selectedRows.splice(arrayIndex, 1);
				} else this.selectedRows.push(index);

				if (this.selectedRows.length === 0) this.isolated = false;
			},

			/**
			 * @name clearIsolatedRows
			 * @description Clears isolated rows
			 */
			clearIsolatedRows() {
				this.isolated = false;
				this.selectedRows = [];
			},

			/**
			 * @name conditionalDecimal
			 * @description format as decimal if the value is less than 1000
			 */
			conditionalDecimal(value, decimal = true) {
				let format = parseInt(value) < 1000 && decimal ? '0,0.00' : '0,0';
				return numeral(value).format(format);
			},

			/**
			 * @name nativeFormatter
			 * @description format html
			 */
			nativeFormatter(content) {
				let formatted = content.replace(new RegExp(/(<([^>]+)>)/gi), '');
				formatted = formatted.replace(/\s\s\s+/g, '  ');
				formatted = formatted.replace(/\s\s+/g, ' ');
				formatted = formatted.replace(/\s,+/g, ',');
				formatted = formatted.replace(/\s\.+/g, '.');
				formatted = formatted.replace('&amp;', '&');
				return formatted;
			},

			/**
			 * @name printResults
			 * @description Print borrowing forecast calculation results
			 */
			async printResults() {
				if (this.options.calculatorType === null) return false;
				this.printing = true;

				setTimeout(() => {
					window.scroll(0, 0);

					let today = new Date();
					let ref = today.toLocaleString('en-GB', { timeZone: 'GMT' });
					let docDefinition = {
						pageSize: 'A4',
						pageOrientation: 'landscape',
						pageMargins: [20, 20, 20, 20],
						name: `Borrowing Forecast ${ref}`,
						info: {
							title: `Borrowing Forecast ${ref}`,
							author: 'Air Sourcing'
						},
						styles: {
							small: {
								fontSize: 8
							},
							header: {
								fontSize: 10,
								lineHeight: 1.2
							}
						},
						content: []
					};

					html2canvas(document.querySelector('#screenshot_target'), { scale: '2' }).then((canvas) => {
						let blurbs = [1, 2, 3];
						if (this.options.calculatorType === 1) blurbs = [4, 5, 6];

						// First paragraph
						let blurb = document.getElementById('forecastBlurb' + blurbs[0]);
						let content = this.nativeFormatter(blurb.innerHTML);
						docDefinition.content.push({
							text: content,
							style: 'header',
							margin: [0, 0, 0, 0]
						});

						// Headings
						let headings = [{ text: 'Year', fillColor: '#DDDDDD' }];
						if (this.drawdownsActive) {
							headings.push(...[{ text: 'Drawdowns', fillColor: '#DDDDDD' }]);
						}
						headings.push(
							...[
								{ text: 'Interest', fillColor: '#DDDDDD' },
								{ text: 'Payments', fillColor: '#DDDDDD' },
								{ text: '31st Dec', fillColor: '#DDDDDD' },
								{ text: 'Cost', fillColor: '#DDDDDD' }
							]
						);

						// Group Headings
						let groupHeadings = [{ text: '', colSpan: 1, border: [false, false, false, false] }];
						if (this.drawdownsActive) {
							groupHeadings.push(...[{ text: '', colSpan: 1, border: [false, false, false, false] }]);
						}
						groupHeadings.push(
							...[
								{
									text:
										(this.options.calculatorType === 0 ? 'Product One' : 'New Product') +
										'\n initial balance £' +
										this.conditionalDecimal(this.dataSet[0].loanBalanceFirst),
									colSpan: 4,
									alignment: 'center',
									color: '#ffffff',
									fillColor: '#0D47A1',
									bold: true
								},
								{},
								{},
								{}
							]
						);

						// Comparison product
						if (this.comparisonIsActive) {
							blurb = document.getElementById('forecastBlurb' + blurbs[1]);
							content = this.nativeFormatter(blurb.innerHTML);
							docDefinition.content.push({
								text: content,
								style: 'header',
								margin: [0, 10, 0, 0]
							});

							groupHeadings.push(
								...[
									{ text: '', border: [false, false, false, false] },
									{
										text:
											(this.options.calculatorType === 0 ? 'Product Two' : 'Existing Product') +
											'\n initial balance ' +
											this.conditionalDecimal(this.dataSet[0].comparisonBalanceFirst),
										colSpan: 4,
										alignment: 'center',
										color: '#ffffff',
										fillColor: '#17b890',
										bold: true
									},
									{},
									{},
									{}
								]
							);

							headings.push(
								...[
									{ text: 'Difference', fillColor: '#DDDDDD' },
									{ text: 'Interest', fillColor: '#DDDDDD' },
									{ text: 'Payments', fillColor: '#DDDDDD' },
									{ text: '31st Dec', fillColor: '#DDDDDD' },
									{ text: 'Cost', fillColor: '#DDDDDD' }
								]
							);
						}

						// Product
						if (this.options.propertyActive) {
							blurb = document.getElementById('forecastBlurb' + blurbs[2]);
							content = this.nativeFormatter(blurb.innerHTML);
							docDefinition.content.push({
								text: content,
								style: 'header',
								margin: [0, 10, 0, 0]
							});

							groupHeadings.push(
								...[
									{
										text: 'Property\n starting valuation £' + this.conditionalDecimal(this.dataSet[0].priceFirst),
										colSpan: 2,
										alignment: 'center',
										color: '#ffffff',
										fillColor: '#670056',
										bold: true
									},
									{}
								]
							);

							headings.push(
								...[
									{ text: 'Valuation', fillColor: '#DDDDDD' },
									{ text: 'Equity', fillColor: '#DDDDDD' }
								]
							);
						}

						// Add graph
						let dataURL = canvas.toDataURL('image/png');
						docDefinition.content.push({
							image: dataURL,
							width: 800,
							margin: [0, 30, 0, 0]
						});

						let rows = [groupHeadings, headings];

						// Table rows
						this.dataSet.forEach((item, index) => {
							let isSelected = this.selectedRows.includes(index);
							let fontColor = isSelected ? '#000000' : '#444444';
							let fillColor = isSelected ? '#1eb0e1' : '#ffffff';
							let fillOpacity = isSelected ? 0.2 : 0.1;
							let isBold = isSelected;

							let tableRows = [
								{
									text: index + 1,
									fillColor: fillColor,
									fillOpacity: fillOpacity,
									color: fontColor,
									bold: isBold
								}
							];
							if (this.drawdownsActive) {
								tableRows.push(
									...[
										{
											text: this.drawdowns[index] == undefined ? '£0' : '£' + this.conditionalDecimal(this.drawdowns[index], false),
											fillColor: fillColor,
											fillOpacity: fillOpacity,
											color: fontColor,
											bold: isBold
										}
									]
								);
							}

							let interest = '£' + this.conditionalDecimal(item.interest, false);
							if (!item.productOneWithinTerm) interest = '--';
							let payments = this.payments[index] == undefined ? '£0' : '£' + this.conditionalDecimal(this.payments[index], false);
							if (!item.productOneWithinTerm) payments = '--';
							tableRows.push(
								...[
									{ text: interest, fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
									{ text: payments, fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
									{ text: '£' + this.conditionalDecimal(item.loanBalanceFinal), fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
									{ text: '£' + this.conditionalDecimal(item.loanCost), fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold }
								]
							);

							if (this.comparisonIsActive) {
								let cInterest = '£' + this.conditionalDecimal(item.comparisonInterest, false);
								if (!item.productTwoWithinTerm) cInterest = '--';
								let cPayments = this.comparisonPayments[index] == undefined ? '£0' : '£' + this.conditionalDecimal(this.comparisonPayments[index], false);
								if (!item.productTwoWithinTerm) cPayments = '--';
								tableRows.push(
									...[
										{
											text: '£' + this.conditionalDecimal(Math.abs(item.loanCost - item.comparisonCost), false),
											fillColor: item.loanCost > item.comparisonCost ? '#F44336' : '#4CAF50',
											fillOpacity: 0.2,
											color: fontColor,
											bold: isBold
										},
										{ text: cInterest, fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
										{ text: cPayments, fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
										{
											text: '£' + this.conditionalDecimal(item.comparisonBalanceFinal),
											fillColor: fillColor,
											fillOpacity: fillOpacity,
											color: fontColor,
											bold: isBold
										},
										{ text: '£' + this.conditionalDecimal(item.comparisonCost), fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold }
									]
								);
							}

							if (this.options.propertyActive) {
								tableRows.push(
									...[
										{ text: '£' + this.conditionalDecimal(item.priceFinal), fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold },
										{ text: '£' + this.conditionalDecimal(item.equity), fillColor: fillColor, fillOpacity: fillOpacity, color: fontColor, bold: isBold }
									]
								);
							}

							rows.push(tableRows);
						});

						// TODO: Ben to comment this line
						let columnWidths = Array(headings.length).fill('*');
						columnWidths[0] = 'auto';

						docDefinition.content.push({
							style: 'small',
							table: {
								headerRows: 2,
								widths: columnWidths,
								body: rows
							},
							layout: {
								hLineColor: function() {
									return '#e0e0e0';
								},
								vLineColor: function() {
									return '#e0e0e0';
								}
							},
							margin: [0, 30, 0, 0]
						});

						docDefinition.content.push({
							text: 'Please refer to the product criteria to review if Early Repayment Charges may be added.',
							style: 'small',
							margin: [0, 15, 0, 0]
						});

						docDefinition.content.push({
							text: 'Please note that the figures above are for illustrative purposes only.',
							style: 'small',
							margin: [0, 0, 0, 0]
						});

						let pdfDocGenerator = pdfMake.createPdf(docDefinition);

						this.printing = false;

						// Google Analytics event
						window.dataLayer.push({
							event: 'borrowingForecastPrinted'
						});

						// Download the pdf in same tab to prevent new tab issues
						pdfDocGenerator.download(docDefinition.name);
					}, 500);
				});
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.footer-active {
		margin-bottom: 72px;
	}

	.card--active {
		background: map-get($grey, lighten-5);
		border: 1px solid tint($col3, 10%) !important;
	}

	td ::v-deep {
		.v-text-field {
			font-size: 0.8rem;
			padding-top: 0;
			margin-top: -4px input {
				padding: 6px 0 2px;
			}
		}

		.v-text-field__prefix {
			padding-top: 2px;
		}
	}

	th ::v-deep {
		white-space: nowrap;
	}


	.graph {
		border: 1px solid map-get($grey, lighten-2);
	}

	::v-deep .comparisonActivator {
		label {
			font-size: 1.25rem;
			color: #000000;
		}
	}

	td.isolate ::v-deep .v-input--selection-controls__input {
		margin-right: 4px;
	}

	::v-deep span.inline-field {
		display: inline-block;
		font-weight: 500;
		border-bottom: 2px dotted $col1;
		line-height: 1.5;
		cursor: pointer;
	}

	::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
		margin-bottom: 0px;
		margin-top: 4px;
	}

	.v-chip-absolute {
		position: absolute;
		top: -5px;
		left: -5px;
	}

	.line-height-inherit {
		line-height: inherit;
	}
</style>
