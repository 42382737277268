<!--
	@name app-firm-dialog-select-trading-style
	@description Select trading style to associate with adviser
	@date 2020/08/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="500">
		<template #header>
			Select Trading Style
		</template>

		<template #body>
			<dynamic-form ref="dynamicForm" :form-schema="formSchema" :form-data="formData" :submit-button-loading="loading" @dynamic-form-submit="submit" />
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-client-dialog-select-property',

		components: { CommonDialog, DynamicForm },

		props: {
			loading: {
				type: Boolean,
				required: true
			}
		},

		data() {
			return {
				formData: {},
				account: {},
				organisationTradingStyleList: []
			};
		},

		computed: {
			tradingStyleOptions() {
				return (this.organisationTradingStyleList).map((tradingStyle) => ({ value: { id: tradingStyle.id, name: tradingStyle.name }, text: tradingStyle.name }));
			},

			formSchema() {
				return [
					{
						type: 'select',
						multiple: true,
						name: 'selected_trading_styles',
						display: 'Select a trading style to associate with the adviser',
						rules: 'required',
						options: this.tradingStyleOptions,
						sm: 12
					}
				];
			}
		},

		methods: {
			...mapActions('AccountOrganisationTradingStyle', ['loadOrganisationTradingStyleList']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			async open(account) {
				this.account = account;

				this.organisationTradingStyleList = await this.loadOrganisationTradingStyleList();

				let selectedTradingStyles = [];
				for(const tradingStyle of this.account.organisationTradingStyles || []) {
					selectedTradingStyles.push({ id: tradingStyle.id, name: tradingStyle.name });
				}
				this.formData = { selected_trading_styles: selectedTradingStyles };

				if (this.$refs.dynamicForm) this.$refs.dynamicForm.$refs.observer.reset();
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description submit new client details
			 */
			async submit(data) {
				this.$refs.dialog.close();

				this.$emit('submit', { organisationTradingStyles: { ...data.selected_trading_styles }, account: this.account });
			}
		}
	};
</script>
