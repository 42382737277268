<!--
	@name app-air-club-register-button
	@description Button to submit a request for further information regarding the club
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-btn class="primary" :dark="!membership('club').request" :disabled="!!membership('club').request" :loading="loading" @click="register">
			<v-icon left>
				mdi-wallet-membership
			</v-icon>
			Register now for FREE membership
		</v-btn>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-air-club-register-button',

		components: {},

		data: () => ({
			loading: false
		}),

		computed: {
			...mapState('Account', ['user']),
			...mapGetters('Account', ['membership'])
		},

		methods: {
			...mapActions('Account', ['requestMembership', 'verify']),

			/**
			 * @name furtherInfo
			 * @description Get further info
			 */
			async register() {
				try {
					this.loading = true;
					await this.requestMembership({ type: 'club', identityId: this.user.id, identityType: 'user' });
					ElementTools.fireNotification(this.$el, 'success', 'You have successfully joined Air Club');
					await this.verify();
					this.loading = false;
				} catch (e) {
					ElementTools.fireNotification(this.$el, 'error', e);
				}
			}
		}
	};
</script>

<style scoped lang="scss"></style>
