<!--
	@name app-client-dialog-add-note
	@description Add New Client Note
	@date 2020/07/08
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Add Note
		</template>
		<template #body>
			<p v-if="text" class="text">
				{{ text }}
			</p>
			<dynamic-form ref="dynamicForm" :form-schema="form" :form-data="data" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-client-dialog-add-client',

		components: { CommonDialog, DynamicForm },

		props: {
			client: { type: Object, required: false, default: null },
			association: { type: Object, required: false, default: null }
		},

		data() {
			return {
				data: {},
				text: null,
				form: [
					{
						type: 'textarea',
						name: 'note',
						display: 'Note',
						rules: 'required',
						sm: 12
					}
				],
				submitting: false
			};
		},

		methods: {
			...mapActions('LogActivityUser', ['addActivityUser']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open(text) {
				this.text = null;
				if (text) this.text = text;
				this.data = {};
				if (this.$refs.dynamicForm) this.$refs.dynamicForm.$refs.observer.reset();
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description submit new client details
			 */
			submit() {
				this.submitting = true;
				const clients = [this.client];
				if (this.association) clients.push(this.association);

				this.addActivityUser({
					type: 'client_note',
					name: `Client Note Added for ${clients.map((client) => `${client.nameTitleName} ${client.nameGiven} ${client.nameFamily}`).join(' and ')}`,
					description: `${this.data.note}`,
					data: {
						client: clients.map((client) => ({ id: client.id, name: `${client.nameTitleName} ${client.nameGiven} ${client.nameFamily}` })),
						status: 'added'
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'New note has been successfully added against your client');
						this.$emit('note-added');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to add note'))
					.finally(() => {
						this.submitting = false;
						this.$refs.dialog.close();
					});
			}
		}
	};
</script>

<style scope lang="scss">
	.text {
		margin-bottom: 0px !important;
	}
</style>
