<!--
	@name common-form-company
	@description Common company form
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex">
		<div v-if="form.length" class="flex-grow-1">
			<common-structure-section class="flex-grow-1 mb-6 elevation-2">
				<template #header>
					Company Details
				</template>
				<template #body>
					<p class="text-body-1 ma-0">
						<span class="detail font-weight-bold">{{ item['firm.name'] }}</span>
					</p>

					<v-alert border="left" dense text :type="isValid ? 'success' : 'error'" class="mt-2 mb-0">
						<v-row align="center" class="px-3">
							<v-col v-if="isValid" class="grow pa-0 text-subtitle-2">
								You have entered the required company details
							</v-col>

							<v-col v-else class="grow pa-0 text-subtitle-2">
								We require further information relating to your company.
							</v-col>

							<v-col class="shrink pa-0">
								<v-btn small dark @click="openDynamicFormDialog">
									Update Details
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>
				</template>
			</common-structure-section>
		</div>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="dynamicFormSubmit" />
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';

	export default {
		name: 'common-form-company',

		components: {
			CommonStructureSection,
			DynamicFormDialog
		},

		props: {
			form: { type: Array, required: true, default: () => [] },
			item: { type: Object, required: true, default: () => ({}) }
		},

		data() {
			return { isValid: false };
		},

		methods: {
			/**
			 * @name validateForm
			 * @description Validates adviser details form and sets result to store
			 */
			async validateForm() {
				this.isValid = await this.$refs.dynamicFormDialog.validateForm(this.form, this.item);
				this.$emit('set-validation', { company: this.isValid });
			},

			/**
			 * @name dynamicFormSubmit
			 * @description Pass payload through parent
			 * @param {Object} payload
			 */
			dynamicFormSubmit(payload) {
				this.$emit('dynamic-form-submit', payload.data);
			},

			/**
			 * @name openDynamicFormDialog
			 * @description Event for open dynamic form dialog
			 */
			openDynamicFormDialog() {
				this.$refs.dynamicFormDialog.open({
					formSchema: this.form,
					formData: this.item,
					submitButtonText: 'Update Company Details',
					title: 'Company Details',
					action: 'setCompany'
				});
			}
		}
	};
</script>
