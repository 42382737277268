<!--
	@name app-source-result-mortgage-item-name
	@description Lifetime mortgage product item name
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div class="text-weight-medium font-weight-bold mb-1 include-2">
			{{ item.provider.name }}
		</div>
		<span class="text-body-2 font-weight-bold">
			{{ item.name }}
		</span>
		<div class="d-block">
			<v-tooltip v-if="item.enhancedImpairedLife" bottom>
				<template v-slot:activator="{ on }">
					<v-chip class="mr-2 mt-2" color="success" dark label x-small active v-on="on">
						Medically Underwritten
					</v-chip>
				</template>
				<span>Product has been medically underwritten</span>
			</v-tooltip>

			<v-chip v-if="item.preview" class="mr-2 mt-2" color="warning accent-3" label x-small active>
				PREVIEW
			</v-chip>
			<v-chip v-if="item.exclusive" class="mr-2 mt-2" color="cyan accent-3" text-color="black" label x-small active>
				EXCLUSIVE
			</v-chip>
			<v-chip v-if="item.loanPaymentTypeName" class="mr-2 mt-2" color="rgba(103, 0, 86, 1)" dark label x-small active>
				{{ item.loanPaymentTypeName }}
			</v-chip>
			<v-chip v-if="item.earlyRepaymentChargeIsFixed" class="mr-2 mt-2" color="rgba(103, 0, 86, 0.8)" dark label x-small active>
				Fixed ERC
			</v-chip>
			<v-chip v-if="item.incomeAssessed" class="mr-2 mt-2" color="warning accent-3" label x-small active>
				Income Assessed
			</v-chip>
			<v-chip v-if="item.mandatoryPayments" class="mr-2 mt-2" color="error" label x-small active>
				Mandatory Payments
			</v-chip>

			<div v-if="item.procurationFee.length">
				<span v-for="(fee, index) in item.procurationFee" :key="index">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-chip class="mr-2 mt-2" :color="procurationFeeChipColor(fee.type)" text-color="white" label x-small active v-on="on">
								{{ fee.name }}
							</v-chip>
						</template>
						<span> Proc Fee: {{ fee.amount | numFormat('0,0.00') }}% </span>
					</v-tooltip>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-name',

		props: {
			item: { type: Object, required: true }
		},

		methods: {
			/**
			 * @name procurationFeeChipColor
			 * @description Get Procuration Fee chip color based on type
			 * @param {String} type Procuration Fee type
			 * @return {String} color
			 */
			procurationFeeChipColor(type) {
				if (type === 'club') return 'rgba(30, 176, 225, 1)';
				else if (type === 'academy') return 'rgba(27, 120, 216, 1)';
				else if (type === 'platinum') return 'blue-grey lighten-1';
				else if (type === 'platinumPlus') return 'blue-grey';
				else if (type === 'elite') return 'blue-grey darken-1';
				else if (type === 'select') return '#8d6e63';
			}
		}
	};
</script>

<style scoped lang="scss">
	.include-2 {
		display: none;
	}

	@media (max-width: 1235px) {
		.include-2 {
			display: block;
		}
	}
</style>