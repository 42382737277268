<!--
	@name common-structure-toolbar
	@description Toolbar
	@date 2021/10/14
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-structure-toolbar>
		<template v-slot:toolbar-items>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn text color="primary darken-3" to="/account" v-on="on">
						<v-icon>mdi-backburger</v-icon>
					</v-btn>
				</template>
				<span>Back to Dashboard</span>
			</v-tooltip>
		</template>
	</common-structure-toolbar>
-->

<template>
	<v-toolbar class="mb-6">
		<v-toolbar-title class="text-h6 font-weight-bold">
			<slot v-if="$slots.title" name="title" />
			<span v-else>{{ title }}</span>
		</v-toolbar-title>

		<v-spacer />

		<v-toolbar-items>
			<slot name="toolbar-items" />

			<v-tooltip v-if="backToDashboard" bottom>
				<template #activator="{ on }">
					<v-btn text color="primary darken-3" to="/account" v-on="on">
						<v-icon>mdi-backburger</v-icon>
					</v-btn>
				</template>
				<span>Back to Dashboard</span>
			</v-tooltip>
		</v-toolbar-items>
	</v-toolbar>
</template>

<script>
	export default {
		name: 'common-structure-toolbar',

		props: {
			title: { type: String, default: '' },
			backToDashboard: { type: Boolean, default: true }
		}
	};
</script>
