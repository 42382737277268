<!--
	@name app-dialog-select-trading-style
	@description Show a dialog for selecting trading style
	@date 2024/03/28
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" persistent :show-close="showClose">
		<template #header>
			Select Trading Style
		</template>
		<template #body>
			<p v-if="noTradingStyles" class="text-body-1 mb-4 font-weight-medium">
				Please note that your account has not been assigned any Trading Styles yet.
			</p>
			<p v-else class="text-body-1 mb-4 font-weight-medium">
				Select the Trading Style that you will be using
			</p>
			<div v-if="tradingStyleOptions.length">
				<v-list-item v-for="(item, index) in tradingStyleOptions" :key="item.id" :class="{ 'mt-1': index !== 0 }" @click="selectTradingStyle(item)">
					<v-list-item-title v-text="item.name" />
				</v-list-item>
			</div>
			<div v-else-if="noTradingStyles">
				<p>Please contact the account owner to set up your trading styles.</p>
			</div>
			<div v-else>
				<v-card v-for="key in 3" :key="key" class="mb-2">
					<v-skeleton-loader type="list-item-two-line" />
				</v-card>
			</div>
			<common-dialog-confirm ref="confirm" />
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { ElementTools, EventBus } from '@/utils';

	export default {
		name: 'app-dialog-select-trading-style',

		components: { CommonDialog, CommonDialogConfirm },

		data: () => ({
			noTradingStyles: false,
			message: null,
			messageError: false,
			success: false,
			showClose: false
		}),

		computed: {
			...mapState('Account', ['user', 'department','organisation']),
			...mapState('AccountUserTradingStyle', ['organisationTradingStyleList']),

			tradingStyleOptions() {
				return (this.organisationTradingStyleList || []).map((tradingStyle) => ({ id: tradingStyle.id, name: tradingStyle.name, fca: tradingStyle.data?.fca || null  }));
			}
		},

		created() {
			this.loadOrganisationTradingStyleList().then(() => {
				if(!this.tradingStyleOptions.length) {
					if(this.department.name && this.department.name.includes('owner')) {
						this.$refs.dialog.close();
					} else this.noTradingStyles = true;
				}
			});
		},

		methods: {
			...mapActions('AccountOrganisationUser', ['activateUserOrganisationTradingStyle']),
			...mapActions('AccountUserTradingStyle', ['loadOrganisationTradingStyleList']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open(click) {
				if(click) {
					this.showClose = true;
					this.$refs.dialog.open();
				}
				this.loadOrganisationTradingStyleList({ where: { approved: { c: 'IS NOT', v: null}}}).then(() => {
					if(this.tradingStyleOptions.length) {
						const activeTradingStyle = this.organisation.organisationTradingStyles.find((ots) => ots.active);
						if(!activeTradingStyle) {
							if(!click) {
								this.$refs.dialog.open();
							}
						}
					} else this.noTradingStyles = true;
				});
			},

			/**
			 * @name selectTradingStyle
			 * @description select the active trading style
			 */
			 selectTradingStyle(item) {
				this.$refs.dialog.close();

				this.activateUserOrganisationTradingStyle({ userId: this.user.id, organisationTradingStyleId: item.id, name: item.name, fca: item.fca || null })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', `You have selected ${item.name} as your trading style`);
						EventBus.$emit('trading-style-updated');
					})
					.catch((e) => console.log(e));
			}
		}
	};
</script>
