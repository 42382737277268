<!--
	@name app-firm-dialog-add-providers
	@description trading style add providers dialog component
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<common-dialog ref="addProviderDialog" :max-width="800">
			<template #header>
				Add New Provider
			</template>

			<template #fixed>
				<v-container>
					<v-row v-if="selectedProviders.length" align="center" justify="start" class="px-4 pb-6 pt-2">
						<v-chip v-for="(selection, i) in selectedProviders" :key="selection.text" :disabled="loading" close class="mx-2 mt-4" @click:close="selectedProviders.splice(i, 1)">
							{{ selection.text }}
						</v-chip>
					</v-row>
				</v-container>

				<v-divider v-if="selectedProviders.length" />
			</template>

			<template #body>
				<div>
					<v-list :max-height="400">
						<template v-for="item in providerOptions">
							<v-list-item v-if="!selectedProviders.includes(item)" :key="item.text" :disabled="loading" @click="selectedProviders.push(item)">
								<v-list-item-title v-text="item.text" />
							</v-list-item>
						</template>
					</v-list>
				</div>
			</template>

			<template #footer>
				<div class="d-flex justify-center my-4">
					<v-btn color="success" :loading="loading" :disabled="!selectedProviders.length" @click="submit">
						Submit
					</v-btn>
				</div>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-firm-dialog-add-providers',

		components: {
			CommonDialog,
			CommonDialogConfirm
		},

		props: {
			organisations: { type: Array, default: () => [] }
		},

		data() {
			return {
				tradingStyle: null,
				activeProviders: [],
				selectedProviders: [],
				loading: false
			};
		},

		computed: {
			providerOptions() {
				return this.organisations
					?.filter((o) => !this.activeProviders.includes(o.id))
					?.map((o) => ({
						text: o.name,
						value: o.id
					}));
			}
		},

		methods: {
			...mapActions('AccountOrganisationTradingStyle', ['updateSourcingPanel']),

			/**
			 * @name open
			 * @description open providers dialog
			 */
			open(tradingStyle) {
				this.tradingStyle = tradingStyle;
				this.activeProviders = [];
				if(this.tradingStyle.panelData?.panel?.sourcing) {
					this.activeProviders = this.tradingStyle.panelData.panel.sourcing;
				}
				this.selectedProviders = this.organisations
					?.filter((o) => this.activeProviders.includes(o.id))
					?.map((o) => ({
						text: o.name,
						value: o.id
					}));
				this.loading = false;
				this.$refs.addProviderDialog.open();
			},

			/**
			 * @name submit
			 * @description submit new providers
			 */
			submit() {
				this.$refs.confirm
					.open('Add Providers', `Are you sure you want to add these providers?`)
					.then(() => {
						this.loading = true;

						// Array of provider UUIDs
						const sourcing = this.selectedProviders.map((p) => p.value);

						// Filter reject data sets by provider - only providers in list above apply
						const reject = this.tradingStyle.panelData?.reject ? Object.keys(this.tradingStyle.panelData.reject)
							.filter(key => sourcing.includes(key))
							.reduce((obj, key) => {
								obj[key] = this.tradingStyle.panelData.reject[key];
								return obj;
							}, {}) : {};

							this.updateSourcingPanel({
									id: this.tradingStyle.id,
									payload: {
										sourcing: this.selectedProviders.map((p) => p.value),
										reject: reject
									}
								})
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Providers have been added successfully'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst adding Providers to this enquiry'))
							.finally(() => {
								this.$refs.addProviderDialog.close();
								this.$emit('submitted');
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
