import { AccountUserRecommendation } from '@/service';

/**
 * @class @name User
 * @description Exposes menu store module
 */
export default class User {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @name getUserRecommendation
			 * @description get user recommendation
			 * @param {function} commit
			 * @param {String} uid
			 * @returns {Object} Promise a http response
			 */
			getUserRecommendation(store, uid) {
				return AccountUserRecommendation.get(uid);
			},

			/**
			 * @name addUserRecommendation
			 * @description add user recommendation
			 * @param {function} commit
			 * @param {Object} data
			 */
			addUserRecommendation(store, data) {
				let { uid, payload } = data;
				return AccountUserRecommendation.add(uid, payload);
			},

			/**
			 * @name deleteUserRecommendation
			 * @description add user recommendation
			 * @param {function} commit
			 * @param {Object} data
			 */
			deleteUserRecommendation(store, data) {
				let { uid, id } = data;
				return AccountUserRecommendation.delete(uid, id);
			}
		};
	}
}
