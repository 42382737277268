<!--
	@name app-client-dialog-add-property
	@description Add New Property
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Add Property
		</template>

		<template #body>
			<div v-if="!form" class="text-center">
				<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
			</div>

			<dynamic-form v-else ref="dynamicForm" :form-schema="form('add_property').value" :form-data="data" :submit-button-loading="loading" @dynamic-form-submit="submit" />
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-client-dialog-add-property',

		components: { CommonDialog, DynamicForm },

		data() {
			return {
				data: {},
				association: null,
				loading: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('add-property');
		},

		methods: {
			...mapActions('client', ['fetchProperties']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AppClientAsset', ['addClientAsset']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open({ association }) {
				this.data = {};
				if (association) this.association = association;
				if (this.$refs.dynamicForm) this.$refs.dynamicForm.$refs.observer.reset();
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description submit new property details
			 */
			async submit() {
				this.loading = true;
				const payload = {
					type: 'property',
					data: {
						location: {
							address1: this.data.address1,
							address2: this.data.address2,
							townCity: this.data.townCity,
							county: this.data.county,
							countryId: this.data.countryId,
							postcode: this.data.postcode
						}
					}
				};

				if (this.association?.id) payload.associationId = this.association.id;

				this.addClientAsset({ clientId: this.$route.params.id, payload })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'New property has been successfully added');
						this.$emit('load-client-asset');
					})
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error.data.message || `An error occured whilst adding property`))
					.finally(() => {
						this.loading = false;
						this.$refs.dialog.close();
					});
			}
		}
	};
</script>

<style scoped></style>
