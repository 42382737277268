<template>
	<v-footer color="#1c1d36">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" class="mb-6">
				<p class="text-body-2 white--text mb-0">
					{{ appConfig.softwareName }} is a Trading Style of {{ appConfig.company.name }}. {{ appConfig.company.name }} is a Limited Company registered in England and
					Wales number: {{ appConfig.company.number }}. {{ appConfig.contact.address }}. Opening hours: {{ appConfig.contact.openingHours }}.
				</p>
			</v-col>
			<v-col cols="12" sm="6">
				<p class="text-body-2 white--text mb-0">
					Copyright © 2018 {{ appConfig.company.name }}
					<br />
					Telephone: {{ appConfig.contact.number }}
					<br />
					Email: <a :href="`mailto:${appConfig.contact.email}`">{{ appConfig.contact.email }}</a>
					<br />
					<a @click="openTerms">Terms and Conditions</a> | <a @click="openPrivacy">Privacy Notice</a>
				</p>
			</v-col>
			<v-col cols="12" sm="6" class="text-right">
				<img :src="'logo/site/era2019.jpg' | urlize" style="max-height: 6rem" class="pa-3 white" />
				<img :src="'logo/site/erclogo.png' | urlize" style="max-height: 6rem" class="pa-3 white ml-2" />
			</v-col>
		</v-row>

		<app-common-terms ref="terms" />
		<app-common-privacy ref="privacy" />
	</v-footer>
</template>

<script>
	import AppCommonTerms from '@/component/app/common/terms';
	import AppCommonPrivacy from '@/component/app/common/privacy';
	// import { mapGetters, mapState } from 'vuex';

	export default {
		name: 'app-layout-default-footer',

		components: { AppCommonTerms, AppCommonPrivacy },

		methods: {
			openTerms() {
				this.$refs.terms.open();
			},

			openPrivacy() {
				this.$refs.privacy.open();
			}
		}
	};
</script>
