<!--
	@name app-summer-trmna1408024-testimonial
	@description Campaign page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<!-- Testimonial -->
		<div>
			<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
				<v-sheet class="rounded pa-6" style="background-color: #0f75bc;">
					<v-row class="px-10">
						<v-col class="grow align-content-center pr-10">
							<v-icon size="60" color="#1d1d37" class="mb-0" style="position: relative; left: -8px; top: -8px;">
								mdi-format-quote-open
							</v-icon>
							<p :class="testimonial.copyClass ||'text-h5 font-weight-bold mb-0'" style="color: #fff;" v-html="autoLink($sanitize(testimonial.copy))" />
							<p v-if="testimonial.name" class="text-body-2 font-weight-medium mt-5 mb-0" style="color: #1d1d37;">
								{{ testimonial.name }}
							</p>
							<p v-if="testimonial.position" class="text-body-2 font-weight-regular mb-0" style="color: #1d1d37;">
								{{ testimonial.position }}
							</p>
						</v-col>
						<v-col class="shrink align-content-center d-none d-md-block">
							<v-avatar
								size="200px"
							>
								<img
									:alt="testimonial.name"
									:src="testimonial.image | urlize"
								/>
							</v-avatar>
						</v-col>
					</v-row>
				</v-sheet>
			</v-container>
		</div>
	</div>
</template>

<script>
	import Autolinker from 'autolinker';

	export default {
		name: 'app-summer-trmna1408024-testimonial',

		components: {},

        props: {
			testimonials: { type: Array, default: () => [] }
		},

		computed: {
			testimonial() {
				if (!this.testimonials) return false;
				return this.testimonials[0];
			}
		},

		methods: {
			/**
			 * @name autoLink
			 * @description Purify html
			 */
			autoLink(text) {
				return Autolinker.link(text, { stripPrefix: false });
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
