import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Transaction
 * @exports Service/App/Reward/Transaction
 * @description API Reward Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Transaction {
	/**
	 * @static @name list
	 * @description Get all reward transactions
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('reward/transaction/list', payload);
	}

	/**
	 * @static @name add
	 * @description Add a new reward trandaction resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`reward/transaction`, payload);
	}

	/**
	 * @static @name modify
	 * @description modify a new reward trandaction resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`reward/transaction/${id}`, payload);
	}
}
