<!--
	@name common-statistic-reward-counter
	@description Rewards page counter
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name">
		<template #header>
			{{ section === 'rewards' ? 'Current Balance' : 'Air Rewards' }}
		</template>
		<template #body>
			<v-skeleton-loader v-if="total === undefined" type="list-item-avatar-three-line" />
			<div v-else>
				<v-row v-if="total > 0 || section === 'rewards'" class="no-gutters blue-grey lighten-5 blue-grey--text text--darken-4" align="center">
					<v-col class="shrink pa-5">
						<common-base-progress-circle :value="total" :size="100" :width="8" colour="blue darken-2" />
					</v-col>
					<v-col class="grow pa-5 pl-0">
						<p class="text-body-1 font-weight-bold mb-0">
							Your points are added automatically each time you engage with our range of relevant Air Group services.
						</p>
					</v-col>
				</v-row>
				<v-card v-else class="no-gutters grey lighten-3 py-4 px-5" hover to="/rewards">
					<p class="ma-0 text-h6 text-left font-weight-regular">
						What are <span class="font-weight-bold light-blue--text text--accent-3">Air Rewards</span> and how can I make them work for me? <span class="font-weight-bold light-blue--text text--accent-3">Click here</span> to start earning!
					</p>
				</v-card>
			</div>
		</template>
	</common-structure-section>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonBaseProgressCircle from '@/component/common/base/progress-circle';

	export default {
		name: 'common-statistic-reward-counter',

		components: { CommonStructureSection, CommonBaseProgressCircle },

		props: {
			total: { type: Number, default: undefined },
			section: { type: String, default: 'rewards' }
		},

		computed: {
			/**
			 * @name copy
			 * @description Create a text based on current page
			 * @return {String} text
			 */
			copy() {
				if (this.section === 'rewards') return null;
				return 'By requesting your KFIs via Air Sourcing, instructing a Will/LPA, or by using a range of other services offered by Air Group, you will be automatically rewarded with Reward Points. Points can be exchanged for cash or donated to Charity.';
			}
		},

		methods: {}
	};
</script>
