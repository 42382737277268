<!--
	@name app-firm-complete-registration
	@description Register page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="site" to="/">
					<v-img class="mx-auto mb-6" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
				</router-link>

				<v-skeleton-loader v-if="validating" type="list-item-three-line" class="mt-6" />

				<div v-else-if="error === 'invalid_token'">
					<v-icon class="text-h2" color="error">
						mdi-exclamation
					</v-icon>
					<v-card-title class="text-h6 font-weight-bold d-block">
						Invalid Invitation
					</v-card-title>
					<v-card-text class="text-body-1 pb-0">
						This invitation is not valid. If you think this is not correct please let us know at {{ site.contact.number }} or
						<a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
					</v-card-text>
				</div>

				<div v-else-if="error === 'expired'">
					<v-icon class="text-h2" color="error">
						mdi-cancel
					</v-icon>
					<v-card-title class="text-h6 font-weight-bold d-block">
						Invitation Expired
					</v-card-title>
					<v-card-text class="text-body-1 pb-0">
						This invitation is now expired. Please contact account owner for a new invitation.
					</v-card-text>
				</div>

				<div v-else>
					<p class="text-h5 font-weight-bold mt-8 mb-2">
						Approve Invitation
					</p>

					<p class="text-body-1 mt-3 mb-0">
						You've been invited by <span class="font-weight-bold">{{ inviter }}</span> to join firm <span class="font-weight-bold">{{ organisation }}</span>.
					</p>

					<v-alert v-if="error === 'is_business_owner'" prominent text dense type="error" border="left" class="mt-3">
						<span class="text-body-2 font-weight-bold">
							You will not be able to accept this invitation as you are a business owner of a firm with active members. Please contact our support team for guidance.
						</span>
					</v-alert>

					<div v-else>
						<p class="text-body-1">
							You can accept the invitation by clicking accept button below.
						</p>
						<v-btn color="primary" type="submit" :loading="loading" large @click="submit">
							Accept Invitation
						</v-btn>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-firm-complete-registration',

		props: {
			token: {
				type: String,
				required: true
			}
		},

		data() {
			return {
				validating: true,
				error: false,
				invitee: null,
				organisation: null,
				loading: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site'])
		},

		created() {
			this.getAcceptInvitation(this.token)
				.then(({ data }) => {
					this.error = false;
					this.inviter = data.inviter;
					this.organisation = data.organisation;
				})
				.catch(({ data }) => {
					this.error = data?.invalid || 'invalid_token';
					this.inviter = data?.inviter;
					this.organisation = data?.organisation;
				})
				.finally(() => {
					this.validating = false;
				});
		},

		methods: {
			...mapActions('AccountRegistrationAcceptInvitation', ['getAcceptInvitation', 'submitAcceptInvitation']),

			/**
			 * @name submit
			 * @description Submits accept invitation
			 */
			async submit() {
				this.loading = true;

				this.submitAcceptInvitation(this.token)
					.then(() => this.$router.push('/advisers'))
					.catch((error) => ElementTools.fireNotification(this.$el, 'error', error.data?.message || 'An error occurred. Please try again later.'));

				this.loading = false;
			}
		}
	};
</script>
