<!--
	@name common-base-menu-hint
	@description Show a hint overlay with close button
	@date 2020/05/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<v-menu
		offset-y
		:open-on-click="false"
		:close-on-content-click="false"
		:close-on-click="true"
		:nudge-bottom="nudgeBottom"
		origin="top center"
		content-class="menuHint elevation-20"
		min-width="auto"
		:value="active"
	>
		<template #activator="{ menu }">
			<slot name="target" v-on="menu" />
		</template>

		<v-sheet
			v-if="displayStandard"
			tile
			class="pa-4 text-left"
			:dark="dark"
			:class="color"
			:style="`width: ${width}; border-left: ${borderWidth} ${borderStyle} ${borderColor} !important`"
		>
			<v-btn icon color="white" small class="d-inline float-right ml-3 mb-3" @click="hide()">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<div class="mb-0 left d-inline">
				<slot name="standard" />
			</div>
		</v-sheet>

		<slot v-if="displayBespoke" name="bespoke" />
	</v-menu>
</template>

<script>
	import colors from 'vuetify/lib/util/colors';
	import { get } from 'lodash';

	export default {
		name: 'common-base-image-tooltip',

		props: {
			dark: { type: Boolean, default: true },
			nudgeBottom: { type: Number, default: 10 },

			border: {
				type: Object,
				default: () => ({
					width: '6px',
					style: 'solid',
					color: 'yellow.accent2'
				})
			},
			color: { type: String, default: 'blue darken-2' },
			width: { type: String, default: '20rem' }
		},

		data() {
			return {
				active: false
			};
		},

		computed: {
			borderColor: function() {
				return get(colors, this.border.color);
			},

			borderStyle: function() {
				return this.border.style;
			},

			borderWidth: function() {
				return this.border.width;
			},

			displayStandard: function() {
				return !!this.$slots.standard;
			},

			displayBespoke: function() {
				return !!this.$slots.bespoke;
			}
		},

		methods: {
			show: function() {
				this.active = true;
			},

			hide: function() {
				this.active = false;
				this.$emit('hide', this.activeTags);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.menuHint {
		white-space: normal !important;
	}
</style>
