<!--
	@name app-api-health
	@description API Health page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-10 h-100">
		<app-common-api-health-items :providers="providers" layout="alt" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import AppCommonApiHealthItems from '@/component/app/common/api-health/items';

	export default {
		name: 'app-tools',

		components: {
			AppCommonApiHealthItems
		},

		data() {
			return {
				loading: true,
				providers: []
			};
		},

		computed: {},

		created() {
			this.loadConfig({ type: 'lifetime-mortgage', name: 'sourcing', params: { panel: false } }).then((response) => {
				if (!response.error) {
					let providers = this.providers;
					if (response.data.api?.organisation) providers.push(...response.data.api.organisation);
					this.providers = providers.map((i) => ({ ...i, status: null }));
					this.loading = false;
				}
			});

			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('CmsConfig', ['loadConfig']),

			onMessage(message) {
				if (message?.type !== 'apiHealth') return;

				this.providers = this.providers.map((p) => {
					if (p.apiHealth?.organisationProviderId === message.data.organisationProviderId) {
						return { ...p, apiHealth: message.data };
					}
					return p;
				});
			}
		}
	};
</script>
