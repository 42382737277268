import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { $http } from '@/utils';

// Routes
import routerAirAcademy from './academy';
import routerAirClub from './club';
import routerClient from './client';
import routerDashboard from './dashboard';
import routerEnquiry from './enquiry';
import routerFirm from './firm';
import routerReward from './reward';
import routerSettings from './settings';
import routerSource from './source';
import routerTools from './tools';

import routerFrontPage from './front';
import routerAirLaterLife from './air-later-life';

import AppDownload from '@/component/app/download';
import AppDownloadAsset from '@/component/app/download-asset';
import AppMarket from '@/component/app/market';
import AppCaseManager from '@/component/app/case-manager';
import AppChangelog from '@/component/app/changelog';
import AppApiHealth from '@/component/app/api-health';

import AppAccountSettingsCredentialsOauth from '@/component/app/account-settings/credentials/oauth';
import AppError from '@/component/app/error';
import AppSignedAuthentication from '@/component/app/signed-authentication';

import FirmAcceptInvitation from '@/component/app/firm/accept-invitation';
import { EventBus } from '@/utils';

Vue.use(VueRouter);

// Suppress duplicate navigation error in production
if (process.env.NODE_ENV === 'production') {
	const routerPush = VueRouter.prototype.push;
	VueRouter.prototype.push = function(location) {
		return new Promise((resolve, reject) => {
			routerPush.call(
				this,
				location,
				() => {
					resolve(this.currentRoute);
				},
				(error) => {
					if (error.name === 'NavigationDuplicated') resolve(this.currentRoute);
					else reject(error);
				}
			);
		});
	};
}

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

const routes = [
	...routerAirAcademy,
	...routerAirClub,
	...routerClient,
	...routerDashboard,
	...routerEnquiry,
	...routerFirm,
	...routerReward,
	...routerSettings,
	...routerSource,
	...routerTools,

	// Front Pages
	...routerFrontPage,

	// Air Later Life
	...routerAirLaterLife,

	{ path: '/cases', name: 'caseManager', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppCaseManager, redirect: () => ({ path: '/account' }) },
	{ path: '/resources', name: 'downloads', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppDownload },
	{ path: '/on-air', name: 'onAir', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppChangelog },
	{ path: '/market', name: 'market', meta: { layout: 'default-authed' }, component: AppMarket },
	{ path: '/health', name: 'health', meta: { layout: 'authed-blank' }, component: AppApiHealth },

	// Oauth
	{ path: '/oauth/:partner?', name: 'oauth', meta: { layout: 'blank' }, component: AppAccountSettingsCredentialsOauth, props: true },

	// Signed Authentication
	{ path: '/signed-login/:userId/:id', name: 'signedAuthentication', meta: { public: true, layout: 'default-minimal' }, component: AppSignedAuthentication, props: true },

	// Error Pages
	{ path: '/500', name: 'error500', meta: { public: true, layout: 'default-minimal' /*, code: 500*/ }, component: AppError },
	{ path: '*', name: 'error404', meta: { public: true, layout: 'default-minimal' /*, code: 404*/ }, component: AppError },

	{ path: '/accept-invitation/:token', name: 'acceptInvitation', meta: { public: true, layout: 'default-minimal' }, component: FirmAcceptInvitation, props: true },

	{ path: '/download-asset/:assetId', name: 'downloadAsset', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppDownloadAsset }
];

const adminRoutes = ['dashboard.activity', 'settings', 'settings.details', 'settings.security', 'settings.preferences'];
const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes });

router.beforeEach(async(to, from, next) => {
	if (to.path === '/maintenance' && store.getters['CmsSite/site']) return next();

	window.scrollTo(0, 0);

	if (window._isExternalRoute) {
		return next();
	}

	// clear tokens on signed authentication
	if (to.name === 'signedAuthentication') {
		$http.clearToken();
	}

	// wait for prerequisite requests to be completed
	if (!store.getters['App/initialised']) await store.dispatch('App/init');

	// check if the site is a white label, the route is a public page, and in the allowed pages array
	if (
		to.meta.public &&
		!store.getters['CmsSite/allowedPublicRoutes'].includes('*') &&
		!to.matched.some((route) => store.getters['CmsSite/allowedPublicRoutes'].includes(route.name))
	) {
		console.log('page not allowed: ' + to.name);
		return router.push('/advisers');
	}

	// check if the route is restricted
	if (to.matched.find((route) => route.meta.requiresSiteCheck) && to.matched.some((route) => store.getters['CmsSite/restrictedRoutes'].includes(route.name))) {
		console.log('no access to: ', to.path);
		if (to.meta.public) {
			if (!store.getters['CmsSite/restrictedRoutes'].includes('home')) router.push('/');
			else router.push('/advisers');
		} else router.push('/account');
		return;
	}

	// not public, then check authed
	if (!to.meta.public && !store.getters['Account/authed']) {
		// wait for auth if not already
		await store.dispatch('Account/verify').catch(() => router.push('/advisers'));
	}

	// if admin and not admin route, redirect to dashboard
	if (store.getters['Account/isAdmin'] && !adminRoutes.includes(to.name)) {
		return next({ name: 'dashboard.activity' });
	}

	// check for version update
	if (location.hostname !== 'localhost') {
		store.dispatch('SystemVersion/getVersionCheck').then(() => {
			if (store.getters['SystemVersion/versionUpgrade']) EventBus.$emit('app-version-update');
		});
	}

	next();
});

export default router;
