<!--
	@name app-reward-report
	@description Rewards page report rewards
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name">
		<template #header>
			Report a missing completion
		</template>
		<template #body>
			<v-skeleton-loader v-if="!site" type="list-item-three-line" />
			<div v-else>
				<p class="text-body-1 mb-4">
					If you think you are missing a completion, click on the 'Report a missing completion' button and our team will take a look at this for you.
					<b>You will need a copy of the offer and completions letters.</b>
				</p>
				<v-btn color="primary" :href="`mailto:${site.contact.email}?subject=Missing completion&body=** PLEASE ATTACH COPIES OF THE OFFER AND COMPLETIONS LETTERS **`">
					Report a missing completion
				</v-btn>
			</div>
		</template>
	</common-structure-section>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-reward-report',

		components: { CommonStructureSection },

		computed: {
			...mapState('CmsSite', ['site'])
		}
	};
</script>
