import store from '@/store';

/**
 * @class @name ActivityDataMapper
 * @description Create a display ready array of activity items
 */

export class ActivityDataMapper {
	constructor() {
		this.options = {};
		this.workflowSections = store.getters['Account/workflowTemplateItems'];
	}

	/**
	 * @name mapData
	 * @param {array} logs
	 * @param {object} options
	 */
	mapData(logs, options) {
		Object.assign(this.options, options);
		//Remove logs for which we do not have a mapping function
		const validLogs = logs.filter((log) => typeof this[log.type] === 'function');
		return validLogs.map((log) => {
			this[log.type](log);
			return log;
		});
	}

	/**
	 * @name client_note
	 * @param {object} log
	 */
	client_note(log) {
		log.icon = log?.data?.status === 'deleted' ? 'trash.png' : 'note.png';

		//Actions
		log.actions = [];
		if (log?.data?.client?.[0]?.id && 'showAccessClient' in this.options && this.options.showAccessClient === true)
			log.actions.push({
				title: 'Access Client',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true, // used in latest updates section to find action with path
				path: `/client/${log?.data?.client?.[0]?.id}`
			});
		if (!log?.deleted && log?.data?.status !== 'deleted' && 'canDeleteNotes' in this.options && this.options.canDeleteNotes === true)
			log.actions.push({
				title: 'Delete Note',
				icon: {
					style: 'mdi-delete',
					colour: 'error'
				},
				button: {
					colour: 'red darken-3'
				},
				function: {
					name: 'deleteClientNote',
					params: {
						id: log.id
					}
				}
			});
		if (!log.actions.length) delete log.actions;

		//Redact note text?
		if (log.deleted) log.data.redacted = true;
		if (log.data.status === 'deleted') log.data.redacted = true;

		return log;
	}

	/**
	 * @name client
	 * @param {object} log
	 */
	client(log) {
		log.icon = log?.data?.client?.[0]?.gender === 'female' ? 'client2.png' : 'client1.png';

		//Actions
		log.actions = [];
		if (log?.data?.client?.[0]?.id && 'showAccessClient' in this.options && this.options.showAccessClient === true)
			log.actions.push({
				title: 'Access Client',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/client/${log?.data?.client?.[0]?.id}`
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name case_tracking_event
	 * @param {object} log
	 */
	case_tracking_event(log) {
		log.icon = log?.title.toLowerCase().indexOf('incomplete') == -1 ? 'success.png' : 'incomplete.png';

		//Actions
		log.actions = [];
		if (log?.data?.client) {
			let pathUrl = `/client/${log?.data?.client?.[0]?.id}`;
			const matterTypeId = log?.data?.matterType?.id;

			switch (matterTypeId) {
				case '333d123d-f59f-4c2c-890f-3f5333c15aa7': //Care Plans
					pathUrl += '?service=carePlans';
					break;
				case 'e0ab9c38-fddd-4e42-85e6-c3c1ec2a6ffb': //Home Reversion Plan
					pathUrl += '?service=homeReversionPlan';
					break;
				default:
					pathUrl += `?workflowSection=${this.workflowSections.find((section) => section.data.matterTypeId === matterTypeId)?.id}`;
					break;
			}

			log.actions.push({
				title: 'Access Details',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				path: pathUrl
			});
		}
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name case_status_change
	 * @param {object} log
	 */
	case_status_change(log) {
		log.icon = 'bell.png';
		if (log?.title.toLowerCase().indexOf('completed') != -1) log.icon = 'success.png';
		else if (log?.title.toLowerCase().indexOf('suspended') != -1) log.icon = 'pause.png';
		else if (log?.title.toLowerCase().indexOf('accepted') != -1) log.icon = 'play.png';
		else if (log?.title.toLowerCase().indexOf('declined') != -1) log.icon = 'error.png';
		else if (log?.title.toLowerCase().indexOf('submitted') != -1) log.icon = 'submitted.png';

		//Actions
		log.actions = [];
		if (log?.data?.client?.[0]) {
			let pathUrl = `/client/${log?.data?.client?.[0]?.id}`;
			const matterTypeId = log?.data?.matterType?.id;

			switch (matterTypeId) {
				case '333d123d-f59f-4c2c-890f-3f5333c15aa7': //Care Plans
					pathUrl += '?service=carePlans';
					break;
				case 'e0ab9c38-fddd-4e42-85e6-c3c1ec2a6ffb': //Home Reversion Plan
					pathUrl += '?service=homeReversionPlan';
					break;
				default:
					pathUrl += `?workflowSection=${this.workflowSections.find((section) => section.data.matterTypeId === matterTypeId)?.id}`;
					break;
			}

			log.actions.push({
				title: 'Access Details',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: pathUrl
			});
		}
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		let client = log?.data?.client?.[0]?.name || null;
		if (client && log?.data?.client?.[1]?.name) client += ` and ${log?.data?.client?.[1]?.name}`;
		if (client) log.chips.push(client);
		if (!log.chips.length) delete log.chips;
		if (log?.data?.matterType?.name) log.description = `${log?.data?.matterType?.name}`;
		return log;
	}

	/**
	 * @name case_status_change_kfi
	 * @param {object} log
	 */
	case_status_change_kfi(log) {
		log.icon = 'bell.png';
		if (log?.title.toLowerCase().indexOf('application submitted') != -1) log.icon = 'application_submitted.png';
		else if (log?.title.toLowerCase().indexOf('received') != -1) log.icon = 'incoming.png';
		//kfi
		else if (log?.title.toLowerCase().indexOf('requested') != -1) log.icon = 'air_submitted.png';
		//kfi
		else if (log?.title.toLowerCase().indexOf('submitted') != -1) log.icon = 'submitted.png'; //kfi

		//Actions
		log.actions = [];
		if (log?.data?.client?.[0]?.id && log?.data?.property)
			log.actions.push({
				title: 'Access Details',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/client/${log?.data?.client?.[0]?.id}?workflowSection=${this.workflowSections.find((section) => section.nameUnique === 'kfi')?.id}`
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		let client = log?.data?.client?.[0]?.name || null;
		if (client && log?.data?.client?.[1]?.name) client += ` and ${log?.data?.client?.[1]?.name}`;
		if (client) log.chips.push(client);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name reward
	 * @param {object} log
	 */
	reward(log) {
		log.icon = 'diamond.png';

		//Actions
		log.actions = [
			{
				title: 'View Rewards',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: '/rewards'
			}
		];

		//Chips
		log.chips = [];
		if (log?.data?.reward?.name) log.chips.push(log?.data?.reward?.name);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name reward_deduct
	 * @param {object} log
	 */
	reward_deduct(log) {
		log.icon = 'gift.png';

		//Actions
		log.actions = [
			{
				title: 'View Rewards',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: '/rewards'
			}
		];

		return log;
	}

	/**
	 * @name _enquiry
	 * @param {object} log
	 */
	_enquiry(log) {
		//Actions
		log.actions = [];
		if (log?.data?.matter)
			log.actions.push({
				title: 'Go To Enquiry',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/enquiry/${log?.data?.matter.id}`
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
		if (log?.data?.property?.address) log.chips.push(log?.data?.property?.address);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name user_enquiry_update
	 * @param {object} log
	 */
	user_enquiry_update(log) {
		log = this._enquiry(log);
		log.icon = 'update.png'; //update to enquiry
		if (log?.title.toLowerCase().indexOf('sent to') != -1) log.icon = 'edit.png'; //sent by adviser
		if (log?.title.toLowerCase().indexOf('received from') != -1) log.icon = 'fax.png'; //received by adviser
		return log;
	}

	/**
	 * @name user_enquiry_submitted
	 * @param {object} log
	 */
	user_enquiry_submitted(log) {
		log = this._enquiry(log);
		log.icon = 'enquiry_submitted.png';
		return log;
	}

	/**
	 * @name user_enquiry_status
	 * @param {object} log
	 */
	user_enquiry_status(log) {
		log = this._enquiry(log);
		log.icon = 'repeat.png'; //reopened
		if (log?.title.toLowerCase().indexOf('resolved') != -1) log.icon = 'success.png'; //resolved
		return log;
	}

	/**
	 * @name user_enquiry_provider
	 * @param {object} log
	 */
	user_enquiry_provider(log) {
		log = this._enquiry(log);
		log.icon = 'submitted.png';
		return log;
	}

	/**
	 * @name user_membership_status
	 * @param {object} log
	 */
	user_membership_status(log) {
		//TODO - test UI
		log.icon = 'diamond.png';
		return log;
	}

	/**
	 * @name sourcing_session
	 * @param {object} log
	 */
	sourcing_session(log) {
		log.icon = 'submitted.png';

		//Actions
		log.actions = [];
		if (log?.data?.process?.id)
			log.actions.push({
				title: 'Go To Sourcing',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/source/${log?.data?.process.id}`
				//TODO - is this correct? should it be the matter?
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name && (!this.options?.chips?.client || this.options.chips.client !== 'hide')) log.chips.push(log?.data?.client?.[0]?.name);
		if (log?.data?.property?.address) log.chips.push(log?.data?.property?.address);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name kfi_request_failed
	 * @param {object} log
	 */
	kfi_request_failed(log) {
		//TODO - test UI
		log.icon = 'error.png';

		//Actions
		log.actions = [];
		if (log?.data?.client?.[0]?.id && log?.data?.property)
			log.actions.push({
				title: 'Access Details',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/client/${log?.data?.client?.[0]?.id}?workflowSection=${this.workflowSections.find((section) => section.nameUnique === 'kfi')?.id}`
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
		if (log?.data?.property?.address) log.chips.push(log?.data?.property?.address);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/**
	 * @name quick_quote_session
	 * @param {object} log
	 */
	quick_quote_session(log) {
		log.icon = 'stopwatch.png';
		return log;
	}

	/**
	 * @name state_benefit
	 * @param {object} log
	 */
	state_benefit(log) {
		log.icon = 'list.png';
		log.title = 'State Benefits Report Received';
		if (!log.description) log.description = 'A new state benefits report is available to download from the client dashboard.';

		//Actions
		log.actions = [];
		/*if(log?.data?.client?.[0]?.id) log.actions.push({
            title: 'Access Client',
            icon: {
                style: 'mdi-eye',
                colour: 'blue'
            },
            button: {
                colour: 'primary'
            },
            path: `/client/${log?.data?.client?.[0]?.id}`
        });*/
		if (log?.data?.client?.[0]?.id && 'showAccessClient' in this.options && this.options.showAccessClient === true)
			log.actions.push({
				title: 'Access Client',
				icon: {
					style: 'mdi-eye',
					colour: 'blue'
				},
				button: {
					colour: 'primary'
				},
				destinationAction: true,
				path: `/client/${log?.data?.client?.[0]?.id}?workflowSection=${this.workflowSections.find((section) => section.nameUnique === 'state_benefits')?.id}`
			});
		if (!log.actions.length) delete log.actions;

		//Chips
		log.chips = [];
		if (log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
		if (log?.data?.property?.address) log.chips.push(log?.data?.property?.address);
		if (!log.chips.length) delete log.chips;

		return log;
	}

	/*product_issue(log)
    {
        log.icon = 'enquiry_submitted.png';

        //Actions
        log.actions = [];
        if(log?.data?.enquiry) log.actions.push({
            title: 'Go To Enquiry',
            icon: {
                style: 'mdi-eye',
                colour: 'blue'
            },
            button: {
                colour: 'primary'
            },
            path: '#'
        });
        if(!log.actions.length) delete log.actions;

        //Chips
        log.chips = [];
        if(log?.data?.client?.[0]?.name) log.chips.push(log?.data?.client?.[0]?.name);
        if(log?.data?.property?.address) log.chips.push(log?.data?.property?.address);
        if(!log.chips.length) delete log.chips;

        return log;
    }*/
}
