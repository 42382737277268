<!--
	@name app-source-result-dialog-filter-confirm-close
	@description Confirm close filters
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog">
		<template #header>
			Session Archive
		</template>

		<template #body>
			<v-card elevation="0">
				<v-skeleton-loader v-if="loading" type="list-item-three-line" />
				<template v-else-if="validArchivedSessions.length">
					<p class="text-body-2 mb-5">
						See below for an archived list of activity for this sourcing session.
						Click any date/time to open product results exactly as they first appeared.
						Print specific research PDFs, or move back and forth through multiple timestamps to review how filter changes are affecting product results.
					</p>
					<v-list two-line class="ma-n3 pt-1 pb-0">
						<v-card
							v-for="iteration in validArchivedSessions"
							:key="iteration.id"
							:hover="activeIteration(iteration) ? false : true"
							class="ma-3"
							:class="{ 'my-5 mx-5':validArchivedSessions.length > 1 && activeIteration(iteration) }"
							color="grey lighten-2"
							@click="activeIteration(iteration) ? null : goToSession(iteration)"
						>
							<v-system-bar height="16" :class="getClass(iteration)" />
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="mb-1 grey--text text--darken-4 font-weight-medium text-body-1">
										<v-icon size="18" color="grey darken-4" class="mr-2" style="position: relative; top: -1px">
											mdi-archive-clock
										</v-icon>{{ iteration.created | moment('MMM Do YYYY, HH:mm')
										}}<span class="caption grey--text text--darken-3" style="position: relative; left: 2px">{{ iteration.created | moment('ss') }}</span>
									</v-list-item-title>
									<v-list-item-subtitle class="text-caption grey--text text--darken-2 mb-2">
										<strong> Ref:</strong> {{ iteration.id }}
									</v-list-item-subtitle>
									<v-list-item-subtitle class="text-body-2 grey--text text--darken-3">
										<span class="font-weight-regular d-inline-block">
											<span v-if="iteration.data.filters.showMaximumAvailable"> <strong> Advance:</strong> Maximum Available </span>
											<span v-else><strong> Advance:</strong> £{{ iteration.data.filters.initialAdvance | numFormat('0,0') }}</span>
										</span>
										<span class="font-weight-regular d-inline-block ml-3"><strong>Drawdown:</strong> £{{ iteration.data.filters.drawdownFacility | numFormat('0,0') }}</span>
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action class="align-self-start grey--text text--darken-4">
									<div v-if="iteration.diff && iteration.diff.items.length" class="text-right">
										<p class="text-body-2 font-weight-bold mb-1">
											{{ iteration.diff.heading }} ({{ iteration.diff.items.length }})
										</p>
										<p v-for="item in iteration.diff.items.slice(0, 2)" :key="item" class="caption mb-0">
											{{ item }}
										</p>
										<p v-if="iteration.diff.items.length > 2" class="caption font-italic mb-0">
											and {{ iteration.diff.items.length - 2 }} more
										</p>
									</div>
									<div v-else class="text-right">
										<p class="text-body-2 mb-0 grey--text text--darken-2">
											No change
										</p>
									</div>
								</v-list-item-action>
							</v-list-item>
						</v-card>
					</v-list>
				</template>
				<p v-else class="text-body-1 mb-0">
					Session history is only available for sessions created on or after 15th May 2023.
				</p>
			</v-card>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { orderBy, isEqual, startCase } from 'lodash';
	import CommonDialog from '@/component/common/dialog';
	import criteriaList from '@/component/common/dialog/criteria.json';

	export default {
		name: 'common-dialog-sourcing-session-history',

		components: { CommonDialog },

		props: {
			external: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				criteriaList: criteriaList,
				loading: false,
				iterationList: []
			};
		},

		computed: {
			...mapState('Account', ['organisation']),
			iterationId() {
				return this.$route.query.iteration;
			},

			/**
			 * @name validArchivedSessions
			 * @description Sessions can only be viewed in archive if they were not imported
			 */
			validArchivedSessions() {
				let valid = this.iterationList.filter((session) => !session.data.imported);
				let earliest = orderBy(valid, ['created'], ['asc']);
				let prev = null;
				let mapped = earliest.map((x) => {
					let diff = this.getDiff(x, prev);
					prev = x;
					return { ...x, diff };
				});
				return orderBy(mapped, ['created'], ['desc']);
			}
		},

		methods: {
			...mapActions('AppSourceIteration', ['getIterationList']),
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open(processId) {
				this.$refs.dialog.open();
				this.loading = true;
				this.getIterationList({ where: { 'iteration.process_id': processId } }).then(({ data }) => {
					this.iterationList = data.data;
					this.loading = false;
				});
			},

			activeIteration(iteration) {
				return iteration.id === this.iterationId;
			},

			getClass(iteration) {
				return this.activeIteration(iteration) ? 'background-rotation-manual--2' : 'primary';
			},

			getDiff(iteration, previousIteration) {
				if (previousIteration) {
					let isFilterEqual = isEqual(iteration.data.filters, previousIteration.data.filters);

					if (!isFilterEqual) {
						let diff = Object.keys(iteration.data.filters).reduce((result, key) => {
							if (!isEqual(iteration.data.filters[key], previousIteration.data.filters[key])) {
								result[key] = iteration.data.filters[key];
							}
							return result;
						}, {});

						diff = Object.keys(diff).map((key) => startCase(key));
						return { heading: 'Filter change', items: diff };
					}

					let isSortEqual = isEqual(iteration.data.sortConfig, previousIteration.data.sortConfig);

					if (!isSortEqual) {
						let diff = iteration.data.sortConfig.filter((x) => !previousIteration.data.sortConfig.includes(x));
						diff = diff.map((d) => `${startCase(d.key)} - ${startCase(d.direction)}`);
						return { heading: 'Order change', items: diff };
					}

					let isPanelEqual = isEqual(iteration.data.attributes?.panel, previousIteration.data.attributes?.panel);

					if (!isPanelEqual) {
						return { heading: 'Panel change', items: [iteration.data.attributes?.panel?.name ?? 'Off'] }; // Show panel change
					}

					if(this.organisation?.organisationTradingStyles?.length) {
						let isTradingStyleEqual = isEqual(iteration.data.attributes?.tradingStyle, previousIteration.data.attributes?.tradingStyle);

						if (!isTradingStyleEqual && iteration.data.attributes.tradingStyle?.name) {
							return { heading: 'Trading Style change', items: [iteration.data.attributes?.tradingStyle?.name] }; // Show trading style change
						}
					}

					let isCriteriaEqual = isEqual(iteration.data.selectedCriteria, previousIteration.data.selectedCriteria);
					if (!isCriteriaEqual) {
						return {
							heading: 'Criteria change',
							items: this.criteriaList.filter((item) => iteration.data.selectedCriteria.includes(item.Criteria_Category_ID)).map(item => item.Criteria_Category_Title)
						};
					}
				}
			},

			async goToSession(iteration) {
				if (this.external) this.$router.push({ name: 'result', params: { processId: iteration.processId }, query: { iteration: iteration.id, view: 'archive' } });
				else this.$emit('go-to-session', iteration);
				this.$refs.dialog.close();
			}
		}
	};
</script>
