<!--
	@name app
	@description Main app, thats bootstrapped to html
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<router-view />

		<!-- Dialogs -->
		<app-dialog-notification-centre ref="notificationCentre" />
		<app-dialog-rewards-awarded ref="rewardsAwarded" />
		<app-common-terms ref="terms" />
		<app-common-privacy ref="privacy" />
		<app-common-slavery-statement ref="slaveryStatement" />
		<common-dialog ref="importedIterationErrorDialog" :color="'warning'">
			<template #header>
				Document not found
			</template>
			<template #body>
				<p class="text-body-1">
					This session was created prior to the latest data migration and can no longer be downloaded directly from the system, Please click below to submit a document
					request to our support team.
				</p>
				<v-btn color="primary" :href="`mailto:${site?.contact?.email}?subject=Document Request - #ResearchPDF/${iterationId}`">
					Submit Request
				</v-btn>
			</template>
		</common-dialog>
		<common-dialog ref="blockedPopupDialog" :color="'warning'">
			<template #header>
				Popup blocked
			</template>
			<template #body>
				<p class="text-body-1">
					Your browser has prevented a popup window from being opened automatically. Please click the button below to open it.
				</p>
				<v-btn color="primary" :href="blockedPopupConfig?.url" :target="blockedPopupConfig?.target" @click="$refs?.blockedPopupDialog?.close()">
					Open Popup
				</v-btn>
			</template>
		</common-dialog>
		<common-dialog-on-air-article ref="onAirArticle" />

		<common-dialog ref="authoriseDialog" :component="$options.name" eager>
			<template #body>
				<template v-if="authoriseDialogDisplay === 1">
					<p class="text-body-2 mb-5">
						Hint: if you are unsure of your Air Academy Learning Platform password, reset this below for instant access.
					</p>
					<v-item-group>
						<v-row>
							<v-col cols="12" sm="6" class="py-0">
								<v-item>
									<v-card class="text-h6 font-weight-regular pa-10 text-center grey lighten-2" height="100%" @click="authoriseDialogDisplay = 2">
										I <strong class="error">DO NOT</strong> know my <strong>Air Academy Learning Platform</strong> Password
									</v-card>
								</v-item>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-item>
									<v-card class="text-h6 font-weight-regular pa-10 text-center grey lighten-2" height="100%" @click="authoriseDialogDisplay = 3">
										I <strong class="success">DO</strong> know my <strong>Air Academy Learning Platform</strong> Password
									</v-card>
								</v-item>
							</v-col>
						</v-row>
					</v-item-group>
				</template>
				<template v-else-if="authoriseDialogDisplay === 2">
					<a class="d-block mb-4" @click="authoriseDialogDisplay = 1">
						<v-icon size="16" color="primary" style="position: relative; top: -1px"> mdi-chevron-double-left </v-icon> Back
					</a>
					<p class="text-body-2 mb-0">
						Use the form below to change your Air Academy Learning Platform password. Please note: this action will result in your password being updated with immediate
						effect. Your updated password can then be used when connecting your Air Academy account AND signing in directly to https://academy.airlaterlife.co.uk/learn.
					</p>
					<v-alert v-if="authoriseDialogError" dense prominent type="error" class="text-body-2 mt-5 mb-0">
						An unexpected error occurred whilst attempting to update your Air Academy Learning Platform password. Please speak with our support team to resolve this
						issue.
					</v-alert>
					<dynamic-form
						class="mt-3"
						:form-schema="formSchema"
						:form-data="formData"
						:submit-button-loading="loading"
						submit-button-text="Change Password"
						:submit-button-block="false"
						:submit-button-large="false"
						@dynamic-form-submit="submitPasswordChangeRequest"
					/>
				</template>
				<template v-else-if="authoriseDialogDisplay === 3">
					<a class="d-block mb-4" @click="authoriseDialogDisplay = 1">
						<v-icon size="16" color="primary" style="position: relative; top: -1px"> mdi-chevron-double-left </v-icon> Back
					</a>
					<p class="text-body-2 mb-4">
						To connect your account, first click the "grant access" button. A popup window will appear asking you to enter your Air Academy Learning Platform username
						and password. Enter your details and click "authorise". A loading screen will appear briefly, after which the popup window will close and your accout will
						be connected.
					</p>
					<app-account-settings-credentials v-if="!isAdmin" slim :lenders="['air_academy_learning_platform']" @credentials-updated="credentialsUpdated" />
				</template>
			</template>
		</common-dialog>

		<common-dialog-video ref="video" />
	</div>
</template>

<script>
	import { EventBus, ElementTools } from '@/utils';
	import { mapActions, mapState, mapGetters } from 'vuex';
	import AppDialogNotificationCentre from '@/component/app/dialog/notification-centre';
	import AppDialogRewardsAwarded from '@/component/app/dialog/rewards-awarded';
	import AppCommonTerms from '@/component/app/common/terms';
	import AppCommonPrivacy from '@/component/app/common/privacy';
	import AppCommonSlaveryStatement from '@/component/app/common/slavery-statement';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogOnAirArticle from '@/component/common/dialog/on-air-article';
	import CommonDialogVideo from '@/component/common/dialog/video';
	import AppAccountSettingsCredentials from '@/component/app/account-settings/credentials';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app',

		components: {
			AppDialogNotificationCentre,
			AppDialogRewardsAwarded,
			AppCommonTerms,
			AppCommonPrivacy,
			AppCommonSlaveryStatement,
			CommonDialog,
			CommonDialogOnAirArticle,
			CommonDialogVideo,
			AppAccountSettingsCredentials,
			DynamicForm
		},

		data() {
			return {
				iterationId: null,
				blockedPopupConfig: null,
				authoriseDialogDisplay: 1,
				authoriseDialogError: false,
				formData: {},
				formSchema: [
					{
						type: 'passwordStrength',
						password: false,
						name: 'password',
						ref: 'new_password',
						display: 'New Password',
						rules: 'required|verify_password',
						dense: true,
						sm: 12
					},
					{
						type: 'text',
						autocomplete: 'off',
						name: 'confirmPassword',
						display: 'Confirm New Password',
						rules: 'required|confirmed:new_password',
						dense: true,
						sm: 12
					}
				],
				loading: false
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['isAdmin'])
		},

		mounted() {
			EventBus.$on('open-chat', this.openChat);
			EventBus.$on('open-terms', this.openTerms);
			EventBus.$on('open-privacy', this.openPrivacy);
			EventBus.$on('open-slavery-statement', this.openSlaveryStatement);
			EventBus.$on('rewards-awarded', this.openRewardsAwarded);
			EventBus.$on('open-notification-centre', this.openNotificationCentre);
			EventBus.$on('imported-iteration', this.importedIterationError);
			EventBus.$on('popup-blocked', this.openPopupBlockedDialog);
			EventBus.$on('open-on-air-article', this.openOnAirArticle);
			EventBus.$on('open-video', this.openVideo);
			EventBus.$on('open-authorise-dialog', this.openAuthoriseDialog);
			EventBus.$on('handle-dynamic-click', this.handleDynamicClick);
		},

		methods: {
			...mapActions('app', ['openChat']),
			...mapActions('AppWebinar', ['updateUser']),

			/**
			 * @name openRewardsAwarded
			 * @description Opens rewards awarded dialog
			 * @param {Object} config Popup config
			 */
			openRewardsAwarded(config) {
				// NOTE: paul, should we be doing this here?
				this.$refs.rewardsAwarded.open(config);
			},

			/**
			 * @name openChat
			 * @description Open the chat
			 */
			openChat() {
				if (typeof window.zE === 'undefined') return false;
				window.zE('webWidget', 'open');
			},

			openTerms() {
				this.$refs.terms?.open();
			},

			openPrivacy() {
				this.$refs.privacy?.open();
			},

			openSlaveryStatement() {
				this.$refs.slaveryStatement?.open();
			},

			openNotificationCentre() {
				this.$refs.notificationCentre?.open();
			},

			importedIterationError(iterationId) {
				this.iterationId = iterationId;
				this.$refs.importedIterationErrorDialog?.open();
			},

			openPopupBlockedDialog({ url, target }) {
				this.blockedPopupConfig = { url, target };
				this.$refs.blockedPopupDialog?.open();
			},

			openOnAirArticle(id) {
				this.$refs.onAirArticle?.init(id);
			},

			openVideo(data) {
				this.$refs.video?.init(data);
			},

			openAuthoriseDialog() {
				this.authoriseDialogDisplay = 1;
				this.$refs.authoriseDialog?.open();
			},

			credentialsUpdated() {
				this.$refs.authoriseDialog.close();
				EventBus.$emit('credentials-updated');
			},

			submitPasswordChangeRequest() {
				this.authoriseDialogError = false;
				this.loading = true;
				let payload = this.formData;
				payload.email = this.user.email;
				this.updateUser({ id: this.user.id, payload })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', {
							text: 'Your Air Academy Learning Platform password has been updated',
							timeout: 3000,
							close: true
						});
						this.authoriseDialogDisplay = 3;
					})
					.catch(() => {
						this.authoriseDialogError = true;
					})
					.then(() => {
						this.loading = false;
					});
			},

			/**
			 * @name handleDynamicClick
			 * @description Handle clicks on dynamically created alert boxes
			 * @param {Object} e
			 */
			handleDynamicClick(e) {
				if (e.target.matches('.open-chat, .open-chat *')) this.openChat();
				else if (e.target.matches('.open-terms, .open-terms *')) this.openTerms();
				else if (e.target.matches('.open-privacy, .open-privacy *')) this.openPrivacy();
				else if (e.target.matches('.router--link, .router--link *')) this.routerLink(e);
			},

			routerLink(e) {
				if (e.target.getAttribute('data-route')) this.$router.push(e.target.getAttribute('data-route'));
			}
		}
	};
</script>
