import { OauthAuthorize } from '@/service';

/**
 * @class @name Oauth
 * @description Exposes Oauth store module
 */
export default class Oauth {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name country
			 * @param {Object} context
			 * @param {Object} partner & providerNameUnique
			 */
			async getOauthOptions(context, { partner, providerNameUnique, domain }) {
				return OauthAuthorize.get(partner, providerNameUnique, domain);
			},

			/**
			 * @async @name checkGateway
			 * @param {Object} context
			 * @param {Object} providerNameUnique, payload
			 */
			async checkGateway(context, { partner, payload }) {
				return OauthAuthorize.gateway(partner, payload);
			},

			/**
			 * @async @name gsubmitOauthender
			 * @param {Object} context
			 * @param {Object} providerNameUnique, payload
			 */
			async submitOauth(context, { partner, payload }) {
				return OauthAuthorize.post(partner, payload);
			}
		};
	}
}
