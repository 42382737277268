<!--
	@name app-common-privacy
	@description Privacy Notice
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Privacy Notice
		</template>
		<template v-if="site" #body>
			<div>
				<p><strong>THEO TOPCO LIMITED MODERN SLAVERY STATEMENT FOR THE FINANCIAL YEAR ENDED 31ST DECEMBER 2021</strong></p>
				<p><b>1. Introduction</b></p>
				<p>
					This statement is made pursuant to section 54(1) of the Modern Slavery Act 2015 and constitutes Theo Topco Limited and its subsidiaries (“Key Group”) slavery
					and human trafficking statement for the financial year ending 31 December 2021.
				</p>
				<p>
					Whilst we consider the risk of the existence of slavery and human trafficking within our business or supply chains to be very low, we are committed to
					combatting modern slavery in all its forms. We take a zero-tolerance approach to modern slavery.
				</p>
				<p><b>2. Our Business</b></p>
				<p>
					Key Group is a leading financial services group providing products and services for those in or approaching retirement including equity release, later life
					lending and estate planning.
				</p>
				<p>Key Group is made up of several trading divisions including:</p>
				<p>
					Key (Key Retirement Solutions Ltd) – whose primary business is the provision of equity release advice but who also has business lines in mainstream mortgage
					advice and estate planning.
				</p>
				<p>The Equity Release Experts – who provide whole of market equity release advice.</p>
				<p>Mortgage Advice Bureau Later Life – who provide equity release advice offering products from a restricted panel of lenders.</p>
				<p>more 2 life – a lifetime mortgage lender accepting business via intermediaries only, whose products are funded by third party funders.</p>
				<p>Standard Life Home Finance – a lifetime mortgage lender accepting business via intermediaries only, whose products are funded by a third-party funder.</p>
				<p>
					AIR Group – offer a range of services which include a sourcing system for equity release and retirement lending products to help equity release advisers support
					their customers and their business.
				</p>
				<p>
					As a Group we aim to employ the highest ethical and professional standards and always to comply with all local laws and regulations applicable to our business;
					it follows that Key Group is absolutely committed to preventing slavery and human trafficking in its corporate activities and supply chains.
				</p>
				<p>
					A risk assessment for the potential of slavery and human trafficking within our business and supply chains is evaluated by the nature of our business and our
					approach to governance, we therefore consider the risk is very low. However we aim to periodically review the effectiveness of the relevant policies and
					procedures that we have in place.
				</p>
				<p><b>3. Our Policies</b></p>
				<p>
					Key Group has policies and processes in place to mitigate the risk of slavery and human trafficking issues arising within its business and supply chains,
					including:
				</p>
				<p>· Our Corporate Social Responsibility Policy reflects Key Group's commitment to acting ethically and with integrity in all our business relationships.</p>
				<p>
					· Our Grievance Policy, Anti-Harassment &amp; Bullying Policy and our Whistleblowing Policy which is aimed at giving our colleagues a platform to raise concerns
					of any wrongdoing or poor working practices. This extends to human rights violations, including modern slavery. All such reports will be fully investigated and
					appropriate remedial action taken.
				</p>
				<p>
					· Our recruitment policies involve conducting appropriate checks on all new joiners, and mandates that no individuals are paid below the minimum wage, either
					within the Group itself or across our suppliers.
				</p>
				<p>· We conduct appropriate initial and ongoing due diligence checks over our Suppliers and this approach is outlined further in section 4.</p>
				<p><b>4. Our Supply Chains</b></p>
				<p>We source goods and services from a variety of suppliers including:</p>
				<p>· IT – software and hardware</p>
				<p>· Telephony</p>
				<p>· Office accommodation and supplies</p>
				<p>· Professional services such as marketing, legal etc.</p>
				<p>· External funding partners</p>
				<p>
					As part of risk management approach we conduct due diligence on potential suppliers, impose obligations on those suppliers in relation to (among other things)
					compliance with law, and take reasonable steps to monitor their compliance with those obligations.
				</p>
				<p>
					Our Facilities team only select contractors/companies affiliated with/accredited by trading bodies such as the Contractors Health and Safety Scheme (CHAS), and
					such suppliers must complete a Supplier Audit Evidence document in which we request amongst other policies, a copy Modern Slavery statement where available.
				</p>
				<p>We conduct all business with integrity and are committed to acting professionally and fairly in all our business dealings and relationships.</p>
				<p>
					Our procurement activities take place in the UK and our contractors and suppliers are predominantly UK based, although a minority are based in the EU and India.
					Some of our suppliers subcontract work or rely on recruitment agencies to supply permanent or temporary staff. Whilst we consider that the risk is very low, we
					recognise that no supply chain can be considered entirely risk free.
				</p>
				<p><b style="font-size: 1rem; background-color: rgb(255 255 255 / var(--tw-bg-opacity)); color: rgb(0 0 0 / var(--tw-text-opacity))">5. Our Progress</b></p>
				<p>
					We believe that there is a low risk of slavery and human trafficking within our operations. Any potential risk is mitigated as a result of our policies and
					procedures, the controls built into our business operations and the knowledge, skills and behaviours of our colleagues. These are clear
				</p>
				<p>
					performance indicators by which we can measure our effectiveness. In addition, we have processes in place to encourage the reporting of concerns and protect
					whistle-blowers.
				</p>
				<p>In 2021 we:</p>
				<p>
					· Continued to raise a general awareness of the topic amongst our colleagues via the Key Group Annual Training, encouraging colleagues to report concerns and
					providing assurance that such concerns will be acted upon.
				</p>
				<p>
					· Continued to engage with our key suppliers to verify that they have taken appropriate steps to ensure there is no slavery or human trafficking in their
					business or supply chains.
				</p>
				<p>· Did not receive any reports of incidents or allegations of slavery or human trafficking within our business or supply chains.</p>
				<p>· Signed up to the Government Modern Slavery Statement registry and submitted the annual statement for 2020.</p>
				<p>In 2022 we will continue to reinforce this message throughout Key Group. In addition we will:</p>
				<p>
					· Evolve our Corporate Social Responsibility Policy as part of our wider Environmental, Social and Governance commitments, including the development of a new
					Responsible Supply Chain Policy; and
				</p>
				<p>· Undertake a review of our Supplier and Outsourcing Framework to identify opportunities to embed further best practice approaches for the Group.</p>
				<p>We will continue to build on this progress over future years.</p>
				<p>This statement is approved by the Key Group Audit, Risk &amp; Compliance Committee and will be reviewed at least annually.&nbsp;</p>
				<p>
					<b>Simon Thompson<br />
						Key Group CEO</b>
				</p>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-privacy',

		components: { CommonDialog },

		data: () => ({
			website_name: `www.${window.location.hostname}`
		}),

		computed: { ...mapState('CmsSite', ['site']) },

		watch: {},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	table {
		border: 1px solid #888;
		width: 100%;
		td {
			border: 1px solid #888;
			padding: 8px;
		}
	}
</style>
