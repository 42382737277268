<!--
	@name app-summer-campaigns-libf
	@description LIBF campaign section
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div class="d-flex align-center flex-column mb-12">
			<span>
				<p class="text-h5 font-weight-bold mb-3">Welcome to Air Academy: Your Gateway to Expert Education in Later Life Lending</p>
				<p class="text-body-1 mb-6">We are excited to announce a transformative partnership between Air, the premier Later Life lending platform, and the LIBF (London Institute of Banking & Finance). This collaboration represents a significant leap forward in our dedication to enhancing education within the Later Life lending sector.</p>
				<v-divider inset class="mb-5" />
				<v-row>
					<v-col v-for="(video, index) in videos" :key="index" cols="12" lg="4">
						<div class="text-body-2 font-weight-medium text-center px-lg-5 d-none">
							{{ video.copy }}
						</div>

						<div class="mt-0" style="position: relative">
							<div v-if="$vuetify.breakpoint.lgAndUp" class="rounded">
								<img :src="video.poster" class="rounded d-block" style="width: 100%" />
								<v-btn icon class="ga--trigger ga--campaign-click ga--video-play play-icon mt-n1" @click="playVideo(video)">
									<v-icon size="100" color="black lighten-2">
										mdi-play-circle
									</v-icon>
								</v-btn>
							</div>

							<video v-else controls :src="video.src" :poster="video.poster | urlize" width="100%" height="auto" />
						</div>
					</v-col>
				</v-row>
				<v-divider inset class="my-5" />
				<p class="text-h6 font-weight-bold mb-2">Why This Partnership Matters</p>
				<p class="text-body-1 mb-6">Navigating the complexities of later life lending requires a deep understanding and a commitment to achieving the best outcomes for our customers. As the landscape of advising and guiding older consumers evolves, the need for top-tier expertise becomes increasingly critical. That's where Air Academy steps in.</p>
				<p class="text-h6 font-weight-bold mb-2">Elevate Your Expertise with Air Academy</p>
				<p class="text-body-1 mb-6">Through our exclusive partnership with LIBF, we offer you unparalleled access to state-of-the-art technology and essential educational resources. Air Academy is designed to provide a benchmark of excellence in the industry, ensuring you have the knowledge and tools necessary to excel in your profession.</p>
				<p class="text-h6 font-weight-bold mb-2">What You Gain:</p>
				<ul class="text-body-1 mb-6">
					<li>
						<span class="font-weight-bold">Cutting-Edge Technology:</span> Access Air's innovative technology platform that streamlines and enhances your advisory process.
					</li>
					<li>
						<span class="font-weight-bold">Comprehensive Educational Resources:</span> Benefit from a selection of complimentary courses and materials from Air's industry-leading Academy.
					</li>
					<li>
						<span class="font-weight-bold">Expert Insights:</span> Stay ahead with the latest trends and insights in later life lending, ensuring you provide the best possible advice to your clients.
					</li>
				</ul>
				<p class="text-h6 font-weight-bold mb-2">Discover More</p>
				<p class="text-body-1">To learn more about how Air Academy can transform your professional capabilities and to explore the full range of resources available through this groundbreaking partnership, get started today
					<a class="ga--trigger ga--campaign-click ga--register" @click="$router.push('/academy-register')">register</a> /
					<a class="ga--trigger ga--campaign-click ga--login" @click="$router.push('/advisers')">login</a> here.
				</p>
				<p class="text-body-1 mb-6">Join us in setting a new standard of excellence in the Later Life lending industry. Together with LIBF, we are committed to supporting your growth and success.</p>
				<p class="text-h6 font-weight-bold mb-2">Ready to Get Started?</p>
				<p class="text-body-1 mb-0">Unlock your potential and take your expertise to the next level with Air Academy.
					<a class="ga--trigger ga--campaign-click ga--register" @click="$router.push('/academy-register')">Click here to begin your journey</a>.
				</p>
			</span>
		</div>

		<!-- Dialogs -->
		<common-dialog v-if="selectedVideo" ref="videoDialog" :max-width="960" @closed="selectedVideo = null">
			<template #header>
				{{ selectedVideo?.copy }}
			</template>
			<template #body>
				<div class="video-container">
					<video :controls="true" :src="selectedVideo?.src" :poster="selectedVideo?.poster | urlize" width="100%" height="auto" autoplay />
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-summer',

		components: {
			CommonDialog
		},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL,
				selectedVideo: null,
				videos: [
					{
						title: 'Celebrating a decade',
						copy: 'Celebrating a decade of professional development with the Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/65568bfd3ba980849ff38ad31a8c1fa1.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/76cd26f4f6e9da657e7bde2eb964c709.png`,
						controls: false
					},
					{
						title: 'Affordability module',
						copy: 'Bringing affordability to the forefront of your advice process with our 10th Academy module',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/90e581e6d22471df00df811ecce6f16e.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/fe1387496eb4a6440bb261344762905a.png`,
						controls: false
					},
					{
						title: 'LIBF Trusted Partner',
						copy: ' LIBF Trusted Partner: Establishing excellence with Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/Maverix+-+Trusted+Partner.mp4`,
						poster: `https://air-assets.airlaterlife.co.uk/manager_documents/69c8eca99dfe81a6627eb94a6dc0ad64.png`,
						controls: false
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {
			playVideo(video) {
				this.selectedVideo = video;

				this.$nextTick(() => {
					this.$refs.videoDialog.open();
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.video-iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.video-container {
		width: 100%;
		height: 100%;
		aspect-ratio: 1.77777;
	}

	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
</style>
