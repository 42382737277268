<!--
	@name app-air-club-provider
	@description Provider tab view for the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-directory :items="providers" :page-size="6" show-actions show-action-phone show-action-email show-action-website paginate class="mb-6 pa-6">
		<template #title>
			Product Providers
		</template>
		<template #detail>
			Click on any panel product provider below to find more information including provider USP's, contact numbers, email addresses and website links.
		</template>
	</common-structure-directory>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import CommonStructureDirectory from '@/component/common/structure/directory';

	export default {
		name: 'app-air-club-provider',

		components: { CommonStructureDirectory },

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership', 'tier']),
			...mapGetters('AppMatterType', ['providerList', 'providerById', 'providerListByMatterType']),

			providers() {
				if(this.tier.id == 'select' && !this.membership('select').bypass) {
					let panel = !this.membership('select').bypass
						? this.site.matter?.lifetimeMortgage?.panel?.sourcing
						: this.site.membership?.select?.matter?.lifetimeMortgage?.panel?.sourcing;

					return this.providerById(panel || [])?.filter((provider) => provider.clubProvider);
				}

				return this.providerListByMatterType('lifetime_mortgage')?.filter((provider) => provider.clubProvider);
			}
		},

		created() {
			this.loadMatterTypeList({ with: 'organisation', order: { property: 'position' } });
		},

		methods: {
			...mapActions('AppMatterType', ['loadMatterTypeList'])
		}
	};
</script>
