<!--
	@name app-client-dashboard-workflow-sourcing-activity
	@description Client Sourcing Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="loading" type="list-item-three-line" />

		<v-sheet v-else-if="!sessions?.length">
			<v-row>
				<v-col v-if="site && 'usesAirFlow' in site && site.usesAirFlow === false" cols="12">
					<p class="text-body-1 font-weight-bold mb-6">
						This tile will support your sourcing research and evidence how your recommended product is tailored to meet each of your customers individual needs.
					</p>
					<ul class="text-body-1 pl-8 mb-8">
						<li class="mb-2">
							Four separate icons allow you to complete all of your research needs from one place.
						</li>
						<li class="mb-2">
							"Refresh Results," of your last product results in real time or "Resubmit," a sourcing session to make any amendments.
						</li>
						<li class="mb-0">
							Download a copy of your latest research or see an archived list of activity for each session and how filter changes have affected product results.
						</li>
					</ul>
					<v-btn color="primary" @click="createSourcingSession()">
						Get started
					</v-btn>
				</v-col>
				<v-col v-else cols="12">
					<video v-if="introResource" class="d-block ml-10 mb-6" :src="introResource.video" :poster="introResource.poster" controls width="50%" style="float: right" />
					<p class="text-body-1 font-weight-bold mb-6">
						This tile will support your sourcing research and evidence how your recommended product is tailored to meet each of your customers individual needs.
					</p>
					<ul class="text-body-1 pl-8 mb-6">
						<li class="mb-2">
							Four separate icons allow you to complete all of your research needs from one place.
						</li>
						<li class="mb-2">
							"Refresh Results," of your last product results in real time or "Resubmit," a sourcing session to make any amendments.
						</li>
						<li class="mb-0">
							Download a copy of your latest research or see an archived list of activity for each session and how filter changes have affected product results.
						</li>
					</ul>
					<p class="text-body-1 mb-8">
						Click on the intro video to gain a deeper insight into how you can navigate AirFlow's Sourcing tile and maximise its benefits and features.
					</p>
					<v-btn color="primary" @click="createSourcingSession()">
						Get started
					</v-btn>
					<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
				</v-col>
			</v-row>
		</v-sheet>

		<div v-else>
			<v-btn small color="primary" class="mb-6" @click="createSourcingSession()">
				start a new sourcing session
			</v-btn>

			<div v-if="sessions.length >= uiOneMaxSessionLimit">
				<v-data-table :headers="headers" :items="sessions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }" dense class="elevation-3 table--styled">
					<template #body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.name">
								<td>
									{{ item.updated | moment('MMMM Do YYYY, h:mma') }}
								</td>
								<td>
									{{ item.created | moment('MMMM Do YYYY, h:mma') }}
								</td>
								<td>
									{{ item.requestedAdvance }}
								</td>
								<td class="shrunk actions">
									<div class="mx-n1">
										<template v-for="(action, index) in getActions(item)">
											<v-tooltip :key="index" bottom>
												<template #activator="{ on }">
													<v-badge
														:content="item.archived"
														:value="action.function == 'openHistory'"
														color="blue accent-1"
														overlaps
														offset-x="18"
														offset-y="18"
													>
														<v-btn
															v-show="!action.hide"
															class="mx-0"
															dark
															icon
															large
															:color="action.iconColour"
															:disabled="action.disabled"
															@click="handleAction(action, item)"
															v-on="on"
														>
															<v-icon>
																{{ action.icon }}
															</v-icon>
														</v-btn>
													</v-badge>
												</template>
												<span>{{ action.title }}</span>
											</v-tooltip>
										</template>
									</div>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</div>
			<div v-else class="overflow-y-auto overflow-x-hidden" style="max-height: 32rem">
				<v-timeline align-top dense class="pa-0">
					<v-timeline-item v-for="(item, index) in visibleSessions" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
						<v-row no-gutters>
							<v-col>
								<p class="text-h6 mt-n1 mb-1">
									<span class="font-weight-bold"> Last Updated {{ item.updated | moment('MMMM Do YYYY, h:mma') }} </span>
								</p>
								<p class="text-body-2 mb-3">
									Requested Advance: {{ item.requestedAdvance }}
								</p>

								<v-timeline align-top dense class="pt-1">
									<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
										<template #icon>
											<v-icon color="success">
												mdi-check-bold
											</v-icon>
										</template>
										<v-row no-gutters style="position: relative; top: 2px">
											<v-col class="pa-0" cols="12" sm="2">
												<span class="text-caption plain mb-0 d-block"> Created: </span>
											</v-col>
											<v-col class="pa-0">
												<span class="text-caption plain mb-0 d-block">
													{{ item.created | moment('MMM Do YYYY, h:mma') }}
												</span>
											</v-col>
										</v-row>
									</v-timeline-item>
								</v-timeline>

								<div class="mt-4 mx-n2">
									<template v-for="(action, index) in getActions(item)">
										<v-tooltip :key="index" bottom>
											<template #activator="{ on }">
												<v-badge
													:content="item.archived"
													:value="action.function == 'openHistory'"
													color="blue accent-1"
													overlaps
													offset-x="18"
													offset-y="12"
												>
													<v-btn
														v-show="!action.hide"
														class="mx-2"
														small
														:color="action.iconColour"
														:disabled="action.disabled"
														:loading="fileLoading[item.iteration.id]"
														@click="handleAction(action, item)"
														v-on="on"
													>
														<v-icon color="white">
															{{ action.icon }}
														</v-icon>
													</v-btn>
												</v-badge>
											</template>
											<span>{{ action.title }}</span>
										</v-tooltip>
									</template>
								</div>
							</v-col>
						</v-row>
					</v-timeline-item>
				</v-timeline>
				<div v-if="(page + 1) * sessionsLimit < total" class="pa-6 text-center">
					<v-btn small color="secondary" @click="page++">
						load more
					</v-btn>
				</div>
			</div>
		</div>

		<common-dialog-sourcing-session-history ref="sourcingSessionHistoryDialog" external />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';
	import documentMixin from '@/mixins/document';
	import CommonDialogSourcingSessionHistory from '@/component/common/dialog/sourcing-session-history';

	export default {
		name: 'app-client-dashboard-workflow-sourcing-activity',

		components: {
			CommonDialogSourcingSessionHistory
		},

		mixins: [documentMixin],

		inject: ['$loadMatter'],

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				loading: true,
				total: 0,
				sessions: null,
				page: 1,
				sessionsLimit: 10,
				uiOneMaxSessionLimit: 50,
				fileLoading: {},
				headers: [
					{ text: 'Updated', value: 'updated' },
					{ text: 'Created', value: 'created' },
					{ text: 'Request', value: 'requestedAdvance' },
					{
						text: 'Actions',
						align: 'end',
						sortable: false,
						class: 'shrunk actions',
						value: ''
					}
				]
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			visibleSessions() {
				let sessions = this.sessions.slice(0, this.sessionsLimit * this.page);
				return sessions;
			}
		},

		created() {
			this.load();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('LifetimeMortgageSourcing', ['fetchSourcingList']),
			...mapActions('File', ['getFile']),

			onMessage(message) {
				if (message?.type !== 'factFind') return;
				this.load();
			},

			load() {
				this.fetchSourcingList({ entityIds: [this.asset.id] })
					.then(({ data }) => {
						this.total = data.total;
						this.sessions = data.data;
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => {
						this.loading = false;
						if (!this.sessions?.length) this.$emit('toggle-intro-resource');
					});
			},

			getActions(item) {
				return [
					{
						title: 'Refresh Results',
						path: `/results/${item?.id}?iteration=${item?.iteration.id}&view=reload`,
						icon: 'mdi-refresh-circle',
						iconColour: 'success'
					},
					{
						title: 'Re-Submit Session',
						path: `/source/${item?.id}`,
						icon: 'mdi-restart',
						iconColour: 'warning'
					},
					{
						title: 'Download Research PDF',
						function: 'fetchDocument',
						functionParams: item?.documents?.find((doc) => doc?.type === 'researchPdf'),
						icon: 'mdi-file-table',
						iconColour: 'info',
						hide: !item?.documents?.find((doc) => doc?.type === 'researchPdf')
					},
					{
						title: 'View Archive',
						function: 'openHistory',
						functionParams: item?.id,
						icon: 'mdi-archive-clock',
						iconColour: 'blue darken-2'
					}
				];
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 * @param item item that needs updating
			 */
			handleAction(action, item) {
				if (action.path) this.$router.push(action.path);
				if (action.function) this[action.function](item, action.functionParams);
			},

			/**
			 * @name fetchDocument
			 * @description TODO
			 * @param item
			 * @param data
			 */
			async fetchDocument(item, data) {
				this.$set(this.fileLoading, item?.iteration.id, true);
				this.downloadDocument({ processId: item?.id, iterationId: item?.iteration.id, s3Key: data?.s3Key, filename: data?.filename }).then(async() => {
					if (!data.s3Key) this.load();
					this.$set(this.fileLoading, item?.iteration.id, false);
				});
			},

			/**
			 * @name openHistory
			 * @description TODO
			 * @param item
			 */
			openHistory(item) {
				this.$refs.sourcingSessionHistoryDialog.open(item.id);
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name createSourcingSession
			 * @description Create New Sourcing Session
			 */
			createSourcingSession() {
				this.$router.push(
					`/source?clientId=${this.client?.id}${this.asset?.id ? `&propertyId=${this.asset.id}` : ''}${
						this.association?.id ? `&associationId=${this.association.id}` : ''
					}`
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	table {
		th,
		td {
			&.shrunk {
				width: 1px;
				white-space: nowrap;
			}

			&.nowrap {
				white-space: nowrap;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}
</style>
