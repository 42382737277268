<!--
	@name app-conference
	@description Conference page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div
			class="py-15"
			style="
				position: relative;
				overflow: hidden;
				z-index: 1;
				border-bottom: 20px solid rgb(30, 176, 225);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: cover;
				background-image: url('https://air-assets.airlaterlife.co.uk/cms/Team-Pic-2400x600.jpg');
			"
		>
			<video
				src="https://airmortgageclub.co.uk/media/conference.mp4"
				style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
				width="100%"
				autoplay
				muted
				loop
				class="d-none"
			/>
			<v-container style="position: relative; z-index: 2">
				<div class="d-inline-block py-8 px-10 ml-md-10 rounded" style="background-color: rgba(255, 255, 255, 0.75); min-width: 75%">
					<div class="text-h5 font-weight-bold">
						National Later Life Adviser Conference 2024
					</div>
					<div class="text-h2 font-weight-bold info--text mt-4">
						Driving growth<br />through safer tracks
					</div>
					<div class="text-h6 font-weight-bold mt-6">
						Event resources available on-demand
					</div>
				</div>
			</v-container>
		</div>

		<!-- About Us -->
		<div>
			<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
				<div class="text-md-center d-flex align-center flex-column px-md-10">
					<p class="text-h5 font-weight-bold mb-6">
						Our 2024 conference continued
						<span class="font-weight-black">Air's mission of guiding later life lending professionals through the market's transformative journey</span> and supporting
						advisers with delivering better customer outcomes.
					</p>
					<p class="text-body-1 font-weight-regular mb-4">
						Featuring industry experts, interactive panel discussions, a range of exhibitors, and innovative masterclasses, this event delved into the collective views
						of market evolution and the need for the Comprehensive Conversations movement.
					</p>
					<p class="text-body-1 font-weight-regular mb-0">
						As the leading platform for later life lending professionals,
						<strong>Air is committed to supporting you with safer tracks: tools, educational resources, and technology</strong> to help you drive business growth and
						navigate industry challenges.
					</p>
					<v-btn target="_blank" href="https://air-assets.airlaterlife.co.uk/cms/Air+Conference+2024+Brochure.pdf" color="secondary" class="mt-8">
						<v-icon class="mr-1">
							mdi-download
						</v-icon>
						download brochure
					</v-btn>
				</div>
			</v-container>
		</div>

		<!-- Plenary 1 -->
		<conference-section :id="'plenary1'" :title="'Plenary 1'" :subtitle="'Introduction from Air - Driving growth through safer tracks'" :avatars="avatars1" :video="video1">
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						Air opened the conference by discussing the current market landscape, including the evolving needs of over 50s borrowers. Looking at the importance of the
						Comprehensive Conversations movement in supporting better customer outcomes. And how Air’s safer tracks through tools, educational resources and tech can
						support advisers to drive business growth and navigate industry challenges.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Plenary 2 -->
		<conference-section :id="'plenary2'" :title="'Plenary 2'" :subtitle="'Changing demographics of later life customers'" :avatars="avatars2" :video="video2" :reverse="true">
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						An insightful Summit exploring the evolving needs of the over 50s borrower. Industry leaders and experts discussed the latest trends and strategies for
						effectively serving this growing customer base. Gain valuable insights through this panel discussion, designed to enhance your understanding and approach to
						later life customers.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Plenary 3 -->
		<conference-section :id="'plenary3'" :title="'Plenary 3'" :subtitle="'Navigating regulatory compliance in later life lending'" :avatars="avatars3" :video="video3">
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						This focused Symposium featured leading compliance experts who provided critical insights into the latest regulations affecting later life lending. Dive
						deep into the complexities of current regulatory requirements, enhance your knowledge and stay ahead in the evolving regulatory landscape.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Plenary 4 -->
		<conference-section :id="'plenary4'" :title="'Plenary 4'" :subtitle="'The future of later life lending'" :avatars="avatars4" :video="video4" :reverse="true">
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						In this Summit event, industry visionaries and experts delved into the emerging trends, innovative products, and evolving customer needs shaping the later
						life lending market. This event featured thought-provoking panel discussions designed to provide you with a comprehensive understanding of the future
						landscape. You’ll gain valuable insights into technological advancements, and market opportunities that will help you stay ahead of the curve.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Masterclass 1 -->
		<conference-section :id="'masterclass1'" :title="'Masterclass 1'" :subtitle="'Innovations in later life lending solutions'" :avatars="avatars5" :video="video5">
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						In this masterclass you'll explore the evolving mortgage market and its practical implications. Speakers dove into trends like older first-time borrowers
						and extended mortgage terms into retirement. Learn about innovations in long-term fixed-rate products and discover how lenders are adapting to meet the
						needs of customers over 50 with flexible options. Tackle the complexities advisers face, including higher costs and customer acquisition, and explore the
						opportunities and risks of AI solutions. Finally, gain practical skills in leveraging technology to navigate the crowded later-life lending market
						efficiently and deliver optimal outcomes for your customers. Walk away with actionable insights and strategies to enhance your advisory services.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Masterclass 2 -->
		<conference-section
			:id="'masterclass2'"
			:title="'Masterclass 2'"
			:subtitle="'Lead Generation & Client Conversations in Later Life Lending'"
			:avatars="avatars6"
			:video="video6"
			:reverse="true"
		>
			<template #copy>
				<slot>
					<p class="text-body-1 mb-0">
						This masterclass session equips you with effective strategies for attracting and engaging potential clients in the later life lending market. Learn
						cutting-edge lead generation techniques and discover how you can convert leads. Gain insights into crafting compelling messages and master client
						conversations to build trust and rapport. Through interactive exercises and real-world scenarios, develop the skills to identify client needs, address
						concerns, and present tailored solutions confidently. Walk away with actionable tactics to enhance your client acquisition efforts and boost your success in
						the competitive later life lending landscape.
					</p>
				</slot>
			</template>
		</conference-section>

		<!-- Plenary 5 -->
		<div class="curb curb--top" />
		<div class="asphalt">
			<div class="car" />
			<conference-section
				:id="'plenary5'"
				:title="'Plenary 5'"
				:subtitle="'F1 guest speaker Damon Hill on Driving growth through safer tracks'"
				:avatars="avatars7"
				:avatar-size="'large'"
				:avatar-basis="100"
				:video="video7"
				:members-only="true"
				:member="!!auth"
				:reverse="true"
				:divider="false"
				class="asphalt--content"
				@login="login"
			>
				<template #copy>
					<slot>
						<p class="text-body-1 mb-4">
							Former Formula 1 driver Damon Hill delved into the exhilarating world of racing and the invaluable lessons it offers on personal growth and resilience.
						</p>
						<p class="text-body-1 mb-0">
							Explore how innovation, resilience, and seizing opportunities are key, both on and off the track. Don't miss this chance to learn from a racing legend
							about thriving amidst change.
						</p>
					</slot>
				</template>
			</conference-section>
		</div>

		<div class="curb curb--bottom" />

		<!-- Support -->
		<div>
			<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
				<div class="text-md-center d-flex align-center flex-column px-md-10">
					<p class="text-h5 font-weight-bold mb-6">
						Get in touch with our team to see how we can support you to grow your business
					</p>
					<div class="d-md-flex flex-wrap justify-center">
						<v-item>
							<v-card
								class="d-flex flex-grow-1 flex-column ma-4"
								color="grey lighten-2"
								height="170"
								style="flex-basis: 30%; position: relative"
								hover
								href="mailto:tam@airlaterlife.co.uk"
								target="blank"
							>
								<v-system-bar class="secondary" height="16" />
								<div class="d-flex justify-center align-center flex-grow-1 text-center py-6 px-10">
									<p class="text-body-1 font-weight-medium mb-0">
										Our team is on hand to support you and your teams with any queries relating to Air's tools and resources -
										<a href="mailto:tam@airlaterlife.co.uk">tam@airlaterlife.co.uk</a>
									</p>
								</div>
							</v-card>
						</v-item>
						<v-item>
							<v-card
								class="d-flex flex-grow-1 flex-column ma-4"
								color="grey lighten-2"
								height="170"
								style="flex-basis: 30%; position: relative"
								hover
								href="mailto:conference@airlaterlife.co.uk"
								target="blank"
							>
								<v-system-bar class="secondary" height="16" />
								<div class="d-flex justify-center align-center flex-grow-1 text-center py-6 px-10">
									<p class="text-body-1 font-weight-medium mb-0">
										For any queries relating to our Conference please contact - <a href="mailto:conference@airlaterlife.co.uk">conference@airlaterlife.co.uk</a>
									</p>
								</div>
							</v-card>
						</v-item>
					</div>

					<div v-show="displayCountdown">
						<p class="text-h5 font-weight-bold mt-10 mb-4">
							What's next?
						</p>
						<div class="wrap">
							<div class="countdown countdown-static">
								<div class="bloc-time days" :data-init-value="days">
									<span class="count-title">Days</span>

									<div class="figure days days-1">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>

									<div class="figure days days-2">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>
								</div>

								<div class="bloc-time hours" :data-init-value="hours">
									<span class="count-title">Hours</span>

									<div class="figure hours hours-1">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>

									<div class="figure hours hours-2">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>
								</div>

								<div class="bloc-time min" :data-init-value="minutes">
									<span class="count-title">Minutes</span>

									<div class="figure min min-1">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>

									<div class="figure min min-2">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>
								</div>

								<div class="bloc-time sec" :data-init-value="seconds">
									<span class="count-title">Seconds</span>

									<div class="figure sec sec-1">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>

									<div class="figure sec sec-2">
										<span class="top">0</span>
										<span class="top-back">
											<span>0</span>
										</span>
										<span class="bottom">0</span>
										<span class="bottom-back">
											<span>0</span>
										</span>
									</div>
								</div>
							</div>
						</div>
						<p class="text-h6 font-weight-regular mb-0">
							The next Air event is <span class="font-weight-bold">{{ nextEvent.name }}</span> on <span class="font-weight-bold">{{ eventDateTime }}</span>.
							<br />
							For details of upcoming events and training, <a @click="$router.push('/events')">visit our events page</a>.
						</p>
					</div>
				</div>
			</v-container>
		</div>
		<common-dialog-login ref="login" @login-success="loginSuccess" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { orderBy } from 'lodash';
	import { gsap, Quart, Back } from 'gsap';
	import ConferenceSection from '@/component/app/air-later-life/conference/section';
	import CommonDialogLogin from '@/component/common/dialog/login';

	export default {
		name: 'app-conference',

		components: {
			ConferenceSection,
			CommonDialogLogin
		},

		data() {
			return {
				auth: null,
				displayCountdown: false,
				days: null,
				hours: null,
				minutes: null,
				seconds: null,
				total_seconds: null,
				video1: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/plenary_1_-_intro__driving_growth_through_safer_tracks+(720p).mp4`,
				video2: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/plenary_2_-_the_changing_demographics+(720p).mp4`,
				video3: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/plenary_3_-_navigating_regulatory_compliance+(720p).mp4`,
				video4: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/plenary_4_-_the_future_of_later_life_lending+(720p).mp4`,
				video5: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/masterclass_1_-_inovations_in_later_life_lending_solutions+(720p).mp4`,
				video6: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/masterclass_2_-_lead_generation+(720p).mp4`,
				video7: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/plenary_5_-_f1_guest_speaker_damon_hill+(720p).mp4`,
				gridCategories: null,
				avatars1: [
					{
						name: 'Paul Glynn',
						position: 'CEO (Air)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Paul+Glynn.png'
					},
					{
						name: 'Mike Taylor',
						position: 'Managing Director (Air)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Mike+Taylor.png'
					}
				],
				avatars2: [
					{
						name: 'Russell Warwick',
						position: 'Group Distribution Strategy Director (Key Group)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Russell+Warwick.png'
					},
					{
						name: 'Ben Waugh',
						position: 'Managing Director (more2life)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Ben+Waugh.png'
					},
					{
						name: 'Ben Allen',
						position: 'Compliance Director (The Right Mortgage & Protection Network)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Ben+Allen.png'
					},
					{
						name: 'Marie Catch',
						position: 'Head of Mortgage Market Development & Optimisation (L&G)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Marie+Catch.png'
					},
					{
						name: 'Roger Morris',
						position: 'Specialist Lending Business Leader',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Roger+Morris.png'
					}
				],
				avatars3: [
					{
						name: 'Keith Richards',
						position: 'CEO (Consumer Duty Alliance)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Keith+Richards.png'
					},
					{
						name: 'Charlotte Allen',
						position: 'Chief Compliance & Risk Officer (Key Group)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Charlotte+Allen+Key+Group.png'
					},
					{
						name: 'Daniel Wraith',
						position: 'Head of Product Governance (PRIMIS)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Daniel+Wraith.png'
					},
					{
						name: 'Chris Wood',
						position: 'Chief Conduct & Compliance Officer (Aviva)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Chris+Wood.png'
					},
					{
						name: 'Stuart Wilson',
						position: 'Managing Director (Maverix Consultants)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Stuart+Wilson.png'
					}
				],
				avatars4: [
					{
						name: 'Paul Saroya',
						position: 'Director (VIVA Retirement Solutions)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Paul+Saroya+Headshot(002).PNG'
					},
					{
						name: 'Oliver Slimm',
						position: 'Credit Strategist (more2life)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Oliver+Slimm.png'
					},
					{
						name: 'Paul Glynn',
						position: 'CEO (Air)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Paul+Glynn.png'
					},
					{
						name: 'Craig Spark',
						position: 'Principal (One Stop 4 Later Life)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Craig+Spark.png'
					},
					{
						name: 'Darren Deacon',
						position: 'Head of Intermediary Sales (Family Building Society)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Darren+Deacon.png'
					}
				],
				avatars5: [
					{
						name: 'Will Hale',
						position: 'Group Director - Advice & Partner Relationships (Key Group)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Will+Hale.png'
					},
					{
						name: 'John Davison',
						position: 'Head of Product, Proposition & Distribution (Perenna)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/John+Davison.png'
					},
					{
						name: 'Andrew Gilbert',
						position: 'Senior Commercial Actuary (L&G)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Andrew+Gilbert.png'
					},
					{
						name: 'Aron Williams',
						position: 'Product Manager (Equilaw)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Aron+Williams.png'
					}
				],
				avatars6: [
					{
						name: 'Alistair Morrison',
						position: 'National Account Manager (Air)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Alistair+Morrison.png'
					},
					{
						name: 'Andy Longhurst',
						position: 'Commercial Director (Lead Tech)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Andy+Longhurst.png'
					},
					{
						name: 'Nigel Borwell',
						position: 'Joint CEO (Lead Tech)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Nigel+Borwell.png'
					},
					{
						name: 'Phil Calvert',
						position: 'Founder (Life Talk)',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Phil+Calvert.png'
					}
				],
				avatars7: [
					{
						name: 'Damon Hill',
						position: '1996 Formula One World Champion',
						image: 'https://air-assets.airlaterlife.co.uk/cms/Damon+Hill.png'
					}
				]
			};
		},

		computed: {
			...mapGetters('CmsEvent', ['upcomingEvents']),

			sortedEvents() {
				if (!this.upcomingEvents) return [];
				let futureDates = this.upcomingEvents.filter((e) => this.$moment(e.start).isAfter(this.comparisonDateTime));
				return orderBy(
					futureDates,
					(e) => {
						return this.$moment(e.start).format('YYYYMMDD');
					},
					['asc']
				);
			},

			featuredEvents() {
				if (!this.upcomingEvents) return [];
				return this.sortedEvents.filter((e) => this.gridCategories.includes(e.category));
			},

			nextEvent() {
				let event = this.featuredEvents.slice(0, 1)[0];
				if (!event) return {};
				return event;
			},

			/**
			 * @name selectedEventEndTime
			 * @description - If on same day, change this to {start} - {endtime} rather than  {start} - {end}
			 * @return {String} time for selected event
			 */
			eventDateTime() {
				if (!this.eventList) return null;

				const start = this.$moment(String(this.nextEvent.start)).format('ddd, Do MMM YYYY h:mma');
				let end = this.$moment(String(this.nextEvent.end)).format('ddd, Do MMM YYYY h:mma');

				if (this.$moment(String(this.nextEvent.start)).isSame(this.$moment(String(this.nextEvent.end)), 'day')) {
					end = this.$moment(String(this.nextEvent.end)).format('h:mma');
				}

				return `${start} - ${end}`;
			}
		},

		async created() {
			this.auth = window.localStorage.getItem('air.authorization');
			this.comparisonDateTime = this.$moment();
			const options = await this.getSchemaOptions('event-category');
			this.gridCategories = options?.map(option => option.name);
			this.loadEventsList().then(() => {
				this.$nextTick(() => {
					this.init();
				});
			});
		},

		methods: {
			...mapActions('CmsEvent', ['loadEventsList']),
			...mapActions('Option', ['getSchemaOptions']),

			init() {
				var t1 = new Date(this.nextEvent.start);
				var t2 = new Date();
				var dif = t1.getTime() - t2.getTime();
				if (dif < 0) return;

				this.total_seconds = Math.ceil(Math.abs(dif / 1000));
				var delta = this.total_seconds;

				this.days = Math.floor(delta / 86400);
				delta -= this.days * 86400;
				// calculate (and subtract) whole hours
				this.hours = Math.floor(delta / 3600);
				delta -= this.hours * 3600;

				// calculate (and subtract) whole minutes
				this.minutes = Math.floor(delta / 60);
				delta -= this.minutes * 60;

				// what's left is seconds
				this.seconds = Math.floor(delta % 60); // in theory the modulus is not required

				// Init countdown values
				this.values = {
					days: this.days,
					hours: this.hours,
					minutes: this.minutes,
					seconds: this.seconds
				};

				let selector = '.countdown-static';
				this.objel = document.querySelector(selector);

				// DOM
				this.obj = {
					days: this.objel.querySelectorAll('.bloc-time.days .figure'),
					hours: this.objel.querySelectorAll('.bloc-time.hours .figure'),
					minutes: this.objel.querySelectorAll('.bloc-time.min .figure'),
					seconds: this.objel.querySelectorAll('.bloc-time.sec .figure')
				};

				// Animate countdown to the end
				if (this.days < 100) {
					this.displayCountdown = true;
					this.count(this.obj);
				}
			},

			count(obj) {
				var that = this,
					$day_1 = obj.days[0],
					$day_2 = obj.days[1],
					$hour_1 = obj.hours[0],
					$hour_2 = obj.hours[1],
					$min_1 = obj.minutes[0],
					$min_2 = obj.minutes[1],
					$sec_1 = obj.seconds[0],
					$sec_2 = obj.seconds[1];

				if (this.countdown_interval) clearInterval(this.countdown_interval);

				this.countdown_interval = setInterval(() => {
					if (that.total_seconds > 0) {
						--that.values.seconds;

						if (that.values.minutes >= 0 && that.values.seconds < 0) {
							that.values.seconds = 59;
							--that.values.minutes;
						}

						if (that.values.hours >= 0 && that.values.minutes < 0) {
							that.values.minutes = 59;
							--that.values.hours;
						}

						if (that.values.days >= 0 && that.values.hours < 0) {
							that.values.hours = 23;
							--that.values.days;
						}

						// Days
						that.checkHour(that.values.days, $day_1, $day_2);
						// Hours
						that.checkHour(that.values.hours, $hour_1, $hour_2);
						// Minutes
						that.checkHour(that.values.minutes, $min_1, $min_2);
						// Seconds
						that.checkHour(that.values.seconds, $sec_1, $sec_2);

						--that.total_seconds;
					} else {
						this.displayCountdown = false;
						clearInterval(that.countdown_interval);
					}
				}, 1000);
			},

			animateFigure($el, value) {
				var $top = $el.querySelectorAll('.top'),
					$bottom = $el.querySelectorAll('.bottom'),
					$back_top = $el.querySelectorAll('.top-back'),
					$back_bottom = $el.querySelectorAll('.bottom-back');

				// Before we begin, change the back value
				$back_top[0].querySelectorAll('span')[0].innerHTML = value;

				// Also change the back bottom value
				$back_bottom[0].querySelectorAll('span')[0].innerHTML = value;

				// Then animate
				gsap.to($top[0], {
					rotationX: '-180deg',
					transformPerspective: 300,
					ease: Quart.easeOut,
					duration: 0.8,
					onComplete() {
						$top[0].innerHTML = value;
						$bottom[0].innerHTML = value;
						gsap.set($top[0], { rotationX: 0 });
					}
				});

				gsap.to($back_top[0], {
					rotationX: 0,
					transformPerspective: 300,
					ease: Back.easeOut,
					scaleX: 0.98,
					duration: 0.8,
					clearProps: 'all'
				});
			},

			checkHour(value, $el_1, $el_2) {
				var val_1 = value.toString().charAt(0),
					val_2 = value.toString().charAt(1),
					fig_1_value = $el_1.querySelectorAll('.top'),
					fig_2_value = $el_2.querySelectorAll('.top');

				if (value >= 10) {
					// Animate only if the figure has changed
					if (fig_1_value[0].innerHTML !== val_1) this.animateFigure($el_1, val_1);
					if (fig_2_value[0].innerHTML !== val_2) this.animateFigure($el_2, val_2);
				} else {
					// If we are under 10, replace first figure with 0
					if (fig_1_value[0].innerHTML !== '0') this.animateFigure($el_1, 0);
					if (fig_2_value[0].innerHTML !== val_1) this.animateFigure($el_2, val_1);
				}
			},

			login() {
				this.$refs.login.open('Title', `Are you sure...`);
			},

			loginSuccess() {
				this.auth = window.localStorage.getItem('air.authorization');
				this.$refs.login.close();
			}
		}
	};
</script>

<style lang="scss" scoped>
	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}

	.asphalt {
		position: relative;
		background-color: CCCCCC;

		.car {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/cms/clipart4510703.png');
			background-repeat: no-repeat;
			background-position: -250% 50%;
			background-attachment: fixed;
			background-size: 20%;
			animation: animatedBackground 20s ease-in-out infinite;
			opacity: 0.4;
		}

		@keyframes animatedBackground {
			0% {
				background-position: -250% 50%;
			}
			25% {
				background-position: 250% 50%;
			}
			100% {
				background-position: 250% 50%;
			}
		}

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.15;
			background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/cms/asphalt-road-texture-dark-gray-color.jpg');
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: cover;
			background-attachment: fixed;
		}

		&--content {
			position: relative;
		}
	}

	.curb {
		height: 20px;

		&--top {
			border-top: 3px solid #ffffff;
			box-shadow: 2px -5px 10px 2px rgba(0, 0, 0, 0.25);
			background: repeating-linear-gradient(45deg, $error, $error 100px, #ffffff 100px, #ffffff 200px);
		}

		&--bottom {
			border-bottom: 3px solid #ffffff;
			box-shadow: 2px 5px 10px 2px rgba(0, 0, 0, 0.25);
			background: repeating-linear-gradient(135deg, $error, $error 100px, #ffffff 100px, #ffffff 200px);
		}
	}

	@function tint($color, $percentage) {
		@return mix(white, $color, $percentage);
	}

	// Variables
	$lato: 'Roboto';
	$cardCol1: tint(rgb(27, 120, 216), 10%);
	$cardCol2: tint(rgb(27, 120, 216), 20%);

	// Countdown
	.countdown {
		width: 540px;
		margin: 0 auto 20px;
		display: flex;

		.bloc-time {
			text-align: center;
			flex-grow: 1;
			flex-shrink: 1;
			padding: 4px;
			flex-basis: 20%;

			&:last-child {
				margin-right: 0;
			}
		}

		.count-title {
			display: block;
			margin-bottom: 8px;
			font: normal 0.94em $lato;
			//color: #55acb8;
			//color: #f0acb8;
			text-transform: uppercase;
			font-weight: 500;
		}

		.figure {
			position: relative;
			float: left;
			height: 3rem;
			width: 46%;
			margin: 0 2%;
			background-color: $cardCol1;
			border-radius: 8px;
			box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);

			&:last-child {
				margin-right: 0;
			}

			> span {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				font: normal 2.5rem $lato;
				line-height: 3rem;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.6);
			}

			&.days {
				> span {
					color: rgba(255, 255, 255, 0.8);
				}
			}

			.top,
			.bottom-back {
				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			.top {
				z-index: 3;
				background-color: $cardCol2;
				transform-origin: 50% 100%;
				-webkit-transform-origin: 50% 100%;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				transform: perspective(200px);
			}

			.bottom {
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 50%;
					background-color: rgba(0, 0, 0, 0.02);
				}
			}

			.bottom-back {
				z-index: 2;
				top: 0;
				height: 50%;
				overflow: hidden;
				background-color: $cardCol2;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				span {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			.top,
			.top-back {
				height: 50%;
				overflow: hidden;
				backface-visibility: hidden;
			}

			.top-back {
				z-index: 4;
				bottom: 0;
				background-color: #fff;
				background-color: $cardCol1;
				-webkit-transform-origin: 50% 0;
				transform-origin: 50% 0;
				transform: perspective(200px) rotateX(180deg);
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

				span {
					position: absolute;
					top: -100%;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
		}
	}

	.wrap {
		background-position: 50% 50%;
		background-size: cover;
	}
</style>
