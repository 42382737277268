<!--
	@name common-dialog-tutorial
	@description Tutorial Video Dialog
	@date 2020/06/13
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" @closed="dialogClosed">
		<template v-if="showTutorial" #header>
			{{ tutorial.title }}
		</template>
		<template #body>
			<video v-if="showTutorial" width="100%" class="text-center" :src="tutorial.videoUrl" autoplay controls controlsList="nodownload" oncontextmenu="return false;" />
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'common-dialog-tutorial',

		components: { CommonDialog },

		props: { tutorial: { type: Object, required: true } },

		data() {
			return {
				showTutorial: false
			};
		},

		methods: {
			/**
			 * @name dialogClosed
			 * @description Dialog closed event handler
			 */
			dialogClosed() {
				// Wait for popup close animation to be finished
				setTimeout(() => (this.showTutorial = false), 100);
			},

			open() {
				this.showTutorial = true;
				this.$refs.dialog.open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	video {
		display: block;
		max-width: 100%;
	}
</style>
