import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Advisor
 * @exports Service/Option/Advisor
 * @description API Option Advisor Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Advisor {
	/**
	 * @static @name get
	 * @description get advisors list
	 * @returns {Object} Promise a http response
	 */
	static get() {
		return $http.get(`option/advisor`);
	}
}
