import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

/**
 * @namespace Plugins
 * @exports Plugins/Vuetify
 * @description Vuetify plugin for Vue
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default new Vuetify({
	breakpoint: {
		thresholds: {
			xl: 999999
		}
	},
	icons: {
		iconfont: 'mdi'
	},
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: '#1eb0e1',
				secondary: '#1b78d8',
				accent: '#FDE74C',
				info: '#670056',
				success: '#0A8754',
				warning: '#F58F29',
				error: '#FE4A49'
			}
		}
	}
});
