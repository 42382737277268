import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Banner
 * @exports Service/Cms/Banner
 * @description API Banner Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Banner {
	/**
	 * @static @name get
	 * @description get login banner
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static getLoginBanner(cache = true) {
		return $http.get(`cms/banner/login-advert`, {
			cache,
			cachePath: `cms-login-advert`
		});
	}

	/**
	 * @static @name get
	 * @description get advisor banner
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static getAdvisorBanner(cache = true) {
		return $http.get(`cms/banner/advisor-advert`, {
			cache,
			cachePath: `cms-advisor-advert`
		});
	}
}
