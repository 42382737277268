import { CmsContent } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Content {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			contentList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name contentDashboard
			 * @description used to get filtered content from store
			 * @param {Object} state
			 */
			contentDashboard: (state) => state.contentList.find((i) => i.nameUnique === 'dashboard') || {},

			/**
			 * @name form
			 * @description used to get filtered forms from store
			 * @param {Object} state
			 */
			content: (state) => (nameUnique) => {
				return state.contentList.find((i) => i.nameUnique === nameUnique) || {};
			},

			/**
			 * @name contentSearch
			 * @description TODO
			 * @param {Object} state
			 */
			contentSearch: (state) => (nameUnique, contentName) => {
				const content = state.contentList.find((i) => i.nameUnique === nameUnique) || {};
				return content.text ? content.text[contentName] : undefined;
			},

			/**
			 * @name contentClub
			 * @description used to get filtered content from store
			 * @param {Object} state
			 */
			contentClub: (state, getters, rootState, rootGetters) => {
				let clubContent = state.contentList.find((i) => i.nameUnique === 'club')?.text;

				const content = { ...clubContent.default };
				const tier = rootGetters['Account/tier'].id;
				const academyMembership = !!rootGetters['Account/membership']('academy').approve;

				let tierContent = null;

				if (academyMembership && clubContent[`${tier}Academy`]) tierContent = clubContent[`${tier}Academy`];
				else tierContent = clubContent[tier];

				if (!tierContent) return content;

				// Merge default content with tier content
				Object.keys(tierContent).forEach((prop) => {
					if (Object.prototype.hasOwnProperty.call(tierContent, prop)) {
						const defaultValue = content?.[prop];
						const customValue = tierContent?.[prop];

						if (prop === 'benefits') {
							content[prop] = defaultValue?.map((defaultItem) => {
								const customItem = customValue.find((customItem) => customItem?.id === defaultItem?.id);
								return customItem ? { ...defaultItem, ...customItem } : defaultItem;
							});
						} else content[prop] = customValue;
					}
				});

				return content;
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setContent
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setContent: (state, data) => {
				let contentList = [...state.contentList];
				let idx = contentList.reduce((p, c, i) => (c.nameUnique === data.nameUnique ? i : p), -1);
				if (idx >= 0) contentList[idx] = data;
				else contentList.push(data);
				state.contentList = contentList;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadContent
			 * @description used to fetch app content
			 * @param {function} commit
			 */
			async loadContent({ commit, rootState }, name) {
				const domain = rootState.CmsSite.site.name || 'default';
				let response = await CmsContent.get(name, domain);
				if (!response.error) commit('setContent', response.data);
			}
		};
	}
}
