<!--
	@name app-air-academy
	@description Air Academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-2 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink">
					<div class="align-self-center image-content">
						<img class="brand-logo" :src="'logo/brand/air_academy_logo_neg.svg' | urlize" />
					</div>
				</v-col>
				<v-col class="grow text-right">
					<app-common-dialog-glossary
						class="d-inline"
						:items="glossaryItems"
						:button-text-hidden="true"
						:icon-large="true"
						icon-color="white"
						title="Frequently asked questions"
					/>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row no-gutters class="mx-n3 mt-9 px-3" style="position: relative">
			<a
				v-if="recommendations.length"
				:class="['text-body-1', 'font-weight-medium', { 'warning--text': outstandingRecommendations, 'grey--text': !outstandingRecommendations }]"
				style="position: absolute; right: 32px; top: 0"
				@click="$refs.recommendationsDialog.open()"
			>
				Recommendations
				<v-badge :color="outstandingRecommendations ? 'warning' : 'grey'" :content="outstandingRecommendations.toString()" inline style="position: relative; bottom: 2px" />
			</a>
			<v-col cols="10">
				<div class="border-effect border-effect--lg ml-6">
					<p class="text-h5 mb-2 font-weight-regular">
						<span class="font-weight-bold">Air Academy: </span> We live & breathe your development
					</p>
					<p class="text-body-1 mb-0">
						Welcome to your Air Academy Dashboard, an innovative learning platform that gives you access to LIBF-accredited training programmes and continuous
						professional development opportunities, which cover a range of critical areas to help enhance your industry knowledge.
					</p>
				</div>
			</v-col>
			<v-col cols="2" />
		</v-row>

		<v-row class="mt-6">
			<v-col class="py-0" cols="12" md="8">
				<common-base-tab-view :tabs="tabs" router-view />
			</v-col>
			<v-col class="py-0" cols="12" md="4">
				<app-air-academy-status />
				<app-air-academy-cpd class="mt-6" />
				<app-air-academy-libf class="mt-6" />
			</v-col>
		</v-row>

		<common-dialog ref="recommendationsDialog">
			<template #body>
				<div class="border-effect border-effect--grey">
					<p class="text-h6 font-weight-medium mb-1">
						Recommendations ({{ outstandingRecommendations }})
					</p>
					<p class="text-body-2 font-weight-regular mb-6">
						Earn more as you grow. There's so much more to gain by working with Air, your club membership is just the start...
					</p>
				</div>
				<v-card class="mx-auto" elevation="0">
					<v-list color="grey lighten-2" class="pa-0">
						<v-list-item-group v-model="activeRecommendations" active-class="pink--text" multiple>
							<template v-for="(recommendation, index) in recommendations">
								<v-list-item
									:key="recommendation.title"
									:value="recommendation.id"
									:disabled="activeRecommendations.includes(recommendation.id)"
									@click="recommendationUpdate(recommendation.id)"
								>
									<template #default="{ active }">
										<v-list-item-content>
											<v-list-item-title>
												{{ recommendation.title }}
											</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action>
											<v-icon v-if="!active" color="grey lighten-1">
												mdi-check-bold
											</v-icon>

											<v-icon v-else color="success">
												mdi-check-bold
											</v-icon>
										</v-list-item-action>
									</template>
								</v-list-item>

								<v-divider v-if="index < recommendations.length - 1" :key="index" />
							</template>
						</v-list-item-group>
					</v-list>
				</v-card>
			</template>
		</common-dialog>

		<app-air-academy-dashboard-ring
			ref="ringOne"
			:ring="1"
			:catalog-id="25"
			:title="'Adviser Accreditation for Later Life Lending'"
			:testimonial="{
				name: 'Adviser Accreditation for Later Life Lending',
				image: `${VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyAccreditation.jpg`,
				mimetype: 'video/mp4',
				hideStatus: true,
				fixedHeight: true,
				emit: 'play-video'
			}"
			:video="'webinar/AcademyAccreditation.mp4'"
		>
			<template #logo>
				<img :src="'LIBF_Accredited Learning Prog_BlackRust.png' | urlize('brand')" class="d-block" style="width: 100%; max-width: 300px; float: right;" />
			</template>
			<template #highlight>
				<v-alert max-width="400" dense icon="mdi-certificate" type="info" colored-border border="left" color="accent" class="grey lighten-3 mb-6 ml-3">
					24 hours structured CPD
				</v-alert>
			</template>
			<template #copy>
				<p class="text-body-1 mb-3">
					Aligned to the Equity Release Council's Competency framework, our Adviser Accreditation for Later Life Lending programme provides our members with the opportunity to achieve professional status along with a bespoke digital badge of achievement that stands as a kite mark of industry excellence.
				</p>
				<p class="text-body-1 mb-0">
					Our Academy membership is recognised as representing the gold standard of later life lending advice quality and customer outcomes, attracting higher procuration fees from our Ambassador lending partners and boosting the new business growth potential for those who attain and maintain their professional status.
				</p>
			</template>
			<template #upgrade>
				<p class="text-body-1 font-weight-bold mb-6">
					Explore two of the LIBF-accredited Adviser Accreditation for Later Life Lending modules below as a free taster.
					Any progress of these modules will carry over and count towards your full accreditation.
					To find out more or purchase, please <a href="mailto:Academy.enquiries@airlaterlife.co.uk">click here</a>.
				</p>
			</template>
		</app-air-academy-dashboard-ring>

		<app-air-academy-dashboard-ring
			ref="ringTwo"
			:ring="2"
			:catalog-id="26"
			:title="'Later Life Lending Adviser Induction'"
			:testimonial="{
				name: 'Later Life Lending Adviser Induction',
				image: `${VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyInduction.jpg`,
				mimetype: 'video/mp4',
				hideStatus: true,
				fixedHeight: true,
				emit: 'play-video'
			}"
			:video="'webinar/AcademyInduction.mp4'"
		>
			<template #logo>
				<img :src="'LIBF_Accredited Learning Prog_BlackRust.png' | urlize('brand')" class="d-block" style="width: 100%; max-width: 300px; float: right;" />
			</template>
			<template #highlight>
				<v-alert max-width="400" dense icon="mdi-certificate" type="info" colored-border border="left" color="accent" class="grey lighten-3 mb-6 ml-3">
					24 hours structured CPD
				</v-alert>
			</template>
			<template #copy>
				<p class="text-body-1 mb-3">
					Air Academy's Later Life Lending Adviser Induction programme is fully accredited by the LIBF and walks through the customer journey from an adviser's perspective. This blended learning programme focuses on the practicalities involved in achieving good customer outcomes. There are four modules:
				</p>
				<ul class="text-body-1 mb-0">
					<li>
						Your role as a later life lending adviser
					</li>
					<li>
						Establishing your customer's needs
					</li>
					<li>
						Achieving a good customer outcome
					</li>
					<li>
						Presenting your recommendation & building lasting relationships
					</li>
				</ul>
				<v-btn class="mt-6" light small color="primary" @click="navigateToCourse">
					click here to book your place
				</v-btn>
			</template>
			<template #upgrade>
				<p class="text-body-1 font-weight-bold mb-6">
					The brief course below will present you with an overview of the LIBF-accredited Later Life Lending Adviser Induction Programme. To find out more, please <a href="mailto:Academy.enquiries@airlaterlife.co.uk">click here</a>
				</p>
			</template>
		</app-air-academy-dashboard-ring>

		<app-air-academy-dashboard-ring
			ref="ringThree"
			:restricted="false"
			:ring="3"
			:catalog-id="28"
			:title="'Systems & Technology Training'"
			:testimonial="{
				name: 'Systems & Technology Training',
				image: `${VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademySystems.jpg`,
				mimetype: 'video/mp4',
				hideStatus: true,
				fixedHeight: true,
				emit: 'play-video'
			}"
			:video="'webinar/AcademySystems.mp4'"
		>
			<template #copy>
				<p class="text-body-1 mb-3">
					Education is at the heart of raising standards across the market. The online resources below are designed to unlock the full potential of Air's Later Life Lending Platform. From practical 'how-to' guides to immersive walkthrough demos, these materials empower you to become a master of Air's award-winning technology.
				</p>
				<p class="text-body-1 mb-0">
					Available On Demand these resources allow you to learn at your own pace. Mastering the system's full capability enables you to have more comprehensive conversations with your customers, ensuring good customer outcomes. Your learning progress is documented in your CPD log, evidencing your dedication to staying current in the ever-evolving financial landscape.
				</p>
			</template>
		</app-air-academy-dashboard-ring>

		<app-air-academy-dashboard-ring
			ref="ringFour"
			:restricted="false"
			:ring="4"
			:catalog-id="27"
			:title="'Insights Hub: On-Demand CPD'"
			:testimonial="{
				name: 'Insights Hub: On-Demand CPD',
				image: `${VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyCPD.jpg`,
				mimetype: 'video/mp4',
				hideStatus: true,
				fixedHeight: true,
				emit: 'play-video'
			}"
			:video="'webinar/AcademyCPD.mp4'"
		>
			<template #copy>
				<p class="text-body-1 mb-3">
					As the platform for later life lending professionals, it is our mission to keep you abreast of what's happening in our industry, as it happens.
					Explore our Insights Hub for practical resources covering the latest industry trends, products, and effective client communication strategies.
					Access recordings of live Air Academy events, Ambassador webinars, and CPD materials spanning product, technical, and interpersonal growth. Stay ahead in an ever-evolving market, creating new opportunities for your business. With a variety of learning resources and a personalised CPD tracker to evidence your progress, investing in your continuous professional development has never been easier or more rewarding.
				</p>
			</template>
		</app-air-academy-dashboard-ring>

		<app-air-academy-dashboard-events
			ref="ringFive"
			:title="'Air Academy Events'"
		/>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import CommonDialog from '@/component/common/dialog';
	import AppCommonDialogGlossary from '@/component/common/dialog/glossary';
	import AppAirAcademyStatus from '@/component/app/air-academy/status';
	import AppAirAcademyCpd from '@/component/app/air-academy/cpd';
	import AppAirAcademyLibf from '@/component/app/air-academy/libf';
	import AppAirAcademyDashboardRing from '@/component/app/air-academy/dashboard/ring';
	import AppAirAcademyDashboardEvents from '@/component/app/air-academy/dashboard/events';
	import { ElementTools, EventBus } from '@/utils';

	export default {
		name: 'app-air-academy',

		components: {
			CommonBaseTabView,
			CommonDialog,
			AppCommonDialogGlossary,
			AppAirAcademyStatus,
			AppAirAcademyCpd,
			AppAirAcademyLibf,
			AppAirAcademyDashboardRing,
			AppAirAcademyDashboardEvents
		},

		data() {
			return {
				VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL,
				tabs: [
					{ title: 'Overview', alerts: 0, name: 'airAcademy.dashboard', path: '/academy/dashboard' },
					{ title: 'Ambassadors', alerts: 0, name: 'airAcademy.ambassadors', path: '/academy/ambassadors' },
					{ title: 'Cost', alerts: 0, name: 'airAcademy.cost', path: '/academy/cost' }
				],
				glossaryItems: [
					{
						key: '1',
						type: 'text',
						title: 'Why join the Academy?',
						description:
							'With an increasing focus on customer outcomes and the complexities that come with advising and guiding older consumers through the later life lending landscape, Air Academy provides an unrivalled benchmark of expertise and knowledge for this industry. The purpose of the Academy is to empower advisers with sector-leading training that not only enhances industry knowledge and professionalism, but also supports continuous professional development.<br/><br/>Our Academy Premium is recognised as representing the gold standard of later life lending advice quality and customer outcomes, attracting higher procuration fees from our ambassador lending partners and boosting the new business growth potential for those who attain and maintain their professional status.'
					},
					{
						key: '2',
						type: 'text',
						title: 'What are the five stages of the Academy?',
						description:
							'The five stages are:<br/><br/>Stage 1: Adviser Accreditation for Later Life Lending Programme.<br/>Stage 2: Later Life Lending Adviser Induction.<br/>Stage 3: Systems & Technology Training.<br/>Stage 4: Insights Hub: On Demand CPD.<br/>Stage 5: Air Academy Events'
					},
					{
						key: '3',
						type: 'text',
						title: 'What qualification do I need to start the training?',
						description: 'You need to be CeMAP and CeRER/ER1 (or equivalent) qualified to start either of our accredited programmes (Stages 1 & 2).'
					},
					{
						key: '4',
						type: 'text',
						title: 'Who are our programmes accredited by?',
						description:
							'The Adviser Accreditation for Later Life Lending Programme and the Later Life Adviser Induction are both accredited by the London Institute of Banking and Finance (LIBF).'
					},
					{
						key: '5',
						type: 'text',
						title: 'What is Trusted Partner Status?',
						description:
							'One of only 6 training providers worldwide, Air Academy has demonstrated a commitment to providing the highest standards of education and training, while providing sector leading support for its learners. Trusted Partner Status recognises the excellence of our team of highly experienced learning consultants, and the quality of service provided to our learners. In awarding Trusted Partner Status, the LIBF pointed specifically to the high standard of our blended training programmes.<br/><br/>This accolade represents the culmination of 10 years training provision to the later life lending sector and provides independent recognition that Air Academy is truly the gold standard for learning.'
					},
					{
						key: '6',
						type: 'text',
						title: 'Who is the Adviser Accreditation for Later Life Lending for?',
						description:
							'It’s for all Later Life Lending Advisers whether you’re just starting out or have been advising in the market for some time. It’s always helpful to get a reminder of the essential areas that are an integral part of the adviser role and to ensure you keep up to date as the market evolves.'
					},
					{
						key: '7',
						type: 'text',
						title: 'What are the 10 modules included in the Adviser Accreditation for Later Life Lending?',
						description:
							'The Later Life Lending Products, The Later Life Lending Process, Later Life Lending in the Broader Context, Building a Client Proposition, The Market, The Industry, The Client, Soft Skills, Client Vulnerability, Affordability.'
					},
					{
						key: '8',
						type: 'text',
						title: 'How does an adviser achieve professional status through the Academy?',
						description:
							'To achieve professional status, you need to complete the 10 LIBF-accredited modules. These count towards 24 hours structured CPD and are aligned to the Equity Release Council’s Competency framework, as well as a focus on Client Vulnerability and Affordability.'
					},
					{
						key: '9',
						type: 'text',
						title: 'What does an adviser get for achieving professional status?',
						description:
							'Completion of the 10 LIBF accredited modules gives you permission to a digital badge in your email auto signature and other promotional materials, as it demonstrates you have achieved a level of competence recognised by the LIBF. You also have access to higher procurations fees and other benefits.'
					},
					{
						key: '10',
						type: 'text',
						title: 'Do I have to re-take the modules every year to maintain my accreditation?',
						description: 'No, you only have to complete any new modules and sit a re-accreditation MOT, which is based on the previous modules.'
					},
					{
						key: '11',
						type: 'text',
						title: 'Is there a cost to re-accreditation?',
						description: 'No, this is included in your monthly subscription fee.'
					},
					{
						key: '12',
						type: 'text',
						title: 'Who is the Later Life Adviser Induction for?',
						description:
							'The Later Life Adviser Induction is for any Later Life Lending advisers that want to do the right thing for their customers. Covering the complete customer journey, it highlights important changes in the role since the introduction of the FCA’s Consumer Duty, provides top tips on structuring your customer appointments, demonstrates the amazing tools Air provides to support you in having comprehensive customer conversations and explores some of the complementary areas that can impact later life decision-making such as care and estate planning.'
					},
					{
						key: '13',
						type: 'text',
						title: 'How does the Later Life Adviser Induction work?',
						description:
							'The induction takes place remotely and uses a blended learning approach to keep it interesting and engaging. Through a series of 4 self-learning modules, incorporating reading, videos and audio clips, you complete workbook activities and case studies to bring the content to life and explore how they will apply the knowledge in role. Support is available from the Air Academy team throughout, including live webinars and 121 review sessions, where experienced facilitators provide face to face contact time and an opportunity for advisers to raise questions and share ideas with other later life professionals, to fully consolidate their learning.'
					},
					{
						key: '14',
						type: 'text',
						title: 'Why should I complete the induction rather than just get up and running and learn along the way, which would be quicker and wouldn’t cost me anything?',
						description:
							'Whilst there would be no upfront cost in learning along the way, the short-term gain could result in a longer-term pain. If an adviser completes a later life lending case without giving due consideration to the customer’s holistic needs or evidencing why their recommendation is a good outcome, they may find that they have recommended an inappropriate course of action which results in harm for the customer. If that harm were foreseeable and could have been avoided, the adviser may face action from the Regulator. By completing the Air LLL Adviser induction, you give yourself the opportunity to explore all aspects of your role; to fully understand your responsibilities and the options you can consider, putting your best foot forward from the start.'
					}
				],
				activeRecommendations: [],
				recommendations: [],
				cpdTrainingItems: []
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('AppWebinar', ['authStatus', 'courses', 'catalogs']),
			...mapGetters('Account', ['membership', 'tier']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('CmsData', ['data']),

			outstandingRecommendations() {
				return this.recommendations.length - this.activeRecommendations.length;
			}
		},

		watch: {
			'$route.name': {
				handler(name) {
					if (name === 'airAcademy') this.$router.push('/academy/dashboard');
				},
				deep: true,
				immediate: true
			}
		},

		created() {
			EventBus.$on('open-academy-ring', this.openRing);
			EventBus.$on('credentials-updated', this.credentialsUpdated);
		},

		destroyed() {
			EventBus.$off('open-academy-ring', this.openRing);
			EventBus.$off('credentials-updated', this.credentialsUpdated);
		},

		mounted() {
			this.fetchCourseList();
			this.fetchCatalogContent();
			//TODO turn recommendations back on when list is finalised
			/*this.getUserRecommendation(this.user.id).then(({ data }) => {
				this.recommendations = data[this.tier.id || 'none'];
				this.activeRecommendations = this.recommendations.filter((recommendation) => recommendation.user_selected).map((recommendation) => recommendation.id);
			});*/
		},

		methods: {
			...mapActions('CmsDownload', ['loadDownloadsList']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsData', ['loadData']),
			...mapActions('AccountUserRecommendation', ['getUserRecommendation', 'addUserRecommendation', 'deleteUserRecommendation']),
			...mapActions('AppWebinar', ['getCourseList', 'getCatalogContent']),

			/**
			 * @name fetchCourseList
			 * @description Fetch the course list from the API
			 */
			fetchCourseList() {
				ElementTools.fireNotification(this.$el, 'info', {
					text: 'Retrieving your LMS account details',
					timeout: 0,
					close: true
				});

				this.getCourseList()
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', {
							text: 'Success',
							timeout: 3000,
							close: true
						});
					})
					.catch((error) => {
						if ([400].includes(error?.status))
							ElementTools.fireNotification(this.$el, 'error', {
								text: 'An error occurred whilst attempting to connect to your air academy learning platform account. Please click "connect to my air academy learning platform" to resolve this issue.',
								timeout: 0,
								close: true
							});
						else if ([500].includes(error?.status))
							ElementTools.fireNotification(this.$el, 'error', {
								text: 'An unexpected error occurred whilst attempting to connect to your air academy learning platform account. Please speak with our support team to resolve this issue.',
								timeout: 0,
								close: true
							});
						else ElementTools.hideNotification(this.$el);
					});
			},

			fetchCatalogContent() {
				// Accreditation
				this.getCatalogContent(25)
					.then(() => {})
					.catch((error) => { console.log(error) });
				// Induction
				this.getCatalogContent(26)
					.then(() => {})
					.catch((error) => { console.log(error) });
				// Systems
				this.getCatalogContent(28)
					.then(() => {})
					.catch((error) => { console.log(error) });
				// CPD
				this.getCatalogContent(27)
					.then(() => {})
					.catch((error) => { console.log(error) });
			},

			credentialsUpdated() {
				this.fetchCourseList();
				this.fetchCatalogContent();
			},

			openRing(ring) {
				this.$refs[ring].open();
			},

			navigateToCourse() {
				window.location.href = 'https://academy.airlaterlife.co.uk/learn/course/internal/view/classroom/216/air-academy-later-life-adviser-induction-welcome-webinar';
			}
		}
	};
</script>

<style lang="scss" scoped>
	.image-box {
		background: #e8e8eb;
	}

	.brand-logo {
		max-height: 40px;
	}

	.mfg2020 {
		height: 250px;
	}

	.image-content {
		font-size: 0;
	}

	.brand-logo {
		height: 40px;
	}
</style>
