<!--
	@name common-base-helpful-feddback
	@description Was this helpful
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-sheet class="text-center mx-auto" :component="$options.name">
		<p class="text-body-2 mb-2">
			Was this helpful?
		</p>
		<div>
			<v-btn
				class="ga--trigger ga--helpful-feedback-posted mr-1"
				:data-ga-id="id"
				data-ga-value="yes"
				outlined
				small
				@click="feedback = 'yes'"
			>
				<v-icon left small :color="feedback === 'yes' ? 'green' : 'black'">
					{{ feedback === 'yes' ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
				</v-icon>Yes
			</v-btn>
			<v-btn
				class="ga--trigger ga--helpful-feedback-posted hj--negative-feedback-posted ml-1"
				:data-ga-id="id"
				data-ga-value="no"
				outlined
				small
				@click="feedback = 'no'"
			>
				<v-icon left small :color="feedback === 'no' ? 'red' : 'black'">
					{{ feedback === 'no' ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}
				</v-icon>No
			</v-btn>
		</div>
	</v-sheet>
</template>

<script>
	export default {
		name: 'common-base-helpful-feddback',

		props: {
            id: { type: String, required: true }
        },

		data() {
			return {
                feedback: null
            };
		},

		methods: {}
	};
</script>

<style scoped>
</style>