<template>
	<v-footer color="#646464" class="white--text pt-8">
		<v-container>
			<v-row>
				<v-col cols="12" sm="4">
					<img :src="'logo/brand/logo-footer.png' | urlize" width="160px" alt="" />
					<div class="mt-6 text-body-2">
						<p>
							Air <br />
							Southgate House, Southgate Street <br />
							Gloucester, Gloucestershire <br />
							United Kingdom <br />
							GL1 1UD
						</p>
						<p>Tel: <a class="white--text" href="tel:08002945097">0800 294 5097</a></p>

						<!-- Social -->
						<ul class="d-flex pl-0">
							<li class="d-block mr-2">
								<a href="https://www.facebook.com/share/VwuTKueZMPkVJHsj/" target="_blank" rel="noopener noreferrer">
									<v-icon size="24" class="white--text">mdi-facebook</v-icon>
								</a>
							</li>
							<li class="d-block mr-2">
								<a href="https://twitter.com/air_laterlife" target="_blank" rel="noopener noreferrer">
									<v-icon size="24" class="white--text">mdi-twitter</v-icon>
								</a>
							</li>
							<li class="d-block mr-2">
								<a href="https://www.linkedin.com/company/airlaterlife/" target="_blank" rel="noopener noreferrer">
									<v-icon size="24" class="white--text">mdi-linkedin</v-icon>
								</a>
							</li>
						</ul>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<!-- navigation -->
					<div class="d-flex mt-4">
						<div>
							<p class="text-h6 primary--text">
								Air
							</p>
							<ul class="pl-0 white--text">
								<li class="d-block mt-1">
									<router-link class="white--text" to="/air-club">
										Club
									</router-link>
								</li>
								<li class="d-block mt-1">
									<router-link class="white--text" to="/sourcing">
										Sourcing
									</router-link>
								</li>
								<li class="d-block mt-1">
									<router-link class="white--text" to="/air-academy">
										Academy
									</router-link>
								</li>
								<li class="d-block mt-1">
									<router-link class="white--text" to="/ambassadors">
										Ambassadors
									</router-link>
								</li>
								<li class="d-block mt-1">
									<router-link class="white--text" to="/news">
										News
									</router-link>
								</li>
								<li class="d-block mt-1">
									<router-link class="white--text" to="/contact">
										Contact
									</router-link>
								</li>
							</ul>
						</div>
						<div class="ml-10">
							<p class="text-h6 primary--text">
								About Air
							</p>
							<ul class="pl-0 white--text">
								<li class="d-block mt-1">
									<a class="white--text" @click="openSlaveryStatement">Modern Slavery Statement</a>
								</li>
								<li class="d-block mt-1">
									<a class="white--text" @click="openTerms">Terms and Conditions</a>
								</li>
								<li class="d-block mt-1">
									<a class="white--text" @click="openPrivacy">Privacy Notice</a>
								</li>
							</ul>
						</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'text-left': $vuetify.breakpoint.smAndDown }">
					<img :src="'events/MF23Win-mtgeclub.svg' | urlize" width="150px" alt="" />
				</v-col>
			</v-row>
			<div class="mt-8 text-caption white--text">
				<p>
					This site is for professional adviser use only and has not been approved for use with consumers. Air is a Trading Style of Answers in Retirement Limited.
					Answers in Retirement Limited is a Limited Company registered in England and Wales number: 0800 294 5097. Southgate House, Southgate Street, Gloucester,
					Gloucestershire, United Kingdom, GL1 1UD.
				</p>
				<p>© 2023 AIR.</p>
			</div>
		</v-container>
	</v-footer>
</template>

<script>
	import { EventBus } from '@/utils';

	export default {
		name: 'app-layout-default-footer',

		computed: {},

		methods: {
			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			openSlaveryStatement() {
				EventBus.$emit('open-slavery-statement');
			}
		}
	};
</script>
