<!--
	@name common-base-download-items
	@description Show documents on the downloads page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-list two-line class="pa-0">
		<v-list-item
			v-for="(item, index) in orderedItems"
			:key="item.id"
			:download="item.name"
			target="_blank"
			two-line
			class="elevation-2 ga--download"
			:class="[{ 'mt-3': index !== 0 }, getStyle(index)]"
			@click="openResource(item)"
		>
			<v-list-item-avatar class="py-3 pr-3 ma-0 align-self-start" height="auto" width="auto">
				<v-icon large :color="getIcon(item).icon_color">
					{{ getIcon(item).icon }}
				</v-icon>
			</v-list-item-avatar>

			<v-tooltip bottom content-class="text-left" max-width="600" open-delay="400">
				<template #activator="{ on }">
					<v-list-item-content class="py-3 align-self-start" v-on="on">
						<v-list-item-title class="text-subtitle-1 font-weight-bold ma-0 mb-1">
							{{ item.title }}
						</v-list-item-title>
						<v-list-item-subtitle class="text-body-2 ma-0">
							{{ item.description }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</template>
				{{ item.description }}
			</v-tooltip>
			<v-list-item-action>
				<v-list-item-action-text class="font-italic mb-0">
					Posted: {{ item.created | moment('MMMM Do YYYY') }}
				</v-list-item-action-text>
				<div>
					<template v-for="(tag, index) in item.tag">
						<v-chip v-if="displayAllTags || tag.visible" :key="index" label outlined small class="font-weight-medium ma-1 mr-0 secondary secondary--text">
							{{ tag.name }}
						</v-chip>
					</template>
				</div>
			</v-list-item-action>
		</v-list-item>
	</v-list>
</template>

<script>
	import { orderBy } from 'lodash';
	import { mapActions } from "vuex";
	import { ElementTools } from '@/utils';

	export default {
		name: 'common-base-download-items',

		props: {
			items: { type: Array, required: true },
			displayAllTags: { type: Boolean, default: false },
			theme: { type: Array, default: () => ['blue-grey lighten-5', 'grey lighten-4'] },
			type: { type: String, default: 'download' }
		},

		data() {
			return {
				icons: [
					{ icon: 'mdi-link', icon_color: 'green', color: 'green', extension: ['html'] },
					{ icon: 'mdi-file-word', icon_color: 'blue darken-3', color: 'blue lighten-5', extension: ['doc', 'docx'] },
					{ icon: 'mdi-file-pdf-box', icon_color: 'red darken-1', color: 'red lighten-5', extension: ['pdf'] },
					{ icon: 'mdi-file-excel', icon_color: 'green darken-3', color: 'green lighten-5', extension: ['xls', 'xlsx'] },
					{ icon: 'mdi-snowflake', month: [11, 0, 1] },
					{ icon: 'mdi-flower', month: [2, 3, 4] },
					{ icon: 'mdi-weather-sunny', month: [5, 6, 7] },
					{ icon: 'mdi-umbrella', month: [8, 9, 10] }
				]
			};
		},

		computed: {
			/**
			 * @name orderedItems
			 * @description Order items if needed based on type
			 * @return {Array} Ordered items
			 */
			orderedItems() {
				if (this.type === 'newsletter') return orderBy(this.items, ['created'], ['desc']);
				return this.items;
			}
		},

		methods: {
			...mapActions('File', ['getAssetFile']),

			/**
			 * @name download
			 * @description Get presgined url and download document
			 * @param {Object} item
			 */
			async openResource(item) {
				if (item.url) {
					window.open(item.url, '_blank');
					return;
				}

				try {
					const ext = item.name.split('.').pop();
					this.getAssetFile({ s3Key: `download/${item.name}`, filename: `${item.title}.${ext}` })
							.then((response) => {
								if (response.data?.preSignedUrl) window.open(response.data.preSignedUrl);
							});
				} catch(e) {
					ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later');
				}
			},

			/**
			 * @name getStyle
			 * @description Return style
			 * @return {String}
			 */
			getStyle(index) {
				if (!this.theme) return '';
				let num = this.theme.length;
				let key = index % num;
				return this.theme[key];
			},

			/**
			 * @name getIcon
			 * @description Return download icon
			 * @return {String}
			 */
			getIcon(item) {
				if (this.type === 'newsletter') {
					const date = new Date(item.created);
					const month = date.getMonth();
					return this.icons.find((icon) => (icon.month || []).includes(month)) || {};
				}

				// If resource is a link, return relevant icon
				if (item.url) return this.icons.find((icon) => (icon.extension || []).includes('html')) || {};

				const split = item.name.split('.');
				const extension = split[split.length - 1];
				return this.icons.find((icon) => (icon.extension || []).includes(extension)) || {};
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-application .v-list-item__title {
		line-height: 1.75rem;
	}
</style>
