import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Interface
 * @exports Service/App/Interface
 * @description API Interface Service
 * @date 2022/11/07
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Interface {
	/**
	 * @static @name list
	 * @description Get all interface
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('interface/list', payload);
	}
}
