import { $http } from '@/utils';

/**
 * @namespace Service
 * @class UserInvitation
 * @exports Service/Account/UserInvitation
 * @description API UserInvitation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserInvitation {
	/**
	 * @static @name post
	 * @description Add a new user invitation resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/user-invitation`, payload);
	}

	/**
	 * @static @name pendingList
	 * @description Get all pending user invitation with details
	 * @returns {Object} Promise a http response
	 */
	static pendingList() {
		return $http.get('account/user-invitation/pending/list');
	}

	/**
	 * @static @name delete
	 * @description Hard delete user invitation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/user-invitation/${id}`);
	}
}
