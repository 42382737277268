import { render, staticRenderFns } from "./menu-hint.vue?vue&type=template&id=2416fe3f&scoped=true&"
import script from "./menu-hint.vue?vue&type=script&lang=js&"
export * from "./menu-hint.vue?vue&type=script&lang=js&"
import style0 from "./menu-hint.vue?vue&type=style&index=0&id=2416fe3f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2416fe3f",
  null
  
)

export default component.exports