import { $http } from '@/utils';

/**
 * @namespace Service
 * @class KnowledgeBank
 * @exports Service/Cms/KnowledgeBank
 * @description API CMS Knowledge Bank Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class KnowledgeBank {
	/**
	 * @static @name searchCriteria
	 * @description Get Knowledge Bank criteria
	 * @returns {Object} Promise a http response
	 */
	static searchCriteria(payload) {
		return $http.post(`partner/knowledge-bank/criteria`, payload);
	}
}
