<!--
	@name app-client-dashboard-workflow-vulnerability-assessment-activity
	@description Client Vulnerability Assessment Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="loading" type="list-item-three-line" />

		<v-sheet v-else-if="!items?.length">
			<v-row>
				<v-col cols="12">
					<video v-if="introResource" class="d-block ml-10 mb-6" :src="introResource.video" :poster="introResource.poster" controls width="50%" style="float: right" />
					<template v-if="!membership('comentis').approve">
						<p class="text-body-1 font-weight-bold mb-6">
							This tile is an efficient way to show that you've identified vulnerable characteristics and taken proactive steps in providing the necessary support.
						</p>
						<ul class="text-body-1 pl-8 mb-6">
							<li class="mb-2">
								Ability to navigate directly to the health section within the fact find to complete the questions, ensuring enhanced terms are considered.
							</li>
							<li class="mb-2">
								The ability to create your customers individual vulnerability assessment report, using Comentis.
							</li>
							<li class="mb-0">
								The capability to download your latest completed vulnerability assessment report provides a simple and efficient audit trail.
							</li>
						</ul>
						<p class="text-body-1 mb-2">
							Access to the “Co:Mentis” vulnerability assessment tool is available as part of Air Academy membership once you have completed Air Academy's “Client
							Vulnerability” module.
						</p>
						<p class="text-body-1 mb-8">
							Click “Learn more about Air Academy” to contact our team.
						</p>
						<v-btn color="primary" href="mailto:academy.enquiries@airlaterlife.co.uk">
							Learn more about air academy
						</v-btn>
						<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
					</template>
					<template v-else>
						<p class="text-body-1 font-weight-bold mb-6">
							This tile is an efficient way to show that you've identified vulnerable characteristics and taken proactive steps in providing the necessary support.
						</p>
						<ul class="text-body-1 pl-8 mb-6">
							<li class="mb-2">
								Ability to navigate directly to the health section within the fact find to complete the questions, ensuring enhanced terms are considered.
							</li>
							<li class="mb-2">
								The ability to create your customers individual vulnerability assessment report, using Comentis.
							</li>
							<li class="mb-0">
								The capability to download your latest completed vulnerability assessment report provides a simple and efficient audit trail.
							</li>
						</ul>
						<p class="text-body-1 mb-8">
							Click on the intro video for an explanation on how you can navigate AirFlow's Vulnerability Assessment tile and maximise its benefits and features.
						</p>
						<v-btn color="primary" :loading="submitting" @click="createNewAssessment()">
							Get started
						</v-btn>
						<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
					</template>
				</v-col>
			</v-row>
		</v-sheet>

		<v-sheet v-else>
			<v-timeline align-top dense class="pa-0">
				<v-timeline-item v-for="(item, index) in items" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
					<v-row no-gutters>
						<v-col>
							<p class="text-h6 mt-n1 mb-1">
								<span class="font-weight-bold">
									Financial Vulnerability Assessment
									<v-chip
										label
										x-small
										dark
										:color="getStatusColor(item.deleted ? 'Failed' : item.completed ? 'Completed' : 'In Progress')"
										style="position: relative; left: 8px; top: -4px"
									>
										{{ item.deleted ? 'Deleted' : item.completed ? 'Completed' : 'In Progress' }}
									</v-chip>
								</span>
							</p>
							<p class="text-body-2 mb-4">
								Client: {{ item.reference }}
							</p>

							<v-timeline align-top dense class="pt-1">
								<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon color="success">
											mdi-check-bold
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Created: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												{{ item.created | moment('MMM Do YYYY, h:mma') }}
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
								<v-timeline-item v-if="item.deleted" color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon color="error">
											mdi-trash-can
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Deleted: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												{{ item.deleted | moment('MMM Do YYYY, h:mma') }}
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
								<v-timeline-item v-else color="white" small fill-dot class="pb-2 remove-dot">
									<template #icon>
										<v-icon v-if="item.completed" color="success">
											mdi-check-bold
										</v-icon>
										<v-icon v-else color="error">
											mdi-close-thick
										</v-icon>
									</template>
									<v-row no-gutters style="position: relative; top: 2px">
										<v-col class="pa-0" cols="12" sm="2">
											<span class="text-caption plain mb-0 d-block"> Completed: </span>
										</v-col>
										<v-col class="pa-0">
											<span class="text-caption plain mb-0 d-block">
												<template v-if="item.completed">
													{{ item.completed | moment('MMM Do YYYY, h:mma') }}
												</template>
												<template v-else> -- </template>
											</span>
										</v-col>
									</v-row>
								</v-timeline-item>
							</v-timeline>

							<div class="mt-4 mx-n1">
								<v-btn color="primary" class="mx-1" :disabled="item.deleted" @click="openAssessment(item.factFindId)">
									Open assessment
								</v-btn>

								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-btn color="info" class="mx-1" icon :disabled="!item.document" :loading="fileLoading[item.id]" @click="fetchDocument(item)" v-on="on">
											<v-icon> mdi-file-download </v-icon>
										</v-btn>
									</template>
									<span>Download Report</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>
				</v-timeline-item>
			</v-timeline>
		</v-sheet>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { EventBus, ElementTools, PopupWindow } from '@/utils';
	import { orderBy } from 'lodash';

	export default {
		name: 'app-client-dashboard-workflow-vulnerability-assessment-activity',

		components: {},

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, default: null },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				factFinds: null,
				items: null,
				loading: true,
				submitting: false,
				fileLoading: {}
			};
		},

		computed: {
			...mapGetters('Account', ['membership']),
			...mapGetters('AppWorkflow', ['getStatusColor'])
		},

		created() {
			this.fetchItems();
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('FactFind', ['fetchFactFindList']),
			...mapActions('Vulnerability', ['fetchVulnerabilityList', 'submitVulnerability']),
			...mapActions('File', ['getFile']),

			/**
			 * @name onMessage
			 * @description Handle websocket messages
			 * @param message
			 */
			onMessage(message) {
				if (message?.type === 'vulnerabilityAssessmentCreated' && message.item) {
					if (!this.items) this.items = [message.item];
					else this.items.push(message.item);
					this.$emit('workflow-updated');
				} else if (message?.type === 'factFind') this.fetchItems();
			},

			/**
			 * @name fetchItems
			 * @description Fetch affordability list
			 * @param item
			 */
			async fetchItems() {
				const entityIds = [this.client.id];
				if (this.association) entityIds.push(this.association.id);

				try {
					const responses = await Promise.all([await this.fetchFactFindList({ entityIds }), await this.fetchVulnerabilityList({ entityIds })]);

					this.factFinds = responses[0].data.data;
					this.items = responses[1].data;
					this.items = orderBy(this.items, ['created'], ['desc']);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch vulnerability assessments');
				}

				if (!this.items?.length) this.$emit('toggle-intro-resource');
				this.loading = false;
			},

			/**
			 * @name createNewAssessment
			 * @description Create new matter with assessment for all clients
			 */
			async createNewAssessment() {
				// If we don't have an existing fact find for given client(s) open link to create a collection
				if (!this.factFinds?.length) {
					let url = `${process.env.VUE_APP_FACTFIND_ORIGIN}/#/fact-find-create?client1=${this.client.id}`;
					if (this.association) url += `&client2=${this.association.id}`;
					if (this.asset) url += `&property${1}=${this.asset.id}`;
					new PopupWindow().open(url, '_blank');
				} else {
					// Else navigation user to fact-find/family-health/health for existing fact find
					this.openAssessment(this.factFinds[0].id);
				}
			},

			/**
			 * @name openAssessment
			 * @description Redirect user to assessment section on write route
			 */
			openAssessment(factFindId) {
				const url = `${process.env.VUE_APP_FACTFIND_ORIGIN}/#/dashboard?factfindId=${factFindId}&path=family-health/health`;
				new PopupWindow().open(url, '_blank');
			},

			/**
			 * @name fetchDocument
			 * @description Fetch affordability tool document
			 * @param item item that needs updating
			 * @param s3Key
			 */
			async fetchDocument(item) {
				const { id, document } = item;
				this.$set(this.fileLoading, id, true);

				this.getFile({ s3Key: document.s3Key, filename: document.filename ?? `${id}.pdf` })
					.then((response) => {
						if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, id, false));
			},

			/**
			 * @name openChat
			 */
			openChat: function() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}
</style>
