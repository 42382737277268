import { EventBus } from '@/utils';
import { CmsAirMail } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class AirMail {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			airMailItems: null
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setAirMailList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setAirMailList: (state, data) => (state.airMailItems = data.data.data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 *  @name loadAirMailList
			 * @param {function} commit
			 */
			async loadAirMailList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				let airMailItems =  await CmsAirMail.list({ order: { property: 'created', direction: 'desc' } }, domain);
				commit('setAirMailList', airMailItems);
				EventBus.$emit('air-mail-loaded');
				return airMailItems;
			}
		};
	}
}
