import { $http } from '@/utils';

/**
 * @namespace Service
 * @class News
 * @exports Service/Cms/News
 * @description API News Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class News {
	/**
	 * @static @name list
	 * @description Get all news as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static list(domain, cache = true) {
		return $http.post(
			'cms/news/list',
			{},
			{
				cache,
				cachePath: `table-cms-news-${domain}`
			}
		);
	}

	/**
	 * @static @name get
	 * @description Get a single news item by id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`cms/news/${id}`);
	}
}
