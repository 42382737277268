<!--
	@name app-air-mail
	@description AirMail directory page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Air Mail
					</div>
				</div>
			</v-container>
		</v-img>

		<div>
			<v-container>
				<!-- Content list -->
				<div class="pb-8">
					<div v-if="loading">
						<v-row>
							<v-col v-for="i in 3" :key="i" md="4" sm="12" xs="12" class="mt-4">
								<v-skeleton-loader type="card" />
								<v-skeleton-loader type="list-item-three-line" />
							</v-col>
						</v-row>
					</div>

					<v-row v-else>
						<v-col v-for="(item, index) in airMailItems" :key="index" md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img :src="item.image || 'placeholder/gradient.png' | urlize" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air Mail
									</div>

									<div class="mt-4 text-h5 secondary--text line-clamp" :title="item.title">
										{{ item.title }}
									</div>

									<p class="text-body-2 mt-4 line-clamp" style="-webkit-line-clamp: 3">
										{{ item.description | truncate(250) }}
									</p>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4" :to="generateAirMailUrl(item)">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'app-air-mail',

		components: {},

		data() {
			return {};
		},

		computed: {
			...mapState('CmsAirMail', ['airMailItems'])
		},

		created() {
			this.loadAirMailList();
		},

		methods: {
			...mapActions('CmsAirMail', ['loadAirMailList']),

			/**
			 * Generates the URL for the air mail based on the given airMail object.
			 * @param {Object} airMail - The airMail object containing the hash, month, and year.
			 * @returns {string} The generated URL for the air mail.
			 */
			generateAirMailUrl(airMail) {
				return `air-mail/${airMail.hash}/${airMail.month}-${airMail.year}`;
			}
		}
	};
</script>

<style lang="scss" scoped></style>
