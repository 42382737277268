import { $http } from '@/utils';

/**
 * @namespace Service
 * @class KFI
 * @exports Service/System /ContactCampaign
 * @description API ContactCampaign Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class ContactCampaign {
	/**
	 * @static @name post
	 * @description POST method
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('system/contact-campaign', payload);
	}
}
