import { CmsForm } from '@/service';

/**
 * @class @name Form
 * @description Exposes menu store module
 */
export default class Form {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			formList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name form
			 * @description used to get filtered forms from store
			 * @param {Object} state
			 */
			form: (state) => (nameUnique) => {
				return (
					state.formList.find(
						(i) =>
							i.nameUnique.toLowerCase().replace(/\_\w|-\w/g, (m) => m[1].toUpperCase()) === nameUnique.toLowerCase().replace(/\_\w|-\w/g, (m) => m[1].toUpperCase())
					) || {}
				);
			},

			/**
			 * @name forms
			 * @description used to get filtered forms from store
			 * @param {Object} state
			 */
			forms:
				(state) =>
				(...nameUniques) => {
					return nameUniques.map(
						(nameUnique) =>
							state.formList.find(
								(i) =>
									i.nameUnique.toLowerCase().replace(/\_\w|-\w/g, (m) => m[1].toUpperCase()) ===
									nameUnique.toLowerCase().replace(/\_\w|-\w/g, (m) => m[1].toUpperCase())
							) || {}
					);
				},

			/**
			 * @name formFromGroup
			 * @description used to get filtered form from form group
			 * @param {Object} state
			 */
			formFromGroup: (state) => (group, form) => {
				const formGroup = state.formList.find((i) => i.nameUnique === group) || null;
				return formGroup ? formGroup.value.find((i) => i.nameUnique === form).value : [];
			},

			/**
			 * @name getDisplayValuebyId
			 * @description used to get display value of the data to displaying meaningful data to user instead of id
			 * @param {Object} state
			 */
			displayValuebyId: (state, getters) => (nameUnique, fieldName, value) => {
				const form = nameUnique.includes('.') ? getters.formFromGroup(nameUnique.split('.')[0], nameUnique.split('.')[1]) : getters.form(nameUnique);
				const field = form.find((field) => field.name === fieldName);

				return field ? field.options.find((option) => option.value == value).text : '';
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setForm
			 * @description used to form on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setForm: (state, data) => {
				let formList = [...state.formList];
				let idx = formList.reduce((p, c, i) => (c.nameUnique === data.nameUnique ? i : p), -1);
				if (idx >= 0) formList[idx] = data;
				else formList.push(data);
				state.formList = formList;
			},

			/**
			 * @name setFormList
			 * @description used to form on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setFormList: (state, data) => {
				let formList = [...state.formList];
				data.forEach((item) => {
					let idx = formList.reduce((p, c, i) => (c.nameUnique === item.nameUnique ? i : p), -1);
					if (idx >= 0) formList[idx] = item;
					else formList.push(item);
				});
				state.formList = formList;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadForm
			 * @param {function} commit
			 */
			async loadForm({ commit }, name) {
				// disable cache for forms that has dynamic fields
				const cacheDisabledForms = [];
				const response = await CmsForm.get(name.replace(/_/g, '-'), !cacheDisabledForms.includes(name));
				if (!response.error) commit('setForm', response.data);
				return response;
			},

			/**
			 * @name loadFormList
			 * @param {function} commit
			 */
			async loadFormList({ commit }, names) {
				let response = await CmsForm.list(names);
				if (!response.error) commit('setFormList', response.data);
			}
		};
	}
}
