<!--
	@name app-source-result-dialog-websocket-timeout
	@description Dialog component for displaying websocket timeout
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :show-close="false" persistent>
		<template #header>
			Connection Timeout
		</template>

		<template #body>
			<div>
				<p class="text-subtitle-1 mb-0">
					Connection to the dynamic server has timed out. Please retry or switch to the standard server.
				</p>
			</div>
		</template>

		<template #footer>
			<div class="pa-4">
				<v-btn color="success" class="mr-4" @click="refresh">
					Retry
				</v-btn>
				<v-btn text color="primary" class="mr-4" @click="$emit('switch', 'manual')">
					Switch to standard server
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-websocket-timeout',

		components: { CommonDialog },

		data() {
			return {};
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open(title) {
				this.title = title;
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description Close the dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name refresh
			 * @description Refresh the page
			 */
			refresh() {
				window.location.href = window.location.href + '&view=reload';
			}
		}
	};
</script>
