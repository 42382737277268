/**
	@name common-chart-line-chart
	@description Line chart
	@date 2020/05/03
	@license no license
	@copywrite Answers In Retirement Limited
*/

import { Line } from 'vue-chartjs';

export default {
	name: 'common-chart-line-chart',

	extends: Line,

	props: {
		chartData: {
			type: Object,
			required: true
		},

		options: {
			type: Object,
			required: true
		},

		height: {
			type: String,
			default: '100px'
		},

		width: {
			type: String,
			default: 'auto'
		}
	},

	watch: {
		chartData: {
			handler(val) {
				this.renderChart(val, this.options);
			},
			deep: true
		}
	},

	mounted() {
		this.$emit('line-chart-mounted');
		this.renderChart(this.chartData, this.options);
	}
};
