<!--
	@name app-source-full-source
	@description Sourcing Page
	@date 2020/02/27
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex mb-6">
		<common-structure-section class="flex-grow-1 elevation-2">
			<template #header>
				Criteria - Knowledge Bank
				<v-chip x-small label light class="ml-2 mb-1 teal accent-2">
					UPDATED
				</v-chip>
			</template>

			<template #body>
				<div>
					<p class="mb-0 text-body-2">
						Fully integrated, multi-award winning criteria search powered by our software partner <strong>Knowledge Bank</strong>.
						The 3 most popular search items have been listed below for convenience.
						<span class="font-weight-bold">
							Alternatively, <a class="ga--trigger ga--knowledge-bank-opened text-uppercase" data-ga-id="source-form-copy" @click="openDialog">click here</a> to select from a full list of all available criteria items.
						</span>
					</p>
					<v-chip
						class="ga--trigger ga--knowledge-bank-opened text-body-2 mt-3 px-5 py-3 font-weight-regular"
						:class="{ 'success success--text': selectedItemsCount, 'grey--text': !selectedItemsCount }"
						data-ga-id="source-form-selected-count"
						outlined
						label
						small
						@click="openDialog"
					>
						Selected Items:<span class="d-inline-block ml-1">
							{{ selectedItemsCount }}
						</span>
					</v-chip>
					<v-row
						v-intersect="{
							handler: onKBIntersect,
							options: {
								threshold: [0, 0.5, 1.0]
							}
						}"
						style="position: relative"
					>
						<v-col class="grow">
							<v-item-group v-model="item.selectedCriteria" active-class="success" class="my-n2" multiple column>
								<v-item
									v-for="criteria in filteredCriteria"
									:key="criteria.Criteria_Category_ID"
									v-slot="{ active, toggle }"
									active-class=""
									:value="criteria.Criteria_Category_ID"
								>
									<div style="position: relative">
										<v-icon
											v-if="active"
											color="success accent-3"
											style="position: absolute; top: 0px; right: 0px; transform: translateX(35%) translateY(-25%); z-index: 1"
										>
											mdi-check-bold
										</v-icon>
										<v-icon v-else color="error" style="position: absolute; top: 0px; right: 0px; transform: translateX(35%) translateY(-25%); z-index: 1">
											mdi-close-outline
										</v-icon>
										<v-btn block large class="px-4 mt-3 fluid--button" :class="active ? 'success v-btn--active' : ''" @click="toggle">
											{{ criteria.Criteria_Question }}
										</v-btn>
									</div>
								</v-item>
							</v-item-group>
						</v-col>
						<v-fade-transition>
							<span v-show="displayKBHint" class="hint">
								<v-img width="175" height="58" class="mx-auto" :src="'knowledge_bank.svg' | urlize('organisation')" />
								<p class="mt-3 mb-0"><a class="ga--trigger ga--knowledge-bank-opened" data-ga-id="source-form-hint" @click="openDialog">Click here</a> to select from a full list of all available criteria items</p>
							</span>
						</v-fade-transition>
					</v-row>
				</div>
			</template>
		</common-structure-section>

		<common-dialog-knowledge-bank-criteria
			ref="knowledgeBankCriteria"
			:default-criteria="item.selectedCriteria"
			:submit-button-text="'Confirm selection'"
			:close-action-button-text="'Confirm'"
			:submit-intercept="true"
			:minimum-items="0"
			@close-action-confirmed="criteriaSubmitted"
			@search-criteria-submitted="criteriaSubmitted"
		/>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogKnowledgeBankCriteria from '@/component/common/dialog/knowledge-bank-criteria';
	import criteriaList from '@/component/common/dialog/criteria.json';

	export default {
		name: 'app-source-form-product',

		components: {
			CommonDialogKnowledgeBankCriteria,
			CommonStructureSection
		},

		props: {
			process: { type: Object, default: null },
			item: { type: Object, required: true, default: () => ({}) }
		},

		data() {
			return {
				criteriaList: criteriaList,
				KBIsIntersecting: false,
				highlightedCriteria: ['1499', '1625', '1337']
			};
		},

		computed: {
			/**
			 * @name filteredCriteria
			 * @description TODO
			 * @return {Array}
			 */
			filteredCriteria: function() {
				if (!this.criteriaList) return [];
				//return this.criteriaList;
				return this.criteriaList.filter((item) => this.highlightedCriteria.includes(item.Criteria_Category_ID));
			},

			displayKBHint() {
				return this.KBIsIntersecting;
			},

			/**
			 * @name selectedItemsCount
			 * @description TODO
			 * @return {Array}
			 */
			selectedItemsCount: function() {
				return this.item.selectedCriteria?.length;
			}
		},

		watch: {},

		created() {},

		methods: {
			/**
			 * @name onKBIntersect
			 */
			onKBIntersect(entries) {
				this.KBIsIntersecting = entries[0].intersectionRatio >= 0.5;
			},

			/**
			 * @name openDialog
			 */
			openDialog() {
				this.$refs.knowledgeBankCriteria.open();
			},

			/**
			 * @name criteriaSubmitted
			 */
			criteriaSubmitted(payload) {
				this.$refs.knowledgeBankCriteria.close();
				this.item.selectedCriteria = payload.selectedCriteria;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/settings/_variables';

	@media #{map-get($display-breakpoints, 'lg-and-up')} {
		.hint {
			visibility: visible !important;
		}
	}

	.hint {
		visibility: hidden;
		text-align: center;
		position: absolute;
		right: 0;
		top: 16px;
		max-width: 350px;
		padding: 12px 24px;
		color: #000;
		border-radius: 3px;
		background-color: rgba(255, 255, 255, 0.5);
		transform: translateX(100%) translateX(+38px);
	}

	.fluid--button {
		height: 100% !important;
		min-height: 48px !important;
		white-space: normal !important;

		::v-deep .v-btn__content {
			flex: 1 1 auto !important;
		}
	}
</style>
