<!--
	@name app-client
	@description Clients page
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Clients
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" v-on="on" @click="openAddClientDialog">
								<v-icon large>
									mdi-account-network
								</v-icon>
							</v-btn>
						</template>
						<span>Add Client</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row class="mb-10">
			<v-col class="py-0" cols="12">
				<div class="border-effect border-effect--lg ml-6" style="max-width: 1200px;">
					<v-skeleton-loader v-if="!content('client').text" type="list-item-two-line" />
					<template v-else>
						<p class="text-h5 mb-2 font-weight-regular">
							Manage all your clients <span class="font-weight-bold">in one place</span>
						</p>
						<p class="text-body-1 mb-0" @click="handleDynamicClick">
							<common-base-dynamic-content :html-content="content('client').text.introduction" />
						</p>
						<v-alert class="mt-4" outlined text colored-border border="left" color="accent" type="info">
							<strong>Looking for AirFlow?</strong> Open an existing client record, or add a new client, to get started.
						</v-alert>
					</template>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="py-0" cols="12">
				<v-sheet class="pa-6 mb-0">
					<app-client-list ref="clients" @add-client="openAddClientDialog" />
				</v-sheet>
			</v-col>
		</v-row>

		<app-client-dialog-add-client ref="addClientDialog" @load-clients="loadClients" />
		<common-dialog-tutorial v-if="factFindTutorial" ref="tutorialDialog" :tutorial="factFindTutorial" />
		<common-dialog-confirm ref="confirm" />
		<common-dialog ref="campaign">
			<template #header>
				Air's Safer Tracks Summer Challenge!
			</template>
			<template #body>
				<p class="text-body-1 mb-0">
					To access Air's Navigator Tool, click the <v-icon color="primary" style="position: relative; top: -2px;">
						mdi-chevron-right-box
					</v-icon> button next to the desired client and select <strong>Affordability</strong> from the options.
					If you have any questions, or require any additional support, <a @click="openChat"> click here to chat with our team. </a>
				</p>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';
	import { mapActions, mapGetters } from 'vuex';
	import { merge } from 'lodash';
	import AppClientDialogAddClient from '@/component/app/client/dialog/add-client';
	import AppClientList from '@/component/app/client/list';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import CommonDialogTutorial from '@/component/common/dialog/tutorial';
	import CommonBaseDynamicContent from '@/component/common/base/dynamic-content';

	export default {
		name: 'app-client',

		components: { AppClientDialogAddClient, AppClientList, CommonDialog, CommonDialogConfirm, CommonDialogTutorial, CommonBaseDynamicContent },

		data() {
			return {
				factFindTutorial: null,
				carouselModel: 0
			};
		},

		computed: {
			...mapGetters('CmsContent', ['content']),

			factFindUrl() {
				return `${process.env.VUE_APP_FACTFIND_ORIGIN}`;
			},

			isSummerCampaign() {
				return this.$route.query && this.$route.query.utm_id === 'summer-2024-01';
			}
		},

		created() {
			this.loadContent('client');
		},

		mounted() {
			if (this.isSummerCampaign) this.$refs.campaign.open();
		},

		methods: {
			...mapActions('CmsContent', ['loadContent']),
			...mapActions('AppClient', ['loadClient']),

			/**
			 * @name loadClients
			 * @description TODO
			 */
			loadClients() {
				this.$refs.clients.loadClients();
			},

			/**
			 * @name openClientDashboard
			 * @description TODO
			 */
			openClientDashboard() {
				this.property = null;
			},

			/**
			 * @name setItemLoadingStatus
			 * @description set item loading state
			 * @param itemIndex index of item that needs updating
			 * @param status loading status
			 */
			setItemLoadingStatus(itemIndex, status) {
				let item = merge(this.clientList[itemIndex], { loading: status });
				this.clientList.splice(itemIndex, 1, item);
			},

			/**
			 * @name openAddClientDialog
			 * @description TODO
			 */
			openAddClientDialog() {
				this.$refs.addClientDialog.open();
			},

			/**
			 * @name handleDynamicClick
			 * @description Handle clicks on dynamically created alert boxes
			 * @param {Object} e
			 */
			handleDynamicClick(e) {
				if (e.target.matches('.add-client, .add-client *')) this.openAddClientDialog();
				if (e.target.matches('.click-writeroute, .click-writeroute *')) window.open(this.factFindUrl, '_blank');
				EventBus.$emit('handle-dynamic-click', e);
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>
