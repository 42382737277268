import { Process } from '@/service';

/**
 * @class @name MatterHelper
 * @description Create a display ready array of activity items
 */

export class MatterHelper {
	/**
	 * @name groupKfiProducts
	 * @param {Object} matter
	 */
	async groupKfiProducts(matter) {
		let products = [];
		let latestProcess = undefined;

		// Sort processes by created date
		matter.process.sort(function(a, b){
			return new Date(b.created) - new Date(a.created);
		});

		const kfiProcessIds = matter.process.map((p) => p.id);
		const sourcingProcesses = await Process.listSourcingProcesses({ kfiProcessIds });

		matter.process.map((process) => {
			let valid = false;
			const sourcingProcess = (sourcingProcesses?.data?.data || []).find((sp) => sp.id === process.id);
			const currentProcess = {
				asset: sourcingProcess?.sourcingProcess?.data?.asset,
				clients: sourcingProcess?.sourcingProcess?.data?.clients
			};

			// Set the first sorted process as the latest process
			if(!latestProcess) latestProcess = currentProcess;

			// The current process must contain the same data as the latest process for an unsubmitted KFI to be eligible for submission
			if(JSON.stringify(currentProcess) === JSON.stringify(latestProcess)) valid = true;

			(process.iteration || []).map((iteration) => {
				products.push({ ...iteration, processId: process.id, valid });
			});
		});

		return products;
	}
}
