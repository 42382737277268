import { CmsMenu } from '@/service';

/**
 * @class @name menu
 * @description Exposes menu store module
 */
export default class Menu {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			frontMenu: null,
			authedMenu: null
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setMenuList
			 * @description used to menus on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setFrontMenu: (state, data) => (state.frontMenu = data),

			/**
			 * @name setMenuList
			 * @description used to menus on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setAuthedMenu: (state, data) => (state.authedMenu = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadFrontMenu
			 * @description used to fetch app menus
			 * @param {function} commit
			 */
			async loadFrontMenu({ commit }) {
				let response = await CmsMenu.get('front');
				commit('setFrontMenu', response.data);
			},

			/**
			 * @async @name loadAuthedMenu
			 * @description used to fetch app menus
			 * @param {function} commit
			 */
			async loadAuthedMenu({ commit }) {
				let response = await CmsMenu.get('advisor');
				commit('setAuthedMenu', response.data);
			}
		};
	}
}
