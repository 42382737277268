<!--
	@name app-download-asset
	@description Show download assets page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Resources
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<p class="text-body-1 mb-2 font-weight-regular">
						Your download should begin automatically, otherwise please click on the link below:
					</p>
					<p class="text-body-1 mb-0">
						<a
							class="font-weight-medium"
							@click="forceDownload"
						>{{ asset.name }}</a>
					</p>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { $http } from '@/utils';

	export default {
		name: 'app-download-assets',

		data() {
			return {
				asset: {}
			};
		},

		created() {
			this.forceDownload();
		},

		methods: {
			/**
			 * @name forceDownload
			 * @description Download the file in the browser
			 * @param {Object} item
			 */
			 async forceDownload() {
				$http.get(`cms/asset/${this.$route.params.assetId}`).then(async(response) => {
					this.asset = response.data;
					let s3Response = await fetch(this.asset.directlink, {responseType: 'arraybuffer'});
				    const file = await s3Response.blob();
					const a = document.createElement('a');
					a.href = URL.createObjectURL(file);
					const fileExt = this.asset.s3key.split('.').pop();
					a.download = `${encodeURIComponent(this.asset.name.toLowerCase().replace(/\s/g, "-").replace(/-+/g, "-").replace(/[^a-å0-9-]/gi, ""))}${fileExt ? `.${fileExt}` : ''}`;
					a.click();
					a.remove();
				});
			}
		}
	};
</script>
