<!--
	@name app-insights-hub-content-detail
	@description Insights Hub Content Detail
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div>
			<v-container>
				<div class="pb-8">
					<div id="background" />

					<v-container style="max-width: 950px; position: relative; z-index: 4">
						<v-sheet class="pa-14 mt-12">
							<!-- Breadcrumbs -->
							<v-breadcrumbs :items="breadcrumbs" class="pl-0">
								<template #divider>
									<v-icon>mdi-chevron-right</v-icon>
								</template>
							</v-breadcrumbs>

							<div class="text-h4 font-weight-light">
								Later Life Lending Awards shortlist announced!
							</div>

							<div class="text-body-2 font-weight-bold info--text mt-4">
								Air News
							</div>

							<div class="text-body-1 mt-4">
								Posted on 18 September 2023
							</div>

							<!-- image here -->
							<div class="mt-8">
								<img src="https://airlaterlife.co.uk/app/uploads/2023/09/Shortlist-header.png" width="100%" alt="" />
							</div>

							<!-- html content here -->
							<div class="mt-8">
								<p>
									Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering dedication
									to improving the landscape of later life lending…
								</p>
								<p>
									Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering dedication
									to improving the landscape of later life lending…
								</p>
								<p>
									Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering dedication
									to improving the landscape of later life lending…
								</p>
								<p>
									Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering dedication
									to improving the landscape of later life lending…
								</p>
							</div>

							<v-divider class="mt-8" />

							<div class="d-flex pt-6">
								<a href=""> <v-icon color="secondary">mdi-arrow-left</v-icon></a>
								<v-spacer />
								<div class="d-flex">
									<div class="text-body-2 font-weight-black mr-2">
										Share:
									</div>
									<ul class="d-flex pl-0">
										<li class="d-block mr-2">
											<a href="">
												<v-icon size="24" color="secondary">mdi-facebook</v-icon>
											</a>
										</li>
										<li class="d-block mr-2">
											<a href="">
												<v-icon size="24" color="secondary">mdi-twitter</v-icon>
											</a>
										</li>
										<li class="d-block mr-2">
											<a href="">
												<v-icon size="24" color="secondary">mdi-linkedin</v-icon>
											</a>
										</li>
										<li class="d-block mr-2">
											<a href="">
												<v-icon size="24" color="secondary">mdi-whatsapp</v-icon>
											</a>
										</li>
										<li class="d-block mr-2">
											<a href="">
												<v-icon size="24" color="secondary">mdi-link-variant</v-icon>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</v-sheet>

						<!-- Related articles -->
						<div>
							<div class="text-h5 text-center">
								Related articles
							</div>
							<v-row class="mt-4">
								<v-col md="4" sm="12" xs="12" class="mt-4">
									<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
										<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Altz-1024x574.jpg" min-height="200px" max-height="200px" />

										<div class="pa-4">
											<div class="text-body-2">
												Air News
											</div>

											<div class="mt-4 text-h5 secondary--text">
												Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in
												Summer KFI Campaign
											</div>

											<div class="text-body-2 mt-4">
												21 September 2023 – Air, the leading later life lending platform, has announced that it will mark World Alzheimer's Day...
											</div>
										</div>

										<v-spacer />
										<div>
											<v-btn color="info" small class="ml-4 mb-4">
												Read more
											</v-btn>
										</div>
									</v-card>
								</v-col>
								<v-col md="4" sm="12" xs="12" class="mt-4">
									<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
										<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Shortlist-header.png" min-height="200px" max-height="200px" />

										<div class="pa-4">
											<div class="text-body-2">
												Air News
											</div>

											<div class="mt-4 text-h5 secondary--text">
												Later Life Lending Awards shortlist announced!
											</div>

											<div class="text-body-2 mt-4">
												Our awards shortlist represents firms and individuals who have not only demonstrated excellence, but have also displayed unwavering
												dedication to...
											</div>
										</div>

										<v-spacer />
										<div>
											<v-btn color="info" small class="ml-4 mb-4">
												Read more
											</v-btn>
										</div>
									</v-card>
								</v-col>
								<v-col md="4" sm="12" xs="12" class="mt-4">
									<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
										<v-img src="https://airlaterlife.co.uk/app/uploads/2023/07/WLT-1024x551.jpg" min-height="200px" max-height="200px" />

										<div class="pa-4">
											<div class="text-body-2">
												Air News
											</div>

											<div class="mt-4 text-h5 secondary--text">
												Air launches a White Label Calculator to support customer conversations
											</div>

											<div class="text-body-2 mt-4">
												27 July 2023: With Consumer Duty front of mind, Air has launched a new retained equity and repayments calculator (click...
											</div>
										</div>

										<v-spacer />
										<div>
											<v-btn color="info" small class="ml-4 mb-4">
												Read more
											</v-btn>
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</v-container>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-insights-hub-content-detail',

		components: {},

		data() {
			return {
				breadcrumbs: [{ text: 'Insights Hub', exact: true, to: '/air-later-life/insights-hub' }, { text: 'Air News', exact: true, to: '/air-later-life/insights-hub' }, {}]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>

<style lang="scss" scoped>
	#background {
		background-image: linear-gradient(to right, #470048, #1b78d8);
		width: 100%;
		height: 600px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
</style>
