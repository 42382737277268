<!--
	@name common-structure-directory
	@description Video Player
	@date 2020/04/11
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #body>
			<video v-if="source" width="100%" autoplay controls playsinline controlsList="nodownload" oncontextmenu="return false;">
				<source :src="source" type="video/mp4" />
			</video>
		</template>
	</common-dialog>
</template>

<script>
	import { EventBus } from '@/utils';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-dialog-video-player',

		components: { CommonDialog },

		data() {
			return {
				source: null
			};
		},

		created() {
			EventBus.$on('common-dialog-closed', this.close);
		},

		destroyed() {
			EventBus.$off('common-dialog-closed', this.close);
		},

		methods: {
			/**
			 * @name open
			 * @description set source of the video and open dialog
			 */
			open(value) {
				this.source = value;
				this.$refs.dialog.open();
			},

			close() {
				this.source = null;
			}
		}
	};
</script>

<style scoped></style>
