<!--
	@name app-client-dialog-add-client
	@description Add New Client
	@date 2020/07/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Add Client
		</template>

		<template #body>
			<div v-if="!form('add_client').value" class="text-center">
				<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
			</div>

			<dynamic-form v-else ref="dynamicForm" :form-schema="form('add_client').value" :form-data="data" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-client-dialog-add-client',

		components: { CommonDialog, DynamicForm },

		data() {
			return {
				data: {},
				submitting: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('add-client');

			// EventBus.$on('open-property', (property) => this.openProperty(property));
		},

		methods: {
			...mapActions('AppClient', ['addClient']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.data = {};
				if (this.$refs.dynamicForm) this.$refs.dynamicForm.$refs.observer.reset();
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description submit new client details
			 */
			async submit(data) {
				this.submitting = true;

				const client = {
					genderId: data['clients.0.genderId'],
					nameTitleId: data['clients.0.nameTitleId'],
					nameFamily: data['clients.0.nameFamily'],
					nameGiven: data['clients.0.nameGiven'],
					nameMiddle: data['clients.0.nameMiddle']
				};
				if (data.secondLife)
					client.association = {
						genderId: data['clients.1.genderId'],
						nameTitleId: data['clients.1.nameTitleId'],
						nameFamily: data['clients.1.nameFamily'],
						nameGiven: data['clients.1.nameGiven'],
						nameMiddle: data['clients.1.nameMiddle']
					};

				this.addClient(client)
					.then((data) => {
						ElementTools.fireNotification(this.$el, 'success', 'New client added successfully');
						this.$emit('load-clients');
						this.$router.push(`/client/${data.data[0].id}`);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to add client'))
					.finally(() => {
						this.submitting = false;
						this.$refs.dialog?.close();
					});
			}
		}
	};
</script>

<style scoped></style>
