<!--
	@name app-common-flood-report
	@description Generate a flood report from a postcode
	@date 2020/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-btn :small="buttonSize == 'small'" :large="buttonSize == 'large'" color="blue darken-1" :loading="loading" @click="initGenerateFloodReport({ postcode: postcode })">
			<v-icon left>
				mdi-weather-pouring
			</v-icon>
			Download Flood Report PDF
		</v-btn>

		<p class="grey--text text--darken-1 text-caption d-block mt-2 mb-0">
			The flood report is generated via the Environment Agency using their flood map for planning service.
			<b>Please note, the report should be used for guidance purposes only.</b>
		</p>
	</div>
</template>

<script>
	import { ElementTools, PopupWindow } from '@/utils';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-common-flood-report',

		components: {},

		props: {
			buttonSize: { type: String, required: false, default: 'small' },
			newChip: { type: Boolean, required: false, default: true },
			postcode: { type: String, required: true }
		},

		data() {
			return {
				loading: null
			};
		},

		methods: {
			...mapActions('AppCalculator', ['generateFloodReport']),

			async initGenerateFloodReport(params) {
				this.loading = true;
				ElementTools.fireNotification(this.$el, 'info', { text: 'Your flood report PDF is being prepared', timeout: 0 });

				this.generateFloodReport(params)
					.then((response) => {
						let newWin = new PopupWindow().open(response.data);
						if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
							ElementTools.fireNotification(
								this.$el,
								'warning',
								`Your flood report PDF is now ready; however, your browser has prevented the document from being downloaded automatically.. <a href="${response.url}" target="_blank" class="white--text text-decoration-underline">Click here</a> to proceed.`
							);
						} else ElementTools.fireNotification(this.$el, 'success', 'Your flood report PDF has been downloaded');
					})
					.catch(() =>
						ElementTools.fireNotification(this.$el, 'error', {
							text: 'An unexpected error occurred whilst generating your flood report. Please check that the postcode is correct, as an invalid postcode will result in a failed response. If this problem continues, please contact our support team',
							timeout: 0,
							close: true
						})
					)
					.finally(() => (this.loading = false));
			}
		}
	};
</script>
