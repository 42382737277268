<!--
	@name common-structure-directory-selection
	@description Displays selected directory
	@date 2020/04/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-card color="white">
			<v-row no-gutters>
				<v-col cols="12" class="pb-6">
					<div
						v-if="selection.latitude && selection.longitude"
						v-show="paintMap('map-id-' + selection.id, selection.latitude, selection.longitude)"
						:ref="'map-id-' + selection.id"
						class="map"
					/>
					<v-card-title class="text-h5 font-weight-bold pa-6 pb-4">
						{{ selection.title }}
					</v-card-title>
					<v-card-subtitle v-if="selection.subtitle" class="text-subtitle-1 px-6 pt-1 pb-4">
						{{ selection.subtitle }}
					</v-card-subtitle>
					<v-card-text class="px-6 pb-0 detail-chips">
						<v-chip v-if="selection.phone" class="text-body-2" pill>
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-phone-classic
								</v-icon>
							</v-avatar>
							{{ selection.phone }}
						</v-chip>

						<v-chip v-if="selection.mobile" class="text-body-2" pill>
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-phone
								</v-icon>
							</v-avatar>
							{{ selection.mobile }}
						</v-chip>

						<v-chip v-if="selection.email" class="text-body-2" pill :href="`mailto:${selection.email}`">
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-email
								</v-icon>
							</v-avatar>
							{{ selection.email }}
						</v-chip>

						<v-chip v-if="selection.website" class="text-body-2" pill :href="selection.website">
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-link
								</v-icon>
							</v-avatar>
							Visit website
						</v-chip>

						<v-chip v-if="selection.address" class="text-body-2" pill>
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-map
								</v-icon>
							</v-avatar>
							{{ selection.address }}
						</v-chip>

						<v-chip v-if="selection.graduated" class="text-body-2" pill>
							<v-avatar left>
								<v-icon small class="teal lighten-2" dark>
									mdi-certificate
								</v-icon>
							</v-avatar>
							Air Later Life Professional
						</v-chip>
					</v-card-text>
				</v-col>
			</v-row>

			<v-card-text class="pa-6 pt-0">
				<p v-if="!selection.detail && noDetailsMessage" class="text-body-2 font-weight-bold pa-0 mb-0 white-space--pre-line">
					{{ noDetailsMessage }}
				</p>
				<p v-if="selection.detail" class="text-body-1 pa-0 mb-0 mt-4 white-space--pre-line">
					{{ selection.detail }}
				</p>
				<div v-if="selection.footnotes1 && selection.footnotes1.length">
					<p v-if="footnotesHeading" class="text-body-2 font-weight-bold pa-0 mb-0 mt-4">
						{{ footnotesHeading }}
					</p>
					<ul class="text-body-2">
						<li v-for="(item, key) in selection.footnotes1" :key="key">
							{{ item }}
						</li>
					</ul>
				</div>
				<div v-if="selection.footnotes2 && selection.footnotes2.length" class="mt-2 ml-2">
					<p v-for="(item, key) in selection.footnotes2" :key="key" class="mb-0 mt-4">
						{{ item }}
					</p>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import { googleMap } from '@/utils';

	export default {
		name: 'common-structure-directory-selection',

		props: {
			selection: { type: Object, required: true },
			noDetailsMessage: { type: String, required: true },
			footnotesHeading: { type: String, required: true }
		},

		computed: {},

		methods: {
			/**
			 * @name paintMap
			 * @description Paint the map to the element
			 * @return {Boolean} Show the element once painted
			 */
			paintMap(ref, lat, long) {
				setTimeout(() => {
					const gm = new googleMap();

					gm.then(() => {
						let target = this.$refs[ref];
						if (target == null) throw new Error('Error');

						let latLng = { lat: lat, lng: long };
						let map = new window.google.maps.Map(target, {
							center: latLng,
							zoom: 15
						});

						new window.google.maps.Marker({
							position: latLng,
							map: map,
							title: target.dataset.title
						});
					});
				}, 50);

				return true;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.map {
		width: 100%;
		height: 10rem;
	}

	.detail-chips {
		margin: -8px;
		.v-chip {
			margin: 8px;
		}
	}

	.white-space--pre-line {
		white-space: pre-line;
	}
</style>
