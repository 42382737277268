import moment from 'moment';
import numeral from 'numeral';

import ProcessHelper from '@/utils/ProcessHelper';
import ActionHelper from '@/utils/ActionHelper';

/**
 * @class @name MatterHelper
 * @description Matter common methods
 */
export default class MatterHelper {
	/**
	 * @name _getSourcingProcessAndIteration
	 * @param {Object} matter
	 * @param {String} iterationId
	 * @return {Object} The sourcing process and iteration
	 */
	static _getSourcingProcessAndIteration(matter, iterationId) {
		const sourcingProcesses = matter?.process?.filter((p) => p?.processTypeNameUnique === 'sourcing');
		const process = sourcingProcesses?.find((p) => p?.iteration?.find((i) => i?.id === iterationId));
		const iteration = process?.iteration?.find((i) => i?.id === iterationId);
		return { process, iteration };
	}

	/**
	 * @name transformMatter
	 * @param {Object} matter
	 * @return {Object} The transformed matter for cases/property dashboard page
	 */
	static transformMatter(matter, options) {
		// if type application get action created and hard code status
		const mapData = (type, matter, process, iteration, action) => {
			const clients = matter.matterEntity?.filter((e) => e.entity === 'client');
			const asset = matter.matterEntity?.find((e) => e.entity === 'asset');

			const meta = {
				created: process.created,
				modified: process.created,
				matterId: matter.id,
				matterTypeNameUnique: matter.matterTypeNameUnique,
				processId: process.id,
				processTypeId: process.processTypeId,
				processTypeNameUnique: process.processTypeNameUnique,
				iterationId: iteration?.id,
				type,
				status: action ? (type === 'application' ? 'Product Application Submitted' : ActionHelper.getLatestStatusFromActions(action)) : undefined,
				// case page fetches client/assets data excluding matterEntity
				client: {
					id: clients?.[0]?.entityId,
					name:
						clients?.reduce((client, entity) => {
							if (entity.data) {
								if (client.length) client += ' and ';
								client += `${entity.data.nameGiven} ${entity.data.nameFamily}`;
							}
							return client;
						}, '') || undefined
				},
				property: { id: asset?.entityId, ...asset?.data }
			};

			let sourcingProcess, sourcingIteration;
			if (['sourcing'].indexOf(type) > -1) (sourcingIteration = iteration), (sourcingProcess = process);
			else if (['key_facts_illustration', 'application'].indexOf(type) > -1) {
				const sourcing = this._getSourcingProcessAndIteration(matter, process.action?.iteration?.id);
				sourcingProcess = sourcing?.process;
				sourcingIteration = sourcing?.iteration;
			}

			const documents = [];
			//Add the research PDF where relevant, but only if we have a sourcing iteration
			if (['sourcing', 'key_facts_illustration', 'application'].indexOf(type) > -1 && sourcingProcess && sourcingIteration) {
				documents.push({
					iterationId: sourcingIteration?.id,
					processId: sourcingProcess.id,
					s3Key: sourcingIteration?.data?.s3Key,
					filename: `Research PDF ${sourcingIteration?.id}.pdf`,
					type: 'researchPdf'
				});
			}

			const kfiRequestSubmitted = action?.some((a) => a.type === 'status' && a?.data?.status === 'KFI Request Submitted');
			const kfiDocumentRecieved = action?.some((a) => a?.type === 'status' && a?.data?.status == 'KFI Received');
			if (['key_facts_illustration', 'application'].indexOf(type) > -1 && kfiDocumentRecieved && action.find((a) => a.type === 'DocumentUpload')) {
				documents.push({
					s3Key: action.find((a) => a.type === 'DocumentUpload')?.data?.s3Key,
					filename: `Key Facts Illustration ${iteration.id}.pdf`,
					type: 'keyFactsIllustration'
				});
				action.filter((a) => a.type === 'AdditionalDocumentUpload')?.forEach((doc) => {
					documents.push({
						s3Key: doc.data?.s3Key,
						title: doc?.data?.title || 'Supporting document',
						filename: `${doc.id}.${doc.data?.s3Key?.split(".").pop()}`,
						type: 'supportingDocument'
					});
				});
			}

			switch (type) {
				case 'sourcing':
					return {
						...meta,
						caseType: 'Lifetime Mortgage',
						created: iteration?.created,
						modified: iteration?.created,
						statusColour: 'warning black--text',
						icon: {
							color: 'amber lighten-2',
							icon: 'mdi-account-circle',
							iconColor: 'pink'
						},
						data: {
							documents,
							title: `Last Submitted ${moment(iteration?.created).format('MMMM Do YYYY')}`,
							subtitle:
								process?.data?.showMaximumAvailable || process?.data?.initialAdvance
									? `Requested Advance: ${process.data.showMaximumAvailable ? 'Maximum Available' : `£${numeral(process.data.initialAdvance).format('0,0')}`}`
									: undefined
						}
					};

				case 'key_facts_illustration':
					return {
						...meta,
						caseType: 'Retirement Lending',
						caseReference: iteration?.data?.caseReference || null,
						statusColour: kfiDocumentRecieved ? 'green' : 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						created: iteration?.created,
						modified: iteration?.created,
						action,
						data: {
							...process.data,
							documents,
							product: iteration?.data,
							title: iteration?.data?.name,
							subtitle: asset?.data ? `${asset?.data?.address1}, ${asset?.data?.postcode}}` : undefined,
							kfiRequestSubmitted,
							kfiDocumentRecieved
						}
					};

				case 'application':
					return {
						...meta,
						caseType: 'Retirement Lending',
						caseReference: iteration?.data?.caseReference || null,
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						created: action.find((a) => a?.type === 'SubmitApplication')?.created,
						modified: action.find((a) => a?.type === 'SubmitApplication')?.created,
						action,
						data: {
							...process.data,
							documents,
							product: iteration?.data,
							title: iteration?.data?.name,
							subtitle: asset?.data ? `${asset?.data?.address1}, ${asset?.data?.postcode}}` : undefined,
							kfiDocumentRecieved
						}
					};

				case 'conveyancing':
					return {
						...meta,
						caseType: 'Solicitors',
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						data: process.data
					};

				case 'will':
					return {
						...meta,
						caseType: 'Will',
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						data: process.data
					};

				case 'power_of_attorney':
					return {
						...meta,
						caseType: 'LPA',
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						data: process.data
					};

				case 'care_plans':
					return {
						...meta,
						caseType: 'Care Plans',
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						data: process.data
					};

				case 'home_reversion_plan':
					return {
						...meta,
						caseType: 'Home Reversion Plan',
						statusColour: 'warning black--text',
						icon: {
							color: 'red accent-4',
							icon: 'mdi-account-circle',
							iconColor: 'light-green'
						},
						data: process.data
					};
			}
		};

		return matter
			.reduce((list, matter) => {
				if (['conveyancing', 'will', 'care_plans', 'power_of_attorney', 'home_reversion_plan'].indexOf(matter.matterTypeNameUnique) > -1) {
					list.push(
						mapData(matter.matterTypeNameUnique, matter, matter?.process?.[0], matter?.process?.[0]?.iteration?.[0], matter?.process?.[0]?.iteration?.[0]?.action)
					);
				}

				// split lifetime mortgage matter into different sections by process types
				if (matter.matterTypeNameUnique === 'lifetime_mortgage') {
					if (options?.page === 'case') {
						// case manager only shows
						const latestSourcingProcess = matter?.process?.reduce((latestProcess, process) => {
							if (process.processTypeNameUnique !== 'sourcing') return latestProcess;
							if (latestProcess) latestProcess = Date.parse(latestProcess.created) > Date.parse(process.created) ? latestProcess : process;
							else latestProcess = process;
							return latestProcess;
						}, {});
						const iteration = ProcessHelper.getActiveSourcingIteration(latestSourcingProcess);
						list.push(mapData('sourcing', matter, latestSourcingProcess, iteration));
					} else {
						(matter?.process?.filter((p) => p.processTypeNameUnique === 'sourcing') || []).forEach((process) => {
							const iteration = ProcessHelper.getActiveSourcingIteration(process);
							if (iteration) list.push(mapData('sourcing', matter, process, iteration));
						});
					}
					(matter?.process?.filter((p) => p.processTypeNameUnique === 'key_facts_illustration') || []).forEach((process) =>
						process.iteration?.forEach((iteration) => {
							if (!iteration?.action?.some((action) => action.type === 'SubmitApplication'))
								list.push(mapData('key_facts_illustration', matter, process, iteration, iteration?.action));
						})
					);
					// find applications through kfi submissions, presence of SubmitApplication action
					(matter?.process?.filter((p) => p.processTypeNameUnique === 'key_facts_illustration') || []).forEach((process) => {
						process?.iteration?.forEach((iteration) => {
							if (iteration?.action?.some((action) => action.type === 'SubmitApplication'))
								list.push(mapData('application', matter, process, iteration, iteration?.action));
						});
					});
				}
				return list;
			}, [])
			.sort((a, b) => new Date(b.modified) - new Date(a.modified));
	}
}
