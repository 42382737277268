<!--
	@name app-air-club-ambassador
	@description Ambassador tab view for the air academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-directory class="pa-6" :items="list" :page-size="6" show-actions show-action-phone show-action-email show-action-website paginate>
		<template #title>
			Ambassadors
		</template>

		<template #detail>
			Air Academy is financially subsidised and supported by a number our ambassador community.
			The providers below recognise the value that you bring to the later life financial sector and this is reflected in their products enhancements for members and their valued participation in specialist events and webinars.
			Click on any provider logo to find out more.
		</template>
	</common-structure-directory>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import CommonStructureDirectory from '@/component/common/structure/directory';
	import { orderBy } from 'lodash';

	export default {
		name: 'app-air-club-ambassador',

		components: { CommonStructureDirectory },

		computed: {
			...mapGetters('AccountOrganisation', { ambassadorList: 'mappedAmbassadorList' }),

			list() {
				let ids = [
					'0c9d1368-798a-4db6-9011-4021871dd55b',
					'2b2ca9c1-7f2a-427b-aa16-bc528221bfe5',
					'50ce465a-863c-4779-9def-87d6ea114eca',
					'bb9dc49d-472b-4cb8-9ca4-baa4185139d6'
				];
				return orderBy(
					this.ambassadorList,
					[(ambassador) => ids.includes(ambassador.id)],
					['desc']
				);
			}
		},

		created() {
			this.loadAmbassadorList();
		},

		methods: {
			...mapActions('AccountOrganisation', ['loadAmbassadorList'])
		}
	};
</script>
