import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Lpa
 * @exports Service/Lpa
 * @description API Lpa Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Lpa {
	/**
	 * @static @name list
	 * @description Get a list of referrals
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('power-of-attorney/list', payload);
	}
	/**
	 * @static @name post
	 * @description Add a new Lpa resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('power-of-attorney', payload);
	}
}
