<!--
	@name app-air-academy-status
	@description Join tab view for the air academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section class="mt-6">
			<template #header>
				<slot>
					Professional Status Progress
				</slot>
			</template>

			<template #body>
				<template v-if="authStatus == null">
					<p class="text-body-2 mb-4">
						Retrieving your LMS account details
					</p>
					<v-progress-linear height="24" indeterminate />
				</template>

				<template v-else-if="authStatus == 'error'">
					<p class="text-body-2 mb-5">
						To link your Air Academy account, please use the username and password sent to your email by academy.enquiries@airlaterlife.co.uk.
						If you have any questions on how to do this, please <a href="mailto:Academy.enquiries@airlaterlife.co.uk">click here</a> to get in contact.
					</p>

					<v-btn light small color="primary" @click="openAuthoriseDialog">
						Connect my Air Academy Learning Platform
					</v-btn>
				</template>

				<template v-else-if="courses.length > 0">
					<p class="text-body-1 mb-4">
						You have completed <strong>{{ completedRingOneCourses }}</strong> of the <strong>{{ courses.length }}</strong> Adviser Accreditation modules.
					</p>
					<v-progress-linear id="progress" class="mb-5" height="24" :value="completedRingOneCoursesPercentage">
						<template #default="{ value }">
							<strong>{{ Math.ceil(value) }}%</strong>
						</template>
					</v-progress-linear>
					<v-btn light small color="primary" @click="openRing">
						Click here to progress
					</v-btn>
				</template>

				<template v-else>
					<v-alert type="error" class="text-body-2 mb-3">
						Error retrieving LMS course list
					</v-alert>
				</template>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import { mapGetters, mapState } from 'vuex';
	import { EventBus } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-air-academy-dashboard',

		components: {
			CommonStructureSection
		},

		data() {
			return {};
		},

		computed: {
			...mapState('AppWebinar', ['authStatus']),
			...mapGetters('AppWebinar', ['getCatalogWithEnrolment']),

            courses() {
				return this.getCatalogWithEnrolment(1, 25);
            },

			completedRingOneCourses() {
				let completed = this.courses.filter((c) => c.enrollment_info?.status_label === 'completed')?.length;
                return completed || 0;
            },

			completedRingOneCoursesPercentage() {
				return Math.ceil(this.completedRingOneCourses / this.courses.length * 100);
			},

			colorMix() {
				let colour1Mix = 100-this.completedRingOneCoursesPercentage;
				let colour2Mix = this.completedRingOneCoursesPercentage;
				let colour1 = this.$vuetify.theme.themes.light.error;
				let colour2 = this.$vuetify.theme.themes.light.success;
				if (this.completedRingOneCoursesPercentage < 50) {
					colour2 = this.$vuetify.theme.themes.light.warning;
				} else {
					colour1 = this.$vuetify.theme.themes.light.warning;
					colour1Mix = 100-((this.completedRingOneCoursesPercentage-50)*2);
					colour2Mix = (this.completedRingOneCoursesPercentage-50)*2;
				}
				return `color-mix(in lch, ${colour1} ${colour1Mix}%, ${colour2} ${colour2Mix}%)`;
			}
		},

		methods: {
			openAuthoriseDialog() {
				EventBus.$emit('open-authorise-dialog');
			},

			openRing() {
				let ring = this.completedRingOneCoursesPercentage < 100 ? 'ringOne' : 'ringFour';
				EventBus.$emit('open-academy-ring', ring);
			}
		}
	};
</script>

<style scoped lang="scss">
	::v-deep #progress .v-progress-linear__background {
		background-color: v-bind(colorMix) !important;
	}

	::v-deep #progress .v-progress-linear__determinate {
		background-color: v-bind(colorMix) !important;
	}
</style>