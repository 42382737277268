/**
 * @namespace Utils
 * @class GoogleMap
 * @exports Utils/GoogleMap
 * @description Util for exporting a google maps instance
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class GoogleMap {
	constructor() {
		const apiKey = 'AIzaSyAsR_VbqlYFaXmaG4g1onIN1gv4ezA0JoY';
		this.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
		this.id = '-google-maps-api-';

		return this._bootstrap();
	}

	/**
	 * @static @name _bootstrap
	 * @description Bootstrap the google api into head area of document once.
	 * @return {TODO} TODO
	 */
	_bootstrap() {
		if (document.head.querySelector('#' + this.id)) return Promise.resolve();

		return new Promise((res, rej) => {
			const script = document.createElement('script');
			script.id = this.id;
			script.async = true;
			script.defer = true;
			script.onload = () => res();
			script.onerror = () => rej();
			script.src = this.src;
			document.head.appendChild(script);
		}).then(() => window.google);
	}
}
