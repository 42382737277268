import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Registration
 * @exports Service/Account/Registration
 * @description API Registration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Registration {
	/**
	 * @static @name post
	 * @description Perform an account registration request
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('account/registration', payload);
	}
}
