<!--
	@name app-dialog-rewards-awarded
	@description Show a dialog when Air rewards are awarded
	@date 2020/04/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			{{ data.title }}
		</template>
		<template #body>
			<span v-html="$sanitize(data.copy)" />
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-dialog-rewards-awarded',

		components: { CommonDialog },

		data: () => ({
			data: {
				title: null,
				copy: null
			}
		}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open(data) {
				this.data = data;
				this.$refs.dialog.open();
			}
		}
	};
</script>
