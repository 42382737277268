<!--
	@name app-source-result-dialog-restrict-product
	@description restrict product dialog
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" color="error" :component="$options.name">
		<template #header>
			Restrict {{ products.length > 1 ? products.length : '' }} Product{{ products.length > 1 ? 's' : '' }}
		</template>
		<template #body>
			<div>
				<p class="subtitle-1">
					{{ copy }}
				</p>
				<validation-observer v-slot="{ invalid, validate }">
					<form @submit.prevent="validate().then(submit)">
						<validation-provider v-slot="{ errors }" name="Feedback" rules="required">
							<v-text-field v-model="reason" :error-messages="errors" :label="label" outlined />
						</validation-provider>
						<v-btn color="primary" class="mr-4" type="submit" :disabled="invalid">
							Submit {{ products.length > 1 ? products.length : '' }} Restriction{{ products.length > 1 ? 's' : '' }}
						</v-btn>
					</form>
				</validation-observer>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-restrict-product',

		components: { ValidationObserver, ValidationProvider, CommonDialog },

		data() {
			return {
				products: [],
				reason: null
			};
		},

		computed: {
			copy() {
				let semantics = 'product';
				if (this.products.length > 1) semantics += 's';
				return `The reason you provide below will be saved alongside the ${semantics} in your results PDF.`;
			},

			label() {
				let semantics = 'this product is';
				if (this.products.length > 1) semantics = 'these products are';
				return `Please enter the reason for which ${semantics} being restricted`;
			}
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open(products) {
				this.products = products.length ? products : [products];
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description Submit product restriction/s
			 */
			async submit() {
				const { products, reason } = this;
				this.$emit('restrict-product', { products, reason });
				this.$emit('clear-selected-product-list');
				this.reason = null;
				this.$refs.dialog.close();
			}
		}
	};
</script>
