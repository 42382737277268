<!--
	@name app-source-full-source
	@description Sourcing Page
	@date 2020/02/27
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<!-- Applicant Details-->
		<common-form-client
			ref="applicant"
			:form="formFromGroup('source', 'client')"
			:clients="source.applicants"
			:show-error="submitFailed"
			age-disabled
			@set-client="setApplicant"
			@remove-client="removeApplicant"
			@set-validation="setValidation"
		/>

		<!-- Health -->
		<common-form-health
			ref="health"
			:form="healthForm"
			:applicants="source.applicants"
			:health="source.health"
			:submit-failed="submitFailed"
			@set-health="setHealth"
			@set-validation="setValidation"
		/>

		<!-- Property Details -->
		<common-form-property
			ref="property"
			:property-form="formFromGroup('source', 'asset')"
			:property="source.property"
			:client="source.applicants[0]"
			:show-error="submitFailed"
			flood-report
			@set-property="setProperty"
			@set-validation="setValidation"
		/>

		<!-- Borrowing -->
		<common-form-section ref="borrowing" title="Borrowing" :form="formFromGroup('source', 'borrowing')" :item="source.borrowing" @set-validation="setValidation" />

		<app-source-form-product ref="products" :form-data="source.products" @set-validation="setValidation" />

		<app-source-form-knowledge-bank ref="knowledgeBank" :process="process" :item="source.knowledgeBank" />

		<!-- API Health Question Warnings -->
		<v-sheet v-show="cancerHealthQuestionAnswered || ignoredHealthQuestionsAnswered" class="pa-6 px-10 mb-6 text-center red lighten-5 health-warnings">
			<p v-if="ignoredHealthQuestionsAnswered" class="text-body-2 font-weight-regular">
				You have selected health conditions that <span class="font-weight-black">more2life</span> do not currently cater for in their API and we therefore recommend that
				you speak with the lender directly to ensure you have the most accurate product information.
			</p>
		</v-sheet>

		<!-- Validation & Submit -->
		<div class="text-center">
			<v-fade-transition>
				<v-alert v-show="form('source').value" dense prominent outlined text border="left" :type="isValid ? 'success' : 'error'" class="mb-6 text-body-1 text-left">
					<span v-if="isValid"> All sections are completed, you can now proceed to products. </span>
					<span v-else> You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message. </span>
				</v-alert>
			</v-fade-transition>
			<v-btn v-show="form('source').value" large color="primary" :loading="isSubmitting" @click="sourceFormSubmitHandler">
				Proceed To Products
			</v-btn>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import { ElementTools, GoogleAnalytics } from '@/utils';
	import { cloneDeep, camelCase, isEmpty } from 'lodash';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormHealth from '@/component/common/form/health';
	import CommonFormProperty from '@/component/common/form/property';
	import AppSourceFormProduct from '@/component/app/source/form-product';
	import AppSourceFormKnowledgeBank from '@/component/app/source/form-knowledge-bank';
	import CommonFormSection from '@/component/common/form/section';

	export default {
		name: 'app-source-full-source',

		components: {
			CommonFormClient,
			CommonFormHealth,
			CommonFormProperty,
			AppSourceFormProduct,
			AppSourceFormKnowledgeBank,
			CommonFormSection
		},

		props: {
			process: { type: Object, default: null },
			valid: { type: Boolean, default: true }
		},

		data() {
			return {
				isSubmitting: false,
				submitFailed: false,
				forms: null,
				source: {
					applicants: [{}],
					health: {},
					property: {},
					products: {
						maximumMonthlyRepaymentAmount: 0,
						monthlyContribution: 100,
						monthlyRepaymentIncludeVoluntaryRepayment: true,
						paymentOptions: []
					},
					borrowing: {
						useAgeNextBirthday: true
					},
					knowledgeBank: {
						selectedCriteria: []
					},
					additionalCriteria: {}
				},

				validations: {
					applicant: false,
					property: false,
					borrowing: false,
					products: false
				},

				apiHealthQuestions: {}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['form', 'formFromGroup']),
			...mapGetters('CmsData', ['data']),

			airSelect() {
				return this.membership('select').id && !this.membership('select').bypass;
			},

			isValid() {
				if (!this.valid) return false;
				return !Object.keys(this.validations).find((key) => !this.validations[key]);
			},

			cancerHealthQuestionAnswered() {
				let val = false;
				if (Object.keys(this.source.health).length && this.apiHealthQuestions.cancer) {
					[0, 1].forEach((key) => {
						const item = `${key}.health_conditions`;
						if (this.source.health[item] && Array.isArray(this.source.health[item])) {
							val = this.source.health[item].includes(this.apiHealthQuestions.cancer);
						}
					});
				}
				return val;
			},

			ignoredHealthQuestionsAnswered() {
				let val = false;
				if (Object.keys(this.source.health).length && this.apiHealthQuestions.ignored) {
					[0, 1].forEach((key) => {
						if (!val) {
							const item = `${key}.health_conditions`;
							if (this.source.health[item] && Array.isArray(this.source.health[item])) {
								val = this.apiHealthQuestions.ignored.some((o) => this.source.health[item].includes(o));
							}
						}
					});
				}
				return val;
			},

			healthForm() {
				let mappedSchema = [];
				let healthSchema = this.formFromGroup('source', 'health');
				if (!healthSchema || !healthSchema.length) return;

				this.source.applicants.map((applicant, index) => {
					mappedSchema.push({ type: 'title', display: `Applicant ${index + 1} Health Details`, sm: 12, class: 'subtitle-1 font-weight-black pa-2 blue-grey lighten-4' });

					healthSchema.map((item) => {
						let field = cloneDeep(item);
						if (field.name) field.name = index + '.' + field.name;
						if (field.ref) field.ref = index + '.' + field.ref;
						if (field.rules && field.rules.includes('required_if_not:height')) field.rules = field.rules.replace('height', index + '.height');
						if (field.rules && field.rules.includes('required_if_not:weight')) field.rules = field.rules.replace('weight', index + '.weight');

						if (field.conditions) {
							Object.keys(field.conditions).map((key) => {
								field.conditions[key].map((condition) => {
									condition.name = index + '.' + condition.name;
								});
							});
						}
						mappedSchema.push(field);
					});
				});

				return mappedSchema;
			}
		},

		created() {
			this.loadForm('source').then(() => {
				if (this.process && this.process.data) {
					const {
						selectedCriteria,
						paymentOptions,
						maximumMonthlyRepaymentAmount,
						monthlyRepaymentTerm,
						monthlyContribution,
						monthlyRepaymentIncludeVoluntaryRepayment,
						...borrowing
					} = this.process.data;
					this.$refs.products.setPaymentOptions(paymentOptions);
					this.source.products = {
						...this.source.products,
						maximumMonthlyRepaymentAmount: maximumMonthlyRepaymentAmount || 0,
						monthlyRepaymentTerm,
						monthlyContribution,
						monthlyRepaymentIncludeVoluntaryRepayment
					};
					this.source.knowledgeBank = { selectedCriteria };
					this.source.borrowing = borrowing;

					// 06/2023 - Workflow
					// As part of workflow we removed clients associations with relation type other which meant we also had to delete target user id connections from matter entity table
					// As result of this when we need to filter clients in process data to make sure they exists in matter entity data as well
					const clients = this.process.data.clients?.filter((c) => this.process.matterEntity.find((me) => me.entity === 'client' && me.entityId === c.id));
					const asset = this.process.data.asset;
					clients?.forEach(async(client, index) => {
						await this.$refs.applicant?.fetchClient(index, client.id, false);
						if (index === 0) this.$refs.property?.fetchAsset(client.id, asset.id);
					});
				} else {
					if (this.$route.query.clientId) {
						const clientIds = [this.$route.query.clientId, this.$route.query.associationId].filter((client) => client);
						clientIds.forEach((clientId, index) => this.$refs.applicant?.fetchClient(index, clientId));
					}
					if (this.$route.query.propertyId) this.$refs.property?.fetchAsset(this.$route.query.clientId, this.$route.query.propertyId);

					this.$refs.products.setPaymentOptions();
					this.setDefaultValues(this.form('source').value);
				}
			});
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('LifetimeMortgageSourcing', ['submitSourcing']),

			/**
			 * @name sourceFormSubmitHandler
			 * @description Checks validations and club registration before submit
			 */
			async sourceFormSubmitHandler() {
				if (this.isValid) {
					if ((!this.membership('club') || !this.membership('club').request) && this.site.usesMortgageClub) this.$emit('club-registration-required', 'source');
					else this.submitSourceForm();
				} else this.submitFailed = true;
			},

			/**
			 * @name submitSourceForm
			 * @description Submit sourcing form to the server
			 */
			async submitSourceForm() {
				const payload = this.createPayload();

				try {
					this.isSubmitting = true;
					const { data } = await this.submitSourcing(payload);
					this.isSubmitting = false;
					if (payload.process.selectedCriteria) {
						GoogleAnalytics.sendEvent('knowledgeBankCriteriaSubmitted', {
							'gtm.element.dataset.gaId': 'source-form',
							'gtm.element.dataset.gaValue': `${payload.process.selectedCriteria.toString()}?` //add character to ensure value is cast as string in GA4
						});
					}
					this.$router.push(`/results/${data.processId}${data.iterationId ? '?iteration=' + data.iterationId : ''}`);
				} catch (error) {
					this.isSubmitting = false;
					ElementTools.fireNotification(this.$el, 'error', 'An error occured');
				}
			},

			/**
			 * @name setApplicant
			 * @description set applicant
			 * @param {Object} payload
			 */
			setApplicant({ dataIndex, data, type }) {
				if (dataIndex === 0 && type !== 'update') {
					this.$refs.property?.reset();
					if (!isEmpty(this.source.property)) this.setProperty({});
					if (!isEmpty(this.source.health)) this.setHealth({});
				}

				if (type === 'add' || type === 'update') data.assumedDateOfBirth = false;

				this.$set(this.source.applicants, dataIndex, data);
				this.$nextTick(() => this.$refs.applicant?.validateForm());

				if (!type !== 'update' && data.id) this.$refs.health.fetchHealth(dataIndex, data.id);

				// validate health form
				this.$nextTick(() => this.$refs.health.validateForm());
			},

			/**
			 * @name removeApplicant
			 * @description remove applicant
			 * @param {Object} payload
			 */
			removeApplicant(index) {
				this.$delete(this.source.applicants, index);
				this.$nextTick(() => this.$refs.applicant?.validateForm());
			},

			/**
			 * @name setProperty
			 * @description set property
			 * @param {Object} property
			 */
			setProperty(property) {
				this.source.property = property;
				this.$nextTick(() => this.$refs.property?.validateForm());
			},

			/**
			 * @name setHealth
			 * @description set property
			 * @param {Object} property
			 */
			setHealth(health) {
				let merged = isEmpty(health) ? health : { ...this.source.health, ...health };
				this.source.health = merged;
				this.$nextTick(() => this.$refs.health.validateForm());
			},

			/**
			 * @name setValidation
			 * @description set applicant
			 * @param {Object} validation
			 */
			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			/**
			 * @name setDefaultValues
			 * @param {Object} state
			 */
			setDefaultValues(forms) {
				forms.map((form) => {
					let key = form.nameUnique;
					if (key === 'asset') key = 'property';
					if (key === 'client') key = 'applicants';

					form.value.map((field) => {
						if (this.source[key] && typeof field.valueDefault !== 'undefined' && this.source[key][field.name] !== null && typeof this.source[key] === 'object') {
							this.source[key][field.name] = field.valueDefault;
						}
					});
				});
			},

			/**
			 * @name createPayload
			 * @description Submit quick quote form to the server
			 */
			createPayload() {
				// map health
				let mappedHealth = [];

				Object.keys(this.source.health).map((key) => {
					if (key.includes('0.')) {
						if (!mappedHealth[0]) mappedHealth[0] = {};
						mappedHealth[0][key.replace('0.', '')] = this.source.health[key];
					} else if (key.includes('1.')) {
						if (!mappedHealth[1]) mappedHealth[1] = {};
						mappedHealth[1][key.replace('1.', '')] = this.source.health[key];
					}
				});

				const healthSchema = cloneDeep(this.formFromGroup('source', 'health'));
				let healthPayloadList = [];
				mappedHealth.map((health, index) => {
					let healthPayload = [];

					Object.keys(health).map((formKey) => {
						let healthObject;
						let schema = healthSchema.find((schema) => schema.name === formKey);
						if (!schema?.name) return;

						if (schema.name.includes('height') || schema.name.includes('weight')) {
							let healthType = schema.name.includes('height') ? 'height' : 'weight';
							let dataKey = formKey.replace(healthType, '').toLowerCase();
							healthObject = healthPayload.find((item) => item.id === schema.id);

							if (healthObject) {
								healthObject.data[dataKey] = health[formKey];
								return;
							} else
								healthObject = {
									id: schema.id,
									data: {
										[dataKey]: health[formKey]
									}
								};
						} else if (schema.name.includes('alcohol') || schema.name.includes('tobacco')) {
							healthObject = {
								id: schema.id,
								data: {
									[formKey]: health[formKey]
								}
							};
						}

						if (healthObject) healthPayload.push(healthObject);
					});

					(health.healthConditions || []).map((item) => {
						let schema = healthSchema.filter((schema) => schema.id === item);
						let healthObject = {
							id: item,
							data: {}
						};

						if (schema && schema.length > 0) {
							const healthConditionsSchema = healthSchema.find((schema) => schema.name === 'healthConditions');

							schema.map((item) => {
								const schemaOption = healthConditionsSchema.options.find((option) => option.value === item.id);
								const key = camelCase(item.name.replace(camelCase(schemaOption.nameUnique), ''));

								healthObject.data[key] = health[item.name];
							});
						}
						healthPayload.push(healthObject);
					});

					healthPayloadList[index] = healthPayload;
				});

				// map client
				const client = this.source.applicants.map((item, index) => {
					const [day, month, year] = item.dateOfBirth.split('/');
					const dateOfBirth = this.$moment.utc(`${month}/${day}/${year}`).toISOString();
					const health = healthPayloadList[index];
					return { ...item, dateOfBirth, health };
				});

				// map asset
				const property = this.source.property;
				const asset = {
					type: 'property',
					id: property.id,
					data: {
						detail: {
							epcRating: property.epcRating,
							ex: { localAuthority: !!(property.additionalCriteria || []).find((item) => item === 'ex_local_authority') },
							buyToLet: !!(property.additionalCriteria || []).find((item) => item === 'a_buy_to_let'),
							ageRestricted: { value: !!(property.additionalCriteria || []).find((item) => item === 'age_restricted') },
							secondHome: !!(property.additionalCriteria || []).find((item) => item === 'second_home'),
							sheltered: !!(property.additionalCriteria || []).find((item) => item === 'sheltered'),
							tenureId: property.tenureId,
							assetDescriptionId: property.assetDescriptionId,
							unexpiredLeaseTerm: Number(property.unexpiredLeaseTerm)
						},
						location: {
							address1: property.address1,
							address2: property.address2,
							townCity: property.townCity,
							county: property.county,
							countryId: property.countryId,
							postcode: property.postcode
						},
						mortgage: {
							mortgageTypeId: property.mortgageTypeId,
							outstanding: property.outstandingMortgage
						},
						valuation: { value: parseInt(property.valuation) }
					}
				};

				// map borrowing
				const borrowing = {
					// defaults
					useAgeNextBirthday: true,
					addArrangementFeeToAdvance: false,
					drawdownFacility: null,
					drawdownFacilityRequired: false,
					initialAdvance: null,
					interestServicedProduct: 0,
					minimumInitialAdvance: null,
					retirementMortgage: 0,
					showMaximumAvailable: false,
					term: null,
					// merge form data
					...this.removeHiddenFields(cloneDeep(this.source.borrowing), this.formFromGroup('source', 'borrowing')),
					...this.$refs.products.createProductsPayload(this.source.products),
					...this.source.knowledgeBank
				};

				return {
					client,
					asset,
					process: borrowing,
					matterType: 'lifetime_mortgage',
					processType: 'sourcing'
				};
			},

			/**
			 * @name removeHiddenFields
			 * @description Remove hidden fields from form data
			 */
			removeHiddenFields(formData, formSchema) {
				Object.keys(formData).map((prop) => {
					let field = formSchema.find((field) => field.name === prop);

					if (field?.conditions) {
						field.conditions.show?.forEach((condition) => {
							if (
								(condition.type === 'equals' && formData[condition.name] != condition.value) ||
								(condition.type === 'not_equals' && formData[condition.name] == condition.value) ||
								(condition.type === 'includes' && (!formData[condition.name] || !formData[condition.name].includes(condition.value))) ||
								(condition.type === 'in_array' && !condition.value.includes(formData[condition.name]))
							) {
								delete formData[prop];
								return;
							}
						});
					}
				});

				return formData;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-alert {
		font-size: 14px;
		line-height: 18px;
	}

	.health-warnings {
		p {
			margin-bottom: 16px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
</style>
