<!--
	@name app-enquiry
	@description Enquiries page
	@date 2020/06/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						Enquiries
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip v-if="view === 'list'" bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/enquiries/create-new-enquiry" v-on="on">
								<v-icon large>
									mdi-folder-plus
								</v-icon>
							</v-btn>
						</template>
						<span>Create New Enquiry</span>
					</v-tooltip>
					<v-tooltip v-else bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/enquiries" v-on="on">
								<v-icon large>
									mdi-list-box
								</v-icon>
							</v-btn>
						</template>
						<span>View Enquiries</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12">
				<div class="border-effect border-effect--lg ml-6 mb-10" style="max-width: 1200px;">
					<p class="text-h5 mb-2 font-weight-regular">
						An enhanced way to communicate with our support team and <span class="font-weight-bold">leading later life lenders</span>
					</p>
					<p class="text-body-1 mb-0">
						Fully tracked and logged, you can see your enquiry at every stage and even receive instant updates from our support team and lenders.
						You can also archive your enquiries to ensure you have a full audit trail on your cases to support your advice process.
					</p>
				</div>

				<v-alert v-if="view === 'list'" class="pa-6 mt-6 mb-0">
					<app-enquiry-enquiry-list />
				</v-alert>

				<v-alert v-else-if="view === 'add'" class="pa-0 mt-6 mb-0">
					<app-enquiry-new-enquiry />
				</v-alert>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import AppEnquiryEnquiryList from '@/component/app/enquiry/enquiry-list';
	import AppEnquiryNewEnquiry from '@/component/app/enquiry/new-enquiry';
	import { mapActions } from 'vuex';
	import { isEmpty } from 'lodash';

	export default {
		name: 'app-enquiry',

		components: {
			AppEnquiryEnquiryList,
			AppEnquiryNewEnquiry
		},

		data() {
			return {
				view: 'list'
			};
		},

		watch: {
			$route: {
				handler() {
					this.configureRoute();
				},
				deep: true
			}
		},

		created() {
			this.configureRoute();
		},

		methods: {
			...mapActions('LogActivityUser', ['addActivityUser', 'deleteActivityUser']),

			configureRoute() {
				if(this.$route.name == 'enquiries.createNewEnquiry') this.view = 'add';
				else this.view = 'list';
			}
		}
	};
</script>
