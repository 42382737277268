import { CmsDownload, CmsConsumerDutyResource } from '@/service';
import { orderBy, groupBy } from 'lodash';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Download {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			downloadList: [],
			consumerDutyResources: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name downloads
			 * @description Downloads by tag
			 * @param {Object} state
			 */
			downloads: (state) => (tag) => state.downloadList.filter((download) => download.tag.some((t) => t.name === tag)),

			/**
			 * @name recentDownloads
			 * @description Filters downloads according to promoted
			 * @returns {Array}
			 */
			recentDownloads: (state) => (state.downloadList.length > 0 ? orderBy(state.downloadList, 'created', 'desc').slice(0, 4) : null),

			/**
			 * @name downloadTags
			 * @description Tag list created from download items
			 * @returns {Array}
			 */
			downloadTags: (state) => {
				if (state.downloadList.length === 0) return null;

				let tags = [];
				state.downloadList.forEach((downloadItem) => {
					console.log('downloadItem.tag :>> ', downloadItem.tag, downloadItem);
					downloadItem.tag.forEach((tagItem) => {
						if (tagItem.visible && !tags.find((t) => t.name === tagItem.name)) tags.push(tagItem);
					});
				});
				return tags;
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setDownloadList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setDownloadList: (state, data) => (state.downloadList = data),

			/**
			 * @name setConsumerDutyResourceList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setConsumerDutyResourceList: (state, data) => {
				const group = groupBy(data, (r) => r.owned[0]);
				state.consumerDutyResources = group;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadDownloadsList
			 * @param {function} commit
			 */
			async loadDownloadsList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				commit('setDownloadList', []);
				let { data } = await CmsDownload.list({ order: { property: 'title', direction: 'asc' } }, domain);
				let downloads = data.data.filter((download) => !download.tag.some((t) => t.nameUnique == 'consumer_duty'));
				commit('setDownloadList', downloads);
			},

			/**
			 * @async @name loadDownloadsList
			 * @param {function} commit
			 */
			async loadConsumerDutyResourceList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				commit('setConsumerDutyResourceList', []);
				let { data } = await CmsConsumerDutyResource.list({ order: { property: 'title', direction: 'asc' } }, domain);
				let consumerDutyResources = data.data.filter((download) => download.owned.length && download.tag.some((t) => t.nameUnique == 'consumer_duty'));
				commit('setConsumerDutyResourceList', consumerDutyResources);
			}
		};
	}
}
