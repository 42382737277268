import { AccountOrganisationTradingStyle } from '@/service';

/**
 * @class @name TradingStyle
 * @description Exposes menu store module
 */
export default class TradingStyle {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			organisationTradingStyleList: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name tradingStyleById
			 * @description used to get trading style by ID from store
			 * @param {Object} state
			 */
			tradingStyleById: (state) => (id) => state.organisationTradingStyleList.find((t) => t.id === id)
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setOrganisationTradingStyleList
			 * @param {Object} state
			 * @param {Array} data
			 */
			setOrganisationTradingStyleList(state, data) {
				state.organisationTradingStyleList = data;
				// if (!state.organisationTradingStyleList) state.organisationTradingStyleList = data;
				// else {
				// 	const arr = [...state.organisationTradingStyleList];
				// 	data.forEach((item) => {
				// 		const idx = arr.reduce((p, c, i) => (c.id === item.id ? i : p), -1);
				// 		if (idx >= 0) arr[idx] = { ...arr[idx], ...item };
				// 		else arr.push(item);
				// 		state.organisationTradingStyleList = arr;
				// 	});
				// }
			},

			/**
			 * @name setOrganisationTradingStyle
			 * @param {Object} state
			 * @param {Object} data
			 */
			setOrganisationTradingStyle(state, data) {
				if (!state.organisationTradingStyleList) state.organisationTradingStyleList = [data];
				else {
					const arr = [...state.organisationTradingStyleList];
					const idx = arr.reduce((p, c, i) => (c.id === data.id ? i : p), -1);
					if (idx >= 0) arr[idx] = { ...arr[idx], ...data };
					else arr.push(data);
					state.organisationTradingStyleList = arr;
				}
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadOrganisationTradingStyleList
			 * @param {function} commit
			 */
			async loadOrganisationTradingStyleList({ commit }) {
				return AccountOrganisationTradingStyle.list()
					.then(({ data }) => {
						commit('setOrganisationTradingStyleList', data);
						return data;
					})
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisationTradingStyle
			 * @param {function} commit
			 */
			modifyOrganisationTradingStyle({ state, commit }, { id, payload }) {
				return AccountOrganisationTradingStyle.patch(id, payload)
					.then(() => commit('setOrganisationTradingStyle', { ...state.organisationTradingStyleList.find((u) => u.id === id), ...payload }))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name addOrganisationTradingStyle
			 * @param {function} commit
			 */
			async addOrganisationTradingStyle({ commit }, payload) {
				return AccountOrganisationTradingStyle.post(payload)
					.then(({ data }) => commit('setOrganisationTradingStyle', data))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name deleteOrganisationTradingStyle
			 * @param {function} commit
			 */
			async deleteOrganisationTradingStyle({ commit, state }, id) {
				return AccountOrganisationTradingStyle.delete(id)
					.then(() => commit('setOrganisationTradingStyleList', [...state.organisationTradingStyleList.filter((u) => u.id !== id)]))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name updateSourcingPanel
			 * @param {function} commit
			 * @param {String} id
			 * @param {Object} payload
			 */
			updateSourcingPanel(context, { id, payload }) {
				return AccountOrganisationTradingStyle.updateSourcingPanel(id, payload);
			}
		};
	}
}
