import { kebabCase } from 'lodash';
import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Form
 * @exports Service/Cms/Form
 * @description API Form Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Form {
	/**
	 * @static @name get
	 * @description Get Form using name
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(name, cache = true) {
		return $http.get(`cms/form/using/name/${name}`, {
			cache,
			cachePath: `form-${kebabCase(name)}`
		});
	}

	/**
	 * @static @name list
	 * @description Get Forms using selected names
	 * @param {Array} select
	 * @returns {Object} Promise a http response
	 */
	static list(select) {
		return $http.post('cms/form/list', { select });
	}
}
