<!--
	@name app-client-dashboard-workflow-will-activity
	@description Client Workflow Will Activity
	@date 2024/02/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="justify-center">
			<v-col cols="12" md="12">
				<!-- Button to display referral form -->
				<a v-if="showReferralForm" class="text-body-1 d-inline-block" @click="showReferralForm = !showReferralForm"> &laquo; Back to panel options </a>
				<a v-else-if="referrals.length && !showReferralForm" class="text-body-1 d-inline-block" @click="toggleNewReferralOverride"> &laquo; Back to referrals list </a>

				<v-skeleton-loader v-if="!data('solicitors').value" type="list-item-three-line" />
				<div v-else-if="!showReferralForm">
					<v-row>
						<v-col v-for="(solicitor, index) in data('solicitors').value" :key="index" cols="12" md="6" lg="4">
							<v-card class="pa-6 elevation-3 v-card--solicitor" outlined tile style="position: relative">
								<v-img :src="solicitor.logo | urlize('organisation')" contain height="150" />
								<v-card-actions class="pa-10 d-flex flex-column justify-center">
									<v-btn
										v-for="(button, buttonIndex) in solicitor.buttons"
										:key="buttonIndex"
										class="ma-2"
										block
										:color="button.color"
										@click="handleFunction(button)"
									>
										{{ button.title }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</div>

				<div v-else-if="showReferralForm">
					<app-client-dashboard-workflow-will-referral
						v-if="client"
						class="mt-6"
						:organisation-id="organisationId"
						:client="client"
						:association="association"
						:asset-id="asset.id"
						:submitting="submitting"
						@submit="submit"
					/>
				</div>
			</v-col>
		</v-row>

		<common-dialog ref="airEstatePlanning">
			<template #header>
				Air Estate Planning
			</template>
			<template #body>
				<p class="text-body-1 font-weight-medium">
					Expand your Proposition | Boost your income | Grow your business | Secure better outcomes for you and your customers... <strong>Become an Air Estate Planning Partner.</strong>
				</p>
				<ul class="text-body-1 mb-6">
					<li class="mb-2">
						<strong>Air Estate Planning</strong> provides market-leading, personalised estate planning services across England, Wales and Scotland, delivered by our experienced team of qualified professional Estate Planners.
					</li>
					<li class="mb-2">
						Earn a generous <strong>25% commission</strong> on Will and LPA sales.
					</li>
					<li class="mb-0">
						Seamless process, exceptional service - become an <strong>Air Estate Planning</strong> partner by selecting us today!
					</li>
				</ul>
				<v-simple-table class="pricing elevation-3">
					<thead>
						<tr>
							<th>Type</th>
							<th class="text-center">
								Commission
							</th>
							<th class="text-right">
								Base Price ex. VAT*
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, i) in airPricing" :key="i">
							<td class="text-left font-weight-medium">
								{{ item.type }}
							</td>
							<td class="text-center font-weight-bold">
								{{ item.commission }}
							</td>
							<td class="text-right font-weight-bold">
								{{ item.price }}
							</td>
						</tr>
					</tbody>
				</v-simple-table>

				<p class="caption mt-6 mb-0">
					* All prices exclude VAT which will be added to the prices above
				</p>
			</template>
		</common-dialog>

		<common-dialog ref="faraniTaylor">
			<template #header>
				Farani Taylor
			</template>
			<template #body>
				<p class="text-body-1 font-weight-bold">
					At Farani Taylor you won't find a typical law firm; we believe in offering the extraordinary with personal services tailored just for you. As an
					international law expert, we offer global services and have been instrumental in achieving success for our clients from many countries.
				</p>
				<p class="text-body-1 mb-6">
					Although a full service law firm, we offered specialist estate planning advice and do not just stop at a Will. We look to refer clients back to their
					financial adviser should there be any need for financial planning within their estate plan
				</p>
				<v-simple-table class="pricing elevation-3">
					<thead>
						<tr>
							<th>Type</th>
							<th class="text-center">
								Commission
							</th>
							<th class="text-right">
								Base Price ex. VAT*
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, i) in pricing" :key="i">
							<td class="text-left font-weight-medium">
								{{ item.type }}
							</td>
							<td class="text-center font-weight-bold">
								{{ item.commission }}
							</td>
							<td class="text-right font-weight-bold">
								{{ item.price }}
							</td>
						</tr>
					</tbody>
				</v-simple-table>

				<p class="caption mt-6 mb-0">
					* All prices exclude VAT which will be added to the prices above
				</p>
				<p class="caption mb-0">
					** Plus disbursements
				</p>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import AppClientDashboardWorkflowWillReferral from '@/component/app/client/dashboard/workflow/will/referral';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-client-dashboard-workflow-will-solicitors',

		components: {
			AppClientDashboardWorkflowWillReferral,
			CommonDialog
		},

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			referrals: { type: Array, default: () => [] }
		},

		data() {
			return {
				showReferralForm: false,
				submitting: false,
				organisationId: null,
                solicitors: {
                    "name": "Solicitors",
                    "nameUnique": "solicitors",
                    "value": [
						{
                            "logo": "1ad47b4e-7425-4a1b-ba79-496d6fe42ae2.png",
                            "introduction": "Air Estate Planning",
                            "buttons": [
                                {
                                    "title": "Instruct via Air",
                                    "color": "primary",
                                    "fnctn": "airInstruct",
									"organisationId": "1ad47b4e-7425-4a1b-ba79-496d6fe42ae2"
                                },
                                {
                                    "title": "Pricing Details",
                                    "color": "info",
                                    "fnctn": "openAirEstatePlanningInfo"
                                }
                            ]
                        },
						{
                            "logo": "39d28293-edf2-4af5-8b53-903423b790f0.png",
                            "introduction": "Farani Taylor",
                            "buttons": [
                                {
                                    "title": "Instruct via Air",
                                    "color": "primary",
                                    "fnctn": "airInstruct",
									"organisationId": "39d28293-edf2-4af5-8b53-903423b790f0"
								},
                                {
                                    "title": "Pricing Details",
                                    "color": "info",
                                    "fnctn": "openFaraniTaylorInfo"
                                }
                            ]
                        }
                    ]
                },
				airPricing: [
					{ type: 'Single Will', commission: '£29', price: '£117' },
					{ type: 'Mirror Will', commission: '£58', price: '£233' },
					{ type: 'Property Protection Trust', commission: '£114', price: '£457' },
					{ type: 'Flexible Life Interest Trust', commission: '£153', price: '£613' },
					{ type: 'Disabled Trust', commission: '£80', price: '£320' },
					{ type: "Children's Trust", commission: '£80', price: '£320' },
					{ type: 'Storage (per annum)', commission: '£6', price: '£25' }
				],
				pricing: [
					{ type: 'Single Will', commission: '£0 - £50', price: '£180' },
					{ type: 'Mirror Will', commission: '£0 - £80', price: '£270' },
					{ type: 'Property Protection Trust**', commission: '£65', price: '£585' },
					{ type: 'Flexible Life Interest Trust**', commission: '£65', price: '£585' },
					{ type: 'Disabled Trust**', commission: '£50', price: '£450' },
					{ type: "Children's Trust**", commission: '£10', price: '£90' },
					{ type: 'Life Insurance Trust**', commission: '£65', price: '£585' },
					{ type: 'Storage (per annum)', commission: '£5', price: '£45' }
				]
			};
		},

		computed: {
			...mapGetters('CmsData', ['data'])
		},

		created() {
            //TODO - replace with proper data
			this.loadData('solicitors').then(() => {
                this.data('solicitors').value = this.solicitors.value;
            });
		},

		methods: {
			...mapActions('CmsData', ['loadData']),
			...mapActions('AppWill', ['submitWill']),

			/**
			 * @name handleFunction
			 * @description handle solicitor button click
			 */
			handleFunction: function(btn) {
				if (btn.href) new PopupWindow().open(btn.href, '_blank');
				else if (btn.fnctn) {
					this.organisationId = btn.organisationId;
					this[btn.fnctn]();
				}
			},

			/**
			 * @name airInstruct
			 * @description show air instruct form
			 */
            airInstruct() {
				this.showReferralForm = true;
			},

			/**
			 * @name openAirEstatePlanningInfo
			 * @description TODO
			 */
			openAirEstatePlanningInfo() {
				this.$refs.airEstatePlanning.open();
			},

			/**
			 * @name openFaraniTaylorInfo
			 * @description TODO
			 */
			openFaraniTaylorInfo() {
				this.$refs.faraniTaylor.open();
			},

            /**
			 * @name submit
			 * @description submit air instruct form
			 */
			submit(payload) {
				this.submitting = true;

				this.submitWill(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Submitted Successfully');
						this.$emit('submittedNewReferral');
						this.$emit('workflow-updated');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
                    .finally(() => this.submitting = false);
			},

            /**
			 * @name toggleNewReferralOverride
			 * @description TODO
			 */
			toggleNewReferralOverride() {
				this.$emit('toggleNewReferralOverride');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.solicitor {
		flex: 0 0 50%;
		width: 50%;
	}

	.v-card--solicitor {
		.v-card__actions {
			opacity: 0;
			transition: 0.5s;
			background-color: rgba(255, 255, 255, 0.75);
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		&:hover {
			.v-card__actions {
				opacity: 1;
			}
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.pricing {
		tr td {
			border-bottom: 5px solid #ffffff !important;
		}
		tr:last-child td {
			border-bottom: none !important;
		}
	}
</style>
