<!--
	@name app-account-settings-credentials
	@description Account settings - Account credentials tab content
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" :class="{ 'mb-6 pa-5': !slim }">
		<div v-if="!slim">
			<p class="text-body-1 mb-4">
				Grant access for {{ site.softwareName }} to make authenticated requests on your behalf to any of the partner apps and services listed below. For our lender
				partners, we require this access in order to retrieve KFIs at your request.
				<span class="font-weight-bold">It is therefore vital that these details are kept up to date.</span>
			</p>

			<v-alert
				class="text-body-2 mb-3 pl-6 background-rotation-manual--2"
				color="accent"
				colored-border
				dark
				border="left"
				icon="mdi-shield-check"
				prominent
			>
				The <span class="font-weight-bold">scope</span> parameter describes what level of consent you are granting to {{ site.softwareName }}. For example
				<span class="font-weight-bold">ltm:quote</span> and <span class="font-weight-bold">ltm:kfi</span> grant access to retrieve product rates and request KFIs
				respectively.
			</v-alert>

			<p class="caption mt-1 mb-6">
				{{ site.softwareName }} will not share this information with anyone else and will only make authenticated requests on your behalf for the purposes under which the
				access was granted. See our Terms and Conditions for more information.
			</p>
		</div>

		<v-card v-if="!orderedAccounts" class="flex-grow-1">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>
		<p v-else-if="!orderedAccounts.length" class="text-body-1 mb-0">
			There are 0 lenders for which we require account credentials
		</p>
		<v-list v-else two-line class="py-0">
			<v-list-item-group>
				<div v-for="(account, index) in orderedAccounts" :key="index">
					<!-- Note: paul, active needs to come from DB call, not form -->
					<v-list-item
						:key="account.organisationName"
						inactive
						:ripple="false"
						:class="[{ 'mt-3': index !== 0 }, account.credential ? 'blue-grey lighten-5' : 'grey lighten-4 elevation-1 inactive']"
					>
						<!-- Note: paul, logo needs to come from DB call, not form -->
						<v-list-item-avatar tile width="5rem">
							<v-img :src="logoName(account.organisationLogo) | urlize('organisation')" contain />
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title class="text-body-1 font-weight-medium mb-1" v-text="account.organisationName" />
							<v-list-item-subtitle class="text-body-2 mb-1" v-text="account.data.description" />
							<v-list-item-subtitle class="caption font-weight-bold font-italic">
								Scope: {{ account.data.scope }}
							</v-list-item-subtitle>
						</v-list-item-content>

						<!-- Note: paul, granted needs to come from DB call, not form -->
						<v-list-item-action class="justify-start">
							<v-list-item-action-text v-if="account.credential" class="mb-3">
								Granted: {{ account.credential.updated | moment('MMM Do YYYY, h:mma') }}
							</v-list-item-action-text>

							<v-menu v-if="account.credential" offset-y open-on-hover transition="slide-y-transition" left>
								<template #activator="{ on }">
									<v-btn color="secondary" :loading="isLoading[account.organisationName]" small v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list dense class="py-0">
									<v-list-item @click="updateCredentials(account)">
										<v-list-item-icon>
											<v-icon color="blue">
												mdi-eye
											</v-icon>
										</v-list-item-icon>
										<v-list-item-title>View Credentials</v-list-item-title>
									</v-list-item>

									<v-list-item @click="updateCredentials(account)">
										<v-list-item-icon>
											<v-icon color="warning">
												mdi-pencil
											</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Update Credentials</v-list-item-title>
									</v-list-item>

									<v-list-item @click="revokeAccess(account)">
										<v-list-item-icon>
											<v-icon color="error">
												mdi-link-off
											</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Revoke Access</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>

							<v-tooltip v-else bottom>
								<template #activator="{ on }">
									<v-btn :loading="isLoading[account.organisationName]" small color="primary" v-on="on" @click="grantAccess(account)">
										<v-icon left>
											mdi-link-plus
										</v-icon>
										Grant Access
									</v-btn>
								</template>
								<span>Grant access to your {{ account.organisationName }} account</span>
							</v-tooltip>
						</v-list-item-action>
					</v-list-item>
				</div>
			</v-list-item-group>
		</v-list>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submitCredentials" />
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapGetters, mapState, mapActions } from 'vuex';
	import { ElementTools, EventBus, PopupWindow } from '@/utils';
	import { orderBy } from 'lodash';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-account-settings-tab-credentials',

		components: {
			DynamicFormDialog,
			CommonDialogConfirm
		},

		props: {
			slim: { type: Boolean, default: false },
			lenders: { type: Array, default: () => [] }
		},

		data() {
			return {
				accounts: null,
				isLoading: {}
			};
		},

		computed: {
			...mapState('Account', ['account']),
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),

			orderedAccounts: {
				cache: false,
				get() {
					let accounts = this.accounts ? orderBy(this.accounts, [(a) => !!a.credential, (a) => a.organisationName.toLowerCase()], ['desc', 'asc']) : null;
					if (this.lenders.length) accounts = accounts?.filter((account) => this.lenders.includes(account.organisationNameUnique));
					return accounts;
				}
			}
		},

		watch: {
			orderedAccounts: {
				handler() {
					this.$emit('accounts-updated', this.orderedAccounts);
				},
				deep: true
			}
		},

		mounted() {
			EventBus.$on('trading-style-updated', this.init);
		},

		created() {
			this.init();
		},

		destroyed() {
			EventBus.$off('trading-style-updated', this.init);
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Oauth', ['getOauthOptions']),
			...mapActions('AppInterface', ['loadInterfaceList']),
			...mapActions('AppInterfaceUser', ['loadCredentialList', 'getCredential', 'addCredential', 'modifyCredential', 'deleteCredential']),

			init() {
				Promise.all([this.loadInterfaceList(), this.loadCredentialList(), this.loadForm('interface')]).then((response) => {
					const { data: interfaces } = response[0].data;
					const { data: userInterfaces } = response[1].data;
					const form = this.form('interface').value;

					interfaces.map((item) => {
						const formItem = form.find((el) => el.id === item.organisationId);
						const userInterface = userInterfaces.find((el) => el.interfaceId === item.id);
						if (formItem) item.form = formItem.value;
						if (userInterface) item.credential = userInterface;
						return item;
					});

					this.accounts = interfaces;
				});
			},

			/**
			 * @name grantAccess
			 * @description Grant access button handler
			 * @param {Object} account Account to revoke access
			 */
			grantAccess(account) {
				if (account.type === 'api') this.openAuthPopup(account);
				else {
					this.$refs.dynamicFormDialog.open({
						formSchema: account.form,
						formData: {},
						submitButtonText: 'Update Credentials',
						type: 'add',
						title: account.organisationName,
						account
					});
				}
			},

			/**
			 * @name updateCredentials
			 * @description Update credentials button handler
			 * @param {Object} account Account to update credentials
			 */
			async updateCredentials(account) {
				if (account.type === 'api') this.openAuthPopup(account);
				else {
					this.$set(this.isLoading, account.organisationName, true);
					let { data } = await this.getCredential(account.credential.id);
					this.$set(this.isLoading, account.organisationName, false);

					this.$refs.dynamicFormDialog.open({
						formSchema: account.form,
						formData: data.access,
						submitButtonText: 'Update Credentials',
						type: 'update',
						title: account.organisationName,
						account
					});
				}
			},

			/**
			 * @name submitCredentials
			 * @description Dynamic form submit handler for submitting provider account credentials
			 * @param {Object} config account config
			 * @param {Object} data Form data that contains credentials
			 */
			async submitCredentials({ config, data }) {
				let payload = {
					interfaceId: config.account.id,
					access: data
				};

				this.$set(this.isLoading, config.account.organisationName, true);

				try {
					if (config.type === 'add') {
						const response = await this.addCredential(payload);
						const index = this.accounts.findIndex((account) => account.id === response.data.interfaceId);
						this.$set(this.accounts, index, { ...this.accounts[index], credential: response.data });
						this.$forceUpdate();
					} else {
						await this.modifyCredential({ id: config.account.credential.id, payload });
					}

					ElementTools.fireNotification(this.$el, 'success', `Your ${config.account.organisationName} credentials has been updated successfully`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to update credentials');
				}

				this.$set(this.isLoading, config.account.organisationName, false);
			},

			/**
			 * @name revokeAccess
			 * @description Revoke access button click handler
			 * @param {Object} account Account to revoke access
			 */
			async revokeAccess(account) {
				this.$refs.confirm
					.open(
						'Revoke Access',
						`Are you sure you wish to revoke access to your ${account.organisationName} account? This will result in ${this.site.softwareName} no longer being able to perform certain actions on your behalf.`
					)
					.then(() => this.submitRevokeAccess(account))
					.catch(() => {});
			},

			/**
			 * @name submitRevokeAccess
			 * @description Revoke access request handler
			 * @param {Object} account Account to revoke access
			 */
			async submitRevokeAccess(account) {
				try {
					await this.deleteCredential(account.credential.id);

					this.$set(account, 'credential', null);
					this.$forceUpdate();

					ElementTools.fireNotification(this.$el, 'success', `You have been successfully revoked access for ${account.organisationName}`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to revoke access');
				}
			},

			/**
			 * @name openAuthPopup
			 * @description Popup window to authenticate account over an external party
			 */
			async openAuthPopup(account) {
				this.$set(this.isLoading, account.organisationName, true);

				let { data: res } = await this.getOauthOptions({ partner: account.config.partner, providerNameUnique: account.organisationNameUnique, domain: this.site.name });

				let payload = {
					provider: res.provider,
					authUrl: res.url,
					authPopupOptions: account.config.authPopupOptions
				};

				new PopupWindow().openAuthPopup(
					payload,
					({ data }) => {
						if (data[payload.provider]) {
							const iFace = data[payload.provider];
							ElementTools.fireNotification(this.$el, 'success', `Your ${account.organisationName} credentials has been updated successfully`);
							const index = this.accounts.findIndex((account) => account.id === iFace.interfaceId);
							this.$set(this.accounts, index, { ...this.accounts[index], credential: iFace });
							this.$emit('credentials-updated');
						} else ElementTools.fireNotification(this.$el, 'error', data.message);

						this.$set(this.isLoading, account.organisationName, false);
					},
					({ error }) => {
						ElementTools.fireNotification(this.$el, 'error', error);
						this.$set(this.isLoading, account.organisationName, false);
					}
				);
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			}
		}
	};
</script>

<style scoped lang="scss">
	.v-list-item.inactive {
		.v-list-item__avatar {
			opacity: 0.6;
		}
		.v-list-item__content {
			opacity: 0.6;
		}
	}
</style>
