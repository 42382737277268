<!--
	@name app-signed-authentication
	@description Signed authentication page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog :value="true" persistent width="300">
			<v-card>
				<v-card-text>
					Authenticating...
					<v-progress-linear indeterminate color="primary" class="mt-2 mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { $http } from '@/utils';
	export default {
		name: 'app-signed-authentication',

		props: {
			id: { type: String, required: true },
			userId: { type: String, required: true }
		},

		created() {
			this.signedAuthenticate({ id: this.id, userId: this.userId }).then(({ data }) => {
				$http.setToken(data.tokens.accessToken, data.tokens.refreshToken);
				this.$router.push({ path: data.redirectUrl.replace(window.location.origin, '') });
			});
		},

		methods: {
			...mapActions('Account', ['signedAuthenticate'])
		}
	};
</script>
