<!--
	@name app-source-result-dialog-filter-request
	@description Equity dialog showing showing submit request
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Filter Request
		</template>
		<template #body>
			<div v-if="success" class="text-center">
				<v-icon class="text-h2" color="success">
					mdi-check-bold
				</v-icon>
				<v-card-text class="text-subtitle-1 d-block pb-5">
					{{ message }}
				</v-card-text>
			</div>
			<div v-else>
				<p v-if="message" class="text-subtitle-1" :class="{ 'error--text': messageError }">
					{{ message }}
				</p>
				<p v-else class="text-subtitle-1">
					Please provide as much information as possible to ensure we have a complete understanding of the type of filter you are requesting and the effect it will have
					on the product results.
				</p>
				<validation-observer v-slot="{ invalid, validate }">
					<form @submit.prevent="validate().then(submit)">
						<validation-provider v-slot="{ errors }" name="Request" rules="required">
							<v-textarea
								v-model="data.request"
								:error-messages="errors"
								:label="`Please describe the filter you would like to see added to ${site.softwareName}`"
								outlined
							/>
						</validation-provider>
						<v-btn color="primary" class="mr-4" type="submit" :loading="loading" :disabled="invalid">
							Submit Request
						</v-btn>
					</form>
				</validation-observer>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-filter-request',

		components: { ValidationObserver, ValidationProvider, CommonDialog },

		data() {
			return {
				data: {},
				loading: false,
				message: null,
				messageError: false,
				success: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site'])
		},

		methods: {
			// ...mapActions('equity', ['submitFilterRequest']),

			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open() {
				this.data = {};
				this.messageError = false;
				this.success = false;
				this.$refs.dialog.open();
			},

			/**
			 * @name submit
			 * @description Submit product restriction/s
			 */
			async submit() {
				// this.loading = true;
				// const response = await this.submitFilterRequest(this.data);
				// this.loading = false;
				// if (response) {
				// 	this.message = response.message;
				// 	if (response.success) this.success = true;
				// 	else this.messageError = true;
				// }
			}
		}
	};
</script>
