<!--
	@name app-layout-blank
	@description Blank page layout
	@date 2020/06/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<!-- content -->
		<v-main>
			<template v-if="errorPage">
				<app />
			</template>

			<v-container v-else class="pa-0">
				<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
					<v-col cols="12">
						<app />
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<v-footer v-if="site" color="#1c1d36" class="mt-6">
			<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
				<v-col cols="12" class="mb-0 text-center">
					<p class="text-caption white--text mb-0">
						Copyright © 2018 {{ site.company.name }}. {{ site.softwareName }} is a Trading Style of {{ site.company.name }} {{ site.company.name }} is a Limited Company
						registered in England and Wales number: {{ site.contact.number }}. {{ site.contact.address }}. Opening hours: {{ site.contact.openingHours }}.
					</p>
				</v-col>
			</v-row>
		</v-footer>
	</v-app>
</template>

<script>
	import { mapState } from 'vuex';
	import App from '@/component/app';

	export default {
		name: 'app-layout-default-minimal',

		components: { App },

		computed: {
			...mapState('CmsSite', ['site']),

			errorPage() {
				return ['error404', 'error500'].includes(this.$route.name);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.theme--light.v-application {
		background: map-get($grey, lighten-5) !important;
	}
</style>
