<!--
	@name app-register-invalid-details
	@description Invalid details page
	@date 2020/12/15
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="site" to="/">
					<v-img class="mx-auto" contain :max-height="site.logo.maxHeight" :src="site.logo.path | urlize('brand')" />
				</router-link>
			</v-col>
		</v-row>

		<v-row class="mx-auto" style="max-width: 900px">
			<v-col cols="12" class="text-center">
				<div v-if="valid">
					<v-icon class="text-h2" color="primary">
						mdi-checkbox-marked-circle-outline
					</v-icon>
					<v-card-title class="text-h6 font-weight-bold d-block">
						Thank you
					</v-card-title>
					<v-card-text class="text-body-1 pb-0">
						Your details are valid
					</v-card-text>
				</div>
				<template v-else>
					<p class="text-h5 font-weight-bold mb-4">
						Invalid Details
					</p>

					<p class="text-body-2 mb-6">
						Please update the relevant details below to continue to your account. If you have any questions, please email us at
						{{ appConfig.contact.email }} or call on {{ appConfig.contact.number }}.
					</p>

					<window-form :form-data="formData" :form-schema="formSchema" :steps="steps" :submitting="submitting" @submit-window-form="submit" />
				</template>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	// import { mapGetters, mapState } from 'vuex';

	import WindowForm from '@/component/common/base/window-form';

	export default {
		name: 'app-register-invalid-details',

		components: { WindowForm },

		data() {
			return {
				valid: false,
				formData: {},
				formSchema: null,
				submitting: false,
				steps: []
			};
		},

		created() {
			// RegistrationService.fetchInvalidDetailsSchema().then((response) => {
			// 	if (response.success) {
			// 		this.formData = response.data;
			// 		this.formSchema = response.schema;
			// 		this.steps = response.steps;
			// 	} else {
			// 		this.valid = true;
			// 	}
			// });
		},

		methods: {
			/**
			 * @name submitRegistration
			 * @description Submits registration form to server
			 */
			async submit() {
				// this.submitting = true;
				// const response = await RegistrationService.updateInvalidDetails(this.formData);
				// this.submitting = false;
				// if (response.success) this.$router.push({ name: 'dashboard' });
			}
		}
	};
</script>
