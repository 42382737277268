import { Workflow } from '@/service';

/**
 * @class @name Workflow
 * @description Exposes worflow store module
 */
export default class WorkflowStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			statuses: [
				{ items: ['Submitted', 'Accepted', 'In Progress'], color: 'warning' },
				{ items: ['Declined', 'Failed'], color: 'error' },
				{ items: ['Completed'], color: 'success' }
			]
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name getStatusColor
			 * @description Find status color using status
			 * @param {Object} state
			 * @return {String} Status color
			 */
			getStatusColor: (state) => (status) => {
				return state.statuses.find((s) => s.items.includes(status))?.color || 'grey';
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name findWorkflow
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async findWorkflow(context, payload) {
				return await Workflow.find(payload);
			},

			/**
			 * @async @name createWorkflow
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async createWorkflow(context, payload) {
				return await Workflow.post(payload);
			},

			/**
			 * @async @name addStatus
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async addStatus(context, payload) {
				return await Workflow.patch(payload);
			}
		};
	}
}
