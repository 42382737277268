<!--
	@name app-air-club-deal
	@description Deal tab view for the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-6">
		<h2 class="text-h5 font-weight-bold mb-2">
			Deals
		</h2>
		<p class="text-body-1 mb-3">
			View the latest Air Club deals that you have access to. <strong>Remember</strong>, in order to benefit from increased procuration fees or client enhanced terms,
			<strong>select your relevant Air submission route when submitting applications to the provider.</strong>
		</p>
		<div class="row">
			<div class="col sm-6 pb-0">
				<v-btn class="ma-0" block color="purple lighten-2" dark @click="downloadFile">
					<v-icon left>
						mdi-download
					</v-icon>
					View Equity Release Deals (PDF)
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { PopupWindow } from '@/utils';

	export default {
		name: 'app-air-club-deal',

		computed: {
			...mapGetters('Account', ['membership', 'tier'])
		},

		methods: {
			...mapActions('File', ['getAssetFile']),

			downloadFile() {
				let s3Key = this.tier.id === 'select' ? `document/Equity%20Release%20Deals%20-%20Air%20Select.pdf` : `document/Equity%20Release%20Deals.pdf`;
				let filename = this.tier.id === 'select' ? `Equity%20Release%20Deals%20-%20Air%20Select.pdf` : `Equity%20Release%20Deals.pdf`;

				this.getAssetFile({ s3Key, filename }).then((response) => {
					if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
				});
			}
		}
	};
</script>
