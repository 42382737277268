import { gsap, TweenMax, TimelineMax, Power0, Power1, Back, Expo } from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
gsap.registerPlugin(MorphSVGPlugin);

/**
 * @namespace Utils
 * @class Svg
 * @exports Utils/Svg <instance>
 * @description Util for animating SVG
 * @date 2020/06/10
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Svg {
	/**
	 * @name animate
	 * @description TODO
	 */
	static homePage() {
		this.filters();
		this.kfiRequest();
	}

	static filters() {
		let timescale = 1.2;

		let a = function(a) {
			return document.querySelector(a);
		};

		let laptop = a('#laptop');
		let c = a('#laptop .stand');
		let d = a('#laptop .screen');
		let e = a('#laptop .case');
		let f = a('#laptop .detail');
		let g = a('#button');
		let g2 = a('#button .bg');
		let h = document.querySelectorAll('#results-box .result');
		h = Object.assign([], h).reverse();
		let i = a('#checkbox');
		let j = document.querySelectorAll('#checkbox .checkbox');
		let k = document.querySelectorAll('#checkbox .checkbox1 .checked');
		let k2 = document.querySelectorAll('#checkbox .checkbox1 .check');
		let l = document.querySelectorAll('#results-box .bad1 .logo');
		let m = document.querySelectorAll('#results-box .bad1 .copy');
		let bad1Move = document.querySelectorAll('#results-box .bad1Move');
		let good1Move = a('#results-box .good1Move');
		let slider = a('#slider');
		let slider1 = a('#slider1');
		let slider2 = a('#slider2');
		let highlight = a('#highlight');
		let bad2Logo = document.querySelectorAll('#results-box .bad2 .logo');
		let bad2Copy = document.querySelectorAll('#results-box .bad2 .copy');
		let bad2Move = document.querySelectorAll('#results-box .bad2Move');
		let goodResult = document.querySelectorAll('#results-box .goodResult');
		let goodResultLogo = document.querySelectorAll('#results-box .goodResult .logo');
		let goodResultCopy = document.querySelectorAll('#results-box .goodResult .copy');
		let badResults = document.querySelectorAll('#results-box .badResults');
		let request_kfi = a('#button2');
		let request_kfi_bg = a('#button2 .bg');
		TweenMax.set([c, f, g, i, j, k, slider, highlight, request_kfi, laptop], {
			transformOrigin: '50% 50%'
		});
		TweenMax.set([d, e], {
			transformOrigin: '0% 100%'
		});
		TweenMax.set([h], {
			transformOrigin: '0% 50%'
		});

		let B = new TimelineMax({
			repeat: -1,
			repeatDelay: 2,
			delay: 1,
			yoyo: !1,
			paused: !1
		});

		B.timeScale(timescale);
		B.fromTo(
			c,
			1,
			{
				scale: 0
			},
			{
				scale: 1,
				ease: Power1.easeOut
			}
		);

		B.fromTo(
			e,
			1,
			{
				scaleY: 0
			},
			{
				scaleY: 1,
				ease: Power1.easeOut
			},
			'-=0.25'
		);

		B.fromTo(
			d,
			0.75,
			{
				scaleY: 0
			},
			{
				scaleY: 1,
				ease: Power1.easeIn
			},
			'-=0.5'
		);

		B.fromTo(
			f,
			0.75,
			{
				scale: 0
			},
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=0.5'
		);

		B.fromTo(
			g,
			0.75,
			{
				scale: 0.25,
				y: 20,
				alpha: 0
			},
			{
				scale: 1,
				y: 0,
				alpha: 1,
				ease: Back.easeOut
			}
		);

		B.fromTo(
			g,
			0.5,
			{
				scale: 1
			},
			{
				scale: 2,
				alpha: 0,
				ease: Back.easeIn
			},
			'+=2'
		);
		B.to(
			g2,
			0.75,
			{
				fill: '#1b78d8'
			},
			'-=1'
		);

		B.fromTo(
			h,
			0.5,
			{
				scaleX: 0,
				y: -10
			},
			{
				scaleX: 1,
				y: 0,
				stagger: 0.1
			}
		);

		B.fromTo(
			i,
			0.75,
			{
				scale: 0,
				alpha: 0
			},
			{
				scale: 1,
				alpha: 1,
				ease: Back.easeOut
			},
			'+=2'
		);

		B.fromTo(
			j,
			0.5,
			{
				alpha: 0
			},
			{
				alpha: 1,
				stagger: 0.25
			},
			'+=.5'
		);

		B.fromTo(
			k,
			0.5,
			{
				scale: 0,
				alpha: 0
			},
			{
				scale: 1,
				alpha: 1,
				ease: Back.easeOut
			},
			'+=2'
		);
		B.to(
			k2,
			0.5,
			{
				fill: '#1c1d36'
			},
			'-=1'
		);

		B.fromTo(
			i,
			0.5,
			{
				scale: 1
			},
			{
				scale: 1.2,
				alpha: 0,
				ease: Back.easeIn
			},
			'+=.5'
		);

		B.to(
			l,
			0.5,
			{
				fill: '#b80c09',
				alpha: 0.6,
				stagger: 0.4
			},
			'+=1'
		);

		B.to(
			m,
			0.5,
			{
				fill: '#b80c09',
				alpha: 0.6,
				stagger: 0.4
			},
			'-=1.35'
		);

		B.fromTo(
			bad1Move,
			0.5,
			{
				y: 0
			},
			{
				y: gsap.utils.wrap([88, 44]),
				ease: Back.easeOut,
				stagger: 0.2
			}
		);
		B.to(
			good1Move,
			0.5,
			{
				y: '-=88',
				ease: Back.easeOut
			},
			'-=.5'
		);
		B.to(
			goodResult,
			0.5,
			{
				y: '-=44',
				ease: Back.easeOut
			},
			'-=.5'
		);

		B.fromTo(
			slider,
			0.75,
			{
				scale: 0,
				alpha: 0
			},
			{
				scale: 1,
				alpha: 1,
				ease: Back.easeOut
			},
			'+=1'
		);

		B.fromTo(
			slider1,
			0.75,
			{
				x: 0
			},
			{
				x: '+=50',
				ease: Back.easeOut
			},
			'+=1'
		);

		B.fromTo(
			slider2,
			0.75,
			{
				x: 0
			},
			{
				x: '-=50',
				ease: Back.easeOut
			},
			'-=.75'
		);

		B.fromTo(
			highlight,
			0.75,
			{
				scaleX: 1
			},
			{
				scaleX: 0.6,
				ease: Back.easeOut
			},
			'-=.75'
		);

		B.fromTo(
			slider,
			0.5,
			{
				scale: 1
			},
			{
				scale: 1.2,
				alpha: 0,
				ease: Back.easeIn
			},
			'+=.5'
		);

		B.to(
			bad2Logo,
			0.5,
			{
				fill: '#b80c09',
				alpha: 0.6,
				stagger: 0.4
			},
			'+=1'
		);

		B.to(
			bad2Copy,
			0.5,
			{
				fill: '#b80c09',
				alpha: 0.6,
				stagger: 0.4
			},
			'-=1.35'
		);

		B.fromTo(
			bad2Move,
			0.5,
			{
				y: 0
			},
			{
				y: gsap.utils.wrap([44, 44]),
				ease: Back.easeOut,
				stagger: 0.2
			}
		);
		B.to(
			goodResult,
			0.5,
			{
				y: -132,
				ease: Back.easeOut
			},
			'-=.5'
		);

		B.to(
			goodResultLogo,
			0.5,
			{
				fill: '#1eb0e1'
			},
			'-=.25'
		);
		B.to(
			goodResultCopy,
			0.5,
			{
				fill: '#1eb0e1'
			},
			'-=.5'
		);

		B.to(
			badResults,
			0.5,
			{
				alpha: 0,
				y: '+=44',
				ease: Back.easeOut
			},
			'+=1'
		);

		B.to(
			goodResult,
			0.75,
			{
				y: '+=200',
				ease: Back.easeOut
			},
			'-=.5'
		);

		B.fromTo(
			request_kfi,
			0.75,
			{
				scale: 0.25,
				y: 0,
				alpha: 0
			},
			{
				scale: 1,
				y: -30,
				alpha: 1,
				ease: Back.easeOut
			}
		);

		B.fromTo(
			request_kfi,
			0.5,
			{
				scale: 1
			},
			{
				scale: 2,
				alpha: 0,
				ease: Back.easeIn
			},
			'+=2'
		);
		B.to(
			request_kfi_bg,
			0.75,
			{
				fill: '#1b78d8'
			},
			'-=1'
		);

		B.fromTo(
			goodResult,
			0.75,
			{
				alpha: 1
			},
			{
				alpha: 0
			},
			'-=.75'
		);

		B.fromTo(
			laptop,
			0.75,
			{
				alpha: 1,
				scale: 1,
				rotation: 0
			},
			{
				alpha: 0,
				scale: 0,
				rotation: 180
			}
		);
	}

	static kfiRequest() {
		let timescale = 1.2;

		function getRandom(arr, n) {
			let result = new Array(n),
				len = arr.length,
				taken = new Array(len);
			if (n > len) throw new RangeError('getRandom: more elements taken than available');
			while (n--) {
				let x = Math.floor(Math.random() * len);
				result[n] = arr[x in taken ? taken[x] : x];
				taken[x] = --len in taken ? taken[len] : len;
			}
			return result;
		}

		let a = function(a) {
			return document.querySelector(a);
		};

		let tablet = a('#tablet');
		let tablet_button = a('#tablet_button');
		let tablet_screen = a('#tablet_screen');
		let tablet_outer = a('#tablet_outer');
		let keyboard = a('#keyboard');
		let form = a('#form');

		let inputColour = '#EFF0F4';
		let inputHighlight = '#E6E9F4';

		let input1BG = a('#text #input1 .input');
		let input1Txt = a('#text #input1 .text');

		let input2BG = a('#text #input2 .input');
		let input2Txt = a('#text #input2 .text');

		let input3 = a('#text #input3');
		let input3BG = a('#text #input3 .input');
		let input3Txt = a('#text #input3 .text');

		let input4 = a('#text #input4');
		let input4BG = a('#text #input4 .input');
		let input4Txt = a('#text #input4 .text');

		let input5 = a('#text #input5');
		let input5BG = a('#text #input5 .input');
		let input5Txt = a('#text #input5 .text');

		let checkBoxes = a('#check_boxes');
		let checkBox1BG = a('#check_boxes #box1 .bg');
		let checkBox1Tick = a('#check_boxes #box1 .tick');
		let checkBox4BG = a('#check_boxes #box4 .bg');
		let checkBox4Tick = a('#check_boxes #box4 .tick');

		let request = a('#form #request');
		let requestBG = a('#form #request .bg');
		let apply = a('#form #apply');
		let applyBG = a('#form #apply .bg');
		TweenMax.set([request, apply], {
			scale: 0.75,
			transformOrigin: '50% 0%'
		});
		TweenMax.set([apply], {
			alpha: 0,
			rotation: 90
		});
		let email1 = a('#email1');
		let receivedTitle1 = a('#email1 .received');
		let prodTitle1 = a('#email1 .productTitle');

		let email2 = a('#email2');
		let receivedTitle2 = a('#email2 .received');
		let prodTitle2 = a('#email2 .productTitle');
		let tempTitle2 = a('#email2 .tempTitle');

		let email3 = a('#email3');
		let receivedTitle3 = a('#email3 .received');
		let prodTitle3 = a('#email3 .productTitle');

		let keyColour = '#DEDFF2';
		let keys = document.querySelectorAll('#keyboard #keys .st1');
		let keys1 = getRandom(keys, 5);
		let keys2 = getRandom(keys, 5);
		let keys3 = getRandom(keys, 5);
		let keys4 = getRandom(keys, 5);
		let keys5 = getRandom(keys, 5);

		TweenMax.set([input1Txt, input2Txt, input3Txt, input4Txt, input5Txt], {
			x: '+=6'
		});

		TweenMax.set([tablet, tablet_button, tablet_screen, checkBox1Tick, checkBox4Tick, keys1, keys2, keys3, keys4, keys5, email1, email2, email3], {
			transformOrigin: '50% 50%'
		});

		TweenMax.set([tablet_screen, keyboard], {
			transformOrigin: '0% 100%'
		});

		TweenMax.set([email1, email2, email3], {
			rotation: 90,
			y: 10
		});
		TweenMax.set([email2], {
			scale: 1.2
		});
		TweenMax.set([email1], {
			scale: 0.5,
			y: '-=125'
		});
		TweenMax.set([email3], {
			scale: 0.5,
			y: '+=125'
		});

		let customEase = RoughEase.ease.config({
			template: Power0.easeNone,
			strength: 0.25,
			points: 10,
			taper: 'none',
			randomize: true,
			clamp: false
		});

		let t1 = new TimelineMax({
			repeat: -1,
			repeatDelay: 2,
			delay: 1,
			yoyo: !1,
			paused: !1
		});

		t1.timeScale(timescale);

		t1.set(keys1, {
			scale: 1,
			fill: '#FFFFFF'
		});
		t1.set(keys2, {
			scale: 1,
			fill: '#FFFFFF'
		});
		t1.set(keys3, {
			scale: 1,
			fill: '#FFFFFF'
		});
		t1.set(keys4, {
			scale: 1,
			fill: '#FFFFFF'
		});
		t1.set(keys5, {
			scale: 1,
			fill: '#FFFFFF'
		});

		t1.fromTo(
			tablet_screen,
			1,
			{
				scaleY: 0
			},
			{
				scaleY: 1,
				ease: Power1.easeOut
			}
		);

		t1.fromTo(
			tablet_outer,
			0.75,
			{
				scaleY: 0
			},
			{
				scaleY: 1,
				ease: Power1.easeOut
			},
			'-=.75'
		);

		t1.fromTo(
			keyboard,
			0.75,
			{
				y: +200
			},
			{
				y: 0,
				ease: Expo.easeOut
			},
			'-=.25'
		);

		t1.fromTo(
			form,
			1,
			{
				alpha: 0,
				y: +300
			},
			{
				alpha: 1,
				y: 45,
				ease: Expo.easeOut
			},
			'-=.5'
		);

		t1.fromTo(
			tablet_button,
			0.5,
			{
				scale: 0
			},
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=.5'
		);

		t1.to(
			input1BG,
			0.5,
			{
				fill: inputHighlight
			},
			'+=.5'
		);
		t1.fromTo(
			input1Txt,
			1,
			{
				scaleX: 0
			},
			{
				scaleX: 0.6,
				ease: customEase
			},
			'-=.25'
		);

		t1.fromTo(
			keys1,
			{
				scale: 0.6,
				fill: keyColour
			},
			{
				immediateRender: false,
				scale: 1,
				fill: '#FFFFFF',
				stagger: 0.2,
				ease: Back.easeOut
			},
			'-=1'
		);
		t1.to(input1BG, 0.5, {
			fill: inputColour
		});

		t1.to(
			input2BG,
			0.5,
			{
				fill: inputHighlight
			},
			'-=.25'
		);
		t1.fromTo(
			input2Txt,
			1,
			{
				scaleX: 0
			},
			{
				scaleX: 0.8,
				ease: customEase
			},
			'-=.25'
		);

		t1.fromTo(
			keys2,
			{
				scale: 0.6,
				fill: keyColour
			},
			{
				immediateRender: false,
				scale: 1,
				fill: '#FFFFFF',
				stagger: 0.2,
				ease: Back.easeOut
			},
			'-=1'
		);
		t1.to(input2BG, 0.5, {
			fill: inputColour
		});

		t1.to(
			form,
			0.75,
			{
				y: '-=55',
				ease: Back.easeOut
			},
			'-=.25'
		);

		t1.to(
			input3BG,
			0.5,
			{
				fill: inputHighlight
			},
			'-=.75'
		);
		t1.fromTo(
			input3Txt,
			0.5,
			{
				scaleX: 0
			},
			{
				scaleX: 0.6,
				ease: customEase
			},
			'-=.25'
		);

		t1.fromTo(
			keys3,
			{
				scale: 0.6,
				fill: keyColour
			},
			{
				immediateRender: false,
				scale: 1,
				fill: '#FFFFFF',
				stagger: 0.2,
				ease: Back.easeOut
			},
			'-=.5'
		);
		t1.to(input3BG, 0.5, {
			fill: inputColour
		});

		t1.to(
			input4BG,
			0.5,
			{
				fill: inputHighlight
			},
			'-=.5'
		);
		t1.fromTo(
			input4Txt,
			0.5,
			{
				scaleX: 0
			},
			{
				scaleX: 0.8,
				ease: customEase
			},
			'-=.25'
		);
		t1.fromTo(
			keys4,
			{
				scale: 0.6,
				fill: keyColour
			},
			{
				immediateRender: false,
				scale: 1,
				fill: '#FFFFFF',
				stagger: 0.2,
				ease: Back.easeOut
			},
			'-=.5'
		);
		t1.to(input4BG, 0.5, {
			fill: inputColour
		});

		t1.to(
			form,
			0.75,
			{
				y: '-=55',
				ease: Back.easeOut
			},
			'-=.25'
		);

		t1.to(checkBox1BG, 0.5, {
			fill: '#c6c7cd'
		});
		t1.fromTo(
			checkBox1Tick,
			0.5,
			{
				scale: 0
			},
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=0.5'
		);
		t1.to(checkBox4BG, 0.5, {
			fill: '#c6c7cd'
		});
		t1.fromTo(
			checkBox4Tick,
			0.5,
			{
				scale: 0
			},
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=0.5'
		);

		t1.to(form, 0.75, {
			y: '-=55',
			ease: Back.easeOut
		});

		t1.to(
			input5BG,
			0.5,
			{
				fill: inputHighlight
			},
			'-=.5'
		);
		t1.fromTo(
			input5Txt,
			1,
			{
				scaleX: 0
			},
			{
				scaleX: 0.8,
				ease: customEase
			},
			'-=.25'
		);
		t1.fromTo(
			keys5,
			{
				scale: 0.6,
				fill: keyColour
			},
			{
				immediateRender: false,
				scale: 1,
				fill: '#FFFFFF',
				stagger: 0.2,
				ease: Back.easeOut
			},
			'-=1'
		);
		t1.to(input5BG, 0.5, {
			fill: inputColour
		});

		t1.to(form, 0.5, {
			y: '-=15',
			ease: Back.easeOut
		});
		t1.to(
			[input3, input4, checkBoxes, input5],
			0.5,
			{
				alpha: 0
			},
			'-=.5'
		);
		t1.fromTo(
			keyboard,
			0.5,
			{
				y: 0
			},
			{
				y: +200,
				immediateRender: false,
				ease: Power1.easeOut
			},
			'-=.5'
		);

		t1.to(tablet, 0.75, {
			rotation: -90,
			ease: Back.easeInOut
		});
		t1.to(
			tablet,
			0.35,
			{
				scale: 0.8,
				ease: Back.easeOut
			},
			'-=.75'
		);
		t1.to(
			tablet,
			0.35,
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=.4'
		);
		t1.fromTo(
			request,
			0.75,
			{
				transformOrigin: '50% 50%'
			},
			{
				rotation: 90,
				scale: 1,
				ease: Back.easeInOut
			},
			'-=.75'
		);

		t1.to(
			request,
			0.5,
			{
				scale: 2,
				alpha: 0,
				ease: Back.easeIn
			},
			'+=1'
		);
		t1.to(
			requestBG,
			0.75,
			{
				fill: '#1b78d8'
			},
			'-=.75'
		);

		t1.to(prodTitle2, 0, {
			alpha: 0
		});
		t1.fromTo(
			tempTitle2,
			0,
			{
				alpha: 0
			},
			{
				alpha: 1
			}
		);

		t1.fromTo(
			email2,
			0.75,
			{
				alpha: 0
			},
			{
				alpha: 1
			}
		);

		t1.to(
			email2,
			0.75,
			{
				scale: 0.75,
				ease: Back.easeInOut
			},
			'+=.5'
		);

		t1.fromTo(
			prodTitle2,
			0.25,
			{
				alpha: 0
			},
			{
				alpha: 1
			},
			'-=.5'
		);
		t1.fromTo(
			tempTitle2,
			0.25,
			{
				alpha: 1
			},
			{
				alpha: 0
			},
			'-=.25'
		);

		t1.fromTo(
			email1,
			0.75,
			{
				alpha: 0
			},
			{
				alpha: 1,
				scale: 0.75,
				y: '+=15',
				ease: Back.easeOut
			},
			'-=.5'
		);

		t1.fromTo(
			email3,
			0.75,
			{
				alpha: 0
			},
			{
				alpha: 1,
				scale: 0.75,
				y: '-=15',
				ease: Back.easeOut
			},
			'-=.75'
		);

		t1.addLabel('startTest');

		let transTime = 0.4;
		t1.to(
			email1,
			transTime,
			{
				scale: 1,
				ease: Back.easeIn
			},
			'+=.5'
		);
		t1.to(email1, transTime, {
			scale: 0.8,
			ease: Back.easeOut
		});
		t1.to(
			'#email1 #mailFlap',
			transTime * 2,
			{
				morphSVG: {
					shape: '#email1 #pdfShape'
				},
				stroke: '#E86B12'
			},
			'-=' + transTime * 2 + ''
		);
		let mailOutline1 = MorphSVGPlugin.convertToPath('#email1 #mailOutline');
		t1.to(
			mailOutline1,
			transTime * 2,
			{
				morphSVG: {
					shape: '#email1 #pdfOutline'
				}
			},
			'-=' + transTime * 2 + ''
		);
		t1.fromTo(
			receivedTitle1,
			0.25,
			{
				alpha: 0
			},
			{
				alpha: 1
			},
			'-=' + transTime + ''
		);
		t1.fromTo(
			prodTitle1,
			0.25,
			{
				alpha: 1
			},
			{
				alpha: 0
			},
			'-=.25'
		);

		t1.to(email2, transTime, {
			scale: 1,
			ease: Back.easeIn
		});
		t1.to(email2, transTime, {
			scale: 0.8,
			ease: Back.easeOut
		});
		t1.to(
			'#email2 #mailFlap',
			transTime * 2,
			{
				morphSVG: {
					shape: '#email2 #pdfShape'
				},
				stroke: '#E86B12'
			},
			'-=' + transTime * 2 + ''
		);
		let mailOutline2 = MorphSVGPlugin.convertToPath('#email2 #mailOutline');
		t1.to(
			mailOutline2,
			transTime * 2,
			{
				morphSVG: {
					shape: '#email2 #pdfOutline'
				}
			},
			'-=' + transTime * 2 + ''
		);
		t1.fromTo(
			receivedTitle2,
			0.25,
			{
				alpha: 0
			},
			{
				alpha: 1
			},
			'-=' + transTime + ''
		);
		t1.fromTo(
			prodTitle2,
			0.25,
			{
				alpha: 1
			},
			{
				alpha: 0
			},
			'-=.25'
		);

		t1.to(email3, transTime, {
			scale: 1,
			ease: Back.easeIn
		});
		t1.to(email3, transTime, {
			scale: 0.8,
			ease: Back.easeOut
		});
		t1.to(
			'#email3 #mailFlap',
			transTime * 2,
			{
				morphSVG: {
					shape: '#email3 #pdfShape'
				},
				stroke: '#E86B12'
			},
			'-=' + transTime * 2 + ''
		);
		let mailOutline3 = MorphSVGPlugin.convertToPath('#email3 #mailOutline');
		t1.to(
			mailOutline3,
			transTime * 2,
			{
				morphSVG: {
					shape: '#email3 #pdfOutline'
				}
			},
			'-=' + transTime * 2 + ''
		);
		t1.fromTo(
			receivedTitle3,
			0.25,
			{
				alpha: 0
			},
			{
				alpha: 1
			},
			'-=' + transTime + ''
		);
		t1.fromTo(
			prodTitle3,
			0.25,
			{
				alpha: 1
			},
			{
				alpha: 0
			},
			'-=.25'
		);

		t1.to(
			['#email1 #mailOutline', '#email1 #mailFlap'],
			0.5,
			{
				stroke: '#b80c09'
			},
			'+=1'
		);
		t1.to(
			prodTitle1,
			0.5,
			{
				fill: '#b80c09'
			},
			'-=.5'
		);
		t1.to(
			email1,
			0.5,
			{
				scale: 0.5,
				alpha: 0.3,
				ease: Back.easeOut
			},
			'-=.5'
		);
		t1.to(
			receivedTitle2,
			0.5,
			{
				alpha: 0
			},
			'-=.5'
		);
		t1.to(
			prodTitle2,
			0.5,
			{
				alpha: 1
			},
			'-=.5'
		);

		t1.to(
			['#email3 #mailOutline', '#email3 #mailFlap'],
			0.5,
			{
				stroke: '#b80c09'
			},
			'-=.5'
		);
		t1.to(
			prodTitle3,
			0.5,
			{
				fill: '#b80c09'
			},
			'-=.5'
		);
		t1.to(
			email3,
			0.5,
			{
				scale: 0.5,
				alpha: 0.3,
				ease: Back.easeOut
			},
			'-=.5'
		);

		t1.to(
			['#email2 #mailOutline', '#email2 #mailFlap'],
			0.5,
			{
				stroke: '#1eb0e1'
			},
			'-=.5'
		);
		t1.to(
			prodTitle2,
			0.5,
			{
				fill: '#1eb0e1'
			},
			'-=.5'
		);
		t1.to(
			email2,
			0.5,
			{
				scale: 1,
				ease: Back.easeOut
			},
			'-=.5'
		);

		t1.to(
			tablet,
			0.75,
			{
				rotation: 0,
				ease: Back.easeInOut.config(0.5)
			},
			'+=1'
		);
		t1.to(
			email2,
			0.75,
			{
				rotation: 0,
				scale: 0.8,
				y: '-=60',
				ease: Back.easeInOut
			},
			'-=.75'
		);
		t1.to(
			[email1, email3],
			0.5,
			{
				alpha: 0,
				scale: 0
			},
			'-=.75'
		);
		t1.set(applyBG, {
			fill: '#1eb0e1'
		});
		t1.fromTo(
			apply,
			0.5,
			{
				immediateRender: false,
				scale: 0,
				alpha: 0,
				rotation: 0,
				y: '+=60'
			},
			{
				scale: 0.75,
				alpha: 1,
				ease: Back.easeOut
			},
			'+=.25'
		);
		t1.to(
			applyBG,
			0.75,
			{
				fill: '#1b78d8'
			},
			'+=1.5'
		);
		t1.to(
			apply,
			0.5,
			{
				scale: 2,
				alpha: 0,
				ease: Back.easeIn
			},
			'-=.5'
		);
		t1.to(
			email2,
			0.5,
			{
				scale: 0,
				alpha: 0,
				ease: Back.easeIn
			},
			'-=.5'
		);

		t1.fromTo(
			tablet,
			0.75,
			{
				immediateRender: false,
				alpha: 1,
				scale: 1,
				rotation: 0
			},
			{
				alpha: 0,
				scale: 0,
				rotation: 180
			}
		);

		t1.addLabel('endTest');
	}
}
