import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Shortcut
 * @exports Service/Cms/Shortcut
 * @description API Shortcut Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Shortcut {
	/**
	 * @static @name get
	 * @description Get Shortcut using name
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(name) {
		return $http.get(`cms/shortcut/${name}`);
	}
}
