<!--
	@name app-layout-account-sidebar
	@description Sidebar for account pages
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" @mouseenter="mini = false" @mouseleave="mini = true">
		<v-navigation-drawer app dark expand-on-hover clipped permanent color="black" class="navigation" width="160" mini-variant-width="70" mobile-breakpoint="768">
			<v-list dense>
				<div v-if="notificationList">
					<v-list-item link @click="openNotificationCentre">
						<v-list-item-icon>
							<v-badge color="red accent-3" bottom offset-y="10" offset-x="10" overlap :content="unreadNotificationCount || 0" :value="unreadNotificationCount">
								<v-icon> mdi-message-alert </v-icon>
							</v-badge>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title class="font-weight-regular">
								Notifications
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="my-3" />
				</div>

				<v-list-item v-for="item in menuList" :key="item.nameUnique" link :to="item.url">
					<v-list-item-icon>
						<v-badge
							color="red accent-3"
							bottom
							offset-y="10"
							offset-x="10"
							overlap
							:content="getBadgeCount(item.badgeVariable) || null"
							:value="getBadgeCount(item.badgeVariable) || null"
						>
							<v-icon> {{ item.mdi }} </v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">
							{{ item.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';
	import { mapActions, mapGetters, mapState } from 'vuex';

	export default {
		name: 'app-layout-account-sidebar',

		data() {
			return {
				mini: true
			};
		},

		computed: {
			...mapState('AppNotification', ['notificationList']),
			...mapState('CmsMenu', ['authedMenu']),
			...mapState('Account', ['user', 'changelogItems']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsSite', ['restrictedRoutes']),

			hiddenItems() {
				let hiddenItems = [];
				return hiddenItems;
			},

			unreadNotificationCount() {
				return this.notificationList?.filter((notification) => !notification.read).length;
			},

			unreadChangelogCount() {
				return this.changelogItems.filter((item) => {
					// If posted date is in the future, use the "created" date to judge where the item is new since last login
					if (this.$moment(item.posted) > this.$moment()) {
						if (this.$moment(item.created) > this.$moment(this.user.loginPrevious)) return true;
					}
					// Else check if "posted" date is after last login
					else if (this.$moment(this.$moment(item.posted).endOf('day').format()) > this.$moment(this.user.loginPrevious)) return true;
					return false;
				}).length;
			},

			menuList() {
				return this.authedMenu?.list?.filter((item) => {
					if (this.restrictedRoutes.includes(item.nameUnique)) return false;
					if (this.hiddenItems.includes(item.nameUnique)) return false;
					return true;
				});
			}
		},

		created() {
			this.loadNotificationList({ order: { property: 'notification.created', direction: 'DESC' } }).then(() => {
				const anyUnreadImportant = this.notificationList.some((n) => !n.read && n.important);
				// If on dashboard and important unread items, open centre
				if (anyUnreadImportant && this.$route.name.startsWith('dashboard')) this.openNotificationCentre();
			});
		},

		methods: {
			...mapActions('AppNotification', ['loadNotificationList']),

			openNotificationCentre() {
				EventBus.$emit('open-notification-centre');
			},

			getBadgeCount(badgeVariable) {
				return this[badgeVariable] || null;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.navigation.v-navigation-drawer {
		&--is-mouseover {
			box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
		}
		.v-icon.v-icon {
			font-size: 20px;
			margin: 0 auto;
		}
		.v-list-item .v-list-item__content {
			padding: 6px 0;
		}
	}
</style>
