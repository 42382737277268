<!--
	@name common-promotion-carousel
	@description Show a carousel of adverts
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<div :class="{ 'd-block': orientation === 'portrait', 'd-flex overflow-x-auto': orientation === 'landscape' }">
			<a v-for="(advert, index) in activeAdverts" v-show="index === visibleIndex" :key="index" :href="advert.link" class="advert" target="_blank">
				<img :src="advert.image | urlize" />
			</a>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { PopupWindow } from '@/utils';

	export default {
		name: 'common-promotion-carousel',

		props: {
			adverts: { type: Array, required: true, default: () => [] },
			orientation: { type: String, required: true }
		},

		data() {
			return {
				visibleIndex: 0
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			activeAdverts() {
				const section = window.location.pathname.split('/')[1];
				return this.adverts.filter((item) => {
					if (section && item.hidePathNames && item.hidePathNames.includes(section)) return false;
					return true;
				});
			}
		},

		mounted() {
			setInterval(() => (this.visibleIndex = this.visibleIndex === this.activeAdverts.length - 1 ? 0 : this.visibleIndex + 1), 5000);
		},

		methods: {
			/**
			 * @name redirect
			 * @description Redirect the page
			 * @param {String} link The link to redirect to
			 */
			redirect(link) {
				new PopupWindow().open(link);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.advert {
		cursor: pointer;
		width: 100%;
		display: flex;
		img {
			width: 100%;
		}
	}
</style>
