<!--
	@name app-air-academy-cpd
	@description Air Academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section>
			<template #header>
				<slot> CPD Log </slot>
			</template>

			<template #body>
				<v-row no-gutters>
					<v-col class="grow pr-6">
						<p class="text-body-2 mb-5">
							It is crucial to capture & evidence your ongoing professional development as an adviser. Air Academy captures this for you below:
						</p>
						<v-btn color="primary" small @click="active = true">
							access personal CPD log
						</v-btn>
					</v-col>
					<v-col class="shrink">
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-sheet class="pa-3 grey lighten-2 rounded text-center" v-on="on">
									<p class="text-h4 font-weight-bold text-center mb-1">
										{{ totalCpdTargetHours(false) }}<span class="text-h6 d-inline-block font-weight-regular ml-1">/ {{ target }}</span>
									</p>
									<p class="text-caption plain nowrap mb-0">
										Total CPD hours
									</p>
								</v-sheet>
							</template>
							<span>Logged since {{ startDate | moment('ddd, Do MMM YYYY') }}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</template>
		</common-structure-section>

		<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								CPD
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="active = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-card-text>
					<v-container class="px-0 pb-10">
						<v-row class="mb-6">
							<v-col cols="7" class="pr-10">
								<p class="text-h6 mb-3">
									Personal CPD Log
								</p>
								<p class="text-body-1 mb-3">
									Air Academy's CPD log offers a seamless way to record your continuous professional development, evidencing your dedication to staying current in
									the constantly evolving financial landscape. It dynamically updates with any CPD you engage with within the Academy, while also allowing you to
									include external learning sources for a comprehensive overview of your development progress. Each learning activity is time-stamped for
									documentation, and editable fields are provided for capturing notes or reflections alongside each entry.
								</p>
							</v-col>
							<v-col cols="5">
								<v-skeleton-loader v-if="!testimonial.name" type="card" class="pa-0 ma-0" />
								<app-air-academy-training-webinar-item v-else :item="testimonial" @play-video="playVideo" />
								<app-dialog-video-player ref="videoDialog" />
							</v-col>
						</v-row>
						<v-row class="mt-0">
							<v-col cols="12" class="pt-0">
								<v-alert border="left" colored-border color="grey lighten-1" class="mb-0" text elevation="3">
									<v-row no-gutters>
										<v-col class="grow align-content-center pl-3 pr-10">
											<p class="text-body-2 mb-0">
												You have logged <strong>{{ totalCpdTargetHours() }}</strong> CPD since <strong>{{ startDate | moment('Do MMM YYYY') }}</strong>.
												<span v-if="remainingTarget()">You must log <strong>{{ remainingTarget() }}</strong> of CPD to hit your annual target by <strong>{{ endDate | moment('Do MMM YYYY') }}</strong>.</span>
												<span v-if="!remainingTarget()">Your target has been reached.</span>
											</p>
										</v-col>
										<v-col class="shrink">
											<v-btn small color="secondary" @click="changeCPD">
												change CPD settings
											</v-btn>
										</v-col>
									</v-row>
								</v-alert>
							</v-col>
						</v-row>
						<v-divider inset class="my-10" />
						<v-row class="mb-6">
							<v-col cols="12">
								<v-sheet class="mb-4">
									<v-btn color="primary" @click="addItem">
										Add new log item
									</v-btn>
									<v-btn class="ml-2" color="secondary" @click="openPrintDialog">
										Print
									</v-btn>
								</v-sheet>

								<v-data-table
									class="table--styled elevation-3"
									:headers="headers"
									:items="visibleCpdTrainingItems"
									:options.sync="options"
									:server-items-length="options.total"
									:loading="loading"
									:footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
								>
									<template #progress>
										<v-progress-linear indeterminate />
									</template>
									<template #item="{ item }">
										<tr>
											<td>
												{{ item.name || 'Name missing' }}
												<a v-if="!item.reflection" class="text-caption plain d-block error--text mt-1 mb-0" @click="editItem(item)">
													<v-icon color="error" size="16" left>
														mdi-alert
													</v-icon>Add reflection log
												</a>
											</td>
											<td>{{ convertTime(item.points) }}</td>
											<td>
												{{ item.type }}
											</td>
											<td>
												{{ item.issued | moment('MMM Do YYYY') }}
											</td>
											<td class="text-right shrunk">
												<v-tooltip bottom content-class="text-center" max-width="250">
													<template #activator="{ on }">
														<v-btn color="primary" icon :disabled="deleting[item.id]" @click="editItem(item)" v-on="on">
															<v-icon v-on="on">
																mdi-pencil
															</v-icon>
														</v-btn>
													</template>
													<span> Edit item </span>
												</v-tooltip>
												<v-tooltip bottom content-class="text-center" max-width="250">
													<template #activator="{ on }">
														<v-btn color="error" icon :loading="deleting[item.id]" @click="deleteItem(item)" v-on="on">
															<v-icon> mdi-trash-can </v-icon>
														</v-btn>
													</template>
													<span> Delete this item </span>
												</v-tooltip>
											</td>
										</tr>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<cpd-event-form ref="addCpdEventDialog" @dynamic-form-submit="submit" />
		<dynamic-form-dialog ref="dynamicTargetDialog" @dynamic-form-submit="saveTarget" />
		<common-dialog-confirm ref="confirm" />
		<cpd-print ref="printCpdDialog" :cpd-training-items="cpdTrainingItems" :convert-time="convertTime" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';
	import CpdEventForm from '@/component/app/air-academy/dialog/cpd-event-form';
	import CpdPrint from '@/component/app/air-academy/dialog/cpd-print';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';
	import AppDialogVideoPlayer from '@/component/app/dialog/video-player';

	export default {
		name: 'app-air-academy-cpd',

		components: {
			CommonStructureSection,
			CpdEventForm,
			CpdPrint,
			CommonDialogConfirm,
			DynamicFormDialog,
			AppAirAcademyTrainingWebinarItem,
			AppDialogVideoPlayer
		},

		data() {
			return {
				active: false,
				month: false,
				loading: false,
				startDate: this.$moment(this.$moment().startOf('year'), 'DD/MM/YYYY'),
				target: 35,
				options: {
					page: 1,
					itemsPerPage: 10,
					total: 0
				},
				headers: [
					{
						text: 'Name',
						align: 'start',
						sortable: false,
						value: 'name',
						cellClass: 'shrink'
					},
					{
						text: 'CPD Time',
						align: 'start',
						sortable: false,
						value: 'points',
						cellClass: 'shrink'
					},
					{
						text: 'Type',
						align: 'start',
						sortable: false,
						value: 'type',
						cellClass: 'shrink'
					},
					{
						text: 'Date',
						align: 'start',
						sortable: false,
						value: 'issued',
						cellClass: 'shrink'
					},
					{ text: '', value: 'actions', sortable: false, class: 'shrunk actions' }
				],
				testimonial: {
					name: 'Insights Hub: On-Demand CPD',
					image: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyCPD.jpg`,
					mimetype: 'video/mp4',
					hideStatus: true,
					fixedHeight: true,
					emit: 'play-video'
				},
				deleting: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsCpdTrainingItem', ['cpdTrainingItems']),
			...mapGetters('CmsForm', ['form']),

			visibleCpdTrainingItems() {
				if (!this.cpdTrainingItems.length) return [];
				return this.cpdTrainingItems.slice(
					(this.options.page - 1) * this.options.itemsPerPage,
					this.options.page * this.options.itemsPerPage
				);
			},

			endDate() {
				return this.$moment(this.startDate, 'DD/MM/YYYY').add(1, 'years');
			}
		},

		created() {
			this.loadForm('academy_cpd');
			this.fetchCpdTrainingItems(true);
			this.loadUserDetails(this.user.id).then(({ data }) => {
				if (data?.cpdSettings?.startDate) this.startDate = this.$moment(data.cpdSettings.startDate, 'DD/MM/YYYY');
				if (data?.cpdSettings?.target) this.target = data.cpdSettings.target;
			});
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsCpdTrainingItem', ['addNewCpdTrainingItem', 'modifyCpdTrainingItem', 'deleteCpdTrainingItem', 'loadCpdTrainingItems']),
			...mapActions('Account', ['loadUserDetails', 'modifyUser']),

			/**
			 * @name fetchCpdTrainingItems
			 * @description Fetch the CPD training items.
			 */
			fetchCpdTrainingItems(loading = false) {
				this.loading = loading;
				this.loadCpdTrainingItems({ order: { property: 'issued', direction: 'desc' } })
				.finally(() => {
					this.options.total = this.cpdTrainingItems.length;
					this.loading = false;
				});
			},

			/**
			 * @name addItem
			 * @description Open a dialog to add a new CPD training item.
			 */
			addItem() {
				this.openFormDialog();
			},

			/**
			 * @name editItem
			 * @description Open a dialog to edit a CPD training item.
			 * @param {Object} item - The CPD training item to edit.
			 */
			editItem(item) {
				const formData = { ...item, issued: this.$moment(item.issued).format('DD/MM/YYYY') };
				this.openFormDialog(formData);
			},

			/**
			 * @name openFormDialog
			 * @description Open a dialog with the provided form data and configuration.
			 * @param {Object} formData - The data to populate the form with.
			 * @param {string} submitButtonText - The text for the form's submit button.
			 * @param {string} title - The title for the form dialog.
			 */
			openFormDialog(formData = {}) {
				this.$refs.addCpdEventDialog.open({
					formSchema: this.form('academy_cpd').value,
					formData,
					submitButtonText: 'Submit',
					title: 'Log CPD'
				});
			},

			changeCPD() {
				const formData = { target: this.target, startDate: this.$moment(this.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY') };

				this.$refs.dynamicTargetDialog.open({
					formSchema: [
					{
							type: 'date-picker',
							name: 'startDate',
							display: 'Annual CPD target start date',
							rules: 'required|date_format:dd/MM/yyyy',
							sm: 6
						},
						{
							type: 'text',
							name: 'target',
							display: 'Annual CPD target (hours)',
							rules: 'required',
							dense: true,
							sm: 6
						}
					],
					formData,
					submitButtonText: 'Update Settings',
					title: 'Change CPD Settings'
				});
			},

			saveTarget({ data }) {
				this.modifyUser({
					data: {
						cpdSettings: {
							target: data.target,
							startDate: data.startDate
						}
					}
				})
				.then(() => {
					this.target = data.target;
					this.startDate = this.$moment(data.startDate, 'DD/MM/YYYY');
					ElementTools.fireNotification(this.$el, 'success', 'Updated CPD settings successfully');
				})
				.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update CPD settings'));
			},

			/**
			 * @name submit
			 * @description Handle the response from the form submission.
			 * @param {Object} val - The response data from the form submission.
			 */
			submit({ data }) {
				const payload = {
					...data,
					issued: this.$moment(data.issued, 'DD/MM/YYYY').toISOString()
				};

				if (data.id) {
					this.modifyCpdTrainingItem(payload)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', 'CPD Log updated successfully');
							this.fetchCpdTrainingItems();
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update CPD Log'));
				} else {
					this.addNewCpdTrainingItem(payload)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', 'CPD Log added successfully');
							this.fetchCpdTrainingItems();
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to add CPD Log'));
				}
			},

			/**
			 * @name convertTime
			 * @description Convert minutes to hours (rounded down) and minutes.
			 * @param {number} mins - The number of minutes to convert.
			 * @returns {number} The converted time in hours.
			 */
			convertTime(mins, withFormat = true) {
				const wholeHours = Math.floor(this.$moment.duration(mins, 'minutes').asHours());
				const remainingMinutes = mins - (wholeHours * 60);
				let result = '';
				if (wholeHours) {
					result += `${wholeHours} hour${wholeHours > 1 ? 's' : ''} `;
				}
				if (remainingMinutes) {
					result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''} `;
				}
				if (!wholeHours && !remainingMinutes) {
					result = `0 hours`;
				}

				return withFormat ? result.trim() : wholeHours;
			},

			/**
			 * @name calculateTotalMinutes
			 * @description Calculates total number of CPD minutes achieved between 2 dates
			 * @returns {number} Total number of minutes.
			 */
			calculateTotalMinutes() {
				const items = this.cpdTrainingItems.filter((item) =>
					this.$moment(item.issued).isAfter(this.$moment(this.startDate)) &&
					this.$moment(item.issued).isBefore(this.$moment(this.startDate).add(1, 'years')));
				return items.reduce((acc, item) => acc + item.points, 0);
			},

			/**
			 * @name totalCpdTargetHours
			 * @description Return pretty display of calculateTotalMinutes, with or without formatting
			 * @returns {string} Pretty display.
			 */
			totalCpdTargetHours(withFormat = true) {
				const totalMinutes = this.calculateTotalMinutes();
				return this.convertTime(totalMinutes, withFormat);
			},

			remainingTarget() {
				const totalMinutes = this.calculateTotalMinutes();
				const duration = this.$moment.duration(totalMinutes, 'minutes');
				if (duration.asHours() >= this.target) {
					return false;
				}
				let wholeHours = this.target - Math.floor(duration.asHours());
				const remainingMinutes = 60 - (totalMinutes % 60);

				let result = '';

				if (remainingMinutes < 60) {
					wholeHours -= 1;
				}

				if (wholeHours > 0) {
					result += `${wholeHours} hour${wholeHours !== 1 ? 's' : ''} `;
				}

				if (remainingMinutes < 60) {
					result += `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''} `;
				}

				return result.trim();
			},

			/**
			 * @name deleteItem
			 * @description Delete a CPD training item.
			 * @param {Object} item - The CPD training item to delete.
			 */
			async deleteItem(item) {
				this.$refs.confirm
					.open('Delete item', `Are you sure you wish to delete this item?`)
					.then(() => {
						this.$set(this.deleting, item.id, true);
						return this.deleteCpdTrainingItem(item.id);
					})
					.then(() => this.fetchCpdTrainingItems())
					.catch(() => {})
					.finally(() => this.$set(this.deleting, item.id, false));
			},

			/**
			 * @name playVideo
			 * @description Open a dialog to play a video.
			 */
			playVideo() {
				this.$refs.videoDialog.open(`${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyCPD.mp4`);
			},

			openPrintDialog() {
				this.$refs.printCpdDialog.open({
					submitButtonText: 'Print',
					title: 'Print CPD Log'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	td {
		&.shrunk {
			width: 1px;
			white-space: nowrap;
		}

		&.nowrap {
			white-space: nowrap;
		}
	}
</style>
