import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Credit
 * @exports Service/App/Reward/Credit
 * @description API Reward Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Credit {
	/**
	 * @static @name creditList
	 * @description Add a new reward resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`reward/credit/list`, payload);
	}
}
