<!--
	@name app-tools
	@description Tools page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Tools
					</div>
				</div>
			</v-container>
		</v-img>

		<!-- Tools -->
		<div :class="{ 'mt-8': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<v-row no-gutters class="justify-center">
					<v-col v-for="n in tools" :key="n.name" cols="12" md="4">
						<v-card class="pa-4 ma-6" elevation="2">
							<v-card-title class="font-weight-bold">
								{{ n.name }}
							</v-card-title>

							<v-card-text>
								{{ n.description }}
							</v-card-text>

							<v-card-actions>
								<v-btn :href="n.link" target="_blank" color="info">
									{{ n.cta }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-tools',

		components: {},

		data() {
			return {
				tools: [
					{
						name: 'Repayment Tool',
						description: "See how your clients' voluntary repayments and drawdowns could affect their loan and property value.",
						cta: 'Launch Tool',
						link: 'https://airlaterlife.co.uk/repayments-calculator'
					},
					{
						name: 'White Label Tool',
						description: 'Sign up or login to our White Label LTV Tool to create a bespoke tool in your company branding.',
						cta: 'Launch Tool',
						link: 'https://whitelabel.airlaterlife.co.uk'
					},
					{
						name: 'Affordability Tools',
						description: 'Register your interest for exclusive access to our affordability tools that could support your advice process.',
						cta: 'Register Interest',
						link: 'https://airlaterlife.co.uk/gain-exclusive-access-to-our-affordability-tools'
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>
