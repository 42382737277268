<!--
	@name app-comprehensive-conversations-manifesto
	@description Comprehensive Conversations Manifesto
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img
			src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/NAoT2bSBw3/5-air-manifesto-2560x1440.webp"
			:height="$vuetify.breakpoint.mdAndUp ? '600' : ''"
		>
			<v-container style="position: relative" fill-height>
				<div>
					<!-- <div class="text-h4 white--text">Air Sourcing</div> -->
					<div class="text-h3 mt-6 white--text">
						Comprehensive
					</div>
					<div class="text-h3 mt-2 white--text">
						Conversations campaign
					</div>
					<div class="text-h5 mt-6 white--text">
						Manifesto
					</div>
				</div>
			</v-container>
		</v-img>

		<!-- About -->
		<div style="background-color: #f6f6f6">
			<v-container>
				<div class="text-center d-flex flex-column align-center py-8" :class="{ 'py-16': $vuetify.breakpoint.mdAndUp }">
					<div>
						<p class="text-h6">
							The purpose of this document is to create a manifesto underpinning the Comprehensive Conversations campaign, where advisers, lenders, networks and other
							professionals can commit to offering more options and moving to ‘safer tracks'.
						</p>
					</div>
				</div>
			</v-container>
		</div>

		<!-- Comprehensive Conversations Movement -->
		<div :class="{ 'bg-comprehensive': $vuetify.breakpoint.lgAndUp }" :style="{ height: $vuetify.breakpoint.mdAndUp ? '800px' : '' }">
			<v-row class="fill-height">
				<v-col lg="7" md="12" sm="12" xs="12">
					<div class="fill-height d-flex align-center" :style="{ padding: `24px ${$vuetify.breakpoint.mdAndUp ? '140px' : '70px'}` }">
						<div>
							<div class="text-h4 font-weight-bold" style="line-height: 1.5">
								The Comprehensive <br />Conversations Movement
							</div>
							<div class="text-body-1 font-weight-bold font-italic mt-8" style="line-height: 2.5">
								<p>
									Following the introduction of the FCA Consumer Duty as Principle 12 in the FCA Handbook and recent reviews into the UK later life advice sector,
									advice firms and product providers are re-examining how financial advice and lending products are offered to individuals over the age of 50.
								</p>
							</div>
							<div class="mt-8">
								<p class="text-h6 font-weight-regular">
									The products that we as lenders, adviser networks, and clubs respectively develop, advise on, or connect on, offer much-needed solutions for
									many older borrowers and retirees. It's time that we stopped seeing mainstream and later life as two totally discrete markets, and instead, one
									overall market serving the borrowing needs of customers, reflecting their evolving needs as they move through lifestages.
								</p>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>

		<div style="background-color: #f6f6f6">
			<v-container>
				<div class="text-center d-flex flex-column align-center py-8" :class="{ 'py-16': $vuetify.breakpoint.mdAndUp }">
					<div>
						<p class="text-h6">
							The best way to ensure that every client or customer has the right products that delivers good outcomes, is to have a comprehensive conversation of
							their needs which considers a wide range of products, ensuring that limitations in the advisers own breadth of advice services is not a factor in
							establishing the best outcome for the customer, through more professionals expanding their specialisms to encompass Later Life Advice supplemented by
							triage and referral services where required.
						</p>
					</div>
				</div>
			</v-container>
		</div>

		<div class="mt-2" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<div class="text-h4 text-center mb-4" :class="{ 'mb-10': $vuetify.breakpoint.mdAndUp }">
					<p>As UK mortgage professionals</p>
				</div>

				<v-row>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/A8JZvki9RC/certificate-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We will adhere to regulatory and industry guidance from key industry bodies including the FCA, ERC, AMI, UK finance,</strong> as well as
								continue to monitor for further guidance and insight from these bodies on how we should best serve customers.
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/Bxw34tBlfC/recommend-a-friend-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We commit to exploring all the options available to our customers and to expand our advice, product or service offerings where we can.</strong>
								We commit to ensuring that customers have access to advice across a range of suitable products and services; through expanding our advice service,
								or via product triage and referral systems. Ultimately, all customers over 50 will be able to access advice services which ensure all suitable
								products are considered to deliver advice on the best product to suit their needs.
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/RWUcQ8UIfH/read-online-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We commit to ensuring that customers have an understanding of non-product related options too, and providing the right advice irrespective of
									remuneration considerations.</strong>
								In particular, this could include discussions around downsizing, funding via government grants and using other (non-housing equity) assets.
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/Q5nPVxUlkj/education-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We will provide support and education where possible to support continually enhancing the capabilities within our sector and will stay updated
									with the most recent education ourselves.</strong>
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/SkkzI7rwPs/processing-1-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We will use tools and technology that keep consumer affordability at the heart of our advice processes, and support development of these where
									possible for others in our industry.</strong>
								We commit to ensuring customers fully understand the costs of meeting their preferences so we can evidence that they have made concrete decisions
								with full understanding of the products and implications. For example, if they require immediate security of tenure of their home, or have
								flexibility to modify payments over the term of the product, we will provide full details on how much this may cost over alternatives which do not
								provide this.
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" sm="6" xs="12">
						<div class="text-center px-4">
							<div>
								<img
									src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/Vmf2B8LEMj/paper-tick-400x400.png"
									height="100"
									alt=""
								/>
							</div>
							<div class="text-body-2 mt-2">
								<strong>We will create safer tracks for our wider industry to match customer good outcomes and meet regulatory requirements. </strong>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<!-- Download Manifesto -->
		<div class="mt-2 py-8 text-center" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }" style="background-color: #f6f6f6">
			<v-container>
				<v-row :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '' }" class="fill-height">
					<v-col md="7" sm="12" xs="12">
						<div class="fill-height d-flex align-center py-4">
							<div>
								<div class="text-body-1 mt-4">
									<p>
										Through all these avenues and more, we pledge to support this movement in creating more comprehensive conversations for customers over the
										age of 50 across the UK mortgage market.
									</p>
								</div>
								<v-btn class="mt-6" color="info" :href="'document/Air_Manifesto.pdf' | urlize" target="_blank">
									<v-icon class="mr-2">
										mdi-download
									</v-icon> Download the Manifesto
								</v-btn>
							</div>
						</div>
					</v-col>
					<v-col md="5" sm="12" xs="12" class="pa-0" :style="{ 'max-height': $vuetify.breakpoint.mdAndUp ? '600' : '200' }">
						<v-img
							src="https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/NgkMrvqmGD/air-manifesto-pdf-cta-image-901x948.png"
							width="100%"
							:height="$vuetify.breakpoint.mdAndUp ? '100%' : '400'"
							contain
							alt=""
						/>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<!-- Contact Form -->
		<div class="py-16">
			<v-container style="max-width: 800px">
				<div style="background-color: #f6f6f6" class="pa-8">
					<div class="text-h4 text-center">
						Sign up now
					</div>
					<validation-observer ref="observer">
						<v-form class="mt-8" @submit.prevent="submit">
							<v-row>
								<v-col cols="12" md="6">
									<validation-provider v-slot="{ errors }" name="First Name" rules="required">
										<v-text-field v-model="firstName" :disabled="disabled" :error-messages="errors" label="First Name" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12" md="6">
									<validation-provider v-slot="{ errors }" name="Last Name" rules="required">
										<v-text-field v-model="lastName" :disabled="disabled" :error-messages="errors" label="Last Name" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<validation-provider v-slot="{ errors }" name="Firm Name" rules="required">
										<v-text-field v-model="firmName" :disabled="disabled" :error-messages="errors" label="Firm Name" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<validation-provider v-slot="{ errors }" name="FCA Number" rules="required|numeric|min:6|max:7">
										<v-text-field v-model="fcaNumber" :disabled="disabled" :error-messages="errors" label="FCA Number" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<validation-provider v-slot="{ errors }" name="Email" rules="required|email">
										<v-text-field v-model="email" :disabled="disabled" :error-messages="errors" label="Email" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<validation-provider v-slot="{ errors }" name="Phone" rules="required|numeric">
										<v-text-field v-model="phone" :disabled="disabled" :error-messages="errors" label="Phone" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<validation-provider v-slot="{ errors }" name="Message" rules="required">
										<v-textarea v-model="message" :disabled="disabled" :error-messages="errors" label="Message" :hide-details="!errors?.length" filled />
									</validation-provider>
								</v-col>
								<v-col cols="12">
									<v-btn color="info" :disabled="disabled" :loading="loading" type="submit" large class="white--text" block>
										Submit
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</validation-observer>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { ElementTools } from '@/utils';
	import { mapActions } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-comprehensive-conversations-manifesto',

		components: {
			ValidationObserver,
			ValidationProvider
		},

		data() {
			return {
				firstName: '',
				lastName: '',
				firmName: '',
				fcaNumber: '',
				email: '',
				phone: '',
				message: '',
				loading: false,
				disabled: false
			};
		},

		computed: {},

		created() {},

		methods: {
			...mapActions('SignUpManifesto', ['signUp']),

			submit() {
				this.$refs.observer.validate().then(async(valid) => {
					if (!valid) return false;

					this.loading = true;

					this.signUp({
						firstName: this.firstName,
						lastName: this.lastName,
						firmName: this.firmName,
						fcaNumber: this.fcaNumber,
						email: this.email,
						phone: this.phone,
						message: this.message
					})
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', {
								text: 'Thank you for signing up to our Comprehensive Conversations Manifesto',
								timeout: 10000,
								close: true
							});
							this.resetForm();
						})
						.catch(() => {
							ElementTools.fireNotification(this.$el, 'error', 'Failed to sign up to the Comprehensive Conversations Manifesto. Please try again later.');
						})
						.finally(() => {
							this.loading = false;
						});
				});
			},

			resetForm() {
				this.firstName = '';
				this.lastName = '';
				this.firmName = '';
				this.fcaNumber = '';
				this.email = '';
				this.phone = '';
				this.message = '';
				this.disabled = true;
				this.$refs.observer.reset();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.bg-comprehensive {
		background-image: url('https://stories.rostrum.agency/air/comprehensive-conversations-campaign-manifesto/assets/b3deZtw3Oj/air-manifesto-2560x1440.webp');
		background-size: cover;
	}

	::v-deep .v-text-field__slot textarea {
		margin-top: 30px !important;
	}
</style>
