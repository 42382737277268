import { AccountUserOrganisationTradingStyle } from '@/service';

/**
 * @class @name TradingStyle
 * @description Exposes menu store module
 */
export default class TradingStyle {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			organisationTradingStyleList: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setOrganisationTradingStyleList
			 * @param {Object} state
			 * @param {Array} data
			 */
			setOrganisationTradingStyleList(state, data) {
				state.organisationTradingStyleList = data;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadOrganisationTradingStyleList
			 * @param {function} commit
			 */
			async loadOrganisationTradingStyleList({ commit }) {
				return AccountUserOrganisationTradingStyle.list()
					.then(({ data }) => commit('setOrganisationTradingStyleList', data))
					.catch((error) => {
						throw error;
					});
			}
		};
	}
}
