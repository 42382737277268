<template>
	<v-navigation-drawer v-model="drawer" app right temporary>
		<v-list
			dense
			nav
		>
			<v-list-item
				v-for="item in frontMenu?.list"
				:key="item.nameUnique"
				link
				:href="isExternalLink(item) ? item.url : ''"
				:to="!isExternalLink(item) ? item.url : ''"
				:target="item.target == null ? '_self' : item.target"
			>
				<v-list-item-content>
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-layout-default-sidebar',

		data() {
            return {
                items: [
                    { title: 'Dashboard', icon: 'mdi-view-dashboard' },
                    { title: 'Photos', icon: 'mdi-image' },
                    { title: 'About', icon: 'mdi-help-box' }
                ],
                drawer: null
            };
        },

        computed: {
			...mapState('CmsMenu', ['frontMenu'])
		},

        methods: {
            isExternalLink(link) {
				return link.url.includes('http');
			},

			openNavigation() {
				this.drawer = true;
			}
		}
	};
</script>
