import { $http } from '@/utils';

/**
 * @namespace Service
 * @class File
 * @exports Service/File
 * @description API File Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Oauth {
	/**
	 * @static @name get
	 * @description get oauth information
	 * @returns {Object} Promise a http response
	 */
	static get(partner, providerNameUnique, domain = null) {
		let url = `oauth/${partner}/authorize?provider=${providerNameUnique}`;
		if (domain) url += `&domain=${domain}`;
		return $http.get(url);
	}

	/**
	 * @static @name post
	 * @description post oauth information
	 * @returns {Object} Promise a http response
	 */
	static gateway(partner, payload) {
		return $http.post(`oauth/${partner}/authorize/gateway`, payload);
	}

	/**
	 * @static @name post
	 * @description post oauth information
	 * @returns {Object} Promise a http response
	 */
	static post(partner, payload) {
		return $http.post(`oauth/${partner}/authorize`, payload);
	}
}
