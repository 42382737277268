<template>
	<common-dialog ref="dialog" color="error" :component="$options.name">
		<template #header>
			Restrict Providers Filter
		</template>
		<template #body>
			<p class="text-body-1">
				The 'Restrict Providers' filter was used during this sourcing session. Should you wish, you can supply a summary of why those Providers were restricted. This
				summary will then be printed alongside your Research PDF.
			</p>
			<validation-observer v-slot="{ invalid, validate }">
				<form @submit.prevent="validate().then(submit)">
					<validation-provider v-slot="{ errors }" name="Reason" rules="required">
						<v-textarea v-model="reason" rows="3" :error-messages="errors" label="Providers were restricted due to ..." outlined />
					</validation-provider>
					<div class="d-flex">
						<v-btn color="primary" class="mr-4" type="submit" :disabled="invalid">
							Submit and download PDF
						</v-btn>
						<v-btn color="secondary" outlined @click="submit">
							Skip and Download PDF
						</v-btn>
					</div>
				</form>
			</validation-observer>
		</template>
	</common-dialog>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-client-property-dialog-download-results',

		components: { ValidationObserver, ValidationProvider, CommonDialog },

		data() {
			return {
				reason: null
			};
		},

		methods: {
			open() {
				this.$refs.dialog.open();
			},

			close() {
				this.$refs.dialog.close();
			},

			submit() {
				this.$emit('download-document', this.reason ? { restrictedProviderReason: this.reason }: undefined);
				this.close();
			}
		}
	};
</script>
