<!--
	@name common-chart-sparkline
	@description Sparkline
	@date 2020/05/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-skeleton-loader v-if="!data.labels || !data.value" type="card-heading, image" class="px-0" />
		<v-sheet v-else>
			<v-card :color="color" elevation="0" dark tile>
				<v-list dense flat tile dark :color="color">
					<v-list-item tile>
						<v-list-item-content>
							<v-list-item-title class="font-weight-light text-body-1">
								{{ title }}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon v-show="false">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn target="_blank" icon small v-on="on">
										<v-icon>mdi-download</v-icon>
									</v-btn>
								</template>
								<span>Download Report</span>
							</v-tooltip>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
				<v-card-text class="text-center pt-0">
					<v-sheet color="rgba(255, 255, 255, .10)">
						<v-sparkline :labels="data.labels" :value="data.value" color="rgba(255, 255, 255, .7)" height="75" padding="24" stroke-linecap="round" smooth>
							<template #label="item">
								{{ item.value }}
							</template>
						</v-sparkline>
					</v-sheet>
				</v-card-text>
			</v-card>
		</v-sheet>
	</div>
</template>

<script>
	export default {
		name: 'common-chart-sparkline',

		props: {
			color: { type: String, default: 'blue darken-4' },
			title: { type: String, required: true },
			data: { type: Object, required: true }
		}
	};
</script>

<style scoped></style>
