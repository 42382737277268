<!--
	@name app-source-results-toolbar
	@description Show source results page toolbar
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-toolbar class="mb-0" :component="$options.name">
			<v-toolbar-title class="font-weight-bold">
				<span class="success--text"> {{ availableProductCount }} Matching Products </span> /
				<common-base-menu-hint color="amber accent-2" :border="{ width: '6px', style: 'solid', color: 'orange.accent2' }">
					<template #target>
						<v-tooltip bottom>
							<template #activator="{ on: tooltip }">
								<span v-if="loadingProducts" class="error--text">{{ outsideCriteriaProductCount }} Outside Criteria</span>
								<span
									v-else
									class="ga--trigger ga--outside-criteria-opened error--text text-decoration-underline"
									data-ga-id="toolbar"
									style="cursor: pointer"
									v-on="tooltip"
									@click="$emit('open-outside-criteria-products-dialog')"
								>
									{{ outsideCriteriaProductCount }} Outside Criteria
								</span>
							</template>
							<span>Click here to see all products outside criteria</span>
						</v-tooltip>
					</template>
					<template #standard>
						<p class="mb-0 text-body-2 black--text font-weight-medium">
							Click <span class="error--text font-weight-bold">{{ outsideCriteriaProductCount }} Outside Criteria</span> above to view a breakdown of ALL restricted
							products - searchable and grouped by restriction.
						</p>
					</template>
				</common-base-menu-hint>
				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-progress-linear
							v-show="loadingProducts"
							class="ga--trigger ga--retrieving-live-rates-opened mt-1"
							data-ga-id="toolbar"
							indeterminate
							color="orange"
							height="8"
							style="cursor: pointer"
							v-on="on"
							@click="$emit('open-product-loader-dialog')"
						/>
					</template>
					<span class="text-center"><span class="font-weight-medium">Retrieving Live Rates...</span><br />Click to open full summary</span>
				</v-tooltip>
			</v-toolbar-title>
			<v-spacer />
			<v-toolbar-items>
				<common-base-menu-hint v-if="site.knowledgeBankAccess" ref="knowledgeBankOnBoardingHint">
					<template #target>
						<v-tooltip bottom>
							<template #activator="{ on: tooltip }">
								<v-btn
									class="ga--trigger ga--knowledge-bank-opened"
									data-ga-id="toolbar"
									text
									target="_blank"
									color="blue darken-2"
									:disabled="loadingProducts || isArchive"
									v-on="tooltip"
									@click="$emit('open-knowledge-bank-criteria-dialog')"
								>
									<v-icon left>
										mdi-compass
									</v-icon>
									Criteria
								</v-btn>
							</template>
							<span>Overlay product criteria direct from Knowledge Bank</span>
						</v-tooltip>
					</template>
					<template #standard>
						<h2 class="font-weight-black text-body-1 mb-2">
							Hi, i'm new here...
						</h2>
						<p class="mb-0 text-body-2">
							Our EXCLUSIVE integration with Knowledge Bank has launched! Click the <b>CRITERIA</b> button to see how it works.
						</p>
					</template>
				</common-base-menu-hint>

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn
							class="ga--trigger ga--defaqto-opened"
							data-ga-id="toolbar"
							color="#005493"
							text
							href="https://www.defaqto.com/star-ratings/money/equity-release/"
							target="_blank"
							v-on="on"
						>
							<v-icon left>
								mdi-star
							</v-icon>
							defaqto
						</v-btn>
					</template>
					<span>Click here to view product ratings from defaqto</span>
				</v-tooltip>

				<app-common-dialog-glossary button-class="ga--trigger ga--glossary-opened faq-button" data-ga-id="toolbar" :items="glossaryItems" title="Glossary of Terms" />

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn
							class="ga--trigger ga--print"
							data-ga-id="toolbar"
							color="primary darken-1"
							text
							:loading="loadingProducts || generatingResultsDocument"
							v-on="on"
							@click.stop="printResults"
						>
							<v-icon>{{ displayDownloadIcon[iterationId] ? 'mdi-file-download' : 'mdi-printer' }}</v-icon>
						</v-btn>
					</template>
					<span>Print Results</span>
				</v-tooltip>

				<v-tooltip v-if="form('source_result_filter').value" bottom>
					<template #activator="{ on }">
						<v-btn
							class="ga--trigger ga--filters-opened"
							data-ga-id="toolbar"
							text
							:loading="loadingProducts"
							color="primary darken-2"
							v-on="on"
							@click.stop="$emit('open-filters')"
						>
							<v-icon>mdi-filter</v-icon>
						</v-btn>
					</template>
					<span>Open Filters</span>
				</v-tooltip>

				<v-tooltip v-if="!isFactFind" bottom>
					<template #activator="{ on }">
						<v-btn class="ga--trigger ga--back-button" data-ga-id="toolbar" text color="primary darken-3" v-on="on" @click="$emit('open-back-to-sourcing-dialog')">
							<v-icon>mdi-backburger</v-icon>
						</v-btn>
					</template>
					<span>Back to Sourcing Details</span>
				</v-tooltip>
			</v-toolbar-items>
		</v-toolbar>

		<app-source-result-dialog-research-doucment-restrict-filter ref="restrictFilter" @download-document="downloadDoc" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import documentMixin from '@/mixins/document';
	import CommonBaseMenuHint from '@/component/common/base/menu-hint';
	import AppCommonDialogGlossary from '@/component/common/dialog/glossary';
	import AppSourceResultDialogResearchDoucmentRestrictFilter from '@/component/app/source-result/dialog/research-document-restrict-filter';

	export default {
		name: 'source-result-toolbar',

		components: { CommonBaseMenuHint, AppCommonDialogGlossary, AppSourceResultDialogResearchDoucmentRestrictFilter },

		mixins: [documentMixin],

		props: {
			availableProductCount: { type: Number, required: true, default: 0 },
			outsideCriteriaProductCount: { type: Number, required: true, default: 0 },
			loadingProducts: { type: Boolean, required: true },
			iteration: { type: Object, required: true, default: () => ({}) }
		},

		data() {
			return {
				glossaryItems: [
					{
						key: 'knowledgeBank',
						icon: false,
						chipClass: 'pink--text yellow',
						iconColour: 'accent',
						title: 'Fully integrated Knowledge Bank criteria search',
						description:
							'Knowledge Bank is a multi-award winning lending criteria system designed by brokers to help you place your business. Perform as many searches as you like across ' +
							'multiple criteria categories containing over 102,000 individual criteria to find out which lender will accept your case.',
						type: 'image',
						imagePath: 'logo/organisation/knowledge_bank.svg'
					},
					{
						key: 'productCount',
						icon: false,
						chipClass: 'pink--text yellow',
						title: 'Real-time count of Available and Restricted products',
						description:
							'Keep an eye on the real time product counters to see how many products fit your search criteria and how many products are restricted. These counters update automatically every time you adjust a filter item, to give you instant feedback as to how your changes have affected the results. <span class="purple--text"><span class="font-weight-bold">PRO TIP</span> - click \'Outside Criteria\' to open up a new window showcasing all the products outside criteria, grouped by the reason they are restricted, with search functionality and advice as to how these products can be made available.</span>',
						type: 'custom'
					},
					{
						key: 'columnOrdering',
						icon: false,
						chipClass: 'pink--text yellow',
						title: 'Multi-sort Table Columns',
						description:
							'Sort your product results by clicking the relevant column heading. When pointing UP, the arrow indicates that a column has been sorted in ASCENDING order (lowest to highest). When pointing DOWN, the arrow indicates that a column has been sorted in DESCENDING order (highest to lowest). You can sort by any number of columns, giving you total control over how your results are displayed. When sorting on multiple columns, a number will appear next to each column header to indicate in what order the columns are being considered. Use the example table on the left to see a simple demonstration of this powerful functionality in action.',
						type: 'dataTable',
						data: {
							headers: [
								{ text: 'A', align: 'left', value: 'a' },
								{ text: 'B', align: 'left', value: 'b' }
							],
							rows: [
								{ a: 1, b: 2 },
								{ a: 3, b: 6 },
								{ a: 5, b: 6 }
							]
						}
					},
					{
						key: 'filterButton',
						icon: 'mdi-filter',
						iconColour: 'pink accent-2',
						title: 'Fixed Action Filter Button',
						description:
							"We've vastly increased both the breadth and depth of our filters, allowing you to drill down into product features and customer needs with far " +
							'greater detail and efficiency. You can access the filters either from the icon at the top right of the toolbar, or the fixed position button that ' +
							'will appear at all times to the bottom right of your screen.',
						type: 'button',
						buttonClass: 'pulse--accent',
						isFab: true
					},
					{
						key: 'productSelectSwitch',
						icon: false,
						chipClass: 'blue--text teal accent-2',
						titleIcon: 'mdi-refresh-circle',
						title: 'Product Select Switch',
						description:
							'You can select one or more products by using the switch located at the far right of the relevant product row. ' +
							'Once any one product has been selected, a toolbar will display at the bottom of the screen listing the number of chosen products and the actions available to you. ' +
							'Request single product or multiple product KFIs from a single data entry form OR restrict multiple products at once.',
						type: 'switch',
						class: 'ma-0 pa-0 mr-n3 pr-0',
						colour: 'primary'
					},
					{
						key: 'productActions',
						icon: 'mdi-dots-horizontal',
						iconColour: 'secondary',
						title: 'Product Actions',
						description:
							'Our new suite of Product Actions is designed to save you valuable time with a more personalised results set. When viewing your product results, ' +
							'hover over this button to bring up the full list of available actions for that product.',
						type: 'button',
						buttonClass: '',
						isSmall: true
					},
					{
						key: 'productCriteria',
						icon: 'mdi-magnify',
						iconColour: 'green',
						title: 'Product Actions / Product Criteria',
						description:
							'Click Product Criteria to bring up all the data we hold about that particular product. For more complex cases, you can find everything here from ' +
							'restrictions on concrete construction properties to policies on adverse credit checks and much, much more.',
						type: 'icon'
					},
					{
						key: 'reportIssue',
						icon: 'mdi-comment-alert',
						iconColour: 'warning',
						title: 'Product Actions / Report an issue',
						description:
							'Found an issue with a product? You can now report product issues direct from the results screen. Using this facility will give our support team all ' +
							'the necessary information to investigate the issue in co-operation with the relevant Provider and deploy a quick resolution.',
						type: 'icon'
					},
					{
						key: 'restrictProduct',
						icon: 'mdi-close-circle',
						iconColour: 'error lighten-1',
						title: 'Product Actions / Restrict Product',
						description:
							'Need to restrict a product for reasons not covered by our filters? You can now restrict individual products and provide bespoke rationale that will ' +
							'appear in both the results screen and the printed PDF.',
						type: 'icon'
					},
					{
						key: 'productFeatures',
						icon: false,
						title: 'Product Features',
						description: 'Specific features, such as whether the product is income assessed or provides a fixed erc, will be displayed underneath the product name.',
						type: 'custom'
					},
					{
						key: 'procurationFees',
						icon: false,
						title: 'Procuration Fees',
						description:
							'Where applicable, Club deals will be displayed underneath the product name. Where deals have been secured for multiple submission routes, each route will ' +
							'be displayed individually. You can view the available procuration fee by hovering over the relevant submission route.',
						type: 'custom'
					},
					{
						key: 'outsideCriteriaProducts',
						icon: false,
						title: 'Products Outside Criteria',
						description: 'If a product is outside criteria, it will be clearly identified with a red background and a reason for the restriction.',
						type: 'custom'
					},
					{
						key: 'activeFilters',
						icon: 'mdi-check-network',
						iconColour: 'success',
						title: 'Active Filters',
						description:
							'Whenever a filter is activated, this icon will appear to the right of the filter item to give you an instant interpretation of which filters are ' +
							'affecting the results.',
						type: 'icon'
					},
					{
						key: 'paymentTerm',
						icon: 'mdi-home-clock',
						iconColour: 'success',
						title: 'Payment Term',
						description: 'The payment term for this product subceeds the comparision term.',
						type: 'icon'
					},
					{
						key: 'repossessionRisk',
						icon: 'mdi-home-alert',
						iconColour: 'indigo',
						title: 'Repossession Risk Warning',
						description: 'This product carries a risk of repossession if monthly payments cease.',
						type: 'icon'
					},
					{
						key: 'lockDownRate',
						icon: 'mdi-lock-off',
						iconColour: 'red',
						title: 'Lock Down Rate',
						description: 'Your payment term does not meet the requirements to lock in the discounted rate.',
						type: 'icon'
					},
					{
						key: 'rateIncrease',
						icon: 'mdi-arrow-up-bold-circle',
						iconColour: 'red',
						title: 'Rate Increase',
						description: 'A rate increase will come into effect when monthly payments cease.',
						type: 'icon'
					}
				],
				generatingResultsDocument: false,
				displayDownloadIcon: {}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),

			iterationId() {
				return this.$route.query.iteration;
			},

			processId() {
				return this.$route.params.processId;
			},

			isFactFind() {
				return this.$route.query && !!this.$route.query.factfind;
			},

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			}
		},

		methods: {
			...mapActions('AppSourceIteration', ['getIteration']),

			/**
			 * @name printResults
			 * @description TODO
			 */
			printResults() {
				if (this.iteration.data.filters.restrictedProviders.length && !this.iteration.data.s3Key) this.$refs.restrictFilter.open();
				else this.downloadDoc();
			},

			downloadDoc(data) {
				this.generatingResultsDocument = true;
				this.downloadDocument({
					processId: this.processId,
					iterationId: this.iterationId,
					s3Key: this.iteration?.data?.s3Key,
					filename: `Research PDF ${this.iteration.id}.pdf`,
					data
				})
					.then(async() => {
						if (!this.iteration.data.s3Key) {
							const { data } = await this.getIteration(this.iterationId);
							this.$emit('iteration-updated', data);
						}
						this.displayDownloadIcon[this.iterationId] = true;
					})
					.finally(() => (this.generatingResultsDocument = false));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
