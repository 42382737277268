import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Asset
 * @exports Service/Client/Asset
 * @description API Client Asset Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Asset {
	/**
	 * @static @name list
	 * @description Get all assets for a specific client (RBAC)
	 * @param {Object} clientId
	 * @returns {Object} Promise a http response
	 */
	static list(clientId, payload) {
		return $http.post(`client/${clientId}/asset/list`, payload);
	}

	/**
	 * @static @name get
	 * @description Get asset for specific client using CID and ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(clientId, id) {
		return $http.get(`client/${clientId}/asset/${id}`);
	}

	/**
	 * @static @name delete
	 * @description Hard delete client asset for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(clientId, id) {
		return $http.delete(`client/${clientId}/asset/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new client asset
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(clientId, payload) {
		return $http.post(`client/${clientId}/asset`, payload);
	}
}
