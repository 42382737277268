<!--
	@name app-reward-dialog-smallprint
	@description Reward dialog showing showing smallprint
	@date 2020/05/18
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable :component="$options.name">
		<v-card v-if="site" tile>
			<v-toolbar flat dark color="info" class="pl-3 pr-3 flex-grow-0">
				<v-toolbar-title> The Small Print </v-toolbar-title>
				<v-spacer />
				<v-btn icon dark @click="active = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-container fluid>
					<ul v-if="site" class="text-body-1">
						<li>Air Rewards will be added to your account upon receipt of a statement from the relevant lenders.</li>
						<li>Points will not be added without this confirmation.</li>
						<li>Due to current lender timescales, this could mean your points may not be added until the end of the following month.</li>
						<li>
							If by the end of the following month your points have not been allocated please contact us via the missing completions button. You will need to provide
							a copy of your completion letter to allow our team to investigate.
						</li>
						<li>
							Air Rewards will be re-set every 6 months. You will have 3 months to claim these points from the re-set date. If points are not claimed these will be
							lost.
						</li>
						<li>
							Once you have submitted your request to claim your points, you will receive an email from our team requesting your bank details. You must respond to
							this email in order for our team to process your request. You will receive this email in 1 - 2 working days following your request to claim your points.
							If you do not receive this email within 2 working days, please contact our team at
							<a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
						</li>
						<li>
							We aim to make Air Rewards payments weekly. Air Rewards payments are normally submitted every Monday, payment is made on the Wednesday and funds will be
							in the account on the Friday. Any claims made after 12pm Monday will be submitted the following Monday. There may be a delay during busy periods. If you
							have any queries about your payment please email <a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
						</li>
					</ul>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-reward-dialog-smallprint',

		data() {
			return {
				active: false
			};
		},

		computed: { ...mapState('CmsSite', ['site']) },

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open() {
				this.active = true;
			}
		}
	};
</script>
