import { LogActivityUser } from '@/service';
import { ActivityDataMapper } from '@/store/helpers/ActivityDataMapper';
/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class ActivityUser {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			activityList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setActivityList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setActivityList: (state, data) => (state.activityList = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadActivityList
			 * @param {function} commit
			 * @param {object} payload
			 */
			async loadActivityList(context, payload = {}) {
				// delete from payload before sending to API
				const mappingOptions = payload.mappingOptions;
				delete payload.mappingOptions;
				// default order of created descending
				if ('order' in payload == false) payload.order = { property: 'created', direction: 'desc' };
				if ('limit' in payload == false) payload = Object.assign(payload, { offset: 0, limit: 10 });
				try {
					let response = await LogActivityUser.list(payload);
					const a = new ActivityDataMapper();
					let logItems = a.mapData(response.data.data, mappingOptions);
					return logItems;
				} catch (e) {
					console.log(e);
				}
			},

			/**
			 * @async @name loadClientActivityList
			 * @param {function} commit
			 * @param {object} payload
			 */
			async loadClientActivityList(context, payload = {}) {
				Object.keys(payload?.payload).forEach((k) => payload.payload[k] == null && delete payload.payload[k]);
				try {
					let response = await LogActivityUser.clientList(payload.clientId, payload.payload);
					const a = new ActivityDataMapper();
					let logItems = a.mapData(response.data.data, payload.mappingOptions);
					return logItems;
				} catch (e) {
					console.log(e);
				}
			},

			/**
			 * @async @name addActivityUser
			 * @param {function} commit
			 */
			addActivityUser(context, payload) {
				return LogActivityUser.post(payload);
				// commit('setActivityUserList', response.data);
				// return response.data;
			},

			/**
			 * @async @name deleteActivityUser
			 * @param {function} commit
			 */
			async deleteActivityUser(context, id) {
				return LogActivityUser.delete(id);
			},
			/**
			 * @async @name deleteActivityUser
			 * @param {function} commit
			 */
			async deleteClientNote(context, id) {
				return LogActivityUser.delete(id);
			}
		};
	}
}
