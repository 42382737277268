<template>
	<v-dialog :value="active" eager scrollable :component="$options.name" persistent max-width="800" max-height="600">
		<v-card>
			<v-app-bar dark :color="color">
				<v-toolbar-title>
					{{ config.title }}
				</v-toolbar-title>

				<v-spacer />

				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-app-bar>

			<v-card-text class="dynamic-form-wrapper">
				<slot name="header" />

				<dynamic-form
					:form-schema="formSchema"
					:form-data="formData"
					:submit-button-loading="loading"
					submit-button-text="Print"
					:submit-button-block="false"
					:submit-button-large="false"
					@dynamic-form-submit="print"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import pdfMake from 'pdfmake/build/pdfmake';
	import pdfFonts from 'pdfmake/build/vfs_fonts';
	import { mapState } from 'vuex';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'common-dialog-dynamic-form-dialog',

		components: {
			DynamicForm
		},

		props: {
			color: { type: String, default: 'blue-grey v-app-bar-generic-gradient' },
			cpdTrainingItems: { type: Array, required: true },
			convertTime: { type: Function, required: true }
		},

		data() {
			return {
				config: {},
				active: false,
				loading: false,
				formData: {
					startDate: this.$moment().format('DD/MM/YYYY'),
					endDate: this.$moment().format('DD/MM/YYYY')
				},
				formSchema: [
					{
						type: 'date-picker',
						name: 'startDate',
						display: 'Start Date',
						rules: 'required|date_format:dd/MM/yyyy',
						sm: 6
					},
					{
						type: 'date-picker',
						name: 'endDate',
						display: 'End Date',
						rules: 'required|date_format:dd/MM/yyyy',
						sm: 6
					}
				]
			};
		},

		computed: {
			...mapState('Account', ['user'])
		},

		methods: {
			/**
			 * @name open
			 * @description Show dialog
			 * @param {Object} config
			 * @param {String} config.title Dialog title
			 */
			open(config) {
				this.active = true;
				this.config = config;
			},

			closeDialog() {
				this.active = false;
				this.$emit('closed', this.config);
			},

			/**
			 * @name print
			 * @description Print the CPD log.
			 */
			print() {
				pdfMake.vfs = pdfFonts.pdfMake.vfs;

				const startDate = this.$moment(this.formData.startDate, 'DD/MM/YYYY').startOf('day');
				const endDate = this.$moment(this.formData.endDate, 'DD/MM/YYYY').endOf('day');

				let rows = [];
				let total = 0;
				this.cpdTrainingItems.forEach((item) => {
					const itemDate = this.$moment(item.issued);
					if (itemDate.isBetween(startDate, endDate, undefined, '[]')) {
						total += item.points;
						rows.push([
							{ text: item.name, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
							{ text: `${this.convertTime(item.points)}`, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
							{ text: `${item.type}`, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
							{ text: itemDate.format('DD/MM/YYYY'), fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
							{ text: item.reflection, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] }
						]);
					}
				});

				const docDefinition = {
					pageOrientation: 'portrait',

					info: {
						title: `Personal CPD Log`,
						author: 'Air Sourcing'
					},
					images: {
						logo: 'https://s3.eu-west-2.amazonaws.com/air.assets/logo/brand/air_academy_logo.jpg'
					},
					content: [
						{
							alignment: 'center',
							image: 'logo',
							width: 200
						},
						{
							canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1 }],
							margin: [0, 20]
						},
						{
							alignment: 'left',
							text: `Personal CPD Log`,
							style: 'header',
							fontSize: 16,
							bold: true,
							margin: [0, 0, 0, 10]
						},
						{
							alignment: 'left',
							text: `Name: ${this.user.name}`,
							fontSize: 12,
							margin: [0, 2]
						},
						{
							alignment: 'left',
							text: `Date Produced: ${this.$moment().format('DD/MM/YYYY HH:mm:ss')}`,
							fontSize: 12,
							margin: [0, 2]
						},
						{
							alignment: 'left',
							text: `CPD Date Range: ${this.formData.startDate} - ${this.formData.endDate}`,
							fontSize: 12,
							margin: [0, 2]
						},
						{
							alignment: 'left',
							text: `Total Time CPD: ${this.convertTime(total)}`,
							fontSize: 12,
							margin: [0, 2, 0, 10]
						},
						{
							margin: [0, 10, 0, 0],
							layout: {
								fillColor: function(rowIndex) {
									return rowIndex === 0 ? '#E6E6E6' : null;
								}
							},
							table: {
								widths: ['23%', '10%', '12%', '12%', '43%'],
								headerRows: 1,
								body: [
									[
										{ text: 'Name', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Time', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Type', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Date', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Reflection Log', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] }
									],
									...rows
								]
							}
						}
					]
				};

				pdfMake.createPdf(docDefinition).download(`Personal CPD Log - ${this.$moment().format('DD-MM-YYYY')}.pdf`);
			}
		}
	};
</script>
