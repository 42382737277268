<!--
	@name common-base-dynamic-natural-language-form
	@description Natural language form
	@date 2020/03/28
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<form :class="textClass" :style="textStyle" @submit.prevent="submit">
		<span v-for="(field, index) in parsedSchema" :key="index">
			<span v-if="field.type === 'plain-text' && displayField(field)" :data-type="field.type" :data-display="field.display" :class="field.class">{{ field.display }}</span>
			<template v-if="(field.type === 'text' || field.type === 'number') && displayField(field)">
				<span v-if="field.prefix_text">
					{{ field.prefix_text }}
				</span>
				<v-menu v-model="menu[field.name]" :close-on-content-click="false" transition="scale-transition" :nudge-width="250">
					<template #activator="{ on }">
						<span v-if="field.currency" class="inline-field" :style="`color: ${themeColor}`" v-on="on">
							{{ field.prefix }}{{ formData[field.name] | numFormat() }}
						</span>
						<span v-else-if="field.percentage" class="inline-field" :style="`color: ${themeColor}`" v-on="on"> {{ formData[field.name] }}{{ field.suffix }} </span>
						<span v-else class="inline-field" :style="`color: ${themeColor}`" v-on="on"> {{ field.prefix }}{{ formData[field.name] }} </span>
					</template>
					<v-card>
						<validation-provider v-slot="{ errors }" :name="field.display" :rules="field.rules">
							<v-card-text>
								<v-text-field
									v-if="field.type === 'text'"
									v-model="menuField[field.name]"
									type="text"
									:label="field.display"
									:prefix="field.prefix"
									:hide-details="!errors.length"
									:error-messages="errors"
								/>
								<v-text-field
									v-else-if="field.type === 'number'"
									v-model.number="menuField[field.name]"
									type="text"
									:label="field.display"
									:step="(field.rules || '').includes('decimal') ? 'any' : false"
									:prefix="field.prefix"
									:hide-details="!errors.length"
									:error-messages="errors"
								/>
							</v-card-text>

							<v-card-actions>
								<v-spacer />

								<v-btn text @click="menu[field.name] = false"> Cancel </v-btn>
								<v-btn :color="theme" text @click="updateField(field.name, errors)"> Save </v-btn>
							</v-card-actions>
						</validation-provider>
					</v-card>
				</v-menu>
			</template>
			<template v-if="field.type === 'select' && displayField(field)">
				<v-menu v-model="menu[field.name]" close-on-content-click transition="scale-transition">
					<template #activator="{ on }">
						<span class="inline-field" :style="`color: ${themeColor}`" v-on="on"> {{ getDisplayValue(field) }} </span>
					</template>
					<v-card>
						<v-list>
							<v-list-item-group v-model="formData[field.name]" color="primary">
								<v-list-item v-for="(item, index) in field.options" :key="index" :value="item.value" :disabled="item.value == formData[field.name]">
									<v-list-item-content>
										<v-list-item-title v-text="item.text" />
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-card>
				</v-menu>
			</template>
		</span>

		<div v-if="submitButton" :class="submitButton.parentClass">
			<v-btn :style="`background-color: ${submitButtonColor}`" :dark="submitButton.dark" :loading="submitButtonLoading" type="submit">
				<v-icon v-if="submitButton.icon" left>
					{{ submitButton.icon }}
				</v-icon>
				{{ submitButton.text }}
			</v-btn>
		</div>
	</form>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';
	import colors from 'vuetify/lib/util/colors';
	import { get } from 'lodash';

	export default {
		name: 'common-base-dynamic-natural-language-form',

		components: {
			ValidationProvider
		},

		props: {
			formSchema: {
				type: Object,
				required: true
			},

			formData: {
				type: Object,
				required: true
			},

			submitButtonIcon: {
				type: [Boolean, String],
				default: false
			},

			submitButtonLoading: {
				type: Boolean,
				default: false
			},

			submitButton: {
				type: [Boolean, Object],
				default: () => ({
					icon: false,
					text: 'Submit',
					color: null,
					parentClass: 'text-center mt-6',
					dark: true
				})
			},

			theme: {
				type: String,
				default: 'primary'
			},

			textClass: {
				type: String,
				default: 'title font-weight-bold'
			},

			textStyle: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				menu: {},
				menuField: {}
			};
		},

		computed: {
			themeColor: function() {
				if(['primary','secondary','accent','info','warning','error','success'].includes(this.theme)) return this.$vuetify.theme.defaults.light[this.theme];
				return get(colors, this.theme);
			},

			submitButtonColor: function() {
				if (this.submitButton.color) return get(colors, this.submitButton.color);
				return this.themeColor;
			},

			/**
			 * @name parsedSchema
			 * @description Parses form text into a proper form schema to generate the form
			 * @returns {Array} Form schema
			 */
			parsedSchema() {
				let schema = [];
				let fields = this.formSchema.text.split(/\{([^}]*)\}/g);

				fields.map((item) => {
					if (item) {
						let field = this.formSchema.fields.find((schema) => schema.name === item);
						if (field) schema.push(field);
						else if (item) {
							schema.push({
								type: 'plain-text',
								display: item
							});
						}
					}
				});

				return schema;
			}
		},

		watch: {
			menu: {
				handler() {
					Object.keys(this.menu).map((key) => {
						this.menuField[key] = this.formData[key];
					});
				},
				deep: true
			}
		},

		methods: {
			displayField(field) {
				if (field.conditions && field.conditions.show) {
					for (let i = 0; i < field.conditions.show.length; i++) {
						if (!this.checkCondition(field.conditions.show[i])) return false;
					}
				}
				return true;
			},

			checkCondition(condition) {
				let target = this.formData[condition.name];

				if (condition.type === 'equals' && target == condition.value) return true;
				if (condition.type === 'not_equals' && target != condition.value) return true;
				if (condition.type === 'in_array') {
					if (condition.value.includes(target)) return true;
					if (condition.options != null && !target.length && condition.options.showWhenEmpty) return true;
				}
				if (condition.type === 'not_in_array' && !condition.value.includes(target)) return true;
				return false;
			},

			/**
			 * @name updateField
			 * @description Updates the actual form data and closes the menu if the field is valid
			 * @param {Object} field Field to be updated
			 * @param {Array} errors Error list
			 */
			updateField(field, errors) {
				if (!errors || !errors.length) {
					this.formData[field] = this.menuField[field];
					this.menu[field] = false;
				}
			},

			/**
			 * @name getDisplayValue
			 * @description Returns display value of the select field to show meaningful data
			 * @param {Object} field Field to be displayed
			 * @returns {String} Display value
			 */
			getDisplayValue(field) {
				return field.options.find((option) => option.value == this.formData[field.name]).text;
			},

			/**
			 * @name submit
			 * @description Dispatches a form submit event that passes form data
			 */
			submit() {
				this.$emit('submit', this.formData);
			}
		}
	};
</script>

<style lang="scss" scoped>
	form.title {
		line-height: 1.8;
	}

	.inline-field {
		display: inline-block;
		font-weight: 500;
		border-bottom: 2px dotted var(--v-primary-base);
		line-height: 1.5;
		cursor: pointer;
	}

	::v-deep .v-item-group {
		cursor: pointer;
	}
</style>
