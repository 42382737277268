<!--
	@name app-source-result-mortgage-item-drawdown
	@description Lifetime mortgage product item advance
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="item.restriction && item.restriction.type === 'hard'">
			--
		</div>
		<div v-else-if="!item.drawdownFacility.available" class="text-body-2 font-weight-medium">
			N/A
		</div>
		<div v-else>
			<span class="text-body-2 font-weight-medium d-block">
				£{{ facility }}
			</span>
			<div v-if="requestedfacility">
				<v-tooltip bottom content-class="text-center" max-width="300">
					<template #activator="{ on }">
						<span class="text-caption text-decoration-underline" v-on="on">Max £{{ maxFacility }} </span>
					</template>
					<span v-html="$sanitize(maxFacilityTooltip)" />
				</v-tooltip>
			</div>
			<v-tooltip v-if="drawdownNotes" bottom content-class="text-center" max-width="300">
				<template #activator="{ on }">
					<div class="mt-1">
						<v-chip color="purple lighten-1" text-color="white" label x-small active v-on="on">
							See more
						</v-chip>
					</div>
				</template>
				<span>
					{{ drawdownNotes }}
				</span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>
	import numeral from 'numeral';

	export default {
		name: 'app-source-result-mortgage-item-drawdown',

		props: {
			item: { type: Object, required: true }
		},

		computed: {
			/**
			 * @name facility
			 * @description TODO
			 * @return {Boolean} TODO
			 */
			facility() {
				return numeral(this.item.drawdownFacility.amount).format('0,0[.]00');
			},

			/**
			 * @name requestedfacility
			 * @description TODO
			 * @return {Boolean} TODO
			 */
			requestedfacility() {
				if (this.item.drawdownFacility.amount !== this.item.drawdownFacility.maximumAmount) return true;
				return false;
			},

			/**
			 * @name maxFacility
			 * @description TODO
			 * @return {Boolean} TODO
			 */
			maxFacility() {
				return numeral(this.item.drawdownFacility.maximumAmount).format('0,0[.]00');
			},

			/**
			 * @name maxFacilityTooltip
			 * @description TODO
			 * @return {Mixed} TODO
			 */
			maxFacilityTooltip() {
				if (!this.requestedfacility) return false;
				return `This product is being displayed with a £${numeral(this.item.drawdownFacility.amount).format('0,0[.]00')} drawdown. At the current advance, the maximum available drawdown is £${numeral(this.item.drawdownFacility.maximumAmount).format('0,0[.]00')}. Altering the drawdown amount could result in a higher/lower LTV/AER for this product.`;
			},

			/**
			 * @name drawdownNotes
			 * @description TODO
			 * @return {String|Boolean}
			 */
			drawdownNotes() {
				if (this.item.drawdownFacility.note) return this.item.drawdownFacility.note;
				return false;
			}
		}
	};
</script>
