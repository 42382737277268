<!--
	@name app-account-settings-details-preferences
	@description Account settings - User preferences tab content
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-5">
		<v-form v-if="preferences">
			<p class="text-body-1 mb-4">
				{{ site.softwareName }} would like to keep you up to date with the latest information about our services and special offers. Please let us know if you wish to be
				contacted via the following channels:
			</p>

			<v-switch v-model="preferences.email" label="Email" dense hide-details inset />
			<v-switch v-model="preferences.phone" label="Phone" dense hide-details inset />
			<v-switch v-model="preferences.mail" label="Mail" dense hide-details inset />

			<p class="text-body-1 mt-8 mb-4">
				From time to time we may contact you on behalf of our selected third party companies. Please let us know if you would be happy to be contacted.
			</p>

			<v-checkbox v-model="preferences.thirdParty" label="I am happy to be contacted" hide-details dense />

			<v-btn class="mt-6" color="primary" :loading="loading" @click="submit">
				Update Preferences
			</v-btn>
		</v-form>

		<div v-else>
			<v-skeleton-loader type="table-heading, list-item-three-line, table-heading" />
		</div>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-account-settings-details-preferences',

		components: {
			CommonDialogConfirm
		},

		data() {
			return {
				currentEmailPreference: null,
				loading: false,
				preferences: {}
			};
		},

		computed: {
			...mapState('Account', ['user', 'organisation']),
			...mapState('CmsSite', ['site'])
		},

		created() {
			this.currentEmailPreference = this.user.preference.email;

			this.preferences = {
				email: this.user.preference.email || false,
				phone: this.user.preference.phone || false,
				mail: this.user.preference.mail || false,
				thirdParty: this.user.preference.thirdParty || false
			};
		},

		methods: {
			...mapActions('Account', ['modifyUser']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 */
			submit() {
				if (this.currentEmailPreference && !this.preferences.email) {
					this.$refs.confirm
						.open('Update Preferences', `Are you sure you wish to unsubscribe from all email communication?`, {
							app_bar_color: 'warning',
							system_bar_color: 'warning darken-2'
						})
						.then(async() => this.submitPreferences())
						.catch(() => {
							this.preferences.email = true;
						});
				} else this.submitPreferences();
			},

			/**
			 * @name submitPreferences
			 * @description Event listener for form submissions
			 */
			async submitPreferences() {
				this.loading = true;

				this.modifyUser({
					data: {
						preference: {
							email: this.preferences.email,
							phone: this.preferences.phone,
							mail: this.preferences.mail,
							thirdParty: this.preferences.thirdParty
						}
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Preferences has been updated successfully'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update user preferences'))
					.finally(() => (this.loading = false));
			}
		}
	};
</script>
