/**
 * @namespace Utils
 * @class UrlTools
 * @exports Utils/DateTools
 * @description Util for manipulating dates
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class DateTools {
	/**
	 * @static @name filepathToComponentName
	 * @description TODO
	 * @return {TODO} TODO
	 */
	static stringToLocale(dateString) {
		const date = new Date(Date.parse(dateString));
		const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

		return date.toLocaleDateString('en-GB', options);
	}
}
