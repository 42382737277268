<!--
	@name app-source-result-mortgage-item-available
	@description Lifetime mortgage product item available
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="item.restriction && item.restriction.type === 'hard'">
			--
		</div>
		<div v-else>
			<span class="text-body-2 font-weight-medium">
				£{{ item.totalFacility | numFormat }}
			</span>

			<v-tooltip v-if="item.loanToValue" bottom content-class="text-center">
				<template #activator="{ on }">
					<span class="text-caption text-decoration-underline" v-on="on">
						Max: {{ item.loanToValue | numFormat('0,0.00') }}%
					</span>
				</template>
				The maximum LTV available for this product is {{ item.loanToValue | numFormat('0,0.00') }}%
			</v-tooltip>

			<v-tooltip v-if="item.advance && item.retentionPercentage" bottom content-class="text-center" max-width="450">
				<template #activator="{ on }">
					<div class="mt-1">
						<v-chip color="warning" text-color="white" label x-small active v-on="on">
							{{ item.retentionPercentage }}% Retention
						</v-chip>
					</div>
				</template>
				£{{ item.retentionAmount | numFormat }} ({{ item.retentionPercentage }}%) of Maximum Advance wil be retained by the lender, then available at the offered rate once
				a physical valuation has been conducted
			</v-tooltip>
			<v-tooltip v-if="item.advance && item.retainedFacilityPercentage" bottom content-class="text-center" max-width="450">
				<template #activator="{ on }">
					<div class="mt-1">
						<v-chip color="warning" text-color="white" label x-small active v-on="on">
							{{ item.retainedFacilityPercentage }}% Retained
						</v-chip>
					</div>
				</template>
				£{{ item.retainedAmount | numFormat }} ({{ item.retainedFacilityPercentage }}%) of Maximum Advance wil be retained by the lender, then available at the prevailing
				rate once a physical valuation has been conducted
			</v-tooltip>
			<v-tooltip v-if="item.propertyDevaluationPercentage" bottom content-class="text-center">
				<template #activator="{ on }">
					<div class="mt-1">
						<v-chip color="warning" text-color="white" label x-small active v-on="on">
							{{ item.propertyDevaluationPercentage }}% Devaluation
						</v-chip>
					</div>
				</template>
				Only {{ 100 - item.propertyDevaluationPercentage }}% of the Property value has been accounted for
			</v-tooltip>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-available',

		props: {
			item: { type: Object, required: true }
		},

		computed: {}
	};
</script>
