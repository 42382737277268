<!--
	@name app-source-result-mortgage-item-advance
	@description Lifetime mortgage product item advance
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="item.restriction && item.restriction.type === 'hard'">
			--
		</div>
		<div v-else>
			<span v-if="item.advance" class="text-body-2 font-weight-medium">
				£{{ item.advance | numFormat }}
			</span>
			<span v-else class="text-body-2 text-subtitle-2">
				N/A
			</span>
			<v-tooltip v-if="showCashback" bottom content-class="text-center" max-width="300">
				<template #activator="{ on }">
					<span class="text-caption text-tooltip" v-on="on">
						CB: £{{ item.cashback.amount | numFormat }}
					</span>
				</template>
				<span>
					Cashback
				</span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-advance',

		props: {
			item: { type: Object, required: true }
		},

		computed: {
			/**
			 * @name showCashback
			 * @description conditionally show cashback
			 * @return {Boolean} show cashback
			 */
			showCashback() {
				return this.item.cashback?.available && this.item.cashback?.amount && parseInt(this.item.cashback.amount) > 0;
			}
		}
	};
</script>
