<!--
	@name app-client-dashboard-workflow-lpa-get-started
	@description Client Workflow LPA get started
	@date 2024/02/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet>
			<v-row>
				<v-col cols="12">
					<video v-if="introResource" class="d-block ml-10 mb-6" :src="introResource.video" :poster="introResource.poster" controls width="50%" style="float: right" />
					<p class="text-body-1 font-weight-bold mb-6">
						If you're unable to help customers arrange the setting up of Powers of Attorney, then you can refer them to a specialist here who can.
					</p>
					<ul class="text-body-1 pl-8 mb-6">
						<li class="mb-2">
							Direct access to specialist Power of Attorney service providers.
						</li>
						<li class="mb-2">
							Instantly obtain pricing and services offered by each provider.
						</li>
						<li class="mb-0">
							Referral form is directly available for each of the providers to speed up the enquiry process.
						</li>
					</ul>
					<p class="text-body-1 mb-8">
						Click on the intro video for an explanation on how you can navigate AirFlow's Power of Attorney tile and maximise its benefits and features.
					</p>
					<v-btn color="primary" @click="hideGetStartedDisplay">
						Get started
					</v-btn>
					<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
				</v-col>
			</v-row>
		</v-sheet>
	</div>
</template>

<script>
	import {EventBus } from '@/utils';

	export default {
		name: 'app-client-dashboard-workflow-lpa-get-started',

		props: {
			introResource: { type: Object, required: false, default: null }
		},

		created() {
			this.$emit('toggle-intro-resource');
		},

		methods: {
			hideGetStartedDisplay() {
				this.$emit('hideGetStartedDisplay');
				this.$emit('toggle-intro-resource');
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>
