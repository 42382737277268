import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Wehbook
 * @exports Service/Option/Wehbook
 * @description API File Service
 * @date 2024/07/16
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Wehbook {
	/**
	 * @static @name get
	 * @description get webhook options
	 * @returns {Object} Promise a http response
	 */
	static list() {
		return $http.get(`option/webhook`);
	}
}
