<!--
	@name app-air-mail-edition
	@description AirMail edition page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<iframe
			v-if="isHashValid"
			class="d-block"
			:src="`https://player.flipsnack.com?hash=${hash}`"
			width="100%"
			height="800"
			seamless="seamless"
			scrolling="no"
			frameBorder="0"
			allowFullScreen
			allow="autoplay; clipboard-read; clipboard-write"
		/>
		<div v-if="!hash">
			<v-container>
				<ul>
					<li v-for="(item, index) in airMailItems" :key="index">
						<a :href="generateAirMailUrl(item)">{{ item.title }}</a>
					</li>
				</ul>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'app-air-mail-edition',

		components: {},

		data() {
			return {
				hash: null,
				month: null,
				isHashValid: false
			};
		},

		computed: {
			...mapState('CmsAirMail', ['airMailItems'])
		},

		watch: {
			airMailItems: {
				handler() {
					this.checkHashValidity(this.hash);
				},
				deep: true
			}
		},

		created() {
			this.loadAirMailList();
			this.hash = this.$route.params.hash;
			this.month = this.$route.params.month;
		},

		methods: {
			...mapActions('CmsAirMail', ['loadAirMailList']),

			/**
			 * Generates the URL for the air mail based on the given airMail object.
			 * @param {Object} airMail - The airMail object containing the hash, month, and year.
			 * @returns {string} The generated URL for the air mail.
			 */
			generateAirMailUrl(airMail) {
				return `air-mail/${airMail.hash}/${airMail.month}-${airMail.year}`;
			},

			checkHashValidity(hash) {
				// If hash is not provided, set isHashValid to false and return
				if (!hash) {
					this.isHashValid = false;
					return;
				}

				// Check if hash exists in airMailItems
				for (const item of this.airMailItems) {
					if (item.hash === hash) {
						this.isHashValid = true;
						// If month is not set, redirect to the air mail route with hash, month and year
						if (!this.month) this.$router.push(`/air-mail/${hash}/${item.month}-${item.year}`);
						return;
					}
				}

				// If hash is not found in airMailItems, set isHashValid to false and redirect to error page
				this.isHashValid = false;
				this.$router.push('/error/404');
			}
		}
	};
</script>

<style lang="scss" scoped></style>
