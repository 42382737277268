import AppKfiRequest from '@/component/app/kfi-request';
import AppSourceResult from '@/component/app/source-result';
import AppSource from '@/component/app/source';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for Source
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{ path: '/source/:processId?', name: 'source', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppSource },
	{
		path: '/results/:processId',
		name: 'result',
		meta: { layout: 'default-authed' },
		component: AppSourceResult,
		beforeEnter: (to, from, next) => {
			if (to.query && to.query.factfind) to.meta.layout = 'fact-find';
			next();
		}
	},
	{ path: '/request/:matterId', name: 'kfiRequest', meta: { layout: 'default-authed' }, component: AppKfiRequest }
];
