<!--
	@name app-source-result-product-loader-item
	@description Load products via API
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-list-item :component="$options.name" class="yellow lighten-4" dense>
			<v-list-item-avatar size="22" class="ma-0">
				<v-progress-circular v-if="pending || queued" class="ma-0" size="22" width="2" indeterminate />
				<v-icon v-else :color="icon.color" size="22">
					{{ icon.name }}
				</v-icon>
			</v-list-item-avatar>
			<v-list-item-content class="pl-3">
				<v-list-item-title class="text-body-1">
					{{ provider.name }}
				</v-list-item-title>
			</v-list-item-content>
			<v-list-item-action>
				<v-list-item-action-text v-if="failed">
					<v-btn
						class="ga--trigger ga--view-api-details"
						data-ga-id="product-loader"
						text
						small
						:disabled="loadingProducts && !webSocketEnabled"
						@click="openDialog"
					>
						View Details
					</v-btn>
				</v-list-item-action-text>
			</v-list-item-action>
		</v-list-item>

		<common-dialog ref="dialog" :max-width="600">
			<template #header>
				{{ provider.name }}
			</template>
			<template #body>
				<div v-if="attempts >= maxAttempts">
					<p class="text-body-1 mb-0">
						The {{ provider.name }} API error looks to be either a temporary or permanent error. If you are unable to proceed without viewing these products, please
						contact our support team on {{ site.contact.email }}.
					</p>
				</div>
				<div v-else>
					<p class="text-body-1 mb-5">
						{{ error || message }}
					</p>
					<v-btn
						v-if="provider.refreshToken"
						class="ga--trigger ga--update-api-credentials"
						data-ga-id="product-loader"
						:loading="loading.update"
						color="primary"
						@click="updateCredentials"
					>
						Update credentials
					</v-btn>
					<v-btn
						v-if="provider.refreshToken"
						class="ga--trigger ga--remove-api-credentials ml-2"
						data-ga-id="product-loader"
						:loading="loading.remove"
						color="error"
						@click="removeCredentials"
					>
						Remove credentials
					</v-btn>
					<v-btn
						v-else
						class="ga--trigger ga--resubmit-api-request"
						data-ga-id="product-loader"
						color="primary"
						@click="retry"
					>
						Resubmit Request
					</v-btn>
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-product-loader-item',

		components: { CommonDialog },

		props: {
			provider: { type: Object, required: true },
			loadingProducts: { type: Boolean, required: true }
		},

		data() {
			return {
				attempts: 0,
				maxAttempts: 3,
				error: null,
				account: null,
				loading: {
					update: false,
					remove: false
				}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			pending() {
				return this.provider.status === 'Pending';
			},

			success() {
				return this.provider.status === 'Success';
			},

			failed() {
				return this.provider.status === 'Failed';
			},

			queued() {
				return this.provider.status === 'queued';
			},

			message() {
				if (this.provider?.message) return this.provider.message;
				return `The ${this.provider.name} API has returned 0 products. If you believe this to be incorrect, click the Resubmit Request button below to try again.`;
			},

			icon() {
				return this.success
					? {
							name: 'mdi-check-bold',
							color: 'success'
					  }
					: this.failed
					? {
							name: 'mdi-exclamation-thick',
							color: 'error'
					  }
					: {};
			},

			webSocketEnabled() {
				return !this.$route.query.websocket || this.$route.query.websocket !== 'false';
			}
		},

		methods: {
			...mapActions('Oauth', ['getOauthOptions']),
			...mapActions('AppInterface', ['loadInterfaceList']),
			...mapActions('AppInterfaceUser', ['loadCredentialList', 'deleteCredential']),

			/**
			 * @name openDialog
			 * @description open error details dialog
			 */
			openDialog() {
				this.$refs.dialog.open();
			},

			/**
			 * @name closeDialog
			 * @description close error details dialog
			 */
			closeDialog() {
				this.$refs.dialog.close();
			},

			/**
			 * @name retry
			 * @description retry the request
			 */
			retry() {
				this.$emit('retry', this.provider, true);
				this.$refs.dialog.close();
				this.error = null;
				this.attempts++;
			},

			/**
			 * @name fetchInterface
			 * @description get credentials for the provider
			 */
			async fetchInterface() {
				let { data } = await this.loadInterfaceList({ where: { 'organisation.name_unique': this.provider.nameUnique } });
				this.account = data.data[0];

				let { data: credential } = await this.loadCredentialList({ where: { interface_id: this.account?.id } });
				this.account.credential = credential.data[0];

				return data;
			},

			/**
			 * @name openAuthPopup
			 * @description Popup window to authenticate account over an external party
			 */
			async updateCredentials() {
				this.loading.update = true;
				if (!this.account) await this.fetchInterface();
				if (this.account?.type === 'api') this.openAuthPopup();
			},

			/**
			 * @name openAuthPopup
			 * @description Popup window to authenticate account over an external party
			 */
			async openAuthPopup() {
				let { data } = await this.getOauthOptions({ partner: this.account.config.partner, providerNameUnique: this.account.organisationNameUnique, domain: this.site.name });

				let payload = {
					provider: data.provider,
					authUrl: data.url,
					authPopupOptions: this.account.config.authPopupOptions
				};

				new PopupWindow().openAuthPopup(
					payload,
					({ data }) => {
						if (data[payload.provider]) {
							ElementTools.fireNotification(this.$el, 'success', `Your ${this.account.organisationNameUnique} credentials has been updated successfully`);
							this.retry();
							this.closeDialog();
						} else ElementTools.fireNotification(this.$el, 'error', data.message);
					},
					// eslint-disable-next-line no-unused-vars
					({ error }) => {
						/* error */
					}
				);

				this.loading.update = false;
			},

			/**
			 * @name removeCredentials
			 * @description Revoke access request handler
			 */
			async removeCredentials() {
				this.loading.remove = true;

				if (!this.account) await this.fetchInterface();

				this.deleteCredential(this.account?.credential?.id)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', `You have been successfully removed credentials for ${this.account.organisationName}`);
						this.retry();
						this.closeDialog();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to remove credentials'))
					.finally(() => (this.loading.remove = false));
			}
		}
	};
</script>
