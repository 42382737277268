import { ClientAsset } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class ClientAssetStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchClients
			 * @param {function} commit
			 */
			async loadClientAssetList(context, { clientId, type, payload }) {
				let { data } = await ClientAsset.list(clientId, payload);
				return type ? data.data.filter((i) => i.type === type) || [] : data.data;
			},

			/**
			 * @async @name loadClientAsset
			 * @param {function} commit
			 */
			async loadClientAsset(context, { clientId, id }) {
				return ClientAsset.get(clientId, id);
			},

			/**
			 * @async @name fetchClients
			 * @param {function} commit
			 */
			async addClientAsset(context, { clientId, payload }) {
				return ClientAsset.post(clientId, payload);
			},

			/**
			 * @async @name deleteClientAsset
			 * @param {function} commit
			 */
			async deleteClientAsset(context, { clientId, id }) {
				return ClientAsset.delete(clientId, id);
			}
		};
	}
}
