<!--
	@name app-dashboard-api-health
	@description API health component
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" style="height: 100%" class="white">
		<common-structure-section :header-class="apiHealthStatus" flex-height :dark-header="true">
			<template #header>
				API Health
			</template>

			<template #body>
				<v-card v-if="loading" class="flex-grow-1">
					<v-skeleton-loader type="list-item-three-line" />
				</v-card>
				<div v-else class="d-flex flex-column justify-space-between h-100">
					<p class="mb-0">
						<span class="text-body-2">
							{{ apiHealthMessage }}
						</span>
					</p>
					<div class="flex-grow-0 pt-3">
						<v-btn
							class="ga--trigger ga--api-health-opened"
							data-ga-id="dashboard"
							:data-ga-value="apiHealthStatus"
							color="grey lighten-3"
							small
							@click="$refs.apiHealth.open()"
						>
							view health status
						</v-btn>
					</div>
				</div>
			</template>
		</common-structure-section>

		<app-common-api-health ref="apiHealth" :providers="providers" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';
	import AppCommonApiHealth from '@/component/app/common/api-health';
	import { EventBus } from '@/utils';

	export default {
		name: 'app-dashboard-api-health',

		components: {
			CommonStructureSection,
			AppCommonApiHealth
		},

		data() {
			return {
				loading: true,
				providers: []
			};
		},

		computed: {
			apiHealthErrors() {
				return this.providers.filter((p) => p.apiHealth?.status && ['error'].includes(p.apiHealth.status?.toLowerCase())).length;
			},

			apiHealthWarnings() {
				return this.providers.filter((p) => p.apiHealth?.status && ['warning'].includes(p.apiHealth.status?.toLowerCase())).length;
			},

			apiHealthIssues() {
				return this.apiHealthErrors + this.apiHealthWarnings;
			},

			apiHealthStatus() {
				if (this.loading) return 'grey lighten-2';
				if (this.apiHealthErrors) return 'error';
				if (this.apiHealthWarnings) return 'warning';
				return 'success';
			},

			apiHealthMessage() {
				if (this.apiHealthErrors)
					return 'Please be aware that there is an ongoing issue affecting at least 1 provider API integration for either product rates, instant KFI or magic link application.';
				if (this.apiHealthWarnings)
					return 'Please be aware that there is an ongoing issue affecting at least 1 provider API integration for either product rates, instant KFI or magic link application.';
				return 'There are currently no known issues affecting provider API integrations for product rates, instant KFI or magic link application.';
			}
		},

		created() {
			this.loadApiHealth();
			EventBus.$on('toggle-air-select-bypass', this.loadApiHealth);
			EventBus.$on('trading-style-selected', this.loadApiHealth);
			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			EventBus.$off('toggle-air-select-bypass', this.loadApiHealth);
			EventBus.$off('trading-style-selected', this.loadApiHealth);
			this.$websocket.$off('message', this.onMessage);
		},

		methods: {
			...mapActions('CmsConfig', ['loadConfig']),

			loadApiHealth() {
				this.providers = [];
				this.loadConfig({ type: 'lifetime-mortgage', name: 'sourcing' }).then((response) => {
					if (!response.error) {
						let providers = this.providers;
						if (response.data.api?.organisation) providers.push(...response.data.api.organisation);
						this.providers = providers.map((i) => ({ ...i, status: null }));
						this.loading = false;
					}
				});
			},

			onMessage(message) {
				if (message?.type !== 'apiHealth') return;
				this.providers = this.providers.map((p) => {
					if (p.apiHealth?.organisationProviderId === message.data.organisationProviderId) {
						return { ...p, apiHealth: message.data };
					}
					return p;
				});
			}
		}
	};
</script>
