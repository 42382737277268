import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Membership
 * @exports Service/Membership
 * @description API Membership Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Membership {
	/**
	 * @static @name get
	 * @description Get membership details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`membership/${id}`);
	}

	/**
	 * @static @name modify
	 * @description Update some membership details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	static patch(id, payload) {
		return $http.patch(`membership/${id}`, payload);
	}

	/**
	 * @static @name add
	 * @description Add a new membership resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`membership`, payload);
	}
}
