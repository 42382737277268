<!--
	@name app-source-result-info
	@description Show source results page
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="h-100">
		<v-card elevation="0" class="h-100 d-flex flex-column card--design1 card--design1--4 hover--disabled">
			<v-card-title class="text-body-2 pb-2">
				Summary
			</v-card-title>
			<v-card-text class="flex-grow-1">
				<template v-if="process && infoAlert">
					<v-tooltip max-width="500" top>
						<template #activator="{ on }">
							<p class="text-body-2 pb-0 mb-3 line-clamp" style="-webkit-line-clamp: 2;" v-on="on">
								{{ infoAlert.message }}
							</p>
						</template>
						<span>
							{{ infoAlert.message }}
						</span>
					</v-tooltip>
					<v-btn
						v-if="quickQuote"
						small
						style="z-index: 2;"
						@click="$router.push(`/source/${process.id}?upgrade`)"
					>
						Proceed to full sourcing session
					</v-btn>
					<v-btn
						v-else-if="!isFactFind"
						class="ga--trigger ga--filters-opened"
						data-ga-id="summary"
						:disabled="loadingProducts || isArchive"
						small
						style="z-index: 2;"
						@click="$emit('open-filters', 400)"
					>
						Click to edit session details
					</v-btn>
				</template>
				<v-skeleton-loader v-else type="sentences" class="mt-2" />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import numeral from 'numeral';
	import { isEmpty } from 'lodash';
	import { mapState } from 'vuex';

	export default {
		name: 'app-source-result-info',

		props: {
			process: {
				type: Object,
				default: null
			},
			applicants: {
				type: Array,
				default: null
			},
			property: {
				type: Object,
				default: null
			},
			searchCriteria: {
				type: Object,
				default: null
			},
			quickQuote: {
				type: Object,
				default: null
			},
			isFactFind: {
				type: Boolean,
				default: false
			},
			loadingProducts: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			...mapState('CmsSite', ['site']),

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			},

			/**
			 * @name infoAlert
			 * @description Outputs an info block of text for an alert box, based on application info
			 * @return {String} The info alert string based on applicant/s
			 */
			infoAlert() {
				if (this.quickQuote) {
					return {
						message: `Quick Source results are based on a ${this.quickQuote.numberOfApplicants > 1 ? 'joint' : 'single'}${
							this.quickQuote.gender ? `, ${this.quickQuote.gender}` : ''
						} applicant of ${this.quickQuote.age} years old - with a property${this.quickQuote.country ? ' in ' + this.quickQuote.country : ''} valued at £${numeral(
							this.quickQuote.valuation
						).format('0,0[.]00')}. `
					};
				}

				if (isEmpty(this.applicants) || isEmpty(this.property)) return;

				// grab applicant data
				const applicantListDesc = this.applicants.map(
					(applicant) => ' a ' + this.$moment().diff(this.$moment(applicant.dateOfBirth), 'years') + 'yr old ' + applicant.genderName + ' '
				);

				// write out applicant note
				let message = `Results are based on ${this.applicants.length === 1 ? 'one' : 'two'} applicant${this.applicants.length === 1 ? '' : 's'}`;
				message += ` - ${applicantListDesc.join(' and ')} - with a ${this.property.data?.detail?.tenureName} ${this.property.data?.detail?.assetDescriptionName} in ${
					this.property.data?.location?.countryName
				} `;
				message += `valued at £${numeral(this.property.data?.valuation?.value).format('0,0[.]00')}`;

				if (this.searchCriteria.filters) {
					const term = this.searchCriteria.filters.term;
					let advanceText = 'the maximum available loan';

					if (!this.searchCriteria.filters.showMaximumAvailable) advanceText = `an advance of £${numeral(this.searchCriteria.filters.initialAdvance).format('0,0[.]00')}`;
					message += ` and ${advanceText}, displayed over ${term} year${term !== 1 ? 's' : ''}.`;
				}

				return {
					message: message
				};
			}
		}
	};
</script>
