<!--
	@name app-client-dashboard-enquiry
	@description Client Enquiries
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section class="mt-0">
			<template #header>
				<span>Enquiries</span>

				<v-spacer />

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn color="grey darken-3" icon v-on="on" @click="createNewEnquiry">
							<v-icon>mdi-folder-plus</v-icon>
						</v-btn>
					</template>
					<span>New Enquiry</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template #activator="{ on }">
						<v-btn color="grey darken-3" icon to="/enquiries" v-on="on">
							<v-icon>mdi-view-list</v-icon>
						</v-btn>
					</template>
					<span>View All Enquiries</span>
				</v-tooltip>
			</template>
			<template #body>
				<v-skeleton-loader v-if="loading" type="article" class="px-0" />

				<v-card v-else-if="item" class="grey lighten-3" :to="`/enquiry/${item.id}`">
					<v-card-title class="text-body-1 font-weight-bold mb-0 py-3 pb-0">
						Enquiry #{{ item.matterReference }}
					</v-card-title>
					<v-card-text class="pb-0">
						{{ item.numberOfResponse }} Responses
					</v-card-text>

					<v-card-actions class="pa-0 pt-0">
						<v-card-text class="caption pb-0 pt-2">
							Last Response: {{ item.lastResponse.at | moment('from', 'now') }}
						</v-card-text>
					</v-card-actions>

					<v-card-actions class="pa-0 pt-0">
						<v-card-text class="caption pt-0">
							Created: {{ item.created | moment('from', 'now') }}
						</v-card-text>
					</v-card-actions>

					<div style="position: absolute; right: 16px; top: 16px">
						<v-chip light label small :color="status.color">
							{{ status.text }}
						</v-chip>
					</div>
				</v-card>

				<div v-else class="text-center">
					<v-sheet class="rounded px-4 py-3 mb-3 background-rotation-manual--1">
						<p class="text-body-1 white--text mb-0">
							<strong>Got a complex case?</strong> With <a class="white--text text-decoration-underline" @click="createNewEnquiry">the Air Enquiries service</a>, you can refer your client's case into the providers for pre-approval before you submit an application.
						</p>
					</v-sheet>
					<p class="text-body-2 font-italic black--text px-6 mb-0">
						"Rather than ring round to every provider, it's easy to put the enquiry in 1 place and have a response from all the providers within 24 hours."
					</p>
					<p class="text-caption plain font-weight-light grey--text text--darken-3 mt-2 mb-0">
						Kelly Worthington - Adviser
					</p>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-client-dashboard-enquiry',

		components: { CommonStructureSection },

		props: {
			clientId: { type: String, required: true },
			assetId: { type: String, default: null },
			associationId: { type: String, default: null }
		},

		data() {
			return {
				item: null,
				loading: false
			};
		},

		computed: {
			/**
			 * @name status
			 * @description Get status object
			 */
			status() {
				let text = this.item?.data?.closed ? 'Resolved' : 'Open';
				let color = this.item?.data?.closed ? `rgba(23, 184, 144, 0.25)` : `rgba(247, 203, 115, 0.25)`;

				return { text, color };
			}
		},

		created() {
			this.getEnquiryList();
		},

		methods: {
			...mapActions('Enquiry', ['fetchEnquiryList']),

			/**
			 * @name fetchEnquiryList
			 * @description fetch enquiries
			 */
			async getEnquiryList() {
				this.loading = true;
				const payload = {
					limit: 1,
					offset: 0,
					order: { property: 'updated', direction: 'DESC' },
					where: [{ 'client.id': { type: 'uuid', v: this.associationId ? [this.clientId, this.associationId] : [this.clientId] } }]
				};

				this.fetchEnquiryList(payload).then(({ data }) => {
					this.item = data.data[0];
					this.loading = false;
				});
			},

			/**
			 * @name createNewEnquiry
			 * @description create new enquiry
			 */
			createNewEnquiry() {
				this.$router.push(
					`/enquiries/create-new-enquiry?clientId=${this.clientId}${this.assetId ? `&propertyId=${this.assetId}` : ''}${
						this.associationId ? `&associationId=${this.associationId}` : ''
					}`
				);
			}
		}
	};
</script>

<style scoped lang="scss"></style>
