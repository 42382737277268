<!--
	@name common-structure-calendar
	@description Display a section with header/footer
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-structure-calendar :items="events" v-on:change="changeView(year)" />
-->

<template>
	<div :component="$options.name">
		<div v-if="!!$slots['detail']" class="d-flex flex-row pa-5 pb-0">
			<p class="text-body-1 mb-4">
				<slot name="detail" />
			</p>
		</div>

		<v-row v-if="items" no-gutters class="fill-height pa-5" :class="{ 'pt-0':!!$slots['detail'] }">
			<v-col>
				<v-sheet height="64" class="mb-3">
					<v-toolbar flat dark class="generic-gradient">
						<v-btn outlined class="mr-4" color="white" @click="setToday">
							Today
						</v-btn>
						<v-btn fab text small color="white" @click="prev">
							<v-icon small>
								mdi-chevron-left
							</v-icon>
						</v-btn>
						<v-btn fab text small color="white" @click="next">
							<v-icon small>
								mdi-chevron-right
							</v-icon>
						</v-btn>
						<v-toolbar-title class="ml-4">
							{{ title }}
						</v-toolbar-title>
						<v-spacer />
						<v-menu bottom right>
							<template #activator="{ on }">
								<v-btn outlined color="white" v-on="on">
									<span>{{ typeToLabel[type] }}</span>
									<v-icon right>
										mdi-menu-down
									</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="type = 'day'">
									<v-list-item-title>Day</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-title>Week</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-title>Month</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = '4day'">
									<v-list-item-title>4 days</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar>
				</v-sheet>

				<v-sheet height="600">
					<v-calendar
						ref="calendar"
						v-model="focus"
						color="primary"
						:events="mapItems(items)"
						:event-color="getEventColor"
						:now="today"
						:type="type"
						@click:event="showEvent"
						@click:more="viewDay"
						@click:date="viewDay"
						@change="updateRange"
					/>

					<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x max-width="30rem">
						<v-card color="grey lighten-4" min-width="350px" flat :href="selectedEvent.url ? selectedEvent.url : null" :target="selectedEvent.url ? '_blank' : null" :link="!!selectedEvent.url">
							<v-system-bar :color="getEventColor(selectedEvent)" height="10" />

							<v-card-text class="text-h6 font-weight-bold">
								{{ selectedEvent.name }}
							</v-card-text>

							<v-list-item dense>
								<v-list-item-icon class="mr-0">
									<v-icon left>
										mdi-calendar-clock
									</v-icon>
								</v-list-item-icon>
								<v-list-item-subtitle>
									{{ selectedEventTime(selectedEvent) }}
								</v-list-item-subtitle>
							</v-list-item>

							<v-list-item dense>
								<v-list-item-icon class="mr-0">
									<v-icon left>
										mdi-map-marker
									</v-icon>
								</v-list-item-icon>
								<v-list-item-subtitle>{{ selectedEvent.address }}</v-list-item-subtitle>
							</v-list-item>
							<v-list-item dense>
								<v-list-item-subtitle>
									<v-chip small label outlined :color="getEventColor(selectedEvent)" dark>
										{{ getEventTypeName(selectedEvent) }}
									</v-chip>
								</v-list-item-subtitle>
							</v-list-item>

							<v-divider class="mt-5" />

							<v-card-text class="py-6 pt-5">
								<span class="text-body-2 font-weight-bold">{{ selectedEvent.description }}</span>
							</v-card-text>

							<v-card-actions class="pt-0">
								<v-spacer />
								<v-tooltip v-if="selectedEvent.url" top>
									<template #activator="{ on }">
										<v-btn icon color="primary" v-on="on">
											<v-icon>mdi-link</v-icon>
										</v-btn>
									</template>
									<span>Find out more</span>
								</v-tooltip>
								<v-tooltip top>
									<template #activator="{ on }">
										<v-btn icon color="error" v-on="on" @click.stop.prevent="selectedOpen = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</template>
									<span>Close</span>
								</v-tooltip>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-sheet>
			</v-col>
		</v-row>
		<div v-else class="pa-5">
			<v-skeleton-loader type="paragraph" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'common-structure-calendar',

		props: {
			items: { type: Array, default: null }
		},

		data: () => ({
			focus: '',
			type: 'month',
			typeToLabel: {
				month: 'Month',
				week: 'Week',
				day: 'Day',
				'4day': '4 Days'
			},
			start: null,
			end: null,
			selectedEvent: {},
			selectedElement: null,
			selectedOpen: false,
			today: null
		}),

		computed: {
			/**
			 * @name title
			 * @description Return a title based on dates
			 * @return {String} The title as a string
			 */
			title() {
				const { start, end } = this;
				if (!start || !end) return '';

				const startMonth = this.monthFormatter(start);
				const endMonth = this.monthFormatter(end);
				const suffixMonth = startMonth === endMonth ? '' : endMonth;

				const startYear = start.year;
				const endYear = end.year;
				const suffixYear = startYear === endYear ? '' : endYear;

				const startDay = start.day + this.nth(start.day);
				const endDay = end.day + this.nth(end.day);

				switch (this.type) {
					case 'month':
						return `${startMonth} ${startYear}`;
					case 'week':
					case '4day':
						return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
					case 'day':
						return `${startMonth} ${startDay} ${startYear}`;
				}

				return '';
			},

			/**
			 * @name monthFormatter
			 * @description Format the month
			 * @return {String} formatter
			 */
			monthFormatter() {
				return this.$refs.calendar.getFormatter({ timeZone: 'UTC', month: 'long' });
			}
		},

		mounted() {
			if (this.items?.length) this.$refs.calendar.checkChange();
		},

		methods: {
			mapItems(items) {
				return items.map((i) => ({
					...i,
					start: this.$moment(String(i.start)).format('YYYY-MM-DD HH:mm'),
					end: this.$moment(String(i.end)).format('YYYY-MM-DD HH:mm')
				}));
			},

			/**
			 * @name viewDay
			 * @description Set the day viewed
			 * @param {Object} date the date to use
			 */
			viewDay({ date }) {
				this.focus = date;
				this.type = 'day';
			},

			/**
			 * @name getEventColor
			 * @description Return the colour fo the event
			 * @param {Object} event The event as an object
			 * @return {String} Color
			 */
			getEventColor(event) {
				if (event.cancelled) return 'rgb(234, 99, 140)';
				else if (!event.active) return 'grey';
				else if (event.type === 'Air Mortgage Club') return 'rgb(27, 120, 216)';
				else if (event.type === 'Air Later Life Academy') return 'rgb(23, 184, 144)';
				else if (event.type === 'Air Select') return 'rgb(103, 0, 86)';
				else return 'primary';
			},

			/**
			 * @name getEventTypeName
			 * @description Return type name for the event
			 * @param {Object} event The event as an object
			 * @return {String} Type name
			 */
			getEventTypeName(event) {
				if (event.cancelled) return 'Cancelled';
				else if (!event.active) return 'Sold Out';
				else if (event.type === 'Air Mortgage Club') return 'Air Mortgage Club Event';
				else if (event.type === 'Air Later Life Academy') return 'Air Later Life Academy Event';
				else if (event.type === 'Air Select') return 'Air Select Event';
				else return 'Other Company Event';
			},

			/**
			 * @name setToday
			 * @description Set the focus day
			 */
			setToday() {
				this.focus = this.today;
			},

			/**
			 * @name prev
			 * @description Move calendar
			 */
			prev() {
				this.$refs.calendar.prev();
			},

			/**
			 * @name next
			 * @description Move calendar
			 */
			next() {
				this.$refs.calendar.next();
			},

			/**
			 * @name showEvent
			 * @description Show the event
			 * @param {Object} {nativeEvent, event} data for the event
			 */
			showEvent({ nativeEvent, event }) {
				const open = () => {
					this.selectedEvent = event;
					this.selectedElement = nativeEvent.target;
					setTimeout(() => (this.selectedOpen = true), 10);
				};

				if (this.selectedOpen) {
					this.selectedOpen = false;
					setTimeout(open, 10);
				} else open();

				nativeEvent.stopPropagation();
			},

			/**
			 * @name updateRange
			 * @description Updat ehte range of the cal
			 */
			updateRange({ start, end }) {
				this.$emit('change', new Date(start.date).getFullYear());

				this.start = start;
				this.end = end;
			},

			/**
			 * @name nth
			 * @description Get abbrevs
			 * @return {String} the abbreviaiton of the date suffix
			 */
			nth(d) {
				return d > 3 && d < 21 ? 'th' : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
			},

			/**
			 * @name selectedEventEndTime
			 * @description - If on same day, change this to {start} - {endtime} rather than  {start} - {end}
			 * @return {String} time for selected event
			 */
			selectedEventTime(selectedEvent) {
				const start = this.$moment(String(selectedEvent.start)).format('ddd, Do MMM YYYY h:mma');
				let end = this.$moment(String(selectedEvent.end)).format('ddd, Do MMM YYYY h:mma');

				if (this.$moment(String(selectedEvent.start)).isSame(this.$moment(String(selectedEvent.end)), 'day')) {
					end = this.$moment(String(selectedEvent.end)).format('h:mma');
				}

				return `${start} - ${end}`;
			}
		}
	};
</script>
