import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Webhook
 * @exports Service/Account/Organisation/Webhook
 * @description API Organisation Webhook Service
 * @date 2024/07/16
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Webhook {
	/**
	 * @static @name list
	 * @description Get all organisation webhook with details
	 * @returns {Object} Promise a http response
	 */
	static list(oid) {
		return $http.get(`account/organisation/${oid}/webhook`);
	}

	/**
	 * @static @name post
	 * @description Add new organisation webhook resources
	 * @param {String} oid
	 * @param {Array} payload
	 * @returns {Object} Promise a http response
	 */
	static post(oid, payload) {
		return $http.post(`account/organisation/${oid}/webhook`, payload);
	}
}
