import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Verify
 * @exports Service/Verify
 * @description API Verify Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Verify {
	/**
	 * @static @name get
	 * @description Performs API verify
	 * @returns {Object} Promise a http response
	 */
	static get(token) {
		return $http.get(`account/registration/verify/${token}`);
	}

	/**
	 * @static @name post
	 * @description Performs API verify
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/registration/verify`, payload);
	}
}
