<!--
	@name app-conference-section
	@description Conference section
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :id="id" :name="id" :component="$options.name">
		<v-divider v-if="divider" inset />
		<v-container class="py-8" :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }">
			<v-row>
				<v-col :order="reverse && $vuetify.breakpoint.mdAndUp ? 'first' : 'last'" cols="12" md="6">
					<v-sheet
						v-if="membersOnly && !member"
						width="100%"
						height="320"
						class="rounded d-flex align-center justify-center"
						style="position: relative; overflow: hidden; transform: translate3d(0,0,0);"
					>
						<div class="bg" />
						<div class="bg bg2" />
						<div class="bg bg3" />
						<div class="text-h5 font-weight-bold pa-10 text-center">
							<span class="link--special">
								Member exclusive
							</span>
							<p class="text-body-1 mt-4 px-1 mb-0">
								Unlock exclusive insights from former Formula 1 driver Damon Hill on innovation, resilience, and seizing opportunities - log in or register now to access our member-only video!
							</p>
							<div class="ma-n2 pt-5">
								<v-btn color="primary" class="ma-2" @click="login">
									Log in
								</v-btn>
								<v-btn color="primary" class="ma-2" to="/register">
									Register
								</v-btn>
							</div>
						</div>
					</v-sheet>
					<img
						v-else-if="image"
						:src="image"
						width="100%"
						class="rounded"
					/>
					<video
						v-else-if="video"
						:src="video"
						:poster="poster || null"
						width="100%"
						controls
						class="rounded"
						:data-poster="poster"
					/>
					<v-sheet
						v-else
						width="100%"
						height="320"
						class="rounded d-flex align-center justify-center"
						style="position: relative; overflow: hidden; transform: translate3d(0,0,0);"
					>
						<div class="bg" />
						<div class="bg bg2" />
						<div class="bg bg3" />
						<div class="text-h5 font-weight-bold pa-10">
							Video coming soon
						</div>
					</v-sheet>
				</v-col>
				<v-col :order="reverse && $vuetify.breakpoint.mdAndUp ? 'last' : 'first'" cols="12" md="6" :class="reverse ? 'pl-md-10' : 'pr-md-10'">
					<p class="text-h5 font-weight-bold mb-4" :class="{ 'white--text':dark }">
						{{ subtitle }}
					</p>
					<slot name="copy" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: 'app-conference-section',

		components: {},

        props: {
			id: { type: String, required: true },
			subtitle: { type: String, required: true },
			image: {type: String, default: null },
			video: {type: String, default: null },
			poster: {type: String, default: null },
			reverse: { type: Boolean, default: false },
			divider: { type: Boolean, default: true },
			dark: { type: Boolean, default: false },
			membersOnly: { type: Boolean, default: false },
			member: { type: Boolean, default: false }
		},

		data() {
			return {};
		},

		computed: {},

		created() {},

		methods: {
			login() {
				this.$emit('login');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.bg {
		animation:slide 3s ease-in-out infinite alternate;
		background-image: linear-gradient(-60deg, tint($col1, 75%) 50%, tint($col5, 75%) 50%);
		bottom:0;
		left:-50%;
		opacity:.5;
		position:fixed;
		right:-50%;
		top:0;
		z-index:-1;

		&--alt {
			background-image: linear-gradient(-60deg, tint($col3, 65%) 50%, tint($col4, 75%) 50%);
		}
	}

	.bg2 {
		animation-direction:alternate-reverse;
		animation-duration:4s;
	}

	.bg3 {
		animation-duration:5s;
	}

	@keyframes slide {
		0% {
			transform:translateX(-25%);
		}
		100% {
			transform:translateX(25%);
		}
	}

	.link--special {
		background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/cms/texthighlight.svg');
        background-position: 50% 50%;
        padding: 8px 16px;
		margin: 0px !important;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>
