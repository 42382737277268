<!--
	@name app-client-service-care-plan
	@description Care Plan
	@date 2020/07/17
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<div>
		<!-- LOADING -->
		<v-dialog v-model="loading" persistent width="300">
			<v-card color="white">
				<v-card-text>
					Loading
					<v-progress-linear indeterminate color="black" class="mt-2 mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- CASE TRACKING -->
		<!-- hasReferral set to false to not display in built referral -->
		<app-client-service-template id="serviceTemplate" ref="serviceTemplate" :has-referral="false">
			<template slot="header">
				<app-client-service-header
					:header="'Care Plans'"
					:subheader="'My Care Consultant'"
					:link="'mycareconsultant.co.uk'"
					:url="'http://www.mycareconsultant.co.uk/'"
					:logo="'1ae7b1f9-90f4-46cf-ad3a-38e03dc58f9c.png'"
				/>
			</template>
			<!-- CARE PLAN REFERRAL -->
			<template slot="copy">
				<p class="text-body-1 font-weight-bold">
					My Care Consultant (MCC) helps those in need of care to navigate the complex social and health care system in England. From explaining what care options and
					support is available, to helping people understand the value of professional, financial and legal advice and to gain access to it, MCC seeks to establish a
					clear pathway, a joined-up approach and to be an independent 'first port of call' in meeting the often complicated and frequently urgent needs that many older
					people have.
				</p>
				<p class="text-body-2 mb-6">
					MCC's client services include Telephone Consultations and Bespoke Care Reports, preceded by a detailed client fact-find, designed to resolve issues and to
					provide solutions to care needs. Often, people 'don't know what they don't know', so MCC fills in the gaps by providing guidance to meet a client's personal
					needs and circumstances.
				</p>

				<div class="mt-6">
					<app-client-service-care-plan-referral v-if="client" :client="client" :association="association" :asset-id="workflowAsset?.id" :submitting="submitting" @submit="submit" />
				</div>
			</template>
		</app-client-service-template>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import AppClientServiceCarePlanReferral from '@/component/app/client/service/care-plan/referral';
	import AppClientServiceTemplate from '@/component/app/client/service/template';
	import AppClientServiceHeader from '@/component/app/client/service/header';

	export default {
		name: 'app-client-care-plan-solicitor',

		components: { AppClientServiceTemplate, AppClientServiceCarePlanReferral, AppClientServiceHeader },

		props: {
			client: { type: Object, required: false, default: null },
			association: { type: Object, required: false, default: null },
			assetList: { type: Array, required: false, default: null },
			workflowAsset: { type: Object, required: false, default: null },
			items: { type: Array, required: false, default: null }
		},

		data() {
			return {
				active: false,
				loading: false,
				submitting: false
			};
		},

		computed: {
			referrals() {
				if (!this.items || !this.assetList) return [];
				return this.items;
			}
		},

		methods: {
			...mapActions('AppCarePlan', ['submitCarePlan', 'loadCarePlanList']),

			open() {
				this.loading = true;
				this.$nextTick().then(() => {
					this.$refs.serviceTemplate.open({ formSchema: [], formData: [], referrals: this.referrals });
					this.loading = false;
				});
			},

			/**
			 * @name submit
			 * @description Fetch client
			 */
			submit(payload) {
				this.submitting = true;

				this.submitCarePlan(payload)
					.then(() => {
						this.submitting = false;
						this.$refs.serviceTemplate.submitSuccess();
						this.$refs.serviceTemplate.refresh();
						this.$emit('submit');
					})
					.catch(() => this.$refs.serviceTemplate.submitError());
			},

			close() {
				this.active = false;
				this.$router.replace({ query: null });
			}
		}
	};
</script>
