<!--
	@name common-structure-directory
	@description Displays a directory of entities with pageable listings and a detail view
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-structure-directory :items="items" :pageSize="pageSize" showActions showActionPhone showActionEmail showActionWebsite paginate>
		<template v-slot:title>Your Header</template>
		<template v-slot:detail>Some content</template>
	</common-structure-directory>

	[
		{
			"id": 1,
			"name": "Hodge Lifetime",
			"image": "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
			"email": "info@hodgelifetime.com",
			"website": "https://www.hodgelifetime.co.uk",
			"phone": "08007314076",
			"detail": "Our focus is to provide a full suite of residential and lifetime mortgages that ap",
			"address": "One Central Square, Cardiff, CF10 1FS",
			"latitude": 51.47671,
			"longitude": -3.18012,
		},
		...
	]

	[

		{
			"id": 1,
			"name": "Partial 1",
			"image": "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
			"items": [
				{
					"id": 10,
					"name": "Partial 2",
					"image": "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
					"items": [
						{
							"id": 100,
							"name": "Hodge Lifetime",
							"image": "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
							"email": "info@hodgelifetime.com",
							"website": "https://www.hodgelifetime.co.uk",
							"phone": "08007314076",
							"detail": "Our focus is to provide a full suite of residential and lifetime mortgages that ap",
							"address": "One Central Square, Cardiff, CF10 1FS",
							"latitude": 51.47671,
							"longitude": -3.18012,
						}
					]
				}
			]
		}
	]
-->

<template>
	<div :component="$options.name">
		<div v-if="selected !== false && selectedChild === false" class="navigate mb-4 text-right">
			<v-btn color="primary" outlined @click="select(false)">
				<v-icon left small>
					mdi-chevron-left
				</v-icon>
				Back
			</v-btn>
		</div>
		<div v-if="selected === false" class="header mb-6">
			<div class="left">
				<h2 v-if="!!$slots.title" class="text-h5 font-weight-bold mb-0">
					<slot name="title" />
				</h2>
				<p v-if="!!$slots.detail" class="text-body-1 mt-2 mb-0">
					<slot name="detail" />
				</p>
			</div>
			<div v-if="paginate && items.length > pageSize" class="right justify-start">
				<v-btn-toggle class="mb-5">
					<v-btn small @click="pageDown">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn small @click="pageUp">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-btn-toggle>
			</div>
		</div>
		<div class="body">
			<div v-if="selected === false && items.length > 0" class="flexer">
				<v-card v-for="(item, idx) in filteredItems" :key="item.id" class="item mx-auto">
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="text-h6 mb-1">
								{{ item.title }}
							</v-list-item-title>
							<v-list-item-subtitle v-show="item.subTitle">
								{{ item.subTitle }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-sheet v-if="!item.organisation" height="194" color="grey lighten-4" class="d-flex align-center justify-center">
						<img v-if="item.image" class="clickable logo" :src="item.image | urlize" @click="select(idx)" />
					</v-sheet>
					<v-img v-else-if="item.image" class="clickable" :src="item.image | urlize" height="194" @click="select(idx)" />
					<v-sheet v-else height="194" :color="item.color" tile />

					<v-card-actions v-if="showActions">
						<v-btn text color="primary" @click="select(idx)">
							Select
						</v-btn>
						<v-spacer />
						<v-btn v-if="showActionPhone" v-show="item.phone" icon :href="'tel:' + item.phone">
							<v-icon>mdi-phone</v-icon>
						</v-btn>
						<v-btn v-if="showActionEmail" v-show="item.email" icon :href="'mailto:' + item.email">
							<v-icon>mdi-email</v-icon>
						</v-btn>
						<v-btn v-if="showActionWebsite" v-show="item.website" icon :href="item.website" target="__blank">
							<v-icon>mdi-link</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</div>

			<div v-if="!items || items.length === 0" class="flexer">
				<v-card v-for="i in 2" :key="i" class="item">
					<v-skeleton-loader type="card" />
				</v-card>
			</div>

			<div v-if="selected !== false" class="details">
				<common-structure-directory
					v-if="filteredItems[selected].items"
					:items="filteredItems[selected].items"
					:show-actions="filteredItems[selected].hasOwnProperty('showActions') ? filteredItems[selected].showActions : false"
					:show-action-phone="filteredItems[selected].hasOwnProperty('showActionPhone') ? filteredItems[selected].showActionPhone : false"
					:show-action-email="filteredItems[selected].hasOwnProperty('showActionEmail') ? filteredItems[selected].showActionEmail : false"
					:show-action-website="filteredItems[selected].hasOwnProperty('showActionWebsite') ? filteredItems[selected].showActionWebsite : false"
					:page-size="pageSize"
					@selected="childSelected"
				>
					<template #title>
						{{ filteredItems[selected].title }}
					</template>
					<template v-if="filteredItems[selected].detail" #detail>
						{{ filteredItems[selected].detail }}
					</template>
				</common-structure-directory>
				<common-structure-directory-selection v-else :selection="filteredItems[selected]" no-details-message="" footnotes-heading="" />
			</div>
		</div>
	</div>
</template>

<script>
	import CommonStructureDirectorySelection from '@/component/common/structure/directory-selection';

	export default {
		name: 'common-structure-directory',

		components: { CommonStructureDirectorySelection },

		props: {
			items: { type: Array, required: true, default: () => [] },
			pageSize: { type: Number, required: false, default: 6 },
			showActions: { type: Boolean, required: false, default: false },
			showActionPhone: { type: Boolean, required: false, default: false },
			showActionEmail: { type: Boolean, required: false, default: false },
			showActionWebsite: { type: Boolean, required: false, default: false },
			paginate: { type: Boolean, required: false, default: false }
		},

		data() {
			return {
				page: 1,
				selected: false,
				selectedChild: false
			};
		},

		computed: {
			/**
			 * @name filteredItems
			 * @description Return a filtered list of items per page
			 * @return {Array} An array of object items
			 */
			filteredItems() {
				return !this.paginate ? this.items : this.items.filter((d, i) => i >= (this.page - 1) * this.pageSize && i < this.page * this.pageSize);
			}
		},

		methods: {
			/**
			 * @name pageDown
			 * @description Go down a page
			 */
			pageDown() {
				this.page = this.page < 2 ? 1 : this.page - 1;
			},

			/**
			 * @name pageUp
			 * @description Go up a page
			 */
			pageUp() {
				const pages = Math.ceil(this.items.length / this.pageSize);
				this.page = this.page >= pages ? pages : this.page + 1;
			},

			/**
			 * @name select
			 * @description select an item
			 */
			select(idx) {
				this.selected = idx;
				this.$emit('selected', this.selected);
			},

			/**
			 * @name selected
			 * @description selected an item from nested directory
			 */
			childSelected(idx) {
				this.selectedChild = idx;
			}
		}
	};
</script>

<style scoped>
	.header {
		display: flex;
		flex-flow: row;
	}

	.header .left {
		flex: 1 1;
	}

	.header .right {
		flex: 0 1 100px;
		display: flex;
		flex-flow: column;
		align-items: flex-end;
		justify-content: flex-end;
	}

	.flexer {
		display: flex;
		margin: -10px;
		flex-flow: row wrap;
	}

	.item {
		margin: 10px !important;
		display: inline-block;
		flex: 1 1 300px;
		width: calc(33.333% - 20px);
	}

	.clickable {
		cursor: pointer;
	}

	.logo {
		max-width: 80%;
		max-height: 80%;
	}
</style>
