<!--
	@name app-common-promotion-advisor-advert
	@description Account Page
	@date 2020/04/24
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet v-if="hasBanner && banners.length" class="pa-5">
			<common-promotion-carousel :adverts="banners" orientation="landscape" />
		</v-sheet>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import CommonPromotionCarousel from '@/component/common/promotion/carousel';

	export default {
		name: 'app-common-promotion-advisor-advert',

		components: {
			CommonPromotionCarousel
		},

		data() {
			return {
				banners: []
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			hasBanner() {
				return this.site.adverts?.advisor;
			}
		},

		created() {
			this.loadAdvisorBanner().then(({ data }) => {
				if(data?.value.dashboard_right) this.banners = [data.value.dashboard_right];
			});
		},

		methods: {
			...mapActions('CmsBanner', ['loadAdvisorBanner'])
		}
	};
</script>
