import axios from 'axios';

const BASE_URL = 'https://api.addressy.com/Capture/Interactive';
const API_KEY = process.env.VUE_APP_LOQATE_API_KEY;
/**
 * @namespace Service
 * @class Address
 * @exports Service/Reward
 * @description API Address Service
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Address {
	/**
	 * @static @async @name find
	 * @description Find addresses and places. This must be followed by a Retrieve to obtain a fully formatted address.
	 * @param {String} text - The search text to find.
	 * @param {String} container - A container for the search. This should only be another Id previously returned from this service when the Type of the result was not 'Address'.
	 * @returns {Array} Search results
	 */
	static async find(text, container) {
		return await axios.post(`${BASE_URL}/Find/v1.10/json3.ws?Key=${API_KEY}&Text=${text}${container ? `&Container=${container}` : ''}`);
	}

	/**
	 * @static @async @name retrieve
	 * @description Returns the full address details based on the Id.
	 * @param {Number} id - The Id from a Find method to retrieve the details for.
	 * @returns {Object} Full address details
	 */
	static async retrieve(id) {
		return await axios.post(`${BASE_URL}/Retrieve/v1/json3.ws?Key=${API_KEY}&Field1Format={{HomeNation}}&Id=${id}`);
	}
}
