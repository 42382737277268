<!--
	@name app-dialog-contact
	@description Show a dialog for contact
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="600">
		<template #header>
			Need help? Get in touch!
		</template>
		<template #body>
			<v-container class="pa-0">
				<v-row>
					<v-col v-if="site" class="py-0" sm="12">
						<p class="text-subtitle-1 mb-0">
							You can speak to a member of our dedicated support team using any of the methods listed below.
						</p>
						<p v-if="site.contact" class="text-subtitle-1 mb-0 mt-4">
							<span class="font-weight-bold"> Tel: </span>
							{{ site.contact.number }}
							<br />
							Available between 9am - 5pm Monday to Friday
						</p>
						<p v-if="site.contact" class="text-subtitle-1 mb-0 mt-4">
							<span class="font-weight-bold"> Email: </span>
							<a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
						</p>
						<p v-if="site.onlineChatAccess" class="subtitle-1 mb-0 mt-4">
							<span class="font-weight-bold"> Live Chat: </span>
							See bottom left of screen
							<br />
							Available between 9am - 5pm Monday to Friday*
							<span class="text-caption d-block mt-2 blue-grey--text">
								* Chat messages left outside of these hours and will be dealt with on the next working day
							</span>
						</p>
					</v-col>

					<v-container v-else>
						<v-skeleton-loader type="list-item-three-line" />
					</v-container>
				</v-row>
			</v-container>
		</template>
	</common-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-dialog-contact',

		components: { CommonDialog },

		data: () => ({}),

		computed: {
			...mapState('CmsSite', ['site'])
		},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			}
		}
	};
</script>
