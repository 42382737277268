<!--
	@name app-firm-members-list
	@description Firm Members List
	@date 2020/05/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section thick-header>
			<template #header>
				<v-pagination v-model="currentPage" class="justify-start" :length="pageCount" :total-visible="8" color="info" />
				<v-spacer />
				<v-text-field
					v-model="searchTerm"
					placeholder="Search: e.g. Luis Carruthers"
					append-icon="mdi-magnify"
					hide-details
					dense
					solo
					:class="{ 'flex-shrink-0': '17rem' }"
					:style="{ width: '17rem', 'max-width': '17rem' }"
				/>
			</template>
			<template #body>
				<div class="text-center">
					<v-btn color="primary" class="mb-0" @click="createAccount">
						<v-icon left>
							mdi-account-plus
						</v-icon>
						Invite New Team Member
					</v-btn>
				</div>
				<v-skeleton-loader v-if="!mappedAccountList.length" type="list-item-avatar-three-line@3" class="px-0" />
				<v-data-table
					v-else
					class="data-table"
					:footer-props="footerProps"
					:headers="headers"
					:items="mappedAccountList"
					:items-per-page="itemsPerPage"
					:page.sync="currentPage"
					:search="searchTerm"
					@page-count="pageCount = $event"
				>
					<template #body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.id" :class="{ 'grey lighten-4 tbl-row--deleted': item.deleted }">
								<td>
									<p class="text-body-1 font-weight-bold mb-0">
										{{ item.name }}
									</p>
									<p v-if="item.email" class="text-subtitle-2 ma-0">
										{{ item.email }}
									</p>
									<div class="d-block mt-0">
										<v-chip v-if="item.deleted" class="mr-1 mt-1" color="error accent-3" label x-small active>
											Deleted
										</v-chip>

										<v-chip v-if="!item.type && item.request && item.request.pending" class="mr-1 mt-1" color="purple" label x-small active dark>
											Firm Join Request
										</v-chip>

										<v-chip v-if="!item.type && item.request && !item.request.pending" class="mr-1 mt-1" color="purple" label x-small active dark>
											Join Request Accepted - Awaiting Verification
										</v-chip>

										<v-chip v-if="item.type === 1 && !item.invite.pending" class="mr-1 mt-1" color="secondary" label x-small active dark>
											Account Owner
										</v-chip>

										<v-chip v-else-if="item.type === 2 && !item.invite.pending" class="mr-1 mt-1" color="success" label x-small active dark>
											Admin
										</v-chip>

										<v-chip v-if="item.invite.pending && !item.deleted" class="mr-1 mt-1" color="grey" label x-small active dark>
											Pending
										</v-chip>

										<v-chip v-if="item.suspended && !item.invite.pending" class="mr-1 mt-1" color="error accent-3" label x-small active>
											Suspended
										</v-chip>

										<v-chip v-if="item.inactive && !item.invite.pending" class="mr-1 mt-1" color="warning" label x-small active dark>
											Inactive
										</v-chip>

										<v-tooltip v-if="item.tradingStyle && !item.deleted" bottom>
											<template #activator="{ on }">
												<v-chip class="mr-1 mt-1" color="pink accent-3" label x-small active dark v-on="on">
													{{ item.tradingStyle.name }}
												</v-chip>
											</template>
											<span>Trading Style</span>
										</v-tooltip>
									</div>
								</td>
								<td class="shrunk text-right">
									<div class="d-flex flex-no-wrap justify-end">
										<v-menu offset-y open-on-hover transition="slide-y-transition" left>
											<template #activator="{ on }">
												<v-btn
													small
													color="secondary"
													:loading="submitting[item.id] ? submitting[item.id].updating : false"
													:disabled="submitting[item.id] ? submitting[item.id].deleting : false || (item.type === 1 && !department.name.includes('owner')) || item.deleted"
													class="mr-2"
													v-on="on"
												>
													<v-icon>mdi-dots-horizontal</v-icon>
												</v-btn>
											</template>
											<v-list dense class="py-0">
												<template v-for="(fnctn, index) in accountActions(item)">
													<v-list-item v-if="displayAccountFunction(fnctn, item)" :key="index" @click="accountFunction(item, fnctn.fnctn)">
														<v-list-item-icon>
															<v-icon :color="fnctn.icon_color" v-text="fnctn.icon" />
														</v-list-item-icon>
														<v-list-item-title>{{ fnctn.title }}</v-list-item-title>
													</v-list-item>
												</template>
											</v-list>
										</v-menu>

										<v-btn
											color="error"
											small
											text
											:loading="submitting[item.id] ? submitting[item.id].deleting : false"
											:disabled="item.type === 1 || item.invite.existing || item.deleted || item.id === user.id || item.request"
											@click="submitDeleteAccount(item)"
										>
											<v-icon>mdi-trash-can</v-icon>
										</v-btn>
									</div>
									<div class="d-flex flex-no-wrap justify-end mt-3 text-caption grey--text text--darken-1">
										{{ item.invite.pending ? 'Invited' : 'Joined' }}: {{ item.created | moment('ddd, MMM Do YYYY') }}
									</div>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</template>
		</common-structure-section>
		<common-dialog-confirm ref="confirm" />
		<app-firm-dialog-select-trading-style ref="selectTradingStyle" :loading="false" @submit="tradingStyleSelected" />
		<app-firm-dialog-user-account ref="userAccount" />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { startCase, toLower } from 'lodash';
	import { ElementTools, EventBus } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppFirmDialogSelectTradingStyle from '../dialog/select-trading-style';
	import AppFirmDialogUserAccount from '../dialog/user-account';

	export default {
		name: 'app-firm-members-list',

		components: { CommonStructureSection, CommonDialogConfirm, AppFirmDialogSelectTradingStyle, AppFirmDialogUserAccount },

		data() {
			return {
				organisationUserList: [],
				userInvitationList: [],
				firmJoinRequestList: [],
				loading: false,
				submitting: {},
				currentPage: 1,
				itemsPerPage: 10,
				pageCount: 0,
				searchTerm: '',
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				headers: [
					{ text: 'Account Holder', align: 'left', value: 'name', class: 'text-no-wrap' },
					{ text: '', align: 'left', value: '', sortable: false }
				],
				inviteFunctions: [
					{
						title: 'Cancel Invitation',
						fnctn: 'submitCancelInvitation',
						icon: 'mdi-cancel',
						icon_color: 'error'
					},
					{
						title: 'Resend Invitation',
						fnctn: 'resendInvitation',
						icon: 'mdi-email-sync-outline',
						icon_color: 'primary'
					}
				],
				firmJoinRequestFunctions: [
					{
						title: 'Accept Join Request',
						fnctn: 'submitAcceptFirmJoinRequest',
						icon: 'mdi-email-sync-outline',
						icon_color: 'success'
					},
					{
						title: 'Reject Join Request',
						fnctn: 'submitDeleteFirmJoinRequest',
						icon: 'mdi-cancel',
						icon_color: 'error'
					}
				],
				firmAcceptedRequestFunctions: [
					{
						title: 'Delete Join Request',
						fnctn: 'submitDeleteFirmJoinRequest',
						icon: 'mdi-cancel',
						icon_color: 'error'
					}
				],
				accountFunctions: [
					{
						title: 'View/Edit Account',
						fnctn: 'openUserDialog',
						icon: 'mdi-account-edit',
						icon_color: 'primary'
					},
					{
						title: 'Create Admin',
						fnctn: 'submitAdminCreate',
						icon: 'mdi-key-plus',
						icon_color: 'secondary',
						businessOwner: true,
						conditions: {
							key: 'type',
							value: 3
						}
					},
					{
						title: 'Revoke Admin',
						fnctn: 'submitAdminRevoke',
						icon: 'mdi-key-remove',
						icon_color: 'error',
						businessOwner: true,
						conditions: {
							key: 'type',
							value: 2
						}
					},
					{
						title: 'Suspend Account',
						fnctn: 'submitSuspendAccount',
						icon: 'mdi-lock',
						icon_color: 'warning',
						sameAccountDenied: true,
						conditions: {
							key: 'suspended',
							value: undefined
						}
					},
					{
						title: 'Reactivate Account',
						fnctn: 'submitReactivateAccount',
						icon: 'mdi-lock-open',
						icon_color: 'success',
						sameAccountDenied: true,
						conditions: {
							key: 'suspended',
							value: 'defined'
						}
					},
					{
						title: 'Manage Trading Styles',
						fnctn: 'openTradingStyleDialog',
						icon: 'mdi-link',
						icon_color: 'green'
					}
				]
			};
		},

		computed: {
			...mapState('Account', ['organisation', 'department', 'user']),
			...mapState('CmsSite', ['site']),

			mappedAccountList() {
				if (this.loading) return [];
				let list = [
					...this.organisationUserList.map((u) => ({
						id: u.id,
						userDepartmentId: u.userDepartment.id,
						created: u.created,
						deleted: !!u.deleted,
						email: u.userDetail?.email || u.userIdentity?.identity,
						inactive: this.checkInactive(u),
						invite: { pending: false },
						request: false,
						lastLogin: u.userAccount ? u.userAccount.loginCurrent || u.userAccount.loginPrevious : undefined,
						name: startCase(toLower(u.name)),
						suspended: u.suspended || undefined,
						organisationTradingStyles: u.userOrganisationTradingStyles,
						type: u.department ? (u.department.name.includes('owner') ? 1 : u.department.name.includes('manager') ? 2 : 3) : undefined
					})),
					...this.userInvitationList.map((r) => ({
						id: r.id,
						created: r.created,
						deleted: !!r.deleted,
						email: r.registration ? r.registration.identity : r.userDetail.email,
						invite: { pending: true },
						request: false,
						name: startCase(toLower(r.registration ? r.registration.data.name : r.user.name))
					}))
				].sort((a, b) => (a.name > b.name ? 1 : -1));

				return [
					...this.firmJoinRequestList.map((r) => ({
						id: r.registration.id,
						created: r.created,
						deleted: !!r.deleted,
						email: r.registration.identity,
						invite: { pending: false },
						request: { pending: r.registration.data.firmJoinRequestAccepted ? false : true },
						name: startCase(toLower(r.registration.data.name))
					})),
					...list
				];
			}
		},

		created() {
			this.loading = true;
			Promise.all([this.fetchOrganisationUserList(), this.fetchUserInvitations(), this.fetchFirmJoinRequests()]).then(() => (this.loading = false));
			EventBus.$on('user-invited', () => {
				this.fetchUserInvitations();
			});
		},

		methods: {
			...mapActions('AccountOrganisationUser', [
				'loadOrganisationUserList',
				'modifyOrganisationUser',
				'deleteOrganisationUser',
				'modifyOrganisationUserDepartment',
				'updateUserOrganisationTradingStyles'
			]),
			...mapActions('AccountUserInvitation', ['loadUserInvitationPendingList', 'addUserInvitation', 'deleteUserInvitation', 'reinvite']),
			...mapActions('AccountFirmJoinRequest', ['loadFirmJoinRequestList', 'acceptFirmJoinRequest', 'deleteFirmJoinRequest']),

			fetchOrganisationUserList() {
				this.loadOrganisationUserList(this.organisation.id).then(({ data }) => {
					this.organisationUserList = data;
				});
			},

			fetchUserInvitations() {
				this.loadUserInvitationPendingList().then(({ data }) => {
					this.userInvitationList = data;
				});
			},

			fetchFirmJoinRequests() {
				this.loadFirmJoinRequestList().then(({ data }) => {
					this.firmJoinRequestList = data;
				});
			},

			createAccount() {
				this.$emit('open-invite');
			},

			/**
			 * @name setAccountSubmittingStatus
			 * @description set item loading state
			 */
			setAccountSubmittingStatus(accountId, action, submitting) {
				this.$set(this.submitting, accountId, {});
				this.$set(this.submitting[accountId], action, submitting);
			},

			/**
			 * @name accountActions
			 * @description Available account actions
			 * @param account
			 * @returns Array functions
			 */
			accountActions(account) {
				if (account.invite.pending) return this.inviteFunctions;
				else if (account.request) {
					if(account.request.pending) return this.firmJoinRequestFunctions;
					else return this.firmAcceptedRequestFunctions;
				}
				return this.accountFunctions;
			},

			/**
			 * @name accountFunction
			 * @description call function
			 */
			accountFunction: function(account, fnctn) {
				this[fnctn](account);
			},

			/**
			 * @name openUserDialog
			 * @description Opens Trading style select dialog
			 */
			openUserDialog(account) {
				this.$refs.userAccount.open(account);
			},

			submitAcceptFirmJoinRequest(account) {
				this.$refs.confirm.open('Accept Join Request', `Are you sure you wish to accept ${account.name}'s request to join your firm?`).then(async() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.acceptFirmJoinRequest({ registration_id: account.id })
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `You have accepted ${account.name}'s request to join your firm`);
							this.fetchFirmJoinRequests();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
				});
			},

			/**
			 * @name submitDeleteFirmJoinRequest
			 * @description Remove member of a firm
			 */
			submitDeleteFirmJoinRequest(account) {
				this.$refs.confirm
					.open('Delete Join Request', `Are you sure you wish to delete ${account.name}'s request to join your firm?`, { app_bar_color: 'error', system_bar_color: 'error darken-2' })
					.then(() => {
						this.setAccountSubmittingStatus(account.id, 'deleting', true);

						this.deleteFirmJoinRequest(account.id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', `${account.name}'s request to join the firm has been rejected`);
								this.fetchFirmJoinRequests();
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occurred'))
							.finally(() => this.setAccountSubmittingStatus(account.id, 'deleting', false, this.fetchFirmJoinRequests()));
					});
			},

			submitAdminCreate(account) {
				this.$refs.confirm.open('Create Admin', `Are you sure you wish to upgrade account ${account.name} to Admin?`).then(async() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.modifyOrganisationUserDepartment({ id: account.userDepartmentId, payload: { department: { name: 'manager' } } })
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `Account ${account.name} has been successfully upgraded to Admin`);
							this.fetchOrganisationUserList();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
				});
			},

			/**
			 * @name submitAdminRevoke
			 * @description Revoke admin privilege for given member of a firm
			 */
			submitAdminRevoke(account) {
				this.$refs.confirm.open('Revoke Admin', `Are you sure you wish to revoke Admin status from account ${account.name}?`).then(async() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.modifyOrganisationUserDepartment({ id: account.userDepartmentId, payload: { department: { name: 'basic' } } })
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `Account ${account.name} has been successfully downgraded from Admin`);
							this.fetchOrganisationUserList();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
				});
			},

			/**
			 * @name displayAccountFunction
			 * @description Used to check if a action button should be shown or not
			 */
			displayAccountFunction(fnctn, account) {
				// Business Owner Only
				if (fnctn.businessOwner && !this.department.name.includes('owner')) return false;
				// Not permissable on owner's account
				if (fnctn.sameAccountDenied && account.id === this.user.id) return false;
				// No conditions
				if (!fnctn.conditions) return true;
				// Not null
				if (fnctn.conditions.value === 'defined' && account[fnctn.conditions.key]) return true;
				// Simple comparison
				if (account[fnctn.conditions.key] == fnctn.conditions.value) return true;

				return false;
			},

			/**
			 * @name submitDeleteAccount
			 * @description Remove member of a firm
			 */
			submitDeleteAccount(account) {
				this.$refs.confirm
					.open('Delete Account', `Are you sure you wish to delete account ${account.name}?`, { app_bar_color: 'error', system_bar_color: 'error darken-2' })
					.then(() => {
						this.setAccountSubmittingStatus(account.id, 'deleting', true);

						const deleteAccount = !account.invite.pending ? this.deleteOrganisationUser : this.deleteUserInvitation;
						deleteAccount(account.id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', `Account ${account.name} has been successfully deleted`);
								if (!account.invite.pending) this.fetchOrganisationUserList();
								else this.fetchUserInvitations();
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occurred'))
							.finally(() => this.setAccountSubmittingStatus(account.id, 'deleting', false, this.fetchUserInvitations()));
					});
			},

			/**
			 * @name submitSuspendAccount
			 * @description Suspend account
			 */
			submitSuspendAccount(account) {
				this.$refs.confirm.open('Suspend Account', `Are you sure you wish to suspend account ${account.name}?`).then(() => {
					this.setAccountSubmittingStatus(account.id, 'deleting', true);

					this.modifyOrganisationUser({ id: account.id, payload: { suspended: true } })
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `Account ${account.name} has been successfully suspended`);
							this.fetchOrganisationUserList();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'deleting', false));
				});
			},

			/**
			 * @name submitReactivateAccount
			 * @description Reactivate Suspended account
			 */
			submitReactivateAccount(account) {
				this.$refs.confirm.open('Reactivate Account', `Are you sure you wish to reactivate account ${account.name}?`).then(() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.modifyOrganisationUser({ id: account.id, payload: { suspended: false } })
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `Account ${account.name} has been successfully reactivated`);
							this.fetchOrganisationUserList();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
				});
			},

			/**
			 * @name openTradingStyleDialog
			 * @description Opens Trading style select dialog
			 */
			openTradingStyleDialog(account) {
				this.$refs.selectTradingStyle.open(account);
			},

			/**
			 * @name tradingStyleSelected
			 * @description Event handler for trading style selection
			 */
			async tradingStyleSelected(data) {
				this.setAccountSubmittingStatus(data.account.id, 'updating', true);

				let tradingStyles = [];
				for(const tradingStyle of Object.values(data.organisationTradingStyles)) {
					tradingStyles.push(tradingStyle);
				}

				this.updateUserOrganisationTradingStyles({ userId: data.account.id, organisationTradingStyles: tradingStyles })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', `Trading styles have been updated for this user`);
						this.fetchOrganisationUserList();
					})
					.catch((e) => console.log(e))
					.finally(() => this.setAccountSubmittingStatus(data.account.id, 'updating', false));
			},

			/**
			 * @name unlinkTradingStyleHandler
			 * @description Suspend account
			 */
			unlinkTradingStyleHandler(account) {
				this.$refs.confirm.open('Unlink Trading Style', `Are you sure you wish to unlink ${account.name} from ${account.tradingStyle.name}?`).then(async() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.deleteUserOrganisationTradingStyle(account.userTradingStyleId)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', `${account.name} has been unlinked from ${account.tradingStyle.name}`);
							this.fetchOrganisationUserList();
						})
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
				});
			},

			/**
			 * @name submitCancelInvitation
			 * @description Cancel invitation
			 */
			submitCancelInvitation(account) {
				this.$refs.confirm.open('Cancel Invitation', `Are you sure you wish to cancel ${account.name}'s invitation?`).then(() => {
					this.setAccountSubmittingStatus(account.id, 'updating', true);

					this.deleteUserInvitation(account.id)
						.then(() => ElementTools.fireNotification(this.$el, 'success', `${account.name}'s invitation has been cancelled`))
						.catch((e) => console.log(e))
						.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false, this.fetchUserInvitations()));
				});
			},

			/**
			 * @name resendInvitation
			 * @description Resend invitation
			 */
			async resendInvitation(account) {
				const formData = {
					organisationId: this.organisation.id,
					data: { inviterUserId: this.user.id },
					registration: {
						identity: account.email,
						identityType: 'email',
						data: { name: account.name }
					}
				};

				this.setAccountSubmittingStatus(account.id, 'updating', true);

				this.reinvite({ id: account.id, payload: formData })
					.then(() => ElementTools.fireNotification(this.$el, 'success', `Invitation has been sent to ${account.name}`))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `Failed to invite`))
					.finally(() => this.setAccountSubmittingStatus(account.id, 'updating', false));
			},

			/**
			 * @name checkInactive
			 * @description Check if user has been inactive
			 */
			checkInactive(user) {
				if (!user.userAccount?.loginCurrent && !user.userAccount?.loginPrevious) return true;

				const difference = this.$moment().diff(this.$moment(user.userAccount.loginCurrent || user.userAccount.loginPrevious), 'months');
				if (difference >= 3) return true;

				return false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.pagination-column ul {
		width: auto;
	}

	.shrunk {
		width: 1px;
		white-space: nowrap;
	}

	.tbl-row--deleted {
		opacity: 0.5;
	}
</style>
