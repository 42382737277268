<!--
	@name app-common-api-health
	@description Display API health for all lenders
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog width="500" persistent :value="model">
		<v-toolbar dense :color="status" dark>
			<v-toolbar-title>
				API Health
			</v-toolbar-title>

			<v-spacer />

			<v-tooltip bottom>
				<template #activator="{ on }">
					<v-btn icon color="white" v-on="on" @click="productLoaderDisplayInfo = !productLoaderDisplayInfo">
						<v-icon>mdi-frequently-asked-questions</v-icon>
					</v-btn>
				</template>
				<span>What's all this?</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template #activator="{ on }">
					<v-btn icon color="white" v-on="on" @click="close">
						<v-icon>mdi-window-minimize</v-icon>
					</v-btn>
				</template>
				<span>Minimise window</span>
			</v-tooltip>
		</v-toolbar>

		<v-card-text v-show="!productLoaderDisplayInfo" class="py-2 px-0 white">
			<app-common-api-health-items :providers="providers" />
		</v-card-text>
		<v-card-text v-show="productLoaderDisplayInfo" class="pa-5 white">
			<v-btn icon class="float-left mr-3 mb-2" @click="productLoaderDisplayInfo = false">
				<v-icon> mdi-arrow-left </v-icon>
			</v-btn>
			<p class="text-body-2 mb-3">
				Should a provider make us aware of an issue that could affect the performance of their API, our team will immediately update their API health status to either
				<span class="warning--text">amber</span> or <span class="error--text">red</span> depending on the severity of the issue. Whenever an API health issue is raised,
				our team will add an accompanying note to provide as much detail as possible.
			</p>
			<p class="text-body-2 mb-0 font-weight-bold">
				Click 'view details' for any affected provider to see information relating to potential workarounds and expected timescales for resolution.
			</p>
		</v-card-text>
	</v-dialog>
</template>

<script>
	import AppCommonApiHealthItems from '@/component/app/common/api-health/items';

	export default {
		name: 'app-common-api-health',

		components: {
			AppCommonApiHealthItems
		},

		props: {
			providers: { type: Array, required: true }
		},

		data() {
			return {
				model: false,
				productLoaderDisplayInfo: false,
				state: null
			};
		},

		computed: {
			status() {
				let errors = this.providers.filter((p) => p.apiHealth?.status && ['error'].includes(p.apiHealth.status?.toLowerCase())).length;
				if(errors) return 'error';
				let warnings = this.providers.filter((p) => p.apiHealth?.status && ['warning'].includes(p.apiHealth.status?.toLowerCase())).length;
				if(warnings) return 'warning';
				return 'success';
			}
		},

		methods: {
			open(state = 'active') {
				this.state = state;
				this.model = true;
			},

			close() {
				this.model = false;
			}
		}
	};
</script>
