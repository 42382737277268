import { ProxyFloodReport } from '@/service';
/**
 * @class @name Calculator
 * @description Exposes menu store module
 */
export default class Calculator {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name getFloodReport
			 * @param {function} commit
			 */
			async getFloodReport(store, payload) {
				const { postcode, radius } = payload;
				const floodReports = await ProxyFloodReport.post({ postcode, radius });
				let status = 0;
				if (floodReports.data.error) status = 1;
				return {
					status: status,
					message: 'Success!',
					warnings: floodReports.data.flood,
					areas: floodReports.data.floodAreas,
					longitude: floodReports.data.longitude,
					latitude: floodReports.data.latitude
				};
			},

			/**
			 * @async @name getFloodReport
			 * @param {function} commit
			 */
			async generateFloodReport(context, payload) {
				return ProxyFloodReport.pdf(payload);
			}
		};
	}
}
