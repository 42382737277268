import { $http } from '@/utils';

/**
 * @namespace Service
 * @class AuthenticateTwoFactor
 * @exports Service/Account/AuthenticateTwoFactor
 * @description API AuthenticateTwoFactor Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class AuthenticateTwoFactor {
	/**
	 * @static @name patch
	 * @description Get two factor codes
	 * @returns {Object} Promise a http response
	 */
	static patch(data) {
		return $http.patch('account/authenticate-two-factor', data);
	}

	/**
	 * @static @name get
	 * @description Get two factor codes
	 * @returns {Object} Promise a http response
	 */
	static get() {
		return $http.get('account/authenticate-two-factor');
	}

	/**
	 * @static @name delete
	 * @description Get two factor codes
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete('account/authenticate-two-factor', id);
	}
}
