<!--
	@name app-winners
	@description Winners page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img
			:src="'events/highlights_cover-cropped.png' | urlize"
			style="border-bottom: 20px solid #1eb0e1"
			:height="$vuetify.breakpoint.mdAndUp ? '600' : ''"
		>
			<div id="overlay" />
			<v-container style="position: relative" fill-height>
				<v-col>
					<div class="text-h3 mt-6 white--text">
						Awards 2022
					</div>
				</v-col>
				<v-col>
					<v-img style="width: 24rem" :src="'logo/brand/later-life-lending-awards-logo.png' | urlize" />
				</v-col>
			</v-container>
		</v-img>

		<!-- About Us -->
		<div>
			<v-container>
				<div class="text-center d-flex flex-column align-center pt-12">
					<div class="text-h4 mb-6">
						Winners Revealed
					</div>
					<p class="text-body-1" style="max-width: 800px">
						Congratulations to everyone who won one of our Later Life Lending Awards. All your hard work has definitely not gone unnoticed.
					</p>
				</div>
			</v-container>
		</div>

		<!-- Winners -->
		<div>
			<v-container>
				<v-row no-gutters class="justify-center mb-10">
					<v-col v-for="(n, i) in categories" :key="n.name" cols="12" md="4" class="d-flex flex-column">
						<v-card class="px-2 py-8 ma-6 text-center flex d-flex flex-column" elevation="2">
							<img :src="'/img/numbers/' + (i + 1) + '.svg'" height="80" />

							<div class="flex text-body-1 font-weight-bold mt-4">
								{{ n.name }}
							</div>

							<div class="mt-2">
								{{ n.winner }}
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-winners',

		components: {},

		data() {
			return {
				categories: [
					{
						name: 'Best Later Life Provider',
						winner: 'more2life'
					},
					{
						name: 'Best Large Advisory Firm (+5)',
						winner: 'Viva Retirement Solutions'
					},
					{ name: 'Newcomer of the Year', winner: 'John Harris – Cooper Associates' },

					{
						name: 'Best Later Life Online Support',
						winner: 'Knowledge Bank'
					},
					{
						name: 'Best Small Advisory Firm (2-5)',
						winner: 'Ocean Equity Release'
					},
					{
						name: 'Consumer Champion of the Year',
						winner: 'Jon Dunckley – About Consulting'
					},

					{
						name: 'Best Legal Firm',
						winner: 'Barton Law'
					},
					{
						name: 'Later Life Advisor of the Year',
						winner: 'Liam Jenkins – StepChange'
					},
					{
						name: 'Underwriter of the Year',
						winner: 'Pat Leese – Standard Life Home Finance'
					},
					{
						name: 'BDM of the Year',
						winner: 'Fran Green – more2life'
					},
					{
						name: 'Later Life Lending Innovation of the Year',
						winner: 'Comentis'
					},
					{
						name: 'Best Adviser Support & Training of the Year',
						winner: 'About Consulting'
					},
					{
						name: 'Lifetime Achievement Award',
						winner: 'Stuart Wilson'
					},
					{
						name: 'Back Office Support of the Year',
						winner: 'Amber Gerard'
					},
					{
						name: 'Network of the Year',
						winner: 'HLPartnerships'
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>

<style lang="scss" scoped>
	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}
</style>
