<!--
	@name app-firm-tab-view-trading-styles
	@description Firm Trading Styles
	@date 2020/08/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section thick-header>
			<template #header>
				<v-pagination v-model="currentPage" :length="pageCount" :total-visible="8" color="info" />

				<v-spacer />

				<v-btn v-if="department.name.includes('owner')" @click="openTradingStyleDialog">
					Add New Trading Style
				</v-btn>
			</template>

			<template #body>
				<v-skeleton-loader v-if="!organisationTradingStyleList" type="list-item-avatar-three-line@3" class="px-0" />

				<div v-else-if="!organisationTradingStyleList.length">
					<div class="text-body-1 font-weight-bold mb-3">
						Create separate Trading Styles with which to group Accounts.
					</div>
					<div class="text-body-2 mb-3">
						Once a Trading Style has been created, you can assign it to an Account using the Link Trading Style action in the Manage Accounts section. Assigning an
						account to a Trading Style will result in the Trading Style being used in place of the Company details for system generated documentation such as Fact
						Finds.
					</div>
					<div class="text-body-2">
						Click the ADD NEW TRADING STYLE button to get started.
					</div>
				</div>

				<v-data-table
					v-else
					class="data-table"
					:footer-props="footerProps"
					:headers="headers"
					:items="orderedTradingStyles"
					:items-per-page="itemsPerPage"
					:page.sync="currentPage"
					@page-count="pageCount = $event"
				>
					<template #body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.id" class="tbl-row">
								<td>
									<p class="text-body-1 font-weight-bold mb-0">
										{{ item.name }}
									</p>
								</td>
								<td>
									<p class="text-body-1 font-weight-bold mb-0">
										{{ item.data?.fca }}
									</p>
								</td>
								<td v-if="site.tradingStylePanels && department.name.includes('owner')">
									<div v-if="item.panelData?.panel?.sourcing" class="mx-n2 mt-n4">
										<div v-for="id in item.panelData?.panel?.sourcing" :key="id" class="d-block">
											<v-chip class="ml-2 mt-4">
												{{ getOrganisationName(id) }}
											</v-chip>
											<v-btn color="primary" class="small" @click="openRestrictionDialog(item, id)">
												Advanced
											</v-btn>
											<div class="d-block">
												<span v-for="term in item.panelData?.reject?.[id]" :key="term">
													<v-chip x-small class="mr-2 mt-2 v-chip v-chip--label theme--dark v-size--small">
														<span class="v-chip__content">{{ term }}</span>
													</v-chip>
												</span>
											</div>
										</div>
									</div>
								</td>

								<td v-if="site.tradingStylePanels && department.name.includes('owner')">
									{{ item.approved ? 'Approved' : 'Pending' }}
								</td>

								<td v-if="site.tradingStylePanels && department.name.includes('owner')">
									<v-btn v-if="organisations.length > 0" color="primary" class="d-block" @click="openProvidersDialog(item)">
										Update Panel
									</v-btn>
								</td>

								<td v-if="department.name.includes('owner')" class="text-right shrunk">
									<v-tooltip bottom content-class="text-center" max-width="250">
										<template #activator="{ on }">
											<v-btn color="error" small text :loading="loading[item.id]" @click="deleteTradingStyle(item)" v-on="on">
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
										</template>
										<span> Delete this trading style </span>
									</v-tooltip>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</template>
		</common-structure-section>

		<common-dialog-confirm ref="confirm" />
		<app-firm-dialog-add-trading-style ref="addTradingStyleDialog" :form="form('firm_trading_styles').value" />
		<app-firm-dialog-add-providers ref="providersDialog" :organisations="organisations" @submitted="loadOrganisationTradingStyleList" />
		<app-firm-dialog-restriction ref="restrictionDialog" @submitted="loadOrganisationTradingStyleList" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { orderBy } from 'lodash';
	import { ElementTools } from '@/utils';

	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppFirmDialogAddTradingStyle from '../dialog/add-trading-style';
	import AppFirmDialogAddProviders from '../dialog/add-providers';
	import AppFirmDialogRestriction from '../dialog/restriction';

	export default {
		name: 'app-firm-members-list',

		components: { CommonStructureSection, CommonDialogConfirm, AppFirmDialogAddTradingStyle, AppFirmDialogAddProviders, AppFirmDialogRestriction },

		props: {},

		data() {
			return {
				ots: [],
				loading: {},
				formData: {},
				currentPage: 1,
				itemsPerPage: 10,
				pageCount: 0,
				organisations: [],
				selectedProviders: [],
				organisationUserList: [],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				headers: [
					{ text: 'Name', align: 'left', value: 'name', class: 'text-no-wrap' },
					{ text: 'FCA No.', align: 'left', value: 'data.fca', class: 'text-no-wrap' },
					{ text: 'Panel', align: 'left', value: 'panelData.sourcing', class: 'text-no-wrap' },
					{ text: 'Status', align: 'left' },
					{ text: '', align: 'left' },
					{ text: '', align: 'centre' }
				]
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['organisation', 'department']),
			...mapState('AccountOrganisationTradingStyle', ['organisationTradingStyleList']),
			...mapGetters('CmsForm', ['form']),

			orderedTradingStyles() {
				return orderBy(this.organisationTradingStyleList, 'created', ['desc']) || [];
			},

			providerOptions() {
				return this.organisations
					?.filter((o) => !this.selectedProviders.includes(o.id))
					?.map((o) => ({
						text: o.name,
						value: o.id
					}));
			}
		},

		created() {
			// Remove columns for all users other than business owner for WL with trading style panels access
			if(!(this.site.tradingStylePanels && this.department.name.includes('owner'))) {
				this.headers = this.headers.filter((h) => !(h.text === 'Panel' || h.text === 'Status' || h.text === ''));
			}
			this.loadOrganisationTradingStyleList();
			this.fetchOrganisationList();
			this.fetchOrganisationUserList();
			this.loadForm('firmTradingStyles');
		},

		methods: {
			...mapActions('AccountOrganisation', ['loadProviderList']),
			...mapActions('AccountOrganisationTradingStyle', ['loadOrganisationTradingStyleList', 'deleteOrganisationTradingStyle']),
			...mapActions('AccountOrganisationUser', ['loadOrganisationUserList']),
			...mapActions('CmsForm', ['loadForm']),

			fetchOrganisationList() {
				this.loadProviderList({ /* where: { id: { value: organisationIds, type: 'uuid' } },*/ order: { property: 'organisation.name' } }).then(({ data }) => {
					this.organisations = data;
				});
			},

			fetchOrganisationUserList() {
				this.loadOrganisationUserList(this.organisation.id).then(({ data }) => {
					this.organisationUserList = data;
				});
			},

			/**
			 * @name getOrganisationName
			 * @description Get organisation name by id
			 * @param {String} id
			 */
			getOrganisationName(id) {
				return this.organisations?.find((o) => o.id === id)?.name;
			},

			/**
			 * @name openTradingStyleDialog
			 * @description Open add trading style dialog
			 */
			openTradingStyleDialog() {
				this.$refs.addTradingStyleDialog.open();
			},

			/**
			 * @name openProvidersDialog
			 * @description Event for provider panel dialog
			 */
			openProvidersDialog(item) {
				this.$refs.providersDialog.open(item);
			},

			/**
			 * @name openRestrictionDialog
			 * @description Event for product restriction dialog
			 */
			 openRestrictionDialog(item, id) {
				this.$refs.restrictionDialog.open(item, id);
			},

			/**
			 * @name deleteTradingStyle
			 * @description Delete trading style
			 * @param {Object} item
			 */
			async deleteTradingStyle(item) {
				if (this.userByOrganisationTradingStyleId(item.id))
					ElementTools.fireNotification(
						this.$el,
						'error',
						`${item.name} cannot be removed since there are accounts associated to it. Please unlink all accounts from this trading style before attempting to delete it`
					);
				else {
					this.$refs.confirm.open('Delete Trading Style', `Are you sure you wish to delete ${item.name}?`).then(async() => {
						this.$set(this.loading, item.id, true);

						this.deleteOrganisationTradingStyle(item.id)
							.then(() => ElementTools.fireNotification(this.$el, 'success', `${item.name} has been successfully deleted`))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete trading style'))
							.finally(() => this.$set(this.loading, item.id, false));
					});
				}
			},

			/**
			 * @name userByOrganisationTradingStyleId
			 * @description Get user by organisation trading style id
			 * @param {Object} item
			 */
			userByOrganisationTradingStyleId(id) {
				return this.organisationUserList.find((u) => u.organisationTradingStyle && u.organisationTradingStyle.id === id);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.shrunk {
		width: 1px;
		white-space: nowrap;
	}
</style>
