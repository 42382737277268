<!--
	@name app-market
	@description Marketing Page
	@date 2020/05/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-toolbar title="Marketing" />

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<v-card class="mx-auto">
					<!-- <v-img :src="imagePath('construction.jpg')" height="390" /> -->
					<v-card-text class="pt-10 pb-10 amber lighten-5">
						<div class="d-flex">
							<div>
								<v-icon size="75" color="amber" class="mt-n3">
									mdi-format-quote-open
								</v-icon>
							</div>
							<div>
								<h2 class="text-h5 font-weight-bold text-center success--text text--darken-3">
									Technology is nothing. What's important is that you have a faith in people, that they're basically good and smart, and if you give them tools,
									they'll do wonderful things with them.
								</h2>
								<h3 class="text-h6 mt-5 font-weight-light text-center success--text text--darken-3">
									Steve Jobs
								</h3>
							</div>
							<div class="align-self-end">
								<v-icon size="75" color="amber" class="mb-n3">
									mdi-format-quote-close
								</v-icon>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col class="py-0" cols="12" md="4">
				<v-sheet v-if="adverts.length" class="pa-5">
					<common-promotion-carousel :adverts="adverts" orientation="landscape" />
				</v-sheet>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import CommonPromotionCarousel from '@/component/common/promotion/carousel';
	import CommonStructureToolbar from '@/component/common/structure/toolbar';

	export default {
		name: 'app-market',

		components: { CommonPromotionCarousel, CommonStructureToolbar },

		computed: {}
	};
</script>
