<!--
	@name app-insights-hub-content-list
	@description Insights Hub Content List
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Air News
					</div>
				</div>
			</v-container>
		</v-img>

		<div>
			<v-container>
				<!-- Content list -->
				<div class="pb-8">
					<div v-if="loading">
						<v-row>
							<v-col v-for="i in 3" :key="i" md="4" sm="12" xs="12" class="mt-4">
								<v-skeleton-loader type="card" />
								<v-skeleton-loader type="list-item-three-line" />
							</v-col>
						</v-row>
					</div>

					<v-row v-else>
						<v-col v-for="(item, index) in filteredNews" :key="index" md="4" sm="12" xs="12" class="mt-4">
							<v-card class="d-flex flex-column fill-height mx-auto">
								<v-img :src="item.image || 'placeholder/gradient.png' | urlize" min-height="200px" max-height="200px" />

								<div class="pa-4">
									<div class="text-body-2">
										Air News
									</div>

									<div class="mt-4 text-h5 secondary--text line-clamp" :title="item.name">
										{{ item.name }}
									</div>

									<p class="text-body-2 mt-4 line-clamp" style="-webkit-line-clamp: 3">
										{{ item.headline | truncate(250) }}
									</p>
								</div>

								<v-spacer />
								<div>
									<v-btn color="info" small class="ml-4 mb-4" @click="readMore(item)">
										Read more
									</v-btn>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</div>
				<v-pagination v-if="!loading" v-model="page" :length="length" :total-visible="7" class="mb-12" />
			</v-container>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { PopupWindow } from '@/utils';

	export default {
		name: 'app-insights-hub-content-list',

		components: {},

		data() {
			return {
				page: 1,
				loading: true,
				breadcrumbs: [{ text: 'Insights Hub', exact: true, to: '/air-later-life/insights-hub' }, { text: 'Air News' }]
			};
		},

		computed: {
			...mapGetters('CmsNews', ['nonMemberNewsList']),

			filteredNews() {
				return this.nonMemberNewsList.slice((this.page - 1) * 6, this.page * 6);
			},

			length() {
				return Math.ceil(this.nonMemberNewsList.length / 6);
			}
		},

		created() {
			this.loadNewsList().then(() => {
				this.loading = false;
			});
		},

		methods: {
			...mapActions('CmsNews', ['loadNewsList']),

			readMore(item) {
				if (item.url) new PopupWindow().open(item.url, '_blank');
				else this.$router.push(`/news/${item.id}`);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.line-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
</style>
