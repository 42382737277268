import { $http } from '@/utils';

/**
 * @namespace Service
 * @class OrganisationTradingStyle
 * @exports Service/Account/OrganisationTradingStyle
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class OrganisationTradingStyle {
	/**
	 * @static @name post
	 * @description Add a new Organisation Trading Style resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/organisation/trading-style`, payload);
	}

	/**
	 * @static @name list
	 * @description Get all organisation trading styles with details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list() {
		return $http.get(`account/organisation/trading-style`);
	}

	/**
	 * @static @name delete
	 * @description Hard delete Organisation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/organisation/trading-style/${id}`);
	}

	/**
	 * @static @name updateSourcingPanel
	 * @description Update the sourcing provider panel for a given trading style
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static updateSourcingPanel(id, payload) {
		return $http.patch(`account/organisation/trading-style/${id}/sourcing-providers`, payload);
	}
}
