import { InterfaceUser } from '@/service';

/**
 * @class @name InterfaceUser
 * @description Exposes menu store module
 */
export default class InterfaceUserModeule {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadCredentialList
			 * @param {function} commit
			 */
			async loadCredentialList(context, payload) {
				return InterfaceUser.list(payload);
			},

			/**
			 * @async @name getCredential
			 * @param {function} commit
			 */
			async getCredential(context, id) {
				return InterfaceUser.get(id);
			},

			/**
			 * @async @name updateCredential
			 * @param {function} commit
			 */
			async modifyCredential(context, { id, payload }) {
				return InterfaceUser.patch(id, payload);
			},

			/**
			 * @async @name deleteCredential
			 * @param {function} commit
			 */
			async deleteCredential(context, id) {
				return InterfaceUser.delete(id);
			},

			/**
			 * @async @name addCredential
			 * @param {function} commit
			 */
			async addCredential(context, payload) {
				return InterfaceUser.post(payload);
			}
		};
	}
}
