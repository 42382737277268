<!--
	@name app-common-terms-business-owner
	@description Terms
	@date 2020/11/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<div v-if="site">
			<p><strong>&nbsp;Please read in conjunction with Our Privacy Policy</strong></p>
			<p>&nbsp;</p>
			<ol>
				<li><strong>Definitions</strong></li>
			</ol>
			<p>In these Terms and Conditions (&ldquo;<strong>Agreement</strong>&rdquo;) the following terms shall have the meanings set out below:</p>
			<p>
				<strong>{{ site.softwareName }}</strong>&nbsp;or&nbsp;<strong>Websites</strong>&nbsp;means the websites under the domain name {{ website_name }} or any other domain we may use that point to the
				{{ site.softwareName }} software. {{ site.softwareName }} is a trading style of {{ site.company.name }};
			</p>
			<p><strong>Company, Us, Our, Ourselves or We&nbsp;</strong>means {{ site.company.name }};</p>
			<p>
				<strong>Customer, Authorised User or User&nbsp;</strong>means financial intermediaries authorised and regulated by the Financial Conduct Authority who have
				registered and been approved to access the Services of the {{ site.softwareName }} websites or an employee or agent of the Customer who is authorised by the
				Customer to access the Services;
			</p>
			<p>
				<strong>Customer Personal Data</strong>&nbsp;means Personal Data which the Company may have access to in the course of the provision of the Services and in relation
				to which the Customer, a member of the Customer&rsquo;s Group, or an Authorised User of {{ site.softwareName }} acts as Controller.&lsquo;Customer Personal
				Data&rsquo; will include Personal Data which is provided in order to register Authorised Users for {{ site.softwareName }}, and Personal Data which is input into
				{{ site.softwareName }} in the course of using and receiving the Services;
			</p>
			<p><strong>Processor</strong> means the Company {{ site.company.name }}</p>
			<p><strong>Authorised Processor</strong>&nbsp;means the Company and any Sub-processor engaged by the Company in accordance with clause 6;</p>
			<p>
				<strong>Subprocessor&nbsp;</strong>means any person (including any third party and any member of the Company, but excluding an employee of the Company) appointed by
				or on our behalf to process Customer Personal Data on Our behalf;
			</p>
			<p>
				<strong>Services or Resources&nbsp;</strong>means the services made available via {{ site.softwareName }}, including (without limitation) the ability to obtain
				online quotes and comparisons relating to financial products, and to submit and track new business<strong>;</strong>
			</p>
			<p>
				<strong>Data Privacy Legislation means</strong> (i) the Data Protection Act 2018; (ii) the United Kingdom General Data Protection Regulations (UK GDPR) as
				established by the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2019 and (iii) any other directly applicable law
				or regulation relating to privacy and the protection of personal data (including with regards to electronic communications).
			</p>
			<p>
				<strong>UK GDPR</strong> means the United Kingdom General Data Protection Regulations (UK GDPR) as established by the Data Protection, Privacy and Electronic
				Communications (Amendments etc.) (EU Exit) Regulations 2019.
			</p>
			<p>
				<strong>Third Party Product Provider/s</strong>&nbsp;means a third party who makes available certain financial products and/or services via {{ site.softwareName }};
			</p>
			<p><strong>You or Your</strong>&nbsp;means any person who accesses and uses the Websites;</p>
			<p>
				The terms "<strong>Controller</strong>", "<strong>Processor</strong>", "<strong>Data Subject</strong>", "<strong>Personal Data</strong>", &ldquo;<strong>Personal Data Breach</strong>&rdquo;, &ldquo;<strong>Supervisory Authority</strong>&rdquo; and "<strong>processing</strong>" shall have the meaning given to those terms in the Data Privacy
				Legislation, and "<strong>process</strong>" and "<strong>processed</strong>" shall be construed accordingly.
			</p>
			<p>&nbsp;</p>
			<ol start="2">
				<li><strong>Acceptance of Terms</strong></li>
			</ol>
			<p>
				By accessing the {{ site.softwareName }} Websites or using any of the resources provided by these Websites You agree to be bound by these Terms and Conditions and
				acknowledge the terms of Our Privacy Policy. If we make a change to this Agreement or Our Privacy Policy We will inform Authorised Users by publishing an
				announcement on {{ site.softwareName }}. The amended Agreement or Privacy Policy will be effective from the date they are posted on {{ site.softwareName }}.
			</p>
			<p>&nbsp;</p>
			<ol start="3">
				<li><strong>License</strong></li>
			</ol>
			<p><strong>3.1.</strong>&nbsp;You are permitted to print and download extracts from the Website for Your own use on the following basis:</p>
			<ul>
				<li>no material or related graphics on the Websites are modified by You in any way;</li>
				<li>no graphics on the Websites are used by You separately from the corresponding text; and</li>
				<li>The Company's copyright and trade mark notices and this permission notice appear in all copies that you make of extracts from the Website.</li>
			</ul>
			<p>
				<strong>3.2.</strong>&nbsp;Unless otherwise stated, the copyright and other intellectual property rights in all material on the Website (including without
				limitation graphical images) are owned by the Company or its licensors. For the purposes of this legal notice, any use of extracts from the Website other than in
				accordance with clause 3.1 for any purpose is prohibited. If you breach any of the terms in this legal notice, your permission to use the websites will be
				terminated and you must immediately destroy any downloaded or printed extracts from the website.
			</p>
			<p>
				<strong>3.3.</strong>&nbsp;Subject to clause 3.1, no part of the Website may be reproduced or stored in any other website or included in any public or private
				electronic retrieval system or service without the Company's prior written permission.
			</p>
			<p><strong>3.4.</strong>&nbsp;Any rights not expressly granted in these terms are reserved.</p>
			<p>&nbsp;</p>
			<ol start="4">
				<li><strong>Registration</strong></li>
			</ol>
			<p>
				<strong>4.1.</strong>&nbsp;When you register with {{ site.softwareName }} You are responsible for safeguarding your username and password. Please ensure that you
				keep your password secure and do not reveal it to anyone. If you believe your password has been compromised then please take action to change it immediately. We
				reserve the right to cancel your registration with {{ site.softwareName }} where we have evidence to believe that you are not an intermediary authorised and
				regulated by the Financial Conduct Authority to conduct regulated business. We will notify you in advance and allow you to provide evidence to the contrary.
			</p>
			<p>
				<strong>4.2.</strong>&nbsp;Each registration is for one Authorised User only. The Company does not permit you to share your user name and password with any other
				person nor with multiple users on a network.
			</p>
			<p>&nbsp;</p>
			<ol start="5">
				<li><strong>Customer Group</strong></li>
			</ol>
			<p>
				If the Personal Data being processed is data in relation to which a member of Our Group acts as Controller, then all rights afforded to the Customer and all
				obligations applicable to the Customer in this document shall also be deemed to apply to the relevant Customer Group member. Notwithstanding the foregoing, any
				claims under these Terms and Conditions may only be made by the Customer.
			</p>
			<p>&nbsp;</p>
			<ol start="6">
				<li><strong>Processing of Customer Personal Data</strong></li>
			</ol>
			<p>
				<strong>6.1.</strong>&nbsp;The parties agree that for the purposes of the Data Privacy Legislation, We shall, in the course of the provision of the Services, be
				acting as a Processor in respect of the Customer Personal Data.
			</p>
			<p>
				<strong>6.2.</strong>&nbsp;Each party agrees to comply with the Data Privacy Legislation applicable to it in its role as Controller or Processor (as the case may
				be) to the extent that it relates to the provision and/or receipt of the Services provided under this Agreement.
			</p>
			<p>
				<strong>6.3.</strong>&nbsp;We shall not process Customer Personal Data other than on the Company's documented instructions (provided that such instructions are
				within the scope of the Services provided by {{ site.softwareName }}), unless We are required to process the Customer Personal Data by any law to which We are
				subject (in such a case We shall inform the Customer of that legal requirement before processing unless that law prohibits Us from so notifying the Customer). For
				the avoidance of doubt, an instruction given by you or any Authorised User shall be deemed to have been given on the Customer's behalf.
			</p>
			<p><strong>6.4.</strong>&nbsp;Details regarding the scope, nature and purpose of the processing, the type of Personal Data</p>
			<p>
				Processed by us, the duration of the processing and the categories of Data Subject (the &ldquo;Processing Information&rdquo;) are set out in Annex 1. The Customer
				hereby instructs and authorises the Company (and any Authorised Processor) to process the Customer Personal Data in a manner consistent with Annex 1, and where You
				acting on behalf of a member of Your Group, You warrant that You are and will at all relevant times remain duly authorised to give the instruction set out in this
				clause 6.4 on behalf of each relevant member of Your Group.
			</p>
			<p>
				<strong>6.5.</strong>&nbsp;We shall ensure that appropriate technical, organisational and security measures are taken against unauthorised or unlawful processing of
				the Customer Personal Data and against accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, such Personal Data.
			</p>
			<p>
				<strong>6.6.</strong>&nbsp;We shall take reasonable steps to ensure the reliability of any employee, agent or contractor of the Company or any Authorised Processor
				who may have access to Customer Personal Data and shall ensure that such personnel are aware of the confidential nature of the Customer Personal Data and are
				subject to enforceable duties of confidence in respect of Customer Personal Data.
			</p>
			<p>
				<strong>6.7.</strong>&nbsp;We shall notify the Customer promptly upon receipt of any subject access request or other request received from a Data Subject in
				accordance with the Data Privacy Legislation, and at the Customer&rsquo;s cost, assist the Customer utilising appropriate technical and organisational measures, in
				so far as this is possible, in order that the Customer may respond to any such request in a timely manner and in accordance with the Customer&rsquo;s obligations
				under the Data Privacy Legislation.
			</p>
			<p><strong>6.9.</strong>&nbsp;We notify the Customer without undue delay upon becoming aware of any Personal Data Breach.</p>
			<p>
				<strong>6.10.</strong>&nbsp;We shall, at the Customers cost, provide reasonable assistance to the Customer in relation to any data protection impact assessments and
				prior consultations with Supervisory Authorities or other competent data privacy authorities, which the Customer reasonably considers to be required pursuant to
				article 35 or 36 of the UK GDPR or equivalent provisions of any other Data Privacy Legislation, in each case solely in relation to the processing of the Customer
				Personal Data by, and taking into account the nature of the processing and information available to, any Authorised Processor.
			</p>
			<p>
				<strong>6.11.</strong>&nbsp;We shall maintain all necessary records and information to demonstrate its compliance with the provisions set out in this clause 6 and
				shall allow for audits to be conducted by the Customer (or an auditor mandated by the Customer) in accordance with clauses 6.12 to 6.15 below.
			</p>
			<p>
				<strong>6.12.</strong>&nbsp;The Customer shall ensure that We are provided with reasonable notice of any audit or inspection to be conducted under clause 6.11 and
				the Customer shall ensure that it complies (and ensure that any third party carrying out an audit on its behalf complies) with any reasonable restrictions and
				requirements for access (including but not limited to security processes, confidentiality and vetting procedures) imposed by the Company, an Authorised Processor,
				or any other operator of the relevant premises and shall ensure that the conduct of the visits is within normal business hours and shall not unreasonably disturb
				the business operations of the Company or an Authorised Processor.
			</p>
			<p>
				<strong>6.13.</strong>&nbsp;Upon the provision of at least ten (10) business days' written notice of an audit (save where the Customer reasonably suspects that
				there has been a material breach by Us or an Authorised Processor of the provisions set out in this clause 6, in which case access may be granted on shorter
				notice), We shall allow the Customer (or its authorised representatives) reasonable access to the premises, information and personnel of the Company and its
				Authorised Processors to enable the Customer to inspect and audit the data processing activities carried out on behalf of the Customer and to verify Our compliance
				with the provisions set out in this clause 6.
			</p>
			<p>
				<strong>6.14.</strong>&nbsp;The Customer is only entitled to one audit or inspection of an Authorised Processor in any twelve month period, except for any
				additional audits or inspections which:
			</p>
			<ul>
				<li>the Customer reasonably considers necessary because of genuine concerns as to Our(or an Authorised Processor&rsquo;s) compliance with these terms; or</li>
				<li>
					the Customer is required or requested to carry out by Data Privacy Legislation, a Supervisory Authority or any similar regulatory authority responsible for the
					enforcement of Data Privacy Legislation,
				</li>
			</ul>
			<p>
				In each case provided that the Customer has identified its concerns or the relevant requirement or request in its notice to the Customer of the additional audit or
				inspection.
			</p>
			<p><strong>6.15.</strong>&nbsp;To the extent:</p>
			<ul>
				<li>
					a physical/ on-site audit or inspection carried out by the Customer (or its authorised representatives) pursuant to this clause 6 exceeds one business day, We
					shall be entitled to charge the Customer (at its standard rates from time to time) for any effort it reasonably expends in assisting such audit for every day
					over and above the initial day;
				</li>
				<li>
					We are required to respond to any written request for information made by the Customer (or its authorised representatives) relating to Our compliance with the
					provisions of this Agreement, We shall be entitled to charge the Customer (at its standard rates from time to time) for any effort it reasonably expends over
					and above half a business day.
				</li>
			</ul>
			<p>
				<strong>6.16.</strong>&nbsp;We shall, upon receipt of a written request from the Customer, delete or return all Customer Personal Data at the end of the provision
				of the Services to which the Customer Personal Data relates, provided that We may retain copies of the Customer Personal Data in accordance with any legal and
				regulatory requirements, and any guidance that has been issued in relation to deletion or retention by a Supervisory Authority.
			</p>
			<p><strong>6.17.</strong>&nbsp;The Customer authorises the Company to appoint the following Subprocessors:</p>
			<ul>
				<li>members of the Answers In Retirement Group; and</li>
				<li>Third party data centre and hosting services providers.</li>
			</ul>
			<p>
				<strong>6.18.</strong>&nbsp;In relation to the engagement of a Subprocessor other than one authorised pursuant to clause 6.17, We shall give the Customer prior
				written notice of the appointment of such Subprocessor, which may include publishing an announcement on {{ site.softwareName }} regarding the proposed appointment
				of such Subprocessor. Such notice shall include full details of the processing to be undertaken by that Subprocessor, and any other information regarding the
				activities and/or processes of the Subprocessor which we may deem relevant. If, within 30 days of receipt of that notice, the Customer notifies us in writing of any
				objections (on reasonable grounds) to the proposed appointment then we shall not appoint (nor disclose any Customer Personal Data to) the proposed Subprocessor
				except with the prior written consent of the Customer.
			</p>
			<p>
				<strong>6.19.</strong>&nbsp;We shall ensure that any arrangement between the Company and a Subprocessor is governed by a written contract including terms which
				offer at least the same level of protection for Customer Personal Data as those set out in this document and meets the requirements of article 28(3) of the UK GDPR
				or equivalent provisions of any other Data Privacy Legislation.
			</p>
			<p>
				<strong>6.20.</strong>&nbsp;We shall ensure that any transfers of Customer Personal Data to locations outside of the United Kingdom are undertaken in accordance
				with the obligations or standards imposed by Data Privacy Legislation.
			</p>
			<p>
				<strong>6.21. </strong>We shall hold your client&rsquo;s personal data for a minimum of 24 months (2years) after this the information may be removed from the
				{{ site.softwareName }} Platform. It is the responsibility of the Authorised Users to retain any client personal data for a longer period.
			</p>
			<p>&nbsp;</p>
			<ol start="7">
				<li><strong>Customer Acknowledgment</strong></li>
			</ol>
			<p>
				<strong>7.1.</strong>&nbsp;Without prejudice to the generality of clause 6.2, the Customer warrants that in relation to all Customer Personal Data which is
				processed by the Company or an Authorised Processor:
			</p>
			<ul>
				<li>
					the Customer will ensure that all required fair processing notices are provided to the relevant Data Subjects which are sufficient in scope to enable Us or any
					of the Authorised Processors to (i) transfer Customer Personal Data to Third Party Product Providers in an electronic communication sent via
					{{ site.softwareName }}, and (ii) otherwise to provide the Services and to carry out their obligations under the agreement in existence between the Customer and
					Ourselves, in accordance with the Data Privacy Legislation; and
				</li>
				<li>The Customer Personal Data transferred to us can be lawfully processed by the Company (or any of the Authorised Processors).</li>
			</ul>
			<p><strong>7.2.</strong>&nbsp;The Customer:</p>
			<ul>
				<li>
					acknowledges that the services provided via {{ site.softwareName }} require Us to transfer certain Customer Personal Data to Third Party Product Providers on
					behalf of the Customer (but for the avoidance of doubt, the Third Party Product Provider shall not be deemed to be a Subprocessor of the Company);
				</li>
				<li>
					Authorises us to transfer Customer Personal Data to a Third Party Product Provider. Where such Third Party Product Provider is based outside of the United
					Kingdom, the Customer warrants that it has obtained all necessary consents of the Data Subject to the proposed transfer in sufficient terms to enable Us to
					transfer the Customer Personal Data to the Third Party Product Provider;
				</li>
				<li>
					agrees that in relation to all Customer Personal Data which is transferred to Third Party Product Providers in an electronic message via
					{{ site.softwareName }}, the Customer will ensure that the fair processing notice provided to a Data Subject is sufficient in scope to enable the Third Party
					Product Provider to process the Customer Personal Data in order to provide an electronic response to such message.
				</li>
			</ul>
			<p>&nbsp;</p>
			<ol start="8">
				<li><strong>Cancellation Rights</strong></li>
			</ol>
			<p>
				<strong>8.1</strong>. You may cancel your account and have all Personal Information and data against your account removed by requesting termination of your account
				in writing. You can do so by sending an email to:&nbsp;<a>{{ site.contact.email }}</a>.
			</p>
			<p>
				<strong>8.2.</strong>&nbsp;We reserve the right to cancel your access to the Service where we have grounds to believe that you are not an intermediary authorised
				and regulated by the Financial Conduct Authority or we have evidence that you are in breach of the terms of this Agreement. We will notify you in advance in this
				event and allow you to provide evidence to the contrary, however we have absolute discretion to determine whether or not to permit your continued access to the
				Service.
			</p>
			<p>&nbsp;</p>
			<ol start="9">
				<li><strong>Disclaimer</strong></li>
			</ol>
			<p>
				<strong>9.1.</strong>&nbsp;While the Company endeavours to ensure that the information on the Website is correct, the Company does not warrant the accuracy and
				completeness of the information on the Website. All product information including interest rates, fees and product criteria are provided directly by Third Party
				Product Providers. The responsibility to keep all product information updated sits with the Third Party Product Providers who list and maintain their own product
				data on the Websites and the Company makes no commitment to update such information on their behalf.
			</p>
			<p>
				<strong>9.2.</strong>&nbsp;The information on the Website is provided "as is", without any conditions, warranties or other terms of any kind. Accordingly, to the
				maximum extent permitted by law, the Company provides You with the Website on the basis that the Company excludes all representations, warranties, conditions and
				other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill)
				which, but for this legal notice, might have effect in relation to the Website.
			</p>
			<p>&nbsp;</p>
			<ol start="10">
				<li><strong>Liability</strong></li>
			</ol>
			<p>
				<strong>10.1.</strong>&nbsp;The Company, any other party (whether or not involved in creating, producing, maintaining or delivering the Service), and any of the
				Company's officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage
				that may result to You or a third party (including without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income,
				profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including
				without limitation negligence), contract or otherwise) in connection with the Website in any way or in connection with the use, inability to use or the results of
				use of the Website, any websites linked to the Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect
				Your computer equipment, software, data or other property on account of Your access to, use of, or browsing the Website or Your downloading of any material from the
				Website or any websites linked to the Website.
			</p>
			<p><strong>10.2.</strong>&nbsp;Nothing in this legal notice shall exclude or limit the Company's liability for:</p>
			<ul>
				<li>death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977; or</li>
				<li>fraud; or</li>
				<li>misrepresentation as to a fundamental matter; or</li>
				<li>Any liability which cannot be excluded or limited under applicable law.</li>
			</ul>
			<p>
				<strong>10.3.</strong>&nbsp;If your use of material on the Website results in the need for servicing, repair or correction of equipment, software or data, you
				assume all costs thereof.
			</p>
			<p>&nbsp;</p>
			<ol start="11">
				<li><strong>Service Availability</strong></li>
			</ol>
			<p>
				<strong>11.1</strong>There may be occasions whereby due to events outside our control, access to the {{ site.softwareName }} websites is temporary unavailable. In
				this event we will do all that is possible to restore access as soon as possible and we do not accept responsibility for any loss during this eventuality and will
				not be liable for any actions by you that result in an infringement claim.
			</p>
			<p>&nbsp;</p>
			<ol start="12">
				<li><strong>Links to and from other websites</strong></li>
			</ol>
			<p>
				<strong>12.1.</strong>&nbsp;Links to third party websites on the Website are provided solely for your convenience. If you use these links, you leave the Website.
				The Company is not able to keep under constant review all of these third party websites, does not control and is not responsible for these websites or their content
				or availability. The Company therefore does not endorse or make any representations about them, or any material found there, or any results that may be obtained
				from using them. If you decide to access any of the third party websites linked to the Website, You do so entirely at your own risk.
			</p>
			<p>
				<strong>12.2.</strong>&nbsp;If you would like to create a link to the Website, You may only do so on the basis that you link to, but do not replicate, the home page
				of the Website, and subject to the following conditions:
			</p>
			<ul>
				<li>You do not remove, distort or otherwise alter the size or appearance of the Company logo;</li>
				<li>You do not create a frame or any other browser or border environment around the Website;</li>
				<li>You do not in any way imply that the Company is endorsing any products or services other than its own;</li>
				<li>You do not misrepresent Your relationship with the Company nor present any other false information about the Company;</li>
				<li>You do not otherwise use any Company trademarks displayed on the Website without express written permission from the Company;</li>
				<li>You do not link from a website that is not owned by You; and</li>
				<li>
					Your website does not contain content that is distasteful, offensive or controversial, infringes any intellectual property rights or other rights of any other
					person or otherwise does not comply with all applicable laws and regulations.
				</li>
			</ul>
			<p>
				<strong>12.3.</strong>&nbsp;The Company expressly reserves the right to revoke the right granted in clause 12.2 for breach of these terms and to take any action it
				deems appropriate.
			</p>
			<p>
				<strong>12.4.</strong>&nbsp;You shall fully indemnify the Company for any loss or damage suffered by the Company or any of its group companies for breach of clause
				12.2.
			</p>
			<p>&nbsp;</p>
			<ol start="13">
				<li><strong>Copyright</strong></li>
			</ol>
			<p>The {{ site.softwareName }} websites content is the copyright of {{ site.company.name }}. All rights not expressly granted by this Agreement are reserved.</p>
			<p>&nbsp;</p>
			<ol start="14">
				<li><strong>General</strong></li>
			</ol>
			<p>
				<strong>14.1.</strong>&nbsp;These terms and conditions are governed by and interpreted in accordance with English law. Disputes arising out of or in connection with
				these terms and conditions shall be subject to the non-exclusive jurisdiction of England and Wales.
			</p>
			<p>
				<strong>14.2.</strong>&nbsp;If any provision of this Agreement is held to be unlawful, invalid or unenforceable, that provision shall be deemed deleted from this
				Agreement and the validity and enforceability of the remaining provisions of this Agreement shall not be affected.
			</p>
			<p>
				<strong>14.3.</strong>&nbsp;This Agreement, together with Our Privacy Policy, constitute the entire agreement between the Customer and the Company relating to the
				Customer's access to and use of {{ site.softwareName }} and supersede any prior agreements (including any previous terms of use of {{ site.softwareName }}).
			</p>
			<p>
				<strong>14.4.</strong>&nbsp;No failure or delay by us in exercising any right under this Agreement will operate as a waiver of that right nor will any single or
				partial exercise by us of any right preclude any further exercise of any right.
			</p>
			<p>&nbsp;</p>
			<ol start="15">
				<li><strong>Complaints</strong></li>
			</ol>
			<p>
				If you wish to make a complaint you can do so in writing by either email&nbsp;<u>({{ site.contact.email }})&nbsp;</u>or by post ({{ site.company.name }},
				{{ site.contact.address }}). We will acknowledge your complaint within seven (7) business days and inform You of Our intended course of action including timescales.
			</p>
			<p>&nbsp;</p>
			<ol start="16">
				<li>
					<strong>{{ site.softwareName }} Contact Details</strong>
				</li>
			</ol>
			<p><strong>Trading Address</strong>: {{ site.contact.address }}</p>
			<p><strong>Telephone</strong>: {{ site.contact.number }}</p>
			<p><strong>Email:</strong> {{ site.contact.email }}</p>
			<p><strong>Company registration number: </strong>{{ site.company.number }}</p>
			<p>&nbsp;</p>
			<p><strong>Annex 1 - Processing Information</strong></p>
			<p><strong>(a) Scope and purpose of processing:</strong></p>
			<p>We may process Customer Personal Data</p>
			<p>- During the provision of the Services;</p>
			<p>- in the course of providing support services to the Customer relating to {{ site.softwareName }}; - in accordance with these Terms.</p>
			<p><strong>(b) Nature of processing:</strong></p>
			<p>The nature of the processing activity may include:-</p>
			<p>
				- receiving, uploading, downloading, extracting, copying, duplicating, transmitting, organising, referencing, indexing, classifying, compressing, compiling,
				updating, transferring, transforming, analysing, modelling, changing, maintaining, protecting or securing, preserving, storing, backing-up or archiving, restoring,
				retrieving and accessing Customer Personal Data in order to perform any of the services set out in paragraph (a) above (or any other services which We may be
				instructed to provide in accordance with clause 6.3);
			</p>
			<p>- destroying, erasing and/or anonymising Customer Personal Data;</p>
			<p>
				- processing Customer Personal Data in order to comply with the our obligations under the Data Privacy Legislation, or to assist the Customer in complying with its
				obligations, including responding to any requests made by Data Subjects;
			</p>
			<p>
				- Transferring to or receiving Customer Personal Data from Third Party Product Providers including Third Party Product Providers - this may include transferring the
				Customer Personal Data to a Third Party Product Provider located outside of the United Kingdom depending on the location of that Third Party Product Provider and
				its processing activity.
			</p>
			<p><strong>(c) Duration of Processing</strong></p>
			<p>
				We will process Customer Personal Data for the duration of the provision of the Services. Upon termination of the Services, and subject to clause 6.16, Customer
				Personal Data will be retained in accordance with the principles set out in the {{ site.softwareName }}&rsquo; privacy and data retention policies (each as amended
				from time to time).
			</p>
			<p><strong>(d) Types of Personal Data</strong></p>
			<p>We may process Customer Personal Data which falls into the following categories:</p>
			<ul>
				<li>
					Personal details which may include (without limitation): names, addresses, date of birth, sex, National Insurance number, passport number, tax identification
					numbers, telephone and mobile numbers and email addresses
				</li>
				<li>family details</li>
				<li>lifestyle and social circumstances</li>
				<li>goods and services</li>
				<li>employment, education details</li>
				<li>income and financial details which may include (without limitation):</li>
				<li>Bank account details, investment details, insurance details, documentation of the above and notes of meetings.</li>
				<li>physical or mental health details which may include (without limitation):</li>
				<li>Smoker status and health records</li>
				<li>nationality</li>
				<li>photographs</li>
				<li>IP addresses</li>
				<li>Any other information which is uploaded into the Services pursuant to this Agreement.</li>
			</ul>
			<p>&nbsp;</p>
			<p><strong>Categories of data subject</strong></p>
			<p>Authorised Users who make available their personal information in order to become registered as a user of {{ site.softwareName }}.</p>
			<p>Clients or potential clients (including, where relevant, their relatives, dependents, guardians and associates) of the Customer and Authorised Users</p>
		</div>

		<v-container v-else class="mx-auto" max-width="500">
			<v-skeleton-loader type="list-item-three-line@3" />
		</v-container>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-terms-business-owner',

		data: () => ({
			website_name: `www.${window.location.hostname}`
		}),

		computed: { ...mapState('CmsSite', ['site']) }
	};
</script>
