import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Notification
 * @exports Service/NotificationUser
 * @description API Notification Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class NotificationUser {
	/**
	 * @static @name modify
	 * @description Update some notification details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	static patch(id, payload) {
		return $http.patch(`notification/user/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Delete notification details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`notification/user/${id}`);
	}

	/**
	 * @static @name list
	 * @description Get all notifications
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('notification/user/list', payload);
	}
}
