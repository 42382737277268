import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Configuration
 * @exports Service/Cms/CpdTrainingItem
 * @description API Configuration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Menu {
	/**
	 * @static @name get
	 * @description Get training items
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`cms/cpd-training-item/list`, payload);
	}

    /**
	 * @static @name post
	 * @description Add training items
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`cms/cpd-training-item`, payload);
	}

	/**
	 * @static @name patch
	 * @description Update training items
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`cms/cpd-training-item/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Selete training items
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`cms/cpd-training-item/${id}`);
	}
}
