<!--
	@name app-enquiry-list
	@description Enquiry list
	@date 2020/06/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters>
			<v-col>
				<v-pagination
					v-model="options.page"
					class="mx-n2"
					color="info"
					:disabled="loading"
					:length="pageCount"
					:total-visible="8"
					@input="paginationUpdated"
				/>
			</v-col>
			<v-col class="shrink align-self-center">
				<validation-observer ref="observer">
					<validation-provider v-slot="{ errors }" name="Search" rules="search">
						<v-text-field
							v-model="searchTerm"
							placeholder="Search: e.g. Luis Carruthers"
							append-icon="mdi-magnify"
							dense
							solo
							:error-messages="errors"
							style="width: 20rem; max-width: 20rem; margin-top: 3px"
						/>
					</validation-provider>
				</validation-observer>
			</v-col>
		</v-row>
		<common-structure-section :body-class="''">
			<template #body>
				<v-data-table
					class="table--styled elevation-3"
					:footer-props="footerProps"
					:headers="headers"
					:items="items"
					:search="searchTerm"
					:server-items-length="total"
					:loading="loading"
					:options.sync="options"
					style="margin-top: -3px"
					loading-text="Loading... Please wait"
					no-data-text="No matching enquiries found"
					no-results-text="No matching enquiries found"
					@update:options="optionsUpdated($event)"
				>
					<template #loading>
						<span class="text-body-2 py-3"> Loading... Please wait </span>
					</template>
					<template #item="{ item }">
						<tr class="tbl-row">
							<td class="shrunk px-10">
								<v-avatar size="44px" :color="getStatus(item).color">
									<v-icon size="28" color="white" v-html="isClosed(item) ? 'mdi-folder' : 'mdi-folder-open'" />
								</v-avatar>
							</td>
							<td class="nowrap">
								<p class="text-body-1 font-weight-bold mb-0">
									{{ item.matterReference }}
								</p>
							</td>
							<td class="nowrap">
								<p class="text-body-1 font-weight-bold mb-0">
									{{ getClientName(item) }}
								</p>
							</td>
							<td>
								{{ getPropertyText(item) }}
							</td>
							<td class="nowrap">
								{{ item.numberOfResponse }} Response{{ item.numberOfResponse === 1 ? '' : 's' }}
							</td>
							<td class="nowrap">
								{{ item.lastResponse.at | moment('Do MMM YYYY, HH:mm') }}
							</td>
							<td class="nowrap">
								<v-chip light label small :color="getStatus(item).color">
									{{ getStatus(item).text }}
								</v-chip>
							</td>
							<td class="shrunk nowrap text-right">
								<div class="d-flex flex-no-wrap justify-end">
									<v-badge color="red accent-3" :content="item.numberOfNewResponse" overlap left :value="item.numberOfNewResponse">
										<v-btn class="white--text" small color="secondary" :to="`/enquiry/${item.id}`" depressed>
											View Enquiry
										</v-btn>
									</v-badge>

									<v-tooltip v-if="isClosed(item)" bottom>
										<template #activator="{ on }">
											<v-btn color="warning" class="ml-2 mr-2" small dark text :loading="statusLoading[item.id]" v-on="on" @click="openEnquiry(item)">
												<v-icon>mdi-lock-open</v-icon>
											</v-btn>
										</template>
										<span>Open Enquiry</span>
									</v-tooltip>

									<v-tooltip v-else bottom>
										<template #activator="{ on }">
											<v-btn color="success" class="ml-2 mr-2" small dark text :loading="statusLoading[item.id]" v-on="on" @click="closeEnquiry(item)">
												<v-icon>mdi-lock-check</v-icon>
											</v-btn>
										</template>
										<span>Close Enquiry</span>
									</v-tooltip>

									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-btn color="error" dark small text :loading="deleteLoading[item.id]" v-on="on" @click="deleteEnquiry(item)">
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
										</template>
										<span>Remove</span>
									</v-tooltip>
								</div>
							</td>
						</tr>
					</template>

					<template #no-results>
						No results found
					</template>
				</v-data-table>
			</template>
		</common-structure-section>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-enquiry-list',

		components: {
			CommonStructureSection,
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data() {
			return {
				deleteLoading: {},
				statusLoading: {},
				items: [],
				searchTerm: '',
				pageCount: 0,
				total: 0,
				options: {
					page: 1,
					itemsPerPage: 10,
					sortBy: ['updated'],
					sortDesc: ['desc']
				},
				headers: [
					{
						text: '',
						value: '',
						sortable: false
					},
					{
						text: 'Reference',
						value: 'matterReference'
					},
					{
						text: 'Client',
						value: 'matterReference',
						sortable: false
					},
					{
						text: 'Property',
						value: 'matterReference',
						sortable: false
					},
					{
						text: 'Responses',
						value: 'numberOfResponse',
						sortable: false
					},
					{
						text: 'Last response',
						value: 'lastResponse.at',
						sortable: false
					},
					{
						text: 'Status',
						value: 'lastResponse.at',
						sortable: false
					},
					{
						text: '',
						value: 'actions',
						sortable: false
					}
				],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				loading: false
			};
		},

		watch: {
			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;

				if (!this.searchTerm.lengh) {
					this.options.page = 1;
				}
				this.debouncedGetEnquiryList();
			}
		},

		methods: {
			...mapActions('AppMatter', ['modifyMatter', 'deleteMatter']),
			...mapActions('Enquiry', ['fetchEnquiryList']),

			/**
			 * @name fetchEnquiryList
			 * @description fetch enquiries
			 */
			 async getEnquiryList() {
				this.loading = true;
				this.items = [];
				const { page, itemsPerPage, sortDesc, sortBy } = this.options;
				const payload = {
					limit: itemsPerPage,
					offset: (page - 1) * itemsPerPage,
					order: { property: 'updated', direction: 'DESC' },
					search: this.searchTerm ? `%${this.searchTerm.trim()}%` : undefined,
				};
				if (sortBy.length) {
					payload.order = [];
					sortBy.forEach((property, index) => payload.order.push({ property, direction: sortDesc[index] ? 'DESC' : 'ASC' }));
				}
				this.fetchEnquiryList(payload).then((response) => {
					this.pageCount = Math.ceil(response.data.total / itemsPerPage);
					this.items = response.data.data;
					this.total = response.data.total;
					this.loading = false;
				});
			},

			/**
			 * @name getClientName
			 * @description get client full name
			 * @param {Object} enquiry
			 */
			getClientName(enquiry) {
				let clientName = '';
				enquiry.client.map((client, index) => {
					clientName += `${index > 0 ? ' and ' : ''}${client.nameTitle} ${client.nameGiven} ${client.nameFamily}`;
				});
				return clientName;
			},

			/**
			 * @name getPropertyText
			 * @description get property full address
			 * @param {Object} enquiry
			 */
			getPropertyText(enquiry) {
				const { location: property } = enquiry.asset;
				return `${property.address1},${property.address2 ? ` ${property.address2},` : ''} ${property.townCity}, ${property.postcode}`;
			},

			/**
			 * @name isClosed
			 * @description check if the enquiry is closed
			 * @param {Object} enquiry
			 */
			isClosed(enquiry) {
				return enquiry.data?.closed;
			},

			/**
			 * @name openEnquiry
			 * @description Open enquiry
			 * @param {Object} enquiry
			 */
			openEnquiry(enquiry) {
				this.$refs.confirm
					.open('Open Enquiry', 'Are you sure you wish to open this enquiry?')
					.then(() => {
						this.$set(this.statusLoading, enquiry.id.toString(), true);
						const payload = { data: { closed: null } };
						const data = {
							id: enquiry.id,
							payload
						};

						this.modifyMatter(data)
							.then(() => {
								enquiry = { ...enquiry, ...payload };
								const index = this.items.findIndex((item) => item.id === enquiry.id);
								this.$set(this.items, index, enquiry);
								ElementTools.fireNotification(this.$el, 'success', 'Enquiry successfully opened');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
							.finally(() => this.$set(this.statusLoading, enquiry.id.toString(), false));
					})
					.catch(() => {});
			},

			/**
			 * @name closeEnquiry
			 * @description Close enquiry
			 * @param {Object} enquiry
			 */
			closeEnquiry(enquiry) {
				this.$refs.confirm
					.open('Close Enquiry', 'Are you sure you wish to close this enquiry?')
					.then(() => {
						this.$set(this.statusLoading, enquiry.id.toString(), true);
						const payload = { data: { closed: new Date() } };
						const data = {
							id: enquiry.id,
							payload
						};

						this.modifyMatter(data)
							.then(() => {
								enquiry = { ...enquiry, ...payload };
								const index = this.items.findIndex((item) => item.id === enquiry.id);
								this.$set(this.items, index, enquiry);
								ElementTools.fireNotification(this.$el, 'success', 'Enquiry successfully closed');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
							.finally(() => this.$set(this.statusLoading, enquiry.id.toString(), false));
					})
					.catch(() => {});
			},

			/**
			 * @name deleteEnquiry
			 * @description Delete enquiry
			 * @param {Object} enquiry
			 */
			deleteEnquiry(enquiry) {
				this.$refs.confirm
					.open('Delete Enquiry', 'Are you sure you wish to delete this enquiry?')
					.then(() => {
						this.$set(this.deleteLoading, enquiry.id.toString(), true);
						const payload = { data: { closed: new Date() } };
						const data = {
							id: enquiry.id,
							payload
						};
						this.modifyMatter(data)
							.then(() => {
								this.deleteMatter(enquiry.id)
								.then(() => {
									ElementTools.fireNotification(this.$el, 'success', 'Enquiry successfully deleted');
									this.items = this.items.filter((item) => item.id !== enquiry.id);
								})
								.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
								.finally(() => this.$set(this.deleteLoading, enquiry.id.toString(), false));
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'));
					})
					.catch(() => {});
			},

			/**
			 * @name optionsUpdated
			 * @description event handler for options updated
			 * @param {Object} options
			 */
			 optionsUpdated(options) {
				this.options = options || this.options;
				this.getEnquiryList();
			},

			/**
			 * @name paginationUpdated
			 * @description event handler for pagination updated
			 */
			paginationUpdated() {
				this.optionsUpdated();
			},

			/**
			 * @name debouncedGetEnquiryList
			 * @description Load clients with debounce
			 */
			 debouncedGetEnquiryList: debounce(function() {
				this.getEnquiryList();
			}, 500),

			/**
			 * @name getStatus
			 * @description Get status object
			 */
			 getStatus(enquiry) {
				let opacity = 0.25;
				const isClosed = this.isClosed(enquiry);
				let text = isClosed ? 'Resolved' : 'Open', color;

				switch (text) {
					case 'Resolved':
						color = `rgba(23, 184, 144, ${opacity})`;
						break;
					default:
						color = `rgba(247, 203, 115, ${opacity})`;
				}

				return {
					text,
					color
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-pagination {
		justify-content: start !important;
	}

	.opacity {
		opacity: 0.6;
		transition: 0.3s;
		&:hover {
			opacity: 1;
		}
	}

	::v-deep .v-data-table-header th .v-data-table-header__icon {
		color: #fff !important;
	}
</style>