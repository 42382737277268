<!--
	@name app-enquiry-details-documents-dialog
	@description Documents dialog
	@date 2020/07/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable :component="$options.name">
		<v-card tile>
			<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
				<v-row>
					<v-col cols="12" md="2" class="d-flex pa-0">
						<v-toolbar-title class="align-self-center">
							DOCUMENTS
						</v-toolbar-title>
					</v-col>
					<v-spacer />
					<v-col cols="12" md="8" class="d-flex pa-0" style="max-width: 800px">
						<v-text-field
							v-model="searchTerm"
							placeholder="Search: e.g. NOC List"
							class="search-field align-self-center"
							append-icon="mdi-magnify"
							hide-details
							dense
							solo
							light
						/>
					</v-col>
					<v-spacer />
					<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
						<v-btn icon dark class="align-self-center" @click="active = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-toolbar>
			<v-card-text>
				<v-container class="px-0" style="max-width: 800px">
					<div v-for="(group, date) in files" :key="date" class="mb-8">
						<p class="mb-3 text-body-2 font-weight-bold black-text">
							{{ date }}
						</p>
						<v-list two-line class="pa-0">
							<v-list-item v-for="(item, index) in group" :key="index" three-line class="elevation-3 ma-0 pa-0" :class="[{ 'mt-4': index !== 0 }]">
								<v-list-item-avatar class="pa-5 px-8 ma-0 align-self-start" height="auto" width="auto">
									<v-icon size="48" color="primary">
										mdi-file-download
									</v-icon>
								</v-list-item-avatar>

								<v-list-item-content class="pa-5 pl-0 align-self-start">
									<v-list-item-title class="text-subtitle-1 font-weight-bold ma-0 mb-2">
										{{ item.filename }}
									</v-list-item-title>

									<v-list-item-subtitle class="text-body-2 ma-0">
										<strong> Uploaded by:</strong> {{ item.author }}
									</v-list-item-subtitle>

									<v-list-item-subtitle v-if="item.expired" class="text-body-2 ma-0 error--text">
										Document expired
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action class="pa-5 ma-0">
									<v-btn icon color="primary" class="mb-1" :loading="fileLoading[item.s3Key]" :disabled="item.expired" @click="downloadFile(item)">
										<v-icon large>
											mdi-download
										</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</div>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import { groupBy } from 'lodash';

	export default {
		name: 'app-enquiry-details-documents-dialog',

		props: {
			responses: { type: Array, required: true },
			organisations: { type: Array, default: () => [] }
		},

		data() {
			return {
				active: false,
				searchTerm: '',
				fileLoading: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('CmsSite', ['site']),

			documents() {
				let documents = [];

				this.responses.map((response) => (response.files || []).map((file) => documents.push({ file, response })));

				documents = documents.filter(
					(document) =>
						document.file.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						document.response.author.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						this.$moment(String(document.response.created)).format('DD MMMM YYYY').toLowerCase().includes(this.searchTerm.toLowerCase())
				);

				documents = groupBy(documents, (o) => this.$moment(String(o.response.created)).format('DD MMMM YYYY'));

				return documents;
			},

			files() {
				let files = [];

				this.responses.map((response) =>
					(response.data?.files || []).map((file) => {
						let author = '';
						if (response.data?.responder?.type === 'organisation') author = this.organisations?.find((o) => o.id === response.data?.responder?.id)?.name;
						else if (response.data.responder?.type === 'system') author = `${this.site.softwareName} Support Team`;
						else author = this.user.name;

						files.push({ ...file, created: response.created, author, expired: this.$moment(response.created).isBefore(this.$moment().subtract(10, 'days')) });
					})
				);

				files = files.filter(
					(document) =>
						document.filename.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						document.author.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						this.$moment(String(document.created)).format('DD MMMM YYYY').toLowerCase().includes(this.searchTerm.toLowerCase())
				);

				files = groupBy(files, (o) => this.$moment(String(o.created)).format('DD MMMM YYYY'));

				return files;
			}
		},

		methods: {
			...mapActions('File', ['getFile']),

			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open() {
				this.active = true;
			},

			async downloadFile(file) {
				this.$set(this.fileLoading, file.s3Key, true);

				this.getFile(file)
					.then((response) => {
						if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, file.s3Key, false));
			}
		}
	};
</script>
