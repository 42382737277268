import Home from '@/component/app/air-later-life';
import Ambassadors from '@/component/app/air-later-life/ambassadors';
import Marketing from '@/component/app/air-later-life/marketing';
import Sourcing from '@/component/app/air-later-life/sourcing';
import Academy from '@/component/app/air-later-life/academy';
import Club from '@/component/app/air-later-life/club';
import Awards from '@/component/app/air-later-life/awards/awards-2023';
import Winners from '@/component/app/air-later-life/awards/awards-2022';
import Events from '@/component/app/air-later-life/events';
import Tools from '@/component/app/air-later-life/tools';
import Conference from '@/component/app/air-later-life/conference';
import Contact from '@/component/app/air-later-life/contact';
import ComprehensiveConversations from '@/component/app/air-later-life/comprehensive-conversations';
import ComprehensiveConversationsManifesto from '@/component/app/air-later-life/comprehensive-conversations/manifesto';
import InsightsHub from '@/component/app/air-later-life/insights-hub';
import InsightsHubContentList from '@/component/app/air-later-life/insights-hub/content-list';
import InsightsHubContentDetail from '@/component/app/air-later-life/insights-hub/content-detail';
import InsightsHubContentCategory from '@/component/app/air-later-life/insights-hub/content-category';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for Air Later Life
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'airLaterLife.home',
		path: '/air-later-life',
		meta: { public: true, layout: 'air-later-life' },
		component: Home
	},
	{
		name: 'airLaterLife.ambassadors',
		path: '/air-later-life/ambassadors',
		meta: { public: true, layout: 'air-later-life' },
		component: Ambassadors
	},
	{
		name: 'airLaterLife.marketing',
		path: '/air-later-life/marketing',
		meta: { public: true, layout: 'air-later-life' },
		component: Marketing
	},
	{
		name: 'airLaterLife.sourcing',
		path: '/air-later-life/sourcing',
		meta: { public: true, layout: 'air-later-life' },
		component: Sourcing
	},
	{
		name: 'airLaterLife.academy',
		path: '/air-later-life/academy',
		meta: { public: true, layout: 'air-later-life' },
		component: Academy
	},
	{
		name: 'airLaterLife.club',
		path: '/air-later-life/club',
		meta: { public: true, layout: 'air-later-life' },
		component: Club
	},
	{
		name: 'airLaterLife.awards',
		path: '/air-later-life/awards',
		meta: { public: true, layout: 'air-later-life' },
		component: Awards
	},
	{
		name: 'airLaterLife.awards.2022-winners',
		path: '/air-later-life/2022-winners',
		meta: { public: true, layout: 'air-later-life' },
		component: Winners
	},
	{
		name: 'airLaterLife.events',
		path: '/air-later-life/events',
		meta: { public: true, layout: 'air-later-life' },
		component: Events
	},
	{
		name: 'airLaterLife.tools',
		path: '/air-later-life/tools',
		meta: { public: true, layout: 'air-later-life' },
		component: Tools
	},
	{
		name: 'airLaterLife.conference',
		path: '/air-later-life/conference',
		meta: { public: true, layout: 'air-later-life' },
		component: Conference
	},
	{
		name: 'airLaterLife.contact',
		path: '/air-later-life/contact',
		meta: { public: true, layout: 'air-later-life' },
		component: Contact
	},
	{
		name: 'airLaterLife.comprehensiveConversations',
		path: '/air-later-life/comprehensive-conversations',
		meta: { public: true, layout: 'air-later-life' },
		component: ComprehensiveConversations
	},
	{
		name: 'airLaterLife.comprehensiveConversations.manifesto',
		path: '/air-later-life/comprehensive-conversations/manifesto',
		meta: { public: true, layout: 'air-later-life' },
		component: ComprehensiveConversationsManifesto
	},
	{
		name: 'airLaterLife.insightsHub',
		path: '/air-later-life/insights-hub',
		meta: { public: true, layout: 'air-later-life' },
		component: InsightsHub
	},
	{
		name: 'airLaterLife.insightsHub.contentList',
		path: '/air-later-life/insights-hub/content-list',
		meta: { public: true, layout: 'air-later-life' },
		component: InsightsHubContentList
	},
	{
		name: 'airLaterLife.insightsHub.contentDetail',
		path: '/air-later-life/insights-hub/content-detail',
		meta: { public: true, layout: 'air-later-life' },
		component: InsightsHubContentDetail
	},
	{
		name: 'airLaterLife.insightsHub.contentCategory',
		path: '/air-later-life/insights-hub/content-category',
		meta: { public: true, layout: 'air-later-life' },
		component: InsightsHubContentCategory
	}
];
