<!--
	@name app-layout-account-notification-centre
	@description dialog to show notifications
	@date 2020/10/15
	@license no license
	@copywrite Answers In Retirement Limited
-->
<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="800">
		<template #header>
			Notifications
		</template>

		<template #body>
			<v-sheet class="ma-n5">
				<p v-if="!notificationList">
					<v-skeleton-loader type="list-item-three-line" />
				</p>
				<p v-else-if="!notificationList.length" class="text-body-1 pa-4 ma-0">
					You have 0 notifications
				</p>
				<v-timeline v-else dense align-top class="small pa-3 pl-0 pr-6">
					<v-timeline-item v-for="(item, index) in notificationList" :key="index" small class="py-3" :class="{ read: item.read }">
						<template #icon>
							<img :src="item.avatar | urlize(item.avatar?.startsWith('organisation') ? 'logo' : 'notifications')" width="50px" class="mt-5" />
						</template>

						<v-card class="elevation-2" :color="!item.read ? 'warning' : 'blue-grey lighten-4'" :dark="!item.read">
							<v-card-title class="body-2 font-weight-medium py-3 ma-0">
								{{ item.title }}
								<v-spacer />
								<span class="body-2">{{ item.created | moment('from', 'now') }}</span>
							</v-card-title>
							<v-card-text class="pa-4 body-1 white text--primary">
								<div class="d-flex justify-space-between">
									<div class="flex-grow-1 white-space--break-spaces" @click="handleDynamicClick" v-html="$sanitize(item.message)" />
									<div v-if="!item.read" class="flex-grow-0 flex-shrink-1 pl-5">
										<v-tooltip bottom>
											<template #activator="{ on }">
												<v-checkbox light class="mt-0 pt-0" hide-details color="primary" @change="markAsRead(item)" v-on="on" />
											</template>
											<span>Mark as read</span>
										</v-tooltip>
									</div>
									<div class="flex-grow-0 flex-shrink-1">
										<v-tooltip bottom>
											<template #activator="{ on }">
												<v-icon color="error" style="cursor: pointer" @click="deleteNotification(item.id)" v-on="on">
													mdi-trash-can
												</v-icon>
											</template>
											<span>Permanently delete this message?</span>
										</v-tooltip>
									</div>
								</div>
							</v-card-text>
							<v-card-actions v-if="item.actionText" class="pa-4 pt-0 white text--primary">
								<v-btn small color="primary" class="notification-cta" :class="item.id" @click="handleButtonClick(item)">
									{{ item.actionText }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-timeline-item>
				</v-timeline>
			</v-sheet>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-layout-account-notification-centre',

		components: {
			CommonDialog
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('AppNotification', ['notificationList'])
		},

		mounted() {
			EventBus.$on('lllNavigatorRegisterInterest', this.lllNavigatorRegisterInterest);
		},

		beforeDestroy() {
			EventBus.$off('lllNavigatorRegisterInterest', this.lllNavigatorRegisterInterest);
		},

		methods: {
			...mapActions('SystemRegisterNavigatorInterest', ['registerInterest']),
			...mapActions('AppNotification', ['markAsRead', 'deleteNotification']),

			handleButtonClick(notification) {
				if (notification.actionUrl) {
					if (notification.actionUrl.includes('http')) window.open(notification.actionUrl, '_blank');
					else this.$router.push(notification.actionUrl);
					this.close();
				} else if (notification.actionEmit) {
					if (notification.actionEmit === 'submitMarkAsReadAndClose') this.markAsRead(notification).then(() => this.close());
					else EventBus.$emit(notification.actionEmit);
					return null;
				}
			},

			/**
			 * @name handleDynamicClick
			 * @description Handle clicks on dynamically created alert boxes
			 * @param {Object} e
			 */
			handleDynamicClick(e) {
				EventBus.$emit('handle-dynamic-click', e);
			},

			/**
			 * @name open
			 * @description Opens the dialog
			 */
			open() {
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description Closes the dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

			async lllNavigatorRegisterInterest() {
				const payload = (({ name, email, id }) => ({ name, email, id }))(this.user);
				this.registerInterest(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', {
							text: 'Thank you for registering your interest. Our team have been notified and will be in touch shortly.',
							timeout: 0,
							close: true
						});
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An unexpected error occurred'));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-timeline-item.read {
		opacity: 0.5;
		transition: 0.3s;
		&:hover {
			opacity: 1;
		}
	}

	::v-deep .v-timeline-item__dot--small {
		height: 0px !important;
		width: 0px !important;
		padding-top: 24px !important;

		.v-timeline-item__inner-dot {
			height: 0px !important;
			width: 0px !important;
			margin: 0 !important;
		}
	}
</style>
