import { AccountFirmJoinRequest } from '@/service';

/**
 * @class @name FirmJoinRequest
 * @description Exposes menu store module
 */
export default class FirmJoinRequest {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadFirmJoinRequestPendingList
			 * @description used to fetch app registration
			 * @param {function} commit
			 */
			async loadFirmJoinRequestList() {
				return AccountFirmJoinRequest.list();
			},

			/**
			 * @async @name loadFirmJoinRequestPendingList
			 * @description used to fetch app registration
			 * @param {function} commit
			 */
			async acceptFirmJoinRequest(context, payload) {
				return AccountFirmJoinRequest.acceptFirmJoinRequest(payload);
			},

			/**
			 * @async @name deleteFirmJoinRequest
			 * @param {function} commit
			 */
			async deleteFirmJoinRequest(context, id) {
				return AccountFirmJoinRequest.delete(id);
			}
		};
	}
}
