<!--
	@name app-client-dashboard-workflow-template
	@description Client Workflow Template
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-sheet :component="$options.name">
		<v-row>
			<v-col cols="12" sm="6" class="text-left">
				<a class="text-body-2" @click="$emit('close-workflow-item')">
					<v-icon class="d-inline-block mr-1" color="primary" size="16" style="position: relative; top: -1px;">
						mdi-close
					</v-icon>
					<span class="d-inline-block">
						<span class="font-weight-bold">Back to Client Dashboard</span>
					</span>
				</a>
			</v-col>
			<v-col v-if="workflowItem.prev || workflowItem.next" cols="12" sm="6" class="text-right">
				<a v-if="workflowItem.prev" class="text-body-2 d-inline-block" @click="handleRoute(workflowItem.prev)">
					<v-icon class="d-inline-block mr-1" color="primary" size="16" style="position: relative; top: -1px;">
						mdi-chevron-double-left
					</v-icon>
					<span class="d-inline-block">
						<span class="font-weight-bold">Prev:</span> {{ workflowItem.prev.name }}
					</span>
				</a>
				<span v-if="workflowItem.prev && workflowItem.next" class="d-inline-block px-3">
					|
				</span>
				<a v-if="workflowItem.next" class="text-body-2 d-inline-block" @click="handleRoute(workflowItem.next)">
					<span class="d-inline-block">
						<span class="font-weight-bold">Next:</span> {{ workflowItem.next.name }}
					</span>
					<v-icon class="d-inline-block ml-1" color="primary" size="16" style="position: relative; top: -1px;">
						mdi-chevron-double-right
					</v-icon>
				</a>
			</v-col>
		</v-row>
		<v-row class="mb-3">
			<v-col class="align-content-center">
				<h2 class="text-h5 font-weight-bold mb">
					{{ workflowItem.name }}
					<v-chip
						label
						x-small
						dark
						:color="getStatusColor(workflowItem.statuses?.[0]?.status)"
						style="position: relative; left: 8px; top: -4px"
						@click="$emit('update-status', { item: workflowItem, status: completed ? 'Pending' : 'Completed' })"
					>
						{{ workflowItem.statuses?.[0]?.status || 'Pending' }}
					</v-chip>
				</h2>
			</v-col>
			<v-col class="shrink">
				<v-sheet width="240" inline :border-color="statusColor">
					<v-select
						v-model="selectedItem"
						:items="statuses"
						label="Status"
						outlined
						hide-details
						dense
						:autofocus="autofocus"
						:item-color="statusColor"
						:color="statusColor"
						@change="$emit('update-status', { item: workflowItem, status: $event })"
					/>
				</v-sheet>
			</v-col>
		</v-row>
		<p class="text-body-1">
			{{ workflowItem.copy }}
		</p>

		<v-divider inset class="my-10" />

		<slot name="activity" />
		<slot name="resources" />
	</v-sheet>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'app-client-dashboard-workflow-template',

		props: {
			workflowItem: { type: Object, required: true }
		},

		data() {
			return {
				selectedItem: null, // Initially selected item
				autofocus: true,
				statusColor: null,
				statuses: []
			};
		},

		computed: {
			...mapGetters('AppWorkflow', ['getStatusColor'])
		},

		watch: {
			workflowItem: {
				handler() {
					if (this.workflowItem) this.updateStatus();
				}
			}
		},

		created() {
			this.setStatusOptions();
			this.updateStatus();
		},

		methods: {
			setStatusOptions() {
				switch (this.workflowItem.name) {
					case 'Key Facts Illustration':
						this.statuses = ['Pending', 'In Progress', 'Completed', 'Failed'];
						break;
					case 'Conveyancing':
					case 'Will':
					case 'Power of Attorney':
						this.statuses = ['Pending', 'Submitted', 'Declined', 'Accepted', 'In Progress', 'Suspended', 'Completed', 'Failed'];
						break;
					default:
						this.statuses = ['Pending', 'In Progress', 'Completed'];
						break;
				}
			},

			updateStatus() {
				const status = this.workflowItem.statuses?.[0]?.status;
				this.selectedItem = status || 'Pending';
				this.statusColor = this.getStatusColor(status);
			},

			handleRoute(item) {
				this.$router.push({ query: Object.assign({}, this.$route.query, { workflowSection: item.id }) });
				// Prevent double scrollbar https://github.com/vuetifyjs/vuetify/issues/5356
				this.$nextTick().then(() => document.documentElement.classList.add('overflow-y-hidden'));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
