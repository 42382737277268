import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Country
 * @exports Service/Option/Country
 * @description API Option Country Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Country {
	/**
	 * @static @name get
	 * @description get country option
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`option/country/${id}`);
	}
}
