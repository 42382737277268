<!--
	@name app-firm-dialog-user-account
	@description Show a dialog to view user details
	@date 2023/09/25
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-dialog v-model="active" fullscreen hide-overlay persistent transition="dialog-bottom-transition" scrollable :component="$options.name">
			<v-card tile>
				<v-toolbar flat dark color="info" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								USER
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="close()">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>

				<v-card-text class="pa-6">
					<v-container class="px-0" style="max-width: 1400px;">
						<div v-if="account" class="mb-10">
							<p class="text-h5 mb-0 font-weight-regular">
								User: <span class="font-weight-bold">
									{{ account.name }} / {{ account.email }}
								</span>
							</p>
						</div>

						<v-divider class="mb-10" />

						<p class="text-h6 mb-3">
							Activity over last six months
						</p>
						<p class="text-body-1 mb-6">
							Here, you can examine cumulative figures for advisers sourcing, KFI submissions, and cases that have progressed to applications. These statistics are derived from the activities of the past six months, up to today's date.
						</p>

						<template v-if="!loading">
							<div class="d-flex mx-n3 mt-n3 mb-10">
								<v-card class="flex-grow-1 flex-shrink-0 text-center ma-3 flex-basis-one-third">
									<v-card-title class="black--text text-h6 font-weight-light justify-center py-2 sourcing">
										Sourcing Sessions
									</v-card-title>
									<v-card-text class="pa-6">
										<h2 class="text-h2 ma-0 font-weight-light">
											{{ sourcing.total }}
										</h2>
									</v-card-text>
								</v-card>

								<v-card class="flex-grow-1 flex-shrink-0 text-center ma-3 flex-basis-one-third">
									<v-card-title class="black--text text-h6 font-weight-light justify-center py-2 kfi">
										KFI Submissions
									</v-card-title>
									<v-card-text class="pa-6">
										<h2 class="text-h2 ma-0 font-weight-light">
											{{ kfi.total }}
										</h2>
									</v-card-text>
								</v-card>

								<v-card class="flex-grow-1 flex-shrink-0 flex- text-center ma-3 flex-basis-one-third">
									<v-card-title class="black--text text-h6 font-weight-light justify-center py-2 application">
										Applications
									</v-card-title>
									<v-card-text class="pa-6">
										<h2 class="text-h2 ma-0 font-weight-light">
											{{ application.total }}
										</h2>
									</v-card-text>
								</v-card>
							</div>

							<v-divider class="mb-10" />

							<p class="text-h6">
								WriteRoute
							</p>

							<div class="v-data-table table--styled">
								<v-data-table :headers="headers" :items="factFind.data" :items-per-page="5" class="elevation-1">
									<template #item.progress="{ item }">
										{{ item.progress }}%
									</template>
									<template #item.actions="{ item }">
										<v-btn icon color="success" :disabled="!item.s3Key">
											<v-icon @click="downloadFactFind(item)">
												mdi-download
											</v-icon>
										</v-btn>
									</template>
								</v-data-table>
							</div>
						</template>

						<v-card v-for="key in 2" v-else :key="key" class="flex-grow-1" :class="key !== 1 ? 'mt-10' : ''">
							<v-skeleton-loader type="list-item-three-line" />
						</v-card>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { orderBy } from 'lodash';

	export default {
		name: 'app-client-dialog-user-account',

		components: {},

		props: {},

		data() {
			return {
				active: false,
				loading: true,
				account: null,
				sourcing: null,
				kfi: null,
				application: null,
				factFind: null,
				headers: [
					{ text: 'Reference', align: 'start', value: 'reference', sortable: false },
					{ text: 'Progress', align: 'center', width: '100', value: 'progress', sortable: false },
					{ text: 'Actions', align: 'center', width: '150', value: 'actions', sortable: false }
				]
			};
		},

		computed: {},

		methods: {
			...mapActions('LifetimeMortgageSourcing', ['fetchSourcingList']),
			...mapActions('LifetimeMortgageKfi', ['fetchKfiAdvisorList']),
			...mapActions('LifetimeMortgageApplication', ['fetchApplicationList']),
			...mapActions('FactFind', ['fetchFactFindList', 'loadFactFind']),

			/**
			 * @name open
			 * @description Open the dialog, fetch initial data
			 */
			open(account) {
				this.active = true;
				this.loading = true;
				this.account = account;
				this.sourcing = null;
				this.kfi = null;
				this.application = null;
				this.factFind = null;
				this.loadData();
			},

			/**
			 * @name loadData
			 * @description Load user data
			 */
			async loadData() {
				// Sourcing Sessions, KFI Submissions and Applications data should be from last 6 months
				const start = this.$moment().subtract(6, 'months').format();
				const end = this.$moment().format();

				const payload = { userId: this.account.id };
				const sourcingPayload = { ...payload, where: { 'process.created': { type: 'daterange', condition: 'between', value: [start, end] } } };
				const kfiPayload =  { ...payload, where: { 'iteration.created': { type: 'daterange', condition: 'between', value: [start, end] } }, cumulative: true };
				const applicationPayload = { ...payload, where: { 'process.created': { type: 'daterange', condition: 'between', value: [start, end] } } };

				const [sourcing, kfi, application, factFind] = await Promise.all([
					this.fetchSourcingList(sourcingPayload),
					this.fetchKfiAdvisorList(kfiPayload),
					this.fetchApplicationList(applicationPayload),
					this.fetchFactFindList(payload)
				]);

				this.sourcing = sourcing.data;
				this.kfi = kfi.data;
				this.application = application.data;

				// order fact find by upated
				factFind.data.data = orderBy(factFind.data.data, 'updated', ['desc']);
				this.factFind = factFind.data;
				this.loading = false;
			},

			/**
			 * @name downloadFactFind
			 * @description Download Fact Find payload
			 */
			async downloadFactFind(item) {
				this.loadFactFind(item.id).then((response) => {
					const a = document.createElement('a');
					const file = new Blob([JSON.stringify(response.data, null, 4)], { type: 'application/json' });
					a.href = URL.createObjectURL(file);
					a.download = `${item.reference}.json`;
					a.click();
					a.remove();
				});
			},

			/**
			 * @name close
			 * @description Close the dialog
			 */
			close() {
				this.active = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.sourcing {
		background-color: rgba(27, 120, 216, 0.2);
	}

	.kfi {
		background-color: rgba(30, 176, 225, 0.2);
	}

	.application {
		background-color: rgba(103, 0, 86, 0.2);
	}

</style>
