<!--
	@name app-reward-history
	@description Rewards page history
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<common-structure-section :component="$options.name" body-class="flex-grow-1 pa-8">
			<template #body>
				<div>
					<v-fade-transition>
						<v-card v-if="redeemableVoucher" class="d-flex flex-column mb-6 flex-fill flex-grow-1 flex-shrink-0">
							<v-card-title class="white--text text-body-1 font-weight-medium justify-left py-2 background-rotation-manual--2">
								Reward points redeemable before {{ redeemableVoucher.expiry | moment('D/MM/YY') }}
							</v-card-title>
							<v-card-text class="px-4 py-1 ma-0 d-flex">
								<v-row align="center">
									<v-col class="shrink">
										<p class="text-h5 font-weight-medium success--text text--darken-2 mb-0">
											{{ redeemableVoucher.amount | numFormat('0,0') }}
										</p>
									</v-col>
									<v-col class="grow text-body-1">
										Your Air Rewards points have been re-set and must be redeemed before {{ redeemableVoucher.expiry | moment('dddd, Do MMMM') }}. Click the
										redeem button to find out how you can use your rewards points.
									</v-col>
									<v-col class="shrink">
										<v-btn small color="primary" @click="$emit('redeem', 'voucher', redeemableVoucher.id)">
											Redeem
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-fade-transition>

					<v-card class="d-flex flex-column mb-6 flex-fill flex-grow-1 flex-shrink-0">
						<v-card-title class="white--text text-body-1 font-weight-medium justify-left py-2 background-rotation-manual--1">
							Current reward points balance
						</v-card-title>
						<v-card-text class="px-4 py-1 ma-0 d-flex">
							<v-row align="center">
								<v-col class="shrink">
									<p v-if="!rewardBalance && rewardBalance !== 0" class="text-h5 font-weight-medium blue--text text--darken-4 mb-0">
										<v-progress-circular size="24" indeterminate />
									</p>
									<p v-else class="text-h5 font-weight-medium blue--text text--darken-4 mb-0">
										{{ rewardBalance | numFormat('0,0') }}
									</p>
								</v-col>
								<v-col class="grow text-body-1">
									This is the current balance of your Air Rewards points. This will automatically update when a reward is registered on your account. You will be
									paid £3.00 per 600 points.
								</v-col>
								<v-col class="shrink">
									<v-btn :disabled="rewardBalance <= 0" small color="primary" @click="$emit('redeem', 'redemption')">
										Redeem
									</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>

					<v-card class="flex-grow-1 flex-shrink-0 text-center">
						<v-card-title class="white--text text-body-1 font-weight-medium justify-left py-2 background-rotation-manual--5">
							Last 6 Months
						</v-card-title>
						<v-card-text class="pa-6">
							<v-skeleton-loader v-if="!rewardSparkline.length" type="image" />
							<v-sheet v-else color="purple lighten-5">
								<v-sparkline :value="rewardSparkline" color="rgba(103, 0, 86, 0.95)" line-width="3" height="50" padding="20" stroke-linecap="round" label-size="5" smooth>
									<template #label="item">
										{{ item.value }}
									</template>
								</v-sparkline>
							</v-sheet>
						</v-card-text>
					</v-card>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-reward-summary',

		components: { CommonStructureSection },

		inject: ['$rewardsBalance', '$rewardTransactionList'],

		computed: {
			rewardTransactionList() {
				return this.$rewardTransactionList();
			},

			rewardBalance() {
				return this.$rewardsBalance();
			},

			redeemableVoucher() {
				return this.rewardTransactionList
					? this.rewardTransactionList.find((t) => t.data && t.data.type === 'voucher' && !t.actioned && !this.$moment(t.expiry).isBefore(this.$moment(new Date()))&& !t.deleted)
					: null;
			},

			rewardSparkline() {
				if (!this.rewardTransactionList) return [];

				const now = this.$moment();
				const sixMonthsAgo = this.$moment().subtract(5, 'months');
				const months = [];

				while (sixMonthsAgo.isSameOrBefore(now, 'month')) {
					months.push(sixMonthsAgo.format('YYYY-MM'));
					sixMonthsAgo.add(1, 'months');
				}

				return months.map((month) => {
					const monthTransactions = this.rewardTransactionList.filter((t) => t.credit && this.$moment(t.created).format('YYYY-MM') === month);
					return monthTransactions.reduce((sum, t) => sum + t.amount, 0);
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	::v-deep .v-progress-circular__info {
		span.text-body-1 {
			font-size: 1.75rem !important;
		}
	}
</style>
