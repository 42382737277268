<!--
	@name app-marketing
	@description Marketing page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img
			:src="'generic/img_air_marketing_hero_1800x700.jpg' | urlize"
			style="border-bottom: 20px solid #1eb0e1"
			:height="$vuetify.breakpoint.mdAndUp ? '600' : ''"
		>
			<div id="overlay" />
			<v-container style="position: relative" fill-height>
				<div>
					<div class="text-h4 white--text">
						Air Marketing
					</div>
					<div class="text-h3 mt-6 white--text">
						Accelerating business <br />
						momentum
					</div>
				</div>
			</v-container>
		</v-img>

		<!-- About Us -->
		<div>
			<v-container>
				<div class="text-center d-flex flex-column align-center py-4" :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }">
					<p class="text-h6" style="max-width: 800px">
						Air Marketing is a virtual micro-marketing team for your business. It provides access to know-how, support, tools and channels to create, promote and master
						the momentum of your business development and customer relationships, all of which help you better educate prospective clients and win more business.
					</p>
				</div>
			</v-container>
		</div>

		<!-- Benefits -->
		<div class="mt-2 benefits pb-12" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<div class="text-h4 text-center">
					<p>What Air Marketing offers</p>
				</div>
				<p class="text-body-1 text-center">
					Our Marketing Hub provides you with access to a suite of ready to go marketing templates to support your business.
				</p>
				<v-row>
					<v-col cols="12" md="4" class="mt-6" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
						<div class="text-center px-4">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="76" height="58" viewBox="0 0 76 58" fill="none" style="height: 80px; width: auto">
									<path
										d="M71.6896 34.8599V21.8599C71.6896 21.6599 71.6496 21.4699 71.5796 21.2999C71.3996 20.0899 70.6596 19.0099 69.5396 18.3899L37.9196 0.899946C36.6696 0.209946 35.0596 0.209946 33.8096 0.899946L2.17961 18.3899C0.899609 19.0999 0.0996094 20.4299 0.0996094 21.8599C0.0996094 23.2899 0.899609 24.6199 2.17961 25.3299L11.5196 30.4899V47.4199C11.5196 48.9099 12.2696 50.2799 13.5296 51.0899C20.1996 55.3599 27.9196 57.6199 35.8596 57.6199C43.7996 57.6199 51.5296 55.3599 58.1896 51.0899C59.4496 50.2899 60.1996 48.9199 60.1996 47.4199V30.4899L68.5496 25.8699V34.8499C66.1196 35.5399 64.3396 37.7699 64.3396 40.4099V48.9699C64.3396 49.7999 65.0096 50.4699 65.8396 50.4699H74.3996C75.2296 50.4699 75.8996 49.7999 75.8996 48.9699V40.4099C75.8996 37.7699 74.1096 35.5399 71.6896 34.8499V34.8599ZM3.09961 21.8599C3.09961 21.5199 3.29961 21.1999 3.62961 21.0099L35.2496 3.51995C35.4296 3.41995 35.6396 3.36995 35.8496 3.36995C36.0596 3.36995 36.2696 3.41995 36.4496 3.51995L66.7596 20.2799H35.8396C35.0096 20.2799 34.3396 20.9499 34.3396 21.7799V21.9199C34.3396 22.7499 35.0096 23.4199 35.8396 23.4199H66.7496L36.4396 40.1799C36.0796 40.3799 35.5896 40.3799 35.2296 40.1799L13.7196 28.2899L3.60961 22.6999C3.27961 22.5199 3.07961 22.1899 3.07961 21.8599H3.09961ZM57.1996 32.1499V47.4199C57.1996 47.8799 56.9696 48.3099 56.5796 48.5599C50.3996 52.5199 43.2296 54.6199 35.8596 54.6199C28.4896 54.6199 21.3296 52.5299 15.1396 48.5599C14.7496 48.3099 14.5196 47.8799 14.5196 47.4199V32.1499L33.8096 42.8099C34.4296 43.1499 35.1396 43.3399 35.8596 43.3399C36.5796 43.3399 37.2896 43.1599 37.9096 42.8099L57.1996 32.1499ZM72.8996 47.4799H67.3396V40.4199C67.3396 38.8899 68.5796 37.6399 70.1096 37.6399H70.1296C71.6596 37.6399 72.8996 38.8899 72.8996 40.4199V47.4799Z"
										fill="url(#paint0_linear_1872_1748)"
									></path>
									<defs>
										<linearGradient id="paint0_linear_1872_1748" x1="-8.51788" y1="57.62" x2="83.4423" y2="57.6187" gradientUnits="userSpaceOnUse">
											<stop stop-color="#470048"></stop>
											<stop offset="0.272677" stop-color="#670056"></stop>
											<stop offset="0.59989" stop-color="#1B78D8"></stop>
											<stop offset="1" stop-color="#1EB0E1"></stop>
										</linearGradient>
									</defs>
								</svg>
							</div>
							<div class="text-h6 secondary--text mt-2">
								Safe and simple marketing
							</div>
							<div class="text-body-2 mt-2">
								Wide range of expert-reviewed, off-the-shelf marketing templates
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" class="mt-6" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
						<div class="text-center px-4">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none" style="height: 80px; width: auto">
									<path
										d="M10.2008 18.6301H49.4008C50.2308 18.6301 50.9008 17.9601 50.9008 17.1301C50.9008 16.3001 50.2308 15.63 49.4008 15.63H10.2008C9.37078 15.63 8.70078 16.3001 8.70078 17.1301C8.70078 17.9601 9.37078 18.6301 10.2008 18.6301ZM10.2008 29.9701H49.3508C50.1808 29.9701 50.8508 29.3001 50.8508 28.4701C50.8508 27.6401 50.1808 26.9701 49.3508 26.9701H10.2008C9.37078 26.9701 8.70078 27.6401 8.70078 28.4701C8.70078 29.3001 9.37078 29.9701 10.2008 29.9701ZM10.2008 41.03H49.3508C50.1808 41.03 50.8508 40.3601 50.8508 39.53C50.8508 38.7 50.1808 38.03 49.3508 38.03H10.2008C9.37078 38.03 8.70078 38.7 8.70078 39.53C8.70078 40.3601 9.37078 41.03 10.2008 41.03ZM62.0008 0.300049H7.40078C3.48078 0.300049 0.300781 3.49005 0.300781 7.40005V60.6C0.300781 64.5201 3.49078 67.7 7.40078 67.7H52.2008C56.1208 67.7 59.3008 64.51 59.3008 60.6V14.5H66.2008C67.0308 14.5 67.7008 13.83 67.7008 13V6.00005C67.7008 2.86005 65.1408 0.300049 62.0008 0.300049ZM56.9508 3.36005C56.8608 3.52005 56.7908 3.69005 56.7208 3.87005C56.6908 3.93005 56.6708 4.00005 56.6508 4.06005C56.6008 4.20005 56.5508 4.34005 56.5108 4.48005C56.4908 4.55005 56.4708 4.62005 56.4508 4.70005C56.4208 4.85005 56.3908 5.00005 56.3608 5.15005C56.3508 5.22005 56.3408 5.28005 56.3308 5.35005C56.3008 5.57005 56.2908 5.79005 56.2908 6.01005V60.6101C56.2908 62.87 54.4508 64.7101 52.1908 64.7101H7.40078C5.14078 64.7101 3.30078 62.87 3.30078 60.6101V7.40005C3.30078 5.14005 5.14078 3.30005 7.40078 3.30005H56.9808C56.9808 3.30005 56.9608 3.34005 56.9508 3.36005ZM64.7008 11.5H59.3008V6.00005C59.3008 4.51005 60.5108 3.30005 62.0008 3.30005C63.4908 3.30005 64.7008 4.51005 64.7008 6.00005V11.5ZM44.4008 46.1301C40.8208 46.1301 37.9008 49.05 37.9008 52.63C37.9008 56.21 40.8208 59.13 44.4008 59.13C47.9808 59.13 50.9008 56.21 50.9008 52.63C50.9008 49.05 47.9808 46.1301 44.4008 46.1301Z"
										fill="url(#paint0_linear_1872_1753)"
									></path>
									<defs>
										<linearGradient id="paint0_linear_1872_1753" x1="-7.36173" y1="67.7" x2="74.4076" y2="67.6992" gradientUnits="userSpaceOnUse">
											<stop stop-color="#470048"></stop>
											<stop offset="0.272677" stop-color="#670056"></stop>
											<stop offset="0.59989" stop-color="#1B78D8"></stop>
											<stop offset="1" stop-color="#1EB0E1"></stop>
										</linearGradient>
									</defs>
								</svg>
							</div>
							<div class="text-h6 secondary--text mt-2">
								Bigger and better campaigns
							</div>
							<div class="text-body-2 mt-2">
								Better personalise your communication to clients with campaigns in a box
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="4" class="mt-6" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
						<div class="text-center px-4">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="74" height="68" viewBox="0 0 74 68" fill="none" style="height: 80px; width: auto">
									<path
										d="M72.4199 26.08L37.4199 0.879971C36.8999 0.499971 36.1899 0.499971 35.6699 0.879971L0.669863 26.08C0.139863 26.46 -0.0801376 27.14 0.119862 27.76C0.319862 28.38 0.899863 28.8 1.54986 28.8H4.24986V35.7C4.24986 36.53 4.91986 37.2 5.74986 37.2H7.04986V41.3C7.04986 42.13 7.71986 42.8 8.54986 42.8H9.84986V59.4H5.74986C4.91986 59.4 4.24986 60.07 4.24986 60.9V66.5C4.24986 67.33 4.91986 68 5.74986 68H67.3499C68.1799 68 68.8499 67.33 68.8499 66.5V60.9C68.8499 60.07 68.1799 59.4 67.3499 59.4H63.2499V42.8H64.5499C65.3799 42.8 66.0499 42.13 66.0499 41.3V37.2H67.3499C68.1799 37.2 68.8499 36.53 68.8499 35.7V28.8H71.5499C72.1999 28.8 72.7799 28.38 72.9799 27.76C73.1799 27.14 72.9599 26.46 72.4299 26.08H72.4199ZM65.8399 62.4V65H7.24986V62.4H65.8499H65.8399ZM11.3499 39.8H10.0499V37.2H21.0499V39.8H11.3499ZM18.2499 42.8V59.4H12.8499V42.8H18.2499ZM29.5499 42.8H30.8499V59.4H21.2499V42.8H22.5499C23.3799 42.8 24.0499 42.13 24.0499 41.3V37.2H28.0499V41.3C28.0499 42.13 28.7199 42.8 29.5499 42.8ZM32.3499 39.8H31.0499V37.2H42.0499V39.8H32.3499ZM39.2499 42.8V59.4H33.8499V42.8H39.2499ZM50.5499 42.8H51.8499V59.4H42.2499V42.8H43.5499C44.3799 42.8 45.0499 42.13 45.0499 41.3V37.2H49.0499V41.3C49.0499 42.13 49.7199 42.8 50.5499 42.8ZM53.3499 39.8H52.0499V37.2H63.0499V39.8H53.3499ZM60.2499 42.8V59.4H54.8499V42.8H60.2499ZM65.8499 34.2H7.24986V28.8H65.8499V34.2ZM6.19986 25.8L36.5499 3.94997L66.8999 25.8H6.19986ZM31.5499 17.5C31.5499 20.26 33.7899 22.5 36.5499 22.5C39.3099 22.5 41.5499 20.26 41.5499 17.5C41.5499 14.74 39.3099 12.5 36.5499 12.5C33.7899 12.5 31.5499 14.74 31.5499 17.5ZM38.5499 17.5C38.5499 18.6 37.6499 19.5 36.5499 19.5C35.4499 19.5 34.5499 18.6 34.5499 17.5C34.5499 16.4 35.4499 15.5 36.5499 15.5C37.6499 15.5 38.5499 16.4 38.5499 17.5Z"
										fill="url(#paint0_linear_1867_1878)"
									></path>
									<defs>
										<linearGradient id="paint0_linear_1867_1878" x1="-8.25297" y1="68" x2="80.3175" y2="67.999" gradientUnits="userSpaceOnUse">
											<stop stop-color="#470048"></stop>
											<stop offset="0.272677" stop-color="#670056"></stop>
											<stop offset="0.59989" stop-color="#1B78D8"></stop>
											<stop offset="1" stop-color="#1EB0E1"></stop>
										</linearGradient>
									</defs>
								</svg>
							</div>
							<div class="text-h6 secondary--text mt-2">
								Learn when you want
							</div>
							<div class="text-body-2 mt-2">
								Multiple on-demand, industry-expert-led webinars offering expert support
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row class="d-flex flex-column align-center mt-16">
					<div class="text-h4">
						Sign up now or login
					</div>
					<div class="d-flex flex-wrap justify-center mt-4">
						<v-item>
							<v-card
								class="d-flex flex-grow-1 flex-column ma-4"
								color="grey lighten-2"
								height="170"
								style="flex-basis: 30%; position: relative"
								hover
								href="https://airhub.bdprints.co.uk/print/"
								target="blank"
							>
								<v-system-bar class="primary" height="16" />
								<div class="d-flex justify-center align-center flex-grow-1 text-center pa-6">
									<p class="text-h6 mb-0">
										If you're an Advance, Platinum, Elite member click here
									</p>
								</div>
							</v-card>
						</v-item>
						<v-item>
							<v-card
								class="d-flex flex-grow-1 flex-column ma-4"
								color="grey lighten-2"
								height="170"
								style="flex-basis: 30%; position: relative"
								hover
								href="https://airselecthub.bdprints.co.uk/"
								target="blank"
							>
								<v-system-bar class="primary" height="16" />
								<div class="d-flex justify-center align-center flex-grow-1 text-center pa-6">
									<p class="text-h6 mb-0">
										If you're a Select member click here
									</p>
								</div>
							</v-card>
						</v-item>
					</div>
				</v-row>
			</v-container>
		</div>

		<!-- Hero -->
		<!-- <div class="mt-10 py-10" style="background-color: #f6f6f6">
			<v-container>
				<v-row>
					<v-col cols="12" sm="6">
						<h3 class="text-h5 font-weight-bold" style="color: #470048">
							Acquisition and nurture marketing materials
						</h3>
						<ul class="text-body-2 mt-8" style="list-style-type: none; padding-left: 0">
							<li v-for="(item, index) in nurtureMarketingMaterials" :key="index" class="pb-1">
								<v-icon color="secondary">
									mdi-chevron-right
								</v-icon>
								<span>{{ item }}</span>
							</li>
						</ul>
					</v-col>
					<v-col cols="12" sm="6">
						<h3 class="text-h5 font-weight-bold" style="color: #470048">
							Referral marketing materials
						</h3>
						<ul class="text-body-2 mt-8" style="list-style-type: none; padding-left: 0">
							<li v-for="(item, index) in referralMarketingMaterials" :key="index" class="pb-1">
								<v-icon color="secondary">
									mdi-chevron-right
								</v-icon>
								<span>{{ item }}</span>
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-container>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: 'app-marketing',

		components: {},

		data() {
			return {
				nurtureMarketingMaterials: ['Database email template', 'Direct mail templates', 'Local press adverts', 'Event/door drop leaflets', 'Event banners'],
				referralMarketingMaterials: ['Equity release brochure', 'Customer leaflets', 'Opportunities leaflet', 'Case studies', 'Email/social media', 'Event banners']
			};
		},

		computed: {},

		created() {},

		methods: {
			test() {
				console.log('test');
			}
		}
	};
</script>

<style lang="scss" scoped>
	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}
</style>
