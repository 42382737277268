<!--
	@name app-summer-summer202401-hero
	@description
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div
			style="
				position: relative;
				overflow: hidden;
				z-index: 1;
				background-color: rgb(180, 51, 13);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: cover;
			"
			:style="
				`height: ${height}px;`
			"
		>
			<div
				style="
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.9;
					background-color: rgb(180, 51, 13);
				"
				:class="`${background}`"
			/>
			<v-carousel hide-delimiters :show-arrows="$vuetify.breakpoint.mdAndUp" cycle continuous height="100%" style="opacity: 0.8; mix-blend-mode: hard-light;">
				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_001.svg' | urlize" />
					</v-container>
				</v-carousel-item>

				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_002.svg' | urlize" />
					</v-container>
				</v-carousel-item>

				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_003.svg' | urlize" />
					</v-container>
				</v-carousel-item>

				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_004.svg' | urlize" />
					</v-container>
				</v-carousel-item>

				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_005.svg' | urlize" />
					</v-container>
				</v-carousel-item>

				<v-carousel-item>
					<v-container class="d-flex align-center pa-0 h-100" style="position: relative; z-index: 2">
						<img style="width: 100%; height: 100%;" :src="'cms/AIR-Track_006.svg' | urlize" />
					</v-container>
				</v-carousel-item>
			</v-carousel>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-summer-summer202401-hero',

		components: {},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL
			};
		},

		computed: {
			height() {
				if (this.$vuetify.breakpoint.lgAndUp) return 550;
				if (this.$vuetify.breakpoint.mdAndUp) return 400;
				return 200;
			},

			background() {
				if (this.$vuetify.breakpoint.mdAndUp) return 'background--normal';
				return 'background--small';
			}
		},

		created() {}
	};
</script>

<style lang="scss" scoped>
.background--normal {
	background-position: left 30px, left bottom 30px, 50% 50%;
	background-size: auto, auto, contain;
	background-repeat: repeat-x, repeat-x, repeat;
	background-blend-mode: hard-light, hard-light, lighten;
	background-image: url($VUE_APP_S3_AIR_ASSETS_URL+'/cms/AIR+White+Line.jpg'), url($VUE_APP_S3_AIR_ASSETS_URL+'/cms/AIR+White+Line.jpg'), url($VUE_APP_S3_AIR_ASSETS_URL+'/cms/Red-Track-Texture.jpg');
}
.background--small {
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: repeat;
	background-blend-mode: lighten;
	background-image: url($VUE_APP_S3_AIR_ASSETS_URL+'/cms/Red-Track-Texture.jpg');
}
</style>
