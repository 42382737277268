import { $http } from '@/utils';

/**
 * @namespace Service
 * @class InterfaceUser
 * @exports Service/Interface/User
 * @description API InterfaceUser Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class InterfaceUser {
	/**
	 * @static @name list
	 * @description Get all credentials
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('interface/user/list', payload);
	}

	/**
	 * @static @name get
	 * @description Get credential details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`interface/user/${id}`);
	}

	/**
	 * @static @name patch
	 * @description Update some credential details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	static patch(id, payload) {
		return $http.patch(`interface/user/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete credential details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`interface/user/${id}`);
	}

	/**
	 * @static @name post
	 * @description Add a new credential
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`interface/user`, payload);
	}
}
