<!--brand-logobrand-logo
	@name app-changelog
	@description Changelog page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						OnAir
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-container class="ml-0 pa-0">
			<v-row no-gutters>
				<v-col cols="12">
					<div class="border-effect border-effect--lg ml-6">
						<p class="text-h5 font-weight-regular mb-3">
							Welcome to <span class="font-weight-bold">OnAir</span>: Where Innovation Meets Improvement in Later Life Financial Services.
						</p>
						<p class="text-body-1 mb-6">
							At Air, we're committed to continuous innovation and improvement in later life financial planning, guided by your feedback. OnAir is your exclusive
							window into our dynamic world of recent updates and future enhancements.
						</p>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<v-container class="ml-8 py-0">
			<v-row no-gutters>
				<v-col cols="12">
					<v-row no-gutters>
						<v-col cols="6" class="pr-4">
							<v-text-field v-model="search" solo placeholder="Search" />
						</v-col>
						<v-col cols="3" class="pl-4 pr-4">
							<v-select v-model="selectedCategories" :items="categoryOptions" label="Categories" multiple solo>
								<template #selection="{ index }">
									<span v-if="index === 0"> {{ selectedCategories.length }} selected </span>
								</template>
							</v-select>
						</v-col>
						<v-col cols="3" class="pl-4">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								:return-value.sync="dateModel"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template #activator="{ on, attrs }">
									<v-text-field
										v-model="dateModel"
										solo
										label="Date"
										prepend-icon="mdi-calendar"
										clearable
										readonly
										v-bind="attrs"
										v-on="on"
										@input="dateChanged"
									/>
								</template>
								<v-date-picker v-model="dateModel" no-title scrollable>
									<v-spacer />
									<v-btn text color="primary" @click="menu = false">
										Cancel
									</v-btn>
									<v-btn text color="primary" @click="dateChanged(dateModel)">
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<v-container class="ml-10" style="max-width: 950px">
			<v-row no-gutters>
				<v-col cols="12">
					<div v-if="!filteredItems">
						<v-skeleton-loader type="heading" />
						<v-skeleton-loader class="mt-6" type="chip" />
						<v-skeleton-loader class="mt-6" type="paragraph" />
						<v-skeleton-loader class="mt-4" type="paragraph" />
						<v-skeleton-loader class="mt-4" type="image" />
					</div>
					<v-sheet v-else-if="!filteredItems.length" color="grey lighten-3" class="pa-3">
						<div class="text-body-2">
							No results found
						</div>
					</v-sheet>
					<div v-else style="padding-left: 96px">
						<v-timeline dense>
							<v-timeline-item v-for="(item, i) in filteredItems" :key="i" color="secondary" fill-dot small>
								<template #opposite>
									<v-chip v-if="$moment(item.posted).isAfter($moment())" dark label small color="pink">
										Coming soon
									</v-chip>
									<p v-else class="text-body-2 font-weight-regular grey--text text--darken-2 text-right">
										Launched on
										<br />
										{{ item.posted | moment('MMM Do YYYY') }}
									</p>
								</template>
								<v-card :class="`card--design1 card--design1` + ((i % 5) + 1 === 1 ? '' : '--' + ((i % 5) + 1))" flat>
									<v-card-title :class="`headline mb-0 px-6 pt-6 pb-0`">
										{{ item.title }}
									</v-card-title>
									<div class="px-6 pb-4">
										<v-chip-group class="mb-1">
											<v-chip v-if="showNewChip(item)" light label x-small color="lime">
												New
											</v-chip>
											<v-chip v-for="(category, index) in item.changelogCategoryId" :key="index" dark label x-small :color="getCategoryColour(category)">
												{{ getCategoryName(category) }}
											</v-chip>
										</v-chip-group>
										<p class="text-body-1 mb-0">
											{{ item.abstract }}
										</p>
										<video
											v-if="item.video"
											class="d-block mt-5 mb-6"
											:src="item.video | urlize"
											:poster="item.image || 'generic/gradient.png' | urlize"
											controls
											width="100%"
										/>
										<v-img
											v-else-if="item.image"
											:src="item.image | urlize"
											class="align-end mt-5 mb-6"
											gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
											height="200px"
											cover
										/>
										<v-btn v-if="item.description" small color="primary" class="d-block mt-4" @click="openDescriptionDialog(item)">
											READ MORE
										</v-btn>
									</div>
									<div class="d-flex px-5 pb-4">
										<div>
											<v-tooltip bottom>
												<template #activator="{ on }">
													<v-btn icon :loading="item.loading" @click="submitChangelogLike(item)" v-on="on">
														<v-icon v-if="item.changelogLikeActive" color="red lighten-2">
															mdi-heart
														</v-icon>
														<v-icon v-else color="blue-grey lighten-2">
															mdi-heart-outline
														</v-icon>
													</v-btn>
												</template>
												<span>{{ item.changelogLikeActive ? 'Thank you for registering your interest' : 'Click here to show your interest' }}</span>
											</v-tooltip>
											{{ item.changelogLikeCount }}
										</div>
										<div class="ml-4">
											<v-tooltip bottom>
												<template #activator="{ on }">
													<v-btn icon @click="openCommentDialog(item)" v-on="on">
														<v-icon v-if="item.changelogCommentActive" color="blue lighten-2">
															mdi-comment-text
														</v-icon>
														<v-icon v-else color="blue-grey lighten-2">
															mdi-comment-text-outline
														</v-icon>
													</v-btn>
												</template>
												<span>{{ item.changelogCommentActive ? 'Thank you for leaving a comment' : 'Click here to leave a comment' }}</span>
											</v-tooltip>
											{{ item.changelogCommentCount }}
										</div>
										<div class="ml-4">
											<v-tooltip bottom>
												<template #activator="{ on }">
													<v-btn icon @click="openChat" v-on="on">
														<v-icon color="blue-grey lighten-2">
															mdi-chat-question-outline
														</v-icon>
													</v-btn>
												</template>
												<span>Got a question? Speak with one of our team</span>
											</v-tooltip>
										</div>
									</div>
								</v-card>
							</v-timeline-item>
							<mugen-scroll :handler="fetchData" :should-handle="!loading" />
						</v-timeline>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<dynamic-form-dialog ref="dynamicFormDialog" :submit-button-loading="submittingComment" @dynamic-form-submit="submitChangelogComment" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools, EventBus } from '@/utils';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';
	import MugenScroll from 'vue-mugen-scroll';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-changelog',

		components: {
			DynamicFormDialog,
			MugenScroll
		},

		data() {
			return {
				items: null,
				itemCounter: 5,
				dateModel: null,
				selectedDate: null,
				menu: false,
				search: '',
				selectedCategories: [],
				categories: [],
				commentFormData: {},
				submittingComment: false,
				likeCounts: {},
				commentCounts: {},
				loading: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),
			...mapState('Account', ['user', 'changelogItems']),

			categoryOptions() {
				return this.categories.map((category) => ({ text: category.name, value: category.id }));
			},

			filteredItems() {
				// Filter for search terms and date cut-off
				let items = this.items
					?.filter((item) =>
						this.search
							? item.title?.toLowerCase()?.includes(this.search.toLowerCase()) ||
							  item.abstract?.toLowerCase()?.includes(this.search.toLowerCase()) ||
							  item.description?.toLowerCase()?.includes(this.search.toLowerCase())
							: true
					)
					.filter((item) => (this.selectedDate ? this.$moment(item.posted).isSameOrBefore(this.selectedDate) : true));
				// If categories have been selected, filter items in those categories, else show all
				if (this.selectedCategories.length > 0) {
					items = items.filter((item) => this.selectedCategories.some((category) => item.changelogCategoryId.includes(category)));
				}

				return items?.slice(0, this.itemCounter);
			}
		},

		created() {
			if (!this.changelogItems) EventBus.$on('changelog-loaded', this.init);
			else this.init();
		},

		destroyed() {
			EventBus.$off('changelog-loaded', this.init);
		},

		methods: {
			...mapActions('CmsChangelog', ['loadChangelogCategoryList', 'addChangelogLike', 'addChangelogComment', 'loadChangelogLikeCounts', 'loadChangelogCommentCounts']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name init
			 * @description init component
			 */
			init() {
				// Get preloaded changelog items from store and assign without reference to 'items' to be mutated with likes/comments
				this.items = cloneDeep(this.changelogItems);

				// Get the categories and then fetch like/comment counts
				this.loadChangelogCategoryList().then(({ data: changelogCategoryList }) => {
					this.categories = changelogCategoryList.data;
					this.counts();
				});

				this.loadForm('changelog_comment');
			},

			/**
			 * @name submitChangelogLike
			 * @description add like to changelog
			 * @param {Object} item changelog item
			 */
			async submitChangelogLike(item) {
				if (item.changelogLikeActive) return;

				this.$set(item, 'loading', true);
				this.addChangelogLike({ changelogId: item.id }).then(() => {
					item.changelogLikeActive = true;
					item.changelogLikeCount++;
					this.$set(item, 'loading', false);
				});
			},

			/**
			 * @name submitChangelogComment
			 * @description add comment to changelog
			 * @param {Object} item changelog item
			 */
			async submitChangelogComment({ data, config }) {
				console.log('config :>> ', config);
				this.submittingComment = true;
				this.addChangelogComment({ changelogId: config.item.id, comment: data.comment })
					.then(() => {
						config.item.changelogCommentActive = true;
						config.item.changelogCommentCount++;
						ElementTools.fireNotification(this.$el, 'success', `Your comment has been posted successfully.`);
						// this.$refs.dynamicFormDialog.closeDialog();
					})
					.catch((e) => {
						console.log('e :>> ', e);
						ElementTools.fireNotification(this.$el, 'error', `An error occured. Please try again later.`);
					})
					.finally(() => {
						this.submittingComment = false;
					});
			},

			/**
			 * @name getCategoryName
			 * @description Get category name by id
			 * @param {String} id category id
			 */
			getCategoryName(id) {
				const category = this.categories.find((category) => category.id === id);
				return category ? category.name : '';
			},

			/**
			 * @name getCategoryColour
			 * @description Get category colour by id
			 * @param {String} id category id
			 */
			getCategoryColour(id) {
				const category = this.categories.find((category) => category.id === id);
				return category ? category.colour : '';
			},

			/**
			 * @name openCommentDialog
			 * @description Open comment dialog
			 * @param {Object} item changelog item
			 */
			openCommentDialog(item) {
				if (item.changelogCommentActive) return;

				this.$refs.dynamicFormDialog.open({
					formSchema: this.form('changelog_comment').value,
					formData: {},
					title: 'Add Comment',
					item
				});
			},

			/**
			 * @name openDescriptionDialog
			 * @description Open description dialog
			 * @param {Object} item changelog item
			 */
			openDescriptionDialog(item) {
				this.$nextTick(() => EventBus.$emit('open-on-air-article', item.id));
			},

			/**
			 * @name dateChanged
			 * @description Date changed event
			 * @param {String} date selected date
			 */
			dateChanged(date) {
				this.$refs.menu.save(date);
				this.selectedDate = this.$moment(date).toISOString();
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name showNewChip
			 */
			showNewChip(item) {
				// If posted date is in the future, use the "created" date to judge where the item is new since last login
				if (this.$moment(item.posted) > this.$moment()) {
					if (this.$moment(item.created) > this.$moment(this.user.loginPrevious)) return true;
				}
				// Else check if "posted" date is after last login
				else if (this.$moment(this.$moment(item.posted).endOf('day').format()) > this.$moment(this.user.loginPrevious)) return true;
				return false;
			},

			/**
			 * @name counts
			 * @description Retrieves the like/comment counts for a given set of changelog items and incorporates them back into the items object
			 */
			counts() {
				const changelogUuids = this.items.slice(this.itemCounter - 5, this.itemCounter).map((a) => a.id);
				if (changelogUuids.length) {
					const where = { where: { changelog_id: { c: 'IN', v: changelogUuids } } };

					// Get the relevant like/comment counts for the changelog items we're passing
					Promise.all([this.loadChangelogLikeCounts(where), this.loadChangelogCommentCounts(where)]).then(
						([{ data: changelogLikeCounts }, { data: changelogCommentCounts }]) => {
							// Put counts into changelog items
							this.items = this.items.map((item) => {
								if(changelogUuids.includes(item.id)) {
									if (changelogLikeCounts[item.id]) {
										item.changelogLikeCount = changelogLikeCounts[item.id].changelogLikeCount;
										item.changelogLikeActive = changelogLikeCounts[item.id].changelogLikeActive;
									} else {
										item.changelogLikeCount = 0;
										item.changelogLikeActive = false;
									}
									if (changelogCommentCounts[item.id]) {
										item.changelogCommentCount = changelogCommentCounts[item.id].changelogCommentCount;
										item.changelogCommentActive = changelogCommentCounts[item.id].changelogCommentActive;
									} else {
										item.changelogCommentCount = 0;
										item.changelogCommentActive = false;
									}
								}
								return item;
							});
						}
					);
				}
			},

			fetchData() {
				this.loading = true;
				this.itemCounter = this.itemCounter + 5;
				this.counts();
				this.loading = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.image-content {
		font-size: 0;
	}

	.brand-logo {
		height: 40px;
	}

	.v-timeline-item {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	::v-deep .v-timeline--dense .v-timeline-item__opposite {
		display: block;
		width: 96px;
		margin-left: -96px;
	}

	::v-deep {
		.v-timeline-item__divider,
		.v-timeline-item__opposite {
			flex: 0 1 auto;
			align-self: start;
			padding-top: 8px;
		}
	}

	::v-deep .v-timeline-item__body {
		padding-left: 20px;
	}
</style>
