<template>
	<v-sheet v-if="site" color="white" class="header">
		<v-row no-gutters class="py-10 px-6 mx-auto" style="max-width: 1280px">
			<v-col cols="6" md="3" class="d-flex align-center">
				<img :src="site.logo.path | urlize('brand')" class="mr-5 h-100 logo" />
			</v-col>
			<v-col cols="6" md="9" class="text-right py-2">
				<v-app-bar-nav-icon class="d-md-none" @click.stop="openNavigation" />
				<div class="d-none d-md-flex justify-end" style="height: 100%">
					<div v-for="item in frontMenu?.list" :key="item.nameUnique" class="flex-grow-0 align-self-center nav-button">
						<v-btn
							class="px-3 text-none"
							text
							:href="isExternalLink(item) ? item.url : ''"
							:to="!isExternalLink(item) ? item.url : ''"
							:target="item.target == null ? '_self' : item.target"
						>
							{{ item.name }}
						</v-btn>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-layout-default-header',

		data() {
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { title: 'Photos', icon: 'mdi-image' },
          { title: 'About', icon: 'mdi-help-box' }
        ],
        right: null
      };
    },

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('CmsMenu', ['frontMenu'])
		},

		methods: {
			// <!-- TODO: [SPA] Should be coming from api -->
			isExternalLink(link) {
				return link.url.includes('http');
			},

			openNavigation() {
				this.$emit('open-navigation');
			}
		}
	};
</script>

<style lang="scss" scoped>
.logo {
	max-height: 4rem;
	max-width: 100%;
}
.nav-button {
	margin-left: 8px;

	&:first-child {
		margin-left: 0;
	}
}
</style>
