<!--
	@name app-download-document
	@description Show documents on the downloads page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<common-structure-section thick-header :component="$options.name" body-class="flex-grow-1 pt-5">
		<template #header>
			Documents
			<v-spacer />
			<v-select :value="selectedSortOption" :items="sortOptions" label="Sort by" class="sort-option-select mr-2" hide-details dense solo @change="handleSort($event)" />
			<v-text-field
				:value="searchTerm"
				placeholder="Search: e.g. NOC List"
				class="search-field"
				append-icon="mdi-magnify"
				hide-details
				dense
				solo
				@input="handleSearch($event)"
			/>
		</template>

		<template #body>
			<div v-if="!downloads.length">
				<v-skeleton-loader type="actions" />
				<v-row>
					<v-col v-for="index in 3" :key="index" cols="12" sm="12" class="pa-0 ma-0">
						<v-skeleton-loader type="list-item-avatar-two-line" class="pa-0 ma-0" />
					</v-col>
				</v-row>
			</div>

			<div v-else>
				<div v-if="tags.length" class="pb-4">
					<common-base-tag :tags="tags" @update-tags="updateTags" />
				</div>
				<common-base-download-items :items="visibleDownloads" />
				<div v-show="visibleDownloads.length === 0" class="pa-10">
					<p class="text-h6 text-center ma-0">
						No results found
					</p>
				</div>
			</div>
		</template>
	</common-structure-section>
</template>

<script>
	import { debounce, orderBy } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonBaseTag from '@/component/common/base/tag';
	import CommonBaseDownloadItems from '@/component/common/base/download-items';
	import { mapGetters, mapState } from 'vuex';

	export default {
		name: 'app-download-document',

		components: { CommonStructureSection, CommonBaseTag, CommonBaseDownloadItems },

		data() {
			return {
				activeTags: [],
				searchTerm: '',
				selectedSortOption: 'title',
				sortOptions: [
					{
						text: 'Order by document title',
						value: 'title',
						sortType: 'asc'
					},
					{
						text: 'Order by most recent',
						value: 'created',
						sortType: 'desc'
					}
				]
			};
		},

		computed: {
			...mapState('CmsDownload', { downloads: 'downloadList' }),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsDownload', { tags: 'downloadTags' }),

			/**
			 * @name visibleDownloads
			 * @description Filters downloads according to user selections
			 * @returns {Array}
			 */
			visibleDownloads() {
				let filtered = this.downloads.filter((download) => {
					//Remove Academy downloads if not academy member
					//if(!this.membership('academy').approve && download.tag.some((t) => t.name.toLowerCase() === 'academy')) return false;
					return (
						download.active &&
						download.tag.some((t) => this.activeTags.includes(t.name)) &&
						(download.title.toLowerCase().includes(this.searchTerm.toLowerCase()) || download.description.toLowerCase().includes(this.searchTerm.toLowerCase()))
					);
				});

				let selectedSortOption = this.sortOptions.filter((option) => option.value === this.selectedSortOption)[0];
				return orderBy(filtered, selectedSortOption.value === 'title' ? [(item) => item.title.toLowerCase()] : [selectedSortOption.value], [selectedSortOption.sortType]);
			}
		},

		methods: {
			/**
			 * @debounce @name handleSearch
			 * @description handle search term changes
			 * @param {String} value updated search term
			 */
			handleSearch: debounce(function(value) {
				this.searchTerm = value;
			}, 200),

			/**
			 * @name handleSort
			 * @description handle sort option changes
			 * @param {String} value updated sort option
			 */
			handleSort(value) {
				this.selectedSortOption = value;
			},

			/**
			 * @name updateTags
			 * @description Tags updated event handler
			 * @param {Array} activeTags The active tags
			 */
			updateTags(activeTags) {
				this.activeTags = activeTags;
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-skeleton-loader__actions {
		text-align: left;
		padding: 6px 0 18px;
	}
	.sort-option-select {
		width: 200px;
		max-width: 200px;
	}
	.search-field {
		width: 225px;
		max-width: 225px;
	}
</style>
