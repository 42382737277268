<!--
	@name app-login
	@description Register page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 pt-0 mx-auto" style="max-width: 600px">
			<v-col cols="12">
				<v-card class="mx-auto" max-width="500">
					<h2 class="text-h5 text-center font-weight-bold pt-6 pb-3">
						Adviser Log In
					</h2>

					<v-sheet class="body pa-5 pt-0">
						<dynamic-form
							ref="loginForm"
							:form-schema="form('login').value"
							:form-data="formData"
							:submit-button-loading="loading"
							submit-button-text="Log in"
							:submit-button-block="true"
							:submit-button-disabled="true"
						/>

						<v-btn color="blue-grey lighten-1" disabled text class="mt-3 mx-auto text-none d-flex" to="/password-recovery">
							Forgotten your password?
						</v-btn>
					</v-sheet>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapGetters, mapState, mapActions } from 'vuex';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-login',

		components: { DynamicForm },

		data() {
			return {
				formData: {},
				twoFactorFormData: {},
				loading: false,
				twoFactor: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('login');
		},

		methods: {
			...mapActions('Account', ['login']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsSite', ['loadSite'])
		}
	};
</script>
