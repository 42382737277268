<!--
	@name app-air-academy-dashboard-events
	@description Events dialog for the air academy dashboard
	@date 2024/08/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" sm="10" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								{{ title }}
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" sm="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="active = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-card-text>
					<v-container class="px-0 pb-10">
						<v-sheet v-if="!selectedEvent" class="pa-6 mb-3 rounded grey lighten-2">
							<v-row no-gutters>
								<v-col cols="12" sm="7" class="align-content-center">
									<p class="text-h6 mb-0">
										{{ title }}
									</p>
								</v-col>
								<v-col cols="12" sm="5" class="grow text-right">
									<slot name="logo" />
								</v-col>
							</v-row>
						</v-sheet>

						<v-container v-if="selectedEvent" class="py-0 px-4" style="max-width: 800px">
							<a class="text-body-2" @click="toggleEvent(null)">
								<v-icon class="d-inline-block mr-1" color="primary" size="16" style="position: relative; top: -1px;">
									mdi-close
								</v-icon>
								<span class="d-inline-block">
									<span class="font-weight-bold">Back to Events</span>
								</span>
							</a>
							<app-events-event-details :event="selectedEvent" />
						</v-container>
						<v-container v-else class="py-0 px-4">
							<app-events-list @select-event="toggleEvent" />
						</v-container>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import AppEventsList from '@/component/app/events/events-list';
	import AppEventsEventDetails from '@/component/app/events/event-details';

	export default {
		name: 'app-air-academy-dashboard',

		components: {
			AppEventsList,
			AppEventsEventDetails
		},

        props: {
			title: { type: String, required: true }
		},

		data() {
			return {
				active: false,
				selectedEvent: null
			};
		},

		methods: {
            open() {
                this.active = true;
            },

            close() {
                this.active = false;
				this.selectedEvent = null;
            },

			toggleEvent(event) {
				this.selectedEvent = event;
			}
		}
	};
</script>
