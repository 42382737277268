/**
 * @class @name ProcessHelper
 * @description Process common methods
 */
export default class ProcessHelper {
	/**
	 * @name getActiveSourcingIteration
	 * @param {Object} process
	 * @return {Object} The active iteration
	 */
	 static getActiveSourcingIteration(process) {
		let active = process?.iteration?.find(i => i.active);
		return active;
	}
}
