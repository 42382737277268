import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Organisation
 * @exports Service/Account/Organisation
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class MatterType {
	/**
	 * @static @name list
	 * @description Get all matters
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('matter/type/list', payload);
	}
}
