<!--
	@name common-dialog-confirm
	@description A common dialog box for confirmations
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" :persistent="options.persistent" :component="$options.name" @keydown.esc="cancel">
		<v-card>
			<v-app-bar dark class="generic-gradient" :color="options.app_bar_color">
				<v-toolbar-title> Log in </v-toolbar-title>
				<v-spacer />
				<v-btn icon @click="close">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-app-bar>

			<div v-if="!twoFactor" class="pt-4 px-6 pb-6">
				<dynamic-form
					ref="loginForm"
					:form-schema="form('login').value"
					:form-data="formData"
					:submit-button-loading="loading"
					submit-button-text="Log in"
					:submit-button-block="true"
					:submit-button-large="true"
					@dynamic-form-submit="submit"
				/>
			</div>
			<div v-else class="pa-6">
				<h3 class="pb-3">
					Two Factor Authentication
				</h3>
				<v-divider />
				<p class="font-weight-medium pt-3">
					Please enter a 2FA code generated by your authenticator application in the field below.
				</p>

				<dynamic-form
					ref="twoFactorForm"
					:form-schema="form('two-factor').value"
					:form-data="twoFactorFormData"
					:submit-button-loading="loading"
					:submit-button-block="true"
					:submit-button-large="true"
					@dynamic-form-submit="submit"
				/>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapGetters, mapState, mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'common-dialog-login',

		components: { DynamicForm },

		data() {
			return {
				formData: {},
				twoFactorFormData: {},
				loading: false,
				twoFactor: false,
				dialog: false,
				options: {
					buttons: { yes: 'OK', no: 'Cancel' },
					sessionHide: false,
					dialogName: null,
					system_bar_color: 'warning darken-2',
					app_bar_color: 'warning',
					headline: null,
					hideText: null,
					paragraphs: [],
					titleIcon: null,
					persistent: false,
					width: 650,
					zIndex: 200
				}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('login');
		},

		beforeDestroy() {
			ElementTools.hideNotification(this.$root.$el);
		},

		methods: {
			...mapActions('Account', ['login']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsSite', ['loadSite']),

			/**
			 * @name open
			 * @description Open the dialog
			 * @param {Object} options Any options for the dialog
			 * @return {Promise} A Promise
			 */
			async open(options) {
				this.twoFactor = false;
				this.loading = false;
				this.formData = {};
				this.twoFactorFormData = {};
				this.$refs.loginForm?.resetValidation();
				this.$refs.twoFactorForm?.resetValidation();
				this.options = Object.assign(this.options, options);
				this.dialog = true;
			},

			async submit() {
				this.displayHint = false;
				this.loading = true;

				try {
					await this.login({ ...this.formData, ...this.twoFactorFormData });
					await this.loadSite();
					this.$emit('login-success');
				} catch (err) {
					if (err.data?.twoFactor) {
						await this.loadForm('two-factor');
						this.twoFactor = true;
					} else {
						ElementTools.fireNotification(this.$root.$el, 'error', { text: err.data?.message || 'Login failed, please try again', close: true });
						this.$emit('login-failed');
					}
				} finally {
					this.loading = false;
				}
			},

			close() {
				this.dialog = false;
			}
		}
	};
</script>
