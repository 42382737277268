import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
	environment: process.env.NODE_ENV + (process.env.VUE_APP_AIRBRAKE_ENV_SUFFIX ? ':' + process.env.VUE_APP_AIRBRAKE_ENV_SUFFIX : ''),
	projectId: process.env.VUE_APP_AIRBRAKE_PROJECT_ID,
	projectKey: process.env.VUE_APP_AIRBRAKE_PROJECT_KEY
});

const dump = {
	notify: function(data) {
		console.log('AIRBRAKE DISABLED (enable this to push traces)', data);
	}
};

airbrake.addFilter((notice) => {
	// Filter out 403
	if (Object.prototype.hasOwnProperty.call(notice, 'response') && Object.prototype.hasOwnProperty.call(notice.response, 'status') && notice.response.status) return null;
	return notice;
});

export default process.env.VUE_APP_AIRBRAKE_ENABLED === 'true' ? airbrake : dump;
