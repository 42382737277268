<!--
	@name app-firm-tab-view-domains
	@description Firm Domains
	@date 2020/08/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section thick-header>
			<template #header>
				<v-pagination v-model="currentPage" :length="pageCount" :total-visible="8" color="info" />

				<v-spacer />

				<v-btn @click="openDomainDialog">
					Add New Domain
				</v-btn>
			</template>

			<template #body>
				<v-skeleton-loader v-if="!organisationDomainList" type="list-item-avatar-three-line@3" class="px-0" />

				<div v-else-if="!organisationDomainList.length">
					<div class="text-body-1 font-weight-bold mb-3">
						Add domain names that your firm uses.
					</div>
					<div class="text-body-2 mb-3">
						If your FCA Number is locked on your account, any new adviser registrations with email addresses matching your domain will automatically join your firm as an adviser account.
					</div>
					<div class="text-body-2">
						Click the ADD NEW DOMAIN button to get started.
					</div>
				</div>

				<v-data-table
					v-else
					class="data-table"
					:footer-props="footerProps"
					:headers="headers"
					:items="orderedDomains"
					:items-per-page="itemsPerPage"
					:page.sync="currentPage"
					@page-count="pageCount = $event"
				>
					<template #body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.id" class="tbl-row">
								<td>
									<p class="text-body-1 font-weight-bold mb-0">
										{{ item.domainAddress }}
									</p>
								</td>

								<td class="text-right shrunk">
									<v-tooltip bottom content-class="text-center" max-width="250">
										<template #activator="{ on }">
											<v-btn color="error" small text :loading="loading[item.id]" @click="deleteDomain(item)" v-on="on">
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
										</template>
										<span> Delete this domain </span>
									</v-tooltip>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</template>
		</common-structure-section>

		<common-dialog-confirm ref="confirm" />
		<app-firm-dialog-add-domain ref="addDomainDialog" :form="form('firm_domains').value" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { orderBy } from 'lodash';
	import { ElementTools } from '@/utils';

	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppFirmDialogAddDomain from '../dialog/add-domain';

	export default {
		name: 'app-firm-members-list',

		components: { CommonStructureSection, CommonDialogConfirm, AppFirmDialogAddDomain },

		props: {},

		data() {
			return {
				ots: [],
				loading: {},
				formData: {},
				currentPage: 1,
				itemsPerPage: 10,
				pageCount: 0,
				organisationUserList: [],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				headers: [
					{ text: 'Name', align: 'left', value: 'name', class: 'text-no-wrap' },
					{ text: '', align: 'centre' }
				]
			};
		},

		computed: {
			...mapState('Account', ['organisation']),
			...mapState('AccountOrganisationDomain', ['organisationDomainList']),
			...mapGetters('CmsForm', ['form']),

			orderedDomains() {
				return orderBy(this.organisationDomainList, 'created', ['desc']) || [];
			}
		},

		created() {
			this.loadOrganisationDomainList();
			this.loadForm('firmDomains');
		},

		methods: {
			...mapActions('AccountOrganisationDomain', ['loadOrganisationDomainList', 'deleteOrganisationDomain']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name openDomainDialog
			 * @description Open add domain dialog
			 */
			openDomainDialog() {
				this.$refs.addDomainDialog.open();
			},

			/**
			 * @name deleteDomain
			 * @description Delete domain
			 * @param {Object} item
			 */
			async deleteDomain(item) {
				this.$refs.confirm.open('Delete Domain', `Are you sure you wish to delete ${item.domainAddress}?`).then(async() => {
					this.$set(this.loading, item.id, true);

					this.deleteOrganisationDomain(item.id)
						.then(() => ElementTools.fireNotification(this.$el, 'success', `${item.domainAddress} has been successfully deleted`))
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete domain'))
						.finally(() => {
							this.loadOrganisationDomainList();
							this.$set(this.loading, item.id, false);
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.shrunk {
		width: 1px;
		white-space: nowrap;
	}
</style>
