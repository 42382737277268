import { CmsEvent } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Event {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			eventList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			events: (state) => state.eventList,
			upcomingEvents: (state) => state.eventList.filter((event) => new Date(event.end) > new Date()),
			passedEvents: (state) => state.eventList.filter((event) => new Date(event.end) < new Date())
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setEventList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setEventList: (state, data) => (state.eventList = data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadEventsList
			 * @param {function} commit
			 */
			async loadEventsList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				let { data } = await CmsEvent.list({ showPassedEvents: true, where: { published: { c: 'IS NOT', v: null } }, limit: 1000, offset: 0 }, domain);
				commit('setEventList', data.data);
			}
		};
	}
}
