import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Course
 * @exports Service/Cms/Course
 * @description API Course Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Course {
	/**
	 * @static @name list
	 * @description Get all course as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post('cms/course/list', payload, {
			cache,
			cachePath: `table-cms-course-${domain}`
		});
	}
}
