<!--
	@name app-client-dashboard-workflow-conveyancing-activity
	@description Client Workflow Conveyancing Activity
	@date 2024/02/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="justify-center">
			<v-col cols="12" md="12">
				<!-- Button to display referral form -->
				<a v-if="showReferralForm" class="text-body-1 d-inline-block" @click="showReferralForm = !showReferralForm"> &laquo; Back to panel options </a>
				<a v-else-if="referrals.length && !showReferralForm" class="text-body-1 d-inline-block" @click="toggleNewReferralOverride"> &laquo; Back to referrals list </a>

				<div class="d-none">
					<p class="text-body-1 mb-6">
						Each specialist solicitor has their own tile which further provides you with two options of either clicking on "Instruct via Air," or "Find out More" icon.
					</p>
					<ul class="text-body-1 mb-7">
						<li class="mb-2">
							Clicking on "Find out More," icon within a specialist solicitor tile you will navigate directly to their individual website, where you can obtain
							further information, such as, pricing, services and testimonials.
						</li>
						<li class="mb-2">
							By clicking on "Instruct via Air," icon you will navigate directly to that chosen specialist solicitor's referral form which when completed and
							submitted will act as the trigger for you instructing the specialist solicitor to act on the customers behalf.
						</li>
					</ul>
				</div>
				<v-skeleton-loader v-if="!data('solicitors').value" type="list-item-three-line" />
				<div v-else-if="!showReferralForm">
					<v-row>
						<v-col v-for="(solicitor, index) in data('solicitors').value" :key="index" cols="12" md="6" lg="4">
							<v-card class="pa-6 elevation-3 v-card--solicitor" outlined tile style="position: relative">
								<v-img :src="solicitor.logo | urlize('organisation')" contain height="150" />
								<v-card-actions class="pa-10 d-flex flex-column justify-center">
									<v-btn
										v-for="(button, buttonIndex) in solicitor.buttons"
										:key="buttonIndex"
										class="ma-2"
										block
										:color="button.color"
										@click="handleFunction(button)"
									>
										{{ button.title }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</div>

				<div v-else-if="showReferralForm" id="conveyancingReferralForm">
					<app-client-dashboard-workflow-conveyancing-referral
						v-if="client"
						class="mt-6"
						:organisation-id="organisationId"
						:client-id="client.id"
						:association-id="association?.id"
						:asset-id="asset.id"
						:submitting="submitting"
						@submit="submit"
					/>
				</div>
			</v-col>
		</v-row>
		<app-client-dashboard-workflow-conveyancing-boyd-legal ref="boydlDialog" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import AppClientDashboardWorkflowConveyancingBoydLegal from '@/component/app/client/dashboard/workflow/conveyancing/boyd-legal';
	import AppClientDashboardWorkflowConveyancingReferral from '@/component/app/client/dashboard/workflow/conveyancing/referral';

	export default {
		name: 'app-client-dashboard-workflow-conveyancing-solicitors',

		components: {
			AppClientDashboardWorkflowConveyancingBoydLegal,
			AppClientDashboardWorkflowConveyancingReferral
		},

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			referrals: { type: Array, default: () => [] }
		},

		data() {
			return {
				showReferralForm: false,
				submitting: false,
				organisationId: null
			};
		},

		computed: {
			...mapGetters('CmsData', ['data'])
		},

		created() {
			this.loadData('solicitors');
		},

		methods: {
			...mapActions('CmsData', ['loadData']),
			...mapActions('AppConveyancing', ['submitConveyancing']),

			/**
			 * @name handleFunction
			 * @description handle solicitor button click
			 */
			handleFunction: function(btn) {
				if (btn.href) new PopupWindow().open(btn.href, '_blank');
				else if (btn.fnctn) {
					this.organisationId = btn.organisationId;
					this[btn.fnctn]();
				}
			},

			/**
			 * @name equilawAirInstruct
			 * @description show equilaw air instruct form
			 */
			equilawAirInstruct() {
				this.showReferralForm = true;

				this.$nextTick(() => {
					let element = document.getElementById('conveyancingReferralForm');
					element?.scrollIntoView({ behavior: 'smooth' });
				});
			},

			/**
			 * @name boydlDialog
			 * @description open dialog with boyd legal information
			 */
			boydlDialog() {
				this.$refs.boydlDialog.open();
			},

			submit(payload) {
				this.submitting = true;

				this.submitConveyancing(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Submitted Successfully');
						this.$emit('submittedNewReferral');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => (this.submitting = false));
			},

			toggleNewReferralOverride() {
				this.$emit('toggleNewReferralOverride');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.solicitor {
		flex: 0 0 50%;
		width: 50%;
	}

	.v-card--solicitor {
		.v-card__actions {
			opacity: 0;
			transition: 0.5s;
			background-color: rgba(255, 255, 255, 0.75);
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		&:hover {
			.v-card__actions {
				opacity: 1;
			}
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
