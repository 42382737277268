<!--
	@name app-client-dashboard-workflow-conveyancing-activity
	@description Client Workflow Conveyancing Activity
	@date 2024/02/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet>
			<v-row no-gutters class="justify-center">
				<v-col cols="12">
					<div class="pa-0 mb-6">
						<!-- Button to display referral form -->
						<v-btn color="primary" class="mb-0" @click="toggleNewReferralOverride">
							Create New Referral
						</v-btn>

						<!-- Referrals -->
						<div class="mt-6">
							<v-card v-for="(item, index) in referrals" :key="index" class="pa-5" :class="{ 'mt-5': index !== 0 }" max-width="5000">
								<div class="d-flex justify-space-between align-center pa-0">
									<div class="flex-grow-1">
										<p class="text-body-1 font-weight-bold mb-0">
											{{ item.property }}
										</p>
										<p class="text-body-2 d-inline-block warning--text ma-0">
											Case Ref: {{ item.reference || '--' }}
										</p>
									</div>
									<div class="text-body-1 text-right">
										<p class="ma-0">
											<span class="text-body-2 font-weight-bold">Status:</span> {{ item.status }}
										</p>
										<p class="text-body-2 d-inline-block grey--text ma-0">
											Instructed: {{ item.instructed | moment('MMM DD, YYYY HH:mm') }}
										</p>
									</div>
								</div>

								<v-timeline v-if="item.events.length" class="mt-3 pa-4 blue-grey lighten-5">
									<v-timeline-item v-for="(event, index) in item.events" :key="index" small class="py-3" :color="index % 2 ? 'blue darken-4' : 'purple darken-3'">
										<template #opposite>
											<span class="text-caption ma-0 d-none" :class="index % 2 ? 'blue--text text--darken-4 pl-6' : 'purple--text text--darken-3 pr-6'">
												{{ event.created | moment('HH:mm:ss') }}
											</span>
										</template>
										<p class="text-body-1 font-weight-medium ma-0" :class="{ 'text-right': index % 2 }">
											{{ event.title }}
										</p>
										<p class="text-body-2 ma-0 blue-grey--text text--lighten-1" :class="{ 'text-right': index % 2 }">
											{{ event.created | moment('MMM Do YYYY') }}
										</p>
									</v-timeline-item>
								</v-timeline>

								<v-alert v-else color="warning" text outlined class="text-center mt-4 mb-0">
									<p class="text-body-1 font-weight-medium mb-0">
										Awaiting Case Updates...
									</p>
									<p class="text-body-2 ma-0 mb-3">
										Case tracking events will appear in this section as and when they are completed. There are currently no updates for this case.
									</p>
									<v-icon x-large color="warning ">
										mdi-progress-clock
									</v-icon>
								</v-alert>
							</v-card>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-sheet>
	</div>
</template>

<script>
	export default {
		name: 'app-client-dashboard-workflow-conveyancing-activity',

		props: {
			referrals: { type: Array, required: true }
		},

		methods: {
			toggleNewReferralOverride() {
				this.$emit('toggleNewReferralOverride');
			}
		}
	};
</script>
