import { AccountSecurityQuestion } from '@/service';

/**
 * @class @name SecurityQuestion
 * @description Exposes menu store module
 */
export default class SecurityQuestion {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			securityQuestionList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setSecurityQuestion
			 * @description used to set securityQuestion on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setSecurityQuestion: (state, data) => {
				state.securityQuestionList = data;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadSecurityQuestionList
			 * @description used to fetch app securityQuestion
			 * @param {function} commit
			 */
			async loadSecurityQuestionList({ commit }) {
				let { data } = await AccountSecurityQuestion.list();
				commit('setSecurityQuestion', data);
			}
		};
	}
}
