<!--
	@name app-dashboard-tab-view
	@description Tab view portion of the dashboard page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" @mouseenter="mouseEnter">
		<div>
			<v-tabs id="tabs" v-model="tab" :background-color="backgroundColour" :color="colour" class="elevation-2 mt-6" :dark="dark" :grow="grow" :fixed-tabs="fixed">
				<v-tabs-slider color="accent" />

				<v-tab v-for="(item, index) in filteredTabs" :key="item.title" :to="item.path" :value="index">
					<v-badge color="red accent-3" :value="item.alerts" right offset-x="-2" offset-y="10" :content="item.alerts">
						{{ item.title }}
					</v-badge>
				</v-tab>
			</v-tabs>

			<div v-if="!loading">
				<v-sheet v-if="routerView" class="no-top-radius">
					<transition name="fade" mode="out-in">
						<router-view v-on="$listeners" />
					</transition>
				</v-sheet>

				<v-tabs-items v-else v-model="tab">
					<v-tab-item v-for="item in tabs" :key="item.name">
						<slot :name="[item.name]" />
					</v-tab-item>
				</v-tabs-items>
			</div>
			<v-card v-else class="flex-grow-1">
				<v-skeleton-loader type="list-item-three-line" />
			</v-card>
		</div>
	</div>
</template>

<script>
	import { findIndex } from 'lodash';
	import { mapGetters } from 'vuex';

	export default {
		name: 'common-tab-view',

		props: {
			tabs: { type: Array, required: true },
			dark: { type: Boolean, default: false },
			grow: { type: Boolean, default: false },
			fixed: { type: Boolean, default: true },
			routerView: { type: Boolean, default: false },
			autoSwitch: { type: Boolean, default: false },
			autoSwitchInterval: { type: Number, default: 10000 },
			loading: { type: Boolean, default: false }
		},

		data() {
			return {
				tab: null
			};
		},

		computed: {
			...mapGetters('CmsSite', ['restrictedRoutes']),

			backgroundColour() {
				return this.dark ? 'blue-grey lighten-2' : 'blue-grey lighten-4';
			},

			colour() {
				return this.dark ? 'white' : 'black';
			},

			filteredTabs() {
				return this.tabs.filter((tab) => !this.restrictedRoutes.includes(tab.name));
			}
		},

		mounted() {
			if (this.autoSwitch) this.switchBetweenTabs();
		},

		destroyed() {
			if (this.autoSwitch) this.clearTabsInterval();
		},

		methods: {
			/**
			 * @name switchBetweenTabs
			 * @description Creates an interval to switch between tabs automatically
			 */
			switchBetweenTabs() {
				if (this.interval) return;

				this.interval = setInterval(() => {
					let activeTabIndex = findIndex(this.tabs, (item) => item.path === this.tab);
					let route = activeTabIndex === this.tabs.length - 1 ? this.tabs[0].path : this.tabs[activeTabIndex + 1].path;
					this.$router.push(route);
				}, this.autoSwitchInterval);
			},

			/**
			 * @name mouseEnter
			 * @description Mouse enter event for tabs component
			 */
			mouseEnter() {
				this.clearTabsInterval();
			},

			/**
			 * @name clearTabsInterval
			 * @description Clears interval for auto switch between tabs
			 */
			clearTabsInterval() {
				clearInterval(this.interval);
				this.interval = undefined;
			},

			/**
			 * @name switchToTab
			 * @description Switch to selected tab
			 */
			switchToTab(index) {
				this.tab = index;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.25s ease;
	}

	.fade-leave-active {
		opacity: 0;
	}
</style>
