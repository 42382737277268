<!--
	@name app-reward-earn
	@description Rewards page detail
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section>
			<template #body>
				<div v-if="!rewardCreditList">
					<v-row>
						<v-col v-for="index in 3" :key="index" cols="12" sm="4">
							<v-skeleton-loader height="75" type="image" />
						</v-col>
					</v-row>
				</div>
				<div v-else>
					<v-row class="px-2">
						<v-card v-for="(item, i) in rewardCreditList" :key="i" class="flex-grow-1 ma-3 card--design1" width="300" height="100%">
							<v-card-title class="text-body-1 pb-1">
								{{ item.name }}
							</v-card-title>
							<v-card-text>
								<p class="text-body-2 pb-0 mb-0 text-no-wrap text-truncate">
									{{ item.amount | numFormat }} {{ item.type === 'B' ? ' Bonus' : '' }} Points
								</p>
							</v-card-text>
						</v-card>
					</v-row>
				</div>
			</template>
		</common-structure-section>
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import { sortBy } from 'lodash';

	export default {
		name: 'app-reward-earn',

		components: { CommonStructureSection },

		inject: ['$rewardCreditList'],

		data() {
			return {
				colours: ['green', 'blue darken-4', 'yellow darken-2', 'purple darken-2', 'green darken-3']
			};
		},

		computed: {
			/**
			 * @name rewardCreditList
			 * @description order reward items with completions first ordered by tier ascending, then everything else by points ascending
			 */
			rewardCreditList() {
				let items = this.$rewardCreditList();
				if(!items) return null;

				const tiers = ['advance', 'platinum', 'elite', 'select'];
				let ordered = sortBy(items.data, [function(item) {
					let name = item.name.toLowerCase();
					if(name.includes('completion')) {
						let tier = name.split(" ").pop();
						return tiers.indexOf(tier);
					}
					return item.amount;
				}]);
				return ordered;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.v-card__title {
		word-break: normal;
	}
</style>
