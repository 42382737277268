<!--
	@name common-base-activity
	@description Common Activity Component
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section>
			<template v-if="!hideHeader" #header>
				Latest Activity
			</template>
			<template #body>
				<slot name="copy" />
				<v-fade-transition hide-on-leave>
					<v-skeleton-loader v-if="!activityList" type="article" class="px-0" />
					<div v-else-if="activityList.length" class="overflow-y-auto" :style="`max-height: ${maxHeight}`">
						<v-timeline dense class="mr-6 py-3" :class="{ small: !avatar }">
							<v-slide-x-reverse-transition group hide-on-leave>
								<v-timeline-item
									v-for="(item, index) in activityList"
									:key="index"
									small
									fill-dot
									class="pb-0 remove-dot"
									:class="{ 'pt-6': index !== 0 }"
								>
									<template #icon>
										<img class="avatar--image " :src="item.icon | urlize('icon')" :style="`max-height: ${avatar ? '50px' : '50px'}; max-width: ${avatar ? '50px' : '50px'};`" />
									</template>

									<v-card
										:hover="item.actions?.length === 1 ? true : false"
										:color="noteDisplay === 'note' ? '#FFF3B0' : ''"
										:class="noteDisplay === 'note' ? 'elevation-0 pa-1 note-background' : index % 2 === 0 ? 'grey lighten-3' : 'grey lighten-3'"
										@click="item.actions?.length === 1 ? applyAction(item.actions[0]) : null"
									>
										<div class="d-flex flex-column flex-lg-row">
											<div class="flex-grow-1">
												<v-card-title v-if="noteDisplay !== 'note'" class="text-body-1 font-weight-bold mb-0 py-3 pb-2" v-text="item.title" />
												<v-card-title v-else class="text-body-1 mb-0 py-3 pb-2" v-text="item.description" />

												<v-card-text v-if="noteDisplay !== 'note' && (item.description || (item.chips && item.chips.length))" class="pt-0 mt-n3">
													<p v-if="item.description && 'redacted' in item.data && item.data.redacted" class="mt-2 mb-0 line-clamp" style="-webkit-line-clamp: 1;">
														{{ redact(item.description) }}
													</p>
													<p v-else-if="item.description" class="mt-2 mb-0 line-clamp" style="-webkit-line-clamp: 1;">
														{{ item.description }}
													</p>
													<div v-if="item.chips && item.chips.length" class="mt-2 mx-n1 mb-n1 s">
														<v-tooltip v-for="(chip, chipIndex) in item.chips" :key="chipIndex" bottom>
															<template #activator="{ on }">
																<v-chip outlined small label dark color="secondary" class="py-2 ma-1" v-on="on" v-text="chip" />
															</template>
															<span>{{ chip }}</span>
														</v-tooltip>
													</div>
												</v-card-text>
											</div>

											<v-card-actions class="flex-grow-0 flex-shrink-1 pa-4 pt-0 pt-lg-4">
												<v-list-item-action class="flex-row flex-grow-1 ma-0 flex-lg-column align-self-lg-start fill-height">
													<v-list-item-action-text class="nowrap">
														{{ item.created | moment('from', 'now') }}
													</v-list-item-action-text>
													<div v-if="isDeleteNoteAction(item)" class="mt-2">
														<v-btn small color="error" icon :loading="deletingNote[item.id]" @click="deleteClientNote(item)">
															<v-icon>mdi-trash-can</v-icon>
														</v-btn>
													</div>

													<v-menu v-else-if="item.actions && item.actions?.length" offset-y open-on-hover transition="slide-y-transition" left>
														<template #activator="{ on }">
															<v-btn class="mt-lg-3" color="secondary" small v-on="on">
																<v-icon>mdi-dots-horizontal</v-icon>
															</v-btn>
														</template>
														<v-list dense class="py-0">
															<v-list-item v-for="(action, index) in item.actions" :key="index" link @click="applyAction(action)">
																<v-list-item-icon>
																	<v-icon :color="action.icon.colour">
																		{{ action.icon.style }}
																	</v-icon>
																</v-list-item-icon>
																<v-list-item-title>{{ action.title }}</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</v-list-item-action>
											</v-card-actions>
										</div>
									</v-card>
								</v-timeline-item>
							</v-slide-x-reverse-transition>
						</v-timeline>
					</div>
					<div v-else>
						<v-timeline dense class="small py-5">
							<v-timeline-item small fill-dot class="py-2">
								<template #icon>
									<img :src="'binoculars.png' | urlize('icon')" width="35px" />
								</template>

								<v-card class="elevation-2 grey lighten-5">
									<div class="d-flex flex-column flex-lg-row">
										<div class="flex-grow-1">
											<v-card-title class="text-body-1 font-weight-bold mb-0 py-3" v-text="'Nothing to see here…YET!'" />
											<v-card-text class="pt-0 mt-n3">
												<p class="mt-2 mb-0">
													However, once you set up your first Client you'll start to see activity appear here. This section presents a snapshot of the
													latest activity against your entire client bank - ensuring you stay informed at all times.
												</p>
											</v-card-text>
										</div>
									</div>
								</v-card>
							</v-timeline-item>
						</v-timeline>
					</div>
				</v-fade-transition>
			</template>
		</common-structure-section>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	const COLOURS = [
		'rgba(27, 120, 216, 0.75)',
		'rgba(23, 184, 144, 0.75)',
		'rgba(234, 99, 140, 0.75)',
		'rgba(30, 176, 225, 0.75)',
		'rgba(103, 0, 86, 0.75)'
	];

	import { mapActions } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'common-base-activity',

		components: { CommonStructureSection, CommonDialogConfirm },

		props: {
			hideHeader: { type: Boolean, default: false },
			avatar: { type: Boolean, required: false },
			maxHeight: { type: String, default: '500px' },
			id: { type: String, default: null },
			activityList: { type: Array, default: null },
			noteDisplay: { type: String, default: 'default' }
		},

		data() {
			return {
				deletingNote: {}
			};
		},

		methods: {
			...mapActions('LogActivityUser', ['deleteActivityUser']),

			redact(str) {
				str = str.trim();
				if (str.length < 2) return str + '**';
				return str[0] + '*'.repeat(str.length > 2 ? str.length - 2 : 2) + '*';
			},

			/**
			 * @name applyAction
			 * @description execute correct action
			 * @param action item action
			 * @param itemIndex index of item that needs updating
			 */
			async applyAction(action) {
				if (action.path) this.$router.push(action.path);
				if (action?.function?.name && typeof this[action.function.name] === 'function') this[action.function.name](action?.function?.params);
			},

			isDeleteNoteAction(item) {
				return item.actions?.length === 1 && item.actions[0].function?.name === 'deleteClientNote';
			},

			/**
			 * @name deleteClientNote
			 * @description delete client note
			 * @param obj note object
			 */
			async deleteClientNote(obj) {
				this.$refs.confirm
					.open('Delete Note', `Are you sure you wish to delete this note?`, {
						app_bar_color: 'warning',
						system_bar_color: 'warning darken-2'
					})
					.then(async() => {
						this.$set(this.deletingNote, obj.id, true);
						try {
							await this.deleteActivityUser(obj.id);
							this.$emit('note-deleted');
						} catch (e) {
							console.log(e);
						} finally {
							this.$set(this.deletingNote, obj.id, false);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			},

			/**
			 * @name colourClass
			 * @description TODO
			 * @param {Number} key TODO
			 * @return {String} Classes for element
			 */
			 colourClass(key) {
				return COLOURS[key % COLOURS.length];
			}
		}
	};
</script>

<style scoped lang="scss">
	.v-list-item__content > * {
		line-height: 1.2rem;
	}

	.v-timeline.small {
		&.v-timeline--dense:not(.v-timeline--reverse):before {
			left: calc(30px - 1px);
		}

		&.v-timeline--dense ::v-deep .v-timeline-item__body {
			max-width: calc(100% - 60px);
		}

		::v-deep .v-timeline-item__divider {
			min-width: 60px;
			margin-right: 8px;
		}
	}

	.v-card__title {
		word-break: normal;
	}

	.note-background {
		background: linear-gradient(150deg, #fff3b0 0%, #f6e27f 60%);
	}

	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			//padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.avatar--image {
		border-radius: 0 !important;
		mix-blend-mode: lighten;
	}
</style>
