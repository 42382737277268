import { EventBus } from '@/utils';
import { CmsCourse } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Course {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			courseItems: null
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setCourseList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setCourseList: (state, data) => (state.courseItems = data.data.data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 *  @name loadCourseList
			 * @param {function} commit
			 */
			async loadCourseList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				let courseItems =  await CmsCourse.list({ order: { property: 'position', direction: 'asc' } }, domain);
				commit('setCourseList', courseItems);
				EventBus.$emit('course-loaded');
				return courseItems;
			}
		};
	}
}
