<!--
	@name app-insights-hub
	@description Insights Hub page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Insights Hub
					</div>
				</div>
			</v-container>
		</v-img>

		<v-container>
			<!-- Carousel -->
			<v-sheet elevation="4" class="mt-4">
				<v-carousel hide-delimiter-background continuous light :show-arrows="$vuetify.breakpoint.mdAndUp" :height="$vuetify.breakpoint.mdAndUp ? 400 : 'auto'" class="">
					<v-carousel-item>
						<v-container fill-height class="pb-10">
							<v-row class="fill-height">
								<v-col cols="12" md="6" class="pt-0">
									<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/img_air_marketing_hero_1800x700.jpg" width="100%" height="100%" alt="" cover />
								</v-col>
								<v-col cols="12" md="6">
									<div>
										<div class="text-body-2">
											Featured Article
										</div>
										<div class="text-h5 py-2">
											How will the latest BOE base rate rise impact later life lending? Air asks the experts
										</div>
										<div class="text-body-1">
											With the Bank of England (BOE) increasing rates to 5% – the 13th consecutive rise
										</div>
										<v-btn color="info" class="mt-6">
											Read More
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-carousel-item>
					<v-carousel-item>
						<v-container fill-height class="pb-10">
							<v-row class="fill-height">
								<v-col cols="12" md="6" class="pt-0">
									<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/Altz.jpg" width="100%" height="100%" alt="" cover />
								</v-col>
								<v-col cols="12" md="6">
									<div>
										<div class="text-body-2">
											Featured Article
										</div>
										<div class="text-h5 py-2">
											Air Sourcing makes charity donation to Alzheimer's Society to mark World Alzheimer's Day after reaching 1 million milestone in Summer
											KFI Campaign
										</div>
										<div class="text-body-1">
											21 September 2023 – Air, the leading later life lending platform, has announced that it
										</div>
										<v-btn color="info" class="mt-6">
											Read More
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-carousel-item>
					<v-carousel-item>
						<v-container fill-height class="pb-10">
							<v-row class="fill-height">
								<v-col cols="12" md="6" class="pt-0">
									<v-img src="https://airlaterlife.co.uk/app/uploads/2023/09/hero_navigator-screen-mockup.jpeg" width="100%" height="100%" alt="" cover />
								</v-col>
								<v-col cols="12" md="6">
									<div>
										<div class="text-body-2">
											Featured Article
										</div>
										<div class="text-h5 py-2">
											Looking for support in discussing affordability with your clients?
										</div>
										<div class="text-body-1">
											At Air, we have listened to our members, on the areas that they need support
										</div>
										<v-btn color="info" class="mt-6">
											Read More
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-carousel-item>
				</v-carousel>
			</v-sheet>
		</v-container>

		<!-- About -->
		<div>
			<v-container>
				<div class="text-center d-flex flex-column align-center pt-12">
					<p class="text-h6" style="max-width: 800px">
						As the platform for later life lending professionals it is our mission to keep you abreast of what's happening in our industry, as it happens. Through our
						Insights hub you can explore our practical resources from the latest trends in the industry and products, to tips on talking equity release with your
						clients - so you can create opportunities for your business and keep up to date with an ever evolving market.
					</p>
				</div>
			</v-container>
		</div>

		<!-- Categories -->
		<div class="pb-8">
			<v-container>
				<v-row>
					<v-col md="4" sm="12" xs="12" class="mt-4">
						<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
							<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/Ambassador_news.jpg" min-height="200px" max-height="200px" />

							<div class="ma-6 text-h4 text-center">
								Air News
							</div>

							<v-spacer />
							<div class="text-center">
								<v-btn color="info" class="mb-4">
									See Articles
								</v-btn>
							</div>
						</v-card>
					</v-col>
					<v-col md="4" sm="12" xs="12" class="mt-4">
						<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
							<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/Later-life_news.jpg" min-height="200px" max-height="200px" />

							<div class="ma-6 text-h4 text-center">
								Client Lead Generation
							</div>

							<v-spacer />
							<div class="text-center">
								<v-btn color="info" class="mb-4">
									View Content
								</v-btn>
							</div>
						</v-card>
					</v-col>
					<v-col md="4" sm="12" xs="12" class="mt-4">
						<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
							<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/Marketing_news.jpg" min-height="200px" max-height="200px" />

							<div class="ma-6 text-h4 text-center">
								Air News
							</div>

							<v-spacer />
							<div class="text-center">
								<v-btn color="info" class="mb-4">
									See Articles
								</v-btn>
							</div>
						</v-card>
					</v-col>
					<v-col md="4" sm="12" xs="12" class="mt-4">
						<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
							<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/Club_news.jpg" min-height="200px" max-height="200px" />

							<div class="ma-6 text-h4 text-center">
								Market Trends
							</div>

							<v-spacer />
							<div class="text-center">
								<v-btn color="info" class="mb-4">
									See Articles
								</v-btn>
							</div>
						</v-card>
					</v-col>
					<v-col md="4" sm="12" xs="12" class="mt-4">
						<v-card class="d-flex flex-column fill-height mx-auto" max-width="350">
							<v-img src="https://airlaterlife.co.uk/app/uploads/2022/06/Ambassador_news.jpg" min-height="200px" max-height="200px" />

							<div class="ma-6 text-h4 text-center">
								Product News
							</div>

							<v-spacer />
							<div class="text-center">
								<v-btn color="info" class="mb-4">
									See Articles
								</v-btn>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-insights-hub',

		components: {},

		data() {
			return {};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>
