import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Client
 * @exports Service/Client/Aggregated
 * @description API Client Aggregated Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Aggregated {
	/**
	 * @static @name list
	 * @description Get all clients with associated clients
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('client/aggregated/list', payload);
	}
}
