<!--
	@name app-common-terms
	@description Terms
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :show-close="showClose">
		<template v-if="accept" #footer>
			<div class="pa-5 white white font-weight-medium">
				<v-checkbox inset class="ma-0" hide-details label="I accept the Terms and Conditions" @change="accepted" />
			</div>
		</template>
		<template #header>
			Terms and Conditions <span class="version">version 17 September 2024</span>
		</template>
		<template #body>
			<component :is="terms" />
		</template>
	</common-dialog>
</template>

<script>
	import { EventBus } from '@/utils';
	import BusinessOwnerTerms from './business-owner';
	import CommonDialog from '@/component/common/dialog';
	import DefaultTerms from './default';

	const DEFAULT = 'default-terms';

	export default {
		name: 'app-common-terms',

		components: { BusinessOwnerTerms, CommonDialog, DefaultTerms },

		props: {
			accept: { type: Boolean, default: false },
			showClose: { type: Boolean, default: true }
		},

		computed: {
			// TODO store
			// ...mapState('App', ['account']),
			account() {
				return { firmMemberType: 0 };
			},

			terms() {
				return DEFAULT;
				// return this.account.firmMemberType === 1 ? 'business-owner-terms' : DEFAULT;
			}
		},

		created() {
			EventBus.$on('common-dialog-closed', () => this.termsClosed());
		},

		methods: {
			/**
			 * @name close
			 * @description Close dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name termsClosed
			 * @description Terms and Conditions dialog closed
			 */
			termsClosed() {
				this.$emit('terms-closed', true);
			},

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			},

			/**
			 * @name accepted
			 * @description Checkbox changed event handler
			 */
			accepted() {
				this.$emit('terms-accepted', true);
			}
		}
	};
</script>

<style lang="scss" scoped>
	ul {
		padding: 0;
		margin: 0 0 16px 16px;
	}
	.version {
		font-size: 0.8rem;
	}
</style>
