import { kebabCase } from 'lodash';
import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Configuration
 * @exports Service/Cms/Content
 * @description API CMS Content Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Content {
	/**
	 * @static @name get
	 * @description Get Content
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(name, domain, cache = true) {
		return $http.get(`cms/content/${name}`, {
			cache,
			cachePath: `content-${kebabCase(name)}-${domain}`
		});
	}
}
