import Vue from 'vue';

/**
 * @namespace Utils
 * @class EventBus
 * @exports Utils/EventBus
 * @description Util for housing all system based events
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
class EventBus {
	constructor() {
		return new Vue();
	}
}

export default new EventBus();
