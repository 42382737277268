import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Product
 * @exports Service/Product/HrpRequest
 * @description API Product Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class ProductHrpRequest {
	/**
	 * @static @name post
	 * @description Add a new hrp request
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`product/hrp-request`, payload);
	}
}
