<!--
	@name app-source-result-dialog-filter-confirm-close
	@description Confirm close filters
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :show-close="false" persistent>
		<template #header>
			Before you start...
		</template>

		<template #body>
			<div>
				<p class="text-subtitle-1 mb-0">
					Would you like to view product results as they appeared on {{ $moment(created).format('MMM Do YYYY, HH:mm') }}, or create a new session based on real-time
					product data?
				</p>
			</div>
		</template>

		<template #footer>
			<div class="pa-4">
				<v-btn color="success" class="mr-4" @click="archive">
					view archived results
				</v-btn>
				<v-btn color="primary" class="mr-4" @click="create">
					create new session
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-filter-confirm-close',

		components: { CommonDialog },

		props: {
			created: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				resolve: null
			};
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open(resolve) {
				this.resolve = resolve;
				this.$refs.dialog.open();
			},

			/**
			 * @name archive
			 * @description view in archive mode
			 */
			archive() {
				this.$refs.dialog.close();
				this.resolve(true);
			},

			/**
			 * @name create
			 * @description create a new session
			 */
			create() {
				this.$refs.dialog.close();
				this.resolve(false);
			}
		}
	};
</script>
