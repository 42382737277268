<!--
	@name app-dashboard-tab-view-activity
	@description Activity tab view for the dashboard page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-base-activity :activity-list="activityList" max-height="750px" avatar hide-header>
			<template #copy>
				<p class="text-body-1">
					This section provides you with a summary of the latest 10 actions to have occurred within your Air Sourcing account. Examples of such actions could include Case
					Updates, Air Notifications, Latest Promotions and Client Interactions.
				</p>
			</template>
		</common-base-activity>
	</div>
</template>

<script>
	import CommonBaseActivity from '@/component/common/base/activity';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-dashboard-tab-view-activity',
		components: { CommonBaseActivity },
		data() {
			return {
				activityList: null
			};
		},

		created() {
			this.loadActivities();
		},

		methods: {
			...mapActions('LogActivityUser', ['loadActivityList', 'addActivityUser', 'deleteActivityUser']),

			loadActivities() {
				this.loadActivityList({ mappingOptions: { showAccessClient: true } }).then((activityList) => (this.activityList = activityList));
			}
		}
	};
</script>
