import { PartnerKnowledgeBank } from '@/service';

/**
 * @class @name KnowledgeBank
 */
export default class KnowledgeBank {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 * @param {function} commit
	 * @param {object} payload
	 */
	static get actions() {
		return {
			// eslint-disable-next-line no-unused-vars
			async searchCriteria({ commit }, payload) {
				let response = await PartnerKnowledgeBank.searchCriteria(payload);
				if (!response.error) return response.data.criteria;
				return false;
			}
		};
	}
}
