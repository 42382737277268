<!--
	@name app-source-result-mortgage-item-action
	@description Lifetime mortgage product item action
	2/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="d-flex flex-no-wrap justify-center" :component="$options.name">
		<div class="d-flex align-center px-1">
			<v-menu offset-y open-on-hover transition="slide-y-transition" left>
				<template #activator="{ on }">
					<v-btn color="secondary" small v-on="on">
						more
					</v-btn>
				</template>
				<v-list dense class="py-0">
					<v-list-item
						v-for="(pf, index) in productFunctions(item)"
						v-show="(!pf.hideCondition || !item[pf.hideCondition.attr]) && (!pf.showCondition || item[pf.showCondition.attr])"
						:key="index"
						:disabled="isArchive && pf.archiveDisabled"
						@click="productFunction(item, pf.fn)"
					>
						<v-list-item-icon>
							<v-icon :color="pf.iconColour" v-text="pf.icon" />
						</v-list-item-icon>
						<v-list-item-title>{{ pf.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<div v-if="!item.restriction && !isArchive" class="d-flex align-center px-1">
			<v-tooltip v-if="isBlocked" left max-width="300">
				<template #activator="{ on }">
					<v-icon color="grey darken-1" class="mx-1" v-on="on">
						mdi-block-helper
					</v-icon>
				</template>
				<span>Actions including KFI Request and Restrict Product are not available in Quick Source. To see a full list of features, click Proceed to full sourcing
					session</span>
			</v-tooltip>
			<v-switch v-else :input-value="inputValue(item)" class="ma-0 pa-0 mr-n3 pr-0" color="success" hide-details inset @change="switchChangeHandler($event, item)" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-source-result-mortgage-item-action',

		props: {
			item: { type: Object, required: true },
			isQuickQuote: { type: Boolean, default: false },
			selectedProductList: { type: Array, required: true }
		},

		data() {
			return {
				selectedProductsModel: []
			};
		},

		computed: {
			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			},

			isBlocked() {
				return this.isQuickQuote && !this.item.availableActions?.createForecast;
			}
		},

		methods: {
			inputValue(item) {
				return this.selectedProductList.find((product) => product.id === item.id);
			},

			/**
			 * @name switchChangeHandler
			 * @description Product selection event handler
			 * @param {Boolean} value
			 * @param {Object} product
			 */
			switchChangeHandler(event, product) {
				this.$emit('update-selected-product-list', { product, event });
			},

			/**
			 * @name productFunction
			 * @description TODO
			 * @param {TODO} item TODO
			 * @param {TODO} fn TODO
			 */
			productFunction(item, fn) {
				this[fn](item);
			},

			/**
			 * @name selectProduct
			 * @description TODO
			 * @param {TODO} product TODO
			 */
			selectProduct(product, event = true) {
				this.$emit('update-selected-product-list', { product, event });
			},

			/**
			 * @name openProductCriteriaDialog
			 * @description TODO
			 * @param {TODO} item TODO
			 */
			openProductCriteriaDialog(item) {
				this.$emit('open-product-criteria-dialog', item);
			},

			/**
			 * @name openProductIssueDialog
			 * @description TODO
			 * @param {TODO} item TODO
			 */
			openProductIssueDialog(item) {
				this.$emit('open-product-issue-dialog', item);
			},

			/**
			 * @name openRestrictProductDialog
			 * @description TODO
			 * @param {TODO} item TODO
			 */
			openRestrictProductDialog(item) {
				this.$emit('open-restrict-product-dialog', item);
			},

			/**
			 * @name openBorrowingForecast
			 * @description TODO
			 * @param {TODO} product TODO
			 */
			openBorrowingForecast(product) {
				this.$emit('open-borrowing-forecast-dialog', product, false, false, 'product-actions');
			},

			productFunctions(item) {
				var functions = [
					{
						title: 'Request KFI',
						fn: 'selectProduct',
						icon: 'mdi-file-pdf-box',
						iconColour: 'blue',
						hideCondition: { attr: 'restriction' },
						archiveDisabled: true
					},
					{
						title: 'Product Criteria',
						fn: 'openProductCriteriaDialog',
						icon: 'mdi-magnify',
						iconColour: 'green',
						showCondition: { attr: 'id' }
					},
					{
						title: 'Report an issue',
						fn: 'openProductIssueDialog',
						icon: 'mdi-comment-alert',
						iconColour: 'warning',
						archiveDisabled: true
					},
					{
						title: 'Restrict Product',
						fn: 'openRestrictProductDialog',
						icon: 'mdi-close-circle',
						iconColour: 'error lighten-1',
						hideCondition: { attr: 'restriction' },
						archiveDisabled: true
					},
					{
						title: 'Create forecast',
						fn: 'openBorrowingForecast',
						icon: 'mdi-chart-timeline-variant-shimmer',
						iconColour: 'purple',
						hideCondition: { attr: 'restriction' },
						archiveDisabled: true
					}
				];

				// Certain actions not available in quick quoute
				if (this.isQuickQuote) functions = functions.filter((fnctn) => !['Request KFI', 'Restrict Product'].includes(fnctn.title));

				// Have any actions been suppressed
				if (!item.availableActions?.kfiRequest) functions = functions.filter((fnctn) => fnctn.title !== 'Request KFI');
				if (!item.availableActions?.createForecast) functions = functions.filter((fnctn) => fnctn.title !== 'Create forecast');

				return functions;
			}
		}
	};
</script>

<style scoped lang="scss">
	::v-deep .v-input--switch__track {
		color: rgba(0, 0, 0, 0.8) !important;
	}
</style>
