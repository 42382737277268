<!--
	@name app-contact
	@description Contact page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Advisor Helpdesk
					</div>
				</div>
			</v-container>
		</v-img>

		<!-- Contact -->
		<div :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<p class="text-body-1">
							Air is the next generation, industry-leading later life lending platform. Through technology, knowledge and people, we provide best-in-class digital
							sourcing tools, personal development services and incentives to reward financial professionals and aid business growth.
						</p>
						<ul class="text-body-1" style="list-style-type: none; padding-left: 0">
							<li class="py-2">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>A simple and effective end-to-end experience </span>
							</li>
							<li class="py-2 d-flex align-center">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>Access to exclusive later life lending products </span>
							</li>
							<li class="py-2 d-flex">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>LIBF-accredited, on-demand training modules to help you develop </span>
							</li>
							<li class="py-2 d-flex align-center">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>Better connect, promote, learn, monitor and manage your later life lending activities </span>
							</li>
							<li class="py-2 d-flex align-center">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>Everything you need to be secure, knowledgeable and compliant </span>
							</li>
							<li class="py-2 d-flex align-center">
								<v-icon color="secondary" size="24" class="mr-4">
									mdi-check
								</v-icon>
								<span>Unique and market-leading approach to recognising you for your loyalty and success </span>
							</li>
						</ul>
					</v-col>
					<v-col cols="12" md="6">
						<v-sheet class="secondary pa-12 white--text" rounded elevation="4">
							<div class="text-h4">
								Contact us directly
							</div>
							<v-row class="mt-4">
								<v-col cols="6" md="6" class="text-body-2">
									<div class="font-weight-bold">
										Address
									</div>
									<div>
										Southgate House <br />
										Southgate Street<br />
										Gloucester<br />
										Gloucestershire<br />
										United Kingdom<br />
										GL1 1UD
									</div>
								</v-col>
								<v-col cols="6" md="6" class="text-body-2">
									<div class="font-weight-bold">
										Phone Number
									</div>
									<a class="white--text" href="tel:08002945097">0800 294 5097</a>
								</v-col>
							</v-row>
							<div>
								<div class="font-weight-bold mt-4">
									General Air Club Enquiries
								</div>
								<a class="white--text" href="mailto:club.enquiries@airlaterlife.co.uk">club.enquiries@airlaterlife.co.uk</a>
							</div>
							<div>
								<div class="font-weight-bold mt-4">
									Air Sourcing &amp; Technical Issues
								</div>
								<a class="white--text" href="mailto:sourcing.enquiries@airlaterlife.co.uk">sourcing.enquiries@airlaterlife.co.uk</a>
							</div>
							<div>
								<div class="font-weight-bold mt-4">
									KFI Requests
								</div>
								<a class="white--text" href="mailto:KFI@airlaterlife.co.uk">club.enquiries@airlaterlife.co.uk</a>
							</div>
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-contact',

		components: {},

		data() {
			return {
				tools: [
					{
						name: 'Repayment Tool',
						description: "See how your clients' voluntary repayments and drawdowns could affect their loan and property value.",
						cta: 'Launch Tool',
						link: 'https://airlaterlife.co.uk/repayments-calculator'
					},
					{
						name: 'White Label Tool',
						description: 'Sign up or login to our White Label LTV Tool to create a bespoke tool in your company branding.',
						cta: 'Launch Tool',
						link: 'https://whitelabel.airlaterlife.co.uk'
					},
					{
						name: 'Affordability Tools',
						description: 'Register your interest for exclusive access to our affordability tools that could support your advice process.',
						cta: 'Register Interest',
						link: 'https://airlaterlife.co.uk/gain-exclusive-access-to-our-affordability-tools'
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>
