import { CmsSite } from '@/service';
import router from '@/router';

/**
 * @class @name Site
 * @description Exposes Site store module
 */
export default class Site {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			site: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			site: (state) => state.site,
			restrictedRoutes: (state) => state.site.restrictedRoutes || [],
			allowedPublicRoutes: (state) => state.site.allowedPublicRoutes || []
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setSite
			 * @description used to set app config
			 * @param {Object} state
			 * @param {Object} data
			 */
			setSite(state, data) {
				const { view, ...rest } = data;
				state.site = { ...view, ...rest };
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadSite
			 * @description used to fetch app config
			 * @param {function} commit
			 */
			async loadSite({ commit }) {
				let { data } = await CmsSite.get();
				if (data.maintenance && router.history.fullPath !== '/maintenance') router.push('/maintenance').catch(() => {});
				commit('setSite', data);
			},

			/**
			 * @async @name getMembership
			 * @param {String} type
			 */
			async getMembershipSite(context, type) {
				return await CmsSite.getMembership(type);
			}
		};
	}
}
