<!--
	@name app-source-full-source
	@description Sourcing Page
	@date 2020/02/27
	@license no license
	@copywrite Answers In Retirement Limited
-->
<template>
	<div :component="$options.name" class="d-flex mb-6">
		<common-structure-section class="flex-grow-1 elevation-2">
			<template #header>
				Products
			</template>

			<template #body>
				<div>
					<p class="mb-3 text-body-2">
						To assist you and your clients, please review all the product options. For a comprehensive understanding, you can find further information about equity
						release and later life lending products by
						<a class="ga--trigger ga--more-info-opened font-weight-bold" data-ga-id="product-option-information" @click="openDialog">clicking here</a>.
					</p>
					<div
						v-intersect="{
							handler: onHintIntersect,
							options: {
								threshold: [0, 0.2, 0.5, 1.0]
							}
						}"
						class="pa-0"
						fluid
						style="position: relative"
					>
						<dynamic-form ref="dynamicForm" :form-schema="formFromGroup('source', 'product')" :form-data="formData" :submit-button="false" />
						<v-fade-transition>
							<span v-if="displayProductOptionsHint" class="hint">
								In alignment with guidance from the Equity Release Council, Interest Serviced products are now active by default.
								<a class="ga--trigger ga--more-info-opened d-block pt-1 white--text text-decoration-underline" data-ga-id="erc-hint" @click="$refs.hint1.open">
									Click here for more info
								</a>
							</span>
						</v-fade-transition>
						<v-fade-transition>
							<span v-if="displayContributionHint" class="hint hint2">
								Enter an amount greater than £0 to see how even a small monthly repayment can dramatically reduce the overall cost of borrowing.
								<a
									class="ga--trigger ga--more-info-opened d-block pt-1 white--text text-decoration-underline"
									data-ga-id="repayment-amount-hint"
									@click="$refs.hint2.open"
								>
									Click here for more info
								</a>
							</span>
						</v-fade-transition>
					</div>
				</div>
			</template>
		</common-structure-section>

		<common-dialog ref="dialog">
			<template #header>
				Products
			</template>
			<template #body>
				<v-sheet>
					<h3 class="mb-1">
						Lifetime Mortgages with Voluntary Repayment:
					</h3>
					<p class="text-body-2 mb-0">
						When a customer takes out a lifetime mortgage, they retain full ownership of their home and any interest on the loan can either be paid monthly, on an
						ad-hoc basis or rolled up into the loan amount. The loan and any outstanding interest are repaid when the borrower dies or moves to long-term care, and for
						plans which adhere to Equity Release Council Standards cannot exceed the value of the property providing the terms and conditions have been adhered to.
					</p>
				</v-sheet>
				<v-sheet class="mt-6">
					<h3 class="mb-1">
						Lifetime Mortgages with Interest Serviced:
					</h3>
					<p class="text-body-2 mb-0">
						A lifetime mortgage under which the customer commits to a regular payment at the outset, which reflects all or part of the interest. Depending on the
						specific terms of the product, the payment may or may not generate preferential terms and also may or may not have commercial implications in the event the
						payment ceases, but in any event security of tenure is not dependent on maintenance of the payment.
					</p>
				</v-sheet>
				<v-sheet class="mt-6">
					<h3 class="mb-1">
						Lifetime Mortgages with Mandatory Payments:
					</h3>
					<p class="text-body-2 mb-0">
						A lifetime mortgage under which the customer commits to a regular payment at the outset, which reflects all or part of the interest to achieve a higher LTV
						at the outset than would otherwise be available. Whilst the product will provide security of tenure from the outset, this is dependent on maintenance of the
						agreed payment.
					</p>
				</v-sheet>
				<v-sheet class="mt-6">
					<h3 class="mb-1">
						Retirement Mortgages:
					</h3>
					<p class="text-body-2 mb-0">
						Only available on a main residence, a RIO is similar to a standard interest-only mortgage, however the loan is usually only paid off when the borrower dies,
						moves into long-term care or sells the property. Affordability is based on the ability to meet monthly interest payments and the property can be at risk if
						repayments are not met.
					</p>
				</v-sheet>
				<common-base-helpful-feedback :id="'product-options-information'" class="mt-6" />
			</template>
		</common-dialog>

		<common-dialog ref="hint1">
			<template #header>
				Equity Release Council Rules and Guidance
			</template>
			<template #body>
				<v-sheet>
					<p class="text-body-2">
						In alignment with guidance from the Equity Release Council, Interest Serviced products are now active by default.
					</p>
					<p class="text-body-2 pl-3 py-1 grey--text text--darken-2 font-italic mb-0" style="border-left: 1px solid #bbbbbb">
						Advisers should ensure they are having and evidencing specific conversations around the options available for interest served or capital repayment lifetime
						mortgage solutions to mitigate the impact of roll-up interest over a longer period of time. This is of particular relevance when advising younger borrowers
						(55 to 60).
						<span class="d-block mt-1 text-caption">
							<a href="https://www.equityreleasecouncil.com/about/standards/rules-and-guidance/" target="_blank">
								Equity Release Council Rules and Guidance - V12 Standards Part A
								<sup>
									<v-icon size="12" color="primary">mdi-open-in-new</v-icon>
								</sup>
							</a>
						</span>
					</p>
				</v-sheet>
			</template>
		</common-dialog>

		<common-dialog ref="hint2">
			<template #header>
				Benefits of making repayments
			</template>
			<template #body>
				<v-sheet>
					<p class="text-body-2 mb-3">
						If your client were to borrow £50,000 and make no repayments at all, after 20 years, the total cost of borrowing would be £182,222<sup>*</sup>. However, by
						making a monthly £67.50 payment, after 20 years, your client would owe £149,719 - with a total cost of borrowing, including payments, of £165,919.
						<span class="font-weight-bold">This means, by repaying £67.50 a month, your client and their beneficiaries could benefit from a £16,903 net interest saving.</span>
					</p>
					<p class="text-caption font-italic grey--text text--darken-2 plain mb-0">
						* Based on a fixed 6.5% MER
					</p>
				</v-sheet>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	const VOLUNTARY_PAYMENTS = 'fed7de7b-4c86-4e2c-b028-6534185f2b6d';
	const INTEREST_SERVICED = '9779385a-2a82-46d2-bbaa-6da1df69017d';
	const CAPITAL_AND_INTEREST = '4a38fc40-e285-4019-a486-03b623a65066';
	const FULL_INTEREST = '90649bf3-09be-45c4-aaf9-6266dbb4bfc7';
	const MANDATORY_PAYMENTS = '5f018d6a-4f09-45de-be42-3c140ad8317a';

	import { mapGetters } from 'vuex';
	import { debounce } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import CommonBaseHelpfulFeedback from '@/component/common/base/helpful-feedback';

	export default {
		name: 'app-source-form-product',

		components: {
			CommonStructureSection,
			CommonDialog,
			DynamicForm,
			CommonBaseHelpfulFeedback
		},

		props: {
			formData: { type: Object, required: true, default: () => ({}) }
		},

		data() {
			return {
				hint1IsIntersecting: false,
				hint2IsIntersecting: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form', 'formFromGroup']),

			displayProductOptionsHint() {
				return !this.$route.params.processId && this.formData.paymentOptions.includes(INTEREST_SERVICED) && this.hint1IsIntersecting;
			},

			displayContributionHint() {
				const includesAny = (arr, values) => values.some((v) => arr.includes(v));
				const paymentOptionsArray = [INTEREST_SERVICED, MANDATORY_PAYMENTS, 'RETIREMENT_MORTGAGES'];
				return this.hint2IsIntersecting && includesAny(this.formData.paymentOptions, paymentOptionsArray) && !Number(this.formData.maximumMonthlyRepaymentAmount);
			}
		},

		watch: {
			formData: {
				handler() {
					debounce((vm) => vm.validateForm(true), 500)(this);
				},
				deep: true,
				immediate: true
			}
		},

		methods: {
			/**
			 * @name openDialog
			 * @description Open dialog
			 */
			openDialog() {
				this.$refs.dialog.open();
			},

			/**
			 * @name onHintIntersect
			 * @description Check form intersection ratio to calculate hint visibility
			 */
			onHintIntersect(entries) {
				// More information about these options
				// is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
				this.hint1IsIntersecting = entries[0].intersectionRatio >= 0.2;
				this.hint2IsIntersecting = entries[0].intersectionRatio >= 0.5;
			},

			/**
			 * @name validateForm
			 * @description Validates item details form and sets result to store
			 */
			async validateForm(silent) {
				const isValid = await this.$refs.dynamicForm?.validate(false, silent);
				this.$emit('set-validation', { products: isValid });
			},

			/**
			 * @name setPaymentOptions
			 * @description Set initial payment options when loading an existing sourcing process
			 * @param {*} options Loan payment types
			 */
			setPaymentOptions(options = []) {
				this.formData.paymentOptions = [];
				if (options.includes(VOLUNTARY_PAYMENTS)) this.formData.paymentOptions.push(VOLUNTARY_PAYMENTS);
				if (options.includes(INTEREST_SERVICED)) this.formData.paymentOptions.push(INTEREST_SERVICED);
				if (options.includes(CAPITAL_AND_INTEREST) && options.includes(FULL_INTEREST)) this.formData.paymentOptions.push('RETIREMENT_MORTGAGES');
				if (options.includes(MANDATORY_PAYMENTS)) this.formData.paymentOptions.push(MANDATORY_PAYMENTS);
				// If empty, let's set our defaults here
				if (!this.formData.paymentOptions.length) this.formData.paymentOptions = [VOLUNTARY_PAYMENTS, INTEREST_SERVICED];
			},

			/**
			 * @name createProductsPayload
			 * @description create products payload to be sent in request, changes products types
			 * @param {*} products products form data
			 */
			createProductsPayload(products) {
				if (products.paymentOptions.includes('RETIREMENT_MORTGAGES')) {
						if (!products.paymentOptions.includes(CAPITAL_AND_INTEREST)) {
							products.paymentOptions.push(CAPITAL_AND_INTEREST);
						} else {
							const index = products.paymentOptions.indexOf(CAPITAL_AND_INTEREST);
							products.paymentOptions.splice(index, 1);
						}

						if (!products.paymentOptions.includes(FULL_INTEREST)) {
							products.paymentOptions.push(FULL_INTEREST);
						} else {
							const index = products.paymentOptions.indexOf(FULL_INTEREST);
							products.paymentOptions.splice(index, 1);
						}

						const index = products.paymentOptions.indexOf('RETIREMENT_MORTGAGES');
						products.paymentOptions.splice(index, 1);
					}
				return products;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/settings/_variables';

	@media #{map-get($display-breakpoints, 'lg-and-up')} {
		.hint {
			visibility: visible !important;
		}
	}

	.hint {
		visibility: hidden;
		position: absolute;
		right: 0;
		top: 20px;
		max-width: 350px;
		padding: 8px 12px;
		color: #ffffff;
		border-radius: 3px;
		background-color: rgba(0, 0, 0, 0.5);
		transform: translateX(100%) translateX(+50px);
	}

	.hint2 {
		top: 165px;
	}

	.fluid--button {
		height: 100% !important;
		min-height: 48px !important;
		white-space: normal !important;

		::v-deep .v-btn__content {
			flex: 1 1 auto !important;
		}
	}
</style>
