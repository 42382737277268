import { $http } from '@/utils';

/**
 * @namespace Service
 * @class UserAnalytics
 * @exports Service/Account/UserAnalytics
 * @description API User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserAnalytics {
	/**
	 * @static @name get
	 * @description Fetch some User analytics for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get() {
		return $http.get(`account/user/analytics`);
	}
}
