var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"component":_vm.$options.name}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredResources.length),expression:"filteredResources.length"}]},[_c('v-divider',{staticClass:"mt-10 mb-8",attrs:{"inset":""}}),_c('p',{staticClass:"text-body-1 font-weight-bold mb-2"},[_vm._v(" Resources ")]),_c('p',{staticClass:"text-body-2 mb-0"},[_vm._v(" The resources below have been produced by "),_c('strong',[_vm._v("Air Academy")]),_vm._v(" to provide a helping hand as you progress through the AirFlow journey. Each stage has it's own dedicated resources, specifcally designed to equip you with all the knowledge you need to maximise your use of the platform. "),(_vm.authStatus == 'error')?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" To access these resources, you'll first need to link your Air Academy account. If you have any questions on how to do this, please "),_c('a',{on:{"click":_vm.openChat}},[_vm._v("start a live chat")]),_vm._v(" or "),_c('a',{attrs:{"href":"mailto:Academy.enquiries@airlaterlife.co.uk"}},[_vm._v("click here")]),_vm._v(" to get in contact. ")]):_vm._e()]),(_vm.authStatus == 'error')?_c('v-btn',{staticClass:"mt-4 mb-0",attrs:{"light":"","small":"","color":"primary"},on:{"click":_vm.openAuthoriseDialog}},[_vm._v(" Connect my Air Academy Learning Platform ")]):_vm._e(),(_vm.authStatus == null)?[_c('p',{staticClass:"text-body-2 mt-4 mb-3"},[_vm._v(" Retrieving your LMS account details ")]),_c('v-progress-linear',{attrs:{"height":"24","indeterminate":""}})]:_c('v-row',{staticClass:"mt-4"},_vm._l((_vm.filteredResources),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('app-air-academy-training-webinar-item',{attrs:{"item":{
						name: item.name,
						image: item.image ? `${_vm.VUE_APP_S3_AIR_ASSETS_URL}/${item.image}` : `${_vm.VUE_APP_S3_AIR_ASSETS_URL}/placeholder/gradient.png`,
						mimetype: 'video/mp4',
						hideStatus: true,
						fixedHeight: true,
						url: item.url,
						disable: _vm.authStatus == 'error' ? 'You must first link your Air Academy account' : false
					}}}),_c('v-card',{staticClass:"d-none"},[_c('v-img',{attrs:{"src":_vm._f("urlize")((item.image ? item.image : 'placeholder/gradient.png')),"height":"120px"}}),_c('v-card-title',{staticClass:"pb-5",staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.description,150))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","href":item.url,"target":"_blank"}},[_vm._v(" View ")])],1)],1)],1)}),1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }