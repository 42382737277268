import { CmsProductUpdate } from '@/service';
import { filter } from 'lodash';
import moment from 'moment';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class ProductUpdate {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			latestProductChanges: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setProducts
			 * @param {Object} state
			 * @param {Object} data
			 */
			setLatestProductChanges(state, data) {
				state.latestProductChanges = data;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchProductChanges
			 * @param {function} commit
			 */
			async loadLatestProductChanges({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				const { data } = await CmsProductUpdate.list({ where: { 'product_change.last_application': { c: '>=', v: new Date().toISOString() } } }, domain);
				// Filter items so that cached product updates are only shown if they are after current point in time
				const productChangeData = filter(data.data, (o) => moment(String(o.lastApplication)).isSameOrAfter(moment(), 'days'));
				commit('setLatestProductChanges', productChangeData);
			}
		};
	}
}
