/**
 * @namespace Filters
 * @exports Filters/strip-html
 * @description Strip html tags from string
 * @date 2023/10/23
 * @license no license
 * @copyright AIR Later Life
 */

export default (string) => {
	const div = document.createElement('div');
	div.innerHTML = string.replace(/<\/p>/g, '</p> ');
	return div.textContent || div.innerText || '';
};