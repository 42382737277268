<!--
	@name app-layout-blank
	@description Blank page layout
	@date 2020/06/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<!-- content -->
		<v-main>
			<app />
		</v-main>
	</v-app>
</template>

<script>
	import App from '@/component/app';

	export default {
		name: 'app-layout-default-minimal',

		components: { App },

		computed: {}
	};
</script>

<style lang="scss" scoped>
	.theme--light.v-application {
		background: #fff !important;
	}
</style>
