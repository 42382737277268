<!--
	@name app-source-result-dialog-loading
	@description loading dialog
	@date 2020/08/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog v-model="model" persistent width="500">
		<v-toolbar dense color="blue-grey darken-4" dark>
			<v-toolbar-title> {{ title }} </v-toolbar-title>
		</v-toolbar>
		<v-card-text class="py-2 px-0 yellow lighten-4">
			<div class="pa-4">
				<v-progress-linear indeterminate color="black" />
			</div>
		</v-card-text>
	</v-dialog>
</template>

<script>
	export default {
		name: 'app-source-result-dialog-loading',

		data() {
			return {
				title: null,
				model: false
			};
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open(title) {
				this.title = title;
				this.model = true;
			},

			/**
			 * @name close
			 * @description Close the dialog
			 */
			close() {
				this.model = false;
			}
		}
	};
</script>
