<!--
	@name app-client-service-template
	@description Service Template
	@date 2020/07/16
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-dialog v-model="loading" persistent width="300">
			<v-card color="white">
				<v-card-text>
					Loading
					<v-progress-linear indeterminate color="black" class="mt-2 mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-if="active" v-model="active" fullscreen hide-overlay persistent transition="dialog-bottom-transition" scrollable :component="$options.name">
			<v-card tile>
				<v-toolbar flat dark color="secondary" class="pl-3 pr-3" max-height="64">
					<v-toolbar-title />
					<v-spacer />
					<v-toolbar-items>
						<v-btn icon dark @click="close()">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text class="pa-6">
					<slot name="header" />
					<v-row no-gutters class="justify-center">
						<v-col cols="12" md="8">
							<!-- Loading... -->
							<v-skeleton-loader v-if="!formSchema || refreshing" type="list-item-three-line" />

							<div v-else>
								<div v-if="referrals.length" class="pa-0 mb-6">
									<!-- Button to display referral form -->
									<v-btn v-show="!displayReferralForm" color="primary" class="mb-0" @click="displayReferralForm = true"> Create New Referral </v-btn>

									<!-- Button to hide referral form -->
									<v-btn v-show="displayReferralForm" color="secondary" outlined class="mb-0" @click="displayReferralForm = false">
										<v-icon left> mdi-arrow-left </v-icon>Back to Referral List
									</v-btn>

									<!-- Referrals -->
									<div v-show="!displayReferralForm" class="mt-6">
										<v-card v-for="(item, index) in orderedReferrals" :key="index" class="pa-5" :class="{ 'mt-5': index !== 0 }" max-width="5000">
											<div class="d-flex justify-space-between align-center pa-0">
												<div class="flex-grow-1">
													<p class="text-body-1 font-weight-bold mb-0">
														{{ item.property }}
													</p>
													<p class="text-body-2 d-inline-block warning--text ma-0">Case Ref: {{ item.reference || '--' }}</p>
												</div>
												<div class="text-body-1 text-right">
													<p class="ma-0"><span class="text-body-2 font-weight-bold">Status:</span> {{ item.status }}</p>
													<p class="text-body-2 d-inline-block grey--text ma-0">Instructed: {{ item.instructed | moment('MMM DD, YYYY HH:mm') }}</p>
												</div>
											</div>

											<v-timeline v-if="item.events.length" class="mt-3 pa-4 blue-grey lighten-5">
												<v-timeline-item
													v-for="(event, index) in item.events"
													:key="index"
													small
													class="py-3"
													:color="index % 2 ? 'blue darken-4' : 'purple darken-3'"
												>
													<template #opposite>
														<span
															class="text-caption ma-0 d-none"
															:class="index % 2 ? 'blue--text text--darken-4 pl-6' : 'purple--text text--darken-3 pr-6'"
														>
															{{ event.created | moment('HH:mm:ss') }}
														</span>
													</template>
													<p class="text-body-1 font-weight-medium ma-0" :class="{ 'text-right': index % 2 }">
														{{ event.title }}
													</p>
													<p class="text-body-2 ma-0 blue-grey--text text--lighten-1" :class="{ 'text-right': index % 2 }">
														{{ event.created | moment('MMM Do YYYY') }}
													</p>
												</v-timeline-item>
											</v-timeline>

											<v-alert v-else color="warning" text outlined class="text-center mt-4 mb-0">
												<p class="text-body-1 font-weight-medium mb-0">Awaiting Case Updates...</p>
												<p class="text-body-2 ma-0 mb-3">
													Case tracking events will appear in this section as and when they are completed. There are currently no updates for this case.
												</p>
												<v-icon x-large color="warning "> mdi-progress-clock </v-icon>
											</v-alert>
										</v-card>
									</div>
								</div>

								<!-- Only display our referral form is 0 referrals OR explicitly told to do so -->
								<div v-show="!referrals.length || displayReferralForm">
									<slot name="copy" />
									<common-structure-section v-if="hasReferral && formSchema" class="flex-grow-1 elevation-2">
										<template #header>
											<slot> Case Details </slot>
										</template>
										<template #body>
											<dynamic-form
												ref="dynamicForm"
												:form-schema="formSchema"
												:form-data="formData"
												:submit-button-loading="submitButtonLoading"
												@dynamic-form-submit="submit"
											/>
										</template>
									</common-structure-section>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { cloneDeep, orderBy } from 'lodash';
	import { ElementTools } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-client-service-will',

		components: { CommonStructureSection, DynamicForm },

		props: {
			hasReferral: { type: Boolean, required: false, default: true },
			showReferralForm: { type: Boolean, required: false, default: false }
		},

		data() {
			return {
				active: false,
				loading: false,
				refreshing: false,
				formSchema: [],
				initialFormData: {},
				formData: {},
				referrals: [],
				submitButtonLoading: false,
				displayReferralForm: this.showReferralForm
			};
		},

		computed: {
			orderedReferrals() {
				return orderBy(this.referrals, 'instructed', 'desc');
			}
		},

		methods: {
			load() {
				this.loading = true;
			},

			/**
			 * @name open
			 * @description Open the dialog, fetch initial data
			 */
			open(obj) {
				this.active = true;
				this.loading = false;
				this.refreshing = false;
				this.displayReferralForm = false;
				if (obj) {
					this.formSchema = obj.formSchema;
					this.initialFormData = obj.initialFormData;
					this.formData = cloneDeep(this.initialFormData);
					this.referrals = obj.referrals;
				}
			},

			/**
			 * @name open
			 * @description Open the dialog, fetch initial data
			 */
			refresh() {
				this.refreshing = true;
			},

			/**
			 * @name submit
			 * @description submit new will referral
			 */
			submit() {
				this.submitButtonLoading = true;
				this.$emit('submit', this.formData);
			},

			submitSuccess() {
				ElementTools.fireNotification(this.$el, 'success', 'Submitted Successfully');
				this.submitButtonLoading = false;
			},

			submitError() {
				ElementTools.fireNotification(this.$el, 'error', `An error occured`);
				this.submitButtonLoading = false;
			},

			/**
			 * @name close
			 * @description Close the dialog
			 */
			close() {
				this.active = false;
				this.$emit('closed');
				const query = Object.assign({}, this.$route.query);
				delete query.service;
				this.$router.replace({ query });
			}
		}
	};
</script>
