<!--
	@name app-firm-dialog-invite
	@description Show a dialog to invite a firm member
	@date 2020/06/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" :max-width="500">
		<template #header>
			Invite A Firm Member
		</template>
		<template #body>
			<p class="text-body-2 mb-2 font-weight-medium">
				Please enter the name and email address of an adviser you would like to invite to join your Firm account.
			</p>

			<dynamic-form
				ref="dynamicForm"
				:form-schema="form"
				:form-data="data"
				submit-button-text="Send Invite"
				:submit-button-loading="submitting"
				@dynamic-form-submit="invite"
			/>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools, EventBus } from '@/utils';

	import CommonDialog from '@/component/common/dialog';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-firm-dialog-invite',

		components: { CommonDialog, DynamicForm },

		props: {
			form: { type: Array, required: true, default: () => [] }
		},

		data: () => ({
			data: {},
			submitting: false
		}),

		computed: {
			...mapState('Account', ['user', 'organisation'])
		},

		methods: {
			...mapActions('AccountUserInvitation', ['addUserInvitation']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.data = {};
				this.$refs.dialog.open();
			},

			async invite() {
				const formData = {
					organisationId: this.organisation.id,
					data: { inviterUserId: this.user.id },
					registration: {
						identity: this.data.email,
						identityType: 'email',
						data: { name: this.data.name }
					}
				};
				this.submitting = true;

				this.addUserInvitation(formData)
					.then(() => {
						this.$refs.dialog.close();
						ElementTools.fireNotification(this.$el, 'success', `Invitation to join firm have been successfully sent to ${this.data.name}`);
						EventBus.$emit('user-invited');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `Failed to invite`))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
