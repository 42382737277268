<template>
	<div>
		<v-tooltip bottom>
			<template #activator="{ on }">
				<v-btn
					:class="buttonClass"
					:data-ga-id="dataGaId"
					:data-ga-value="dataGaValue"
					style="height: 100%"
					text
					:color="iconColor"
					v-on="on"
					@click="active = !active"
				>
					<v-icon :large="iconLarge" :left="!buttonTextHidden">
						mdi-frequently-asked-questions
					</v-icon>
					<template v-if="!buttonTextHidden">
						{{ buttonText }}
					</template>
				</v-btn>
			</template>
			<span>{{ title }}</span>
		</v-tooltip>

		<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable :component="$options.name" :retain-focus="false">
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-toolbar-title>
						{{ title }}
					</v-toolbar-title>
					<v-spacer />
					<v-btn icon dark @click="active = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-container fluid>
						<div v-for="(item, key) in items" :key="key">
							<v-divider v-if="key !== 0" class="mb-8" />
							<div class="d-flex" :class="item.type == 'text' ? 'mb-8' : 'mb-16'">
								<div v-if="item.type !== 'text'" class="hints__icon text-center">
									<div v-if="item.type === 'icon'">
										<v-icon x-large :color="item.iconColour">
											{{ item.icon }}
										</v-icon>
									</div>

									<div v-else-if="item.type === 'image'">
										<v-img max-width="100%" class="mr-6" :src="item.imagePath | urlize" />
									</div>

									<div v-else-if="item.type === 'button'">
										<v-btn :color="item.iconColour" :class="item.buttonClass" :fab="item.isFab || false" :small="item.isSmall || false">
											<v-icon>{{ item.icon }}</v-icon>
										</v-btn>
									</div>

									<div v-else-if="item.type === 'chip'">
										<v-chip :color="item.chipColour" :class="item.chipClass" label small active>
											<v-icon :size="item.iconSize" left :color="item.iconColor">
												{{ item.icon }}
											</v-icon>
											{{ item.iconText }}
										</v-chip>
									</div>

									<div v-else-if="item.type === 'dataTable'">
										<v-data-table :headers="item.data.headers" :items="item.data.rows" multi-sort hide-default-footer class="columnOrdering mr-6" />
									</div>

									<div v-else-if="item.type === 'switch'" class="d-inline-block my-auto mx-auto">
										<v-switch :class="item.class" :color="item.colour" hide-details inset />
									</div>

									<!-- Not Dynamic -->
									<div v-else-if="item.key === 'monthlyContributions'" class="px-4">
										<v-slider v-model="monthlyContributions" step="25" :min="0" :max="100" ticks="always" tick-size="4" hide-details />
										<p class="mb-0 text-subtitle-1 font-weight-bold" :class="monthlyContributionsTextClass">
											£{{ monthlyContributionsExample | numFormat('0,0.00') }}
										</p>
									</div>
									<div v-else-if="item.key === 'avivaRates'">
										<v-btn color="primary" icon small>
											<v-icon>mdi-calculator</v-icon>
										</v-btn>
										<v-btn color="primary darken-2" icon small>
											<v-icon>mdi-file-document-edit</v-icon>
										</v-btn>
									</div>
									<div v-else-if="item.key === 'productFeatures'">
										<v-chip class="mr-1 mt-1" color="info lighten-2" label x-small active>
											Interest Only (RIO)
										</v-chip>
										<v-chip class="mr-1 mt-1" color="info lighten-3" label x-small active>
											Income Assessed
										</v-chip>
									</div>
									<div v-else-if="item.key === 'outsideCriteriaProducts'" class="pr-6">
										<v-simple-table>
											<tbody>
												<tr class="restricted heading">
													<th colspan="100">
														<p class="pa-0 ma-0 text-body-2 font-weight-medium">
															OUTSIDE CRITERIA: ...
														</p>
													</th>
												</tr>
												<tr class="restricted">
													<th class="sized max">
														Product Name
													</th>
												</tr>
											</tbody>
										</v-simple-table>
									</div>
									<div v-else-if="item.key === 'productCount'">
										<v-toolbar class="mr-6" dense light>
											<v-toolbar-title class="font-weight-bold">
												<span class="success--text"> 300 Matching Products </span>
												<br />
												<span class="error--text"> 50 Outside Criteria </span>
											</v-toolbar-title>
										</v-toolbar>
									</div>
									<div v-else-if="item.key === 'procurationFees'">
										<v-chip class="mr-1 mt-1" color="#b26fac" text-color="white" label x-small active>
											Club Enhanced
										</v-chip>
										<v-chip class="mr-1 mt-1" color="#c8486a" text-color="white" label x-small active>
											Academy Enhanced
										</v-chip>
									</div>
								</div>

								<div class="glossary-item-text">
									<h2 class="text-subtitle-1 font-weight-black mb-3">
										<v-chip v-if="item.chip" small class="mr-1 mb-1" :class="item.chipClass">
											{{ item.chip }}
										</v-chip>
										{{ item.title }}
									</h2>
									<p class="text-subtitle-2 mb-0" v-html="$sanitize(item.description)" />
								</div>
							</div>
						</div>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'toolbar-glossary',

		props: {
			items: {
				type: Array,
				required: true,
				z: 'fab'
			},
			buttonClass: {
				type: String,
				required: false,
				default: 'faq-button'
			},
			buttonTextHidden: {
				type: Boolean,
				required: false,
				default: false
			},
			buttonText: {
				type: String,
				required: false,
				default: 'GLOSSARY'
			},
			title: {
				type: String,
				required: false,
				default: 'Glossary of Terms'
			},
			iconColor: {
				type: String,
				required: false,
				default: 'purple darken-2'
			},
			iconLarge: {
				type: Boolean,
				required: false,
				default: false
			},
			dataGaId: {
				type: String,
				required: false,
				default: null
			},
			dataGaValue: {
				type: String,
				required: false,
				default: null
			}
		},

		data: () => {
			return {
				active: false,
				monthlyContributions: 0
			};
		},

		computed: {
			/**
			 * @name monthlyContributionsExample
			 * @description WOrk out monthly contributions example
			 * @return {Number} The contribution example
			 */
			monthlyContributionsExample() {
				let contributions = (88.79 / 100) * this.monthlyContributions * 12 * 20;
				return 250000 - contributions;
			},

			/**
			 * @name monthlyContributionsTextClass
			 * @description Work out class string
			 * @return {String} Text class string
			 */
			monthlyContributionsTextClass() {
				if (this.monthlyContributions == 0) return 'warning--text';
				if (this.monthlyContributions == 25) return 'amber--text';
				if (this.monthlyContributions == 50) return 'lime--text';
				if (this.monthlyContributions == 75) return 'success--text';
				if (this.monthlyContributions == 100) return 'success--text';

				return '';
			}
		},

		methods: {
			open() {
				this.active = true;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.columnOrdering.v-data-table {
		border-radius: 0;
		box-shadow: rgba(#000000, 0.2) 0px 3px 8px 1px;

		::v-deep table tbody tr td {
			height: auto;
			padding: 0.3rem 0.8rem;
		}
	}

	.theme--light.v-data-table {
		tbody tr {
			&.restricted {
				background: rgba(#c20114, 0.1);

				&.heading {
					th {
						background: rgba(#c20114, 0.25);
						border-bottom: none !important;
						height: auto !important;
						padding-top: 0.5rem;
						padding-bottom: 0.5rem;
					}
				}
			}
		}
	}

	.hints__icon {
		flex: none;
		width: 180px;
	}
</style>
