<!--
	@name app-air-club-join
	@description Join tab view for the air club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="mb-6 pa-6">
		<v-alert v-if="membership('club').request && !membership('club').approve" dense type="warning" icon="mdi-progress-clock" border="left" class="mb-6">
			<span class="text-body-2 font-weight-bold">Your application to join Air Club is pending approval...</span>
		</v-alert>

		<p class="text-body-1">
			<span class="font-weight-bold">Top commission and client terms when you choose Air Club - with no commission skimming.</span> As a member of Air Club
			you can benefit from the best product terms and commission, simply by choosing Air Club when you request an illustration or apply for a product.
		</p>
		<p class="text-body-2 mb-5">
			All Club product deals are available on Air Sourcing alongside the widest choice of retirement lending and ancillary later life products. We pay out well over
			£1.5million in additional commission to our members each year without taking a penny of your commission.
		</p>

		<app-air-club-register-button class="mb-6" />

		<p class="text-body-1 font-weight-bold">
			Narrative by Numbers
		</p>
		<v-alert prominent text border="left" color="pink lighten-2" icon="mdi-account-group" dark class="mb-3">
			Increase in Club Membership since 2014 - <span class="font-weight-black">157%</span>
		</v-alert>
		<v-alert prominent text border="left" color="teal lighten-1" icon="mdi-briefcase-plus" dark class="mb-3">
			Increase in cases completed by Club Members since 2014 - <span class="font-weight-black">183%</span>
		</v-alert>
		<v-alert prominent text border="left" color="purple lighten-2" icon="mdi-currency-gbp" dark class="mb-0">
			Amount Club members benefited over standard commissions in 2018 - <span class="font-weight-black">£1,610,236.48</span>
		</v-alert>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import AppAirClubRegisterButton from '@/component/app/air-club/register-button';

	export default {
		name: 'app-air-club-join',

		components: { AppAirClubRegisterButton },

		computed: {
			...mapGetters('Account', ['membership'])
		}
	};
</script>
