import { AccountUser, AccountUserDepartment, AccountOrganisationUser, AccountUserOrganisationTradingStyle } from '@/service';
import { EventBus } from '@/utils';

/**
 * @class @name User
 * @description Exposes menu store module
 */
export default class User {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			organisationUserList: [],
			tradingStyle: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name userTradingStyle
			 * @description used to get user's trading style from store
			 * @param {Object} state
			 */
			userTradingStyle: (state) => state.tradingStyle,

			/**
			 * @name userByOrganisationTradingStyleId
			 * @description used to get users by organisation trading style ID from store
			 * @param {Object} state
			 */
			userByOrganisationTradingStyleId: (state) => (id) => state.organisationUserList.find((u) => u.organisationTradingStyle && u.organisationTradingStyle.id === id)
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setTradingStyle
			 * @description used to set user's trading style
			 * @param {Object} state
			 * @param {Object} data
			 */
			setTradingStyle(state, tradingStyle) {
				state.tradingStyle = { ...state.tradingStyle, ...tradingStyle };
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadOrganisationUserList
			 * @param {function} commit
			 */
			async loadOrganisationUserList(context, organisationId) {
				return AccountOrganisationUser.list(organisationId);
			},

			/**
			 * @async @name modifyOrganisationUser
			 * @param {function} commit
			 */
			async modifyOrganisationUser(context, { id, payload }) {
				return AccountUser.patch(id, payload);
			},

			/**
			 * @async @name deleteOrganisationUser
			 * @param {function} commit
			 */
			async deleteOrganisationUser(context, id) {
				return AccountUser.delete(id);
			},

			/**
			 * @async @name modifyOrganisationUserDepartment
			 * @param {function} commit
			 */
			async modifyOrganisationUserDepartment(context, { id, payload }) {
				return AccountUserDepartment.patch(id, payload);
			},

			/**
			 * @async @name addUserOrganisationTradingStyle
			 * @param {function} commit
			 */
			addUserOrganisationTradingStyle(context, payload) {
				return AccountUserOrganisationTradingStyle.post(payload);
			},

			/**
			 * @async @name updateUserOrganisationTradingStyles
			 * @param {function} commit
			 */
			updateUserOrganisationTradingStyles(context, payload) {
				return AccountUserOrganisationTradingStyle.update(payload);
			},

			/**
			 * @async @name activateUserOrganisationTradingStyle
			 * @param {function} commit
			 */
			activateUserOrganisationTradingStyle({ commit }, payload) {
				return AccountUserOrganisationTradingStyle.activateUserOrganisationTradingStyle(payload).then(() => {
					commit('setTradingStyle', payload);
					EventBus.$emit('trading-style-selected', { id: payload.organisationTradingStyleId, name: payload.name, fca: payload.fca });
				});
			},

			/**
			 * @async @name deleteUserOrganisationTradingStyle
			 * @param {function} commit
			 */
			deleteUserOrganisationTradingStyle(context, id) {
				return AccountUserOrganisationTradingStyle.delete(id);
			}
		};
	}
}
