<!--
	@name app-events
	@description events page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" class="pa-0 mt-5 mt-md-10 text-h6 d-inline-block" />

		<div class="border-effect border-effect--lg mt-5 mt-md-10">
			<p class="text-h5 mb-2 font-weight-bold">
				{{ event.name }}
			</p>
			<div class="mt-1 mb-3">
				<v-chip small dark label color="rgba(23, 184, 144, 0.8)" class="mr-2">
					{{ eventDateTime }}
				</v-chip>
				<v-chip v-if="event.category" small dark label color="rgba(103, 0, 86, 0.8)" class="mt-2 mt-sm-0">
					{{ event.category }}
				</v-chip>
			</div>
			<p class="text-body-2 grey--text text--darken-2 mb-6">
				<v-icon color="grey darken-2" style="position: relative; top:-1px; left:-2px">
					mdi-map-marker
				</v-icon>{{ event.address }}
			</p>
		</div>
		<div>
			<p class="text-body-1 font-weight-medium mb-6">
				{{ event.description }}
			</p>
			<p v-if="!event.url" class="text-body-1 font-italic mb-6">
				* Booking details coming soon *
			</p>
			<v-btn v-else :href="event.url" target="_blank" color="primary" class="mb-6">
				Book now
			</v-btn>
			<v-card class="mb-6" style="position: relative;">
				<app-events-event-countdown v-if="displayCountdown" :event="event" />
				<v-img
					:height="'300px'"
					:src="$options.filters.urlize(event.image) || $options.filters.urlize('placeholder/gradient.png')"
				/>
			</v-card>
			<p class="text-body-1 mb-10" style="white-space: pre-line;">
				{{ event.copy }}
			</p>
			<v-divider inset class="my-10" />
			<p v-if="!event.url" class="text-body-1 font-italic mb-10">
				* Booking details coming soon *
			</p>
			<v-btn v-else :href="event.url" target="_blank" color="primary" class="mb-10">
				Book now
			</v-btn>
		</div>
	</div>
</template>
Ï
<script>
	import AppEventsEventCountdown from '@/component/app/events/event-countdown';

	export default {
		name: 'app-events-event-details',

		components: {
			AppEventsEventCountdown
		},

		props: {
			event: { type: Object, required: true },
			breadcrumbs: { type: Array, required: false, default: null }
		},

		computed: {
			displayCountdown() {
				if (this.$moment(this.event.end) < this.$moment()) return false;
				return true;
			},

			/**
			 * @name selectedEventEndTime
			 * @description - If on same day, change this to {start} - {endtime} rather than  {start} - {end}
			 * @return {String} time for selected event
			 */
			 eventDateTime() {
				const start = this.$moment(String(this.event.start)).format('ddd, Do MMM YYYY h:mma');
				let end = this.$moment(String(this.event.end)).format('ddd, Do MMM YYYY h:mma');

				if (this.$moment(String(this.event.start)).isSame(this.$moment(String(this.event.end)), 'day')) {
					end = this.$moment(String(this.event.end)).format('h:mma');
				}

				return `${start} - ${end}`;
			}
        }
	};
</script>
