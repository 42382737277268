import { Matter } from '@/service';
import MatterHelper from '@/utils/MatterHelper';

/**
 * @class @name PropertyDashboard
 * @description Exposes property dashoard store module
 */
export default class PropertyDashboard {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchMatterList
			 * @param {function} commit
			 * @param {Object} payload
			 */
			async fetchMatterList(context, payload) {
				const { data } = await Matter.list(payload);

				return {
					matterList: data.data,
					mappedMatterList: MatterHelper.transformMatter(data.data)
				};
			}
		};
	}
}
