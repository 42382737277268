import { $http, ElementTools, EventBus, GoogleAnalytics } from '@/utils';
import {
	AccountAuthenticate,
	AccountAuthenticateTwoFactor,
	AccountOrganisation,
	AccountOrganisationAddress,
	AccountRegistration,
	AccountReset,
	AccountSignedAuthenticate,
	AccountUser,
	AccountUserAccount,
	AccountUserAddress,
	AccountUserAnalytics,
	AccountUserDetail,
	AccountUserIdentity,
	AccountUserSecurityQuestion,
	CmsChangelog,
	Completion,
	Membership,
	Workflow
} from '@/service';

import Vue from 'vue';
import moment from 'moment';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Account {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			corporation: {},
			department: {},
			organisation: {},
			organisationAddress: {},
			user: {
				rewardsBalance: null
			},
			completion: {},
			membership: [],
			permission: [],
			impersonator: {},
			organisationTradingStyles: [],
			showJoinForm: false,
			analytics: {},
			authedByLogin: false,
			workflowTemplateItems: [],
			changelogItems: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name authed
			 * @description Is the user authed
			 * @param {Object} state
			 * @return {Boolean} The auth status as a boolean
			 */
			authed: (state) => !!state.user.id,

			/**
			 * @name membership
			 * @description get membership of certain type
			 * @param {Object} state
			 * @return {Object} TODO
			 */
			membership: (state) => (type) => state.membership.find((m) => m.type === type) || {},

			/**
			 * @name tier
			 * @description get membership tier
			 * @param {Object} state
			 * @param {Object} getters
			 * @return {Object} TODO
			 */
			tier: (state, getters) => {
				let tiers = ['select', 'elite', 'platinum', 'club'];
				let membership = null;
				let id = null;

				if (state.membership.length) {
					tiers.every((t) => {
						if (getters.membership(t).id) {
							membership = `Air ${t === 'club' ? 'Advance' : t.charAt(0).toUpperCase() + t.slice(1)}`;
							id = t === 'club' ? 'advance' : t;
							return false;
						}
						return true;
					});
				}

				return { membership, id };
			},

			/**
			 * @name permission
			 * @description get permission of certain type
			 * @param {Object} state
			 * @return {Object} TODO
			 */
			permission: (state) => (role) => state.permission.find((m) => m.role === role) || {},

			/**
			 * @name userSecurityQuestion
			 * @description list of user security question answer list
			 * @param {Object} state
			 * @return {Boolean} The auth status as a boolean
			 */
			userSecurityQuestion: (state) =>
				state.user.userSecurityQuestion
					? state.user.userSecurityQuestion.filter((usq) => !usq.deleted).reduce((p, c) => ({ ...p, [c.securityQuestion.nameUnique]: c }), {})
					: {},

			/**
			 * @name departmentName
			 * @description TODO
			 * @param {Object} state
			 * @return {String} TODO
			 */
			departmentName: (state) => {
				if (state.department.name.includes('owner')) return 'owner';
				if (state.department.name.includes('manager')) return 'manager';
				return 'basic';
			},

			/**
			 * @name accountOwnershipStatus
			 * @description TODO
			 * @param {Object} state
			 * @return {Number} TODO
			 */
			accountOwnershipStatus: (state) => {
				if (state.department.nameUnique.includes('owner')) return 1;
				if (state.department.nameUnique.includes('manage')) return 2;
				if (state.department.nameUnique.includes('basic')) return 3;
				return 0;
			},

			/**
			 * @name isAdmin
			 * @description check if user is admin
			 * @param {Object} state
			 * @return {Boolean} user is admin
			 */
			isAdmin: (state) => state.user?.type === 'admin',

			/**
			 * @name workflowTemplateItems
			 * @description get workflow template items
			 * @param {Object} state
			 * @return {Array} workflow template items
			 */
			workflowTemplateItems: (state) => state.workflowTemplateItems
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setDetails
			 * @param {Object} state
			 * @param {Object} data
			 */
			setDetails(state, data) {
				state.corporation = data.corporation || state.corporation;
				state.department = data.department || state.department;
				state.organisation = data.organisation || state.organisation;
				state.organisationAddress = data.organisationAddress || state.organisationAddress;
				state.user = data.user || state.user;
				state.privacy = data.privacy || state.privacy;
				state.permission = data.permission || state.permission;
				state.completion = data.completion || state.completion;
				state.membership = data.membership || state.membership;
				state.impersonator = data.impersonator || state.impersonator;
				state.organisationTradingStyles = data.organisation_trading_style || state.organisation_trading_style;
				state.analytics = data.analytics || state.analytics;
				state.workflowTemplateItems = data.workflowTemplateItems || state.workflowTemplateItems;
				state.changelogItems = data.changelogItems || state.changelogItems;
			},

			/**
			 * @name setTwoFactor
			 * @param {Object} state
			 * @param {Object} data
			 */
			setTwoFactor(state, data) {
				state.user = { ...state.user, twoFactor: data };
			},

			/**
			 * @name clearDetails
			 * @param {Object} state
			 */
			clearDetails(state) {
				state.corporation = {};
				state.department = {};
				state.organisation = {};
				state.organisationAddress = {};
				state.user = {};
				state.privacy = {};
				state.permission = [];
				state.completion = {};
				state.membership = [];
				state.impersonator = {};
				state.organisation_trading_style = {};
				state.analytics = {};
				state.workflowTemplateItems = [];
				state.changelogItems = [];

				// Google Analytics event
				window.dataLayer.push({
					userId: null
				});
			},

			/**
			 * @name toggleAirSelectBypass
			 * @param {Object} state
			 */
			toggleAirSelectBypass(state, bypass) {
				const select = state.membership.find((m) => m.type === 'select');
				Vue.set(select, 'bypass', bypass);
			},

			/**
			 * @name setMembership
			 * @param {Object} state
			 */
			setMembership(state, set) {
				state.membership.push(set);
			},

			/**
			 * @name setShowJoinForm
			 * @param {Object} state
			 */
			setShowJoinForm(state, set) {
				state.showJoinForm = set;
			},

			/**
			 * @name setUser
			 * @param {Object} state
			 */
			setUser(state, user) {
				state.user = { ...state.user, ...user };
			},

			/**
			 * @name setUserAccount
			 * @param {Object} state
			 */
			setUserAccount(state, data) {
				state.user.userAccount = { ...state.user.userAccount, ...data };
			},

			/**
			 * @name setUserAddress
			 * @param {Object} state
			 */
			setUserAddress(state, data) {
				state.user.userAddress = { ...state.user.userAddress, ...data };
			},

			/**
			 * @name setUserDetail
			 * @param {Object} state
			 */
			setUserDetail(state, data) {
				state.user.userDetail = { ...state.user.userDetail, ...data };
			},

			/**
			 * @name setUserIdentity
			 * @param {Object} state
			 */
			setUserIdentity(state, { identity, payload }) {
				state.user.userIdentity = [...state.user.userIdentity.filter((u) => u.id !== identity.id), { ...identity, ...payload }];
			},

			/**
			 * @name setOrganisation
			 * @param {Object} state
			 */
			setOrganisation(state, organisation) {
				state.organisation = { ...state.organisation, ...organisation };
			},

			/**
			 * @name setOrganisationAddress
			 * @param {Object} state
			 */
			setOrganisationAddress(state, organisationAddress) {
				state.organisationAddress = { ...state.organisationAddress, ...organisationAddress };
			},

			/**
			 * @name setAuthedByLogin
			 * @param {Object} state
			 */
			setAuthedByLogin(state, value) {
				state.authedByLogin = value;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name getLastCompletion
			 */
			async getLastCompletion() {
				try {
					let response = await Completion.list({ limit: 1, order: { property: 'created', direction: 'desc' } });
					let completion = response.data?.data?.[0] || {};
					if (completion.completed) completion.within12Months = moment(String(completion.completed)).isAfter(moment().subtract(12, 'months'));
					return completion;
				} catch (error) {
					return false;
				}
			},

			/**
			 * @async @name getUserAnalytics
			 */
			async getUserAnalytics() {
				try {
					let response = await AccountUserAnalytics.get();
					let analytics = response.data;
					return analytics;
				} catch (error) {
					return false;
				}
			},

			/**
			 * @async @name getWorkflowTemplateItems
			 */
			async getWorkflowTemplateItems() {
				try {
					let response = await Workflow.listTemplateItems();
					let workflowTemplateItems = response.data;
					return workflowTemplateItems;
				} catch (error) {
					return false;
				}
			},

			/**
			 * @async @name getChangelogItems
			 */
			async getChangelogItems() {
				try {
					let response = await CmsChangelog.list({ order: { property: 'posted', direction: 'desc' } });
					EventBus.$emit('changelog-loaded');
					return response?.data?.data;
				} catch (error) {
					return false;
				}
			},

			/**
			 * @async @name login
			 * @param {function} commit
			 */
			async login({ commit, dispatch }, creds) {
				await AccountAuthenticate.post(creds);
				commit('setAuthedByLogin', true);
				await dispatch('verify');
				dispatch('activateSelectMembership');
			},

			/**
			 * @async @name logout
			 * @param {function} commit
			 */
			async logout({ commit }) {
				$http.clearToken();
				commit('clearDetails');
				window.location.href = '/';
			},

			/**
			 * @async @name verify
			 * @param {function} commit
			 */
			async verify({ state, commit, dispatch }) {
				try {
					const verify = await AccountAuthenticate.get();
					let data = { ...state.user, ...verify.data };

					if (data.user.type === 'advisor') {
						const completion = dispatch('getLastCompletion');
						const analytics = dispatch('getUserAnalytics');
						const workflowTemplateItems = dispatch('getWorkflowTemplateItems');
						const changelogItems = dispatch('getChangelogItems');
						await Promise.all([completion, analytics, workflowTemplateItems, changelogItems]).then(async(response) => {
							data = { ...data, completion: response[0], analytics: response[1], workflowTemplateItems: response[2], changelogItems: response[3] };
						});
					}

					commit('setDetails', data);
					dispatch('sendUserLoginEvent');
				} catch (err) {
					commit('clearDetails');
					throw err;
				}
			},

			async activateSelectMembership({ getters, dispatch }) {
				if (getters.membership('select').id && getters.membership('select').bypass) await dispatch('toggleAirSelectBypass');
			},

			/**
			 * @async @name register
			 * @param {function} commit
			 */
			async register(store, payload) {
				return await AccountRegistration.post(payload);
			},

			/**
			 * @async @name loadUserDetails
			 * @param {function} commit
			 */
			async loadUserDetails({ commit, state }, id) {
				const response = await AccountUser.get(id);
				const data = { ...state.user, ...response.data };
				commit('setDetails', { user: data });
				return response.data;
			},

			/**
			 * @async @name modifyUser
			 * @param {function} commit
			 */
			async modifyUser({ commit, state }, payload) {
				return AccountUser.patch(state.user.id, payload)
					.then(() => commit('setDetails', { user: { ...state.user, ...(payload.data || payload) } }))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyUserSecurityQuestion
			 * @param {function} commit
			 */
			// eslint-disable-next-line no-unused-vars
			async modifyUserSecurityQuestion({ commit }, payload) {
				return AccountUserSecurityQuestion.patch(payload.id, payload)
					.then(() => {
						return true;
					})
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name addUserSecurityQuestion
			 * @param {function} commit
			 */
			// eslint-disable-next-line no-unused-vars
			async addUserSecurityQuestion({ commit }, { payload }) {
				return AccountUserSecurityQuestion.post(payload)
					.then(() => {
						return true;
					})
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name addUserSecurityQuestion
			 * @param {function} commit
			 */
			// eslint-disable-next-line no-unused-vars
			async deleteUserSecurityQuestion({ commit }, id) {
				return AccountUserSecurityQuestion.delete(id)
					.then(() => {
						return true;
					})
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyUserAccount
			 * @param {function} commit
			 */
			async modifyUserAccount({ state }, payload) {
				return AccountUserAccount.patch(state.user.userAccount.id, payload);
			},

			/**
			 * @async @name modifyUserAddress
			 * @param {function} commit
			 */
			async modifyUserAddress({ commit, state }, payload) {
				return AccountUserAddress.patch(state.user.userAddress.id, payload).then(() => commit('setUserAddress', payload));
			},

			/**
			 * @async @name modifyUserDetail
			 * @param {function} commit
			 */
			async modifyUserDetail({ commit, state }, payload) {
				return AccountUserDetail.patch(state.user.userDetail.id, payload).then(() => commit('setUserDetail', payload));
			},

			/**
			 * @async @name modifyUserIdentity
			 * @param {function} commit
			 */
			async modifyUserIdentity({ commit }, { identity, payload }) {
				return AccountUserIdentity.patch(identity.id, payload).then(() => commit('setUserIdentity', { identity, payload }));
			},

			/**
			 * @name modifyUser
			 * @param {function} commit
			 */
			async toggleAirSelectBypass({ state, commit, dispatch }) {
				const select = state.membership.find((m) => m.type === 'select');

				// bypass can either be a timestamp or null
				const bypass = !select.bypass ? new Date() : null;
				await Membership.patch(select.id, { bypass });
				commit('toggleAirSelectBypass', bypass);

				// Emit a global event and dispatch certain actions to update the UI
				dispatch('CmsDownload/loadDownloadsList', null, { root: true });
				dispatch('CmsSite/loadSite', null, { root: true });
				EventBus.$emit('toggle-air-select-bypass', bypass);
				ElementTools.fireNotification(document, 'success', `Air Select has been ${!bypass ? 'activated' : 'deactivated'} `);
			},

			/**
			 * @async @name impersonate
			 * @param {function} commit
			 */
			async impersonate({ commit }, creds) {
				let response = await AccountAuthenticate.post(creds);
				commit('setDetails', response.data);
				if (response.data) return true;
			},

			/**
			 * @name reinstate
			 * @param {function} commit
			 */
			reinstate({ state }) {
				$http.setToken(state.impersonator.tokens.accessToken, state.impersonator.tokens.refreshToken);
			},

			/**
			 * @async @name generateTwoFactor
			 * @param {function} commit
			 */
			async generateTwoFactor() {
				let { data } = await AccountAuthenticateTwoFactor.get();
				if (data) return data;
			},

			/**
			 * @async @name deactivateTwoFactor
			 * @param {function} commit
			 */
			async deactivateTwoFactor({ state, commit }) {
				let response = await AccountAuthenticateTwoFactor.delete(state.user.id);
				if (response.data) {
					commit('setTwoFactor', false);
					return response.data;
				}
			},

			/**
			 * @async @name modifyTwoFactor
			 * @param {function} commit
			 */
			async modifyTwoFactor({ commit }, data) {
				let response = await AccountAuthenticateTwoFactor.patch(data);
				if (response.data) {
					commit('setTwoFactor', true);
					return true;
				}
			},

			/**
			 * @async @name requestMembership
			 * @param {function} commit
			 */
			async requestMembership({ commit }, payload) {
				payload = { ...payload, statusRequest: new Date() };
				const { data } = await Membership.post(payload);
				commit('setMembership', data);
			},

			/**
			 * @async @name fetchMembership
			 * @param {function} commit
			 */
			fetchMembership(context, id) {
				return Membership.get(id);
			},

			/**
			 * @async @name setShowJoinForm
			 * @param {function} commit
			 */
			setShowJoinForm({ commit }, set) {
				commit('setShowJoinForm', set);
			},

			/**
			 * @name resetPasswordRequest
			 * @param {object} context
			 * @param {object} payload
			 */
			resetPasswordRequest(context, payload) {
				return AccountReset.post(payload);
			},

			/**
			 * @name resetPassword
			 * @param {object} context
			 * @param {object} payload
			 */
			resetPassword(context, data) {
				const { payload, token } = data;
				return AccountReset.patch(payload, token);
			},

			/**
			 * @name signedAuthenticate
			 * @param {object} context
			 * @param {object} payload
			 */
			signedAuthenticate(context, payload) {
				return AccountSignedAuthenticate.post(payload);
			},

			/**
			 * @async @name modifyOrganisation
			 * @param {function} commit
			 */
			async modifyOrganisation({ state, commit }, payload) {
				return AccountOrganisation.patch(state.organisation.id, payload).then(() => {
					commit('setOrganisation', payload);
				});
			},

			/**
			 * @async @name modifyOrganisationAddress
			 * @param {function} commit
			 */
			async modifyOrganisationAddress({ state, commit }, payload) {
				return AccountOrganisationAddress.patch(state.organisationAddress.id, payload).then(() => {
					commit('setOrganisationAddress', payload);
				});
			},

			/**
			 * @async @name sendUserLoginEvent
			 * @param {function} state
			 */
			sendUserLoginEvent({ state }) {
				GoogleAnalytics.sendEvent('userLogin', {
					userId: state?.user?.id,
					network: state?.user?.networkId,
					persona: state?.analytics?.persona
				});
			}
		};
	}
}
