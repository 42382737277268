<!--
	@name common-base-window-form
	@description window form
	@date 2020/06/12
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-card v-if="forms.length" class="mx-auto" max-width="600">
			<v-card-title class="text-h6 font-weight-regular justify-space-between">
				<span class="text-h6">{{ forms[currentStep].name }}</span>
				<v-avatar color="blue-grey darken-3" class="white--text" size="24" v-text="currentStep + 1" />
			</v-card-title>

			<v-divider />
			<v-progress-linear :value="progress" :color="color" absolute height="7" />

			<v-sheet class="body pa-5 pt-5">
				<v-window v-model="currentStep">
					<v-window-item v-for="(form, index) in forms" :key="index" :value="index">
						<!-- v-if="form.type === 'dynamicForm'" -->
						<dynamic-form
							v-if="!!formData[form.nameUnique]"
							:ref="form.nameUnique"
							:form-schema="form.value"
							:form-data="formData[form.nameUnique]"
							:submit-button="false"
							:custom-error="customError"
							@form-updated="$emit('form-updated', $event)"
						/>
						<div v-show="forms[currentStep].type === 'success' && !!$slots['success-step']">
							<slot name="success-step" />
						</div>
					</v-window-item>
				</v-window>
			</v-sheet>

			<v-divider />

			<v-card-actions v-show="forms[currentStep].type !== 'success'" class="pa-4 blue-grey lighten-5">
				<v-btn :disabled="currentStep === 0" text @click="previousStep">
					Back
				</v-btn>
				<v-spacer />
				<v-btn color="primary" depressed :loading="loading" @click="nextStep">
					{{ forms[currentStep].buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-card v-else class="mx-auto" max-width="600">
			<v-skeleton-loader type="list-item-three-line@3" />
		</v-card>

		<v-sheet v-if="footer" color="rgba(255, 0, 0, 0)" max-width="600" class="mx-auto pt-6">
			<v-row no-gutters>
				<v-col cols="12" sm="6" class="text-left">
					<v-btn text x-small class="text-none" @click="openContactBox">
						Help
					</v-btn>
				</v-col>
				<v-col cols="12" sm="6" class="text-caption black--text mb-0 text-right">
					<v-btn text x-small class="text-none" @click="openTerms">
						Terms
					</v-btn>
					<v-btn text x-small class="text-none" @click="openPrivacy">
						Privacy Policy
					</v-btn>
				</v-col>
			</v-row>
		</v-sheet>
		<app-dialog-contact ref="contact" />
	</div>
</template>

<script>
	import { debounce } from 'lodash';
	import { EventBus } from '@/utils';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import AppDialogContact from '@/component/app/dialog/contact';

	export default {
		name: 'common-base-window-form',

		components: { DynamicForm, AppDialogContact },

		props: {
			formData: {
				type: Object,
				default: () => ({})
			},
			forms: {
				type: Array,
				default: () => []
			},
			submitting: {
				type: Boolean,
				required: true
			},
			footer: {
				type: Boolean,
				default: true
			},
			customError: {
				type: Object,
				default: () => ({})
			}
		},

		data() {
			return {
				currentStep: 0,
				validating: false
			};
		},

		computed: {
			progress() {
				return (this.currentStep / (this.forms.length - 1)) * 100;
			},

			color() {
				return ['red', 'orange', 'yellow accent-3', 'light-green', 'green accent-4'][Math.floor(this.progress / 20)];
			},

			loading() {
				if (this.validating || this.submitting) return true;
				return false;
			}
		},

		methods: {
			/**
			 * @name previousStep
			 * @description Go to previous step
			 */
			previousStep() {
				this.currentStep--;
			},

			/**
			 * @debounce @name openContactBox
			 * @description Open the contact box
			 */
			openContactBox: debounce(function() {
				this.$refs.contact.open();
			}, 200),

			openTerms() {
				EventBus.$emit('open-terms');
			},

			openPrivacy() {
				EventBus.$emit('open-privacy');
			},

			/**
			 * @name nextStep
			 * @description Go to next step
			 */
			async nextStep() {
				this.validating = true;
				let isValid = await this.$refs[this.forms[this.currentStep].nameUnique][0].validate(false);
				this.validating = false;

				if (!isValid) return;

				// Allows a submit function for steps
				if (this.forms[this.currentStep].stepSubmit) this.stepSubmit();
				else if (this.forms[this.currentStep].submit) this.submit();
				else this.currentStep++;
			},

			/**
			 * @name stepSubmit
			 * @description Submits current step form information to server
			 */
			async stepSubmit() {
				this.$emit('step-submit-window-form', this.formData);
			},

			/**
			 * @name submit
			 * @description Submits registration form to server
			 */
			async submit() {
				this.$emit('submit-window-form', this.formData);
			},

			/**
			 * @name incrementCurrentStep
			 * @description On successful submit increment current step to show success step
			 */
			incrementCurrentStep() {
				this.currentStep++;
			},

			/**
			 * @name incrementCurrentStep
			 * @description On successful submit increment current step to show success step
			 */
			goToStep(step) {
				this.currentStep = step;
			}
		}
	};
</script>
