import AppClient from '@/component/app/client';
import AppClientDashboard from '@/component/app/client/dashboard';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for Clients page
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{ path: '/clients', name: 'clients', meta: { layout: 'default-authed', requiresSiteCheck: true }, component: AppClient },
	{ path: '/client/:id', name: 'clientDashboard', meta: { layout: 'default-authed' }, component: AppClientDashboard, props: true },
	{
		path: '/client/:clientId/property/:propertyId',
		name: 'propertyDashboard',
		meta: { layout: 'default-authed' },
		beforeEnter: (to, from, next) => {
			// Redirect to client dashboard
			next({ name: 'clientDashboard', params: { id: to.params.clientId } });
		}
	}
];
