<!--
	@name app-events
	@description events page
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Air Events
					</div>
				</div>
			</v-container>
		</v-img>
		
		<v-container class="py-0 px-4">
			<app-events-list />
		</v-container>
	</div>
</template>

<script>
	import AppEventsList from '@/component/app/events/events-list';

	export default {
		name: 'app-events',

		components: {
			AppEventsList
		}
	};
</script>
