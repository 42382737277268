import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Affordability
 * @exports Service/Affordability
 * @description API Affordability Service
 * @date 2024/02/08
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Affordability {
	/**
	 * @static @name post
	 * @description Add a new affordability resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post('affordability', payload);
	}

	/**
	 * @static @name list
	 * @description Get all affordability matters
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('affordability/list', payload);
	}

	/**
	 * @static @name patch
	 * @description Patch affordability process to mark it as completed
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch({ id, payload }) {
		return $http.patch(`affordability/${id}`, payload);
	}
}
