import { AccountRegistrationComplete } from '@/service';

/**
 * @class @name Complete
 * @description Exposes menu store module
 */
export default class Complete {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadCompleteRegistration
			 * @description used to fetch data to complete registeration
			 * @param {function} commit
			 */
			async loadCompleteRegistration(store, token) {
				return await AccountRegistrationComplete.get(token);
			},

			/**
			 * @async @name submitCompleteRegistration
			 * @description submit data to complete registeration
			 * @param {function} commit
			 */
			async submitCompleteRegistration(store, { token, payload }) {
				return await AccountRegistrationComplete.post(token, payload);
			}
		};
	}
}
