import { $http } from '@/utils';

/**
 * @namespace Service
 * @class UserSecurityQuestion
 * @exports Service/Account/UserSecurityQuestion
 * @description API User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class UserSecurityQuestion {
	/**
	 * @static @name post
	 * @description Add User security question details
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`account/user/security-question`, payload);
	}

	/**
	 * @static @name patch
	 * @description Update some User security question details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`account/user/security-question/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description delete User security question
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`account/user/security-question/${id}`);
	}
}
