<!--
	@name app-insights-hub-content-category
	@description Insights Hub Content Category
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img :src="'generic/AIR-BG.png' | urlize" :height="$vuetify.breakpoint.smAndDown ? 120 : 200">
			<v-container style="position: relative" fill-height>
				<div>
					<div :class="{ 'text-h2': $vuetify.breakpoint.mdAndUp, 'text-h3': $vuetify.breakpoint.smAndDown }" class="text-h2 white--text">
						Insights Hub
					</div>
				</div>
			</v-container>
		</v-img>

		<div>
			<v-container>
				<!-- Breadcrumbs -->
				<v-breadcrumbs :items="breadcrumbs" class="pl-0">
					<template #divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>
				</v-breadcrumbs>

				<div class="mt-4">
					<div class="text-h5">
						Client lead generation
					</div>

					<p class="text-body-2 mt-4">
						Lead generation is an integral part of a business plan to ensure business growth. Your lead generation activity should essentially be aimed at reaching out
						to new prospects or existing clients who might have later life lending need. At Air, we want to support you by providing knowledge and tools to either guide
						you in effective lead generation activity, or to strengthen the work you're already doing. Our Training and Support section includes helpful tips, guides
						and videos to build on your understanding of the typical later life lending clients, the market and marketing for lead generation. Our Client campaigns
						section includes ready to go campaigns for you to download with instructions. Use these to start your own lead generation campaigns or support your existing
						activity.
					</p>
				</div>

				<div class="mb-8">
					<v-container style="max-width: 800px">
						<v-row>
							<v-col md="6" sm="12" xs="12" class="mt-4">
								<v-card elevation="4">
									<div class="pa-8 text-center">
										<div class="text-h4">
											Training &amp; Support
										</div>

										<div class="mt-6 text-body-2">
											Tips, guides and videos to help with your lead generation activity
										</div>

										<v-btn color="info" small class="mt-6">
											View Content
										</v-btn>
									</div>
								</v-card>
							</v-col>
							<v-col md="6" sm="12" xs="12" class="mt-4">
								<v-card elevation="4">
									<div class="pa-8 text-center">
										<div class="text-h4">
											Client Campaigns
										</div>

										<div class="mt-6 text-body-2">
											Ready to go lead generation Campaigns and instructions for you to download
										</div>

										<v-btn color="info" small class="mt-6">
											View Content
										</v-btn>
									</div>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-insights-hub-content-category',

		components: {},

		data() {
			return {
				page: 1,
				breadcrumbs: [{ text: 'Insights Hub', exact: true, to: '/air-later-life/insights-hub' }, { text: 'Client Lead Generation' }]
			};
		},

		computed: {},

		created() {},

		methods: {}
	};
</script>
