<!--
	@name app-client-service-dialog-boyd-legal
	@description boyd legal information
	@date 2020/07/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Boyd Legal
		</template>
		<template #body>
			<p class="body-2 font-weight-bold">
				To instruct Boyd Legal for a single applicant
				<a target="_blank" :href="singleLink">click here</a> to download the instruction form.
			</p>
			<p class="body-2 font-weight-bold">
				To instruct Boyd Legal for joint applicants
				<a target="_blank" :href="coupleLink">click here</a> to download the instruction form.
			</p>
			<p class="body-2 font-weight-bold mb-0">
				To meet the Boyd Legal team <a target="_blank" :href="brochureLink">click here</a> to download the brochure.
			</p>
		</template>
	</common-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-client-dashboard-workflow-conveyancing-gilroy-steel',

		components: { CommonDialog },

		data() {
			return {
				pricing: [
					{ valuation: '£0 to £99,999.00', price: '£535.00 + V.A.T' },
					{ valuation: '£100,000 to £249,999', price: '£560.00 + V.A.T' },
					{ valuation: '£250,000 to £499,999', price: '£600.00 + V.A.T' },
					{ valuation: '£500,000 to £999,999', price: '£725.00 + V.A.T' },
					{ valuation: '£1,000,000 upwards', price: '£900.00 + V.A.T' }
				]
			};
		},

		computed: {
			...mapState('app', ['documents']),

			singleLink() {
				return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/download/ER+-+New+Client+Inception+Form+-+SINGLE.pdf`;
			},

			coupleLink() {
				return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/download/ER+-+New+Client+Inception+Form+-+COUPLE.pdf`;
			},

			brochureLink() {
				return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/download/Boyd+Legal+-+Equity+Release.pdf`;
			}
		},

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			}
		}
	};
</script>

<style scoped lang="scss">
	.pricing {
		tr td {
			border-bottom: 5px solid #ffffff !important;
		}
		tr:last-child td {
			border-bottom: none !important;
		}
	}
</style>
