import Dashboard from '@/component/app/dashboard';
import Activity from '@/component/app/dashboard/activity';
import News from '@/component/app/dashboard/news';
import Event from '@/component/app/dashboard/event';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for Air Club page
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		path: '/account',
		component: Dashboard,
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		children: [
			{
				name: 'dashboard.activity',
				path: '',
				meta: { layout: 'default-authed' },
				component: Activity
			},
			{
				name: 'dashboard.news',
				path: 'news',
				meta: { layout: 'default-authed' },
				component: News
			},
			{
				name: 'dashboard.events',
				path: 'events',
				meta: { layout: 'default-authed' },
				component: Event
			}
		]
	}
];
