import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Download
 * @exports Service/Cms/Download
 * @description API Download Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Download {
	/**
	 * @static @name list
	 * @description Get all downloads as a list that can be manipulated through post body properties
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post(`cms/download/list`, payload, {
			cache,
			cachePath: `table-cms-download-${domain}`
		});
	}
}
