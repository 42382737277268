<!--
	@name app-source-result-filter
	@description Show source results filters
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<!-- Filter button -->
		<v-fade-transition>
			<v-btn
				v-if="showFabButton"
				class="ga--trigger ga--filters-opened filter-button pulse--accent background-rotation-manual--2 accent--text"
				data-ga-id="fab"
				large
				fab
				fixed
				bottom
				right
				@click="showFilterDrawer"
			>
				<v-icon>mdi-filter</v-icon>
			</v-btn>
		</v-fade-transition>

		<!-- Filter panel -->
		<v-navigation-drawer
			ref="navigation-drawer"
			width="450"
			:value="filterDrawer"
			:right="filterPositionRight"
			stateless
			app
			temporary
		>
			<v-layout wrap>
				<v-flex v-if="initialFilterModel" xs12 pa-5 style="padding-top: 160px !important">
					<v-app-bar dark class="generic-gradient" style="height: 140px" fixed dense>
						<v-toolbar-title>Filters</v-toolbar-title>

						<v-spacer />

						<v-btn icon @click="filterPositionRight = !filterPositionRight">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-icon v-on="on">
										{{ filterPositionRight ? 'mdi-arrow-left-circle' : 'mdi-arrow-right-circle' }}
									</v-icon>
								</template>
								<span>{{ filterPositionRight ? 'Dock Left' : 'Dock Right' }}</span>
							</v-tooltip>
						</v-btn>

						<v-btn icon :loading="filterCloseButtonLoading" @click.stop="closeFilterDrawer">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-icon v-on="on">
										mdi-close-circle
									</v-icon>
								</template>
								<span>Close Filter Panel</span>
							</v-tooltip>
						</v-btn>

						<template #extension>
							<v-container class="pa-0">
								<v-row no-gutters class="pt-1">
									<v-col>
										<v-chip class="d-block text-center" color="white" label small>
											<strong>
												<span class="success--text"> {{ availableProductCount }} Matching Products</span>
												<span class="grey--text text--darken-2"> / </span>
												<v-tooltip bottom>
													<template #activator="{ on }">
														<span class="error--text" style="cursor: pointer" v-on="on" @click="$emit('open-outside-criteria-products-dialog')">
															<span> {{ outsideCriteriaProductCount }}</span>
															<span> Outside Criteria</span>
														</span>
													</template>
													<span>Click here to see all products outside criteria</span>
												</v-tooltip>
											</strong>
										</v-chip>
									</v-col>
								</v-row>

								<v-row no-gutters class="pt-4">
									<v-col class="pr-1">
										<v-btn
											class="ga--trigger ga--filters-submitted mb-6"
											data-ga-id="filters-panel"
											color="primary"
											block
											:disabled="isArchive"
											:loading="loadingProducts || loading"
											@click="applyFilters"
										>
											Apply Filters
										</v-btn>
									</v-col>
									<v-col class="pl-1">
										<v-btn color="error" class="mb-6" block :dark="!disableClearFilters" :disabled="disableClearFilters" @click="clearFilters">
											Clear Filters
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</template>
					</v-app-bar>

					<div>
						<!-- Form -->
						<validation-observer ref="observer">
							<v-form>
								<v-expansion-panels v-for="(group, groupIndex) in filterSchema" :key="groupIndex" v-model="panelModel[groupIndex]" multiple class="mb-5">
									<v-expansion-panel v-for="(panel, index) in group.value" :id="`expansion-panel-${groupIndex}${index}`" :key="index">
										<v-expansion-panel-header disable-icon-rotate>
											{{ panel.name }}
											<template v-if="checkFilterPanelEdited(panel)" #actions>
												<v-icon color="amber">
													mdi-plus-network-outline
												</v-icon>
											</template>
											<template v-else-if="checkFilterPanelActive(panel)" #actions>
												<v-icon color="success">
													mdi-check-network
												</v-icon>
											</template>
										</v-expansion-panel-header>

										<v-expansion-panel-content eager>
											<div v-for="(item, key) in panel.value" :key="key">
												<!-- Plain Text -->
												<div v-if="item.type === 'text'" v-show="showField(item)" :class="item.class" v-text="item.display" />

												<!-- Text Field -->
												<validation-provider v-if="item.type === 'input'" v-slot="{ errors }" :name="item.display" :rules="getRules(item)" :vid="item.name">
													<v-text-field
														v-show="showField(item)"
														v-model="filterModel[item.name]"
														:disabled="disableField(item)"
														:label="item.display"
														:placeholder="item.placeholder"
														:suffix="item.suffix"
														:prefix="item.prefix"
														:error-messages="errors"
														:name="item.name"
														:data-vv-as="item.display"
														outlined
													/>
												</validation-provider>

												<!-- Switch -->
												<v-switch
													v-if="item.type === 'switch'"
													v-show="showField(item)"
													v-model="filterModel[item.name]"
													:disabled="disableField(item)"
													:label="item.display"
													color="primary"
												/>

												<!-- Select -->
												<validation-provider
													v-if="item.type === 'select'"
													v-slot="{ errors }"
													:name="item.display"
													:rules="getRules(item)"
													:vid="item.name"
												>
													<v-select
														v-show="showField(item)"
														v-model="filterModel[item.name]"
														:disabled="disableField(item)"
														:label="item.display"
														:error-messages="errors"
														:items="item.options"
														:menu-props="item.menuProps"
														:multiple="item.multiple"
														outlined
													>
														<template v-if="item.selectAll && filterModel[item.name]" #prepend-item>
															<v-list-item ripple @click="selectAll(item)">
																<v-list-item-action>
																	<v-icon v-if="filterModel[item.name].length == 0" color="success">
																		mdi-checkbox-blank-outline
																	</v-icon>
																	<v-icon
																		v-else-if="filterModel[item.name].length > 0 && filterModel[item.name].length !== item.options.length"
																		color="success"
																	>
																		mdi-minus-box
																	</v-icon>
																	<v-icon v-else color="red lighten-1">
																		mdi-close-box
																	</v-icon>
																</v-list-item-action>
																<v-list-item-content>
																	<v-list-item-title v-if="filterModel[item.name].length !== item.options.length">
																		Select All
																	</v-list-item-title>
																	<v-list-item-title v-else>
																		Clear All
																	</v-list-item-title>
																</v-list-item-content>
															</v-list-item>
															<v-divider class="mt-2" />
														</template>
													</v-select>
												</validation-provider>

												<!-- Slider -->
												<div v-if="item.type === 'slider'" v-show="showField(item)">
													<v-subheader class="pa-0" :class="{ 'mb-6': item.thumbLabel }" style="height: 30px">
														{{ item.display }}
													</v-subheader>
													<v-slider
														v-model="filterModel[item.name]"
														:disabled="disableField(item)"
														:min="item.min"
														:max="item.max"
														:ticks="item.ticks"
														:tick-size="item.tickSize"
														:step="item.step"
														:thumb-size="item.thumbSize"
														:thumb-label="item.thumbLabel"
														:append-icon="item.appendIcon"
														class="pt-2 pl-2 pr-2"
													>
														<template #prepend>
															<v-text-field
																v-if="item.prepend"
																v-model="filterModel[item.name]"
																class="mt-0 pt-0"
																hide-details
																single-line
																readonly
																style="width: 60px"
															/>
														</template>
													</v-slider>
												</div>

												<!-- Range & Range Slider-->
												<div v-if="item.type === 'range' || item.type === 'range-slider'" v-show="showField(item)">
													<v-subheader class="pa-0" :class="{ 'mb-6': item.thumbLabel }" style="height: 30px">
														{{ item.display }}
													</v-subheader>

													<v-range-slider
														v-if="item.type === 'range-slider'"
														v-model="filterModel[item.name]"
														class="pt-2 pl-2 pr-2"
														:disabled="disableField(item)"
														:min="getMin(item.name)"
														:max="getMax(item.name)"
														:ticks="item.ticks"
														:tick-size="item.tickSize"
														:step="item.step"
														:thumb-size="item.thumbSize"
														:thumb-label="item.thumbLabel"
														:append-icon="item.appendIcon"
														hide-details
													/>

													<v-container class="pa-0 mt-3 mb-8">
														<v-row dense>
															<v-col v-for="(el, index) in 2" :key="index" cols="sm">
																<validation-provider
																	v-slot="{ errors }"
																	:name="item.display"
																	:rules="getRangeRules(item, index)"
																	:vid="`${item.name}-${index}`"
																>
																	<v-text-field
																		v-model.number="filterModel[item.name][index]"
																		type="number"
																		class="mt-0 pt-0"
																		single-line
																		solo
																		:hide-details="!errors.length"
																		:error-messages="errors"
																		:disabled="disableField(item)"
																	/>
																</validation-provider>
															</v-col>
														</v-row>
													</v-container>
												</div>

												<div v-if="item.type === 'query-builder'">
													<p class="text-caption black--text mb-3">
														Build a custom search query to <span class="font-weight-bold">restrict</span> all products that match the applied conditions
													</p>
													<v-list outlined dense class="pa-0 rounded">
														<v-list-item @click="openQueryBuilderDialog">
															<v-list-item-content>
																<v-list-item-title class="text-body-2">
																	{{ queryModel.length || 'No' }} Condition{{ queryModel.length !== 1 ? 's' : '' }} Applied
																</v-list-item-title>
															</v-list-item-content>
															<v-list-item-action class="my-2">
																<v-btn color="primary" icon @click="openQueryBuilderDialog">
																	<v-icon>mdi-magnify</v-icon>
																</v-btn>
															</v-list-item-action>
														</v-list-item>
														<v-divider v-if="queryModel.length" />
														<div v-for="(q, index) in queryModel" :key="index">
															<v-divider v-if="index" inset />
															<v-list-item>
																<v-list-item-content>
																	<v-list-item-title> {{ q.property.text }} {{ q.condition.text }} {{ q.value }} </v-list-item-title>
																</v-list-item-content>
															</v-list-item>
														</div>
													</v-list>
												</div>
											</div>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-form>
						</validation-observer>

						<v-btn class="mt-6" color="secondary" block outlined :href="`mailto:${site.contact.email}?subject=Request for new sourcing filter`">
							Request Additional Filters?
						</v-btn>
						<p class="text-subtitle-2 text-center mt-2">
							Have we missed something? Use the button above to submit a new filter request.
						</p>
					</div>
				</v-flex>
				<p v-else>
					Loading...
				</p>
			</v-layout>
		</v-navigation-drawer>

		<app-source-result-dialog-query-builder ref="query-builder" @submit="saveQuery" />
		<app-source-result-dialog-filter-request ref="filter-request" />
		<app-source-result-dialog-filter-confirm-close ref="filter-confirm-close" @apply="applyFilters" @close="hideFilterDrawer" />
		<common-dialog-confirm ref="factfind-filter-warning" />
	</div>
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { isEmpty, cloneDeep, isEqual, minBy, maxBy, reduce } from 'lodash';
	import { $sessionStorage } from '@/utils/storage';
	import AppSourceResultDialogQueryBuilder from './dialog/query-builder';
	import AppSourceResultDialogFilterRequest from './dialog/filter-request';
	import AppSourceResultDialogFilterConfirmClose from './dialog/filter-confirm-close';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-source-result-filter',

		components: {
			ValidationObserver,
			ValidationProvider,
			AppSourceResultDialogQueryBuilder,
			AppSourceResultDialogFilterRequest,
			AppSourceResultDialogFilterConfirmClose,
			CommonDialogConfirm
		},

		props: {
			filters: { type: Object, required: true },
			filterOptionsData: { type: Object, required: true },
			availableProductCount: { type: Number, required: true },
			outsideCriteriaProductCount: { type: Number, required: true },
			productList: { type: Array, required: true },
			loadingProducts: { type: Boolean, required: true }
		},

		data() {
			return {
				filterDrawer: false,
				filterPositionRight: true,
				filterCloseButtonLoading: false,
				loading: false,
				panelModel: [],
				activeFilterModel: {},
				filterModel: {},
				initialFilterModel: null,
				queryModel: [],
				fabButtonActive: true
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),

			disableClearFilters() {
				let $this = this;
				let difference = reduce(this.initialFilterModel, (result, value, key) => (isEqual(value, $this.filterModel[key]) ? result : result.concat(key)), []);
				return difference.length === 0;
			},

			filterSchema() {
				return this.form('source_result_filter').value;
			},

			isFactFind() {
				return this.$route.query && !!this.$route.query.factfind;
			},

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			},

			showFabButton() {
				return this.initialFilterModel && !this.loadingProducts && !this.filterDrawer && this.fabButtonActive;
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			init() {
				this.initialFilterModel = { queryBuilder: [], ...cloneDeep(this.filters) };
				this.setRangeInitialValues();

				this.filterModel = cloneDeep(this.initialFilterModel);
				this.activeFilterModel = cloneDeep(this.initialFilterModel);
				this.setQueryBuilderValues();
			},

			/**
			 * @name showFilterDrawer
			 * @description Open filters panel
			 */
			showFilterDrawer() {
				this.filterModel = cloneDeep(this.activeFilterModel);
				this.setQueryBuilderValues();
				this.filterDrawer = true;
				this.fabButtonActive = false;
			},

			/**
			 * @name hideFilterDrawer
			 * @description Hide filters panel
			 */
			hideFilterDrawer() {
				this.filterDrawer = false;
				this.panelModel = [];
				setTimeout(() => { this.fabButtonActive = true }, 500);
			},

			/**
			 * @name hideFilterDrawer
			 * @description Close filters panel
			 */
			closeFilterDrawer() {
				if (this.isArchive) {
					this.hideFilterDrawer();
					return;
				}

				let $this = this;
				let difference = reduce(this.filterModel, (result, value, key) => (isEqual(value, $this.activeFilterModel[key]) ? result : result.concat(key)), []);
				if (difference.length === 0) this.hideFilterDrawer();
				else this.$refs['filter-confirm-close'].open();
			},
			/**
			 * @name showField
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			showField(field) {
				return !field.conditions || !field.conditions.show ? true : this.getCondition(field, 'show');
			},

			/**
			 * @name disableField
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			disableField(field) {
				const disabled = !field.conditions || !field.conditions.disable ? false : this.getCondition(field, 'disable');

				if (disabled) {
					switch (field.type) {
						case 'switch':
							this.filterModel[field.name] = false;
							break;
						case 'array':
							this.filterModel[field.name] = [];
							break;

						default:
							this.filterModel[field.name] = null;
							break;
					}
				}

				return disabled;
			},

			/**
			 * @name getRules
			 * @description Get validation rules for the field
			 * @param {Object} field
			 * @returns {String} rules
			 */
			getRules(field) {
				if (!this.showField(field) || this.disableField(field) || !field.rules) return;
				return field.rules;
			},

			/**
			 * @name getRangeRules
			 * @description Get validation rules for the field
			 * @param {Object} field
			 * @returns {String} rules
			 */
			getRangeRules(field, index) {
				if (!this.showField(field) || this.disableField(field)) return;

				let min = field.min || 0;
				let max = field.max || this.getMax(field.name);

				return {
					required: true,
					...field.rules,
					min_value: index === 0 ? min : this.filterModel[field.name][0] || 0,
					max_value: index === 0 ? this.filterModel[field.name][1] || 0 : max
				};
			},

			/**
			 * @name getCondition
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			getCondition(field, conditionType) {
				let conditionPassed = false;

				field.conditions[conditionType].forEach((condition) => {
					if (
						(condition.type === 'equals' && this.filterModel[condition.name] == condition.value) ||
						(condition.type === 'not_equals' && this.filterModel[condition.name] != condition.value) ||
						(condition.type === 'includes' && this.filterModel[condition.name] && this.filterModel[condition.name].includes(condition.value)) ||
						(condition.type === 'isEmpty' && isEmpty(this.filterModel[condition.name]))
					) {
						conditionPassed = true;
					}
				});

				return conditionPassed;
			},

			/**
			 * @name validate
			 * @description Validates filter form
			 * @returns {Boolean} Is valid
			 */
			async validate() {
				return this.$refs.observer.validate();
			},

			/**
			 * @name applyFilters
			 * @description Close filters panel
			 */
			applyFilters() {
				if (this.isFactFind && !$sessionStorage.isDialogHidden('factfind-filter-warning')) {
					this.$refs['factfind-filter-warning']
						.open('Apply Filters?', `Please note applying / amending a filter may require you to amend the Fact Find data accordingly.`)
						.then(() => {
							this.confirmApplyFilters();
							$sessionStorage.hideDialog('factfind-filter-warning');
						})
						.catch(() => {});
				} else this.confirmApplyFilters();
			},

			/**
			 * @name confirmApplyFilters
			 * @description confirm apply filters
			 */
			async confirmApplyFilters() {
				let isValid = await this.validate();

				if (isValid) {
					this.activeFilterModel = cloneDeep(this.filterModel);
					this.hideFilterDrawer();
					this.$emit('search-criteria-updated', { filters: this.activeFilterModel });
				} else this.navigateToError();
			},

			/**
			 * @name clearFilters
			 * @description Sets filters to their original values
			 */
			clearFilters() {
				this.filterModel = cloneDeep(this.initialFilterModel);
				this.setQueryBuilderValues();
			},

			/**
			 * @name openPanels
			 * @description Open panels includes restriction code
			 * @param {Number} restrictionCode
			 */
			openPanels(restrictionCode = false) {
				this.showFilterDrawer();

				if (!restrictionCode) return;
				let panelModel = [];
				let scrollToPanelId = null;

				this.filterSchema.forEach((group, groupIndex) => {
					group.value.forEach((panel, panelIndex) => {
						panel.value.forEach((item) => {
							if (item.type !== 'subheader' && item.restriction == restrictionCode) {
								if (!panelModel[groupIndex]) panelModel[groupIndex] = [];
								if (!panelModel[groupIndex].includes(panelIndex)) {
									panelModel[groupIndex].push(panelIndex);
									scrollToPanelId = scrollToPanelId || `#expansion-panel-${groupIndex}${panelIndex}`;
								}
							}
						});
					});
				});

				this.panelModel = panelModel;
				this.scrollToPanel(scrollToPanelId);
			},

			/**
			 * @name navigateToError
			 * @description Identify the invalid fields in form and navigate user
			 */
			async navigateToError() {
				let errors = this.$refs.observer.ctx.errors;
				let invalidFields = [];
				let scrollToPanelId = null;
				let panelModel = cloneDeep(this.panelModel);

				Object.keys(errors).forEach((key) => (errors[key].length > 0 ? invalidFields.push(key.replace('-0', '').replace('-1', '')) : null));

				this.filterSchema.forEach((group, groupIndex) => {
					group.value.forEach((panel, panelIndex) => {
						panel.value.forEach((item) => {
							if (invalidFields.includes(item.name)) {
								if (!panelModel[groupIndex]) panelModel[groupIndex] = [];
								if (!panelModel[groupIndex].includes(panelIndex)) panelModel[groupIndex].push(panelIndex);
								scrollToPanelId = scrollToPanelId || `#expansion-panel-${groupIndex}${panelIndex}`;
							}
						});
					});
				});

				this.panelModel = panelModel;
				this.scrollToPanel(scrollToPanelId);
			},

			/**
			 * @name scrollToPanel
			 * @description Scrolls to specified panel
			 * @param panelId
			 */
			scrollToPanel(panelId) {
				// let offset = document.querySelector(panelId).offsetParent.offsetTop + 100;
				// console.log(panelId);

				setTimeout(() => {
					let navigationDrawer = this.$refs['navigation-drawer'].$el.querySelector('div.v-navigation-drawer__content');
					this.$vuetify.goTo(panelId, { container: navigationDrawer });
				}, 100);
			},

			/**
			 * @name setRangeInitialValues
			 * @description Open filters panel
			 */
			setRangeInitialValues() {
				let rangeFields = [];
				this.filterSchema.forEach((group) => {
					group.value.forEach((panel) => {
						panel.value.forEach((item) => {
							if (item.type === 'range' || item.type === 'range-slider') rangeFields.push(item);
						});
					});
				});

				rangeFields.forEach((item) => {
					if (!this.initialFilterModel[item.name] || !this.initialFilterModel[item.name].length) {
						this.initialFilterModel[item.name] = [0, 0];
						this.initialFilterModel[item.name][1] = this.getMax(item.name);
					}
				});
			},

			updateFilterOptionsData() {
				this.filterSchema?.forEach((group, groupIndex) => {
					group?.value?.forEach((panel, panelIndex) => {
						if (panel?.value?.findIndex((item) => item.name === 'restrictedProviders') > -1)
							this.$set(this.filterSchema[groupIndex].value[panelIndex].value[0], 'options', this.filterOptionsData.restrictedProviders);
					});
				});
			},

			/**
			 * @name checkFilterPanelActive
			 * @description Check if a filter is active or not
			 * @returns {Boolean} Is active
			 */
			checkFilterPanelActive(panel) {
				let isActive = false;

				panel.value.forEach((item) => {
					let value = this.filterModel[item.name];

					if (item.type === 'switch' || item.type === 'input' || item.type === 'select') {
						if (Array.isArray(value)) {
							if (value.length > 0) isActive = true;
						} else if (typeof value !== 'undefined' && value !== null && value !== false && value !== '') isActive = true;
					} else if (item.type === 'slider') {
						if (value > 0) isActive = true;
					} else if ((item.type === 'range' || item.type === 'range-slider') && !isEqual(value, this.initialFilterModel[item.name])) isActive = true;
				});

				return isActive;
			},

			/**
			 * @name checkFilterPanelEdited
			 * @description Check if a filter is edited or not
			 * @returns {Boolean} Is edited
			 */
			checkFilterPanelEdited(panel) {
				let isEdited = false;

				panel.value.forEach((item) => {
					if (!isEqual(this.activeFilterModel[item.name], this.filterModel[item.name])) isEdited = true;
				});

				return isEdited;
			},

			/**
			 * @name getMin
			 * @description Get minimum value of the selected property in product list
			 * @returns {Number} minimum value
			 */
			getMin(key) {
				return Math.ceil(minBy(this.productList, key)[key]);
			},

			/**
			 * @name getMax
			 * @description Get maximum value of the selected property in product list
			 * @returns {Number} maximum value
			 */
			getMax(key) {
				return Math.ceil(maxBy(this.productList, key)[key]);
			},

			/**
			 * @name selectAll
			 * @description Select all
			 * @param {Object} field the input
			 */
			selectAll(field) {
				if (this.filterModel[field.name].length === field.options.length) this.filterModel[field.name] = [];
				else this.filterModel[field.name] = (field.options || []).map((option) => option.value);
			},

			/**
			 * @name openQueryBuilderDialog
			 * @description Open query builder dialog
			 */
			openQueryBuilderDialog() {
				console.log('this.queryModel :>> ', this.queryModel);
				this.$refs['query-builder'].open(this.queryModel);
			},

			/**
			 * @name saveQuery
			 * @description Save query
			 * @param {Object} data the query data
			 */
			saveQuery(data) {
				this.queryModel = data;
				let queryBuilder = [];

				data.forEach((item) => {
					let query = {
						property: item.property,
						condition: item.condition,
						value: item.value
					};

					if (item.expression) query.expression = item.expression;

					queryBuilder.push(query);
				});

				this.$set(this.filterModel, 'queryBuilder', queryBuilder);
			},

			/**
			 * @name setQueryBuilderValues
			 * @description gets the query builder value from filter object and sets it to queryModel for query builder component. This is the opposite of saveQuery
			 */
			setQueryBuilderValues() {
				if (!this.filterModel.queryBuilder) return;

				this.queryModel = this.$refs['query-builder'].setQuery(this.filterModel.queryBuilder);
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-toolbar__extension {
		display: block;
	}

	.filter-button {
		right: 64px;
	}
</style>
