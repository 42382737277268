import { Will } from '@/service';

/**
 * @class @name Will
 * @description Exposes menu store module
 */
export default class WillStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @name loadwillList
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async loadWillList(context, payload) {
				return Will.list(payload).then(({ data }) => data);
			},
			/**
			 * @name submitWill
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async submitWill(context, payload) {
				return Will.post(payload).then(({ data }) => data);
			}
		};
	}
}
