import { render, staticRenderFns } from "./video-player.vue?vue&type=template&id=acd7b972&scoped=true&"
import script from "./video-player.vue?vue&type=script&lang=js&"
export * from "./video-player.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acd7b972",
  null
  
)

export default component.exports