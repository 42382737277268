<!--
	@name app-awards
	@description Awards page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-img
			:src="'events/AirAwards2023-192-ALL-WINNERS-1-scaled-e1701948365886.jpg' | urlize"
			style="border-bottom: 20px solid #1eb0e1"
			:height="$vuetify.breakpoint.mdAndUp ? '600' : ''"
		>
			<div id="overlay" />
			<v-container style="position: relative" fill-height>
				<v-col>
					<div class="text-h3 mt-6 white--text">
						The Later Life Lending <br />

						Awards 2023
					</div>
				</v-col>
				<v-col>
					<v-img style="width: 24rem" :src="'logo/brand/later-life-lending-awards-logo.png' | urlize" />
				</v-col>
			</v-container>
		</v-img>

		<!-- About Us -->
		<div>
			<v-container>
				<div class="text-center d-flex flex-column align-center py-8" :class="{ 'py-12': $vuetify.breakpoint.mdAndUp }">
					<div class="text-h4 mb-6">
						About the awards
					</div>
					<p class="text-h6" style="max-width: 800px">
						As the market's leading distribution channel and platform for later life lending professionals, Air is delighted to announce the second 'Later Life Lending
						Awards' to be held at the Voco in Oxford.
					</p>
					<p class="text-body-1 mb-0" style="max-width: 800px">
						This event is designed to celebrate and recognise the very best advisers, lenders and stakeholders from across our industry, an inclusive event covering the
						full range of later life propositions and reflecting the new industry-adopted definition of later life lending.
					</p>
				</div>
			</v-container>
		</div>

		<v-divider />

		<!-- Benefits -->
		<div :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
			<v-container>
				<div class="text-h4 text-center">
					<p>2023 Winners</p>
				</div>

				<v-row no-gutters class="justify-center mt-4" :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }">
					<v-col v-for="(item, i) in awards" :key="i" cols="12" md="4" class="d-flex flex-column">
						<v-card class="ma-6 text-center flex d-flex flex-column" elevation="2">
							<v-img :src="item.image | urlize" height="200" style="flex: 0 0 160px" />

							<v-card-text class="flex px-2 mt-4 py-0">
								<div class="text-body-1 font-weight-bold">
									{{ item.category }}
								</div>
								<div class="text-h6 justify-center secondary--text">
									{{ item.winner }}
								</div>
								<div class="mt-4">
									Presented by
								</div>
								<div class="text-body-1">
									{{ item.presentedBy }}
								</div>
							</v-card-text>

							<v-card-actions class="justify-center">
								<v-btn text class="text-capitalize primary--text" @click="openDialog(i)">
									See Description
								</v-btn>
							</v-card-actions>

							<v-dialog v-model="dialog[i]" max-width="500px" transition="dialog-transition">
								<v-card class="pa-2">
									<v-card-text>
										<v-btn icon style="position: absolute; right: 18px; top: 18px" @click="closeDialog(i)">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<div class="text-h6 justify-center secondary--text" style="line-height: 1">
											{{ item.category }}
										</div>
										<div class="text-body-2 mt-4">
											{{ item.description }}
										</div>
									</v-card-text>
								</v-card>
							</v-dialog>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'app-awards',

		components: {},

		data() {
			return {
				dialog: [],
				awards: [
					{
						category: 'Best Adviser Support (Firm)',
						winner: 'Pure Retirement',
						presentedBy: 'Mike Taylor (Air)',
						image: 'events/Best-Adviser-Support-Firm-Pure-Retirement-2048x1365.jpg',
						description:
							'In a complex and fast-moving market, providing advisers with support to help them keep their finger on the pulse has been critical. Whether it’s latest products, updates in technology or adapting to regulatory change, lenders have had to constantly evolve their support. So who’s done this best?'
					},
					{
						category: 'Best Large Advisory Firm (+5)',
						winner: 'Viva Retirement Solutions',
						presentedBy: 'Kelly Steel (Gilroy Steel)',
						image: 'events/Best-Large-Advisory-Firm-5-Viva-Retirement-Solutions-2048x1365.jpg',
						description:
							'This category is designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions, going above and beyond to improve every touchpoint.'
					},
					{
						category: 'Best Small Advisory Firm (1-5)',
						winner: 'Lifetime Equity Release',
						presentedBy: 'Nick Startford (Poynton Law)',
						image: 'events/Best-Small-Advisory-Firm-1-5-Lifetime-Equity-Release-2048x1365.jpg',
						description:
							'This category recognises the effort of smaller equity release advisory firms in their own right, who make a big impact across the later life lending industry.'
					},
					{
						category: 'Best Later Life Innovation',
						winner: 'LiveMore',
						presentedBy: 'Kieran Mullen (Canada Life)',
						image: 'events/Best-Later-Life-Innovation-LiveMore-Capital-2048x1365.jpg',
						description:
							'This category recognises firms striving to support advisers by quickly evolving and adapting to the changing market condition. Whether it’s a product, a new feature, a enhanced service or an online tool, which firm has delivered the greatest innovation to the market this year?'
					},
					{
						category: 'Best Later Life Provider',
						winner: 'Legal & General',
						presentedBy: 'Leon Diamond (LiveMore)',
						image: 'events/Best-Later-Life-Provider-Legal-General--2048x1365.jpg',
						description:
							'This award recognises providers who have showcased and championed later life lending excellence. And have been at the forefront of supporting advisers with their product and service offering.'
					},
					{
						category: 'Best Legal Firm',
						winner: 'Equilaw',
						presentedBy: 'Lisa Windley (Responsible Lending)',
						image: 'events/Best-Legal-Firm-Equilaw-2048x1365.jpg',
						description:
							'Independent legal advice is critically important in our industry and this award is designed to recognise the very best later life lending conveyancers who provide personalised advice tailored to clients.'
					},
					{
						category: 'Best Network or Club',
						winner: 'HL Partnership',
						presentedBy: 'Kay Westgarth (Standard Life Home Finance)',
						image: 'events/Best-Network-for-Later-Life-Lending-HL-Partnership-2048x1365.jpg',
						description:
							'This award goes to the Network that has made the biggest impact in later life lending this year, through supporting their members with education, resources and tools to enable them to offer their clients more options to meet their needs.'
					},
					{
						category: 'Best Adviser Support (Individual)',
						winner: 'Pauline Dovey',
						presentedBy: 'Mike Millar (more2life)',
						image: 'events/Best-Adviser-Support-Individual-Pauline-Dovey-2048x1365.jpg',
						description:
							'Providing personalised support for clients during the advice process is critical to enable great customer outcomes. This category recognises the individuals that have offered their clients superior support and exceeded their expectations.'
					},
					{
						category: 'BDM of the Year',
						winner: 'Fran Green',
						presentedBy: 'Nick Birdseye (Legal & General)',
						image: 'events/BDM-of-the-Year-Fran-Green-2048x1365.jpg',
						description:
							'This category recognises a Business Development Manager who has demonstrated their skill, market expertise and knowledge to support advisers to navigate the changes impacting the industry and done this with passion and a positive attitude.'
					},
					{
						category: 'Later Life Adviser of the Year',
						winner: 'Stuart Borthwick',
						presentedBy: 'Scott Burman (Pure Retirement)',
						image: 'events/Later-Life-Adviser-of-the-Year-Stuart-Borthwick-2048x1365.jpg',
						description:
							'This award recognises the individual who is the leading light when it comes to advice delivery, customer service and continuing professional development – a true local hero of their community. It is one of our most coveted award categories.'
					},
					{
						category: 'Market Champion of the Year',
						winner: 'David Forsdyke',
						presentedBy: 'Peter Barton (Barton Law)',
						image: 'events/Market-Champion-of-the-Year-David-Forsdyke-2048x1365.jpg',
						description:
							'This award is designed to recognise the very best of our industry’s flag wavers, those individuals who have demonstrated the value of the industry and promoted great customer outcomes.'
					},
					{
						category: 'Newcomer of the Year',
						winner: 'Haydn Garnett',
						presentedBy: 'Jade Barry (Equilaw)',
						image: 'events/Newcomer-of-the-year-Haydn-Garnett-2048x1365.jpg',
						description:
							'This award recognises the achievements of individuals in their first year of working within the later life lending industry. These advisers have undoubtedly had to navigate some of the greatest challenges the industry has faced all whilst showcasing resilience and excellence.'
					},
					{
						category: 'Underwriter of the Year',
						winner: 'Steve Miller',
						presentedBy: 'Emma Graham (Hodge)',
						image: 'events/Underwriter-of-the-year-Steve-Miller-2048x1365.jpg',
						description:
							'This award will recognise the best of the best when it comes to lending criteria expertise and support, these underwriters are the unsung experts of our industry and key gatekeepers to lending success.'
					}
				],
				categories: [
					{
						name: 'Best Later Life Provider',
						description:
							'This award will be open to all providers of later life products that meet the new industry definition of ‘later life lending’ (lifetime mortgages, later life mortgage and Retirement Interest Only mortgages). This category will also be open to Home Reversion providers.'
					},
					{
						name: 'Best Legal Firm',
						description:
							'Independent legal advice is critically important in our industry and this award is designed to recognise the very best later life lending conveyancers, who either through their specialist conveyancing offering or providing of specialist legal advice to clients stands head and shoulders above others.'
					},
					{
						name: 'Best Large Advisory Firm (+5)',
						description:
							'Our Best Advisory Firm award categories are designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions. This category is for firms that have more than five qualified later life advisers working within them.'
					},
					{
						name: 'Best Small Advisor Firm (1-5)',
						description:
							'Our Best Advisory Firm award categories are designed to recognise the stand-out performance of firms that put customer outcomes at the heart of their propositions. This category is for firms that have between 1 and 5 qualified later life advisers working within them.'
					},
					{
						name: 'Later Life Advisor of the Year',
						description:
							'This award recognises the individual in our industry who is the leading light when it comes to advice delivery, customer service and continuing professional development – a true local hero of their community. It is one of our most coveted award categories and competition is fierce!'
					},
					{
						name: 'Newcomer of the Year',
						description:
							'The future of our market depends on more advisers and support staff and qualified, experienced in later life lending joining this industry. This award is specifically to recognise the achievements of those in this first year of their later life profession.'
					},
					{
						name: 'Market Champion of the Year',
						description:
							'The success of our industry depends on many things, including great products, features and of course expert advice. It also hinges on individuals and firms who work hard to promote the great customer outcomes that later life lending can bring and this award is designed to recognise the very best of our industry’s flag wavers.'
					},
					{
						name: 'Underwriter of the Year',
						description:
							'Underwriters are the unsung experts of our industry and key gatekeepers to lending success. This award will recognise the best of the best when it comes to lending criteria expertise and support.'
					},
					{
						name: 'BDM of the Year',
						description:
							'A great BDM is worth their weight in gold, especially during tough market conditions, and our award for the BDM of the Year will demonstrate just how much their skill, market expertise and knowledge is appreciated by advisers.'
					},
					{
						name: 'Best Network or Club',
						description:
							'Networks are fast becoming the engine room of growth in the later life lending market as more and more advisers from other sectors of financial services engage with the products and propositions on offer. Our award goes to the Network that has made the biggest impact in later life lending this year.'
					},
					{
						name: 'Best Later Life Lending Innovation',
						description:
							'Whether it’s a product, a feature, a service, an online tool or other new piece of later life lending kit, which firm – or individual – has delivered the greatest innovation to the market this year?'
					},
					{
						name: 'Best Adviser Support - Individual',
						description:
							'In a complex and fast-moving market, providing advisers with support to help them keep their finger on the pulse of the latest products, technology and regulatory requirements is crucial. Which member of your team does this best?'
					},
					{
						name: 'Best Adviser Support - Firm',
						description:
							'In a complex and fast-moving market, providing advisers with support to help them keep their finger on the pulse of the latest products, technology and regulatory requirements is crucial. Who does this best? A lender? Service provider? This award will give us the answer!'
					}
				]
			};
		},

		computed: {},

		created() {},

		methods: {
			openDialog(i) {
				this.$set(this.dialog, i, true);
			},
			closeDialog(i) {
				this.$set(this.dialog, i, false);
			}
		}
	};
</script>

<style lang="scss" scoped>
	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}
</style>
