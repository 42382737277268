import { detect } from 'detect-browser';

/**
 * @namespace Utils
 * @method browserCompatible()
 * @exports Utils/browserCompatible
 * @description Util for checking browser compatibility
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default function browserCompatible() {
	const browser = detect();
	const html = document.getElementsByTagName('html')[0];
	const el = document.getElementById('ieMessage');
	const acceptedBrowsers = ['chrome', 'edge-chromium', 'firefox', 'safari'];
	const acceptedOS = ['iOS', 'Android OS'];

	html.classList.add(browser.name);

	if (acceptedBrowsers.includes(browser.name) || acceptedOS.includes(browser.os)) {
		if (el) el.remove();

		return true;
	} else {
		if (el) el.removeAttribute('style');

		return false;
	}
}
