import { Process } from '@/service';

/**
 * @class @name Process
 * @description Exposes menu store module
 */
export default class ProcessStore {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name getProcess
			 * @param {String} id
			 */
			async getProcess(context, id) {
				return Process.get(id);
			},

			/**
			 * @async @name getProcess
			 * @param {String} id
			 */
			async getProcessList(context, payload) {
				return Process.list(payload);
			},

			/**
			 * @async @name addProcess
			 * @param {String} id
			 */
			async addProcess(context, payload) {
				return Process.add(payload);
			}
		};
	}
}
