<!--
	@name app-dashboard-product-changes
	@description Product changes component
	@date 2022/09/30
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" style="position: relative;">
		<common-structure-section class="mt-6">
			<template #header>
				Air's 1 million reward point summer giveaway
			</template>

			<template #body>
				<p class="text-body-1 mb-0">
					You've helped us to raise <span class="text-h5 font-weight-bold">{{ total | numFormat('0,00') }}</span> points towards our charitable donation target! For each KFI submitted between 17th July and 1st September,
					we will contribute reward points towards the charity total and you will be entered into a prize draw. <a class="font-weight-medium" @click="open">Click here for full details.</a>
				</p>
				<v-progress-linear v-if="!totalizerDisplay" indeterminate class="mt-5" height="6" />
				<v-fade-transition>
					<div v-show="totalizerDisplay" class="outline elevation-8 mt-5" :class="[{ 'achieved':success }]">
						<div class="totalizer chart-bg">
							<div v-for="step in steps" :key="step" class="step d-flex justify-center align-center" :class="[{ 'complete':progress / 5 >= step }, { 'achieved':success }]">
								<v-icon size="22" color="blue accent-1">
									mdi-check-bold
								</v-icon>
							</div>
						</div>
					</div>
				</v-fade-transition>

				<v-slide-y-transition>
					<div v-show="ticksDisplay" class="ticks text-h6 font-weight-medium mt-5">
						<div class="tick">
							<span>0</span>
						</div>
						<div class="tick">
							<span>500K</span>
						</div>
						<div class="tick text-h5 font-weight-black">
							<span>1M</span>
						</div>
					</div>
				</v-slide-y-transition>
			</template>
		</common-structure-section>

		<div :class="[{ 'pyro':success }]">
			<div class="before" />
			<div class="after" />
		</div>

		<v-dialog v-model="dialogActive" persistent width="1000">
			<v-card>
				<v-app-bar dark color="blue-grey darken-3">
					<v-toolbar-title>
						{{ title }}
					</v-toolbar-title>
					<v-spacer />
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-close-box</v-icon>
					</v-btn>
				</v-app-bar>
				<v-card-text class="pa-8">
					<h2 v-if="subtitle" class="text-h5 font-weight-bold mb-6 text-center">
						{{ subtitle }}
					</h2>

					<v-progress-linear v-if="!totalizerDisplay" indeterminate height="6" />
					<div v-show="totalizerDisplay" class="outline elevation-8" :class="[{ 'achieved':success }]">
						<div class="totalizer">
							<div v-for="step in steps" :key="step" class="step d-flex justify-center align-center" :class="[{ 'complete':progress / 5 >= step }, { 'achieved':success }]">
								<v-icon size="22" color="blue accent-1">
									mdi-check-bold
								</v-icon>
							</div>
						</div>
					</div>
					<div v-show="ticksDisplay" class="ticks text-h6 font-weight-medium mt-5">
						<div class="tick">
							<span>0</span>
						</div>
						<div class="tick">
							<span>500K</span>
						</div>
						<div class="tick text-h5 font-weight-black">
							<span>1M</span>
						</div>
					</div>

					<p class="body-1 mt-6 mb-6 text-center px-10">
						Plus, get entry into a prize draw for each KFI and win some amazing prizes for yourself, including: a luxury weekend away, an iPad, as well as cash prizes.
					</p>
					<p class="body-1 mb-6 text-center px-10">
						For each KFI submitted, Air will contribute reward points towards the charity total and you will be entered into a prize draw.
					</p>
					<p class="body-1 mb-6 text-center px-10">
						You have until the <span class="font-weight-bold">1st September</span> to take advantage of this amazing offer.
					</p>

					<v-sheet class="text-center">
						<v-btn :to="link" color="primary" class="mx-auto">
							click here to get started!
						</v-btn>
					</v-sheet>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import CommonStructureSection from '@/component/common/structure/section';

	export default {
		name: 'app-dashboard-totalizer',

		components: {
			CommonStructureSection
		},

		data() {
			return {
				loading: true,
                progress: 0,
                total: 0,
                target: 1000000,
                success: false,
                totalizerDisplay: false,
                ticksDisplay: false,
                steps: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
				dialogActive: false,
                title: 'Air\'s 1 million reward point summer giveaway',
				subtitle: 'Submit a KFI and contribute towards Air\'s £6000 charitable donation',
				link: '/source'
			};
		},

		computed: {
			...mapState('CmsKfi', ['count']),

            countdownImageStyle() {
				return { 'background-image': `url('${this.$options.filters.urlize('generic/countdown2.png')}')` };
			}
		},

		async created() {
			this.loading = false;
            let currentPercentage = 0;
            let stepValue = 100 / this.steps.length;

            await this.fetchCount().then(() => currentPercentage = Math.floor(this.count / this.target * 100));
            this.totalizerDisplay = true;

            setTimeout(() => {
                this.ticksDisplay = true;
            }, 400);

            setTimeout(() => {
                let timer = setInterval(() => {
                    if(this.progress + stepValue > currentPercentage || this.progress >= 100) {
                        clearInterval(timer);
                        if(this.progress >= 100) {
                            this.progress = 100;
                            setTimeout(() => {
                                this.success = true;
                            }, 250);
                        } else this.total = this.count;
                    } else {
                        this.progress += stepValue;
                        this.total = this.target / 100 * this.progress;
                    }
                }, 150);
            }, 700);
		},

		methods: {
			...mapActions('CmsKfi', ['fetchCount']),

            open() {
				this.dialogActive = true;
			},

			closeDialog() {
				this.dialogActive = false;
            }
		}
	};
</script>

<style scoped lang="scss">
    a {
        position: relative;
        z-index: 2;
    }

    @keyframes bounce {
        0%   { scale: 1; }
        50%   { scale: 1.04; }
        100%   { scale: 1; }
    }

	.outline {
        border: 3px solid #fff;
        border-radius: 5px;
        position: relative;
        z-index: 2;

        &.achieved {
            animation-name: bounce;
            animation-duration: .3s;
        }
	}

    .totalizer {
		width: 100%;
        height: 40px;
        display: flex;
        background-size: cover;
        background-blend-mode: hard-light;
    }

    .step {
        flex-grow: 1;
        height: 100%;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        .v-icon {
            opacity: 0;
        }

        &.complete {
            background-image: linear-gradient(45deg, rgba(71, 0, 72, 1) 0%, rgba(27, 120, 216, 1) 100%);
            background-size: cover;
            background-blend-mode: hard-light;
            transition: background-color .25s;

            &:not(.achieved) {
                &:nth-child(4n+2) {
                    background-image: linear-gradient(135deg, rgba(71, 0, 72, 1) 0%, rgba(27, 120, 216, 1) 100%);
                }

                &:nth-child(4n+3) {
                    background-image: linear-gradient(225deg, rgba(71, 0, 72, 1) 0%, rgba(27, 120, 216, 1) 100%);
                }

                &:nth-child(4n+4) {
                    background-image: linear-gradient(315deg, rgba(71, 0, 72, 1) 0%, rgba(27, 120, 216, 1) 100%);
                }
            }

            .v-icon {
                opacity: 1;
            }
        }

        &.achieved {
            background-blend-mode: color-dodge;
            background-color: rgba(21, 127, 31, 1);

            .v-icon {
                color: #fff !important;
            }
        }

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    .ticks {
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(135deg, rgba(255, 23, 68, 0.7) 0%, rgba(255, 164, 0, 0.8) 30%, rgba(255, 164, 0, 0.8) 70%, rgba(21, 127, 31, 0.8) 100%);
        //background-image: linear-gradient(135deg, rgba(103, 0, 86, 1) 0%, rgba(30, 176, 225, 1) 50%, rgba(103, 0, 86, 1) 100%);
        background-position: 50% 50%;
        background-size: 100% 2px;
        border: none;
        position: relative;
        z-index: 1;

        .tick {
            flex-grow: 1;
            flex-basis: 33%;
            text-align: center;

            span {
                display: inline-block;
                background: #fff;
                padding: 0 12px;
            }

            &:first-child {
                text-align: left;

                span {
                    padding-left: 0;
                }
            }

            &:last-child {
                text-align: right;

                span {
                    padding-right: 0;
                }
            }
        }
	}

    $particles: 150;
    $width: 600;
    $height: 600;

    // Create the explosion...
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
                random($width)-$width / 2 + px
                random($height)-$height / 1.2 + px
                hsl(random(360), 100, 50);
    $box-shadow2: $box-shadow2, 0 0 #fff
    }
    @mixin keyframes ($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @-ms-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @mixin animation-delay ($settings) {
        -moz-animation-delay: $settings;
        -webkit-animation-delay: $settings;
        -o-animation-delay: $settings;
        -ms-animation-delay: $settings;
        animation-delay: $settings;
    }

    @mixin animation-duration ($settings) {
        -moz-animation-duration: $settings;
        -webkit-animation-duration: $settings;
        -o-animation-duration: $settings;
        -ms-animation-duration: $settings;
        animation-duration: $settings;
    }

    @mixin animation ($settings) {
        -moz-animation: $settings;
        -webkit-animation: $settings;
        -o-animation: $settings;
        -ms-animation: $settings;
        animation: $settings;
    }

    @mixin transform ($settings) {
        transform: $settings;
        -moz-transform: $settings;
        -webkit-transform: $settings;
        -o-transform: $settings;
        -ms-transform: $settings;
    }

    .pyro {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .pyro > .before, .pyro > .after {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        box-shadow: $box-shadow2;
        @include animation((1s bang ease-out 3 backwards, 1s gravity ease-in 3 backwards, 5s position linear 3 backwards));
    }

    .pyro > .after {
        @include animation-delay((1.25s, 1.25s, 1.25s));
        @include animation-duration((1.25s, 1.25s, 6.25s));
    }

    @include keyframes(bang) {
        to {
            box-shadow:$box-shadow;
        }
    }

    @include keyframes(gravity)  {
        to {
            @include transform(translateY(200px));
            opacity: 0;
        }
    }

    @include keyframes(position) {
        0%, 19.9% {
            margin-top: 10%;
            margin-left: 40%;
        }
        20%, 39.9% {
            margin-top: 40%;
            margin-left: 30%;
        }
        40%, 59.9% {
            margin-top: 20%;
            margin-left: 70%
        }
        60%, 79.9% {
            margin-top: 30%;
            margin-left: 20%;
        }
        80%, 99.9% {
            margin-top: 30%;
            margin-left: 80%;
        }
    }

	.image {
		width: 100%;
		height: 150px;
		//background-position: 50% 50%;
		background-size: cover;
	}
</style>
