import Vue from 'vue';
import urlize from './urlize';
import truncate from './truncate';
import formatDate from './format-date';
import stripHTML from './strip-html';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import vueMoment from 'vue-moment';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/London');

Vue.use(vueMoment, {
	moment
});

/**
 * @namespace Filters
 * @exports Filters/truncate
 * @exports Filters/numFormat
 * @description Filters for use in Vue
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	install(Vue) {
		Vue.filter('truncate', truncate);
		Vue.filter('formatDate', formatDate);
		Vue.filter('numFormat', numFormat(numeral));
		Vue.filter('urlize', urlize);
		Vue.filter('stripHTML', stripHTML);
	}
};
