import { $http } from '@/utils';

/**
 * @namespace Service
 * @class User
 * @exports Service/Account/User
 * @description API User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class User {
	/**
	 * @static @name get
	 * @description Get User details for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`account/user/${id}`);
	}

	/**
	 * @static @name list
	 * @description Get all Users with details (RBAC)
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`account/user/list`, payload);
	}

	/**
	 * @static @name patch
	 * @description Update some User details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static patch(id, payload) {
		return $http.patch(`account/user/${id}`, payload);
	}

	/**
	 * @static @name delete
	 * @description Hard delete User details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.post(`account/user/delete/${id}`);
	}

	/**
	 * @static @name academyAdvisorList
	 * @description Get all academy advisor users with details (RBAC)
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static academyAdvisorList(payload) {
		return $http.post(`account/user/academy-advisor-list`, payload);
	}
}
