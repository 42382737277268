import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Recommendation
 * @exports Service/Account/User/Recommendation
 * @description API User Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Recommendation {
	/**
	 * @static @name get
	 * @description Get User recommendations for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(uid) {
		return $http.get(`account/user/${uid}/recommendation/details`);
	}

	/**
	 * @static @name delete
	 * @description Hard delete User details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(uid, id) {
		return $http.delete(`account/user/${uid}/recommendation/${id}`);
	}

	/**
	 * @static @name add
	 * @description Add a new User resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static add(uid, payload) {
		return $http.post(`account/user/${uid}/recommendation`, payload);
	}
}
