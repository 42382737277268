import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Event
 * @exports Service/Cms/Event
 * @description API Event Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Event {
	/**
	 * @static @name list
	 * @description Get all events as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post('cms/event/list', payload, {
			cache,
			cachePath: `table-cms-event-${domain}`
		});
	}
}
