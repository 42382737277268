<!--
	@name app-case-manager
	@description Case manager page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-row v-if="viewLegacy">
			<v-col class="py-0" cols="12">
				<v-sheet class="pa-5 mb-6">
					<p class="text-body-1 mb-0">
						<span class="font-weight-bold">The case manager provides you with a comprehensive list of all your active cases.</span> Where applicable, you will be able
						perform actions including 'Apply for a Product', 'View a Research PDF' and 'Download KFI PDF'. Cases that are not currently active will not appear in your
						case manager and can instead be found in the relevant Client record.
					</p>
				</v-sheet>
				<app-case-manager-cases />
			</v-col>
		</v-row>
		<div v-else>
			<v-sheet class="pa-6 mb-9 rounded background-gradient">
				<v-row no-gutters>
					<v-col class="shrink align-self-center">
						<div class="align-self-center nowrap headline white--text">
							Case Manager
						</div>
					</v-col>
					<v-col class="grow text-right">
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn large text color="white" to="/account" v-on="on">
									<v-icon large>
										mdi-backburger
									</v-icon>
								</v-btn>
							</template>
							<span>Back to Dashboard</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-sheet>
			<v-row>
				<v-col class="py-0" cols="12" md="8">
					<div class="border-effect border-effect--lg ml-6 mb-10">
						<p class="text-h5 mb-2 font-weight-regular">
							Important update: <span class="font-weight-bold">this page is being decommissioned</span>
						</p>
						<p class="text-body-1 mb-5">
							We wanted to inform you that the Case Manager page will be removed as of <span class="font-weight-bold">31st October 2024</span>.
							We've recently made updates to this page and asked for your feedback, we highly value your input!
							If you have any suggestions or comments, you can provide feedback until the page is decommissioned by clicking the button below.
							Thank you for your understanding.
						</p>
						<p class="mb-0">
							<v-btn
								class="ga--trigger hj--open-case-manager-survey mr-2"
								color="primary"
							>
								Provide feedback
							</v-btn>
							<v-btn
								color="secondary"
								@click="viewLegacy = true"
							>
								view the old case manager
							</v-btn>
						</p>
					</div>
				</v-col>

				<v-col class="py-0 pt-6" cols="12" md="4" />
			</v-row>
		</div>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';
	import AppCaseManagerCases from '@/component/app/case-manager/cases';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-case-manager',

		components: { AppCaseManagerCases },

		data() {
			return {
				activityList: null,
				viewLegacy: false,
				onAirArticle: {
					id: '97b3974a-35a6-4a44-b735-0031453dd5c1',
					title: 'Introducing AirFlow'
				}
			};
		},

		created() {
			this.loadActivities();
		},

		methods: {
			...mapActions('LogActivityUser', ['loadActivityList', 'addActivityUser', 'deleteActivityUser']),

			loadActivities() {
				this.loadActivityList().then((activityList) => (this.activityList = activityList));
			},

			/**
			 * @name openArticle
			 * @description Open on air article
			 */
			openArticle(id) {
				this.$nextTick(() => EventBus.$emit('open-on-air-article', id));
			}
		}
	};
</script>
