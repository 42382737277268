import linkify from '@/directives/linkify';

/**
 * @namespace Directives
 * @exports Directives/linkify
 * @description Directives for use in Vue
 * @date 2020/08/07
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	install(Vue) {
		Vue.directive('linkify', linkify);
	}
};
