import { $http } from '@/utils';

/**
 * @namespace Service
 * @class ActivityUser
 * @exports Service/Log/ActivityUser
 * @description API ActivityUser Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class ActivityUser {
	/**
	 * @static @name list
	 * @description Returns the log activity for the user, requires a userId as where criteria if you do not have super admin rights
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post(`log/activity-user/list`, payload);
	}

	/**
	 * @static @name clientList
	 * @description Returns the log activity for the client, requires a userId and clientId as where criteria if you do not have super admin rights
	 * @returns {Object} Promise a http response
	 */
	static clientList(clientId, payload) {
		return $http.post(`log/activity-user/client/${clientId}/list`, payload);
	}

	/**
	 * @static @name add
	 * @description Takes an ID and returns log activty, thi smust be your user account or you need supar admin rights
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`log/activity-user`, payload);
	}

	/**
	 * @static @name delete
	 * @description Delete activity user for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(id) {
		return $http.delete(`log/activity-user/${id}`);
	}
}
