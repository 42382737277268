import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Asset
 * @exports Service/Client/Asset
 * @description API Client Asset Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Health {
	/**
	 * @static @name list
	 * @description Get health details for a specific client (RBAC)
	 * @param {Object} clientId
	 * @returns {Object} Promise a http response
	 */
	static list(clientId) {
		return $http.get(`client/${clientId}/health`);
	}

	/**
	 * @static @name delete
	 * @description Hard delete client health for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static delete(clientId) {
		return $http.delete(`client/${clientId}/health`);

	}
}
