<!--
	@name app-equity-dialog-restricted-product
	@description Source result dialog showing products outside criteria
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!-- prettier-ignore -->
<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Products Outside Criteria
		</template>
		<template #body>
			<h3 class="text-h6 font-weight-bold">
				Products Outside Criteria
				<v-chip small dark class="mb-1 ml-1 pa-2 red accent-1">
					{{ outsideCriteriaProductCount }} {{ outsideCriteriaProductCount > 1 ? 'Products' : 'Product' }}
				</v-chip>
				<p class="text-subtitle-1 pt-0">
					Find and manage products outside criteria below.
				</p>
				<v-divider />

				<v-expansion-panels v-model="panel">
					<v-expansion-panel v-for="(item, key) in items" :key="key" @change="panelOpened(item)">
						<v-expansion-panel-header>
							<div>
								<span class="font-weight-medium">
									{{ item.title }}
								</span>
								<v-chip small dark class="mb-1 ml-2 pa-2 blue-grey lighten-5 error--text">
									{{ item.count }}
								</v-chip>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div>
								<v-card-text class="pt-0 px-0">
									<v-row no-gutters>
										<v-col cols="12" sm="4">
											<v-text-field
												v-model="search"
												:disabled="loading"
												append-icon="mdi-magnify"
												label="Search"
												outlined
												dense
												hide-details
												@keydown="searchBarChangeHandler"
											/>
										</v-col>
										<v-col cols="12" sm="4" />
										<v-col cols="12" sm="4">
											{{ item.type }}
											<v-btn
												v-show="item.code != 999999 && !item.hardFail"
												class="ga--trigger ga--filters-opened float-right"
												data-ga-id="outside-criteria-dialog"
												color="primary"
												@click.stop="updateCriteria(item.code)"
											>
												{{ isArchive ? 'View' : 'Update' }} Criteria
											</v-btn>
										</v-col>
									</v-row>
								</v-card-text>
								<v-alert v-if="error" type="error" dense class="body-2 mb-0">
									An unexpected error occurred
								</v-alert>
								<v-alert v-else-if="!loading && !products.length" type="warning" dense class="body-2 mb-0">
									Your search matched 0 products.
								</v-alert>
								<v-data-table
									v-show="products"
									:loading="loading"
									:headers="headers"
									:footer-props="footerProps"
									:items="products"
									:items-per-page="itemsPerPage"
									:server-items-length="productCount"
									:search="search"
									:hide-default-header="true"
									:hide-default-footer="true"
								>
									<template #body="{ items }">
										<tbody>
											<tr v-for="(item, key) in items" :key="key">
												<td class="shrunk">
													<v-tooltip bottom>
														<template #activator="{ on }">
															<v-img
																:src="logoName(item.provider.logo) | urlize('organisation')"
																lazy-src="https://picsum.photos/id/11/10/6"
																aspect-ratio="1"
																contain
																width="50"
																max-height="40"
																v-on="on"
															>
																<template #placeholder>
																	<v-layout fill-height align-center justify-center ma-0>
																		<v-progress-circular indeterminate color="primary" />
																	</v-layout>
																</template>
															</v-img>
														</template>
														<span>{{ item.provider.name }}</span>
													</v-tooltip>
												</td>
												<td>
													<span class="font-weight-regular">{{ item.name }}</span>
													<div v-show="item.code === 999999">
														<strong>Reason: {{ item.reason ? item.reason : '--' }}</strong>
													</div>
												</td>
												<td>
													<v-btn v-show="item.code === 999999" class="float-right" color="success" @click="removeProductRestriction(item)">
														Remove Restriction
													</v-btn>
												</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</div>
							<div class="text-center mt-3">
								<v-pagination v-model="currentPage" :disabled="!pages || !products.length" :length="pages" :total-visible="9" @input="fetchProducts" />
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</h3>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import CommonDialog from '@/component/common/dialog';
	import { debounce } from 'lodash';

	export default {
		name: 'app-source-result-dialog-restricted-product',

		components: { CommonDialog },

		props: {
			outsideCriteriaProductCount: {
				type: Number,
				default: 0
			}
		},

		data() {
			return {
				error: false,
				loading: false,
				iterationId: null,
				processId: null,
				panel: null,
				panels: {},
				products: [],
				items: [],
				code: null,
				search: null,
				currentPage: 1,
				itemsPerPage: 5,
				productCount: 0,
				footerProps: {},
				headers: [
					{ text: 'Name', align: 'left', value: 'provider.name' },
					{ text: 'Product', align: 'left', value: 'name' },
					{ text: 'Reason', align: 'left', value: 'restriction.reason' }
				]
			};
		},

		computed: {
			pages() {
				return Math.ceil(this.productCount / this.itemsPerPage);
			},

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			}
		},

		methods: {
			...mapActions('AppSourceIteration', ['getIteration']),
			...mapActions('LifetimeMortgageSourcing', ['submitIteration']),

			/**
			 * @name open
			 * @description Open the dialog, setting product
			 */
			open({ iterationId, processId, groupedRestrictedProducts }) {
				this.iterationId = iterationId;
				this.processId = processId;
				this.items = groupedRestrictedProducts;
				this.$refs.dialog.open();
			},

			/**
			 * @name logoName
			 * @description Return primary logo name
			 * @return {String}
			 */
			logoName(items) {
				return items.find((l) => l.primary)?.name;
			},

			/**
			 * @name updateCriteria
			 * @description Open relevant section on filter
			 */
			updateCriteria(restrictionCode) {
				this.$refs.dialog.close();
				this.$emit('update-criteria', restrictionCode);
			},

			panelOpened(item) {
				this.$nextTick(() => {
					this.error = false;
					this.loading = false;
					this.products = [];
					this.currentPage = 1;
					this.productCount = 0;
					this.search = null;
					this.code = null;
					if (this.panel == undefined) return false;
					this.code = item.code;
					(async() => {
						await this.fetchProducts();
					})();
				});
			},

			async fetchProducts() {
				this.error = false;
				this.loading = true;
				this.products = [];
				let { data: iteration } = await this.getIteration(this.iterationId);
				const payload = {
					...iteration.data,
					...{
						outsideCriteria: true,
						outsideCriteriaCode: this.code,
						offset: (this.currentPage - 1) * this.itemsPerPage,
						limit: this.itemsPerPage,
						search: this.search
					}
				};
				try {
					const { data } = await this.submitIteration({ iterationId: this.iterationId, processId: this.processId, data: payload });
					if (data.products && data.products.length) {
						this.products = data.products;
						this.productCount = data.meta.count;
					}
					this.loading = false;
				} catch (err) {
					//TODO handle error
					this.error = true;
					this.loading = false;
				}
			},

			/**
			 * @debounce@name searchBarChangeHandler
			 * @description Intercept a keydown on the search bar, decide if this is the first instance nad act accordingly
			 * @return {Null}
			 */
			searchBarChangeHandler: debounce(function() {
				this.currentPage = 1;
				this.productCount = 0;
				this.fetchProducts();
			}, 800)
		}
	};
</script>

<style scoped>
	td.shrunk {
		width: 1px;
		white-space: nowrap;
	}
</style>
