import { CmsShortcut } from '@/service';

/**
 * @class @name Shortcut
 * @description Exposes menu store module
 */
export default class Shortcut {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			shortcutList: []
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name shortcutDashboard
			 * @description used to get filtered shortcut from store
			 * @param {Object} state
			 */
			shortcutDashboard: (state) => state.shortcutList.find((i) => i.nameUnique === 'dashboardShortcuts') || {},

			/**
			 * @name shortcutAccount
			 * @description used to get filtered shortcut from store
			 * @param {Object} state
			 */
			shortcutAccount: (state) => state.shortcutList.find((i) => i.nameUnique === 'accountShortcuts') || {}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setShortcut
			 * @description used to set Shortcut on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setShortcut: (state, data) => {
				let shortcutList = [...state.shortcutList];
				let idx = shortcutList.reduce((p, c, i) => (c.nameUnique === data.nameUnique ? i : p), -1);
				if (idx >= 0) shortcutList[idx] = data;
				else shortcutList.push(data);
				state.shortcutList = shortcutList;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadShortcut
			 * @description used to fetch app shortcut
			 * @param {function} commit
			 */
			async loadShortcut({ commit }, name) {
				let response = await CmsShortcut.get(name);
				commit('setShortcut', response.data);
			}
		};
	}
}
