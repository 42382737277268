import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Gender
 * @exports Service/Option/Gender
 * @description API File Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Gender {
	/**
	 * @static @name get
	 * @description get gender option
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`option/gender/${id}`);
	}
}
